// libraries
import React, { useContext, useMemo } from 'react'
import { withTheme, ListItem, Button } from 'react-native-elements'
import { format } from 'timeago.js'
import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
// styles
import colors from '../styles/colors'

import api from '../data/api'
import MSFESAvatar from './MSFESAvatar'
import { useEffect } from 'react'

//actions
import fileActions from '../store/file/actions'
import { ActivityIndicator, Platform } from 'react-native'
import { Linking } from 'react-native'

import useBetterLinkTo from '../useBetterLinkTo'
import { RolesVisible } from './DocumentReportList/RolesVisible'
import PublicFormContext from '../contexts/PublicFormContext'
import mime from 'mime'

// components

export const openPdf = (path) => {
  const pdfjspath = '/pdfjs/web/viewer.html?file=' + encodeURIComponent(path)
  const pdfViewerUrl = api.getUrl(pdfjspath, '')

  if (Platform.OS === 'web') {
    window.open(pdfViewerUrl, '_blank')
  } else {
    Linking.openURL(pdfViewerUrl)
  }
}

export const openFile = (path) => {
  if (Platform.OS === 'web') {
    window.open(path, '_blank')
  } else {
    Linking.openURL(path)
  }
}
export const openEmbeddedHtml = (html) => {
  const path = '/htmlembed/index.html?html=' + encodeURIComponent(html)

  const pdfViewerUrl = api.getUrl(path, '')

  if (Platform.OS === 'web') {
    window.open(pdfViewerUrl, '_blank')
  } else {
    Linking.openURL(pdfViewerUrl)
  }
}

const getAvatar = (avatarIcon, type, avatarName, avatarImageUri = null) => {
  if (avatarImageUri) {
    return <MSFESAvatar key={'av'} source={avatarImageUri} showLabel={false} />
  } else if (avatarIcon?.type == 'image') {
    return (
      <MSFESAvatar key={'av'} source={avatarIcon?.source} showLabel={false} />
    )
  } else {
    if (type === 'add') {
      return (
        <MSFESAvatar
          key={'av'}
          icon={{
            name: 'plus',
            color: colors.moduleIcon,
            type: 'font-awesome'
          }}
          showLabel={false}
        />
      )
    } else {
      if (!avatarIcon) {
        return <MSFESAvatar key={'av'} label={avatarName} showLabel={false} />
      } else {
        return <MSFESAvatar key={'av'} icon={avatarIcon} showLabel={false} />
      }
    }
  }
}
export const ListItemLinkButton = ({
  to,
  title,
  subtitle,
  timestamp,
  icon,
  disabled = false,
  showChevron = true,
  avatarName,
  entity,
  due_at,
  avatarIcon = null,
  type = null,
  roleIdsVisible = null,
  avatarImageUri = null,
  file = null,
  fileUrl = null,
  removeAction = null,
  textStyle = null,
  containerStyle = null,
  hideAvatar = false,
  onPress = null,
  rightContent = null,
  loading,
  ...rest
}) => {
  const linkTo = useBetterLinkTo()
  const overloadedOnPress = onPress
    ? onPress
    : () => {
        to && linkTo(to)
      }
  const dispatch = useDispatch()

  const places = useSelector((state) =>
    _.get(state, 'transitions.places.data', {})
  )

  const place = entity && places.find((p) => p.name === entity.place)

  const publicFormContext = useContext(PublicFormContext)
  useEffect(() => {
    if (file) {
      // there is an image ID.
      dispatch(
        fileActions.showFile(
          file,
          publicFormContext.tenantId
            ? {
                tenantId: publicFormContext.tenantId,
                tenantHmac: publicFormContext.tenantHmac
              }
            : undefined
        )
      )
    }
  }, [file])

  const fileObject = useSelector((state) =>
    _.get(state.files, `byId[${file}]`, null)
  )

  const avatarImageInUse = useMemo(() => {
    if (file) {
      return fileObject?.url
    } else if (fileUrl) {
      return fileUrl
    } else {
      if (entity?.SYSTEM_display_picture) {
        return entity.SYSTEM_display_picture
      }
      return avatarImageUri
    }
  }, [file, fileObject, fileUrl, avatarImageUri, entity])

  const avatar = getAvatar(
    avatarIcon,
    type,
    avatarName,
    avatarImageInUse,
    title
  )

  if (!avatarName && entity) {
    avatarName = entity.display_name
  }

  const openDocument = () => {
    const mimeType = mime.getType(fileObject?.path ?? fileUrl)
    const opensAsPdf = mimeType === 'application/pdf'

    const opensFileUrl = fileUrl ?? fileObject.url
    if (opensAsPdf) {
      openPdf(opensFileUrl)
    } else {
      openFile(opensFileUrl)
      console.warn('Viewer required.')
    }
  }

  const titleInUse = fileObject ? fileObject.original_name : title

  const subtitleElement = useMemo(() => {
    if (subtitle) {
      return <ListItem.Subtitle>{subtitle}</ListItem.Subtitle>
    }

    if (timestamp) {
      return <ListItem.Subtitle>{format(timestamp)}</ListItem.Subtitle>
    }
    if (due_at) {
      return <ListItem.Subtitle>Due {format(due_at)}</ListItem.Subtitle>
    }
    if (entity?.assigned_to) {
      return (
        <ListItem.Subtitle>
          Assigned to {entity.assigned_to.display_name}
        </ListItem.Subtitle>
      )
    }
    if (entity && place && !roleIdsVisible) {
      return <ListItem.Subtitle>{place.name}</ListItem.Subtitle>
    }
  }, [entity, place, subtitle])

  const ListItemBody = useMemo(() => {
    return [
      !hideAvatar && avatar,
      <ListItem.Content key="content">
        <ListItem.Title style={textStyle}>{titleInUse}</ListItem.Title>
        {roleIdsVisible && <RolesVisible roleIds={roleIdsVisible} />}
        {subtitleElement}
      </ListItem.Content>,
      icon && <Button key={'icon'} icon={icon} type="clear" />,
      removeAction && (
        <Button
          icon={{
            name: 'backspace',
            color: colors.buttonBackspace,
            type: 'font-awesome-5',
            size: 16,
            width: 48
          }}
          style={{ right: 16, width: 48 }}
          onPress={removeAction}
          key={'remove-icon'}
          type="clear"
        />
      ),
      showChevron && <ListItem.Chevron key="chevron" />,
      loading && <ActivityIndicator />
    ]
  }, [
    textStyle,
    hideAvatar,
    avatar,
    titleInUse,
    subtitle,
    timestamp,
    due_at,
    entity,
    place,
    icon,
    loading,
    roleIdsVisible,
    showChevron
  ])

  const propsList = {
    rightContent,
    bottomDivider: true,
    containerStyle,
    disabled,
    onPress: file || fileUrl ? openDocument : overloadedOnPress,
    ...rest
  }

  if (rightContent) {
    return (
      <ListItem.Swipeable {...propsList}>{ListItemBody}</ListItem.Swipeable>
    )
  } else {
    return (
      <ListItem {...propsList} style={disabled ? { opacity: 0.7 } : null}>
        {ListItemBody}
      </ListItem>
    )
  }
}

export default withTheme(ListItemLinkButton)
