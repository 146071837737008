import { resetLocalEntities } from '../entity/actions'

const setEnvironment = (environment) => {
  return (dispatch) => {
    dispatch({ type: 'app/set-environment', environment })
  }
}
const setNetInfo = (netInfo) => {
  return (dispatch) => {
    dispatch({ type: 'app/set-netinfo', netInfo })
  }
}
const setGridOrList = (gridOrList) => {
  return (dispatch) => {
    dispatch({ type: 'app/set-grid-or-list', gridOrList })
  }
}

export const setFeatureFlag = (feature, flagState) => {
  return (dispatch) => {
    switch (feature) {
      case 'showDeleted':
        dispatch(resetLocalEntities())

        break
    }

    dispatch({ type: 'app/set-feature-flag', args: { feature, flagState } })
  }
}
const setCalendarStyle = (dayOrMonth) => {
  return { type: 'app/set-calendar-style', dayOrMonth }
}
const setDrawerExpandedChildren = (expandedChildren) => {
  return { type: 'app/set-drawer-expanded-children', expandedChildren }
}

const setCalendarsVisible = (calendarsVisible) => {
  return { type: 'app/set-calendars-visible', calendarsVisible }
}

export default {
  setEnvironment,
  setFeatureFlag,
  setNetInfo,
  setGridOrList,
  setCalendarStyle,
  setDrawerExpandedChildren,
  setCalendarsVisible
}
