// libraries
import React, { useEffect } from 'react'
import { View, FlatList, Text, ScrollView } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
// styles

//actions
import entityActions, { getFilterString } from '../store/entity/actions'

// components

import WithHeader from '../components/WithHeader'
import { useMemo } from 'react'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import shared from '../styles/shared'
import { ModuleButton } from '../components/ModuleButton'
import { noData } from '../components/HomeContainer'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'
import MSFESButton from '../components/MSFESButton'

import pluralize from 'pluralize'
import { useColumnHelper } from '../hooks/useColumnHelper'
import { useGetEntityTypeIcon } from './SettingsManageEntityTypesScreen'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

export const currentlyNoTemplate = _.template("No '${labels}' found.")
export const currentlyNoTemplateDefaults = (entityType) => {
  const filled = _.defaults(entityType, { label: 'item' })

  const labels = pluralize(filled.label)
  return currentlyNoTemplate({ labels })
}
export const tileButtonWidth = 180

export default function CategoryViewScreen({ route, navigation }) {
  const dispatch = useDispatch()

  const entityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  const categoryEntity = useSelector((state) => {
    const filterString = sanitiseFilterString(getFilterString({}, null))
    const items = _.get(
      state.entities,
      `[home_screen_category${filterString}]`,
      noData
    )

    const item = items.data?.find((i) => i.id == route.params.id)

    return item
  })

  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const screenButtonsArgs = { detailed: true }
  useEffect(() => {
    dispatch(
      entityActions.listEntity(entityTypeScreenButtons, {
        args: screenButtonsArgs,
        per_page: 120,
        filter: {},
        isFullRefresh: true
      })
    )
  }, [entityTypeScreenButtons, route.params.id])

  const { screenButtonsWrapper } = entityTypeScreenButtons
    ? useSelector((state) => {
        const filterString = sanitiseFilterString(
          getFilterString({}, screenButtonsArgs)
        )
        const screenButtonsWrapper =
          entityTypeScreenButtons &&
          _.get(
            state.entities,
            `[${entityTypeScreenButtons.name + filterString}]`,
            noData
          )

        return { screenButtonsWrapper }
      })
    : { screenButtonsWrapper: noData }

  const entityTypesThisCategory = useMemo(() => {
    return entityTypes.filter(
      (et) =>
        _.get(et, 'object_data.module_category.id', null) == route.params.id
    )
  }, [entityTypes, route.params.id])

  const sections = _.chain(entityTypesThisCategory)
    .groupBy((cat) => {
      return _.get(cat, 'object_data.module_category_header', null)
    })
    .map((elements, category_header) => {
      return { elements, category_header }
    })
    .value()

  const thisScreenButtons = useMemo(() => {
    return (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
      (r) =>
        categoryEntity &&
        _.get(r, 'category_screen.id', null) == categoryEntity?.id
    )
  })

  const numColumns = useColumnHelper()

  const getEntityTypeIcon = useGetEntityTypeIcon()

  return (
    <>
      <WithHeader navigation={navigation} leftButtonType={'back'}>
        <View style={[shared.screenView, { flex: 1 }]}>
          <ScrollView
            contentContainerStyle={{
              paddingBottom: 10
            }}
          >
            <View style={{ marginBottom: spacing.m1 }}>
              {thisScreenButtons.map((thisScreenButton, index) => (
                <MSFESButton
                  key={index}
                  to={thisScreenButton.navigate_to}
                  icon={{ name: thisScreenButton.icon, type: 'font-awesome' }}
                  title={thisScreenButton.name}
                />
              ))}
            </View>
            {sections.map((section) => {
              return (
                <View key={section.category_header}>
                  {section.category_header !== 'null' && (
                    <Text
                      style={[
                        { textAlign: 'center' },
                        fonts.headerStyle,
                        shared.debugOff
                      ]}
                    >
                      {section.category_header}
                    </Text>
                  )}
                  {!!numColumns && (
                    <FlatList
                      numColumns={numColumns}
                      key={numColumns}
                      data={section.elements}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[{ flex: 1 / 2, maxWidth: tileButtonWidth }]}
                          >
                            <ModuleButton
                              key={index}
                              title={item.label}
                              icon={getEntityTypeIcon(item)}
                              to={item.uri}
                            />
                          </View>
                        )
                      }}
                    />
                  )}
                </View>
              )
            })}
          </ScrollView>
        </View>
      </WithHeader>
    </>
  )
}
