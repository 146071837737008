import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import colors from '../styles/colors'
import { Text, StyleSheet, TouchableOpacity } from 'react-native'
import spacing from '../styles/spacing'
import { dumpUnsavedEntity } from '../store/entity/actions'
import MSFESIcon from './MSFESIcon'
import _ from 'lodash'
import MSFESNestedAddEntity from './MSFESNestedAddEntity'
export const UnsavedEntitiesBanner = () => {
  const dispatch = useDispatch()
  const { unsavedEntities } = useSelector(
    (state) => {
      return {
        unsavedEntities: state.entities.unsaved ?? []
      }
    },
    (a, b) => a.length === b.length
  )

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const [showNestedAdd, setShowNestedAdd] = useState(false)

  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  const [entityTypeEditing, setEntityTypeEditing] = useState(null)
  const [entityEditing, setEntityEditing] = useState(null)

  return (
    <>
      {unsavedEntities.map((unsavedEntity) => {
        return (
          <TouchableOpacity
            key={unsavedEntity.id}
            style={style.notificationBar}
            onPress={() => {
              setEntityTypeEditing(
                entityTypes.find((e) => e.id === unsavedEntity.type.id)
              )
              setEntityEditing(unsavedEntity)
              setShowNestedAdd(true)
            }}
          >
            <Text style={style.notificationBarHeading}>
              Continue unsaved {unsavedEntity.type.label}
            </Text>

            <MSFESIcon
              name={'trash'}
              size={12}
              style={{ paddingVertical: 2 }}
              color={colors.blueDarkest}
              type="font-awesome"
              onPress={() => {
                dispatch(dumpUnsavedEntity({ entity: unsavedEntity }))
              }}
            />
          </TouchableOpacity>
        )
      })}
      {showNestedAdd && entityTypeEditing && (
        <MSFESNestedAddEntity
          beginsWithEntity={entityEditing}
          entityType={entityTypeEditing}
          prefillFieldsInEntity={null}
          tenant={current_user.current_tenant}
          onDismiss={() => {
            setShowNestedAdd(false)
            setEntityEditing(null)
            setEntityTypeEditing(null)
          }}
          // isResumingEntity={} //pass in the unfinished entity here.
          onChangedCallback={() => {
            console.warn('On changed.')
          }}
        />
      )}
    </>
  )
}

const style = StyleSheet.create({
  notificationBar: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.brightOrange,
    flexDirection: 'row'
  },
  notificationBarQueuedUploads: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.lightOrange,
    flexDirection: 'row'
  },
  notificationBarHeading: {
    color: colors.blueDarkest,
    fontSize: 16,
    flex: 1
  },
  view: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.validationErrorBackground
  },
  text: {},
  textDate: {
    fontSize: 10
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold'
  }
})
