import React from 'react'
import { View } from 'react-native'
import WidgetButton from './WidgetButton'
import spacing from '../styles/spacing'

export const WidgetRenderBucket = ({ bucket }) => {
  const itemWidth = 180

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: spacing.m0,
        paddingBottom: spacing.m1,
        overflow: 'hidden'
      }}
    >
      {bucket.map((widget, index) => {
        let color = widget.color?.key
        let doubleSize = widget.render_type?.key === 'chart'
        let isPlanner = widget.position?.key === 'planner'
        let isProgressBar = widget.render_type?.key === 'progress_bar'
        let isResponseTime = widget.render_type?.key === 'response_time'
        let isMultiLine = widget.render_type?.key === 'multi_line'

        return (
          <View
            key={index}
            style={{
              height:
                isProgressBar && isPlanner
                  ? itemWidth - 135
                  : isProgressBar && !isPlanner
                  ? itemWidth
                  : isResponseTime
                  ? itemWidth - 40
                  : isMultiLine
                  ? itemWidth * 3 - 40
                  : doubleSize && !isResponseTime
                  ? itemWidth * 2 + 20
                  : itemWidth,

              marginBottom: spacing.m2_5,
              width:
                isProgressBar && isPlanner
                  ? itemWidth * 2
                  : isProgressBar && !isPlanner
                  ? itemWidth
                  : isResponseTime
                  ? itemWidth * 2
                  : isMultiLine
                  ? itemWidth * 6
                  : doubleSize
                  ? itemWidth * 2
                  : itemWidth,
              overflow: isResponseTime && 'hidden'
            }}
          >
            <WidgetButton widget={widget} color={color} />
          </View>
        )
      })}
    </View>
  )
}
