import _ from 'lodash'
import { DateTime } from 'luxon'

const defaultState = {
  byId: {},
  queuedUploads: []
}

export default function file(state = defaultState, action) {
  switch (action.type) {
    case 'file/show-started':
      break
    case 'file/show-success':
      {
        const { response, id } = action

        const byId = state.byId
        byId[id] = response.data

        state = {
          ...state,
          byId
        }
      }
      break
      case 'file/store-locally':
        {
  
          const queuedUploads = [..._.get(state, "queuedUploads", []), action]
          state = {
            ...state,
            queuedUploads
          }
        }
        break
        case 'file/remove-stored-locally':

try{
  const queuedUploads = _.get(state, "queuedUploads", []).filter(q => q.data.filename !== action.data.file.data.filename);

  state = {
    ...state,
    queuedUploads
  }
}
catch(e) {
  debugger

}

        break;
  
        case 'file/upload-success':
          {
            // remove from the temp store.

          const queuedUploads = _.get(state, "queuedUploads", []).filter(q => q.data.saveAgainstId !== action.localId);
          state = {
            ...state,
            queuedUploads
          }
          }
    case 'file/list-started':
      {


        const propertyPath =
        'default'

        state = {
          ...state,
          [propertyPath]: {
            ...state[propertyPath],
            lastRetrieveStarted: DateTime.local()
          }
        }
      }

      break
      case 'file/list-success':
        {
          const { response, filterString } = action
  
          const propertyPath =
            'default'
  
            const { data, meta } = response;
  
            const shouldAppend = meta.current_page > 1
            
            const prevData = state[propertyPath].data
          state = {
            ...state,
            ...{
              [propertyPath]: {
                ...state[propertyPath],
                data: shouldAppend ? [...prevData, ...data] : data,
                meta,
                lastRetrieveFinished: DateTime.local()
              }
            }
          }
        }
  
        break
  }

  return state
}
