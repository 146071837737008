import React, { useMemo } from 'react'
import { View, Text, StyleSheet, Image, Dimensions } from 'react-native'
import { withTheme, Icon } from 'react-native-elements'
import spacing from '../styles/spacing'
import { TouchableOpacity } from 'react-native'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
import useBetterLinkTo from '../useBetterLinkTo'
import { useIsDesktop } from '../libraries/shouldShowDesktop'

export const iconShadow = {
  shadowColor: colors.iconShadow,
  shadowOffset: {
    width: 0,
    height: 0
  },
  shadowOpacity: 0.09,
  shadowRadius: spacing.m2,
  borderRadius: spacing.borderRadius,
  elevation: 2,
  backgroundColor: colors.white
}

function AspectView(props) {
  const [layout, setLayout] = React.useState(null)

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {}
  const style = [inputStyle, { aspectRatio }]

  if (layout) {
    const { width = 0, height = 0 } = layout

    if (width == 0 && height == 0) {
      // style.push({ width: 300, height: 300 })
    } else {
      if (width === 0) {
        style.push({ width: height * aspectRatio, height })
      } else {
        style.push({ width, height: width * aspectRatio })
      }
    }
  }

  return (
    <View
      {...props}
      style={style}
      onLayout={({ nativeEvent: { layout } }) => setLayout(layout)}
    />
  )
}
export const tileButtonMargin = 12
export const ModuleButton = ({
  to,
  imageComponent = null,
  title,
  icon,
  isHome = false,
  isResponseTime,
  isPlanner,
  isProgressBar,
  ...rest
}) => {
  const linkTo = useBetterLinkTo()

  const overloadedOnPress = () => {
    if (typeof to === 'function') {
      to()
    } else {
      to ? linkTo(to, true) : rest.onPress()
    }
  }

  const isDesktop = useIsDesktop()
  const iconObj = useMemo(
    () =>
      icon && icon?.type !== 'image'
        ? {
            name: icon.name ?? icon,
            size: fonts.largeLabelSize * 2,
            type: icon.type ?? 'font-awesome',
            color: colors.moduleIcon
          }
        : undefined,
    [icon]
  )

  const imageJsx = useMemo(() => {
    const hasAnImage = !!rest.image || !!icon

    const imageSource =
      rest.image || icon?.source
        ? { uri: rest.image || icon?.source }
        : undefined

    const width = isHome && isDesktop ? 144 : 72
    if (imageSource) {
      return (
        <Image
          style={{
            backgroundColor: 'transparent',
            width: width,
            height: width
          }}
          resizeMode={'contain'}
          source={imageSource}
        />
      )
    }
    if (icon) {
      return <Icon {...iconObj} />
    }

    if (imageComponent) {
      return imageComponent
    }
    if (!hasAnImage) {
      return <View style={{ height: width, width: 110 }}></View>
    }
  }, [rest.image, icon, imageComponent])

  const Component = isHome && isDesktop ? AspectView : View

  const { width } = Dimensions.get('window')

  const tileMinSizeDesktop = useMemo(() => {
    let minWidth = 175
    if (width < 1280) {
      minWidth = 220
    } else if (width < 1600) {
      minWidth = 300
    } else {
      minWidth = 400
    }
    return minWidth
  }, [width])

  return (
    <Component
      style={[
        isPlanner
          ? {
              backgroundColor: colors.white,
              width: '100%'
            }
          : {
              padding: 4,

              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              aspectRatio: isHome && isDesktop ? 1 : undefined,
              maxWidth: isHome && isDesktop ? 400 : undefined,
              minWidth: isHome && isDesktop ? tileMinSizeDesktop : undefined
            }
      ]}
    >
      {isPlanner && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: spacing.m1_5
          }}
        >
          <TouchableOpacity
            onPress={overloadedOnPress}
            style={{
              margin: 0,
              flex: 1,
              width: '100%',
              height: '100%',
              padding: !isPlanner ? spacing.m1_5 : spacing.m0,
              backgroundColor: colors.white,
              borderWidth: !isPlanner ? StyleSheet.hairlineWidth : spacing.m0,
              borderColor: isPlanner ? 'transparent' : colors.lighterGray
            }}
            {...rest}
          >
            {imageJsx}
          </TouchableOpacity>
        </View>
      )}

      {isPlanner && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        ></View>
      )}

      {!isPlanner && (
        <TouchableOpacity
          onPress={overloadedOnPress}
          style={{
            margin: spacing.m1_5,
            flex: 1,
            width: '100%',
            height: '100%',
            padding: spacing.m1_5,
            backgroundColor: colors.white,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: colors.lighterGray,
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            ...iconShadow
          }}
          {...rest}
        >
          {imageJsx}
          <View
            style={{
              justifyContent: 'flex-end',
              minHeight: 40,
              width: '100%'
            }}
          >
            <Text style={[fonts.moduleButtonTextStyle]} numberOfLines={2}>
              {!isProgressBar && !isResponseTime && title}{' '}
              {!isProgressBar && rest.icon}
            </Text>
          </View>
        </TouchableOpacity>
      )}
    </Component>
  )
}

export default withTheme(ModuleButton)
