import { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { navWidth } from '../components/DesktopTopNav'
import { tileButtonMargin } from '../components/ModuleButton'
import { useIsDesktop } from '../libraries/shouldShowDesktop'
import { tileButtonWidth } from '../screens/CategoryViewScreen'

export const useColumnHelper = (isHome) => {
  const isDesktop = useIsDesktop()
  const dimensions = useWindowDimensions()

  const numColumns = useMemo(() => {
    if (isHome) {
      if (!isDesktop) {
        return 1
      }

      return 3
    }

    if (!isDesktop) {
      return 2
    }

    const calculatedColumns = Math.floor(
      (dimensions.width - navWidth) / (tileButtonWidth + tileButtonMargin * 2)
    )

    return calculatedColumns
  }, [isHome, isDesktop, dimensions.width])

  return numColumns
}
