import { useEffect, useState } from 'react'
import _ from 'lodash'
import { getFilterString } from '../../store/entity/actions'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'

export const getFriendlyFilters = (_filter) => {
  const filters = []
  Object.keys(_filter).map((key) => {
    if (key === 'detailed') {
      return
    }
    const selectedDisplayName =
      _filter?.[key]?.display_name ?? _filter?.[key]?.label
    if (selectedDisplayName) {
      filters.push(key + ': ' + selectedDisplayName)
    } else {
      const selectedFrom = _filter?.[key]?.from
      const selectedTo = _filter?.[key]?.to

      if (selectedFrom && selectedTo) {
        filters.push(`${key}: ${selectedFrom} - ${selectedTo}`)
      } else {
        const keyArray = _.castArray(_filter?.[key]).map(
          (innerKey) => innerKey?.display_name ?? innerKey?.label ?? innerKey
        )

        filters.push(key + ': ' + keyArray.join(', '))
      }
    }
  })

  return filters
}
export const useGetFriendlyFilter = ({ reportEntity }) => {
  const [friendlyFilter, setFriendlyFilter] = useState(null)
  const [filterPath, setFilterPath] = useState(null)
  const [filter, setFilter] = useState(null)
  useEffect(() => {
    if (reportEntity) {
      const _filter = JSON.parse(reportEntity.filter_body)

      let filters = getFriendlyFilters(_filter)
      setFriendlyFilter(filters)
      setFilter(_filter)

      const filterString = sanitiseFilterString(
        getFilterString(_filter, { detailed: true })
      )

      setFilterPath(`${reportEntity.report_entity_type?.name + filterString}`)
    }
  }, [reportEntity])

  return { friendlyFilter, filter, filterPath }
}
