import React, { useEffect, useState, useCallback, useMemo } from 'react'
import WithHeader from '../components/WithHeader'
import {
  View,
  FlatList,
  RefreshControl,
  useWindowDimensions,
  LayoutAnimation
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import entityActions from '../store/entity/actions'

import { ListItemLinkButton } from '../components/ListItemLinkButton'

import EntityListFilters from '../components/EntityListFilters'

import appActions from '../store/app/actions'

import CurrentlyNoItems from '../components/CurrentlyNoItems'
import GlobalLoading from '../components/GlobalLoading'
import { currentlyNoTemplateDefaults } from './CategoryViewScreen'
import { useIsFocused } from '@react-navigation/native'
import useInterval from '@use-it/interval'
import SplitView from '../components/SplitView'
import { ListItemEntity } from '../components/ListItemEntity'
import { GridItemEntity } from '../components/GridItemEntity'
import EntityAddScreen, { addMode, viewMode } from './EntityAddScreen'
import { shouldShowDesktop } from '../libraries/shouldShowDesktop'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import { getTypeFromUri } from '../helpers/link'
import { showMessage } from 'react-native-flash-message'

const assignee = 'assignee'
const optionMe = 'me'
const optionAll = 'all'

export default function TasksScreen({ route, navigation }) {
  const isFocused = useIsFocused()
  const dispatch = useDispatch()

  const windowDimensions = useWindowDimensions()
  const shouldSplit = shouldShowDesktop(windowDimensions)

  const columns = Math.round(windowDimensions.width / 140)

  const [forceReRenderTicker, setForceReRenderTicker] = useState(0)

  const [queryParams, setQueryParams] = useState({
    page: 1,
    searchText: null,
    filters: null
  })
  const { page, searchText } = queryParams
  const [splitViewState, setSplitViewState] = useState({
    splitDetailScreen: null,
    splitDetailScreenParams: null
  })

  useEffect(() => {
    if (isFocused) {
      setForceReRenderTicker(forceReRenderTicker + 1)

      const paramsSplitId = route.params ? route.params['split.id'] : undefined
      if (
        (paramsSplitId &&
          splitViewState.splitDetailScreenParams?.id !== paramsSplitId) ||
        route.params?.mode !== splitViewState.splitDetailScreenParams?.mode
      ) {
        splitviewNavigate({ id: paramsSplitId }, route.params?.mode ?? viewMode)
      } else if (!paramsSplitId && !route.params?.mode === addMode) {
        onCloseSplit()
      }
    }
  }, [isFocused, route.params])

  const toggleGridOrList = () => {
    const nextGridOrList = 'list'

    dispatch(appActions.setGridOrList(nextGridOrList))
    setForceReRenderTicker(forceReRenderTicker + 1)
  }

  const { taskItemsWrapper, gridOrList } = useSelector((state) => {
    return {
      gridOrList: _.get(state.app, 'gridOrList', 'grid'),
      taskItemsWrapper: _.get(state.entities, `tasks`, {
        data: null,
        meta: null,
        hasSeenData: false
      })
    }
  })

  const { meta, data: taskItems } = taskItemsWrapper

  const onSearchTextChanged = (_searchText) => {
    // flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
    setQueryParams((queryParams) => {
      return { ...queryParams, page: 1, searchText: _searchText }
    })
  }
  const loadMoreItems = () => {
    if (!meta || page < meta.last_page) {
      setQueryParams((queryParams) => {
        return { ...queryParams, page: page + 1, searchText }
      })
    }
  }

  const onCloseSplit = () => {
    setForceReRenderTicker(forceReRenderTicker + 1)
    setSplitViewState({
      ...splitViewState,
      splitDetailScreen: null,
      splitDetailScreenComponent: null
    })
  }

  const splitviewNavigate = (item, mode = null) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)

    if (!mode || mode === 'undefined') {
      mode = viewMode
    }
    if (item) {
      navigation.setParams({ 'split.id': item?.id, mode })
    } else if (mode === addMode) {
      navigation.setParams({ 'split.id': undefined, mode })
    }

    const type =
      route.params && route.params.type
        ? route.params.type
        : getTypeFromUri(item.uri)

    const newSplitViewState = {
      ...splitViewState,
      splitDetailScreen: 'EntityAddScreen',
      splitDetailScreenComponent: EntityAddScreen,
      splitDetailScreenParams: {
        id: item?.id ?? undefined,
        type: type,
        mode: mode,
        page: 1
      }
    }

    setSplitViewState(newSplitViewState)
  }
  const fetchItemsForParams = () => {
    return dispatch(entityActions.listTasks({ page, searchText, filter }))
  }

  const [filter, setFilter] = useState({ assignee: optionMe })
  useEffect(() => {
    fetchItemsForParams()
  }, [queryParams, ''])

  useEffect(() => {
    if (isFocused) {
      fetchItemsForParams()
    }
  }, [isFocused])

  const [refreshing, setRefreshing] = React.useState(false)
  useInterval(() => {
    fetchItemsForParams()
  }, 60000)

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    fetchItemsForParams()
      .then(() => {
        setRefreshing(false)
      })
      .catch(() => {
        setRefreshing(false)
      })
  }, [])

  const addButtonLabel = 'Create Task'

  const currentlyNoLabel = currentlyNoTemplateDefaults({ label: 'tasks' })

  const rightButtonProps = {
    rightButtonAction: toggleFilterOverlay,
    rightButtonIcon: 'sliders'
  }

  const filtersChanged = (newFilters) => {
    setFilter(newFilters)

    setQueryParams((queryParams) => {
      return { ...queryParams, page: 1, filters: newFilters }
    })
    // do a request.
  }

  const [deleteActionPending, setDeleteActionPending] = useState(null)
  const deleteCancelPressed = useCallback(() => {
    setDeleteActionPending(false)
  })

  const deleteConfirmPressed = useCallback((entity, entityType) => {
    setForceReRenderTicker(forceReRenderTicker + 1)
    return dispatch(entityActions.deleteEntity(entity, entityType))
      .then(async () => {
        await fetchItemsForParams()
        showMessage({ message: 'Deleted Successfully', type: 'success' })
        setDeleteActionPending(false)
      })
      .catch((errors) => {
        console.warn('Delete Entity Errors', errors)
        showMessage({ message: errors.message, type: 'danger' })
        throw errors
      })
      .finally(() => {})
  })

  const [filterOverlayState, setFilterOverlayState] = useState(false)
  const toggleFilterOverlay = () => {
    setFilterOverlayState(!filterOverlayState)
  }

  const splitscreenComponent = useMemo(() => {
    const Component = splitViewState.splitDetailScreenComponent
    if (Component) {
      return (
        <Component
          navigation={navigation}
          key={splitViewState.splitDetailScreenParams.id}
          isInSplitView={true}
          onCloseSplit={onCloseSplit}
          route={{ params: splitViewState.splitDetailScreenParams }}
        />
      )
    }
  }, [splitViewState])

  const isCurrentlySplit = !!splitViewState.splitDetailScreen
  const renderAsGrid = gridOrList === 'grid' && !isCurrentlySplit
  const renderItem = useCallback(
    (item, entityType, index, gridOrList, columns) => {
      const active = splitViewState.splitDetailScreenParams?.id == item.id
      if (renderAsGrid) {
        return (
          <GridItemEntity
            entity={item}
            active={active}
            style={{ flex: 1 / columns }}
            entityType={entityType}
            key={index + '_' + forceReRenderTicker}
            onPress={
              shouldSplit ? () => splitviewNavigate(item, viewMode) : undefined
            }
          />
        )
      } else {
        return (
          <ListItemEntity
            entity={item}
            active={active}
            entityType={entityType}
            key={index + '_' + forceReRenderTicker}
            onPress={
              shouldSplit ? () => splitviewNavigate(item, viewMode) : undefined
            }
          />
        )
      }
    },
    [shouldSplit, splitViewState.splitDetailScreenParams?.id, isCurrentlySplit]
  )
  const flatlistKey = renderAsGrid ? columns : 1

  const viewList = useMemo(() => {
    const pageTitle = filter.assignee == optionMe ? 'My Tasks' : 'All Tasks'
    return (
      <WithHeader
        title={pageTitle}
        navigation={navigation}
        onSearchTextChanged={onSearchTextChanged}
        onToggleGridOrList={toggleGridOrList}
        {...rightButtonProps}
      >
        <EntityListFilters
          isVisible={filterOverlayState}
          onBackdropPress={toggleFilterOverlay}
          onFiltersChanged={filtersChanged}
          filtersApplied={filter}
          filtersAvailable={[
            {
              name: 'Show Tasks for:',
              id: assignee,
              options: [
                {
                  label: 'Me Only',
                  key: optionMe,
                  id: optionMe,
                  default: true
                },
                { label: 'Show All', key: optionAll, id: optionAll }
              ]
            }
          ]}
        />
        <ListItemLinkButton
          title={addButtonLabel}
          type="add"
          key={'add'}
          to={'/tasks/add'}
        />
        <View style={{ flex: 1 }}>
          <FlatList
            key={flatlistKey}
            numColumns={flatlistKey}
            contentContainerStyle={{ flex: 1 }}
            ListFooterComponent={
              <View style={{ height: 0, marginBottom: 30 }}></View>
            }
            ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
            extraData={forceReRenderTicker} /* resets the dragged item */
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            keyExtractor={(item) => item.id.toString()}
            onEndReached={loadMoreItems}
            onEndReachedThreshold={1}
            data={taskItems}
            renderItem={({ item, index }) => {
              return renderItem(
                item,
                { id: item.entity_type_id },
                index,
                gridOrList,
                columns
              )
            }}
          />
          {taskItemsWrapper.hasSeenData === false &&
            !taskItemsWrapper?.isError && <GlobalLoading />}
        </View>
      </WithHeader>
    )
  }, [
    route.params,
    filterOverlayState,
    taskItems,
    filter,
    refreshing,
    forceReRenderTicker,
    splitViewState.splitDetailScreenParams?.id
  ])
  return (
    <>
      <SplitView
        isCurrentlySplit={isCurrentlySplit}
        viewList={viewList}
        viewDetail={splitscreenComponent}
      />
      {deleteActionPending && (
        <YesNoCancelDialog
          title="Delete"
          body={'Are you sure you want to delete? This is a permanent action.'}
          yesAction={() => {
            return deleteConfirmPressed(
              deleteActionPending.entity,
              deleteActionPending.entityType
            )
          }}
          noAction={deleteCancelPressed}
          cancelAction={deleteCancelPressed}
        />
      )}
    </>
  )
}
