import React, { useState, useEffect } from 'react'
import { ActivityIndicator, View, Text, Platform, FlatList } from 'react-native'
import { DataTable } from 'react-native-paper'
import CurrentlyNoItems from '../CurrentlyNoItems'
import OverlayHeader from '../OverlayHeader'
import { Overlay } from 'react-native-elements'
import { useDispatch } from 'react-redux'
import {
  listPersonnelLogins,
  updatePersonnelLogin
} from '../../store/admin/logins/actions'
import { cellStyle } from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import MSFESInput from '../MSFESInput'
import spacing from '../../styles/spacing'
import MSFESButton from '../MSFESButton'
import { showMessage } from 'react-native-flash-message'
import _ from 'lodash'
import { SelectAdhoc } from '../fields/SelectAdhoc'
export const LoginsPersonnel = ({ activeSort, searchText }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [data, setData] = useState([])
  useEffect(() => {
    fetchNextPage()
  }, [page, searchText])

  useEffect(() => {
    setPage(1)
  }, [searchText])

  const fetchNextPage = () => {
    dispatch(listPersonnelLogins({ page, searchText })).then((response) => {
      setLoading(false)
      if (page > 1) {
        setData((data) => [...data, ...response.data])
      } else {
        setData(response.data)
      }
      setPages(response.meta.last_page)
    })
  }

  const [editingPerson, setEditingPerson] = useState(null)

  const [dataSorted, setDataSorted] = useState([])
  useEffect(() => {
    setDataSorted(
      _.orderBy(
        data,
        [(i) => ('' + i).trim().toLowerCase()],
        [activeSort.name == 'a_to_z' ? 'asc' : 'desc']
      )
    )
  }, [data, data.length, activeSort])

  const saveChanges = () => {
    setSaving(true)
    dispatch(updatePersonnelLogin({ person: editingPerson }))
      .then((response) => {
        setEditingPerson(false)
        showMessage({
          message: 'Updated.',
          type: 'success'
        })
        setData((data) => {
          const newData = [...data]
          const index = newData.findIndex((el) => el.id === response.data.id)
          newData[index] = response.data
          return newData
        })
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <View style={{ flex: 1 }}>
      {loading && <ActivityIndicator />}
      {!loading && !data.length && <CurrentlyNoItems label="No personnels" />}

      {!!editingPerson && (
        <Overlay
          isVisible={true}
          onBackdropPress={() => setEditingPerson(false)}
          overlayStyle={{ padding: 0, margin: 0 }}
        >
          <View
            style={{
              flex: 1,
              padding: spacing.m1,
              minHeight: Platform.OS === 'ios' ? 330 : 140
            }}
          >
            <OverlayHeader>{editingPerson.name}</OverlayHeader>

            <MSFESInput
              label={'Person'}
              editable={false}
              horizontal
              defaultValue={editingPerson.display_name}
            />
            <MSFESInput
              label={'Email'}
              editable={false}
              horizontal
              defaultValue={editingPerson.email_address}
            />
            <MSFESInput
              label={'Date Of Birth'}
              editable={false}
              horizontal
              defaultValue={editingPerson.date_of_birth?.display_value}
            />
            <MSFESInput
              label={'Member Status'}
              horizontal
              InputComponent={SelectAdhoc}
              defaultValue={editingPerson.member_status1}
              data={{
                field: {
                  field_data: {
                    params: {
                      options: [
                        { key: 'current', label: 'Current' },
                        { key: 'past', label: 'Past' }
                      ]
                    }
                  }
                }
              }}
              onChangeText={(newValue) => {
                setEditingPerson((editingPerson) => ({
                  ...editingPerson,
                  member_status1: newValue
                }))
              }}
            />
            <MSFESButton
              loading={saving}
              onPress={saveChanges}
              title="Save Sign-In Changes"
            />
          </View>
        </Overlay>
      )}
      {!loading && !!data.length && (
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title key={'person'}>Person</DataTable.Title>
            <DataTable.Title key={'email'}>Email</DataTable.Title>
            <DataTable.Title key={'email'}>Date Of Birth</DataTable.Title>
            <DataTable.Title key={'status'}>Status</DataTable.Title>
            <DataTable.Title key={'role'}>Role</DataTable.Title>
          </DataTable.Header>

          <View style={{ flex: 1 }}>
            <FlatList
              data={dataSorted}
              onEndReached={() => {
                if (page < pages) {
                  setPage(page + 1)
                }
              }}
              renderItem={({ item: person, index }) => {
                return (
                  <DataTable.Row
                    key={index}
                    style={{
                      backgroundColor: index % 2 ? 'transparent' : '#f1f1f1'
                    }}
                    onPress={() => setEditingPerson(person)}
                  >
                    <DataTable.Cell key={'name'} style={cellStyle}>
                      <Text>{person.display_name}</Text>
                    </DataTable.Cell>

                    <DataTable.Cell key={'email'} style={cellStyle}>
                      <Text>{person.email_address}</Text>
                    </DataTable.Cell>

                    <DataTable.Cell key={'dob'} style={cellStyle}>
                      <Text>{person.date_of_birth?.display_value}</Text>
                    </DataTable.Cell>

                    <DataTable.Cell key={'enabled'} style={cellStyle}>
                      <Text
                        style={{
                          fontWeight:
                            person.member_status1?.key === 'current'
                              ? 'normal'
                              : 'bold'
                        }}
                      >
                        {person.member_status1?.label}
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell key={'roles'} style={cellStyle}>
                      <Text>{person.role}</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              }}
            />
          </View>
        </DataTable>
      )}
    </View>
  )
}
