
export const getLoadableValueFromPropValue = (propsValue) => {
  let loadValue = null;
  if(propsValue) {
    if(propsValue.id) {
      loadValue = propsValue.id
    } else if(propsValue.offline_id) {
      loadValue = propsValue.offline_id
    } else if (Number.isInteger(propsValue)) {
      loadValue = propsValue
    }
  }


      return loadValue
}