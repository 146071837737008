// libraries
import React, { useState, useEffect } from 'react'
import { View, SafeAreaView, Platform } from 'react-native'
import { Text, Divider } from 'react-native-elements'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

// styles
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import userActions from '../store/user/actions'
import colors from '../styles/colors'
import MSFESButton from '../components/MSFESButton'
import MSFESInput from '../components/MSFESInput'
import { StatusBar } from 'react-native'
import { KeyboardAvoidingView } from 'react-native'
import MSFESIcon from '../components/MSFESIcon'

import { websafeGoBack } from '../libraries/navigation'
import { useLinkTo, useNavigationState } from '@react-navigation/native'
import MSFESHeading from '../components/MSFESHeading'
import useQueryString from '../libraries/querystring'
import MSFESLabel from '../components/MSFESLabel'
import SignInFireman from '../components/SignInFireman'
import SignInLogo from '../components/SignInLogo'
import DisplayErrors from '../components/DisplayErrors'
import { InputTextStyle } from '../components/ListItemAsFieldProps'

export default function ResetPasswordScreen({ route, navigation }) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({ email: '', password: '' })

  const errors = useSelector((state) => state.users.errors.errors)

  useEffect(() => {
    if (errors) {
      setLocalErrors(errors)
    }
  }, [errors])

  const [localErrors, setLocalErrors] = useState(null)

  useEffect(() => {
    dispatch(userActions.clearErrors())
    setLocalErrors(null)
  }, [''])

  const changeHandler = (name, text) => {
    setValues({ ...values, [name]: text })
  }

  const updatePasswordPressed = () => {
    setLoading(true)
    const { password, confirm } = values

    if (password !== confirm) {
      setLocalErrors('Password and Confirm Password must match.')
      setLoading(false)
      return
    }

    if (!password) {
      setLocalErrors('Please enter a Password.')
      setLoading(false)
      return
    }

    dispatch(
      userActions.updatePassword({
        email,
        password,
        password_confirmation: confirm,
        token
      })
    )
      .then(() => {
        navigation.navigate('SigninScreen', {
          email: email,
          message: 'Password updated successfully! Please sign in to continue.'
        })
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const [email] = useQueryString('email', '', route)
  const [token] = useQueryString('token', '', route)
  const [new_account] = useQueryString('new_account', '', route)

  navigation.setOptions({
    title: new_account ? 'Set Password' : 'Reset Password'
  })

  const environment = useSelector((state) => {
    return state.app.environment
  })

  const navState = useNavigationState((state) => state)
  const linkTo = useLinkTo()
  return (
    <SafeAreaView style={[shared.fullHeight, shared.darkView]}>
      <StatusBar barStyle="light-content" />
      <SignInFireman />

      <View style={{ alignItems: 'flex-start' }}>
        <MSFESIcon
          name={'chevron-left'}
          tappable
          dark
          size={24}
          type="font-awesome"
          onPress={() => {
            navigation && websafeGoBack(navigation, navState, linkTo, null)
          }}
        />
      </View>

      {environment !== 'prod' && (
        <View
          style={[
            shared.debugOff,
            { alignItems: 'flex-end', padding: spacing.m2 }
          ]}
        >
          <Text style={{ color: colors.inputLabel }}>env:{environment}</Text>
        </View>
      )}
      <View
        style={[
          shared.mobileWidthView,
          shared.darkView,
          shared.debugOff,
          shared.fullHeight
        ]}
      >
        <SignInLogo />

        <KeyboardAvoidingView
          keyboardVerticalOffset={140}
          contentContainerStyle={[
            shared.debug,
            { flexDirection: 'column', justifyContent: 'center' }
          ]}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <View
            style={[
              shared.debugOff,
              { flexDirection: 'column', justifyContent: 'center' }
            ]}
          >
            <MSFESHeading
              signin
              fontSize={18}
              style={{ marginBottom: spacing.m2 }}
            >
              Set My Password
            </MSFESHeading>
            <MSFESLabel
              dark
              capitalize={false}
              helpText="Password should be at least 8 characters, with letters and at least one number."
              label="Welcome! Please set a password for your account."
            ></MSFESLabel>
            <Divider style={{ marginTop: spacing.m2 }} />
            <DisplayErrors errors={localErrors} dark />
            <MSFESInput
              showLabel={false}
              signin
              defaultValue={values.password}
              secureTextEntry={true}
              style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
              placeholder="Password"
              name="password"
              errorMessage={_.get(errors, 'password', []).join(',')}
              onChangeText={(text) => changeHandler('password', text)}
            />
            <MSFESInput
              showLabel={false}
              signin
              defaultValue={values.confirm}
              secureTextEntry={true}
              placeholder="Confirm Password"
              name="confirm"
              style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
              errorMessage={_.get(errors, 'confirm', []).join(',')}
              onChangeText={(text) => changeHandler('confirm', text)}
            />
            <Divider style={spacing.sectionDivider} />
          </View>
          <MSFESButton
            dark
            primary
            loading={loading}
            title={new_account ? 'Set Password' : 'Set My Password'}
            onPress={updatePasswordPressed}
          />
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  )
}
