import React, { useCallback, useEffect, useState } from 'react'

import { View, StyleSheet, Platform, Linking, Text, Button, TouchableOpacity } from 'react-native'
import { Overlay, ListItem } from 'react-native-elements'
import { Divider } from 'react-native-elements/dist/divider/Divider';

import { useDispatch, useSelector } from 'react-redux'
import { ListItemLinkButton } from './ListItemLinkButton';

import entityTypeActions from '../store/entity-type/actions'

import _ from 'lodash'
import MediaHelper from '../libraries/mediaHelper';
import { showMessage } from 'react-native-flash-message';
import colors from '../styles/colors';

export const topRightPopoutMenuStyle = StyleSheet.create(
  {
    overlayStyle:
    {
      position: "absolute",
      padding: 0,
      right: 20,
      top: 55,
      minWidth: 200
    }
  }
)
export default function ManageEntityTypesActions({ isVisible,onImportEntityType, onBackdropPress, dropdownOverlayStyle }) {

  const dispatch = useDispatch();

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const canModifyEntityTypes = _.get(current_user, `effective_permissions['modify||name:entity types']`, false)

  const listItemContainerStyle = {backgroundColor: colors.lightestGray, borderTopWidth: StyleSheet.hairlineWidth, borderBottomWidth: 0, borderTopColor: colors.lightGray, maxHeight: 48}

  const onImportEntityTypeWrapper = useCallback((mode) => {

    MediaHelper.getMedia(MediaHelper.DocumentType, false).then(async ({ payload, filename}) => {
      try{
        const blob = await MediaHelper.getBlobFromDataURL(payload.uri)
        const body = JSON.parse(await blob.text());
        const bodyAppended = {mode, ...body}

        let skipTypeChecks = false;



        const handleEntityTypeImportedOK = (response) => {
          const message = mode == 'edit' ? "Entity type updated successfully." : "Entity type added successfully"
          showMessage({ message, type: "success" })
        }

        const handleEntityTypeImportedCrash = e => {
          let onlySourceNameErrorsFound = true;

          let friendlyErrors = [];

          Object.keys(e.errors).map(key => {
            let title = key;
            const field = _.get(body.data, key, null);
            const errorBody = e.errors[key][0];

            if(errorBody.indexOf("sourceName is invalid.") !== -1){
              friendlyErrors.push(`${title}: Entity type '${field}' not found (Import type '${field}' first.)`);
            } else {
              onlySourceNameErrorsFound = false;
              friendlyErrors.push(title + ": " + e.errors[key]);

            }
          })

          if(friendlyErrors.length && onlySourceNameErrorsFound && !skipTypeChecks) {
            
            showMessage({ message: "An error occurred when validating linked entity types: " + e.message + "\n" + friendlyErrors.join("\n"), type: "danger", duration: 10000,
            renderCustomContent: ()=> <TouchableOpacity style={{marginTop: 10}} onPress={() => {

              skipTypeChecks = true;
              dispatch(entityTypeActions.upsertEntityType(bodyAppended, skipTypeChecks)).then(handleEntityTypeImportedOK).catch(handleEntityTypeImportedCrash)
            }}><Text style={{fontWeight:"bold", color:"white", textDecorationLine:"underline"}}>Skip Type Checks and Try Again</Text></TouchableOpacity>})
          } else {
            showMessage({ message: "An error occurred: " + e.message + "\n" + friendlyErrors.join("\n"), type: "danger", duration: 10000})

          }
        }
  
        dispatch(entityTypeActions.upsertEntityType(bodyAppended, skipTypeChecks)).then(handleEntityTypeImportedOK).catch(handleEntityTypeImportedCrash)
      }
      catch(e){
        showMessage({ message: "An error occurred: " + e.message, type: "danger"})
      } finally {
        onBackdropPress()

      }

    })
  }, [onImportEntityType])

  return (
    isVisible && <Overlay visible={true} backdropStyle={{opacity: 0}} onBackdropPress={onBackdropPress} overlayStyle={dropdownOverlayStyle}>
      <View style={{marginTop: -2}}>
        {canModifyEntityTypes && <>
          <Divider />
          <ListItemLinkButton containerStyle={listItemContainerStyle} hideAvatar={true} title={`Import Entity Type (as New Type)`} onPress={() => onImportEntityTypeWrapper('add')} />
        </>}
        {canModifyEntityTypes && <>
          <Divider />
          <ListItemLinkButton containerStyle={listItemContainerStyle} hideAvatar={true} title={`Import Entity Type (as Updated Type)`} onPress={() => onImportEntityTypeWrapper('edit')} />
        </>}

      </View>
    </Overlay>

  )
}