import React, { useEffect, useMemo, useState } from 'react'
import { View, LayoutAnimation, Platform, Text } from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import MSFESIcon from '../components/MSFESIcon'

import userActions from '../store/user/actions'
import entityActions from '../store/entity/actions'
import tenantActions from '../store/tenant/actions'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import shared from '../styles/shared'
import colors from '../styles/colors'
import BottomTabNavigator, {
  bottomTabStructure,
  getIconName,
  ROUTE_HOME
} from '../navigators/TabNavigator'

import DrawerItemList from '../components/DrawerItemList'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { getFilterString } from '../store/entity/actions'
import { getSortedEntitiesFromStore, noData } from '../components/HomeContainer'
import useBetterLinkTo from '../useBetterLinkTo'
import MSFESSvgIcon from '../icons/MSFESSvgIcon'
import DesktopTopNav, { navWidth } from '../components/DesktopTopNav'
import MyProfile from '../components/MyProfile'
import { useShowMyDepartmentUI } from '../hooks/useShowMyDepartmentUI'
import MSFESNestedAddEntity from '../components/MSFESNestedAddEntity'
import { updateMode } from '../screens/EntityAddComponent'
import { CustomParentDrawerItem } from './CustomDrawerItem'
import appActions from '../store/app/actions'
import { treeCategoryLink } from '../components/categories/TreeCategoriesMatrix'
import { useActionNavigateTo } from '../hooks/useActionNavigateTo'
import MSFESModal from '../components/MSFESModal'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
import { useChildCategoriesWithPermissionResolver } from '../hooks/useChildCategoriesWithPermissions'

const backgroundColor = colors.mediumGray
export const activeColor = colors.blueDarkest
export const activeLabelColor = colors.white
export const inactiveLabelColor = colors.skyLighter
const collapsedWidth = Platform.OS === 'web' ? 52 : 70
export const baseDrawerLabelStyle = {
  fontWeight: '500',
  flex: 1
}

export const drawerLabelStyle = []

export const selectedDrawerLabelStyle = { color: activeLabelColor }

const RecursiveCustomParentDrawerItem = ({
  item,
  displayCollapsed,
  depth,
  expandedChildren,
  focused,
  animatedToggleExpandedChildren,
  navState
}) => {
  const index = navState?.routes[0]?.state?.routes[0]?.state?.index
  const page = _.get(
    navState,
    `routes[0].state.routes[0].state.routes[${index}].params.id`,
    null
  )

  return [
    <CustomParentDrawerItem
      item={item}
      depth={depth}
      key={item.id}
      focused={focused || item.id == page}
      displayCollapsed={displayCollapsed}
      isExpanded={expandedChildren[depth] === item.id}
      onExpand={
        item.subScreens?.length
          ? () => animatedToggleExpandedChildren(item.id, depth)
          : undefined
      }
    />,
    ...(expandedChildren[depth] === item.id &&
    item.subScreens &&
    item.subScreens.length
      ? item.subScreens.map((itemChild) => {
          if (depth > 1) {
            let screens = []
            screens.push(
              <CustomParentDrawerItem
                expandedChildren={expandedChildren}
                onExpand={null}
                item={itemChild}
                navState={navState}
                key={itemChild.id}
                animatedToggleExpandedChildren={animatedToggleExpandedChildren}
                displayCollapsed={displayCollapsed}
                depth={depth + 1}
                focused={false}
              />
            )
            return screens
          } else {
            return (
              <RecursiveCustomParentDrawerItem
                expandedChildren={expandedChildren}
                item={itemChild}
                navState={navState}
                key={itemChild.id}
                animatedToggleExpandedChildren={animatedToggleExpandedChildren}
                displayCollapsed={displayCollapsed}
                depth={depth + 1}
                focused={false}
              />
            )
          }
        })
      : [])
  ]
}

const categoryToNavButton = (
  category,
  linkTo,
  categories,
  actionNavigateTo,
  categoryResolver
) => {
  const { categoriesWithChildCategories: children } = categoryResolver({
    parentId: category.id,
    isHome: false
  })

  return {
    id: category.id,
    label: category.category_name,
    onPress: () => {
      linkTo(treeCategoryLink(category, actionNavigateTo))
    },
    focusedWhen: (secondaryRoutes) => {
      if (!secondaryRoutes.length) {
        return
      }
      const lastInRoute = secondaryRoutes[secondaryRoutes.length - 1]
      const focused =
        secondaryRoutes.length &&
        lastInRoute.name === 'CategoryView' &&
        lastInRoute.params.id == category.id

      return focused
    },
    // icon: () => <MSFESIcon name={category.icon} type="font-awesome" />,
    subScreens: [
      ...(children
        ? children.map((childCategory) =>
            categoryToNavButton(
              childCategory,
              linkTo,
              categories,
              actionNavigateTo,
              categoryResolver
            )
          )
        : [])
    ]
  }
}
const CustomDrawerContent = (props) => {
  const dispatch = useDispatch()
  const { navigation, route, displayCollapsed, setDisplayCollapsed } = props

  const linkTo = useBetterLinkTo(navigation)
  const { actionNavigateTo } = useActionNavigateTo()

  const { entityType: entityTypeCategories } = useDiscoverEntityEntityType(
    null,
    null,
    'home_screen_category'
  )

  const { entityType: entityTypeTreeCategory } = useDiscoverEntityEntityType(
    null,
    null,
    'tree_category'
  )
  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const {
    // categoriesListWrapper,
    user,
    screenButtonsWrapper,
    treeCategoriesListWrapper
  } = useSelector((appState) => {
    const args = { detailed: true },
      filter = {}

    const treeCategoriesListWrapper = getSortedEntitiesFromStore(
      entityTypeTreeCategory,
      appState
    )

    const filterString = sanitiseFilterString(getFilterString(filter, args))
    // const categoriesListWrapper =
    //   entityTypeCategories &&
    //   _.get(
    //     appState.entities,
    //     `[${entityTypeCategories.name + filterString}]`,
    //     noData
    //   )

    const tenants = _.get(appState, 'tenants.tenants.data', [])
    const screenButtonsWrapper =
      entityTypeScreenButtons &&
      _.get(
        appState.entities,
        `[${entityTypeScreenButtons.name + filterString}]`,
        noData
      )

    const user = _.get(appState, 'users.user', {})

    return {
      // categoriesListWrapper,
      user,
      tenants,
      screenButtonsWrapper,
      treeCategoriesListWrapper
    }
  })

  const [isTenantAdmin, setIsTenantAdmin] = useState(false)

  const { expandedChildren } = useSelector((appState) => {
    return { expandedChildren: appState.app?.expandedChildren ?? [] }
  })

  const setExpandedChildren = (newExpandedChildren) => {
    dispatch(appActions.setDrawerExpandedChildren(newExpandedChildren))
  }

  // const [expandedChildren, setExpandedChildren] = useState(null)
  const animatedToggleExpandedChildren = (newChildrenId, depth) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)

    const _expandedChildren = _.castArray(expandedChildren)

    _expandedChildren[depth] =
      _expandedChildren[depth] === newChildrenId ? null : newChildrenId

    setExpandedChildren(_expandedChildren)
  }

  const [showMyProfile, setShowMyProfile] = useState(false)

  const dismissMyProfile = () => {
    setShowMyProfile(false)
  }

  useEffect(() => {
    let hasTenantAdmin = false
    user.roles.map((r) => {
      if (r.name === 'Tenant Admin' || r.name === 'Trainer Admin') {
        hasTenantAdmin = true
      }
    })

    setIsTenantAdmin(hasTenantAdmin)

    dispatch(tenantActions.listTenants())
  }, [user])

  const args = { detailed: true },
    filter = {}

  const homeScreenButtons = useMemo(
    () =>
      screenButtonsWrapper
        ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
            (r) => _.get(r, 'position.label', null) === 'Home Screen'
          )
        : [],
    [screenButtonsWrapper]
  )
  useEffect(() => {
    entityTypeCategories &&
      dispatch(
        entityActions.listEntity(entityTypeCategories, {
          ...args,
          filter,
          isFullRefresh: true
        })
      )
    entityTypeScreenButtons &&
      dispatch(
        entityActions.listEntity(entityTypeScreenButtons, {
          ...args,
          per_page: 120,
          filter,
          isFullRefresh: true
        })
      )
  }, [entityTypeCategories, entityTypeScreenButtons])

  const navState = navigation.dangerouslyGetState()
  // routes[0] = "FireHR" / Tab navigator
  // next routes[0] = "Home" / place where the ad-hoc routes live
  const secondaryRoutes = _.get(
    navState,
    'routes[0].state.routes[0].state.routes',
    []
  )

  const lastRoute = secondaryRoutes.length
    ? secondaryRoutes[secondaryRoutes.length - 1].name
    : null

  const categoryResolver = useChildCategoriesWithPermissionResolver()
  const categories = treeCategoriesListWrapper?.data
  const { categoriesWithChildCategories } = categoryResolver({
    parentId: categories?.find((t) => t.category_name === 'Home')?.id
  })

  const structure = useMemo(() => {
    const subScreens = {
      [ROUTE_HOME]: [
        {
          label: 'Dashboard',
          onPress: () => {
            navigation.navigate('Home', { screen: 'Dashboard' })
          },
          id: 'dashboard',
          icon: () => (
            <View style={{ width: 20 }}>
              <MSFESIcon
                size={18}
                name={'tachometer'}
                type="font-awesome"
                color={inactiveLabelColor}
              />
            </View>
          ),
          focusedWhen: (secondaryRoutes) => {
            if (!secondaryRoutes.length) {
              return
            }
            const lastInRoute = secondaryRoutes[secondaryRoutes.length - 1]
            const focused =
              secondaryRoutes.length && lastInRoute.name === 'Dashboard'

            return focused
          }
        },
        ...categoriesWithChildCategories.map((category) =>
          categoryToNavButton(
            category,
            linkTo,
            categories,
            actionNavigateTo,
            categoryResolver
          )
        )
      ]
    }

    return bottomTabStructure.map((tab) => {
      const subscreensThisTab = _.get(subScreens, tab.name, [])

      return {
        id: tab.id,
        label: tab.name,
        onPress: (e) => tab.onPress(e, navigation),
        focusedWhen: tab.desktopFocusedWhen,
        icon: ({ focused }) => {
          return (
            <View style={{ width: 24, height: 28 }}>
              <MSFESSvgIcon
                name={getIconName(tab.name)}
                fill="transparent"
                stroke={focused ? activeLabelColor : inactiveLabelColor}
                strokeWidth="2"
                height="24"
                width="100%"
                viewBox="0 0 40 40"
                preserveAspectRatio="xMinYMin slice"
              />
            </View>
          ) // getTabIcon(getIconName(tab.name))
        },
        subScreens: subscreensThisTab
      }
    })
  }, [bottomTabStructure, categoriesWithChildCategories])

  const [
    showMyDepartment,
    setShowMyDepartment,
    tenantEntityType,
    currentTenantEntity
  ] = useShowMyDepartmentUI(user)

  return (
    <DrawerContentScrollView
      {...props}
      style={{ backgroundColor: backgroundColor }}
      contentContainerStyle={[
        shared.debugOff,
        {
          flexDirection: 'column',
          flex: 1,
          width: displayCollapsed ? collapsedWidth : null
        }
      ]}
    >
      <View style={{ flexDirection: 'row' }}>
        <View style={[{ flex: 1 }, shared.debugOff]}>
          <DrawerItem
            style={[{ margin: 0, borderRadius: 0, paddingLeft: 8 }]}
            label="Collapse panel"
            labelStyle={drawerLabelStyle}
            inactiveTintColor={inactiveLabelColor}
            activeTintColor={activeLabelColor}
            icon={({ focused }) => (
              <MSFESIcon
                name={
                  displayCollapsed
                    ? 'chevron-circle-right'
                    : 'chevron-circle-left'
                }
                size={20}
                color={focused ? activeLabelColor : inactiveLabelColor}
                type="font-awesome"
              />
            )}
            onPress={() => {
              animatedToggleExpandedChildren(null)
              setDisplayCollapsed(!displayCollapsed)
            }}
          />
        </View>
      </View>

      <View
        style={[
          shared.debugOff,
          {
            flex: 1
            // backgroundColor:"blue"
          }
        ]}
      >
        <DrawerItemList {...props} />
        {structure.map((s) => {
          const focused = s.focusedWhen(navState)
          return (
            <RecursiveCustomParentDrawerItem
              expandedChildren={expandedChildren}
              key={s.id}
              displayCollapsed={displayCollapsed}
              animatedToggleExpandedChildren={animatedToggleExpandedChildren}
              item={s}
              depth={0}
              focused={focused}
              navState={navState}
            />
          )
        })}

        {homeScreenButtons.map((homeScreenButton, index) => {
          return (
            <DrawerItem
              key={'hsb' + index}
              style={[
                {
                  margin: 0,
                  borderRadius: 0,
                  paddingLeft: 8,
                  backgroundColor: 'transparent'
                }
              ]}
              labelStyle={drawerLabelStyle}
              inactiveTintColor={inactiveLabelColor}
              activeTintColor={activeLabelColor}
              label={homeScreenButton.name}
              focused={false}
              icon={({ focused }) => (
                <View style={{ width: 20 }}>
                  <MSFESIcon
                    size={18}
                    color={focused ? activeLabelColor : inactiveLabelColor}
                    name={homeScreenButton.icon}
                    type="font-awesome"
                  />
                </View>
              )}
              onPress={() => {
                linkTo(homeScreenButton.navigate_to)
              }}
            />
          )
        })}

        <DrawerItem
          style={[{ margin: 0, paddingLeft: 8, borderRadius: 0 }]}
          inactiveTintColor={inactiveLabelColor}
          activeTintColor={activeLabelColor}
          activeBackgroundColor={activeColor}
          label="Documents"
          focused={
            ['DocumentList', 'DocumentView'].includes(lastRoute) &&
            navState.routes[0].state.index == 0
          }
          icon={({ focused }) => (
            <View style={{ width: 20 }}>
              <MSFESIcon
                color={focused ? activeLabelColor : inactiveLabelColor}
                name="folder"
                size={18}
                type="font-awesome"
              />
            </View>
          )}
          onPress={() =>
            navigation.navigate('Home', {
              screen: 'DocumentList',
              params: { topLevelPath: 'none' }
            })
          }
        />
        <DrawerItem
          style={[{ margin: 0, paddingLeft: 8, borderRadius: 0 }]}
          labelStyle={drawerLabelStyle}
          activeBackgroundColor={activeColor}
          inactiveTintColor={inactiveLabelColor}
          activeTintColor={activeLabelColor}
          label="My Profile"
          onPress={() =>
            setTimeout(() => {
              setShowMyProfile(true)
            }, 1)
          }
          icon={({ focused }) => (
            <View style={{ width: 20 }}>
              <MSFESIcon
                color={focused ? activeLabelColor : inactiveLabelColor}
                name="id-card"
                size={16}
                type="font-awesome"
              />
            </View>
          )}
        />

        {isTenantAdmin && (
          <DrawerItem
            style={[{ margin: 0, paddingLeft: 8, borderRadius: 0 }]}
            labelStyle={drawerLabelStyle}
            activeBackgroundColor={activeColor}
            inactiveTintColor={inactiveLabelColor}
            activeTintColor={activeLabelColor}
            label="My Department"
            onPress={() =>
              setTimeout(() => {
                setShowMyDepartment(true)
              }, 1)
            }
            icon={({ focused }) => (
              <View style={{ width: 20 }}>
                <MSFESIcon
                  color={focused ? activeLabelColor : inactiveLabelColor}
                  name="building"
                  size={20}
                  type="font-awesome"
                />
              </View>
            )}
          />
        )}

        <DrawerItem
          style={[{ margin: 0, paddingLeft: 8, borderRadius: 0 }]}
          activeBackgroundColor={activeColor}
          labelStyle={drawerLabelStyle}
          label="Sign Out"
          inactiveTintColor={inactiveLabelColor}
          activeTintColor={activeLabelColor}
          icon={({ focused }) => (
            <View style={{ width: 20 }}>
              <MSFESIcon
                size={18}
                color={focused ? activeLabelColor : inactiveLabelColor}
                name="sign-out"
                type="font-awesome"
              />
            </View>
          )}
          onPress={() => {
            dispatch(userActions.signout())
          }}
        />

        {!!showMyProfile && (
          <MyProfile
            navigation={navigation}
            route={route}
            onDismiss={dismissMyProfile}
          />
        )}

        {false && (
          <MSFESModal title="MFA">
            <Text>MFA</Text>
          </MSFESModal>
        )}

        {showMyDepartment && !!tenantEntityType && (
          <MSFESNestedAddEntity
            navigation={navigation}
            entityType={tenantEntityType}
            tenant={user.tenant_id}
            mode={updateMode}
            id={currentTenantEntity?.id}
            onDismiss={() => setShowMyDepartment(false)}
            shouldDismissOnSaved={false}
            onChangedCallback={() => {
              setShowMyDepartment(false)
            }}
          />
        )}
      </View>
    </DrawerContentScrollView>
  )
}

const Drawer = createDrawerNavigator()

export default function DesktopDrawerNavigator({ navigation }) {
  const [displayCollapsed, setDisplayCollapsed] = useState(false)

  const setDisplayCollapsedAnimate = (newState) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
    setDisplayCollapsed(newState)
  }

  return (
    <View style={{ flex: 1 }}>
      <DesktopTopNav navigation={navigation} />
      <View style={{ flex: 1 }}>
        <Drawer.Navigator
          screenOptions={{
            drawerType: 'permanent'
          }}
          defaultStatus="open"
          overlayColor={'transparent'}
          drawerType={'permanent'}
          drawerStyle={
            displayCollapsed ? { width: collapsedWidth } : { width: navWidth }
          }
          openByDefault
          drawerContent={(props) => (
            <CustomDrawerContent
              displayCollapsed={displayCollapsed}
              setDisplayCollapsed={setDisplayCollapsedAnimate}
              {...props}
            />
          )}
        >
          <Drawer.Screen
            name="FireHR"
            component={BottomTabNavigator}
            focused={false}
            options={{
              drawerIcon: () => <MSFESIcon name="home" type="font-awesome" />,
              hidden: true
            }}
          />
        </Drawer.Navigator>
      </View>
    </View>
  )
}
