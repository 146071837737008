// libraries
import React from 'react'
import { View } from 'react-native'
import { Divider, Text } from 'react-native-elements'
// styles
import textStyles from '../styles/text'

//actions

// components

export const ModalHeader = (props) => {
  return (
    <View>
      <Text h4 style={[textStyles.modalHeader]}>
        {props.children}
      </Text>
      <Divider />
    </View>
  )
}

export default ModalHeader
