import api from '../../data/api'

const promiseRepository = {}
const listTenants = (args = {}) => {
  const { page = 1 } = args
  return (dispatch) => {
    const route = '/tenant?page=' + page

    if (promiseRepository[route]) {
      return promiseRepository[route]
    }
    dispatch({
      type: 'tenant/list-started'
    })
    const p = api
      .get(route)
      .then(async (response) => {
        delete promiseRepository[route]
        await dispatch({
          type: 'tenant/list-success',
          args: { page },
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'tenant/list-error',
          error
        })

        throw error
      })

    promiseRepository[route] = p
    return p
  }
}
export default { listTenants }
