import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import colors from '../styles/colors'
import spacing from '../styles/spacing'
import MSFESIcon from './MSFESIcon'
import { headerTextStyle } from './WithHeader'
import { useSelector } from 'react-redux'
import { getNavColor } from './DesktopTopNav'
import Mustache from 'mustache'
import { flattenEntity } from '../helpers/entity'
import { updateMode } from '../screens/EntityAddComponent'

export const sublabelTextStyle = {
  color: colors.brightOrange,
  textAlign: 'center',
  fontSize: 16,
  fontWeight: 'bold'
}
const EntityAddHeader = (props) => {
  const {
    rightButtonRef,
    dropdownVisibilityState,
    entityType,
    entity,
    mode
  } = props

  const { environment } = useSelector((state) => {
    const environment = state.app?.environment ?? null
    return { environment }
  })

  const navColor = getNavColor(environment)

  const formtitleValue = Mustache.render(
    `${entityType?.object_data?.formtitle ?? ''}`,
    {
      ...flattenEntity(entity, false)
    }
  )

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column'
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#eee',
          backgroundColor: navColor
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: spacing.m1
          }}
        >
          <View style={{ justifyContent: 'center', marginLeft: spacing.m2 }}>
            <Text style={[headerTextStyle, { color: colors.white }]}>
              {formtitleValue}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {mode === updateMode && (
            <Text style={{ color: colors.white }}>Edit Mode</Text>
          )}
        </View>
        <View style={{ alignItems: 'flex-end' }}>
          {!!props.buttons && <>{props.buttons}</>}
        </View>

        <View
          ref={rightButtonRef}
          style={{
            backgroundColor: dropdownVisibilityState
              ? colors.lightestGray
              : undefined,
            marginVertical: 4,
            borderTopLeftRadius: spacing.borderRadius,
            borderTopRightRadius: spacing.borderRadius,
            paddingBottom: 4
          }}
        >
          <View
            style={{
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 56,
              paddingLeft: 2,
              backgroundColor: dropdownVisibilityState
                ? colors.white
                : colors.white,
              borderRadius: spacing.borderRadius
            }}
          >
            <MSFESIcon
              tappable
              name={props.rightButtonIcon}
              size={24}
              color={navColor}
              onPress={props.rightButtonAction}
              type="font-awesome"
            />
          </View>
        </View>

        <View style={{ position: 'relative', justifyContent: 'center' }}>
          <MSFESIcon
            tappable
            name={'times'}
            size={24}
            color={colors.white}
            onPress={props.onClose}
            type="font-awesome"
          />
        </View>
      </View>
      <View style={{ flex: 1 }}>{props.children}</View>
    </View>
  )
}
export default EntityAddHeader
