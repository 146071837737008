import { DateTime } from 'luxon'

export const flattenEntity = (entity, forDisplay = false) => {
  if (!entity) {
    return {}
  }

  const fieldProperties = entity.type
    ? (entity.type?.fields ?? []).map((f) => ({
        property: f.field_data.property,
        type: f.field_data.type
      }))
    : Object.keys(entity).map((key) => ({ property: key, type: 'unknown' }))

  return Object.fromEntries(
    fieldProperties.map(({ property, type }) => {
      const entityKey = property
      // take rich objects, try to pull out a value
      let value = entity[entityKey]
      let transformedValue = undefined

      /*
    If it's a simple object, return it.
    If it's a 'complex' object, try to return the value, then the label.
    If it's an array, and it's empty, return an empty string
    If it's anything else, throw an error for now.
    */

      // set sensible defaults for missing entries.
      if (!forDisplay) {
        //not going to render it
        if (typeof value === 'undefined') {
          //and it hasn't been filled
          switch (type) {
            case 'year-entry':
            case 'number-entry':
            case 'number-slider':
              value = 0 //add something calculable
              break
          }
        }
      }

      const dontTransform = ['_fieldsInAlarmState', '_alarmStateColors']

      if (dontTransform.includes(property)) {
        transformedValue = value
      } else if (type === 'date-time-picker') {
        transformedValue = value?.value
          ? DateTime.fromISO(value.value).toSeconds() * 1000
          : value
      } else if (['date-picker'].includes(type)) {
        transformedValue = value?.value
          ? DateTime.fromISO(
              value.year + '-' + value.month + '-' + value.day
            ).toSeconds() * 1000
          : value
      } else if (['time-picker'].includes(type)) {
        transformedValue = value?.value
          ? DateTime.fromISO(value.value).toSeconds() * 1000
          : value
      } else if (['boolean', 'number', 'string'].includes(typeof value)) {
        if (typeof value === 'boolean' && forDisplay) {
          transformedValue = value ? 'YES' : 'NO'
        } else {
          transformedValue = value
        }
      } else {
        if (value !== null && value !== undefined) {
          if (value.value !== null && value.value !== undefined) {
            try {
              const jsDate = DateTime.parse('${value.value}')
              transformedValue = jsDate
            } catch (e) {
              transformedValue = value.value
            }
          } else if (value.label && forDisplay) {
            transformedValue = value.label
          } else if (value.display_name && forDisplay) {
            transformedValue = value.display_name
          } else if (value.display_value && forDisplay) {
            transformedValue = value.display_value
          } else if (value.id) {
            transformedValue = value.id
          } else if (value.label) {
            transformedValue = value.label
          } else if (Array.isArray(value) && value.length === 0) {
            transformedValue = ''
          } else {
            if (Array.isArray(value) && value.length == 0) {
              transformedValue = ''
            } else {
              if (Array.isArray(value) && value.length == 0) {
                if (value[0].label) {
                  value[0].label
                }
              } else if (
                Array.isArray(value) &&
                value.length &&
                value[0] &&
                value[0].label
              ) {
                if (value[0].label) {
                  transformedValue = value.map((v) => v.label)

                  if (forDisplay) {
                    transformedValue = transformedValue.join(', ')
                  }
                }
              } else if (
                Array.isArray(value) &&
                value.length &&
                value[0] &&
                value[0].offline_id
              ) {
                // this is a photo or document.
                // unwrap the array, return the first, the consumer can handle it from here.
                transformedValue = value[0]
              } else if (
                Array.isArray(value) &&
                value.length &&
                value[0] &&
                value[0].display_name
              ) {
                if (value[0].display_name) {
                  transformedValue = value.map((v) => v.display_name)

                  if (forDisplay) {
                    transformedValue = transformedValue.join(', ')
                  }
                }
              } else if (transformedValue == undefined) {
                if (typeof value === 'object') {
                  transformedValue = Object.keys(value)
                    .map((key) => {
                      return key + ': ' + value[key]
                    })
                    .join(', ')
                } else {
                  transformedValue = value
                }
              }
            }
          }
        } else {
          transformedValue = forDisplay ? '' : null
        }
      }

      return [entityKey, transformedValue]
    })
  )
}

export const simpleEntityRoute = (entityTypeName) => {
  const path = `${entityTypeName}`
  return path
}
