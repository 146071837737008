import React, { useMemo } from 'react'
import { headerStyles } from '../../components/OverlayHeader'

import _ from 'lodash'

import { useSelector } from 'react-redux'
import { ListItemEntity } from '../../components/ListItemEntity'
import { ScrollView, Text, View } from 'react-native'
import { DateTime } from 'luxon'
import fonts from '../../styles/fonts'
import spacing from '../../styles/spacing'

export const getColor = (entity, entityType) => {
  if (entityType?.object_data?.calendar_color_field) {
    return _.get(
      entity,
      entityType.object_data?.calendar_color_field?.field_data?.property,
      _.get(
        entity.object_data,
        entityType.object_data?.calendar_color_field?.field_data?.property,
        undefined
      )
    )
  } else {
    return _.get(entityType, 'object_data.calendar_color', undefined)
  }
}

export const getTimeFromTimestamp = (timestamp) => {
  if (timestamp) {
    let timestampWorking = timestamp.value ? timestamp.value : timestamp
    const parts = timestampWorking.replace(' ', 'T').split('T')
    const time = parts[1]

    const removeOffset = time.split('+')[0]

    const removeMilliseconds = removeOffset.split('.')[0]
    return removeMilliseconds
  }
  return null
}

const canShowEntityOnCalendarsSelected = (entityType, calendarsVisible) => {
  if (calendarsVisible?.find((c) => c.display_name === 'All')) {
    return true
  }
  const calendarsEntityTypeAppearsOn =
    entityType?.object_data?.visible_on_calendar

  if (calendarsEntityTypeAppearsOn) {
    let hasMatch = false
    calendarsEntityTypeAppearsOn.forEach((calendarAppearsOn) => {
      let enabledCalendarMatch = calendarsVisible?.find(
        (c) => c.id === calendarAppearsOn.id
      )
      if (enabledCalendarMatch) {
        hasMatch = true
      }
    })

    if (hasMatch) {
      return true
    }
  }
}

const getAgendaItem = (entity, entityType, date, calendarsVisible) => {
  if (!entity) {
    console.warn('No entity')
    return null
  }

  const label = _.get(entity, 'display_name', '')

  if (!canShowEntityOnCalendarsSelected(entityType, calendarsVisible)) {
    return null
  }
  if (
    entityType?.object_data?.start_datetime_field &&
    entityType?.object_data?.end_datetime_field
  ) {
    // can go on agenda.
    const start_time = getTimeFromTimestamp(
      _.get(
        entity,
        entityType.object_data?.start_datetime_field?.field_data?.property,
        ''
      ) ||
        _.get(
          entity?.object_data,
          entityType.object_data?.start_datetime_field?.field_data?.property,
          ''
        )
    )
    const end_time = getTimeFromTimestamp(
      _.get(
        entity,
        entityType.object_data?.end_datetime_field?.field_data?.property,
        ''
      ) ||
        _.get(
          entity?.object_data,
          entityType.object_data?.end_datetime_field?.field_data?.property,
          ''
        )
    )

    const color = getColor(entity, entityType)

    const agendaItem = {
      id: entity.id,
      entity: entity,
      start: `${date} ${start_time}`,
      end: `${date} ${end_time}`,
      title: label,
      color,
      summary: label
    }
    return agendaItem
  } else if (entity.created_at || entity.virtual_event_created_date) {
    const start_time = getTimeFromTimestamp(
      entity.virtual_event_created_date ?? entity.created_at
    )

    if (!label) {
      return null
    }
    const color = getColor(entity, entityType)

    const agendaItem = {
      id: entity.id,
      entity: entity,
      start: `${date} ${start_time}`,
      end: `${date} ${start_time}`,
      title: label,
      color,
      summary: label
    }
    return agendaItem
  }
  return null
}
export const organizeAgendaItems = (
  relevantEntities,
  virtualEvents,
  date,
  entityTypes,
  calendarsVisible = null,
  excludeAllDay = false
) => {
  const agendaItems = []

  if (virtualEvents) {
    virtualEvents?.map((ve) => {
      const entityType = entityTypes.find((e) => e.id === ve.entity_type_id)
      const entity = ve.entity

      const agendaItem = getAgendaItem(
        entity,
        entityType,
        date,
        calendarsVisible
      )

      const isAllDay = entityType?.object_data?.all_day_entity

      if (!(isAllDay && excludeAllDay)) {
        if (agendaItem) {
          agendaItems.push(agendaItem)
        }
      }
    })
  }
  if (relevantEntities) {
    relevantEntities.map((re) => {
      const entityType = entityTypes.find((e) => e.id === re.entity_type_id)

      const agendaItem = getAgendaItem(re, entityType, date, calendarsVisible)

      const isAllDay = entityType?.object_data?.all_day_entity
      if (!(isAllDay && excludeAllDay)) {
        if (agendaItem) {
          agendaItems.push(agendaItem)
        }
      }
    })
  }

  return _.sortBy(agendaItems, (a) => a.start)
}
export const CalendarEntityList = ({
  isDashboard,
  date,
  onEntityClicked,
  calendarsVisible
}) => {
  const { byDate, entityTypes } = useSelector((appState) => {
    return {
      byDate: appState.calendar.byDate,
      entityTypes: appState.entityTypes?.data?.data ?? []
    }
  })

  const { agendaItems } = useMemo(() => {
    const formattedDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd')
    const dateObject = byDate[formattedDate]

    if (date) {
      return {
        relevantEntities: dateObject?.entities,
        virtualEvents: dateObject?.virtualEvents,
        agendaItems: {
          [date]: organizeAgendaItems(
            dateObject?.entities,
            dateObject?.virtualEvents,
            date,
            entityTypes,
            calendarsVisible
          )
        }
      }
    }
    return {
      relevantEntities: [],
      virtualEvents: [],
      agendaItems: {}
    }
  }, [date, byDate, entityTypes, calendarsVisible])

  const today = Object.entries(agendaItems)?.[0]?.[1] ?? []

  const dateIso = date ? DateTime.fromISO(date) : DateTime.local()
  const validDate = dateIso.isValid ? dateIso : DateTime.local()
  const dayOfWeek = validDate.toFormat('cccc')

  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <View
        style={{
          flexDirection: 'column',
          flex: 1
        }}
      >
        <View>
          <Text
            style={
              isDashboard
                ? [
                    headerStyles.plannerTextStyle,
                    fonts.headerStyle,
                    {
                      fontSize: fonts.buttonLabelSize,
                      marginRight: spacing.m13,
                      marginLeft: spacing.m1_5 - 5
                    }
                  ]
                : headerStyles.textStyle
            }
          >
            {isDashboard ? `${dayOfWeek} Planner` : 'Schedule'}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <ScrollView>
            {today?.map((event, index) => {
              const { entity } = event

              return (
                entity && (
                  <ListItemEntity
                    isDashboard={isDashboard}
                    date={date}
                    entity={entity}
                    active={false}
                    entityTypeId={entity.entity_type_id}
                    key={index}
                    onPress={() => onEntityClicked(entity)}
                  />
                )
              )
            })}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}
