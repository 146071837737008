import { Platform } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import * as DocumentPicker from 'expo-document-picker'
import md5 from 'md5'
import mime from 'mime'
import pathParse from 'path-parse'
import AsyncStorage from '@react-native-community/async-storage'
const MediaType = 'audiovideo'
const ImageType = 'image'
const DocumentType = 'document'
const PDFType = 'pdf'

function get_mime_extension(mimeType) {
  return mime.getExtension(mimeType)
}
function get_url_extension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

const MediaHelper = {
  get_url_extension,
  get_mime_extension,
  getBlobFromDataURL: async (dataURL) => {
    const blob = await fetch(dataURL).then((res) => res.blob())
    const payload = blob
    return payload
  },
  getMedia: (mediaType, returnReference = true, forceCamera = false) => {
    const offline_id = uuidv4()

    // eslint-disable-next-line no-async-promise-executor
    const p = new Promise(async (resolve, reject) => {
      let result
      let filename

      try {
        switch (mediaType) {
          case PDFType:
            result = await DocumentPicker.getDocumentAsync({
              type: '*/*'
            })

            filename = _.get(result, 'file.name', 'file')
            break
          case MediaType:
          case DocumentType:
            {
              result = await DocumentPicker.getDocumentAsync({
                type: '*/*'
              })

              const localFilename = _.get(result, 'file.name', 'file')
              filename = localFilename
            }

            break
          case ImageType:
            // m1 issue: https://github.com/react-native-image-picker/react-native-image-picker/issues/1541
            if (forceCamera) {
              const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

              if (permissionResult.granted === false) {
                alert('Please allow camera access to continue.')
                return
              }
              result = await ImagePicker.launchCameraAsync({
                allowsEditing: true,
                aspect: [4, 3],
                quality: 0.5,
                base64: true
              })
            } else {
              result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 0.5,
                base64: true
              })
            }

            if (result.cancelled) {
              throw new Error('User cancelled.')
            }
            // const startOfBase64 = result.uri.substring(result.uri.indexOf(":") + 1, result.uri.indexOf(";"));
            // const extension = mime.getExtension(startOfBase64)

            // const pathElements = pathParse(result.uri);
            // filename = pathElements.name + "." + pathElements.ext;
            // payload = result.base64;

            break
        }
      } catch (err) {
        console.warn(err)
        reject(err)
        throw err
      }

      let payload

      const pathElements = pathParse(result.uri)

      result.type = mime.getType(result.uri) // result.type + '/' + ext

      if (Platform.OS === 'web') {
        const startOfBase64 = result.uri.substring(
          result.uri.indexOf(':') + 1,
          result.uri.indexOf(';')
        )
        const extension = mime.getExtension(startOfBase64)
        filename = filename ?? 'Image.' + extension
      } else {
        filename = pathElements.name + pathElements.ext
      }
      result.name = filename

      // if it's web, we're going to store the URI in localstorage (URI is b64)
      // we're going to md5 it
      // on upload attach, we'll fetch from localstorage with md5.
      // if(Platform.OS === "web") {
      result.md5 = md5(result.uri)

      if (returnReference) {
        if (Platform.OS === 'web') {
          localStorage.setItem(result.md5, result.uri)
        } else {
          await AsyncStorage.setItem(result.md5, result.uri)
        }
        result.uri = ''
        delete result.base64
      }

      // }

      payload = result

      // }

      resolve({ payload, filename, offline_id })
    })

    return p
  },
  ImageType,
  MediaType,
  DocumentType,
  PDFType
}
export default MediaHelper
