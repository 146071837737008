import React, { useEffect, useMemo, useState } from 'react'
import useBetterLinkTo from '../useBetterLinkTo'
import { useSelector } from 'react-redux'
import { useWindowDimensions, View, Text, SafeAreaView } from 'react-native'
import _ from 'lodash'
import { discoverPageTitle } from '../libraries/entityTools'

import { v4 as uuidv4 } from 'uuid'
import { shouldShowDesktop } from '../libraries/shouldShowDesktop'
import EntityAddComponent from './EntityAddComponent'
import useQueryString from '../libraries/querystring'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
import GlobalLoading from '../components/GlobalLoading'

const getBareEntity = (entityType, mode, storedEntity = null) => {
  const newBareEntity = {
    ...{
      type: entityType,
      mode: mode,
      files: [],
      offline_id: uuidv4()
    },
    ...storedEntity
  }

  return newBareEntity
}

export const addMode = 'add'
export const updateMode = 'edit'
export const viewMode = 'view'

function EntityAddScreen(props) {
  const { route, navigation, isInSplitView = false, onCloseSplit } = props

  const mode = route.params?.mode ?? viewMode

  const storedEntity = useSelector(
    (state) => {
      const id = _.get(route.params, 'id', null)
      if (id) {
        const entity = _.get(state, `entities.byId[${id}]`, null)
        return entity
      }
      return null
    },
    (prev, next) => prev.updated_at === next.updated_at
  )

  // if the entity type is in the route, fetch from entity types where the name matches
  // if the entity type is in the stored entity, fetch from entity types where ID matches
  // if there is no entity types, fetch them.

  const { current_user } = useSelector((state) => {
    return {
      current_user: state?.users?.user
    }
  })

  const { entityType, isFetching } = useDiscoverEntityEntityType(
    route,
    storedEntity,
    null,
    null,
    true
  )

  const tenantRecord = useMemo(() => {
    return current_user.tenant_record
  }, [current_user])

  const backgroundColor = useMemo(() => {
    return _.get(tenantRecord, 'color', '#9a0223')
  }, [tenantRecord])

  const linkTo = useBetterLinkTo()
  const [entity] = useState(getBareEntity(entityType, mode, storedEntity))

  useEffect(() => {
    const title = discoverPageTitle(entityType, mode, entity)
    navigation.setOptions({ title })
  }, [entityType, entity.display_name, route.params.type, mode])

  useEffect(() => {
    // dispatch(entityActions.clearFieldErrors());

    redirectIfShouldBeSplit()
  }, ['', route.params])

  const windowDimensions = useWindowDimensions()

  const showDesktop = shouldShowDesktop(windowDimensions)
  const redirectIfShouldBeSplit = () => {
    if (!isInSplitView && showDesktop && route.params.type) {
      // http://localhost:19006/tasks/many_field_types_test/96
      navigation.navigate('EntityListDetail', {
        'split.id': route.params.id,
        mode,
        type: route.params.type
      })
    }
  }

  const [currentPage, updatePage] = useQueryString('page', 1)

  const nestedRouteParams = useMemo(() => {
    return {
      params: {
        ...route.params,
        id: route.params.id,
        type: entityType?.name,
        mode: mode
      }
    }
  }, [route?.params, entityType, mode])

  return (
    <>
      {!entityType && !isFetching && (
        <View>
          <Text>Entity Type not found.</Text>
        </View>
      )}
      {entityType && !isFetching && (
        <SafeAreaView style={{ flex: 1 }}>
          <View style={[{ backgroundColor, flex: 1 }]}>
            <EntityAddComponent
              navigation={navigation}
              withTenantRecord={tenantRecord}
              isInSplitView={isInSplitView}
              onCloseSplit={onCloseSplit}
              currentPage={currentPage}
              onUpdatePage={updatePage}
              route={nestedRouteParams}
              style={{ backgroundColor: 'white' }}
              savedCallback={(response, refetchMethod) => {
                const { id } = response.data
                refetchMethod(entityType, id)
                updatePage(1)
                window && window.scrollTo(0, 0)
                if (mode === updateMode) {
                  navigation.setParams({ mode: viewMode })
                } else if (mode === addMode) {
                  if (entityType.object_data?.is_quiz) {
                    // if entity type is a quiz, user should go back into edit mode.

                    linkTo(response.data.uri + '?mode=' + updateMode)
                  } else {
                    linkTo(response.data.uri, true)
                  }
                }
              }}
            />
          </View>
        </SafeAreaView>
      )}
      {!entityType && isFetching && <GlobalLoading />}
    </>
  )
}

export default EntityAddScreen
