// libraries
import React, { useState } from 'react'
import { View } from 'react-native'
import MSFESInput from './MSFESInput'

export const nestedInputExtraProps = {
  inputInternalStyle: { backgroundColor: 'transparent' }
}

export const getLatFromDefaultValue = (defaultValue) => {
  if (typeof defaultValue == 'object') {
    return defaultValue?.lat
  } else if (typeof defaultValue == 'string') {
    try {
      return defaultValue.split(',')?.[0]
    } catch (e) {
      return ''
    }
  }
}
export const getLngFromDefaultValue = (defaultValue) => {
  if (typeof defaultValue == 'object') {
    return defaultValue?.lng
  } else if (typeof defaultValue == 'string') {
    try {
      return defaultValue.split(',')?.[1]
    } catch (e) {
      return ''
    }
  }
}

export const LatLng = React.forwardRef((props, ref) => {
  const { defaultValue, changeHandler, editable } = props //child props

  const [localState, setLocalState] = useState({
    lat: getLatFromDefaultValue(defaultValue),
    lng: getLngFromDefaultValue(defaultValue),
    value: '',
    display_value: ''
  })

  const localChangeHandler = (prop, value) => {
    const currentState = localState

    const newState = { ...currentState, [prop]: value }

    const newValue = newState.lat + ' ' + newState.lng
    const display_value = newState.lat + ' ' + newState.lng
    newState.value = newValue
    newState.display_value = display_value
    setLocalState(newState)
  }

  const blurChanges = () => {
    changeHandler(localState, localState.lat !== '' || localState.lng !== '') //bubble up.
  }
  // useEffect(() => {
  //   changeHandler(localState, localState.lat !== '' || localState.lng !== '') //bubble up.
  // }, [localState])

  return (
    <View style={{ flex: 1, marginBottom: 20 }}>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        <MSFESInput
          keyboardType="numbers-and-punctuation"
          {...nestedInputExtraProps}
          style={{ ...props.style, paddingLeft: 8, flex: 1, marginRight: 10 }}
          sublabel="Latitude (-90 to 90)"
          showLabel={false}
          value={localState.lat}
          placeholder="Latitude"
          disabled={!editable}
          onBlur={blurChanges}
          onChangeText={(value) => localChangeHandler('lat', value)}
        />
        <MSFESInput
          keyboardType="numbers-and-punctuation"
          {...nestedInputExtraProps}
          style={{ ...props.style, paddingLeft: 8, flex: 1 }}
          sublabel="Longitude (-180 to 180)"
          showLabel={false}
          value={localState.lng}
          placeholder="Longitude"
          disabled={!editable}
          onBlur={blurChanges}
          onChangeText={(value) => localChangeHandler('lng', value)}
        />
      </View>
    </View>
  )
})
