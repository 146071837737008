import _ from "lodash"
import React from "react"
import { View } from "react-native"
import { CheckboxMultiselectAdhoc } from "./CheckboxMultiselectAdhoc"
import { quizDescriptionStyle } from "./RadioQuiz"

//actions
import { ViewDescription } from "./ViewDescription"


export const CheckboxMultiselectQuiz = React.forwardRef((props, ref) => {

  const descriptionData = {field: {field_data: {params: {displayInline: true, descriptionText: props.data?.field?.field_data?.params?.descriptionText}}}};

  return (
    <View style={{flex: 1}}>
    <ViewDescription style={quizDescriptionStyle} data={descriptionData} />
    <CheckboxMultiselectAdhoc
      {...props}
    />
</View>
  )
})