// libraries
import React from 'react'
import { Button } from 'react-native-elements'
import { useLinkProps } from '@react-navigation/native'

export const ButtonLink = ({
  to,
  action,
  //type, commented out giving lint errors when committing
  ...rest
}) => {
  const { onPress, ...props } = useLinkProps({ to, action })

  return (
    <Button
      {...props}
      containerStyle={{ position: 'relative' }}
      iconContainerStyle={{ position: 'absolute', left: 6 }}
      onPress={onPress}
      {...rest}
    />
  )
}

export default ButtonLink
