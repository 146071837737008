import React, { useRef, useState } from 'react'
import { Clipboard, Text, Image, View, TouchableOpacity } from 'react-native'
import MSFESModal from '../MSFESModal'
import MSFESButton from '../MSFESButton'
import api from '../../data/api'
import MSFESInput from '../MSFESInput'
import spacing from '../../styles/spacing'
import { DateTime } from 'luxon'
import FlashMessage from 'react-native-flash-message'
import MSFESIcon from '../MSFESIcon'

export const MFAEnrolmentModal = ({ dismissMFA, mfa_enrol_by }) => {
  const [qrcodeImage, setQrcodeImage] = useState(null)
  const [totpText, setTotpText] = useState(null)
  const [code, setCode] = useState(null)
  const [loading, setLoading] = useState(false)

  const modalFlashRef = useRef()
  const [enrolledSuccessfully, setEnrolledSuccessfully] = useState(false)
  const beginMFAEnrolment = () => {
    setLoading(true)
    api.get('/user/enrol-mfa').then((response) => {
      setLoading(false)

      setQrcodeImage(response.data.qr_code_image)
      setTotpText(response.data.key)
    })
  }

  const finishMFAEnrolment = () => {
    setLoading(true)
    api
      .post('/user/finish-mfa', { code })
      .then(() => {
        setLoading(false)
        setEnrolledSuccessfully(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Assuming 'mfa_enrol_by' is the ISO string you want to compare
  const mfaEnrolByDateTime = DateTime.fromISO(mfa_enrol_by)

  // Get the current DateTime
  const currentDateTime = DateTime.now()

  const insetTop = 40

  const isOverdue = mfaEnrolByDateTime < currentDateTime

  return (
    <MSFESModal title="MFA" style={{ paddingTop: insetTop }}>
      <View>
        {!qrcodeImage && (
          <>
            <Text style={{ maxWidth: 500, paddingVertical: spacing.m2 }}>
              Multi-Factor Authentication helps to keep your account and your
              data safe by requiring a 6-digit passcode when signing in to
              MSFES. Please press &apos;Enroll MFA&apos; below to begin.
            </Text>
            <Text style={{ maxWidth: 500, paddingVertical: spacing.m2 }}>
              You must enroll in MFA by{' '}
              {mfaEnrolByDateTime.toFormat('MM-dd-yyyy')}.
            </Text>
            <MSFESButton
              title="Enroll MFA"
              loading={loading}
              onPress={() => beginMFAEnrolment()}
            />
            {!isOverdue && (
              <MSFESButton
                title="Skip for now"
                variant="outline"
                onPress={() => dismissMFA()}
              />
            )}
          </>
        )}

        {qrcodeImage && !enrolledSuccessfully && (
          <>
            <Text style={{ maxWidth: 500, paddingVertical: spacing.m2 }}>
              Copy the text below and enter it in your Multi-Factor
              Authentication App, or if you have another device scan the QR
              code. Then, enter the 6-digit code in the box below to finish.
            </Text>
            <View style={{ alignItems: 'center', minHeight: 200 }}>
              <Image
                source={{ uri: qrcodeImage }}
                style={{ height: 200, width: 200 }}
              />
            </View>
            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(totpText)
                modalFlashRef.current?.showMessage({
                  message: 'Copied.',
                  type: 'success'
                })
              }}
              style={{ flexDirection: 'row', paddingHorizontal: spacing.m1 }}
            >
              <Text
                selectable={true}
                style={{ flex: 1, paddingRight: spacing.m1 }}
              >
                {totpText}
              </Text>

              <MSFESIcon name="copy" />
            </TouchableOpacity>
          </>
        )}
        <View>
          {qrcodeImage && !enrolledSuccessfully && (
            <View style={{ paddingVertical: spacing.m4 }}>
              <MSFESInput
                horizontal
                label={'6 Digit Code'}
                onChangeText={(code) => setCode(code)}
                placeholder={`6 Digit Code`}
              />
            </View>
          )}
          {qrcodeImage && !enrolledSuccessfully && (
            <MSFESButton
              disabled={!code || code?.length !== 6}
              title="Finish Enrollment"
              loading={loading}
              onPress={() => finishMFAEnrolment()}
            />
          )}
        </View>

        {enrolledSuccessfully && (
          <>
            <Text style={{ maxWidth: 500, paddingVertical: spacing.m2 }}>
              Thanks for enrolling! Next time you sign-in, we&apos;ll request
              you enter a 6-digit code from your Authentication App to sign-in
              successfully.
            </Text>
            <MSFESButton title="Close" onPress={() => dismissMFA()} />
          </>
        )}
      </View>

      <FlashMessage
        ref={modalFlashRef}
        position="top"
        floating={true}
        canRegisterAsDefault={false}
      />
    </MSFESModal>
  )
}
