import React, { useState } from 'react'
import spacing from '../../styles/spacing'
import { roles } from '../fields/MultipleRolePicker'
import MSFESChip from '../MSFESChip'
import { ListItem, Overlay } from 'react-native-elements'
import { FlatList, View } from 'react-native'
import OverlayHeader from '../OverlayHeader'

export const RolesVisible = ({ roleIds }) => {
  const [showRolesList, setShowRolesList] = useState(false)
  const RolesVisible = roleIds.every(r => r?.key) ? roleIds : roles.filter((role, index) => {
    if (roleIds.includes(index - 1)) {
      return true
    }
    return false
  })

  const label =
    RolesVisible.length > 1
      ? `${RolesVisible[0].label} + ${RolesVisible.length - 1} others`
      : RolesVisible[0].label
  return (
    <>
      <MSFESChip
        style={{ marginLeft: spacing.m1 }}
        onPress={() => setShowRolesList(true)}
      >
        {label}
      </MSFESChip>
      {showRolesList && (
        <Overlay
          isVisible={showRolesList}
          onBackdropPress={() => setShowRolesList(false)}
        >
          <OverlayHeader>Visible to Roles</OverlayHeader>
          <View style={{ width: '100%' }}>
            <FlatList
              style={{ maxHeight: 400, minWidth: 200 }}
              keyboardShouldPersistTaps={'always'}
              data={RolesVisible}
              renderItem={({ item }) => {
                return (
                  <ListItem>
                    <ListItem.Content>
                      <ListItem.Title>{item.label}</ListItem.Title>
                    </ListItem.Content>
                  </ListItem>
                )
              }}
            ></FlatList>
          </View>
        </Overlay>
      )}
    </>
  )
}
