import _ from 'lodash'

export const discoverAdminPageTitle = (matchingTool, mode = 'add') => {
  return discoverPageTitle(matchingTool, mode)
}

export const getDisplayLabelForEntity = (entity) => {
  if (!entity) {
    return ''
  }
  if (entity.mime_type) {
    // it's a document, picture, etc - a file.
    return entity.original_name
  }

  if (entity.display_name) {
    return entity.display_name
  }
  // if(!entityType){
  //   return entity.display_name
  // }
  // if (entity && entityType) {
  //   const label =
  //     entityType.label_string_template &&
  //     entityType.label_string_template(entity)
  //   return label
  // }

  return ''
}

export const discoverPageTitle = (tool, mode = 'add', entity) => {
  let label = ''

  let prefix
  let suffix = ''

  let useMode = mode == 'add' && entity.virtual ? 'view' : mode

  switch (useMode) {
    case 'add':
      prefix = 'Add '
      label = _.get(tool, 'label', _.get(tool, 'singularTitle', ''))
      break
    case 'edit':
      prefix = ''
      suffix = entity.display_name ? `${entity.display_name}` : ''
      break
    case 'view':
      prefix = ''
      label = ''
      suffix = entity && entity.display_name ? `${entity.display_name}` : ''
      break
    case 'list':
      prefix = 'List '
      break
  }

  return `${prefix}${label}${suffix}`
}
export const discoverSaveButtonLabel = (
  tool,
  mode = 'add',
  overrideCreateLabel = null
) => {
  switch (mode) {
    case 'add':
      return overrideCreateLabel ?? 'Save changes'
    case 'edit':
      return 'Save changes'
  }
}

export const sortListWithEntityType = (list, entityType) => {
  const sortField = _.get(
    entityType,
    'object_data.sort_by_field.field_data.property',
    null
  )

  const sorted = _.sortBy(list, (el) => {
    if (sortField) {
      return el[sortField]
    } else {
      return el.display_name
    }
  })

  return sorted
}
export const entityByName = (name, entityTypes) => {
  const match = entityTypes?.find((t) => t.name == name)

  if (match) {
    return match
  }

  if (name == 'personnel') {
    // it's OK to not have the full item type for personnel - it should always exist, so if it doesn't it's a public form.
    // they need a bare model for assignees.
    return { name }
  }

  return null
}
export const entityBySublist = (sublist, entityTypes) => {
  return entityTypes.find((t) => t.sublist === sublist)
}
export const discoverTool = (route, definitions, entity) => {
  if (entity) {
    const et = definitions.find(
      (entityType) => entityType.id === entity.entity_type_id
    )

    if (et) {
      return et
    }
  }

  if (_.get(route, 'params.sublist', null)) {
    const tool = entityBySublist(route.params.sublist, definitions)
    return tool
  }
  if (_.get(route, 'params.type', null)) {
    let tool = definitions.find((t) => t.entityType === route.params.type)

    if (!tool) {
      tool = definitions.find((t) => t.name === route.params.type)
    }
    return tool
  }

  return null
}
