// libraries
import React from 'react'
import { Text } from 'react-native-elements'
import ModalHeader from './ModalHeader'
import { Overlay } from 'react-native-elements'
import { View, Platform } from 'react-native'
// styles
import spacing from '../styles/spacing'

//actions
import { ActivityIndicator } from 'react-native-paper'
import MSFESButton from './MSFESButton'
import { ScrollView } from 'react-native-gesture-handler'

// components

export const FileImportOverlay = (props) => {
  const smallHeaderStyle = {
    fontSize: 18,
    paddingVertical: 6,
    fontWeight: 'strong'
  }
  return (
    <Overlay
      isVisible={true}
      onBackdropPress={props.onClosePressed}
      overlayStyle={{
        position: 'relative',
        margin: 0,
        padding: 0,
        minWidth: 320,
        maxHeight: '95%'
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            padding: spacing.m1,
            minHeight: Platform.OS === 'ios' ? 330 : 140
          }}
        >
          <ModalHeader>File Import Details</ModalHeader>

          {props.performingImport && (
            <View
              style={{
                alignContent: 'center',
                alignItems: 'center',
                paddingVertical: 12
              }}
            >
              <ActivityIndicator size="small" />
              <Text style={{ paddingLeft: 12 }}>Performing Import</Text>
              <Text style={smallHeaderStyle}>
                Is Test: {props.testRun ? 'YES' : 'NO'}
              </Text>
            </View>
          )}
          {props.results && (
            <ScrollView style={{ flex: 1 }}>
              <Text style={smallHeaderStyle}>Failed Rows:</Text>
              <View style={{ flex: 1 }}>
                <ScrollView style={{ flex: 1 }}>
                  {props.results.failedRows.map((row, index) => {
                    return (
                      <Text key={index}>
                        Row {row.index}: {row.exception}
                      </Text>
                    )
                  })}
                </ScrollView>
              </View>

              <Text style={smallHeaderStyle}>Successful Rows:</Text>
              <Text style={{ flexWrap: 'wrap', width: '100%' }}>
                {props.results.importedItems}
              </Text>
              <Text style={smallHeaderStyle}>
                Ignored Fields (could not match field name):
              </Text>
              <Text style={{ flexWrap: 'wrap', width: '100%' }}>
                {(props.results.ignoredFields ?? []).join(', ')}
              </Text>
              <Text style={smallHeaderStyle}>Was Test?:</Text>
              <Text>
                {props.results.testRun
                  ? 'YES - No Records were imported'
                  : 'NO'}
              </Text>
              {props.results.testRun && (
                <MSFESButton
                  onPress={props.runFinalImport}
                  title="Run Final Import"
                />
              )}
            </ScrollView>
          )}
        </View>
      </View>
    </Overlay>
  )
}

export default FileImportOverlay
