import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import PublicFormsAddScreen from './PublicFormsAddScreen'
const PublicFormsStack = createStackNavigator()
const PublicFormsNavigator = () => {
  return (
    <PublicFormsStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <PublicFormsStack.Screen name="PublicFormAddScreen" component={PublicFormsAddScreen} />
    </PublicFormsStack.Navigator>
  )
}

export default PublicFormsNavigator