// libraries
import React, { useEffect } from 'react'
import { View, Text, FlatList, TouchableOpacity } from 'react-native'
import _ from 'lodash'

// internal libraries
// styles

//actions

import spacing from '../../styles/spacing'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import { RadioButton } from 'react-native-paper'
import MSFESAvatar from '../MSFESAvatar'
import { useMemo } from 'react'
import { formatDataRows, getKey, getLabel, onValueChangeWrapped } from './SelectWithData'
import { MSFESCheckbox } from '../MSFESCheckbox'

export const RadioWithData = React.forwardRef((props, ref) => {
  const {
    placeholder,
    value,
    onChangeText,
    style,
    dataRows = [],
    editable = true,
    valueField = 'id',
    keyField = 'id',
    labelField = 'name',
    objectIsValue = false,
    keyFieldResolver = false
  } = props

  useEffect(() => {
    if (props.defaultValue && props.data?.entity?.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }
  }, [props.defaultValue])


  const dataFormatted = useMemo(() => {
    return formatDataRows(dataRows, keyFieldResolver, keyField, valueField, labelField);
  }, [dataRows, keyFieldResolver, keyField, valueField, labelField]);


  let inputColor;

  if (props.dark) {
    inputColor = colors.inputTextDark;
  }
  else {
    inputColor = colors.inputText
  }


  const inputStyle = [props.inputStyle, {
    color: inputColor,
    fontFamily: fonts.inputTextFace,
    fontWeight: "bold"
  }]


  const selectedItem = useMemo(() => {
    const matchingObject = dataRows.find(d => {
      const rowKey = getKey(d, keyFieldResolver, keyField)
      const selectedKey = getKey(value, keyFieldResolver, keyField)
      const isMatch = rowKey == value || rowKey === selectedKey

      return isMatch
    });

    return matchingObject
  }, [dataFormatted, value])


  return (
    <View style={style}>
      <View style={{marginLeft: -(spacing.m2)}}>
        <FlatList initialNumToRender={20} data={dataFormatted} keyExtractor={(item) => {
      const key = ''+getKey(item, keyFieldResolver, keyField);
      return key}
    }  extraData={selectedItem} renderItem={({ item, index, separators }) => {

          const onclick = () => onValueChangeWrapped(item, dataRows, props.defaultValue, keyField, keyFieldResolver, onChangeText)
          const active = getKey(item, keyFieldResolver, keyField) === getKey(selectedItem, keyFieldResolver, keyField)

          const isOdd = index % 2

          const hasImage = !!item.image;

          const label = getLabel(item, labelField)
          return <TouchableOpacity disabled={!editable} onPress={onclick} style={
            { flexDirection: 'row', alignItems: "center" , backgroundColor: isOdd ? "white" : "transparent", paddingTop: spacing.m0_5, paddingLeft: spacing.m2, marginRight: -12 }}>
            {hasImage && <MSFESAvatar showLabel={false} rounded={false} size={48} source={ item.image } style={{ paddingVertical: spacing.m1, paddingRight: spacing.m2 }} />}
            <Text style={[{ flex: 1, opacity: active ? 1 : 0.7 }, inputStyle]}>{label}</Text>
            <MSFESCheckbox disabled={!editable} checked={active} onPress={onclick} />
            {/* <RadioButton disabled={!editable} value={value} status={active ? 'checked' : 'unchecked'} onPress={onclick} /> */}
          </TouchableOpacity>
        }} />

      </View>

      </View>
      )
})





      export default RadioWithData
