import { Dimensions, useWindowDimensions } from 'react-native'

export const shouldShowDesktop = (windowDimensions) => {
  return windowDimensions.width > 768 && windowDimensions.height > 768
}

export const useIsDesktop = () => {
  const windowDimensions = useWindowDimensions()

  return shouldShowDesktop(windowDimensions)
}
export const useIsMobile = (includeTablet = false) => {
  const windowDimensions = useWindowDimensions()

  return windowDimensions.width < (includeTablet ? 1024 : 481)
}

export const useGetModalDimensions = () => {
  const { height, width } = Dimensions.get('window')
  const isDesktop = useIsDesktop()
  const calculatedWidth = isDesktop ? width * 0.75 : width * 0.95
  const calculatedHeight = isDesktop ? height * 0.85 : height * 0.8

  return { calculatedHeight, calculatedWidth }
}
