import { Platform } from 'react-native'
import { useLinkTo } from '@react-navigation/native';

export const websafeGoBack = (navigation, navState=null, useLinkTo=null) => {
  if (Platform.OS === 'web') {
    if(navState && navState.routes.length){
      const lastRoute = navState.routes[navState.routes.length-1];

      if(lastRoute.params && lastRoute.params.from){
        if(useLinkTo){
          useLinkTo(lastRoute.params.from, true)
          return;
        }
      }
    }

    const currentRoutes = navigation.dangerouslyGetState().routes

    if(currentRoutes.length){
      const lastRoute = currentRoutes[currentRoutes.length - 1];
      const from = _.get(lastRoute, "params.from", null);
      
      if(from && useLinkTo){
        useLinkTo(from, true)
      }
    }

    navigation.goBack()

    //window.history.back()
  } else {
    navigation.goBack()
  }
}
