import { Alert, Platform } from "react-native";


const wrappedAlert = (title, alertText) => {

  if(Platform.OS === "web" ) {

    alert(title + "\n\n" + alertText);
  } else {
    Alert.alert(title, alertText);

  }

}

export default wrappedAlert;