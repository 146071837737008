const defaultState = {
  byDate: {}
}

export default function calendar(state = defaultState, action) {
  switch (action.type) {
    case 'calendar/definition-list-success':
      {
        let { response } = action

        // response.virtualEvents = events
        state = { ...state, eventDefinitions: response }
      }
      break
    case 'calendar/reset-state':
      state = { ...defaultState }
      break
    case 'calendar/list-success':
      {
        let { date, response } = action

        const events = response?.virtualEvents?.filter((v) => {
          const matchingRealEntity = response?.relevantEntities?.find(
            (e) =>
              e.virtual_event_signature == v?.entity?.virtual_event_signature
          )

          return !matchingRealEntity
        })

        response.virtualEvents = events
        state = { ...state, byDate: { ...state.byDate, [date]: response } }
      }

      break
    case 'calendar/list-month-success':
      {
        const { monthYear, response } = action

        const monthYearKey = monthYear?.month + '.' + monthYear?.year

        // response needs to mutate to [(date)]: {marked: true} if count.
        let newResponse = {}

        const byDate = { ...state.byDate }
        response.data?.entities?.map((d) => {
          newResponse[d.day] = { marked: { entity: d.dayCount ? true : false } }

          if (!byDate[d.day]) {
            byDate[d.day] = {}
          }
          byDate[d.day] = { ...byDate[d.day], entities: d.entities }
        })

        response.data?.virtualEvents?.map((d) => {
          if (!newResponse[d.day]) {
            newResponse[d.day] = {}
          }
          newResponse[d.day] = {
            ...newResponse[d.day],
            marked: {
              ...newResponse[d.day]?.marked,
              virtual: d.dayCount ? true : false
            }
          }

          byDate[d.day] = { ...byDate[d.day], virtualEvents: d.events }
        })

        state = {
          ...state,
          byDate: byDate,

          byMonthYear: {
            ...state.byMonthYear,
            [monthYearKey]: newResponse
          }
        }
      }

      break
  }

  return state
}
