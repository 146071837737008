import React from 'react'

import SettingsIndexScreen from './SettingsIndexScreen'
import SettingsAdminToolsScreen from './SettingsAdminToolsScreen'
import SettingsAdminEntityTypeAddScreen from './SettingsAdminEntityTypeAddScreen'
import { createStackNavigator } from '@react-navigation/stack'
import SettingsManageEntityTypesScreen from './SettingsManageEntityTypesScreen'
import SettingsAdminToolsPermissionsScreen from './SettingsAdminToolsPermissionsScreen'
import SettingsAdminModulesScreen from './SettingsAdminModulesScreen'
import SettingsCollectionsScreen from './SettingsCollectionsScreen'
import SettingsReportsScreen from './SettingsReportsScreen'
import ShowReportsScreen from './ShowReportsScreen/ShowReportsScreen'
import SettingsManageLoginsScreen from './SettingsManageLoginsScreen'
const SettingsStack = createStackNavigator()

const SettingsNavigator = () => {
  return (
    <SettingsStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <SettingsStack.Screen
        name="SettingsIndex"
        component={SettingsIndexScreen}
      />
      <SettingsStack.Screen
        name="SettingsAdminTools"
        component={SettingsAdminToolsScreen}
      />
      <SettingsStack.Screen
        name="SettingsManageEntityTypes"
        component={SettingsManageEntityTypesScreen}
      />
      <SettingsStack.Screen
        name="SettingsManageLogins"
        component={SettingsManageLoginsScreen}
      />
      <SettingsStack.Screen
        name="SettingsAdminEntityTypeAdd"
        component={SettingsAdminEntityTypeAddScreen}
      />
      <SettingsStack.Screen
        name="SettingsAdminEntityTypeEdit"
        component={SettingsAdminEntityTypeAddScreen}
      />

      <SettingsStack.Screen
        name="SettingsAdminToolsPermissions"
        component={SettingsAdminToolsPermissionsScreen}
      />

      <SettingsStack.Screen
        name="SettingsAdminModules"
        component={SettingsAdminModulesScreen}
      />

      <SettingsStack.Screen
        name="SettingsReports"
        component={SettingsReportsScreen}
      />

      <SettingsStack.Screen
        name="SettingsReportsShowReport"
        component={ShowReportsScreen}
      />
      <SettingsStack.Screen
        name="SettingsCollections"
        component={SettingsCollectionsScreen}
      />
    </SettingsStack.Navigator>
  )
}

export default SettingsNavigator
