import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { withTheme } from 'react-native-elements'
import MSFESHeading from './MSFESHeading'
import _ from 'lodash'
import { useIsDesktop } from '../libraries/shouldShowDesktop'
export const useWizardSteps = ({
  isWizard,
  wizardStep,
  currentPage,
  definition,
  pageVisibilityRules,
  shouldPaginate,
  offline_id
}) => {
  const { errors } = useSelector((appState) => {
    return { errors: appState.entities?.errors }
  })

  const errorsThisEntity =
    errors?.offline_id === offline_id ? errors?.errorsByPage : null

  return useMemo(() => {
    const currentStep = parseInt(wizardStep) - 1 + parseInt(currentPage)
    const onLastPageOfWizard =
      isWizard && currentPage == definition.pages && shouldPaginate

    let allPages = []
    for (let i = 0; i < definition.pages; i++) {
      allPages.push({
        index: i,
        name: definition?.object_data?.page_labels?.[i] ?? `Page ${i + 1}`,
        available: true,
        errors: _.get(errorsThisEntity, i + 1, undefined)
      })
    }
    let availablePages = definition.pages
    let identifierCurrentPage = currentStep
    Object.keys(pageVisibilityRules).map((k) => {
      if (pageVisibilityRules[k] == false) {
        availablePages -= 1

        if (k <= currentStep) {
          identifierCurrentPage -= 1
        }
        allPages[k - 1].available = false
      }
    })
    return {
      availablePages,
      identifierCurrentPage,
      textLabelCurrentPage:
        definition?.object_data?.page_labels?.[currentStep - 1],
      onLastPageOfWizard,
      allPages
    }
  }, [
    definition.pages,
    pageVisibilityRules,
    isWizard,
    wizardStep,
    currentPage,
    shouldPaginate,
    errorsThisEntity
  ])
}
const WizardSteps = (props) => {
  const { step, steps, pageName, onPress, isInQuickAddMode } = props

  const pageNameLabel = pageName ? `(${pageName})` : ''

  const desktop = useIsDesktop()
  const label =
    desktop || isInQuickAddMode
      ? pageName
      : `Page ${parseInt(step)} of ${steps} ${pageNameLabel}`
  return (
    <>
      <MSFESHeading onPress={onPress}>
        {(label ?? '').toUpperCase()}
      </MSFESHeading>
    </>
  )
}

export default withTheme(WizardSteps)
