import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Text } from 'react-native'
import { ActivityIndicator, DataTable } from 'react-native-paper'
import PermissionTile from '../components/PermissionTile'
import { FlatList } from 'react-native'
import spacing from '../styles/spacing'
import _ from 'lodash'
import permissionsActions from '../store/permission/actions'
import { roles } from './fields/MultipleRolePicker'

export default function PermissionsEditor({
  entityType,
  onPermissionsChanged
}) {
  const [localPermissions, setLocalPermissions] = useState({
    roles: [],
    permissions: [],
    isInitial: true
  })

  const [loading, setLoading] = useState(false)
  const [, setChangesMade] = useState(false)

  useEffect(() => {
    if (localPermissions) {
      onPermissionsChanged(localPermissions)
    }
  }, [localPermissions])
  const user = useSelector((state) => _.get(state, 'users.user', {}))

  const onValueChanged = (_role, _permission, _friendlyName, _newValue) => {
    const { roles } = localPermissions

    setChangesMade(true)

    const role = roles.find((r) => r.id === _role.id)

    if (role) {
      if (_permission) {
        const currentIndex = role.permissions.findIndex(
          (p) => p.id === _permission.id
        )

        if (_newValue) {
          // add the permission.
          if (currentIndex === -1) {
            role.permissions.push(_permission)
          }
        } else {
          if (currentIndex !== -1) {
            role.permissions.splice(currentIndex, 1)
          }
        }

        setLocalPermissions((currentPermissions) => {
          return { ...currentPermissions, roles }
        })
      } else {
        const matches = localPermissions.permissions.filter(
          (p) => p.friendly_name === _friendlyName
        )
        matches.forEach((_permission) => {
          const currentIndex = role.permissions.findIndex(
            (p) => p.id === _permission.id
          )

          if (_newValue) {
            // add the permission.
            if (currentIndex === -1) {
              role.permissions.push(_permission)
            }
          } else {
            if (currentIndex !== -1) {
              role.permissions.splice(currentIndex, 1)
            }
          }
        })

        setLocalPermissions((currentPermissions) => {
          return { ...currentPermissions, roles }
        })
      }
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (entityType?.data?.id) {
      setLoading(true)
      dispatch(
        permissionsActions.listPermissionsForEntityType(entityType)
      ).then((permissions) => {
        setLocalPermissions(permissions.data)
        setLoading(false)
      })
    }
  }, [])

  const [searchQuery] = useState(null)
  const permissionsFiltered = useMemo(() => {
    const searchLower = searchQuery?.toLowerCase()
    return localPermissions.permissions.filter((p) =>
      searchLower
        ? p.friendly_name.toLowerCase().indexOf(searchLower) !== -1
        : true
    )
  }, [localPermissions.permissions, searchQuery])

  return (
    <View style={{ flex: 1 }}>
      {entityType?.data?.id && (
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header fixedHeader={true}>
            <DataTable.Title>Permissions</DataTable.Title>
            {localPermissions.roles.map((r) => {
              const matchRole = roles.find((role) => role.key === r.name)
              return (
                <DataTable.Title key={r.id}>{matchRole.label}</DataTable.Title>
              )
            })}
          </DataTable.Header>

          <FlatList
            keyExtractor={(item) => item.id.toString()}
            style={{ maxHeight: '80%' }}
            data={permissionsFiltered}
            contentContainerStyle={{ flex: 1 }}
            renderItem={({ item: p }) => {
              const displayHeader = false //index === 0 || (p.friendly_name && p.friendly_name !== permissionsFiltered[index - 1].friendly_name);
              return (
                <>
                  {displayHeader && (
                    <DataTable.Header>
                      <DataTable.Title>{p.friendly_name}</DataTable.Title>
                    </DataTable.Header>
                  )}
                  {displayHeader && (
                    <DataTable.Row key={p.id + '_all'}>
                      <DataTable.Title>All</DataTable.Title>
                      {localPermissions.roles.map((r) => (
                        <DataTable.Cell key={r.id + '_all'}>
                          <PermissionTile
                            role={r}
                            friendlyName={p.friendly_name}
                            user={user}
                            valueChanged={(newValue) =>
                              onValueChanged(r, null, p.friendly_name, newValue)
                            }
                          />
                        </DataTable.Cell>
                      ))}
                    </DataTable.Row>
                  )}
                  <DataTable.Row key={p.id}>
                    <DataTable.Title>
                      {p.function ? p.function : p.name}
                    </DataTable.Title>
                    {localPermissions.roles.map((r) => (
                      <DataTable.Cell key={r.id + '_' + p.id}>
                        <PermissionTile
                          role={r}
                          permission={p}
                          user={user}
                          valueChanged={(newValue) =>
                            onValueChanged(r, p, null, newValue)
                          }
                        />
                      </DataTable.Cell>
                    ))}
                  </DataTable.Row>
                </>
              )
            }}
          />
        </DataTable>
      )}
      {loading && (
        <View style={{ flexDirection: 'row', paddingVertical: spacing.m2 }}>
          <ActivityIndicator
            size="small"
            style={{ paddingHorizontal: spacing.m2 }}
          />
          <Text>Requesting updated permissions.</Text>
        </View>
      )}
      {!entityType?.data?.id && (
        <View>
          <Text>Please save the new entity type first.</Text>
        </View>
      )}
    </View>
  )
}
