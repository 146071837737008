import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import _ from 'lodash'
import MSFESLabel from './MSFESLabel'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import MSFESIcon from './MSFESIcon'

export const withBorderStyle = {
  borderRadius: spacing.borderRadius,
  borderWidth: StyleSheet.hairlineWidth,
  borderColor: '#ccc'
}

export const MSFESFieldTile = (props) => {
  const {
    helpText = null,
    isDisabledFromPermissions = false,
    style,
    showLabel = true,
    value,
    defaultValue,
    children
  } = props

  // set up title text
  const { label, editable } = props

  const containerViewStyle = [
    {
      paddingTop: spacing.m1,
      position: 'relative',
      minHeight: 124,
      alignItems: 'center',
      margin: spacing.m1,
      ...withBorderStyle
    },
    style,
    shared.debugOff
  ]

  const dataLabel = value !== undefined ? value : defaultValue

  const dataLabelString =
    typeof dataLabel === 'string' ? dataLabel : _.get(dataLabel, 'label', null)

  const icon = _.get(props, 'data.field.field_data.icon', null)

  const fontSize = useMemo(() => {
    if (dataLabel) {
      if (dataLabel.length > 24) {
        return 10
      }

      if (dataLabel.length > 12) {
        return 16
      }
      if (dataLabel.length < 4) {
        return 40
      }
      return 30
    }

    return 18
  }, [dataLabel])

  const required = _.get(
    props,
    'data.field.field_data.validation.required',
    false
  )

  return (
    <View style={containerViewStyle}>
      {showLabel && (
        <MSFESLabel
          label={label}
          helpText={helpText}
          style={{ textAlign: 'center' }}
          isDisabledFromPermissions={isDisabledFromPermissions}
          required={editable && required}
        />
      )}

      {!!icon && (
        <View
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 10,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MSFESIcon name={icon} color={'#ddd'} size={64} type="font-awesome" />
        </View>
      )}
      <View style={{ flex: 1, justifyContent: 'center', marginTop: -10 }}>
        {children}

        <Text
          style={{
            fontSize: fontSize,
            fontWeight: '400',
            textAlign: 'center',
            color: '#555'
          }}
        >
          {dataLabelString}
        </Text>
      </View>
    </View>
  )
}
