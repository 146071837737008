import React, { useMemo } from 'react'
import { ScrollView, View, Text } from 'react-native'
import colors from '../../styles/colors'
import { PageButton } from './PageButton'
import spacing from '../../styles/spacing'
import { useIsDesktop } from '../../libraries/shouldShowDesktop'
import MSFESAvatar from '../MSFESAvatar'
import { headerTextStyle } from '../WithHeader'
import { sublabelTextStyle } from '../EntityAddHeader'
import MSFESButton from '../MSFESButton'
import { addMode, updateMode } from '../../screens/EntityAddComponent'
export const PagesDropdown = ({
  value,
  onPageChanged,
  onDismiss,
  displayPicture,
  title,
  entityTypeLabel,
  pages,
  onSaveEntityPressed,
  onEditToggled,
  hasQuickAddAvailable,
  onQuickAddToggled,
  isQuickAdd,
  mode,
  hasChanges,
  visibleFields,
  canModifyThisEntityType
}) => {
  const pagesFiltered = useMemo(() => {
    return pages
      ?.filter((p) => p.available)
      .filter((p) =>
        visibleFields.some((v) => v.field_data?.page == p.index + 1)
      )
  }, [pages])

  const onSelected = (option) => {
    onPageChanged && onPageChanged(option, option.index + 1)
    onDismiss && onDismiss()
  }

  const isDesktop = useIsDesktop()
  const desktopOnlyStyle = { backgroundColor: colors.pageNavigatorBackground }

  const enableSave = hasChanges && (mode == updateMode || mode == addMode)

  return (
    <View
      style={[isDesktop && desktopOnlyStyle, { padding: spacing.m1, flex: 1 }]}
    >
      <ScrollView
        style={[
          {
            minWidth: 180,
            flex: 1,
            paddingVertical: spacing.m1
          },
          isDesktop && desktopOnlyStyle
        ]}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: spacing.m1
          }}
        >
          {displayPicture ? (
            <MSFESAvatar
              showLabel={false}
              rounded={true}
              size="xlarge"
              source={displayPicture}
              outerContainerStyle={{
                alignItems: 'center',
                marginVertical: spacing.m1
              }}
            />
          ) : null}
        </View>
        <View
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row'
          }}
        >
          <View style={{ paddingBottom: spacing.m1 }}>
            <Text style={[headerTextStyle, { fontSize: 20, maxWidth: 240 }]}>
              {title}
            </Text>
            <Text style={sublabelTextStyle}>{entityTypeLabel}</Text>
          </View>
        </View>
        {(!isQuickAdd || !hasQuickAddAvailable) &&
          pagesFiltered?.map((option, index) => (
            <PageButton
              key={index}
              option={option}
              active={value - 1 === index}
              onPress={() => onSelected(option, index)}
            />
          ))}
      </ScrollView>

      <View style={{ paddingTop: spacing.m1 }}>
        {mode === addMode && hasQuickAddAvailable && (
          <MSFESButton
            title="Advanced Setup"
            icon={{
              name: !isQuickAdd ? 'check-square' : 'square',
              type: 'font-awesome'
            }}
            onPress={onQuickAddToggled}
          />
        )}
        {mode !== addMode && (
          <MSFESButton
            title="Enable Editing"
            disabled={mode === addMode || !canModifyThisEntityType}
            icon={{
              name:
                mode === updateMode || mode === addMode
                  ? 'check-square'
                  : 'square',
              type: 'font-awesome'
            }}
            onPress={onEditToggled}
          />
        )}
        <MSFESButton
          title="Save Changes"
          onPress={onSaveEntityPressed}
          disabled={!enableSave}
        />
      </View>
    </View>
  )
}
