// libraries

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Text,
  View,
  ScrollView,
  Platform,
  ActivityIndicator
} from 'react-native'
import { Divider } from 'react-native-elements'
import _ from 'lodash'
import { showMessage, hideMessage } from 'react-native-flash-message'
// internal libraries

//actions
import entityTypeActions from '../store/entity-type/actions'

// styles
import shared from '../styles/shared'
import spacing from '../styles/spacing'

// components
import { EntityTypeEdit } from '../components/EntityTypeEdit'
import WithHeader from '../components/WithHeader'
import { websafeGoBack } from '../libraries/navigation'
import MSFESButton from '../components/MSFESButton'
import EntityTypeActions from '../components/EntityTypeActions'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import { useLinkTo, useNavigationState } from '@react-navigation/native'
import { useDropdownHelper } from '../useDropdownHelper'
import { viewMode } from './EntityAddComponent'

export default function SettingsAdminEntityTypeAddScreen({
  route,
  navigation
}) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [loadingInitial, setLoadingInitial] = useState(false)
  const [initialEntityType] = useSelector((state) => {
    return [
      state.entityTypes.data.find(
        (e) => e.id == _.get(route, 'params.id', null)
      )
    ]
  })

  const { current_user } = useSelector((state) => {
    return {
      current_user: state.users?.user
    }
  })

  const entityTypeIsGlobal = initialEntityType
    ? initialEntityType.is_global || initialEntityType.tenants?.length > 1
    : false
  const userIsGlobal = !current_user?.current_tenant
  const userIsLocalAndEditingGlobal = !userIsGlobal && entityTypeIsGlobal

  const canModifyEntityTypes = _.get(
    current_user,
    `effective_permissions['modify||name:entity types']`,
    false
  )

  const addMode = 'add'
  const updateMode = 'edit'

  const mode = canModifyEntityTypes
    ? _.get(route, 'params.id', null)
      ? updateMode
      : addMode
    : 'view'

  const discoverPageTitle = (entityType) => {
    const existingLabel = _.get(entityType, 'data.label', false)

    if (existingLabel && mode !== addMode) {
      return `Edit ${existingLabel}`
    } else {
      return `New Entity Type`
    }
  }

  const [entityType, updateLocalEntityType] = useState({
    ...{
      mode: mode
    },
    ...initialEntityType
  })

  const linkTo = useLinkTo()
  const navState = useNavigationState((state) => state)

  const storedEntityType = useSelector((state) => {
    const match = state.entityTypes.data.find((d) => d.id == route.params.id)

    return match
  })

  useEffect(() => {
    // update the local state entity type if it is updated in redux (by the get returning)
    updateLocalEntityType({ ...entityType, data: storedEntityType })
  }, [storedEntityType?.updated_at])

  useEffect(() => {
    if (mode !== addMode) {
      // TODO: Change to use the 'useDiscover' hook.
      setLoadingInitial(true)
      dispatch(entityTypeActions.getEntityType(route.params.id)).finally(() => {
        setLoadingInitial(false)
      })
    } else {
      updateLocalEntityType({
        ...entityType,
        ...{ data: { fields: [], tenantFields: [], pages: 1 } }
      })
    }

    dispatch(entityTypeActions.clearFieldErrors())
  }, ['', route.params?.id])

  const onCancelEntityTypePressed = () => {
    websafeGoBack(navigation, navState, linkTo)
  }

  const onSaveEntityTypePressed = () => {
    setLoading(true)
    hideMessage()

    const mutatedEntityType = { ...entityType }

    mutatedEntityType.data.fields = mutatedEntityType.data.fields.map((f) => {
      if (
        f.field_data.type === 'number-entry' ||
        f.field_data.type === 'number-slider'
      ) {
        const mustNotHaveDigits =
          f.field_data.params?.isNumberRange ||
          !f.field_data.type === 'number-slider'
        try {
          if (mustNotHaveDigits) {
            // must not have digits
            delete f.field_data.validation.digits
          } else {
            if (f.field_data.type !== 'number-slider') {
              // must not have min/max.
              delete f.field_data.validation.min
              delete f.field_data.validation.max
            }
          }
        } catch (e) {
          // we dont care
        }
      }

      return f
    })

    dispatch(entityTypeActions.upsertEntityType(mutatedEntityType))
      .then((response) => {
        const entityType = response.data
        const { id } = entityType

        showMessage({ message: 'Updated Successfully', type: 'success' })
        navigation.navigate('SettingsAdminEntityTypeEdit', { id })
      })
      .catch((errors) => {
        console.warn(errors)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(250)

  const rightButtonProps = {
    rightButtonAction:
      Platform.OS === 'web' ? () => toggleDropdownState(true) : null,
    rightButtonIcon: 'bars'
  }

  const [deleteActionPending, setDeleteActionPending] = useState(false)

  const onDeletePressed = () => {
    setDeleteActionPending(true)
  }

  const deleteCancelPressed = () => {
    setDeleteActionPending(false)
  }

  const deleteConfirmPressed = () => {
    return dispatch(entityTypeActions.deleteEntityType(entityType.data.id))
      .then(() => {
        showMessage({ message: 'Deleted Successfully', type: 'success' })

        setDeleteActionPending(false)

        navigation.replace(`SettingsManageEntityTypes`)
      })
      .catch((errors) => {
        console.warn('Save Entity Errors', errors)
        showMessage({ message: errors, type: 'danger' })
        throw errors
      })
      .finally(() => {})
  }

  const pageTitle = discoverPageTitle(entityType)

  return (
    <WithHeader
      title={pageTitle}
      dropdownVisibilityState={dropdownVisibilityState}
      rightButtonRef={buttonRef}
      splitViewShowHeader={true}
      navigation={navigation}
      leftButtonType={'back'}
      {...rightButtonProps}
    >
      {entityType && (
        <EntityTypeActions
          entityType={entityType.data}
          dropdownOverlayStyle={dropdownOverlayStyle}
          isVisible={dropdownVisibilityState}
          onBackdropPress={() => toggleDropdownState(false)}
          onDeletePressed={onDeletePressed}
        />
      )}

      {deleteActionPending && (
        <YesNoCancelDialog
          title="Delete"
          body="Are you sure you want to delete? This is a permanent action. All entities belonging to this collection will be inaccessible."
          yesAction={deleteConfirmPressed}
          noAction={deleteCancelPressed}
          cancelAction={deleteCancelPressed}
        />
      )}

      <ScrollView
        style={[shared.debugOff]}
        contentContainerStyle={{
          paddingBottom: 30,
          marginBottom: spacing.viewboxNavbarPadding
        }}
      >
        {loading ? (
          <></>
        ) : entityType.data ? (
          <>
            <EntityTypeEdit
              mode={mode}
              userTenantId={current_user?.current_tenant?.id}
              userIsGlobal={userIsGlobal}
              userIsLocalAndEditingGlobal={userIsLocalAndEditingGlobal}
              entityType={entityType}
              entityTypeChanged={updateLocalEntityType}
            />
            <Divider style={spacing.sectionDivider} />
            <View style={shared.mobileWidthView}>
              <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                <MSFESButton
                  containerStyle={[{ flex: 1 }, spacing.mr1]}
                  loading={loading}
                  title={'Cancel'}
                  modifier="outline"
                  onPress={onCancelEntityTypePressed}
                />
                <MSFESButton
                  containerStyle={[{ flex: 1 }]}
                  loading={loading}
                  title={'Save'}
                  disabled={mode === viewMode && !userIsLocalAndEditingGlobal}
                  onPress={onSaveEntityTypePressed}
                />
              </View>
            </View>
          </>
        ) : (
          <>
            {loadingInitial ? (
              <ActivityIndicator />
            ) : (
              <Text>Entity Type not found.</Text>
            )}
          </>
        )}
      </ScrollView>
    </WithHeader>
  )
}
