import React, { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'
import { ListItem } from 'react-native-elements'
import { titleCase } from 'title-case'
import { useIsDesktop } from '../../libraries/shouldShowDesktop'
import { listItemContainerStyle } from '../EntityTypeActions'
import { MSFESCheckbox } from '../MSFESCheckbox'
import { Dropdown } from './Dropdown'

export const CalendarViewDropdown = ({ value, onViewChanged }) => {
  const options = ['day', 'week', 'work week', 'weekend', 'month']

  const isDesktop = useIsDesktop()
  const desktopOnlyOptions = ['week']

  const optionsFiltered = useMemo(() => {
    if (isDesktop) {
      return options
    } else {
      return options.filter((o) => !desktopOnlyOptions.includes(o))
    }
  }, [isDesktop])
  const title = useCallback((option) => {
    return titleCase('' + option)
  }, [])

  const onSelected = (option) => {
    setDropdownVisibleState(false)
    onViewChanged(option)
    setTimeout(() => {
      setDropdownVisibleState(true)
    })
  }

  const [dropdownVisibleState, setDropdownVisibleState] = useState(true)
  return (
    <Dropdown title={`${title(value)}`} visible={dropdownVisibleState}>
      <View>
        {optionsFiltered.map((option) => (
          <ListItem
            key={option}
            onPress={() => onSelected(option)}
            disabled={false}
            containerStyle={listItemContainerStyle}
          >
            <ListItem.Content>
              <ListItem.Title>{title(option)}</ListItem.Title>
            </ListItem.Content>
            <MSFESCheckbox
              listitem
              radio={true}
              checked={value === option}
              onPress={() => onSelected(option)}
            />
          </ListItem>
        ))}
      </View>
    </Dropdown>
  )
}
