import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCalendarEventsMonth } from '../../store/calendar/actions'
import colors from '../../styles/colors'
export const useMonthLoadingHelper = (activeDate) => {
  /* To go in month/year helper */
  const dispatch = useDispatch()
  const [activeMonthYear, setActiveMonthYear] = useState(null)

  const byMonthYear = useSelector((appState) => {
    return appState.calendar.byMonthYear
  })

  const markedMonthYear = useMemo(() => {
    const entity = {
      key: 'entity',
      color: colors.lightGray,
      selectedDotColor: colors.lightestGray
    }
    const virtual = {
      key: 'virtual',
      color: colors.stagingRed,
      selectedDotColor: colors.lightRed
    }
    if (byMonthYear) {
      const monthYearObject = {
        ...byMonthYear[activeMonthYear?.month + '.' + activeMonthYear?.year]
      }

      if (monthYearObject) {
        Object.keys(monthYearObject).map((key) => {
          const dotsArray = [
            monthYearObject[key].marked?.entity ? entity : undefined,
            monthYearObject[key].marked?.virtual ? virtual : undefined
          ].filter((a) => a)
          monthYearObject[key] = {
            dots: dotsArray,
            selected: key === activeDate
          }
        })
      }

      return monthYearObject
    }

    return null
  }, [activeMonthYear, byMonthYear, activeDate])

  const refetchMonth = () => {
    setMonthLoading(true)
    dispatch(fetchCalendarEventsMonth(activeMonthYear)).finally(() => {
      setMonthLoading(false)
    })
  }
  useEffect(() => {
    if (activeMonthYear) {
      refetchMonth()
    }
  }, [activeMonthYear?.month, activeMonthYear?.year])

  const [monthLoading, setMonthLoading] = useState(false)

  return [markedMonthYear, monthLoading, setActiveMonthYear, refetchMonth]
}
