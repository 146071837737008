import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { SelectWithData } from './SelectWithData'

export const EntityTypePicker = React.forwardRef((props, ref) => {
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  const { onEntityTypeSelected, currentEntityType } = props
  return (
    <SelectWithData
      {...props}
      dataRows={entityTypes}
      labelField={'label'}
      valueField={'id'}
      value={currentEntityType}
      onChangeText={onEntityTypeSelected}
    />
  )
})
