import api from '../../data/api'
import _ from 'lodash'
const getFilterString = (args) => {
  return _.map(args, (value, key) => {
    return `${key}=${encodeURIComponent(value)}`
  }).join('&')
}


const createConversation = ({ participants }) => {
  return (dispatch) => {
    dispatch({
      type: 'conversation/conversation-create-started',
      data: { participants }
    })


    const route = `/conversations/`

    const payload = { participants }

    return api.post(route, payload) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'conversation/conversation-create-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'conversation/conversation-create-error',
          error
        })

        console.error(error);
        throw error
      })
  }
}

const addMessageToConversation = (id, body) => {
  return (dispatch) => {
    dispatch({
      type: 'conversation/message-insert-started',
      data: { id, body }
    })


    const route = `/conversations/${id}/messages`

    const payload = { message: { body } }

    return api.post(route, payload) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'conversation/message-insert-success',
          response,
          id,
          body
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'conversation/message-insert-error',
          error
        })

        console.error(error);
        // throw error
      })
  }
}
const listMessages = (id, args) => {
  const filterString = getFilterString(args)
  const route = `/conversations/${id}/messages?${filterString}`

  return (dispatch) => {
    dispatch({
      type: 'conversation/message-list-started',
      id,
      filterString
    })
    return api
      .get(route)
      .then(async (response) => {

        await dispatch({
          type: 'conversation/message-list-success',
          response,
          id,
          args
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'conversation/message-list-error',
          error
        })

        console.error(error);
        // throw error
      })
  }

}
const listAllConversations = () => {
  return (dispatch) => {
    const route = '/conversations'

    dispatch({
      type: 'conversation/list-started'
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'conversation/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'conversation/list-error',
          error
        })

        console.error(error);
        // throw error
      })
  }
}

export default { listAllConversations, listMessages, addMessageToConversation, createConversation }
