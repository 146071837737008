import React from 'react';

import ReCAPTCHA from "react-google-recaptcha";

const RecaptchaContainer = (props) => {

  const { onChange } = props
  return <ReCAPTCHA
    sitekey="6Lf35BkhAAAAAF6OI-lncbgTuWWhR1IL4ettEkw_"
    onChange={onChange}
  />
}

export default RecaptchaContainer