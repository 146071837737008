import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Text, View } from 'react-native'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import CrossFieldReferencesContext from '../../contexts/CrossFieldReferencesContext'
import CrossFieldRenderAsNullContext from '../../contexts/CrossFieldRenderAsNullContext'
import MSFESButton from '../MSFESButton'
import _ from 'lodash'
import entityActions, {
  viewOrCreateEntity,
  upsertEntity
} from '../../store/entity/actions'
import { MSFESFieldTile } from '../MSFESFieldTile'
import MSFESHeading from '../MSFESHeading'
import { headerTextStyle } from '../WithHeader'
import spacing from '../../styles/spacing'
import EntityEditContext from '../../contexts/EntityEditContext'
import LoadingOverlay from '../LoadingOverlay'
import { simplifyObject } from './SelectWithData'
import { useTimeFormat } from '../../contexts/TimeFormatContext'
import { getDateTimeFormatForTimeFormat } from './DateTimePicker'

const punchInFields = [
  'time_in',
  'time_in1',
  'time_in2',
  'time_in3',
  'time_in4',
  'time_in5',
  'time_in6'
]
const punchOutFields = [
  'time_out',
  'time_out1',
  'time_out2',
  'time_out3',
  'time_out4',
  'time_out5',
  'time_out6'
]
const mealbreakRefs = [
  'meal_break',
  'meal_break1',
  'meal_break2',
  'meal_break3',
  'meal_break4',
  'meal_break5',
  'meal_break6'
]
const sleeptimeFields = [
  'did_you_receive_sleep_time_during_this_shift',
  'did_you_receive_sleep_time_during_this_shift1',
  'did_you_receive_sleep_time_during_this_shift2',
  'did_you_receive_sleep_time_during_this_shift3',
  'did_you_receive_sleep_time_during_this_shift4',
  'did_you_receive_sleep_time_during_this_shift5',
  'did_you_receive_sleep_time_during_this_shift6'
]
const uninterruptedFields = [
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift1',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift2',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift3',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift4',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift5',
  'how_many_uninterrupted_5_hour_periods_of_sleep_did_you_receive_this_shift6'
]
const callbackRefs = [
  'call_back',
  'call_back1',
  'call_back2',
  'call_back3',
  'call_back4',
  'call_back5',
  'call_back6',
  'callback_yn',
  'callback_yn1',
  'callback_yn2',
  'callback_yn3',
  'callback_yn4',
  'callback_yn5',
  'callback_yn6'
]

const incidentalFields = [
  'personnel',
  'shifts_worked_this_week',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const getNowObject = () => {
  const now = DateTime.now()
  const nowValue = now.toISO()
  const nowDisplay = now.toFormat('yyyy-MM-dd h:mm:ss a')
  return { value: nowValue, display_value: nowDisplay }
}

const findCurrentOrMostRecentShift = (
  entity,
  weekStartObj,
  shiftsInThisTimesheet,
  mealbreaksInThisTimesheet
) => {
  let obj = {
    isCurrentlyPunchedIn: false,
    isCurrentlyOnBreak: false,
    currentOrMostRecentShift: null,
    currentOrMostRecentShiftIsFinished: false
  }

  let openShift = null

  const sorted = _.sortBy(shiftsInThisTimesheet, (s) => s.time_to?.value)
  sorted.forEach((s) => {
    if (s.time_from?.value && !s.time_to?.value) {
      // open shift.
      openShift = s
    }
  })

  let openBreak = null

  const sortedBreaks = _.sortBy(
    mealbreaksInThisTimesheet,
    (s) => s.time_to?.value
  )
  sortedBreaks.forEach((s) => {
    if (s.time_from?.value && !s.time_to?.value) {
      // open shift.
      openBreak = s
    }
  })

  const isCurrentlyOnBreak = !!openBreak
  if (openShift) {
    return {
      ...obj,
      isCurrentlyPunchedIn: true,
      isCurrentlyOnBreak,
      breakId: openBreak,
      currentOrMostRecentShift: openShift,
      currentOrMostRecentShiftIsFinished: false
    }
  } else {
    // return most recently completed.
    const mostRecentShift = sorted[sorted.length - 1]

    return {
      ...obj,
      isCurrentlyPunchedIn: false,
      isCurrentlyOnBreak,
      breakId: openBreak,
      currentOrMostRecentShift: mostRecentShift,
      currentOrMostRecentShiftIsFinished: true
    }
  }
}

export const CustomFunctionalityTimesheetPunchCard = (props) => {
  const dispatch = useDispatch()

  const { timeFormat } = useTimeFormat()

  const format = getDateTimeFormatForTimeFormat(timeFormat)

  const [, , , saveEntity] = useContext(EntityEditContext)

  const { setCrossFieldReferences } = useContext(CrossFieldReferencesContext)
  const { setCrossFieldRenderAsNull } = useContext(
    CrossFieldRenderAsNullContext
  )

  const [shiftsInThisTimesheet, setShiftsInThisTimesheet] = useState([])
  const [mealbreaksInThisTimesheet, setMealbreaksInThisTimesheet] = useState([])

  const [hasLoadedShifts, setHasLoadedShifts] = useState(false)
  const [hasLoadedTimesheets, setHasLoadedTimesheets] = useState(false)
  const [isReadyToDisplay, setIsReadyToDisplay] = useState(false)
  useEffect(() => {
    if (hasLoadedShifts && hasLoadedTimesheets) {
      setIsReadyToDisplay(true)
    }
  }, [hasLoadedShifts, hasLoadedTimesheets])

  useEffect(() => {
    reloadShifts()
    reloadMealBreaks()
  }, [''])

  const calculatedStyle = [
    props.style,
    { paddingLeft: spacing.m1, height: null }
  ]

  const { data } = props

  const currentOrMostRecentShiftWrapper = useMemo(() => {
    if (!props.data.entity?.week_start) {
      return null
    }
    const weekStartObj = DateTime.fromISO(props.data.entity.week_start.value)

    const shift = findCurrentOrMostRecentShift(
      data.entity,
      weekStartObj,
      shiftsInThisTimesheet,
      mealbreaksInThisTimesheet
    )
    return shift
  }, [
    props.data.entity.week_start?.value,
    props.data.entity,
    shiftsInThisTimesheet,
    mealbreaksInThisTimesheet
  ])

  const [detailsVisible, setDetailsVisible] = useState(false)

  const [isFirstPass, setIsFirstPass] = useState(true)

  async function saveAndRequery() {
    await saveEntity({
      showLoading: false,
      showToasts: false
    })

    reloadShifts()

    reloadMealBreaks()
  }

  useEffect(() => {
    // this gets stuck after punching on. <-----
    // this needs to replicate for meal breaks. <----
    if (!isFirstPass) {
      // a change.

      saveAndRequery()
    }
    setIsFirstPass(false)
  }, [
    props.data.entity?.shifts_worked_this_week?.length,
    props.data.entity?.meal_break?.length
  ])
  useEffect(() => {
    if (detailsVisible) {
      showDetailsFields()
    } else {
      hideDetailsFields()
    }
  }, [detailsVisible])

  const hideDetailsFields = () => {
    const allFieldsToSuppress = [
      ...punchInFields,
      ...sleeptimeFields,
      ...punchOutFields,
      ...uninterruptedFields,
      ...mealbreakRefs,
      ...callbackRefs,
      ...incidentalFields
    ]
    const suppressObject = allFieldsToSuppress.reduce(
      (a, v) => ({ ...a, [v]: false }),
      {}
    )
    setCrossFieldRenderAsNull((crossFieldRenderAsNullContext) => ({
      ...crossFieldRenderAsNullContext,
      suppressObject
    }))
  }

  const showDetailsFields = () => {
    const allFieldsToSuppress = [
      ...punchInFields,
      ...sleeptimeFields,
      ...punchOutFields,
      ...uninterruptedFields,
      ...mealbreakRefs,
      ...callbackRefs,
      ...incidentalFields
    ]
    let suppressObject = allFieldsToSuppress.reduce(
      (a, v) => ({ ...a, [v]: false }),
      {}
    )

    suppressObject['shifts_worked_this_week'] = true

    setCrossFieldRenderAsNull((crossFieldRenderAsNullContext) => ({
      ...crossFieldRenderAsNullContext,
      suppressObject
    }))
  }
  useEffect(() => {
    setDetailsVisible(false)

    setCrossFieldReferences((crossFieldReferences) => {
      const thisProperty = {
        ...crossFieldReferences['shifts_worked_this_week']
      }
      thisProperty.successHandler = (response) => {
        // when the shift is created in the subcomponent, drop it in the local data so the user gets clocked on.
        const newShiftsInThisTimesheet = [...shiftsInThisTimesheet]
        newShiftsInThisTimesheet.push(response.data)
        setShiftsInThisTimesheet(newShiftsInThisTimesheet)
      }
      return {
        ...crossFieldReferences,
        ['shifts_worked_this_week']: thisProperty
      }
    })
  }, [''])

  const onPunchOut = () => {
    // console.log(currentOrMostRecentShiftWrapper.punchOutProperty, crossFieldReferences)
    // crossFieldReferences[currentOrMostRecentShiftWrapper.punchOutProperty].primaryAction();

    const now = DateTime.now()
    const display_value = now.toLocaleString(format)
    setIsReadyToDisplay(false)
    dispatch(
      upsertEntity(
        {
          ...currentOrMostRecentShiftWrapper.currentOrMostRecentShift,
          time_to: { display_value, value: now.toISO() }
        },
        { name: 'worked_shift' },
        'edit'
      )
    ).then(() => {
      reloadShifts().finally(() => {
        setIsReadyToDisplay(true)
      })
    })
  }

  const reloadShifts = () => {
    return dispatch(
      entityActions.listEntity(
        { name: 'worked_shift' },
        {
          filter: { time_sheet: props.data.entity?.id },
          detailed: true
        }
      )
    ).then((response) => {
      setShiftsInThisTimesheet(response.data)
      setHasLoadedShifts(true)
    })
  }

  const reloadMealBreaks = () => {
    return dispatch(
      entityActions.listEntity(
        { name: 'meal_break' },
        { filter: { time_sheet: props.data.entity?.id }, detailed: true }
      )
    ).then((response) => {
      setMealbreaksInThisTimesheet(response.data)
      setHasLoadedTimesheets(true)
    })
  }
  const onPunchIn = async () => {
    // crossFieldReferences['shifts_worked_this_week'].primaryAction()

    const now = DateTime.now()
    const simpleTimesheet = simplifyObject(props.data.entity)
    setIsReadyToDisplay(false)
    // create and save a timesheet with now as the start time
    await viewOrCreateEntity(
      { name: 'worked_shift' },
      {
        filter: { time_from: '' }
      },
      {
        time_sheet: simpleTimesheet,
        time_from: {
          display_value: now.toLocaleString(format),
          value: now.toISO()
        }
      }
    )().then(() => {
      // make sure to reload parents etc.
      reloadShifts().finally(() => {
        setIsReadyToDisplay(true)
      })
    })
  }
  const onFinishBreak = () => {
    const now = DateTime.now()
    const display_value = now.toLocaleString(format)
    setIsReadyToDisplay(false)
    dispatch(
      upsertEntity(
        {
          ...currentOrMostRecentShiftWrapper.breakId,
          time_to: { display_value, value: now.toISO() }
        },
        { name: 'meal_break' },
        'edit'
      )
    ).then(() => {
      reloadMealBreaks().finally(() => {
        setIsReadyToDisplay(true)
      })
    })
  }

  const onTakeABreak = async () => {
    const now = DateTime.now()
    const simpleTimesheet = simplifyObject(props.data.entity)
    setIsReadyToDisplay(false)
    // create and save a timesheet with now as the start time
    await viewOrCreateEntity(
      { name: 'meal_break' },
      {
        filter: { time_from: '' }
      },
      {
        time_sheet: simpleTimesheet,
        time_from: {
          display_value: now.toLocaleString(format),
          value: now.toISO()
        }
      }
    )().then(() => {
      // make sure to reload parents etc.
      reloadMealBreaks().finally(() => {
        setIsReadyToDisplay(true)
      })
    })
  }

  const onToggleDetails = () => {
    setDetailsVisible((detailsVisible) => !detailsVisible)
  }

  const onTime =
    currentOrMostRecentShiftWrapper?.currentOrMostRecentShift?.time_from &&
    DateTime.fromISO(
      currentOrMostRecentShiftWrapper.currentOrMostRecentShift?.time_from?.value
    )
  const offTime = currentOrMostRecentShiftWrapper?.currentOrMostRecentShift
    ?.time_to
    ? DateTime.fromISO(
        currentOrMostRecentShiftWrapper.currentOrMostRecentShift?.time_to?.value
      )
    : null
  const isCurrentlyOnBreak = currentOrMostRecentShiftWrapper?.isCurrentlyOnBreak
  const isCurrentlyOn = onTime && !offTime
  const punchedInTime = onTime ? onTime.toFormat('D\nh:mm:ss a') : null
  const offTimeOrNow = offTime ? offTime : DateTime.now()

  const hoursWorked = onTime
    ? Math.round(offTimeOrNow.diff(onTime).as('hours') * 10) / 10
    : false
  const minutesWorked = onTime
    ? Math.round(offTimeOrNow.diff(onTime).as('minutes'))
    : false
  const timeWorkingEstimate = onTime
    ? hoursWorked > 0
      ? hoursWorked + 'h'
      : minutesWorked + 'm'
    : null

  return (
    <View style={calculatedStyle}>
      {!isReadyToDisplay && <LoadingOverlay />}
      {isReadyToDisplay && (
        <View>
          <View style={{ flexDirection: 'row' }}>
            {
              <Text
                style={[
                  headerTextStyle,
                  { flex: 1, marginTop: spacing.m2, marginBottom: spacing.m1 }
                ]}
              >
                {isCurrentlyOn ? 'On Shift' : ' Not punched in.'}
              </Text>
            }
          </View>
          <View style={{ flexDirection: 'row', paddingRight: spacing.m1 }}>
            <MSFESHeading style={{ flex: 1, backgroundColor: 'transparent' }}>
              {isCurrentlyOn ? 'Current Shift' : 'Previous Shift'}
            </MSFESHeading>
          </View>
          {onTime && (
            <View
              style={{ flexDirection: 'row', marginLeft: -spacing.m1, flex: 1 }}
            >
              <MSFESFieldTile label="Start Time" style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: '400',
                    textAlign: 'center',
                    color: '#555'
                  }}
                >
                  {punchedInTime}
                </Text>
              </MSFESFieldTile>
              <MSFESFieldTile
                label="Time On Shift"
                value={`${timeWorkingEstimate}`}
                style={{ flex: 1 }}
              />
            </View>
          )}

          <View style={{ flexDirection: 'row', marginLeft: -spacing.m1 }}>
            {isCurrentlyOn && (
              <>
                {!isCurrentlyOnBreak && (
                  <MSFESButton
                    small
                    title="Punch Out"
                    containerStyle={{ padding: 0, flex: 1 }}
                    onPress={onPunchOut}
                  />
                )}
                {isCurrentlyOnBreak && (
                  <MSFESButton
                    small
                    title="Finish Break"
                    containerStyle={{ padding: 0, flex: 1 }}
                    onPress={onFinishBreak}
                  />
                )}
              </>
            )}
            {isCurrentlyOn && !isCurrentlyOnBreak && (
              <MSFESButton
                small
                variant="outline"
                title={`Take a break`}
                containerStyle={{ padding: 0, flex: 1 }}
                onPress={onTakeABreak}
              />
            )}
          </View>

          <View style={{ flexDirection: 'row', marginLeft: -spacing.m1 }}>
            {!isCurrentlyOn && (
              <MSFESButton
                small
                disabled={currentOrMostRecentShiftWrapper?.hasPunchedToday}
                containerStyle={{ padding: 0, flex: 1 }}
                onPress={onPunchIn}
                title="Punch In"
              />
            )}

            {/* 
{isCurrentlyOn && <MSFESButton small variant="outline" title={`Add a callback`} containerStyle={{padding: 0, flex: 1}} onPress={onAddCallback} />} */}
            {
              <MSFESButton
                small
                variant="outline"
                title={detailsVisible ? 'Hide Details' : `Show Details`}
                containerStyle={{ padding: 0, flex: 1 }}
                onPress={onToggleDetails}
              />
            }
          </View>
        </View>
      )}
    </View>
  )
}
