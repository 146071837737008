import React, { useMemo } from 'react'
import { View } from 'react-native'
import { Icon } from 'react-native-elements'
import colors from '../styles/colors'

const MSFESIcon = React.forwardRef((props, ref) => {
  const { tappable, dark = false, style = {} } = props

  const containerStyle = [props.containerStyle]

  const tappableStyle = { padding: 12, minWidth: 48 }
  if (tappable) {
    containerStyle.push({ padding: 12 })
  }

  let color = props.color
  if (dark) {
    color = colors.iconLight
  }

  const adjustedStyle = useMemo(() => {
    return [style, tappable && tappableStyle]
  }, [tappable])

  return (
    <View ref={ref}>
      <Icon
        type="font-awesome-5"
        {...props}
        ref={ref}
        color={color}
        iconStyle={adjustedStyle}
      />
    </View>
  )
})

export default MSFESIcon
