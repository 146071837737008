import React from 'react'
import { Dimensions } from 'react-native'
import { View, Text, StyleSheet } from 'react-native'
import { Divider } from 'react-native-elements'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'

const { width } = Dimensions.get('window')

export default function OverlayHeader({ children, style }) {
  return (
    <View style={style}>
      <View
        style={[
          headerStyles.containerStyle,
          { minWidth: width < 500 ? width * 0.9 : 400 }
        ]}
      >
        <Text style={[headerStyles.textStyle]}>{children}</Text>
      </View>
      <Divider />
    </View>
  )
}

export const headerStyles = StyleSheet.create({
  containerStyle: {
    textAlign: 'center',
    justifyContent: 'center',
    paddingVertical: spacing.m2
  },
  textStyle: {
    textAlign: 'center',
    marginHorizontal: spacing.m2,
    ...fonts.headerStyle
  }
})
