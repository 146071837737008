import React from 'react'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'
export default {
  Bell: (
    <G>
      <Path
        d="M20.3067 28C20.0723 28.4041 19.7358 28.7395 19.331 28.9727C18.9261 29.2058 18.4672 29.3286 18 29.3286C17.5328 29.3286 17.0739 29.2058 16.669 28.9727C16.2642 28.7395 15.9277 28.4041 15.6933 28M26 10.6666C26 8.54489 25.1571 6.51006 23.6569 5.00977C22.1566 3.50948 20.1217 2.66663 18 2.66663C15.8783 2.66663 13.8434 3.50948 12.3431 5.00977C10.8429 6.51006 10 8.54489 10 10.6666C10 20 6 22.6666 6 22.6666H30C30 22.6666 26 20 26 10.6666Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  ),
  Home: (
    <G transform={'translate(1,0)'}>
      <Path
        d="M5.25433 12.0965L18.638 1.68701L32.0216 12.0965V28.4543C32.0216 29.243 31.7082 29.9995 31.1505 30.5573C30.5927 31.115 29.8362 31.4284 29.0474 31.4284H8.22847C7.43968 31.4284 6.6832 31.115 6.12544 30.5573C5.56768 29.9995 5.25433 29.243 5.25433 28.4543V12.0965Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="auto"
      />
    </G>
  ),
  HorizontalMenu: (
    <G transform={'translate(2,8)'}>
      <Path
        d="M5.01936 10.3423H30.8944M5.01936 1.71735H30.8944M5.01936 18.9673H30.8944"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  ),
  Handbook: (
    <G transform={'translate(2,3)'}>
      <Path
        d="M18.681 7.34454C18.681 5.81955 18.0334 4.35701 16.8807 3.27868C15.728 2.20035 14.1646 1.59454 12.5344 1.59454H3.31461V23.157H14.0711C15.2937 23.157 16.4662 23.6114 17.3308 24.4201C18.1953 25.2289 18.681 26.3258 18.681 27.4695M18.681 7.34454V27.4695M18.681 7.34454C18.681 5.81955 19.3286 4.35701 20.4813 3.27868C21.634 2.20035 23.1974 1.59454 24.8275 1.59454H34.0474V23.157H23.2909C22.0683 23.157 20.8957 23.6114 20.0312 24.4201C19.1667 25.2289 18.681 26.3258 18.681 27.4695"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="auto"
      />
    </G>
  ),
  SpeechBubble: (
    <G transform={'translate(3,4)'}>
      <Path
        d="M30.5323 18.4651C30.5323 19.2276 30.2294 19.9589 29.6902 20.4981C29.1511 21.0372 28.4198 21.3401 27.6573 21.3401H10.4073L4.6573 27.0901V4.09015C4.6573 3.32765 4.9602 2.59638 5.49937 2.05722C6.03854 1.51805 6.76981 1.21515 7.5323 1.21515H27.6573C28.4198 1.21515 29.1511 1.51805 29.6902 2.05722C30.2294 2.59638 30.5323 3.32765 30.5323 4.09015V18.4651Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="auto"
      />
    </G>
  ),
  CircledTick: (
    <G transform={'translate(2,2)'}>
      <Path
        d="M33.0259 13.8302V15.1527C33.0241 18.2526 31.9857 21.2688 30.0656 23.7516C28.1456 26.2343 25.4467 28.0506 22.3716 28.9296C19.2964 29.8085 16.0097 29.7029 13.0017 28.6287C9.99368 27.5544 7.42547 25.569 5.68011 22.9685C3.93474 20.368 3.10574 17.2918 3.31673 14.1987C3.52772 11.1055 4.76741 8.16117 6.8509 5.80474C8.9344 3.44831 11.7501 1.80606 14.878 1.12291C18.0059 0.439756 21.2784 0.752307 24.2076 2.01395M33.0259 3.6527L18.1552 18.0421L13.694 13.7296"
        strokeLinecap="round"
        strokeLinejoin="round"
        shapeRendering="auto"
      />
    </G>
  ),
  FieldsText: (
    <G>
      <Path d="M32 33.3333H8.00002C7.26402 33.3333 6.66669 32.736 6.66669 32V7.99999C6.66669 7.26399 7.26402 6.66666 8.00002 6.66666H32C32.736 6.66666 33.3334 7.26399 33.3334 7.99999V32C33.3334 32.736 32.736 33.3333 32 33.3333Z" />
      <Path d="M20 28V13.3333M13.3333 14.6667H26.6666" />
    </G>
  ),
  FieldsDateTime: (
    <G transform={'translate(4,0)'}>
      <Path
        d="M23.6821 2.32835H21.4952V0.652054C21.4952 0.479118 21.429 0.313266 21.3112 0.190982C21.1933 0.0686983 21.0335 0 20.8669 0C20.7002 0 20.5404 0.0686983 20.4226 0.190982C20.3047 0.313266 20.2385 0.479118 20.2385 0.652054V2.32835H6.86571V0.652054C6.86571 0.479118 6.79951 0.313266 6.68167 0.190982C6.56384 0.0686983 6.40402 0 6.23737 0C6.07073 0 5.9109 0.0686983 5.79307 0.190982C5.67523 0.313266 5.60903 0.479118 5.60903 0.652054V2.32835H3.14168C2.30875 2.32934 1.5102 2.67315 0.921224 3.28435C0.33225 3.89556 0.000947926 4.72425 0 5.58862V23.2131C0.000947926 24.0775 0.33225 24.9062 0.921224 25.5174C1.5102 26.1286 2.30875 26.4724 3.14168 26.4734H23.682C24.5149 26.4724 25.3135 26.1286 25.9025 25.5174C26.4914 24.9062 26.8227 24.0775 26.8237 23.2131V5.58856C26.8227 4.72421 26.4914 3.89555 25.9025 3.28436C25.3135 2.67316 24.515 2.32936 23.6821 2.32835ZM25.5671 23.2131C25.5666 23.7317 25.3678 24.229 25.0144 24.5957C24.661 24.9624 24.1819 25.1687 23.6821 25.1693H3.14168C2.64192 25.1687 2.16279 24.9624 1.8094 24.5957C1.45602 24.229 1.25724 23.7317 1.25667 23.2131V10.9083H25.567L25.5671 23.2131ZM1.25667 9.60423V5.58856C1.25724 5.06993 1.45602 4.57272 1.8094 4.20599C2.16279 3.83927 2.64192 3.63298 3.14168 3.6324H5.6091V5.30863C5.6091 5.48157 5.6753 5.64742 5.79313 5.7697C5.91097 5.89199 6.07079 5.96069 6.23743 5.96069C6.40408 5.96069 6.5639 5.89199 6.68173 5.7697C6.79957 5.64742 6.86577 5.48157 6.86577 5.30863V3.6324H20.2386V5.30863C20.2386 5.48157 20.3048 5.64742 20.4226 5.7697C20.5405 5.89199 20.7003 5.96069 20.8669 5.96069C21.0336 5.96069 21.1934 5.89199 21.3112 5.7697C21.4291 5.64742 21.4953 5.48157 21.4953 5.30863V3.6324H23.6822C24.1819 3.63298 24.6611 3.83927 25.0145 4.20599C25.3679 4.57272 25.5666 5.06993 25.5672 5.58856V9.60423H1.25667Z"
        fill="#C7CFD2"
      />
      <G clipPath="url(#clip0)">
        <Path
          d="M23.2323 29.025C26.4134 29.025 28.9921 26.5304 28.9921 23.4531C28.9921 20.3758 26.4134 17.8812 23.2323 17.8812C20.0513 17.8812 17.4726 20.3758 17.4726 23.4531C17.4726 26.5304 20.0513 29.025 23.2323 29.025Z"
          fill="#C4C4C4"
        />
        <Path d="M23.2323 20.11V23.4531L25.5362 24.5675" />
        <Path
          d="M23.2323 20.11V23.4531L25.5362 24.5675M28.9921 23.4531C28.9921 26.5304 26.4134 29.025 23.2323 29.025C20.0513 29.025 17.4726 26.5304 17.4726 23.4531C17.4726 20.3758 20.0513 17.8812 23.2323 17.8812C26.4134 17.8812 28.9921 20.3758 28.9921 23.4531Z"
          stroke="#8F9EA5"
        />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect
            width="13.8234"
            height="13.3726"
            fill="white"
            transform="translate(16.1766 16.6275)"
          />
        </ClipPath>
      </Defs>
    </G>
  ),
  FieldsDate: (
    <G transform={'translate(4,0)'}>
      <Path d="M20 2.5V7.5M10 2.5V7.5M3.75 12.5H26.25M6.25 4.6875H23.75C25.1307 4.6875 26.25 5.80679 26.25 7.1875V24.6875C26.25 26.0682 25.1307 27.1875 23.75 27.1875H6.25C4.86929 27.1875 3.75 26.0682 3.75 24.6875V7.1875C3.75 5.80679 4.86929 4.6875 6.25 4.6875Z" />
    </G>
  )
}
