import React, { useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { useDispatch } from 'react-redux'
import { forceActionRun } from '../../store/entity/actions'
import MSFESButton from '../MSFESButton'
import spacing from '../../styles/spacing'

export const ActionTruthRunnerButton = ({ entity, entityType, action }) => {
  const [loading, setLoading] = useState(false)
  const [hasRun, setHasRun] = useState(false)
  const dispatch = useDispatch()

  const _forceActionRun = ({ entity, entityType, action }) => {
    setLoading(true)
    return dispatch(
      forceActionRun({ id: entity.id, actionId: action.offline_id, entityType })
    )
      .then(() => {
        setHasRun(true)
        showMessage({
          message: 'Action has been queued. Please check history for details.',
          type: 'success'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return loading ? (
    <ActivityIndicator />
  ) : (
    <MSFESButton
      title="Force Run"
      disabled={hasRun}
      style={{ paddingTop: spacing.m1 }}
      onPress={() => _forceActionRun({ entity, entityType, action })}
    />
  )
}
