import _ from 'lodash'
import React from 'react'
import SelectWithData from './SelectWithData'

export const SelectAdhoc = React.forwardRef((props, ref) => {
  const options = _.get(props, 'data.field.field_data.params.options', [])

  const defaultOption = options.find((o) => o.defaultValue)
  return (
    <SelectWithData
      {...props}
      dataRows={options}
      labelField="label"
      keyField="key"
      valueField="key"
      defaultValue={defaultOption}
      value={props.defaultValue}
    />
  )
})
