import React from 'react'
import { View, Text } from 'react-native'
import { useDropdownHelper } from '../../useDropdownHelper'
import MSFESChip from '../MSFESChip'
import { FolderDropdown } from './FolderDropdown'

export const Breadcrumb = ({
  folders,
  folder,
  isLast,
  nestDepth = 0,
  onPress,
  setCurrentRootFolder
}) => {
  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(240, 0)

  return (
    <View style={{ flexDirection: 'row' }} key={folder?.id} ref={buttonRef}>
      <MSFESChip
        onPress={() => {
          onPress && onPress()

          toggleDropdownState()
        }}
      >
        {folder?.folder_name}
      </MSFESChip>
      <FolderDropdown
        setCurrentRootFolder={setCurrentRootFolder}
        selectableSubfolders={{
          folders: folders?.data?.filter(
            (f) => f.parent_folder?.id === folder?.parent_folder?.id
          ),
          nestDepth
        }}
        dropdownVisibilityState={dropdownVisibilityState}
        dropdownOverlayStyle={dropdownOverlayStyle}
        toggleDropdownState={toggleDropdownState}
      />
      {!isLast && !!folder?.folder_name && (
        <Text style={{ paddingTop: 8, paddingRight: 8 }}> / </Text>
      )}
    </View>
  )
}
