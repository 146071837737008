import React, { useEffect, useState } from 'react';
import { View } from "react-native"
import { ListItem } from 'react-native-elements';
import { Portal } from 'react-native-paper';
import ColorPickerOverlay from '../ColorPicker';
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps';

const ColorPickerField = React.forwardRef((props, ref) => {


  const [localValue, setLocalValue] = useState(null);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {

    if (props.defaultValue && !localValue) {
      setLocalValue(props.defaultValue);
      setRenderKey(renderKey +1)
    }
  }, [props.defaultValue])




  const [visible, setVisible] = useState(false)

  const onPressAction = props.editable ? () =>  {

    setVisible(true)
   } : null

  return (
    <View style={props.style}>
    <ListItem onPress={onPressAction} containerStyle={ListItemAsFieldProps.containerStyle} {...ListItemAsFieldProps.touchableProps}>
      <ListItem.Content>
        <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>{localValue}</ListItem.Title>
      </ListItem.Content>
      <ListItem.Chevron type="font-awesome" name="square" size={14} color={localValue} />
    </ListItem>
    <Portal>
    {visible && <ColorPickerOverlay onClosePressed={() => setVisible(false)} color={props.value} onColorChanged={(newColor) => {
      setLocalValue(newColor)
    
      props.changeHandler(newColor);
    }} />}

    </Portal>
  </View>
  )
})


export default ColorPickerField