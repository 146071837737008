// libraries

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

// components
import NotificationsContainer from '../components/NotificationsContainer'

import WithHeader from '../components/WithHeader'


export default function NotificationsScreen({ route, navigation }) {


   return (
     <WithHeader title="Notifications" navigation={navigation}>

            <NotificationsContainer />
     </WithHeader>
  )
}
