// libraries
import React from 'react'
import { useSelector } from 'react-redux'
import { View, Text } from 'react-native'
import _ from 'lodash'
import { ViewDescription } from './ViewDescription'
import { DateTime } from 'luxon'

import { getDisplayLabelForEntity } from '../../libraries/entityTools'

import { MSFESCheckbox } from '../MSFESCheckbox'
import spacing from '../../styles/spacing'
import colors from '../../styles/colors'
import { TouchableOpacity } from 'react-native-gesture-handler'
import fonts from '../../styles/fonts'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
export const TermsAndConditions = React.forwardRef((props, ref) => {
  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const { value, changeHandler } = props

  const { entityType } = useDiscoverEntityEntityType(null, null, 'personnel')

  const checked = _.get(value, 'checked', false)
  const declineChecked = _.get(value, 'declineChecked', false)
  const checkedByName = _.get(value, 'checkedByName', false)
  const declinedByName = _.get(value, 'declinedByName', false)
  const display_value = _.get(value, 'display_value', false).toString()

  const agreeCheckboxChecked = () => {
    const newState = !checked

    const dtObject = DateTime.fromJSDate(new Date())
    changeHandler({
      checked: newState,
      checkedBy: current_user.personnel_record?.id,
      checkedByName: getDisplayLabelForEntity(
        current_user.personnel_record,
        entityType
      ),
      declineChecked: false,
      declinedBy: null,
      declinedByName: null,
      checkedAt: new Date(),
      display_value: dtObject.toFormat('yyyy-MM-dd h:mm a')
    })
  }
  const declineCheckboxChecked = () => {
    const newState = !declineChecked

    const dtObject = DateTime.fromJSDate(new Date())
    changeHandler({
      checked: false,
      checkedBy: null,
      checkedByName: null,
      declineChecked: newState,
      declinedBy: current_user.personnel_record.id,
      declinedByName: getDisplayLabelForEntity(
        current_user.personnel_record,
        entityType
      ),
      checkedAt: new Date(),
      display_value: dtObject.toFormat('yyyy-MM-dd h:mm a')
    })
  }

  const { editable } = props

  const inputStyle = [
    props.inputStyle,
    {
      color: colors.inputText,
      fontFamily: fonts.inputTextFace,
      fontWeight: 'bold'
    }
  ]

  const rowStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingTop: spacing.m0_5,
    paddingLeft: spacing.m2,
    marginRight: -12
  }
  return (
    <View style={{ flex: 1 }}>
      <ViewDescription
        data={props.data}
        textField={'data.field.field_data.params.termsText'}
      />

      <TouchableOpacity
        disabled={!editable}
        onPress={agreeCheckboxChecked}
        style={rowStyle}
      >
        <Text style={[{ flex: 1 }, inputStyle]}>I Accept</Text>
        <MSFESCheckbox
          disabled={!editable}
          radio={false}
          onPress={agreeCheckboxChecked}
          checked={checked}
        />
      </TouchableOpacity>

      <TouchableOpacity
        disabled={!editable}
        onPress={declineCheckboxChecked}
        style={rowStyle}
      >
        <Text style={[{ flex: 1 }, inputStyle]}>I Decline</Text>
        <MSFESCheckbox
          disabled={!editable}
          radio={false}
          onPress={declineCheckboxChecked}
          checked={declineChecked}
        />
      </TouchableOpacity>

      {checked && (
        <Text>
          Agreed by {checkedByName} at {display_value}
        </Text>
      )}
      {declineChecked && (
        <Text>
          Declined by {declinedByName} at {display_value}
        </Text>
      )}
    </View>
  )
})
