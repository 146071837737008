import React, { Component } from 'react'
import { View, Platform } from 'react-native'

import ColorPicker from 'react-native-wheel-color-picker'
import { Overlay } from 'react-native-elements'
import MSFESButton from './MSFESButton'
import spacing from '../styles/spacing'
import { Portal } from 'react-native-paper'
class ColorPickerOverlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      color: props.color
    }
  }
  onColorChange = (color) => {
    this.setState({ color })
  }
  onColorChangeComplete = () => {}
  acceptPressed = () => {
    this.props.onColorChanged(this.state.color)
    this.props.onClosePressed()
  }
  cancelPressed = () => {
    this.props.onClosePressed()
  }

  render() {
    const swatchesOnly = false

    const picker = (
      <Overlay isVisible={true} onBackdropPress={this.props.onClosePressed}>
        <View style={{ width: '100%' }}>
          <ColorPicker
            ref={(r) => {
              this.picker = r
            }}
            color={this.props.color}
            swatchesOnly={swatchesOnly}
            onColorChange={this.onColorChange}
            onColorChangeComplete={this.onColorChangeComplete}
            thumbSize={40}
            sliderSize={40}
            noSnap={true}
            row={false}
            discrete={true}
          />
          <View
            style={{
              flexDirection: 'row',
              marginTop: spacing.m1,
              width: '100%'
            }}
          >
            <MSFESButton
              modifier="outline"
              containerStyle={{ flex: 1 }}
              title={'Cancel'}
              onPress={this.cancelPressed}
            />
            <MSFESButton
              title={'Accept'}
              containerStyle={{ flex: 1 }}
              onPress={this.acceptPressed}
            />
          </View>
        </View>
      </Overlay>
    )
    return Platform.OS === 'web' ? (
      <>
        <Portal>{picker}</Portal>
      </>
    ) : (
      <>{picker}</>
    )
  }
}

export default ColorPickerOverlay
