import React, { useEffect, useState } from 'react'
import {
  View,
  ActivityIndicator,
  Dimensions,
  Platform,
  FlatList
} from 'react-native'
import { DataTable } from 'react-native-paper'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { listEntity } from '../../store/entity/actions'
import { defaultQueryParams } from '../../screens/EntityListScreen'
import { useSelector } from 'react-redux'
import CurrentlyNoItems from '../CurrentlyNoItems'
import { ActivityFeedRow } from './ActivityFeedRow'

export const ActivityFeed = ({ widget }) => {
  const dispatch = useDispatch()

  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.dataLite.data', null)
  )

  const [intervalId, setIntervalId] = useState(null)
  const [queryParams] = useState(defaultQueryParams)
  const [filter] = useState({ activity_feed: true })
  const { page = null } = queryParams

  const [entities, setEntities] = useState([])
  const [loading, setLoading] = useState(false)
  const from_widget = widget.id

  const listParams = {
    filter,
    page,
    from_widget,
    per_page: 100,
    sort: '-updated_at',
    detailed: true
  }

  useEffect(() => {
    const entityTypesMatched = entityTypes.filter((entityType) =>
      widget.calendar_filters?.some((filter) =>
        (entityType.object_data?.visible_on_calendar ?? [])
          .map((item) => item.id)
          .includes(filter.id)
      )
    )

    if (entityTypesMatched.length > 0 && !intervalId) {
      setLoading(true)
      const first3 = entityTypesMatched.slice(0, 3)
      dispatch(listEntity(first3, listParams)).then((response) => {
        setLoading(false)
        setEntities(response.data)
        setIntervalId(
          setInterval(() => {
            setLoading(true)
            dispatch(listEntity(first3, listParams)).then((response) => {
              setLoading(false)
              setEntities(response.data)
            })
          }, 10000)
        )
      })
    }
  }, [entityTypes, widget, filter, page, from_widget, dispatch])
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [intervalId])

  const maxOverlayHeight =
    Platform.OS === 'web' ? Dimensions.get('window').height * 0.7 : 600

  return (
    <View
      style={{
        height: maxOverlayHeight,
        maxWidth: Dimensions.get('window').width * 0.95
      }}
    >
      {loading && <ActivityIndicator />}
      {!loading && !entities.length && (
        <CurrentlyNoItems label="No activity feed items today" />
      )}
      {!!entities.length && (
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title key={'user'}>
              Active items created today
            </DataTable.Title>
          </DataTable.Header>

          <FlatList
            data={entities}
            renderItem={({ item, index }) => {
              return (
                <ActivityFeedRow entity={item} key={item.id} index={index} />
              )
            }}
          />
        </DataTable>
      )}
    </View>
  )
}
