import React from 'react'
import { View } from 'react-native'

import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import colors from '../styles/colors'
import MSFESHeading from './MSFESHeading'

export const TileContainer = React.forwardRef((props, ref) => {
  const {
    mode,
    entity,
    prepopulatedFields,
    fileAppended,
    fieldChanged,
    definition,
    label
  } = props

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )

  // props.data.field.field_data.params.sourceName
  const myEntityType = definition

  const fieldIds = _.get(
    props,
    'data.field.field_data.params.fieldList',
    []
  ).map((f) => f.offline_id ?? f.id)
  const fields = _.get(myEntityType, 'fields', []).filter(
    (f) => fieldIds.includes(f.id) || fieldIds.includes(f.offline_id)
  )

  const fieldsSorted = _.sortBy(fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  const useRowHeader = _.get(
    props,
    'data.field.field_data.params.useRowHeader',
    false
  )
  return (
    <View
      style={{
        flexDirection: 'column',
        maxWidth: 144,
        flex: 1
      }}
    >
      <View
        style={[
          {
            flex: 1,
            backgroundColor: colors.formInputBackground,
            paddingHorizontal: spacing.m1
          },
          shared.debugOff
        ]}
      >
        <MSFESHeading
          style={{
            backgroundColor: 'transparent',
            paddingVertical: spacing.m2,
            paddingHorizontal: spacing.m1,
            textAlign: 'center'
          }}
        >
          {(label ?? '').toUpperCase()}
        </MSFESHeading>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            flexDirection: 'column'
          }}
        >
          {fieldsSorted.map((field, index) => {
            const errorMessage = _.get(
              errors,
              field.field_data.property,
              []
            ).join(',')

            return (
              <EntityField
                tile
                showLabel={!useRowHeader}
                displayIcon={false} // hide in row mode.
                mode={mode}
                entity={entity}
                name={field.field_data.property}
                prepopulatedFieldValue={
                  prepopulatedFields
                    ? prepopulatedFields[field.field_data.property]
                    : undefined
                }
                errorMessage={errorMessage}
                field={field}
                fileAppended={fileAppended}
                fieldChanged={fieldChanged}
                key={index}
              />
            )
          })}
        </View>
      </View>
    </View>
  )
})
