import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { WidgetRenderBucket } from './WidgetRenderBucket'
import { useGetWidgetRenderBuckets } from '../hooks/useGetWidgetRenderBuckets'
import { useDashboardContext } from '../contexts/DashboardContext'
import { useSelector, useDispatch } from 'react-redux'
import DateChanger from './DateChanger'
import { useMonthLoadingHelper } from '../screens/CalendarViewScreenHelpers/useMonthLoadingHelper'
import { DateTime } from 'luxon'
import { CalendarEntityList } from '../screens/CalendarViewScreenHelpers/CalendarEntityList'
import { useRoute } from '@react-navigation/native'
import { getSortedEntitiesFromStore } from './HomeContainer'
import { ViewEntityOverlay } from '../screens/CalendarViewScreenHelpers/ViewEntityOverlay'
import { CalendarEventAdd } from '../screens/CalendarViewScreenHelpers/CalendarEventAdd'
import YesNoCancelDialog from './YesNoCancelDialog'
import { deleteCalendarEvent } from '../store/calendar/actions'
import fonts from '../styles/fonts'
import colors from '../styles/colors'
import spacing from '../styles/spacing'

export const RightPanelDashboard = () => {
  const dispatch = useDispatch()
  const { currentDashboard } = useDashboardContext()
  const { progressBarBuckets } = useGetWidgetRenderBuckets([
    'dashboard',
    'planner'
  ])
  const route = useRoute()
  const isDashboard = route.name.includes('Dashboard') ? true : false
  const [
    markedMonthYear,
    monthLoading,
    setActiveMonthYear,
    refetchMonth
  ] = useMonthLoadingHelper(activeDate)

  if (monthLoading === undefined) {
    return monthLoading
  }
  const [isAddingEventDate, setIsAddingEventDate] = useState(false)
  const [activeDate, setActiveDate] = useState(null)
  const [viewingEntity, setViewingEntity] = useState(null)

  const [modifyingEventWithEventId, setModifyingEventWithEventId] = useState(
    null
  )
  const [deletingEvent, setDeletingEvent] = useState(null)

  const deleteScheduleItemConfirmPressed = () => {
    dispatch(deleteCalendarEvent(deletingEvent))
      .then(() => {
        setViewingEntity(null)
        setDeletingEvent(false)
      })
      .catch(() => {})
      .finally(() => {
        setViewingEntity(null)
        setDeletingEvent(false)
      })
  }

  const onDateChanged = (dateString) => {
    // Ensure dateString is a valid ISO date string
    const parsedDate = DateTime.fromISO(dateString)
    if (parsedDate.isValid) {
      setActiveDate(parsedDate.toISODate()) // Store in ISO format
    } else {
      console.error('Received invalid date format:', dateString)
    }
  }
  useEffect(() => {
    // Default to today's date if activeDate is not set or invalid
    if (!activeDate) {
      const today = DateTime.now().toISODate()
      onDateChanged(today)
    }
  }, [activeDate])
  const [renderKey, setRenderKey] = useState(0)
  if (renderKey === undefined) {
    return renderKey
  }
  useEffect(() => {
    const today = DateTime.now().toISODate()
    if (today === undefined) {
      return today
    }
    const month = DateTime.now().toFormat('MM')
    const year = DateTime.now().toFormat('yyyy')
    setActiveMonthYear({ month, year })
    setTimeout(() => {
      setRenderKey((renderKey) => renderKey + 1)
    }, 300)
  }, [activeDate, setActiveMonthYear])

  const dashboardEntityType = {
    name: 'system_dashboard'
  }
  const { dashboards } = useSelector((state) => ({
    dashboards: getSortedEntitiesFromStore(dashboardEntityType, state)
  }))

  const calendarData = dashboards.data[currentDashboard]

  const calendarsVisible = calendarData?.calendars ?? []

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'relative',
          flex: 13 / 50,
          height: '100%',
          flexShrink: 0,
          backgroundColor: colors.white
        },
        spacing.rightPanelLayout
      ]}
    >
      {viewingEntity && (
        <ViewEntityOverlay
          entity={viewingEntity}
          virtualEventCreateDate={activeDate}
          onDismiss={() => setViewingEntity(null)}
          onRefetch={() => refetchMonth()}
          onModifySchedulePressed={() => {
            const virtual_event_id = viewingEntity.virtual_event_id
            setViewingEntity(null)
            setTimeout(() => {
              setModifyingEventWithEventId(virtual_event_id)
            })
          }}
          onDeleteSchedulePressed={() => {
            setDeletingEvent(viewingEntity.virtual_event_id)
          }}
        />
      )}
      {deletingEvent && (
        <YesNoCancelDialog
          title="Delete"
          body="Are you sure you want to delete this schedule item? This is a permanent action. No future entities will be created for this schedule."
          yesAction={deleteScheduleItemConfirmPressed}
          noAction={() => setDeletingEvent(null)}
          cancelAction={() => setDeletingEvent(null)}
        />
      )}
      {(isAddingEventDate || modifyingEventWithEventId) && (
        <View>
          <CalendarEventAdd
            addingDate={isAddingEventDate.activeDate}
            addingType={isAddingEventDate.addingType}
            modifyingEventWithEventId={modifyingEventWithEventId}
            onDismiss={() => {
              setIsAddingEventDate(false)
              refetchMonth()
              setModifyingEventWithEventId(null)
            }}
          />
        </View>
      )}

      <ScrollView containerStyle={{ justifyContent: 'space-between' }}>
        <View style={{ flex: 1 }}>
          <View style={spacing.rightPanelLayout}>
            <View
              style={[
                spacing.calendarLayout,
                { width: 308, height: 350, flexShrink: 0 }
              ]}
            >
              <DateChanger
                value={activeDate}
                onDateChanged={onDateChanged}
                markedDates={markedMonthYear}
              />
              <View
                style={{
                  paddingVertical: spacing.m4,
                  flex: 1,
                  backgroundColor: colors.white
                }}
              >
                <CalendarEntityList
                  activeDate={activeDate}
                  isDashboard={isDashboard}
                  date={activeDate}
                  calendarsVisible={calendarsVisible}
                  onAddPressed={(date) =>
                    setIsAddingEventDate({ activeDate: date })
                  }
                  onEntityClicked={(entity) => {
                    setViewingEntity(entity)
                  }}
                />
              </View>
            </View>
          </View>
          <View
            style={[
              spacing.incidentTypesHeaderLayout,
              { flexShrink: 0, width: 470, backgroundColor: colors.white }
            ]}
          >
            <Text style={fonts.headerStyle}>Incident Types</Text>
            <View
              style={{
                marginTop: spacing.m3,
                marginLeft: spacing.m0
              }}
            >
              {progressBarBuckets.map((renderBucket, bucketIndex) => {
                return (
                  <WidgetRenderBucket bucket={renderBucket} key={bucketIndex} />
                )
              })}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}
