import { InAppBrowser } from 'react-native-inappbrowser-reborn'

import { Linking, Platform } from 'react-native'

export const getTypeFromUri = (uri) => {
  if(!uri) {
    return null;
  }
  const parts = uri.split("/");

  return parts.length > 3 ? parts[2] : null;
}

export const openExternalLink = async (link) => {
  if (Platform.OS === "web") {
    window.open(link, "_blank")
  }
  else {

    if (InAppBrowser && await InAppBrowser.isAvailable()) {
      const result = await InAppBrowser.open(link, {
        // iOS Properties
        dismissButtonStyle: 'cancel',
        readerMode: false,
        animated: true,
        modalPresentationStyle: 'fullScreen',
        modalTransitionStyle: 'coverVertical',
        modalEnabled: true,
        enableBarCollapsing: false,
        // Android Properties
        showTitle: true,
        enableUrlBarHiding: true,
        enableDefaultShare: true,
        forceCloseOnRedirection: false,
        // Specify full animation resource identifier(package:anim/name)
        // or only resource name(in case of animation bundled with app).
        animations: {
          startEnter: 'slide_in_right',
          startExit: 'slide_out_left',
          endEnter: 'slide_in_left',
          endExit: 'slide_out_right'
        }
      })
    }
    else {
      debugger
      Linking.openURL(link);

    }

  }
}