import React, { useEffect, useState } from "react";

import _ from 'lodash'

import TasksScreen from './TasksScreen'
import TaskAddSelectTypeScreen from './TaskAddSelectTypeScreen'
import { createStackNavigator } from '@react-navigation/stack'
import EntityAddScreen from "./EntityAddScreen";
const TasksStack = createStackNavigator()


const TasksNavigator = () => {


  return (
    /* this stack navigator only handles login stuff. */

    <TasksStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <TasksStack.Screen
        name="TasksIndex"
        component={TasksScreen}
      />
      <TasksStack.Screen name="TaskEdit" component={EntityAddScreen} options={{ title: "Edit" }} />
      <TasksStack.Screen name="TaskView" component={EntityAddScreen} options={{ title: "View" }} />

      <TasksStack.Screen
        name="TaskAddSelectType"
        component={TaskAddSelectTypeScreen}
      />


    </TasksStack.Navigator>
  )
}

export default TasksNavigator