import React, { useCallback, useEffect, useState } from 'react'

import { View, Text, FlatList, ScrollView, RefreshControl, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import { Portal } from 'react-native-paper';
import shared from '../styles/shared';
import colors from '../styles/colors';
import spacing from '../styles/spacing';
import CurrentlyNoItems from './CurrentlyNoItems';
import { ListItemEntity } from './ListItemEntity';
import _ from 'lodash'
import { iconShadow } from './ModuleButton';
import useBetterLinkTo from '../useBetterLinkTo'


const SearchResultsView = ({ searchResults, topNavHeight, navWidth, navigation }) => {

  const openLinkTo = useBetterLinkTo();

  const renderItem = useCallback((item, index) => {

      return <ListItemEntity
        entity={item}
        navigation={navigation}
        entityType={item.entity_type}
        onPress={() => {
          setDisplayResults(false);
          openLinkTo(item.uri)
          
        }}
        key={item.id}
      />

  })

  const [displayResults, setDisplayResults ] = useState(false);

  useEffect(() => {
    if(searchResults && searchResults.searchTerm) {
      setDisplayResults(true);
    }
  }, [searchResults])

  const dismissPressed = () => {
    setDisplayResults(false);
  }


  const data = _.get(searchResults, "results.data", []).filter(r => r);

  return (
    <Portal>
      {displayResults && 
      <TouchableOpacity style={[shared.debugOff, {height: "100%"}]} onPress={() => dismissPressed()}>
      <View style={[iconShadow, { position: 'absolute', top: topNavHeight - 14, height: 300, left: navWidth, right: navWidth, marginHorizontal: spacing.m2, flexDirection:"column", backgroundColor:colors.white, borderRadius: 0, borderBottomLeftRadius: spacing.borderRadius, borderBottomRightRadius: spacing.borderRadius }]}>
        <View><Text style={{ color: "red" }}>Showing search results for {searchResults.searchTerm}</Text></View>

        <ScrollView style={[{ flex: 1 }]} key={'list-container'}>
                  <FlatList
                    contentContainerStyle={{ paddingBottom: 30, flex: 1 }}
                    // onEndReached={loadMoreItems}
                    // onEndReachedThreshold={0.1}
                    keyExtractor={(item) => item.id.toString()}

                    data={data} renderItem={({ item, index, separators }) => {
                      return renderItem(item, index)
                    }} />

                </ScrollView>
      </View></TouchableOpacity> }

    </Portal>
  )
}

export default SearchResultsView