import React from 'react'

import useBetterLinkTo from '../useBetterLinkTo'
import { Platform, StyleSheet, View } from 'react-native'
import { Overlay, ListItem } from 'react-native-elements'

import { useDispatch, useSelector } from 'react-redux'
import { ListItemLinkButton } from './ListItemLinkButton'

import _ from 'lodash'
import entityTypeActions from '../store/entity-type/actions'
import colors from '../styles/colors'

export const downloadResponseAsJson = (response, filename) => {
  const output = JSON.stringify(response)
  const blob = new Blob([output], { type: 'text/json' })
  const downloadUrl = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = downloadUrl
  a.download = filename
  document.body.appendChild(a)
  a.click()
}
export const listItemContainerStyle = {
  backgroundColor: colors.lightestGray,
  borderTopWidth: StyleSheet.hairlineWidth,
  borderTopColor: colors.lightGray
}
export const listItemContainerStyleError = {
  backgroundColor: colors.lighterPink,
  borderTopWidth: StyleSheet.hairlineWidth,
  borderTopColor: colors.lightGray
}

export const getFreshImportState = () => {
  return {
    performingImport: false,
    dialogOpen: false,
    results: null,
    testRun: true
  }
}
export default function EntityTypeActions({
  isVisible,
  onBackdropPress,
  onDeletePressed,
  dropdownOverlayStyle,
  entityType = null
}) {
  const dispatch = useDispatch()

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const canModifyEntityTypes = _.get(
    current_user,
    `effective_permissions['modify||name:entity types']`,
    false
  )

  const disableDeleteOption = !canModifyEntityTypes

  const linkTo = useBetterLinkTo()

  const viewEntityList = () => {
    onBackdropPress()
    linkTo(`/entity-list/?type=${entityType.name}`)
  }
  const exportEntityType = () => {
    dispatch(entityTypeActions.exportEntityType(entityType)).then(
      (response) => {
        downloadResponseAsJson(response, entityType.name + '.json')
      }
    )
  }

  const IsOnDesktop = Platform.OS === 'web'

  return (
    <>
      {isVisible && !!entityType && (
        <Overlay
          visible={true}
          backdropStyle={{ opacity: 0 }}
          onBackdropPress={onBackdropPress}
          overlayStyle={dropdownOverlayStyle}
        >
          <View style={{ marginTop: -1 }}>
            <ListItem
              onPress={onDeletePressed}
              disabled={disableDeleteOption}
              containerStyle={listItemContainerStyle}
            >
              <ListItem.Content>
                <ListItem.Title
                  style={{
                    color: 'red',
                    opacity: disableDeleteOption ? 0.5 : 1
                  }}
                >
                  Delete type &apos;{entityType?.label}&apos;
                </ListItem.Title>
              </ListItem.Content>
            </ListItem>
            {IsOnDesktop && (
              <>
                <ListItemLinkButton
                  containerStyle={listItemContainerStyle}
                  hideAvatar={true}
                  title={`Export type to JSON`}
                  onPress={exportEntityType}
                />
              </>
            )}
            <ListItemLinkButton
              containerStyle={listItemContainerStyle}
              hideAvatar={true}
              title={`View '${entityType?.label}' list`}
              onPress={viewEntityList}
            />
          </View>
        </Overlay>
      )}
    </>
  )
}
