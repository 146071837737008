import React, { useMemo } from 'react'
import { View, useWindowDimensions } from 'react-native'
import _ from 'lodash'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { getFilterString } from '../store/entity/actions'

import { useSelector, useDispatch } from 'react-redux'
import { noData } from './HomeContainer'
import MSFESButton from './MSFESButton'
import colors from '../styles/colors'
import { openPdf } from './ListItemLinkButton'

import { useDropdownHelper } from '../useDropdownHelper'
import { showHandbook, showFileInTenantField } from '../store/file/actions'
import wrappedAlert from '../helpers/alert'
import { QuickOverlay } from './TopNavButtonHousing/QuickOverlay'
import spacing from '../styles/spacing'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

export const pinkButtonStyle = {
  backgroundColor: colors.lighterPink,
  paddingVertical: 9,
  minHeight: 32,
  marginBottom: 0,
  borderWidth: 0
}

export const useNavigateToTenantHandbook = () => {
  const dispatch = useDispatch()
  const { current_user } = useSelector((appState) => {
    return {
      current_user: appState?.users?.user
    }
  })

  const navigateToTenantFile = (field) => {
    // check field, add to search path
    const navigateFieldName =
      field?.navigate_to?.clientFieldFile?.field_data?.property
    if (!current_user.tenant_record?.tenant_id) {
      wrappedAlert('Select a tenant', 'Tenant must be selected first')
      return
    }
    dispatch(showFileInTenantField(navigateFieldName)).then((response) => {
      if (response.result === 'ok') {
        openPdf(response.fieldFile.url)
      } else {
        wrappedAlert('File unavailable', 'File is not available.')
      }
    })
  }
  const navigateToTenantHandbook = () => {
    if (!current_user.tenant_record?.tenant_id) {
      wrappedAlert('Select a tenant', 'Tenant must be selected first')
      return
    }
    dispatch(showHandbook()).then((response) => {
      if (response.result === 'ok') {
        openPdf(response.handbook.url)
      } else {
        wrappedAlert('Handbook unavailable', 'Handbook is not available.')
      }
    })
  }

  return { navigateToTenantHandbook, navigateToTenantFile }
}
const TopNavButtonHousing = () => {
  const dimensions = useWindowDimensions()

  const useIcons = dimensions.width < 1240
  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const { screenButtonsWrapper } = useSelector((appState) => {
    const args = { detailed: true },
      filter = {}

    const filterString = sanitiseFilterString(getFilterString(filter, args))

    const screenButtonsWrapper =
      entityTypeScreenButtons &&
      _.get(
        appState.entities,
        `[${entityTypeScreenButtons.name + filterString}]`,
        noData
      )

    return {
      screenButtonsWrapper,
      current_user: appState?.users?.user
    }
  })

  const navbarButtons = useMemo(
    () =>
      screenButtonsWrapper
        ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
            (r) => _.get(r, 'position.label', null) === 'Navigation Bar'
          )
        : [],
    [screenButtonsWrapper]
  )
  const navbarButtonsDocs = useMemo(
    () =>
      screenButtonsWrapper
        ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
            (r) => _.get(r, 'position.label', null) === 'Navigation Bar - Docs'
          )
        : [],
    [screenButtonsWrapper]
  )

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(240, 0)

  const [
    dropdownVisibilityStateDocs,
    ,
    toggleDropdownStateDocs,
    buttonRefDocs,
    dropdownOverlayStyleDocs
  ] = useDropdownHelper(240, 0)

  return (
    <View>
      <View style={{ flexDirection: 'row' }}>
        <View ref={buttonRef} style={{ marginRight: spacing.m1 }}>
          <MSFESButton
            titleStyle={{
              fontSize: 12,
              ...{ paddingLeft: 28 }
            }}
            variant="primary"
            icon={{ name: 'chevron-down', type: 'font-awesome', size: 12 }}
            buttonStyle={{
              ...pinkButtonStyle,
              minWidth: useIcons ? 36 : 108
            }}
            title={'Quick Links'}
            titleColor={colors.redDark}
            onPress={toggleDropdownState}
          />
        </View>
        <View ref={buttonRefDocs}>
          <MSFESButton
            titleStyle={{
              fontSize: 12,
              ...{ paddingLeft: 28 }
            }}
            variant="primary"
            icon={{ name: 'chevron-down', type: 'font-awesome', size: 12 }}
            buttonStyle={{ ...pinkButtonStyle, minWidth: useIcons ? 36 : 108 }}
            title={'Quick Docs'}
            titleColor={colors.redDark}
            onPress={toggleDropdownStateDocs}
          />
        </View>
      </View>
      {dropdownVisibilityState && (
        <>
          <QuickOverlay
            onBackdropPress={toggleDropdownState}
            overlayStyle={dropdownOverlayStyle}
            buttons={navbarButtons}
          />
        </>
      )}
      {dropdownVisibilityStateDocs && (
        <>
          <QuickOverlay
            onBackdropPress={toggleDropdownStateDocs}
            overlayStyle={dropdownOverlayStyleDocs}
            buttons={navbarButtonsDocs}
          />
        </>
      )}
    </View>
  )
}

export default TopNavButtonHousing
