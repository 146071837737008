import { DateTime } from 'luxon'
import _ from 'lodash'
const defaultState = {
  errors: {
    errors: null,
    hasError: false
  }
}

export const mutateEntityType = (entityType) => {
  return {
    ...entityType
  }
}
export default function entityType(state = defaultState, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      {
        const hydratedState = _.get(action, 'payload.entityTypes.data', []).map(
          mutateEntityType
        )
        _.set(action, 'payload.entityTypes.data', hydratedState)
      }
      break
    case 'entity-type/scrub-excess':
      if (state?.data?.length > 300) {
        // HRFIR-724
        state = {
          ...state,
          data: state.data.slice(0, 250)
        }
      }
      break
    case 'entity-type/list-started':
      {
        state = {
          ...state,
          lastRetrieveStarted: DateTime.local()
        }
      }

      break
    case 'users/signin-success':
      {
        const { core_entity_types } = action
        const mutatedEntityTypes = core_entity_types?.data?.map(
          mutateEntityType
        )
        state = {
          ...state,
          ...{
            data: mutatedEntityTypes
          }
        }
      }

      break
    case 'entity-type/list-success':
      {
        const { response } = action

        const mutatedEntityTypes = response?.data?.map(mutateEntityType)
        state = {
          ...state,
          ...{
            data: mutatedEntityTypes,
            lastRetrieveFinished: DateTime.local()
          }
        }
      }

      break
    case 'entity-type/dump-stored':
      state = {
        ...state,
        ...{
          data: [],
          lastRetrieveFinished: DateTime.local()
        }
      }
      break
    case 'entity-type/list-lite-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            dataLite: response,
            lastLiteRetrieveFinished: DateTime.local()
          }
        }
      }

      break

    case 'entity-type/get-success':
    case 'entity-type/upsert-success':
      {
        const { response } = action

        const data = state.data

        const existingRecord = data.findIndex(
          (record) => record && record.id === response.data.id
        )

        const mutated = mutateEntityType(response.data)
        if (existingRecord !== -1) {
          data[existingRecord] = mutated
        } else {
          data.unshift(
            mutated
          ) /* put on front, so cleaning removes last. HRFIR-724 */
        }

        state = {
          ...state,
          data: [...data]
        }
      }

      break
    case 'entity-type/upsert-started':
      state.errors = {
        ...state.errors,
        ...{ hasError: false, message: null, statusCode: null, errors: null }
      }
      break

    case 'entity-type/upsert-error':
      {
        let friendlyErrors = []

        const { errors } = action.error

        try {
          Object.keys(errors).map((key) => {
            let title = key
            friendlyErrors.push(title + ': ' + errors[key])
          })
        } catch (e) {}

        state.errors = {
          errors: action.error.errors,
          message: action.error.message + '\n' + friendlyErrors.join('\n'),
          statusCode: action.error.statusCode,
          hasError: true
        }
      }

      break
    case 'entity-type/clear-general-error':
      state.errors = {
        ...state.errors,
        ...{ message: null, statusCode: null }
      }
      break
    case 'entity-type/clear-field-errors':
      state.errors = { ...defaultState }
      break
  }

  return state
}
