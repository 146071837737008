import api from '../../../data/api'
let tenantController
let userController
let personnelController

const abortIfRequested = (controller) => {
  if (controller) {
    controller.abort()
  }

  return new AbortController()
}

export const listTenantLogins = ({ page = 1, searchText = '' }) => {
  return () => {
    let routeUsed = '/admin/logins/tenants?page=' + page

    if (searchText) {
      routeUsed += '&search=' + searchText
    }

    tenantController = abortIfRequested(tenantController)

    return api
      .get(routeUsed, null, tenantController)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
export const updateTenantLogin = ({ tenant }) => {
  return () => {
    const routeUsed = `/admin/logins/tenants/${tenant.id}`

    return api
      .put(routeUsed, { tenant })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const listUserLogins = ({ page = 1, searchText = '' }) => {
  return () => {
    let routeUsed = '/admin/logins/users?page=' + page
    if (searchText) {
      routeUsed += '&search=' + searchText
    }

    userController = abortIfRequested(userController)

    return api
      .get(routeUsed, null, userController)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const updateUserLogin = ({ user }) => {
  return () => {
    const routeUsed = `/admin/logins/users/${user.id}`

    return api
      .put(routeUsed, { user })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const listPersonnelLogins = ({ page = 1, searchText = '' }) => {
  return () => {
    let routeUsed = '/admin/logins/personnel?page=' + page
    if (searchText) {
      routeUsed += '&search=' + searchText
    }

    personnelController = abortIfRequested(personnelController)

    return api
      .get(routeUsed, null, personnelController)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const updatePersonnelLogin = ({ person }) => {
  return () => {
    const routeUsed = `/admin/logins/personnel/${person.id}`

    return api
      .put(routeUsed, { person })
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
