import _ from 'lodash'
import React from 'react'

//actions
import CheckboxMultiselectWithData from './CheckboxMultiselectWithData'

export const CheckboxMultiselectAdhoc = React.forwardRef((props, ref) => {
  const options = _.get(props, 'data.field.field_data.params.options', [])

  const dataRows = options.map((o) => {
    return { key: o.key, label: o.label, value: o.value }
  })

  return (
    <CheckboxMultiselectWithData
      {...props}
      dataRows={dataRows}
      labelField="label"
      keyField="key"
      valueField="key"
      value={props.defaultValue}
    />
  )
})
