// libraries
import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import WithHeader from '../components/WithHeader'
import { ActivityIndicator, View } from 'react-native'
import DataListWrapper from './EntityListScreenHelpers/DataListWrapper'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { getFilterString, listEntity } from '../store/entity/actions'
import { useBetterLinkTo } from '../useBetterLinkTo'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
export default function SettingsReportsScreen({ navigation }) {
  const dispatch = useDispatch()
  const pageTitle = 'Reports'

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const filter = false

  const { entityType, isFetching } = useDiscoverEntityEntityType(
    null,
    null,
    'system_report'
  )

  const filterString = sanitiseFilterString(
    getFilterString(filter, { detailed: true })
  )

  const fetchNextPage = () => {
    if (entityType) {
      setLoading(true)
      dispatch(
        listEntity(entityType, {
          filter,
          page,
          searchText: null,
          due_at: null,
          from_widget: null,
          detailed: true
        })
      ).then((response) => {
        setLoading(false)
        setPages(response.meta.last_page)
      })
    }
  }
  useEffect(fetchNextPage, [page, entityType])

  const path = `${entityType?.name + filterString}`

  const linkTo = useBetterLinkTo()
  return (
    <WithHeader
      title={pageTitle}
      navigation={navigation}
      leftButtonType={'back'}
    >
      {loading && <ActivityIndicator />}
      <View style={{ flex: 1 }}>
        <DataListWrapper
          loadMoreItems={() => {
            if (page < pages) {
              setPage(page + 1)
            }
          }}
          refreshing={loading || isFetching}
          entityType={entityType}
          shouldSplit={false}
          sourceKey={path}
          splitviewNavigate={(item) => {
            linkTo('/settings/report/' + item.id)
          }}
        />
      </View>
    </WithHeader>
  )
}
