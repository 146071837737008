import colors from './colors'
import spacing from './spacing'

const inter = 'Inter'
const bold = 'bold'
const font500 = '500'

const buttonFace = inter
const inputLabelFace = inter
const inputTextFace = inter

const standardFace = inter
const inputLabelSize = 16
const smallLabelSize = 12
const tinyLabelSize = 10
const moduleLabelSize = 14
const buttonLabelSize = 18
const largeLabelSize = 24
const fonts = {
  inter,
  bold,
  font500,
  buttonFace,
  tinyLabelSize,
  buttonLabelSize,
  inputLabelFace,
  inputTextFace,
  inputTextSize: 16,
  inputLabelSize,
  smallLabelSize,
  moduleLabelSize,
  largeLabelSize,
  navButtonTitleStyle: {
    fontFamily: buttonFace,
    fontWeight: '500',
    fontSize: smallLabelSize
  },
  buttonTitleStyle: {
    fontFamily: buttonFace,
    fontWeight: '700',
    fontSize: buttonLabelSize
  },
  headerStyle: {
    fontFamily: standardFace,
    fontSize: 20,
    fontWeight: '700',
    color: colors.pageHeaderColor
  },
  incidentTypesHeaderStyle: {
    fontFamily: standardFace,
    fontSize: 18,
    fontWeight: '700',
    color: colors.pageHeaderColor
  },
  listItemSubTextStyle: {
    fontFamily: inter,
    fontSize: buttonLabelSize,
    paddingVertical: spacing.m2,
    color: colors.blueDarkest
  },
  listItemTextStyle: {
    fontFamily: inter,
    fontSize: buttonLabelSize,
    paddingTop: spacing.m2,
    paddingBottom: spacing.m0_5,
    color: colors.blueDarkest
  },
  dashboardListItemTextStyle: {
    fontFamily: 'Roboto',
    fontSize: buttonLabelSize / 1.2,
    paddingTop: spacing.m2,
    paddingBottom: spacing.m0_5,
    color: colors.blueDarkest
  },
  dashboardListItemFormattedDate: {
    fontFamily: 'Rubik',
    color: '#414D55',
    fontSize: buttonLabelSize / 1.8,
    paddingTop: 2,
    paddingBottom: 2,
    // color: colors.blueDarkest,
    lineHeight: 10.171,
    letterSpacing: 0.212
  },
  materialIconArrowStyle: {
    textAlign: 'center',
    fontFamily: 'Material Icons',
    fontSize: moduleLabelSize,
    fontStyle: 'normal',
    fontWeight: '400',
    width: buttonLabelSize,
    height: buttonLabelSize
  },
  gridItemTextStyle: {
    fontFamily: inter,
    fontSize: 12,
    paddingBottom: spacing.m0_5,
    color: colors.blueDarkest,
    textAlign: 'center'
  },
  moduleButtonTextStyle: {
    textShadowColor: 'rgba(0, 0, 0, 0.15)',
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 3,
    fontFamily: inter,
    fontSize: moduleLabelSize,
    textAlign: 'center'
  },
  sublabelStyle: {
    fontFamily: standardFace,
    fontSize: 14,
    fontWeight: '700'
  },
  standardFace
}

export default fonts
