// libraries
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { listLiteEntityTypes } from '../store/entity-type/actions'
import WithHeader from '../components/WithHeader'
import { View } from 'react-native'
import SegmentedControl from '@react-native-community/segmented-control'
import { LoginsTenant } from '../components/Logins/LoginsTenant'
import { LoginsUser } from '../components/Logins/LoginsUser'
import { LoginsPersonnel } from '../components/Logins/LoginsPersonnel'

export const sortOptions = [
  { name: 'a_to_z', icon: 'sort-alpha-down' },
  { name: 'z_to_a', icon: 'sort-alpha-down-alt' }
]

export default function SettingsManageLoginsScreen({ navigation }) {
  const dispatch = useDispatch()

  // if we're on a subroute, we need to work that out.
  useEffect(() => {
    dispatch(listLiteEntityTypes())
  }, [])

  const [activeSort, setActiveSort] = useState(0)
  const [searchText, setSearchText] = useState(null)

  const onSearchTextChanged = (_searchText) => {
    setSearchText(_searchText.toLowerCase())
  }

  const onSortChanged = (newIndex) => {
    setActiveSort(newIndex)
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <WithHeader
      title={'Manage Logins'}
      onSearchTextChanged={onSearchTextChanged}
      onSortChanged={onSortChanged}
      sortOptions={sortOptions}
      activeSort={activeSort}
      navigation={navigation}
      leftButtonType={'back'}
    >
      <View style={{ flex: 1 }}>
        <SegmentedControl
          values={['Tenants', 'Users', 'Personnel']}
          selectedIndex={activeTabIndex}
          onChange={(event) => {
            setActiveTabIndex(event.nativeEvent.selectedSegmentIndex)
          }}
        />
        {activeTabIndex == 0 && (
          <LoginsTenant
            searchText={searchText}
            activeSort={sortOptions[activeSort]}
          />
        )}
        {activeTabIndex == 1 && (
          <LoginsUser
            searchText={searchText}
            activeSort={sortOptions[activeSort]}
          />
        )}
        {activeTabIndex == 2 && (
          <LoginsPersonnel
            searchText={searchText}
            activeSort={sortOptions[activeSort]}
          />
        )}
      </View>
    </WithHeader>
  )
}
