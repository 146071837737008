import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  View,
  StyleSheet,
  Platform,
  Linking,
  ActivityIndicator,
  Text
} from 'react-native'
import { Overlay, ListItem } from 'react-native-elements'
import { Divider } from 'react-native-elements/dist/divider/Divider'

import { useDispatch, useSelector } from 'react-redux'
import { ListItemLinkButton } from './ListItemLinkButton'

import api from '../data/api'
import versionActions from '../store/version/actions'

import _ from 'lodash'
import { MSFESCheckbox } from './MSFESCheckbox'
import colors from '../styles/colors'
import actions from '../store/entity/actions'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import { ViewActionsOverlay } from './EntityActions/ViewActionsOverlay'
import { PrintPdfOverlay } from './EntityActions/PrintPdfOverlay'
import { useBetterLinkTo } from '../useBetterLinkTo'

export const topRightPopoutMenuStyle = StyleSheet.create({
  overlayStyle: {
    position: 'absolute',
    padding: 0,
    right: 20,
    top: 55,
    minWidth: 200
  }
})
export default function EntityActions({
  id = null,
  dropdownOverlayStyle,
  isVisible,
  onViewVersions = null,
  canViewVersionFiles = false,
  onBackdropPress,
  onExportJSONFile = null,
  onEditToggled,
  onModifySchedulePressed = null,
  onDeleteSchedulePressed = null,
  onDeletePressed,
  onToggleWatching,
  watching,
  editing,
  tenant_id = null,
  allPages,
  visibleFields = [],
  mode,
  entityType = null
}) {
  const dispatch = useDispatch()
  const versions = useSelector((state) => {
    return _.get(state, `versions.byEntity[${id}]`, [])
  })

  const allPagesVisible = useMemo(() => {
    return allPages
      ?.filter((p) => p.available)
      .filter((p) =>
        visibleFields.some((v) => v.field_data?.page == p.index + 1)
      )
  }, [allPages])

  useEffect(() => {
    if (id && isVisible) {
      dispatch(versionActions.listFiles({ id }, entityType))
    }
  }, [id, isVisible])

  const [viewActions, setViewActions] = useState(false)
  const [availableReportPDF, setAvailableReportPDF] = useState(null)
  useEffect(() => {
    if (versions.length) {
      const withPDF = _.sortBy(versions, (v) => v.version).filter((v) => v.pdf)

      const lastWithPDF = withPDF[withPDF.length - 1]

      setAvailableReportPDF(lastWithPDF)
    }
  }, [versions])

  const { current_user, entity } = useSelector((state) => {
    return {
      current_user: state.users?.user,
      entity: _.get(state, `entities.byId[${id}]`, null)
    }
  })

  const entityIsGlobal = !entity?.tenant_id && !tenant_id
  const userIsGlobal = !current_user?.current_tenant
  const userIsLocalAndEditingGlobal = !userIsGlobal && entityIsGlobal
  const canModifyThisEntityType =
    entityType &&
    !userIsLocalAndEditingGlobal &&
    _.get(
      current_user,
      `effective_permissions['update|id:${entityType.id}|name:${entityType.name}']`,
      false
    )
  const canDeleteThisEntityType =
    entityType &&
    _.get(
      current_user,
      `effective_permissions['delete|id:${entityType.id}|name:${entityType.name}']`,
      false
    )

  const myEntityId = _.get(current_user, 'personnel_record.id', null)
  const thisEntityIsMyself = myEntityId == entity?.id

  const canModifyEntityTypes =
    entityType &&
    _.get(
      current_user,
      `effective_permissions['modify||name:entity types']`,
      false
    )
  const canViewVersions = canViewVersionFiles && id

  const { isGlobalAdmin, isTenantAdmin } = useTenantOrGlobalAdmin()
  const canViewActions = isGlobalAdmin || isTenantAdmin
  const canViewReport = canViewVersionFiles && availableReportPDF
  const canViewCustomReports = canViewVersionFiles

  const isCollection = !entityType?.is_module && !entityType?.is_task

  const hasCollectionsPermission = _.get(
    current_user?.effective_permissions,
    'update||name:collections',
    false
  )

  const disableBecauseIsCollection = isCollection && !hasCollectionsPermission

  const isAdd = mode === 'add'

  const disableEditOption =
    disableBecauseIsCollection ||
    isAdd ||
    (!canModifyThisEntityType && !thisEntityIsMyself)
  const disableDeleteOption =
    !id || disableBecauseIsCollection || isAdd || !canDeleteThisEntityType

  const linkTo = useBetterLinkTo()

  const editEntityType = () => {
    onBackdropPress()
    linkTo(`/settings/admin-tools/manage-entity-types/${entityType.id}`, false)
  }

  const _onExportJSONFile = () => {
    onExportJSONFile()
  }

  const [printPdfOverlayVisible, setPrintPdfOverlayVisible] = useState(false)
  const onPrint = () => {
    onBackdropPress()
    setTimeout(() => {
      setPrintPdfOverlayVisible(true)
    }, 50)
  }
  const _onViewVersions = () => {
    onBackdropPress()
    setTimeout(() => {
      onViewVersions()
    }, 50)
  }

  const _onViewActions = () => {
    onBackdropPress()
    setTimeout(() => {
      setViewActions(true)
    }, 50)
  }

  const openCustomReport = useCallback((reportType) => {
    setLoadingReport(reportType.offline_id)
    dispatch(
      actions.showCustomReport({ id: entity.id, type: entityType, reportType })
    )
      .then((response) => {
        const path =
          '/pdfjs/web/viewer.html?file=' + encodeURIComponent(response.url)
        const pdfViewerUrl = api.getUrl(path, '')

        if (Platform.OS === 'web') {
          window.open(pdfViewerUrl, '_blank')
        } else {
          Linking.openURL(pdfViewerUrl)
        }
      })
      .finally(() => {
        setLoadingReport(null)
      })
  })

  const listItemContainerStyle = {
    backgroundColor: colors.lightestGray,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: colors.lightGray
  }

  const reportsAvailable = useMemo(() => {
    return (entityType?.reports ?? []).filter((r) => {
      const availableInUi = _.get(r, 'available_in_ui', true)

      return r.enabled && availableInUi
    })
  }, [entityType])

  const [loadingReport, setLoadingReport] = useState(null)

  return (
    <>
      {isVisible && (
        <>
          <Overlay
            visible={true}
            backdropStyle={{ opacity: 0 }}
            onBackdropPress={onBackdropPress}
            overlayStyle={dropdownOverlayStyle}
          >
            {onModifySchedulePressed && (
              <View style={{ marginTop: -1 }}>
                <ListItem
                  onPress={onModifySchedulePressed}
                  containerStyle={listItemContainerStyle}
                >
                  <ListItem.Content>
                    <ListItem.Title>Modify Schedule Item</ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              </View>
            )}
            {onDeleteSchedulePressed && (
              <View style={{ marginTop: -1 }}>
                <ListItem
                  onPress={onDeleteSchedulePressed}
                  containerStyle={listItemContainerStyle}
                >
                  <ListItem.Content>
                    <ListItem.Title
                      style={{
                        color: 'red'
                      }}
                    >
                      Delete Schedule Item
                    </ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              </View>
            )}
            <View style={{ marginTop: -1 }}>
              <ListItem
                onPress={onToggleWatching}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>Receive Notifications</ListItem.Title>
                </ListItem.Content>
                <MSFESCheckbox
                  listitem
                  radio={false}
                  checked={watching}
                  onPress={onToggleWatching}
                />
                {/* <ListItem.CheckBox onPress={onToggleWatching} checked={watching}></ListItem.CheckBox> */}
              </ListItem>
              <ListItem
                onPress={onEditToggled}
                disabled={disableEditOption}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title
                    style={{ opacity: disableEditOption ? 0.5 : 1 }}
                  >
                    Enable Editing
                  </ListItem.Title>
                </ListItem.Content>
                <MSFESCheckbox
                  listitem
                  radio={false}
                  checked={editing}
                  onPress={!disableEditOption && onEditToggled}
                />
                {/* <ListItem.CheckBox onPress={onEditToggled} checked={editing}></ListItem.CheckBox> */}
              </ListItem>
              <ListItem
                onPress={!canViewVersions ? undefined : onPrint}
                disabled={!canViewVersions}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title
                    style={{ opacity: !canViewVersions ? 0.5 : 1 }}
                  >
                    <Text style={{ flex: 1 }}>Print/Save PDF</Text>
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>

              {reportsAvailable?.map((reportType) => {
                return (
                  <ListItem
                    key={reportType.offline_id}
                    onPress={() => openCustomReport(reportType)}
                    disabled={!canViewCustomReports || !entity?.id}
                    containerStyle={listItemContainerStyle}
                  >
                    <ListItem.Content>
                      <ListItem.Title
                        style={{ opacity: !canViewCustomReports ? 0.5 : 1 }}
                      >
                        Create {reportType.name} PDF
                        {loadingReport === reportType.offline_id && (
                          <ActivityIndicator
                            size={'small'}
                            style={{ marginLeft: 8 }}
                          />
                        )}
                      </ListItem.Title>
                    </ListItem.Content>
                  </ListItem>
                )
              })}
              <ListItem
                onPress={_onViewVersions}
                disabled={!canViewVersions}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title
                    style={{ opacity: !canViewVersions ? 0.5 : 1 }}
                  >
                    View Versions
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>
              <ListItem
                onPress={_onViewActions}
                disabled={!canViewActions}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title
                    style={{ opacity: !canViewActions ? 0.5 : 1 }}
                  >
                    View Actions
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>
              {Platform.OS === 'web' && !!userIsGlobal && (
                <ListItem
                  onPress={_onExportJSONFile}
                  disabled={!canViewReport}
                  containerStyle={listItemContainerStyle}
                >
                  <ListItem.Content>
                    <ListItem.Title
                      style={{ opacity: !canViewReport ? 0.5 : 1 }}
                    >
                      Export Record as JSON
                    </ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              )}
              <ListItem
                onPress={onDeletePressed}
                disabled={disableDeleteOption}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title
                    style={{
                      color: 'red',
                      opacity: disableDeleteOption ? 0.5 : 1
                    }}
                  >
                    Delete Record
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>
              {canModifyEntityTypes && (
                <>
                  <Divider />
                  <ListItemLinkButton
                    hideAvatar={true}
                    title={`Edit Type: ${entityType.label}`}
                    containerStyle={listItemContainerStyle}
                    onPress={editEntityType}
                  />
                </>
              )}
            </View>
          </Overlay>
        </>
      )}
      {viewActions && (
        <ViewActionsOverlay
          entity={entity}
          entityType={entityType}
          onDismissed={() => setViewActions(false)}
        />
      )}
      {printPdfOverlayVisible && (
        <PrintPdfOverlay
          allPages={allPagesVisible}
          entity={entity}
          entityType={entityType}
          onDismissed={() => setPrintPdfOverlayVisible(false)}
        />
      )}
    </>
  )
}
