import api from '../../data/api'
import _ from 'lodash'

const route = '/permissions'



const clearGeneralError = () => {
  return {
    type: 'permission/clear-general-error'
  }
}

const clearFieldErrors = () => {
  return {
    type: 'permission/clear-field-errors'
  }
}

const update = (newPermissions) => {

  return (dispatch) => {
    dispatch({
      type: 'permission/update-started',
    })

    const method = "post"
    return api[method](route, newPermissions) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'permission/update-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'permission/update-error',
          error
        })

        throw error
      })
  }
}
const listPermissionsForEntityType = (entityType, args={}) => {
  return (dispatch) => {


    const fullRoute = `${route}/${entityType.data.name}`

    dispatch({
      type: 'permission/list-started'
    })
    return api
      .get(fullRoute)
      .then(async (response) => {
        
        await dispatch({
          type: 'permission/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'permission/list-error',
          error
        })

        throw error
      })
  }
}


const listPermissions = (args={}) => {
  return (dispatch) => {



    dispatch({
      type: 'permission/list-started'
    })
    return api
      .get(route)
      .then(async (response) => {
        
        // strip the entity type level permissions from these roles.
        // this generic editor is only for non entity types.
        response.data.roles.forEach(r => {
          r.permissions = r.permissions.filter(p => p.name.indexOf("|id:") === -1);
        })
        await dispatch({
          type: 'permission/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'permission/list-error',
          error
        })

        throw error
      })
  }
}

export default { listPermissions, listPermissionsForEntityType, update, clearGeneralError, clearFieldErrors }
