import React, { useMemo } from 'react'

import { EntityField } from './EntityField'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
// this component just wraps entity field and injects the
// entity typesd that we know can be assigned to
// e.g. personnel, and any entity types that personnel can be assigned to
// in my case, personnel has a department drop down, so department can be assigned a task
export const EntityFieldSelectAssignee = ({ field, ...restOfProps }) => {
  // we need to fetch the source entity types.
  const { entityType: personnelType } = useDiscoverEntityEntityType(
    null,
    null,
    'personnel'
  )
  const assignableEntityTypes = useMemo(() => {
    //?
    // iterate through props of personnel. If any are a select entity type, they are the relevant props.

    //corresponds to const in entitytypefield.php:16

    return [{ name: 'personnel' }]
  }, [personnelType])

  field.field_data.params.sourceName = assignableEntityTypes?.map((e) => e.name)

  {
    return <EntityField {...restOfProps} field={field} />
  }
}
