import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import ListItemLinkButton from './ListItemLinkButton'
import fileActions from '../store/file/actions'
import PublicFormContext from '../contexts/PublicFormContext'
import mime from 'mime'
import Video from './VideoPlayer/VideoPlayer'
import spacing from '../styles/spacing'
import _ from 'lodash'
/* Files were typically previously rendered with ListItemLinkButton, as a clickable link row.
Files can now be richer, e.g. video or audio player

This component is a wrapper to catch the richer files (and render a video player), and pass through the simple files.

*/
export const ListItemFile = ({
  file,
  containerStyle,
  showChevron,
  removeAction
}) => {
  const dispatch = useDispatch()
  const publicFormContext = useContext(PublicFormContext)

  useEffect(() => {
    if (file) {
      // there is an image ID.
      dispatch(
        fileActions.showFile(
          file,
          publicFormContext.tenantId
            ? {
                tenantId: publicFormContext.tenantId,
                tenantHmac: publicFormContext.tenantHmac
              }
            : undefined
        )
      )
    }
  }, [file])

  const fileObject = useSelector((state) =>
    _.get(state.files, `byId[${file}]`, null)
  )
  const mimeType = mime.getType(fileObject?.path)

  // for any further codepushes of 1.0.12, we do not want to display the video viewer
  // since the 1.0.12 binary does not have the video viewer in it.
  if (mimeType == 'video/mp4' || mimeType == 'video/quicktime') {
    return (
      <View
        style={[
          containerStyle,
          { height: undefined, paddingRight: spacing.m5 }
        ]}
      >
        <Video source={fileObject.url} />
      </View>
    )
  }

  return (
    <ListItemLinkButton
      avatarImageUri={fileObject?.url}
      fileUrl={fileObject?.url}
      title={fileObject?.original_name}
      containerStyle={containerStyle}
      showChevron={showChevron}
      removeAction={removeAction}
    />
  )
}
