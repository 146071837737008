import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View, Text } from 'react-native'
import { EntityTypeFieldPicker } from './EntityTypeFieldPicker'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ListItemLinkButton } from '../ListItemLinkButton'
import { Divider } from 'react-native-elements'
import spacing from '../../styles/spacing'
import shared from '../../styles/shared'
import MSFESButton from '../MSFESButton'
import colors from '../../styles/colors'

export const EntityTypeFieldMapper = React.forwardRef((props, ref) => {
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  const { onEntityTypeSelected, mappedFields } = props

  const entity1Fields = _.get(props.sourceEntityType, 'data.fields', [])

  const entity2 = entityTypes.find(
    (e) => e.id === parseInt(props.destinationEntityTypeId)
  )

  const entity2Fields = _.get(entity2, 'fields', [])

  const entityFieldMapSourceChosen = (value, index) => {
    const mappedFields = [...props.mappedFields]
    mappedFields[index].source = value
    props.onFieldmapChanged(mappedFields)
  }
  const entityFieldMapDestinationChosen = (value, index) => {
    const mappedFields = [...props.mappedFields]
    mappedFields[index].destination = value
    props.onFieldmapChanged(mappedFields)
  }

  const onAddMappedFieldPressed = () => {
    const mappedFields = [...props.mappedFields]
    mappedFields.push({ source: null, destination: null, offline_id: uuidv4 })
    props.onFieldmapChanged(mappedFields)
  }

  const deleteMapRow = (mappedFieldSet, index) => {
    const mappedFields = [...props.mappedFields]
    mappedFields.splice(index, 1)

    props.onFieldmapChanged(mappedFields)
  }

  const textSymbol = [
    {
      backgroundColor: colors.inputBottomBorder,
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderRadius: 4
    }
  ]
  return (
    <View style={[shared.debugOff, { flexDirection: 'column' }]}>
      {mappedFields.map((mappedFieldSet, index) => {
        const sourceValue = _.get(mappedFieldSet.source, 'id', null)
        const targetValue = _.get(mappedFieldSet.destination, 'id', null)

        const sourceTitle =
          '' + _.get(mappedFieldSet.source, 'field_data.title', null)
        const targetTitle =
          '' + _.get(mappedFieldSet.destination, 'field_data.title', null)

        return (
          <View style={[{ flexDirection: 'row', flex: 1 }, shared.debugOff]}>
            {sourceValue && targetValue && (
              <View style={{ flex: 1 }}>
                <Text>
                  <Text style={textSymbol}>{sourceTitle}</Text> maps to{' '}
                  <Text style={textSymbol}>{targetTitle}</Text>
                </Text>
              </View>
            )}
            {!(sourceValue && targetValue) && (
              <View style={{ flex: 1 }}>
                <View style={[{ paddingBottom: spacing.m1 }, { flex: 1 }]}>
                  <EntityTypeFieldPicker
                    entityTypeFields={entity1Fields}
                    placeholder="Source Field"
                    value={'' + sourceValue}
                    onEntityTypeFieldSelected={(value) =>
                      entityFieldMapSourceChosen(value, index)
                    }
                  />
                </View>
                <View style={[{ paddingBottom: spacing.m1 }, { flex: 1 }]}>
                  {entity2 && (
                    <EntityTypeFieldPicker
                      placeholder="Target Field"
                      entityTypeFields={entity2Fields}
                      value={'' + targetValue}
                      onEntityTypeFieldSelected={(value) =>
                        entityFieldMapDestinationChosen(value, index)
                      }
                    />
                  )}
                </View>
              </View>
            )}
            <View>
              <MSFESButton
                icon={{ type: 'font-awesome', name: 'trash' }}
                type="delete"
                onPress={() => deleteMapRow(mappedFieldSet, index)}
              ></MSFESButton>
            </View>
            <Divider />
          </View>
        )
      })}
      <ListItemLinkButton
        title={`Add Mapped Field`}
        type="add"
        onPress={onAddMappedFieldPressed}
      />
    </View>
  )
})
