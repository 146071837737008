import React, { useState } from 'react'

import { View, StyleSheet, Platform, Linking } from 'react-native'
import { Overlay, ListItem } from 'react-native-elements'

import { useDispatch, useSelector } from 'react-redux'

import _ from 'lodash'
import colors from '../styles/colors'
import actions from '../store/entity-type/actions'
import { SelectTenant } from './fields/SelectTenant'
import MSFESHeading from './MSFESHeading'
import { useUploadDataFile } from '../hooks/useUploadDataFile'
import ListItemLinkButton from './ListItemLinkButton'

export default function EntityListActions({
  isVisible,
  entityType,
  onBackdropPress,
  dropdownOverlayStyle,
  onGenerateReportRequestedPDF,
  refreshList,
  onGenerateReportRequestedExcel,
  onGenerateTimesheetReportRequested,
  filters,
  queryParams
}) {
  const dispatch = useDispatch()
  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const hasGlobalAdminRole = !!current_user.roles.find(
    (r) => r.name === 'MSFES User'
  )

  const [showTenantPicker, setShowTenantPicker] = useState(null)
  const listItemContainerStyle = {
    backgroundColor: colors.lightestGray,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: colors.lightGray
  }

  const isAvailablePublicly = _.get(
    entityType,
    'object_data.is_public_form',
    false
  )

  const getPublicFormUrl = (tenantId = null) => {
    dispatch(actions.getPublicUrl(entityType, tenantId)).then((response) => {
      Linking.openURL(response.url)
    })
  }

  const IsOnWeb = Platform.OS === 'web'

  const {
    uploadDataFile,
    fileImportOverlay,
    createImportTemplate,
    exportDataAsCSVLongRunning,
    onGenerateNFIRSReportRequestedLongRunning,
    exportLoading,
    nfirsLoading
  } = useUploadDataFile({ entityType, filters, queryParams })

  return (
    isVisible &&
    entityType && (
      <>
        <Overlay
          visible={true}
          backdropStyle={{ opacity: 0 }}
          onBackdropPress={onBackdropPress}
          overlayStyle={dropdownOverlayStyle}
        >
          <View style={{ marginTop: -1 }}>
            <ListItem
              onPress={refreshList}
              containerStyle={listItemContainerStyle}
            >
              <ListItem.Content>
                <ListItem.Title>Refresh</ListItem.Title>
              </ListItem.Content>
            </ListItem>
            {Platform.OS === 'web' && (
              <ListItem
                onPress={onGenerateReportRequestedPDF}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>PDF Report</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )}
            {Platform.OS === 'web' && (
              <ListItem
                onPress={onGenerateReportRequestedExcel}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>Excel Report</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )}
            {Platform.OS === 'web' && (
              <ListItemLinkButton
                containerStyle={listItemContainerStyle}
                hideAvatar={true}
                title={`NFIRS Report`}
                loading={nfirsLoading}
                onPress={onGenerateNFIRSReportRequestedLongRunning}
              />
            )}
            {Platform.OS === 'web' && entityType.name === 'time_sheet' && (
              <ListItem
                onPress={onGenerateTimesheetReportRequested}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>Timesheet Report</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )}
            {Platform.OS === 'web' && isAvailablePublicly && (
              <ListItem
                onPress={() => {
                  //

                  if (hasGlobalAdminRole && !current_user.current_tenant) {
                    setShowTenantPicker('publicForm')
                  } else if (hasGlobalAdminRole) {
                    getPublicFormUrl(current_user.current_tenant.id)
                  } else {
                    getPublicFormUrl()
                  }
                }}
                containerStyle={listItemContainerStyle}
              >
                <ListItem.Content>
                  <ListItem.Title>Link to Public Form</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )}

            {IsOnWeb && (
              <>
                <ListItemLinkButton
                  containerStyle={listItemContainerStyle}
                  hideAvatar={true}
                  title={`Create Import Template CSV`}
                  onPress={createImportTemplate}
                />
                <ListItemLinkButton
                  containerStyle={listItemContainerStyle}
                  hideAvatar={true}
                  title={`Upload CSV Data File`}
                  onPress={uploadDataFile}
                />
                <ListItemLinkButton
                  containerStyle={listItemContainerStyle}
                  hideAvatar={true}
                  title={`Export Data to CSV`}
                  loading={exportLoading}
                  onPress={exportDataAsCSVLongRunning}
                />
              </>
            )}

            {!!showTenantPicker && (
              <Overlay>
                <MSFESHeading>Select Tenant</MSFESHeading>
                <SelectTenant
                  onChangeText={(tenantId) => {
                    switch (showTenantPicker) {
                      case 'publicForm':
                        getPublicFormUrl(tenantId)
                        break
                    }
                    setShowTenantPicker(null)
                  }}
                />
              </Overlay>
            )}
          </View>
        </Overlay>

        {fileImportOverlay}
      </>
    )
  )
}
