import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { EntityTypeFieldPicker } from './fields/EntityTypeFieldPicker'

import _ from 'lodash'
import { supportedFieldTypes } from '../libraries/supportedFieldTypes'
import spacing from '../styles/spacing'
import MSFESChip from './MSFESChip'
import { getLabel } from './fields/SelectWithData'

export const ContainerFieldsPicker = React.forwardRef((props, ref) => {
  const { fields, field, onChangeText, value } = props

  const [localSelectedFields, setLocalSelectedFields] = useState([])

  const labelField = 'field_data.title'

  const localSelectedIds = localSelectedFields.map((f) => f.id).filter((f) => f)
  const localSelectedOfflineIds = localSelectedFields
    .map((f) => f.offline_id)
    .filter((f) => f)
  const selectableFields = fields
    .filter((f) => f.offline_id !== field.offline_id)
    .filter((f) => {
      if (f.offline_id && !localSelectedOfflineIds.includes(f.offline_id)) {
        return true
      }
      if (f.id && !localSelectedIds.includes(f.id)) {
        return true
      }
    })

  useEffect(() => {
    // we will take the value supplied to the field and load it in the localSelectedFields.
    const fieldsFromValues = value
      ? value
          .map((v) => {
            const { id, offline_id } = v

            const matchingField = fields.find(
              (f) =>
                f.id === id || (f.offline_id && f.offline_id === offline_id)
            )

            return matchingField
          })
          .filter((f) => f)
      : []

    setLocalSelectedFields(fieldsFromValues)
  }, [fields])

  const onSelectedNewField = (value) => {
    if (!value) {
      return
    }

    const newFields = [...localSelectedFields]
    newFields.push(value)

    setLocalSelectedFields(newFields)
    propagateChange(newFields)
  }

  const removeField = (field, index) => {
    const newFields = [...localSelectedFields]
    newFields.splice(index, 1)

    setLocalSelectedFields(newFields)
    propagateChange(newFields)
  }
  const propagateChange = (newFields) => {
    onChangeText(
      newFields.map((nf) => {
        return { id: nf.id, offline_id: nf.offline_id }
      })
    )
  }
  return (
    <View style={[{ flexDirection: 'row', flex: 1 }]}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginHorizontal: spacing.m2,
          maxWidth: 500,
          flexWrap: 'wrap'
        }}
      >
        {localSelectedFields &&
          localSelectedFields.map((valueOption, index) => {
            return (
              <MSFESChip
                key={index}
                onClose={() => removeField(valueOption, index)}
                style={{ marginTop: spacing.m1 }}
              >
                {getLabel(valueOption, labelField)}
              </MSFESChip>
            )
          })}
      </View>

      <View style={{ justifyContent: 'center' }}>
        <EntityTypeFieldPicker
          entityTypeFields={selectableFields}
          placeholder="Source Field"
          filterFunction={(f) => {
            const matchedType = supportedFieldTypes.find(
              (ft) => ft.type == f.field_data.type
            )
            return (
              _.get(matchedType, 'disallowNestingInContainer', false) == false
            )
          }}
          value={null}
          onEntityTypeFieldSelected={(value) => {
            onSelectedNewField(value)
          }}
        />
      </View>
    </View>
  )
})
