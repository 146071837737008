// libraries
import React, { useState, useEffect, useRef } from 'react'
import { View, SafeAreaView, Image, Alert, useWindowDimensions, Platform } from 'react-native'
import { Button, Text, Divider } from 'react-native-elements'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import { showMessage, hideMessage } from 'react-native-flash-message'
// styles
import spacing from '../styles/spacing'
import shared from '../styles/shared'

//actions
import userActions from '../store/user/actions'
// components
import MSFESButton from '../components/MSFESButton'
import MSFESInput from '../components/MSFESInput'
import { StatusBar } from 'react-native'
import { KeyboardAvoidingView } from 'react-native'
import { TouchableOpacity } from 'react-native'
import MSFESIcon from '../components/MSFESIcon'

import { websafeGoBack } from '../libraries/navigation'
import { useLinkTo, useNavigationState } from '@react-navigation/native'
import MSFESHeading from '../components/MSFESHeading'
import { InputTextStyle } from '../components/ListItemAsFieldProps'
import SignInFireman from '../components/SignInFireman'
import SignInLogo from '../components/SignInLogo'
import DisplayErrors from '../components/DisplayErrors'

export default function ForgotPasswordScreen({ route, navigation }) {
  const dispatch = useDispatch()

  navigation.setOptions({ title: "Reset Password" })

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({ email: '', password: '' })

  const previousEmail = useSelector((state) => state.users.email)
  const errors = useSelector((state) => state.users.errors.errors)


  const timer = useRef(null);
  const changeHandler = (name, text) => {
    setValues({ ...values, [name]: text })
  }

  useEffect(() => {
    // if there's an email in store, put it in the form.
    if (previousEmail && !values.email) {
      changeHandler('email', previousEmail)
    }

    if (errors) {
      // showMessage({
      //   message: errors,
      //   type: 'error'
      // })
    }
  }, [errors])

  useEffect(() => {

    dispatch(userActions.clearErrors());
  }, [])

  const resetPasswordPressed = () => {
    setLoading(true)
    dispatch(userActions.clearErrors());
    const { email } = values
    dispatch(userActions.resetPassword({ email }))
      .then(() => {
        showMessage({ message: "If this address exists in our system, you'll receive an email with further instructions.", type: 'success', duration: 10000 })

      })
      .catch((e) => { 
        // setErrors(e.message);
      })
      .finally(() => {
        setLoading(false)
      })
  }


  const environment = useSelector((state) => {
    return state.app.environment;
  })

  const navState = useNavigationState(state => state);
  const linkTo = useLinkTo();

  // const [ errors, setErrors ] = useState(null);


  const imageMinHeight = Platform.OS === "web" ? 300 : 100

  console.log("Errors", errors)
  return (
    <SafeAreaView style={[shared.fullHeight, shared.darkView]}>
      <StatusBar barStyle="light-content" />

      <SignInFireman />
      <View style={{ alignItems: "flex-start" }}>
        <TouchableOpacity onPress={() => {
          navigation && websafeGoBack(navigation, navState, linkTo, null)
        }}><MSFESIcon name={'chevron-left'} tappable dark
          size={24}
          type="font-awesome" /></TouchableOpacity></View>


      <View style={[shared.mobileWidthView, shared.darkView, shared.debugOff, shared.fullHeight]}>
        {/* <View style={[shared.debugOff, { alignItems: "center", flex: 1 / 3, minHeight: imageMinHeight, flexDirection: 'row' }]} pointerEvents={"none"}>
          <TouchableOpacity activeOpacity={1} style={[{ flex: 1, minHeight: imageMinHeight, alignItems: "center" }, shared.debugOff]}>
            <Image source={require("../images/ember_phoenix.png")} style={[{ width: "100%", maxWidth: 180, minHeight: imageMinHeight }, shared.debugOff]} resizeMode="contain" />

          </TouchableOpacity>
        </View> */}

      <SignInLogo />


        <KeyboardAvoidingView keyboardVerticalOffset={160} contentContainerStyle={[{ flex: 1, flexDirection: "column", justifyContent: "center" }]} behavior={Platform.OS === "ios" ? "padding" : "height"}>
          <View style={[shared.debugOff, { flexDirection: "column", justifyContent: "center" }]}>
            <MSFESHeading signin fontSize={18} style={{ marginBottom: spacing.m2 }}>Reset Password</MSFESHeading>
            <DisplayErrors errors={errors} dark />
            <MSFESInput
              showLabel={false}
              signin
              placeholder="Email Address"
              defaultValue={values.email}
              style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
              keyboardType={'email-address'}
              name="email"
              errorMessage={_.get(errors, 'email', []).join(',')}
              onChangeText={(text) => changeHandler('email', text)}
            />

            <MSFESButton

              dark
              primary
              loading={loading}
              title="Reset Password"
              onPress={resetPasswordPressed}
            />

          </View>
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  )
}
