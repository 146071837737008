import React, { useEffect, useMemo, useState } from 'react'
import { View, Text } from 'react-native'
import { useSelector, useStore } from 'react-redux'
import MSFESButton from '../MSFESButton'
import QueryBuilder from '../query/QueryBuilder'
import _ from 'lodash'
import { CURRENT_USER_ENTITY_TYPE } from '../../libraries/supportedEntityTypeProperties'
const QueryEditorField = (props) => {
  const [localValue, setLocalValue] = useState(null)
  const [renderKey, setRenderKey] = useState(0)
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    if (props.defaultValue && !localValue) {
      setLocalValue(props.defaultValue)
      setRenderKey(renderKey + 1)
    }
  }, [props.defaultValue])

  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  const store = useStore()
  const entityTypeSelected = useMemo(() => {
    if (!props.editable) {
      return
    }
    const entityTypeShort =
      props.data.entity?.entity_type_target ||
      props.data.field?.field_data?.params?.entityTypeTarget

    if (entityTypeShort) {
      if (entityTypeShort === CURRENT_USER_ENTITY_TYPE.name) {
        return CURRENT_USER_ENTITY_TYPE
      }
      const match = entityTypes.find((e) => e.id === entityTypeShort.id)
      if (match) {
        return match
      }
      if (!match) {
        const matchName =
          entityTypes.find((e) => e.name === entityTypeShort.name) ||
          entityTypes.find((e) => e.name === entityTypeShort)

        return matchName
      }
    }

    return null
  }, [
    entityTypes,
    props.data.entity?.entity_type_target,
    props.data.field?.field_data?.params?.entityTypeTarget,
    props.editable
  ])

  const onRulesChanged = (newRules) => {
    // props.changeHandler(newRules);
    setLocalValue(newRules)
    setHasChanges(true)
  }

  const saveChanges = () => {
    props.changeHandler(localValue)
    setHasChanges(false)
  }

  return (
    <View>
      {!props.editable && (
        <Text>
          Filter rules:{' '}
          {props.defaultValue
            ? 'A rule has been saved'
            : 'No rule has been saved'}
        </Text>
      )}
      {props.editable && (
        <View>
          {!entityTypeSelected && (
            <Text>Ensure an entity type is selected first.</Text>
          )}
          <QueryBuilder
            key={renderKey}
            entityType={entityTypeSelected}
            store={store}
            additionalFieldsForQueryEditor={
              props.additionalFieldsForQueryEditor
            }
            action={localValue ? { ruleset: localValue?.rules } : undefined}
            onQueryChanged={onRulesChanged}
          />

          <MSFESButton
            disabled={!hasChanges}
            variant="link"
            onPress={saveChanges}
            title="Save Changes"
          />
        </View>
      )}
    </View>
  )
}

export default QueryEditorField
