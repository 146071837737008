import api from '../../data/api'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-community/async-storage'

export const getFilterString = () => {
  return ''
}
const getRouteForFileRequest = ({
  filter = null,
  page = null,
  searchText = null
}) => {
  const filterString = getFilterString(filter)

  const entityLabel = '/files/'

  return (
    entityLabel +
    (page || filterString ? '?' : '') +
    (page ? `page=${page}&` : '') +
    (searchText
      ? `filter[display_name]=${encodeURIComponent(searchText)}&`
      : '') +
    (filterString ? filterString : '')
  )
}

export const showFileInTenantField = (fieldFriendlyName) => {
  return (dispatch) => {
    const route = `/tenant/file/${fieldFriendlyName}`

    return api
      .get(route)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/show-error',
          error
        })

        throw error
      })
  }
}
export const showHandbook = () => {
  return (dispatch) => {
    const route = `/handbook`

    return api
      .get(route)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/show-error',
          error
        })

        throw error
      })
  }
}

const showFile = (id, publicParams = null) => {
  return (dispatch) => {
    let route = '/files/' + id

    if (publicParams) {
      route = `/public-forms/files/${id}?tenantId=${publicParams.tenantId}&tenantHmac=${publicParams.tenantHmac}`
    }
    dispatch({
      type: 'file/show-started',
      id
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'file/show-success',
          response,
          id
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/show-error',
          error
        })

        throw error
      })
  }
}

const tryUploadQueued = (idOrOfflineId = null) => {
  return (dispatch, getState) => {
    const fileState = getState()

    const queuedUploads = fileState.files.queuedUploads ?? []
    const pendingFiles = idOrOfflineId
      ? [...queuedUploads].filter((f) => f.data.saveAgainstId == idOrOfflineId)
      : [...queuedUploads]

    return Promise.all(
      pendingFiles.map((pf) => {
        return dispatch(
          uploadFile(
            pf.data.payload,
            pf.data.filename,
            pf.data.fieldId,
            pf.data.isRestricted,
            pf.data.saveAgainstId,
            pf.data.fileOfflineId
          )
        )
      })
    )
  }
}

const removeQueuedFile = (removePending) => {
  return {
    type: 'file/remove-stored-locally',
    data: {
      file: removePending
    }
  }
}
const storeFile = (
  payload,
  filename,
  fieldId,
  isRestricted,
  saveAgainstId,
  fileOfflineId
) => {
  return {
    type: 'file/store-locally',
    data: {
      payload,
      filename,
      fieldId,
      isRestricted,
      saveAgainstId,
      fileOfflineId
    }
  }
}
const uploadFile = (
  file,
  fileName = 'file',
  entity_field_id = null,
  isRestricted = true,
  localId = null,
  fileOfflineId = null,
  publicArgs = null
) => {
  return async (dispatch) => {
    let dataArray = new FormData()

    let md5
    let sendMultipartHeader

    const functionName = dataArray.set ? 'set' : 'append'
    if (dataArray.set) {
      // desktop

      md5 = file.md5
      const uri = file.uri
        ? file.uri
        : Platform.OS === 'web'
        ? localStorage.getItem(file.md5)
        : await AsyncStorage.getItem(file.md5)

      const blob = await fetch(uri).then((res) => res.blob())

      dataArray[functionName]('file', blob, fileName)
      sendMultipartHeader = false
    } else {
      md5 = file.md5
      const uri = await AsyncStorage.getItem(file.md5)

      // mobile upload just wants URI, not the blob.
      if (!file.uri && uri) {
        file.uri = uri
      }
      if (Platform.OS === 'android') {
        file.uri = 'file://' + file.uri
      }
      delete file.md5
      // const blob = await fetch(uri).then((res) => res.blob())
      dataArray[functionName]('file', file, fileName)
      sendMultipartHeader = true
    }
    entity_field_id &&
      dataArray[functionName]('entity_field_id', entity_field_id)
    dataArray[functionName]('is_restricted', isRestricted ? 1 : 0)
    dataArray[functionName]('client_entity_id', localId)
    dataArray[functionName]('offline_id', fileOfflineId)
    if (publicArgs) {
      Object.keys(publicArgs).map((key) => {
        dataArray.set(key, publicArgs[key])
      })
    }

    dispatch({
      type: 'file/upload-started',
      data: dataArray
    })

    // if the entity def specifies it's own endpoint, use that.
    let route = publicArgs ? '/public-forms/files' : '/files'

    const multipartHeader = { 'Content-Type': 'multipart/form-data' }

    return api
      .postForm(route, dataArray, sendMultipartHeader ? multipartHeader : null) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        md5 &&
          (Platform.OS === 'web'
            ? localStorage.removeItem(md5)
            : await AsyncStorage.removeItem(md5))
        await dispatch({
          type: 'file/upload-success',
          response,
          md5,
          localId
        })

        return response
      })
      .catch((error) => {
        console.warn(error)
        dispatch({
          type: 'file/upload-error',
          error
        })

        throw error
      })
  }
}

const listFiles = (args = {}) => {
  const { filter, page, searchText } = args

  return (dispatch) => {
    const route = getRouteForFileRequest({ filter, page, searchText })

    const filterString = getFilterString(filter)

    dispatch({
      type: 'file/list-started',
      filterString
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'file/list-success',
          response,
          filterString
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'file/list-error',
          error
        })

        throw error
      })
  }
}

export default {
  uploadFile,
  showFile,
  storeFile,
  removeQueuedFile,
  listFiles,
  tryUploadQueued
}
