import React from 'react'
import ListItemAsFieldProps from '../ListItemAsFieldProps'
import ListItemLinkButton from '../ListItemLinkButton'

export const FolderTile = ({ folder, onPress }) => {
  return (
    <ListItemLinkButton
      key={folder.id}
      onPress={onPress}
      title={folder.folder_name}
      containerStyle={ListItemAsFieldProps.containerStyle}
      showChevron={false}
    />
  )
}