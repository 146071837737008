import React from 'react'
import { viewMode } from '../screens/EntityAddScreen'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

import MSFESNestedAddEntity from './MSFESNestedAddEntity'

const MyProfile = ({ navigation, onDismiss }) => {
  const { current_user } = useSelector((state) => {
    return {
      current_user: _.get(state, 'users.user')
    }
  })

  const { entityType } = useDiscoverEntityEntityType(null, null, 'personnel')

  const entity = current_user.personnel_record
  const mode = viewMode

  const entityChanged = () => {}

  return entityType ? (
    <MSFESNestedAddEntity
      navigation={navigation}
      entityType={entityType}
      tenant={entity?.tenant_id}
      mode={mode}
      id={entity?.id}
      onDismiss={onDismiss}
      shouldDismissOnSaved={false}
      onChangedCallback={entityChanged}
    />
  ) : null
}

export default MyProfile
