
export const DEBUG_TEXT = false

import spacing from './spacing'
// console.log('Debugging text is', DEBUG_TEXT)
const debug = DEBUG_TEXT
  ? {
      color: 'red',
      borderWidth: 1,
      borderColor: 'red',
      marginBottom: 4
    }
  : { display: 'none' }

const h2 = {
  marginTop: spacing.m2,
  marginBottom: spacing.m1
}
const h4 = {
  marginTop: spacing.m2,
  marginBottom: spacing.m1
}
const modalHeader = {
  marginTop: 0,
  marginBottom: spacing.m1
}

const small = {
  fontSize: 11
}

const breadcrumb = {}
export default { debug, h2, h4, modalHeader, breadcrumb, small }
