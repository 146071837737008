import React from 'react'
import { View, Image, Text } from 'react-native'

export const MSFESFooter = () => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <Image
        source={require('../images/msfes_header.png')}
        style={{ width: 80, height: 40 }}
        resizeMode={'contain'}
      />
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Text style={{ textAlign: 'right' }}>
          Powered by Management Solutions For Emergency Services
        </Text>
      </View>
    </View>
  )
}
