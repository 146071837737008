// libraries
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext
} from 'react'
import { View, Switch, Platform, Text } from 'react-native'
import { withTheme, Input, Card, Icon } from 'react-native-elements'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { FullAddressNFIRS } from './FullAddressNFIRS'
// internal libraries
import { supportedFieldTypes } from '../libraries/supportedFieldTypes'
import spacing from '../styles/spacing'
import DebugText from './DebugText'

import { SelectField, SelectIcon } from './fields/SelectWithData'
import { SingleFile } from './fields/SingleFile'
import { SignaturePadContainer } from './fields/SignaturePadContainer'
import { TermsAndConditions } from './fields/TermsAndConditions'
import { YesNo } from './fields/YesNo'
import { DynamicTextFields } from './fields/DynamicTextFields'
import MSFESInput from './MSFESInput'
import colors from '../styles/colors'
import MSFESHeading from './MSFESHeading'
import { RowContainer } from './RowContainer'
import shared from '../styles/shared'
import { SelectList } from './fields/SelectList'
import { SelectState } from './fields/SelectState'
import { SelectPersonnel } from './fields/SelectPersonnel'
import { RadioList } from './fields/RadioList'
import { RadioAdhoc } from './fields/RadioAdhoc'
import { SelectAdhoc } from './fields/SelectAdhoc'
import { CheckboxMultiselectAdhoc } from './fields/CheckboxMultiselectAdhoc'
import { ViewDescription } from './fields/ViewDescription'
import { SelectTenant } from './fields/SelectTenant'
import { SelectRole } from './fields/SelectRole'
import { FullName } from './FullName'
import { LatLng } from './LatLng'
import { SelectAdminPersonnel } from './fields/SelectAdminPersonnel'
import { DatePicker } from './fields/DatePicker'
import { TimePicker } from './fields/TimePicker'
import { DateTimePicker } from './fields/DateTimePicker'
import RichTextContainer from './RichTextContainer'
import { SelectListMultiple } from './fields/SelectListMultiple'
import { fieldHeight, InputTextStyle } from './ListItemAsFieldProps'
import MSFESSvgIcon from '../icons/MSFESSvgIcon'
import TextLabel from './TextLabel'
import SelectEntityType from './fields/SelectEntityType'
import QueryEditorField from './fields/QueryEditorField'
import { CheckboxMultiselect } from './fields/CheckboxMultiselect'
import ViewList from './ViewList'
import ViewListInline from './ViewListInline'
import { TileContainer } from './TileContainer'
import { MSFESFieldTile } from './MSFESFieldTile'
import { ButtonContainer } from './ButtonContainer'
import FieldLinkTo from './fields/FieldLinkTo'
import {
  ApprovalRequired,
  CustomFunctionalityFieldType
} from './fields/CustomFunctionalityFieldType'
import CrossFieldRenderAsNullContext from '../contexts/CrossFieldRenderAsNullContext'
import ColorPickerField from './fields/ColorPickerField'
import { QuizQuestionGroupContainer } from './QuizQuestionGroupContainer'
import { RadioQuiz } from './fields/RadioQuiz'
import { CheckboxMultiselectQuiz } from './fields/CheckboxMultiselectQuiz'
import { TrueFalseQuiz } from './fields/TrueFalseQuiz'
import { MatrixContainer } from './MatrixContainer'
import { MultipleRoleSelector } from './fields/MultipleRolePicker'
import { SelectTenantMultiple } from './fields/SelectTenantMultiple'
import WidgetEntityField from './WidgetEntityField'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import { NumberSlider } from './NumberSlider'
import { NumberCounter } from './NumberCounter'
import { StarRating } from './StarRating'
import { MaskedInput } from './MaskedInput'
import { GroupContainer } from './GroupContainer'
import { SelectFilters } from './fields/SelectFilters'

export const getFieldType = (field) => {
  let selectedFieldType = supportedFieldTypes.find(
    (f) => f.type === field.field_data.type
  )

  if (!selectedFieldType) {
    console.info('Unmanaged field type', field, supportedFieldTypes)
  }

  return selectedFieldType
}

const getLabelForField = (field) => {
  if (field.title) {
    return field.title
  }
  if (field.field_data) {
    return _.get(field, 'field_data.title', null)
  }
  throw new Error('Could not match label', field)
}

export const EntityField = (props) => {
  const {
    field,
    renderStaticHeader = false,
    adminLabel = null,
    fieldChanged = () => {},
    showLabel = true,
    displayFieldNumberInsteadOfLabel = false,
    fieldIndex = null,
    displayIcon = true,
    entity,
    definition,
    prepopulatedFieldValue,
    horizontal = false,
    fileAppended = () => {},
    dataAwaitingApproval = null,
    pendingApprovals = [],
    onApproveToggled,
    style,
    fieldVisibilityRules
  } = props

  const { user, role } = useSelector((appState) => ({
    user: appState.users?.user,

    role: appState?.users?.user?.personnel_record?.role ?? 'Tenant User'
  }))
  const userCanApprove = useMemo(() => {
    return !!(field.field_data?.params?.approverRoles ?? []).find(
      (ar) => ar.key === role
    )
  }, [field.field_data?.params?.approverRoles, user, role])

  const [isDirty, setIsDirty] = useState(false)
  const { isGlobalAdmin } = useTenantOrGlobalAdmin()

  const placeholderForField = useMemo(() => {
    return null
    // if (field.placeholder) {
    //   return field.placeholder
    // } else {
    //   if (field.field_data) {
    //     const placeholder = _.get(field, 'field_data.placeholder', false)
    //     if (placeholder) {
    //       return placeholder
    //     }
    //     return 'Enter ' + getLabelForField(field)
    //   }
    // }

    // throw new Error('Could not match placeholder', field)
  }, [field])

  const currentPropertyValue = useMemo(() => {
    return _.get(entity, field.field_data.property, undefined)
  }, [entity])

  const [localValue, setLocalValue] = useState(
    hasPrepopulatedValue ? prepopulatedFieldValue : currentPropertyValue
  )

  useEffect(() => {
    if (currentPropertyValue !== localValue) {
      setLocalValue(currentPropertyValue)
    }
  }, [entity, currentPropertyValue])

  const errorMessage = useMemo(() => {
    let error = false

    const fieldType = getFieldType(field)
    const fieldTitle = _.get(field, 'field_data.title', '')

    if (isDirty) {
      if (fieldType?.validations) {
        // we may have client side validations to apply.
        // draws from supportedFieldTypes[field].validatesAs & supportedFieldTypes[field].validations.*
        Object.keys(fieldType?.validations ?? {}).map((key) => {
          switch (key) {
            case 'max':
              if (fieldType?.validatesAs === 'string') {
                if (
                  currentPropertyValue &&
                  currentPropertyValue.length > fieldType?.validations[key]
                ) {
                  error = `${fieldTitle} must be less than ${fieldType.validations[key]} characters long. (Currently ${currentPropertyValue.length})`
                }
              }
              break
          }
        })
      }
    }

    if (
      isDirty &&
      _.get(field, 'field_data.validation.required', false) &&
      currentPropertyValue === undefined
    ) {
      error = fieldTitle + ' is a required field'
    }

    if (props.errorMessage) {
      error = props.errorMessage
    }

    return error
  }, [currentPropertyValue, field, props.errorMessage, isDirty])

  const getFieldRenderType = (field) => {
    const selectedFieldType = getFieldType(field)

    if (selectedFieldType) {
      return selectedFieldType.renders
    }

    console.info('Unsure how to render', field)

    return 'text'
  }

  const getDynamicEditable = (field, user) => {
    // should include some definition stuff later, based on mode etc.

    let looksEditable = true
    let isDisabledFromPermissions = false

    if (props.mode !== 'edit' && props.mode !== 'add') {
      looksEditable = false
    }

    if (_.get(field, 'field_data.params.readonly', false) == true) {
      looksEditable = false
      isDisabledFromPermissions = true
    }

    if (
      props.mode !== 'add' &&
      _.get(field, 'field_data.validation.on_create_only', false) == true
    ) {
      looksEditable = false
      isDisabledFromPermissions = true
    }

    const hasVisibilityRules =
      _.get(field, 'field_data.permissions.visible_to', null) !== null

    if (looksEditable) {
      const role = user?.personnel_record?.role

      const modifiableBy = _.get(
        field,
        'field_data.permissions.modifiable_by',
        [
          {
            key: 'ALL',
            label: 'ALL',
            value: 'ALL'
          }
        ]
      ).map((m) => m.value) // e.g. "MSFES User"
      looksEditable =
        modifiableBy.includes(role) ||
        modifiableBy.includes('ALL') ||
        isGlobalAdmin
      isDisabledFromPermissions = !looksEditable
    }

    return { looksEditable, isDisabledFromPermissions, hasVisibilityRules }
  }
  const fieldType = getFieldType(field)
  const fieldRenderType = useMemo(() => {
    return getFieldRenderType(field)
  }, [field.field_data?.type])

  const hasPrepopulatedValue = prepopulatedFieldValue !== undefined

  const {
    editable,
    isDisabledFromPermissions,
    hasVisibilityRules
  } = useMemo(() => {
    const {
      looksEditable,
      isDisabledFromPermissions,
      hasVisibilityRules
    } = getDynamicEditable(field, user)

    return {
      editable: looksEditable && !hasPrepopulatedValue,
      isDisabledFromPermissions,
      hasVisibilityRules
    }
  }, [field, user, hasPrepopulatedValue])

  const childProps = {
    editable,
    dataAwaitingApproval,
    pendingApprovals,
    userCanApprove,
    onApproveToggled,
    isDisabledFromPermissions,
    showLabel,
    adminLabel,
    fieldIndex,
    displayFieldNumberInsteadOfLabel,
    horizontal: fieldType?.alwaysHorizontal ? true : horizontal,
    hasVisibilityRules,
    canScanBarcode: fieldType?.canScanBarcode,
    showClear: fieldType?.showClear,
    clearValue: fieldType?.clearValue,
    supportsInputShading: _.get(fieldType, 'supportsInputShading', true),
    disabled: !editable,
    placeholder: placeholderForField,
    definition,
    label: displayFieldNumberInsteadOfLabel
      ? fieldIndex + 1 + '.'
      : getLabelForField(field),
    helpText: _.get(field, 'field_data.helpText', null),
    defaultValue: localValue,
    errorMessage,
    defaultValueCanBeUpdated:
      fieldType?.defaultValueCanBeUpdated === undefined
        ? true
        : fieldType?.defaultValueCanBeUpdated,
    fieldsSupplied: props.fieldsSupplied,
    fieldTypeFilter: props.fieldTypeFilter,
    entityTypeFilter: props.entityTypeFilter,
    data: {
      entity,
      field
    }
  }

  const fieldChangeDebounced = useCallback(_.debounce(fieldChanged, 200), [
    entity
  ])
  const changeHandlerDebounced = useCallback(
    (newValue, isUserInitiated = true) => {
      setIsDirty(true)

      fieldChanged(field, newValue, isUserInitiated)
    },
    [setIsDirty, fieldChanged]
  )

  const changeHandler = useCallback(
    (newValue, isUserInitiated = true) => {
      setIsDirty(true)

      fieldChanged(field, newValue, isUserInitiated)
    },
    [setIsDirty, fieldChanged, field?.field_data?.property]
  )

  const wrappedChangeHandlerDebounced = useCallback(
    (value, isUserInitiated) => {
      setLocalValue(value)
      changeHandlerDebounced(value, isUserInitiated)
    },
    [setLocalValue, changeHandlerDebounced]
  )

  const wrappedChangeHandler = useCallback(
    (value, isUserInitiated) => {
      setLocalValue(value)
      changeHandler(value, isUserInitiated)
    },
    [setLocalValue, changeHandler]
  )
  const activeChangeChildProps = useMemo(() => {
    return {
      onChangeText: wrappedChangeHandlerDebounced,
      changeHandler: wrappedChangeHandlerDebounced,
      changeHandlerEntityLevel: fieldChangeDebounced
    }
  }, [wrappedChangeHandlerDebounced, fieldChangeDebounced])

  const blurChangeChildPropsNumber = {
    onBlur(e) {
      let value = Platform.OS === 'web' ? e.target.value : e.nativeEvent.text

      if (value == '') {
        value = null
      }
      wrappedChangeHandler(value, true)
    }
  }

  const blurChangeChildProps = useMemo(() => {
    return {
      onBlur(e) {
        const value =
          Platform.OS === 'web' ? e.target.value : e.nativeEvent.text
        wrappedChangeHandler(value, true)
      }
    }
  }, [wrappedChangeHandler])

  useEffect(() => {
    if (childProps.defaultValue) {
      changeHandlerDebounced(childProps.defaultValue, false)
    }
  }, [])

  const booleanOnPressHandler = () => {
    const newState = !localValue
    changeHandlerDebounced(newState)
    setLocalValue(newState)
  }

  if (_.get(field, 'field_data.params.mustSaveFirst', false) && !entity.id) {
    return (
      <Input
        {...childProps}
        InputComponent={() => (
          <View style={[{ flex: 1 }, { marginBottom: spacing.m2 }]}>
            <Card>
              <Text>
                Please save the {entity.label} first before setting{' '}
                {field.title}.
              </Text>
            </Card>
          </View>
        )}
      />
    ) // <View style={[{flex: 1}, {marginBottom: spacing.m2 }]}><Card><Text>Please save the {entity.label} first.</Text></Card></View>
  }

  // use these to pass things on down to a container.
  const containerProps = {
    mode: props.mode,
    prepopulatedFields: props.prepopulatedFields,
    fileAppended: fileAppended,
    fieldChanged: fieldChanged,
    entity: props.entity
  }

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering = useMemo(() => {
    return (
      _.get(
        crossFieldRenderAsNull,
        `suppressObject[${field?.field_data?.property}]`,
        true
      ) === false
    )
  }, [crossFieldRenderAsNull, field])

  const control = useMemo(() => {
    if (props.tile) {
      return <MSFESFieldTile {...childProps} />
    }
    let rightIconName = null,
      rightIcon = null,
      rightIconType = 'icon'
    const iconWidthArea = 32
    const svgHeight = iconWidthArea - 4
    const rightIconContainerStyle = { marginLeft: -(iconWidthArea + 4) }

    if (displayIcon) {
      switch (fieldRenderType) {
        case 'text':
        case 'single-text':
        case 'single-text-mask':
          rightIconName = 'FieldsText'
          rightIconType = 'svg'
          break
        case 'textarea':
          rightIconName = 'FieldsText'
          rightIconType = 'svg'
          break
        case 'email':
          rightIconName = 'at'
          break
        case 'year-entry':
          rightIconName = 'hashtag'
          break
        case 'number-entry':
          rightIconName = 'hashtag'
          break
        case 'phone':
          rightIconName = 'phone'
          break
        case 'signature-pad':
          rightIconName = 'signature'
          break
        case 'time-picker':
          rightIconName = 'clock'
          break
        case 'date-picker':
          rightIconName = 'FieldsDate'
          rightIconType = 'svg'
          break
        case 'single-file':
          rightIconName = 'file'
          break
        case 'date-time-picker':
          rightIconName = 'FieldsDateTime'
          rightIconType = 'svg'
          break
        default:
          rightIconName = null
          break
      }
    }

    if (rightIconName) {
      switch (rightIconType) {
        case 'icon':
          rightIcon = (
            <View style={{ width: iconWidthArea }}>
              <Icon
                type="font-awesome-5"
                name={rightIconName}
                color={colors.blueLighter}
                size={16}
              />
            </View>
          )
          break
        case 'svg':
          rightIcon = (
            <View
              style={{
                paddingRight: spacing.m0_5,
                width: iconWidthArea,
                alignItems: 'center'
              }}
            >
              <MSFESSvgIcon
                name={rightIconName}
                fill="transparent"
                stroke={colors.blueLighter}
                strokeWidth="2"
                height={svgHeight}
                width="100%"
                viewBox="0 0 40 40"
                preserveAspectRatio="xMinYMin slice"
              />
            </View>
          )
          break
      }
    }

    const rightIconProps = { rightIcon, rightIconContainerStyle }

    let control
    switch (fieldRenderType) {
      case 'text':
      case 'single-text':
      case 'email':
      case 'phone':
        control = skipStaticRendering ? null : (
          <MSFESInput
            {...childProps}
            {...blurChangeChildProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
          />
        )
        break

      case 'single-text-mask':
        control = (
          <MSFESInput
            {...childProps}
            {...blurChangeChildProps}
            InputComponent={MaskedInput}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
          />
        )
        break
      case 'field-link-to':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={FieldLinkTo}
          />
        )
        break

      case 'formula-result':
        control = (
          <MSFESInput
            {...childProps}
            editable={false}
            {...blurChangeChildProps}
            InputComponent={MaskedInput}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
          />
        )
        break
      case 'formula-result-rollup':
        control = (
          <MSFESInput
            {...childProps}
            editable={false}
            {...blurChangeChildProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
          />
        )
        break
      case 'textarea':
        control = (
          <MSFESInput
            multiline
            numberOfLines={3}
            {...childProps}
            {...blurChangeChildProps}
            inputStyle={{
              paddingTop: InputTextStyle.marginTop,
              height: 120,
              textAlignVertical: 'top'
            }}
            {...rightIconProps}
          />
        )

        break
      case 'select-filters':
        control = (
          <View>
            <MSFESInput
              horizontal
              {...childProps}
              {...activeChangeChildProps}
              InputComponent={SelectFilters}
            />
          </View>
        )
        break
      case 'dynamic-text-with-fields':
        control = (
          <MSFESInput
            horizontal
            {...childProps}
            {...blurChangeChildProps}
            InputComponent={DynamicTextFields}
          />
        )
        break
      case 'year-entry':
      case 'number-entry':
        control = skipStaticRendering ? null : (
          <MSFESInput
            {...childProps}
            {...blurChangeChildPropsNumber}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
            keyboardType={'numbers-and-punctuation'}
            type="number"
          />
        )
        break
      case 'terms-and-conditions':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={TermsAndConditions}
            value={currentPropertyValue}
          />
        )
        break
      case 'text-label':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            showLabel={false}
            InputComponent={TextLabel}
            value={childProps.data.field.field_data.params.labelText}
          />
        )
        // <TextLabel style={{flex: 1}} value={childProps.data.field.field_data.params.labelText}></TextLabel>
        break
      case 'description-rich-text':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={ViewDescription}
          />
        )
        break
      case 'color-picker':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={ColorPickerField}
          />
        )
        break
      case 'query-logic':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={QueryEditorField}
          />
        )
        break
      case 'full-name-entry':
        control = (
          <MSFESInput
            {...childProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...activeChangeChildProps}
            InputComponent={FullName}
          />
        )
        break
      case 'full-address-nfirs':
        control = (
          <MSFESInput
            {...childProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...activeChangeChildProps}
            InputComponent={FullAddressNFIRS}
          />
        )
        break
      case 'lat-lng':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={LatLng}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
          />
        )
      case 'custom-functionality':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            mode={props.mode}
            InputComponent={CustomFunctionalityFieldType}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
          />
        )
      case 'date-picker':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={DatePicker}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
        break
      case 'date-time-picker':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={DateTimePicker}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
        break
      case 'time-picker':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={TimePicker}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
        break
      case 'yes-no':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={YesNo}
            renderStaticHeader={renderStaticHeader}
            value={currentPropertyValue}
          />
        )
        break
      case 'checkbox-multiselect-adhoc':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={CheckboxMultiselectAdhoc}
            value={currentPropertyValue}
          />
        )

        break
      case 'select-list-multiple':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectListMultiple}
            value={currentPropertyValue}
          />
        )
      case 'checkbox-multiselect':
        control = (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={CheckboxMultiselect}
            value={currentPropertyValue}
          />
        )

        break
      case 'approval-required':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={ApprovalRequired}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
      case 'signature-pad':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SignaturePadContainer}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
      case 'single-file':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SingleFile}
            value={currentPropertyValue}
            {...rightIconProps}
          />
        )
      case 'boolean':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={WrappedSwitch}
            onValueChange={booleanOnPressHandler}
            value={localValue}
          />
        )

      case 'number-slider':
        control = skipStaticRendering ? null : (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
            InputComponent={NumberSlider}
          />
        )
        break
      case 'number-counter':
        control = skipStaticRendering ? null : (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
            InputComponent={NumberCounter}
          />
        )
        break
      case 'star-rating':
        control = skipStaticRendering ? null : (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            inputStyle={{ height: fieldHeight, ...InputTextStyle }}
            {...rightIconProps}
            InputComponent={StarRating}
          />
        )
        break
      case 'widget-entity':
        return (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={WidgetEntityField}
          />
        )

      case 'heading':
        return (
          <MSFESHeading {...childProps}>
            {(
              childProps.data.field.field_data.params.headingText ?? ''
            ).toUpperCase()}
          </MSFESHeading>
        )
    }

    return control
  }, [
    fieldRenderType,
    childProps,
    blurChangeChildProps,
    activeChangeChildProps,
    currentPropertyValue,
    displayIcon,
    skipStaticRendering
  ])

  return (
    <>
      <View
        style={[
          style,
          skipStaticRendering ? { maxHeight: 0 } : undefined,
          shared.debugOff
        ]}
      >
        {control}

        {fieldRenderType === 'rich-text-area' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={RichTextContainer}
          />
        )}
        {fieldRenderType === 'select-personnel' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectPersonnel}
            value={currentPropertyValue}
          />
        )}
        {fieldRenderType === 'select-role' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectRole}
          />
        )}
        {fieldRenderType === 'select-roles' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={MultipleRoleSelector}
          />
        )}
        {fieldRenderType === 'select-admin-personnel' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectAdminPersonnel}
          />
        )}

        {fieldRenderType === 'select-tenant' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectTenant}
          />
        )}
        {fieldRenderType === 'select-tenant-multiple' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectTenantMultiple}
          />
        )}
        {fieldRenderType === 'icon-picker' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectIcon}
            value={currentPropertyValue}
          />
        )}
        {fieldRenderType === 'select-list' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectList}
            value={currentPropertyValue}
          />
        )}
        {fieldRenderType === 'select-entity-type' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectEntityType}
            value={currentPropertyValue}
          />
        )}

        {fieldRenderType === 'view-list' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={ViewList}
          />
        )}
        {fieldRenderType === 'view-list-inline' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={ViewListInline}
          />
        )}
        {fieldRenderType === 'select-field' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectField}
          />
        )}
        {fieldRenderType === 'select-state' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectState}
          />
        )}

        {fieldRenderType === 'radio-list' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={RadioList}
            value={currentPropertyValue}
          />
        )}

        {fieldRenderType === 'checkbox-quiz' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={CheckboxMultiselectQuiz}
            value={currentPropertyValue}
          />
        )}

        {fieldRenderType === 'select-adhoc' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={SelectAdhoc}
            value={currentPropertyValue}
          />
        )}

        {fieldRenderType == 'true-false-quiz' && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={TrueFalseQuiz}
            value={currentPropertyValue}
          />
        )}

        {['radio-quiz'].includes(fieldRenderType) && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={RadioQuiz}
            value={currentPropertyValue}
          />
        )}

        {['radio-adhoc'].includes(fieldRenderType) && (
          <MSFESInput
            {...childProps}
            {...activeChangeChildProps}
            InputComponent={RadioAdhoc}
            value={currentPropertyValue}
          />
        )}

        {fieldRenderType === 'container-group' && (
          <GroupContainer {...childProps} {...containerProps} />
        )}
        {fieldRenderType === 'container-row' && (
          <RowContainer {...childProps} {...containerProps} />
        )}

        {fieldRenderType === 'quiz-question-group' && (
          <>
            <QuizQuestionGroupContainer
              fieldVisibilityRules={fieldVisibilityRules}
              {...childProps}
              {...containerProps}
              // give quiz a handler
              onChange={wrappedChangeHandlerDebounced}
            />
          </>
        )}

        {fieldRenderType === 'container-tile' && (
          <>
            <TileContainer
              fieldVisibilityRules={fieldVisibilityRules}
              {...childProps}
              {...containerProps}
            />
          </>
        )}

        {fieldRenderType === 'matrix-field' && (
          <>
            <MatrixContainer
              fieldVisibilityRules={fieldVisibilityRules}
              {...childProps}
              {...containerProps}
            />
          </>
        )}

        {fieldRenderType === 'container-button' && (
          <>
            <ButtonContainer
              fieldVisibilityRules={fieldVisibilityRules}
              {...childProps}
              {...containerProps}
            />
          </>
        )}
        {fieldRenderType === 'hidden' && (
          <>
            <DebugText>
              {childProps.label} {currentPropertyValue}
            </DebugText>
          </>
        )}
      </View>
    </>
  )
}

// export const DebugEntityField = React.forwardRef((props, ref) => {
//   const { value, defaultValue, changeHandler, editable, definition } = props; //child props

//   const changeHandlerDebounced = useCallback(_.debounce(changeHandler, 80), [])

//   const field_data = _.get(props, "data.field.field_data", false)

//   console.log(props)

//   return <View style={{ flex: 1, backgroundColor: "red" }}>
//     <Text>Value {JSON.stringify(value)}</Text>
//     <Text>Editable {JSON.stringify(editable)}</Text>
//     <Text>v {JSON.stringify({ first, middle, last })}</Text>
//     <Text style={{ maxWidth: 400 }}>{JSON.stringify(field_data)}</Text>

//     <View style={{ flexDirection: "row", flex: 1, backgroundColor: "white" }}>

//     </View>
//   </View>
// })

const _WrappedSwitch = (props, ref) => {
  const { value, onValueChange, style, editable } = props

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  return skipStaticRendering ? null : (
    <View
      style={[
        style,
        {
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'flex-end',
          minHeight: 36,
          alignContent: 'center',
          paddingRight: spacing.m1
        }
      ]}
    >
      <View style={{ justifyContent: 'center' }}>
        <Switch
          disabled={!editable}
          onValueChange={onValueChange}
          value={value}
          activeThumbColor={colors.brightOrange}
          activeTrackColor={colors.lightestGray}
          trackColor={{ false: colors.lightGray, true: colors.lightGray }}
          thumbColor={value ? colors.brightOrange : colors.labelGray}
        />
      </View>
    </View>
  )
}
export const WrappedSwitch = React.forwardRef(_WrappedSwitch)
const _WrappedNumber = (props, ref) => {
  const { value, onValueChange, style } = props

  const onValueChangeNumber = (value) => {
    onValueChange(value)
  }

  const displayValue = value === false ? '' : value

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  return skipStaticRendering ? null : (
    <View style={style}>
      <MSFESInput
        keyboardType="numeric"
        onChangeText={onValueChangeNumber}
        value={displayValue}
      />
    </View>
  )
}
export const WrappedNumber = React.forwardRef(_WrappedNumber)

export default withTheme(EntityField)
