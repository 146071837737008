import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { organizeAgendaItems } from './CalendarEntityList'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { CalendarDayTile } from './CalendarDayTile'
import colors from '../../styles/colors'

export const fontSize = 11
export const boxPadding = 2

export const getAgendaItems = (date, calendarsVisible) => {
  const { byDate, entityTypes } = useSelector((appState) => {
    return {
      byDate: appState.calendar.byDate,
      entityTypes: appState.entityTypes?.dataLite?.data ?? []
    }
  })
  const { agendaItems } = useMemo(() => {
    const dateObject = byDate[date]

    if (dateObject) {
      const items = organizeAgendaItems(
        dateObject?.entities,
        dateObject?.virtualEvents,
        date,
        entityTypes,
        calendarsVisible
      )

      return { agendaItems: items }
    }
    return { agendaItems: [] }
  }, [date, byDate, entityTypes, calendarsVisible])

  return { agendaItems }
}

export const CalendarDay = ({
  state,
  date,
  calendarsVisible,
  setViewingEntity,
  setViewPopout,
  itemsToShow = 5
}) => {
  const { agendaItems } = getAgendaItems(date, calendarsVisible)

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        minHeight: 90,
        backgroundColor: colors.white,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: colors.lightestGray,
        borderLeftColor: colors.lighterGray,
        borderRightColor: colors.lighterGray,
        borderLeftWidth: StyleSheet.hairlineWidth,
        borderRightWidth: StyleSheet.hairlineWidth
      }}
    >
      <Text
        style={{
          color: state === 'disabled' ? colors.mediumGray : colors.darkGray,
          textAlign: 'center'
        }}
      >
        {date.split('-')[2]}
      </Text>
      {agendaItems.map((agendaItem, index) => {
        if (index === itemsToShow && agendaItems.length > itemsToShow + 1) {
          return (
            <CalendarDayShowMoreTile
              key={index}
              moreCount={agendaItems.length - itemsToShow}
              onPress={() => setViewPopout({ date, agendaItems })}
            />
          )
        } else if (index > itemsToShow) {
          return null
        } else {
          return (
            <CalendarDayTile
              isLast={index === agendaItems.length - 1}
              key={index}
              onPress={() => setViewingEntity(agendaItem.entity)}
              agendaItem={agendaItem}
            />
          )
        }
      })}
    </View>
  )
}

export const CalendarDayShowMoreTile = ({ onPress, moreCount }) => {
  const calendarDayShowMoreTileStyle = {}
  return (
    <TouchableOpacity onPress={onPress} style={calendarDayShowMoreTileStyle}>
      <Text
        style={{
          fontSize,
          fontWeight: 'bold',
          padding: boxPadding,
          marginLeft: StyleSheet.hairlineWidth
        }}
      >
        View {moreCount} more
      </Text>
    </TouchableOpacity>
  )
}
