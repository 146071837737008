import React from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'
import { iconShadow } from './ModuleButton'
import useBetterLinkTo from '../useBetterLinkTo'
import { Icon } from 'react-native-elements'
import colors from '../styles/colors'

export const IsGenericEntityIdentifier = () => {
  return (
    <View style={{ paddingBottom: 4 }}>
      <Icon
        type="font-awesome"
        name="users"
        size={12}
        color={colors.blueDarkest}
        alt="Global Entity"
      />
    </View>
  )
}

export const IsDeletedEntityIdentifier = () => {
  return (
    <View style={{ paddingBottom: 4 }}>
      <Icon
        type="font-awesome"
        name="remove"
        size={12}
        color={colors.redBase}
        alt="Deleted Entity"
      />
    </View>
  )
}
export const IsVirtualEntityIdentifier = () => {
  return (
    <View style={{ paddingBottom: 4 }}>
      <Icon
        type="font-awesome-5"
        name="ghost"
        size={12}
        color={colors.blueDarkest}
        alt="Virtual Entity"
      />
    </View>
  )
}

export const GridItemButton = (props) => {
  const {
    image,
    alarmColor = null,
    linkTo = null,
    title,
    subtitle = null,
    isGeneric = false,
    isVirtual = false,
    isDeleted = false,
    onPress = null
  } = props

  const openLinkTo = useBetterLinkTo()
  const overloadedOnPress = onPress
    ? onPress
    : () => {
        linkTo && openLinkTo(linkTo)
      }

  return (
    <View style={styles.outerContainer}>
      <View
        style={[
          styles.innerContainer,
          alarmColor && {
            shadowColor: alarmColor,
            shadowRadius: 10,
            shadowOpacity: 0.5
          }
        ]}
      >
        <View style={styles.identifiers}>
          {isGeneric && <IsGenericEntityIdentifier />}
          {isVirtual && <IsVirtualEntityIdentifier />}
          {isDeleted && <IsDeletedEntityIdentifier />}
        </View>

        <TouchableOpacity
          style={{ flexDirection: 'column', flex: 1 }}
          onPress={overloadedOnPress}
        >
          <View style={styles.imageContainer}>{image}</View>
          <View style={styles.labelContainer}>
            <Text style={fonts.gridItemTextStyle} numberOfLines={4}>
              {title}
            </Text>
            {!!subtitle && (
              <Text
                style={[
                  {
                    fontSize: fonts.tinyLabelSize,
                    textAlign: 'center',
                    paddingVertical: 0,
                    paddingHorizontal: spacing.m0_5,
                    paddingBottom: spacing.m2
                  }
                ]}
              >
                {subtitle}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  outerContainer: {
    margin: spacing.m1,
    minHeight: 120,
    flexDirection: 'column'
  },
  identifiers: {
    position: 'absolute',
    right: 8,
    top: 8,
    height: 16
  },
  innerContainer: {
    flexDirection: 'column',
    flex: 1,
    ...iconShadow
  },
  imageContainer: {
    minHeight: 48,
    paddingTop: spacing.m1,
    alignContent: 'center',
    justifyContent: 'center',
    minWidth: 48
  },
  labelContainer: { flex: 1, minHeight: 32, justifyContent: 'center' },
  settingsContainer: {
    minWidth: 40,
    minHeight: 26,
    flexDirection: 'column'
  },
  settingsButtonStyle: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center'
  }
})
