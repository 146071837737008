import React, { useCallback, useEffect, useMemo, useState } from 'react'
import WithHeader from '../components/WithHeader'
import { View, FlatList } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { listLiteEntityTypes } from '../store/entity-type/actions'

import { ListItemLinkButton } from '../components/ListItemLinkButton'

import { getAddUrl } from '../store/entity/actions'
import CurrentlyNoItems from '../components/CurrentlyNoItems'
import { currentlyNoTemplateDefaults } from './CategoryViewScreen'
import spacing from '../styles/spacing'
import { sortOptions } from './SettingsManageEntityTypesScreen'

export default function TaskAddSelectTypeScreen({ navigation }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(listLiteEntityTypes())
  }, [])

  const [activeSort, setActiveSort] = useState(0)
  const [searchText, setSearchText] = useState('')
  const activeSortObject = sortOptions[activeSort]

  const onSortChanged = (newIndex) => {
    setActiveSort(newIndex)
  }

  const pageTitle = 'Select Task Type'

  const entityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  const listEntities = entityTypes.filter((entityType) => entityType.is_task)

  const currentlyNoLabel = currentlyNoTemplateDefaults({ label: 'task types' })

  const onSearchTextChanged = (_searchText) => {
    setSearchText(_searchText)
  }

  const sortedEntityTypes = useMemo(() => {
    let sorted
    switch (activeSortObject.name) {
      case 'a_to_z':
        sorted = _.sortBy(listEntities, (e) => e.label)
        break
      case 'default':
        sorted = _.orderBy(listEntities, ['sort_order'], ['asc'])
        break
      case 'z_to_a':
        sorted = _.orderBy(listEntities, ['label'], ['desc'])
        break
    }
    if (searchText) {
      sorted = sorted.filter(
        (s) => s.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      )
    }

    return sorted
  }, [listEntities, activeSortObject, searchText])

  const _getAddUrl = useCallback((entityType) => {
    return getAddUrl(entityType, null, '/tasks/add')
  }, [])

  return (
    <WithHeader
      onSearchTextChanged={onSearchTextChanged}
      onSortChanged={onSortChanged}
      sortOptions={sortOptions}
      activeSort={activeSort}
      title={pageTitle}
      navigation={navigation}
      leftButtonType={'back'}
    >
      <View
        style={[{ flex: 1, marginBottom: spacing.viewboxNavbarPadding }]}
        key="view-module-wrapper"
      >
        <FlatList
          data={sortedEntityTypes}
          renderItem={({ item: entityType }) => {
            return (
              <ListItemLinkButton
                title={entityType.label}
                avatarIcon={{
                  name: _.get(entityType, 'object_data.icon', null),
                  type: 'font-awesome'
                }}
                to={_getAddUrl(entityType)}
              />
            )
          }}
          keyExtractor={(item) => item.id.toString()}
          contentContainerStyle={{ paddingBottom: 30 }}
          ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
        />
      </View>
    </WithHeader>
  )
}
