// libraries
import React, { useState } from 'react'
import { ScrollView, View } from 'react-native'
import _ from 'lodash'
import MSFESInput from './MSFESInput'
import { SelectList } from './fields/SelectList'
import spacing from '../styles/spacing'
import { ListItem, Overlay } from 'react-native-elements'
import ListItemAsFieldProps from './ListItemAsFieldProps'
import MSFESButton from './MSFESButton'
import { PortalWrapper } from './fields/DatePicker'

export const FullAddressNFIRS = React.forwardRef((props, ref) => {
  const { defaultValue, changeHandler, editable } = props //child props

  const [localState, setLocalState] = useState({
    locationType: _.get(defaultValue, 'locationType', ''),
    milepostNumber: _.get(defaultValue, 'milepostNumber', ''),
    addressPrefix: _.get(defaultValue, 'addressPrefix', ''),
    street: _.get(defaultValue, 'street', ''),
    streetType: _.get(defaultValue, 'streetType', ''),
    addressSuffix: _.get(defaultValue, 'addressSuffix', ''),
    aptSuiteRoomNum: _.get(defaultValue, 'aptSuiteRoomNum', ''),
    city: _.get(defaultValue, 'city', ''),
    state: _.get(defaultValue, 'state', ''),
    zipcode: _.get(defaultValue, 'zipcode', ''),
    crossStreet: _.get(defaultValue, 'crossStreet', ''),
    display_name: _.get(defaultValue, 'display_name', '')
  })

  const [visible, setVisible] = useState(false)

  const localChangeHandler = (prop, value) => {
    setLocalState((current) => {
      const newLocalState = {
        ...current,
        [prop]: value
      }
      const milepost = newLocalState.milepostNumber
        ? `MilePost: ${newLocalState.milepostNumber},`
        : ''
      const addressPrefix = newLocalState.addressPrefix?.display_name || ''
      const street = newLocalState.street || ''
      const streetType = newLocalState.streetType?.display_name || ''
      const addressSuffix = newLocalState.addressSuffix?.display_name || ''
      const aptSuiteRoomNum = newLocalState.aptSuiteRoomNum
        ? `${newLocalState.aptSuiteRoomNum}`
        : ''
      const city = newLocalState.city ? `, ${newLocalState.city}` : ''
      const state = newLocalState.state?.display_name || ''
      const zipcode = newLocalState.zipcode ? `, ${newLocalState.zipcode}` : ''
      const crossStreet = newLocalState.crossStreet
        ? `Cross Street: ${newLocalState.crossStreet?.display_name}`
        : ''

      newLocalState.display_name = `${milepost} ${addressPrefix} ${street} ${streetType} ${addressSuffix} \n${aptSuiteRoomNum}${city} ${state}${zipcode}\n${crossStreet}`.trim()

      return newLocalState
    })
  }

  const onConfirmChanges = () => {
    changeHandler(localState) //bubble up.
  }

  const onPressAction = () => {
    setVisible(true)
  }

  const inputBoxStyling = [{ marginBottom: spacing.m1 }, props.style]

  const outerViewStyle = {
    paddingVertical: 8,
    ...props.style,
    height: null
  }
  const listItemContainerStyle = {
    ...ListItemAsFieldProps.containerStyle,
    height: null
  }

  return (
    <View style={outerViewStyle}>
      <ListItem
        onPress={onPressAction}
        disabled={!editable}
        containerStyle={listItemContainerStyle}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title>
            {localState.display_name
              ? localState.display_name
              : '(None entered)'}
          </ListItem.Title>
        </ListItem.Content>
        {visible && (
          <PortalWrapper>
            <Overlay onBackdropPress={() => setVisible(false)} isVisible={true}>
              <ScrollView
                style={{
                  flexDirection: 'column',
                  maxHeight: 400,
                  minWidth: 300
                }}
              >
                <SelectList
                  style={inputBoxStyling}
                  placeholder="Location Type"
                  value={localState.locationType}
                  keyField={'id'}
                  onChangeText={(value) =>
                    localChangeHandler('locationType', value)
                  }
                  data={{
                    entity: props.data?.entity,
                    field: {
                      field_data: {
                        params: {
                          ...props.data?.field.field_data.params,
                          ...{ sourceName: 'location_type_code' }
                        }
                      }
                    }
                  }}
                />
                <MSFESInput
                  showLabel={false}
                  value={localState.milepostNumber}
                  placeholder="Milepost Number"
                  onChangeText={(value) =>
                    localChangeHandler('milepostNumber', value)
                  }
                />
                <SelectList
                  style={inputBoxStyling}
                  placeholder="Street Prefix"
                  value={localState.addressPrefix}
                  keyField={'id'}
                  onChangeText={(value) =>
                    localChangeHandler('addressPrefix', value)
                  }
                  data={{
                    entity: props.data?.entity,
                    field: {
                      field_data: {
                        params: {
                          ...props.data?.field.field_data.params,
                          ...{ sourceName: 'street_prefix' }
                        }
                      }
                    }
                  }}
                />
                <MSFESInput
                  supportsInputShading={true}
                  placeholder="Street"
                  showLabel={false}
                  value={localState.street}
                  onChangeText={(value) => localChangeHandler('street', value)}
                />
                <SelectList
                  style={inputBoxStyling}
                  placeholder="Street Type"
                  value={localState.streetType}
                  onChangeText={(value) =>
                    localChangeHandler('streetType', value)
                  }
                  keyField={'id'}
                  data={{
                    entity: props.data?.entity,
                    field: {
                      field_data: {
                        params: {
                          ...props.data?.field.field_data.params,
                          ...{ sourceName: 'street_type' }
                        }
                      }
                    }
                  }}
                />

                <SelectList
                  style={inputBoxStyling}
                  placeholder="Street Suffix"
                  value={localState.addressSuffix}
                  keyField={'id'}
                  onChangeText={(value) =>
                    localChangeHandler('addressSuffix', value)
                  }
                  data={{
                    entity: props.data?.entity,
                    field: {
                      field_data: {
                        params: {
                          ...props.data?.field.field_data.params,
                          ...{ sourceName: 'street_suffix' }
                        }
                      }
                    }
                  }}
                />
                <MSFESInput
                  showLabel={false}
                  supportsInputShading={true}
                  value={localState.aptSuiteRoomNum}
                  placeholder="Apt/Suite/Room Number"
                  onChangeText={(value) =>
                    localChangeHandler('aptSuiteRoomNum', value)
                  }
                />
                <MSFESInput
                  showLabel={false}
                  supportsInputShading={true}
                  value={localState.city}
                  placeholder="City"
                  disabled={!editable}
                  onChangeText={(value) => localChangeHandler('city', value)}
                />
                <SelectList
                  style={inputBoxStyling}
                  placeholder="State"
                  value={localState.state}
                  keyField={'id'}
                  onChangeText={(value) => localChangeHandler('state', value)}
                  data={{
                    entity: props.data?.entity,
                    field: {
                      field_data: {
                        params: {
                          ...props.data?.field.field_data.params,
                          ...{ sourceName: 'state' }
                        }
                      }
                    }
                  }}
                />
                <MSFESInput
                  showLabel={false}
                  supportsInputShading={true}
                  value={localState.zipcode}
                  placeholder="Zip Code"
                  onChangeText={(value) => localChangeHandler('zipcode', value)}
                />
                <MSFESInput
                  placeholder="Cross Street"
                  value={localState.crossStreet}
                  showLabel={false}
                  supportsInputShading={true}
                  onChangeText={(value) =>
                    localChangeHandler('crossStreet', value)
                  }
                />

                <MSFESButton
                  style={{ marginTop: 8, justifyContent: 'flex-end' }}
                  onPress={onConfirmChanges}
                  title={'Confirm'}
                ></MSFESButton>
              </ScrollView>
            </Overlay>
          </PortalWrapper>
        )}
        {editable && (
          <ListItem.Chevron
            type="font-awesome"
            name="chevron-down"
            size={14}
            color={'black'}
          />
        )}
      </ListItem>
    </View>
  )
})
