import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { Divider } from 'react-native-paper'
import spacing from '../styles/spacing'
import { ViewDescription } from './fields/ViewDescription'
import MSFESButton from './MSFESButton'
import MSFESHeading from './MSFESHeading'

const EntityEditQuizIntroBlock = ({
  definition,
  onBeginQuiz,
  disabled,
  field
}) => {
  const descriptionData = useMemo(() => {
    return {
      field: {
        field_data: {
          params: {
            displayInline: true,
            descriptionText: field.field_data?.params.quizIntroduction
          }
        }
      }
    }
  }, [definition])

  const onBeginQuizPressed = () => {
    onBeginQuiz()
  }
  const attemptsText = useMemo(() => {
    switch (field.field_data?.params?.quizAttemptsOption) {
      case 'one-attempt':
        return 'Attempts: One (1)'
        break
      case 'unlimited-attempts':
        return 'Attempts: Unlimited Attempts'
        break
      case 'multiple-attempts':
        return `Attempts: ${field.field_data?.params?.quizMaximumAttempts} Attempts`
        break
    }
  })
  return (
    <View>
      <MSFESHeading>Introduction</MSFESHeading>
      <ViewDescription data={descriptionData} />
      <Divider style={{ marginVertical: spacing.m2 }} />
      {!!field.field_data?.params?.quizAttemptsOption && (
        <Text>{attemptsText}</Text>
      )}
      {!!field.field_data?.params?.quizTimeLimit && (
        <Text>
          Time to complete: {field.field_data?.params?.quizTimeLimit} minutes
        </Text>
      )}
      {!!field.field_data?.params?.quiz_pass_mark_percent && (
        <Text>
          Pass Mark: {field.field_data?.params?.quiz_pass_mark_percent}%
        </Text>
      )}

      <Divider style={{ marginVertical: spacing.m2 }} />

      <MSFESButton
        small
        title="Begin Quiz"
        containerStyle={{ padding: 0, flex: 1 }}
        disabled={disabled}
        onPress={onBeginQuizPressed}
      />
    </View>
  )
}

export default EntityEditQuizIntroBlock
