import _ from 'lodash'
import React, { useMemo, memo } from 'react'
import { View, Text } from 'react-native'
import spacing from '../../styles/spacing'
import { RadioAdhoc } from './RadioAdhoc'
import { ViewDescription } from './ViewDescription'

export const quizDescriptionStyle = {
  paddingBottom: spacing.m1,
  paddingLeft: spacing.m2,
  paddingTop: spacing.m0_5
}
export const RadioQuiz = memo(
  React.forwardRef((props, ref) => {
    const { showDescription } = props
    const descriptionData = useMemo(() => {
      return {
        field: {
          field_data: {
            params: {
              displayInline: true,
              descriptionText:
                props.data?.field?.field_data?.params?.descriptionText
            }
          }
        }
      }
    }, [props.data?.field?.field_data])

    return (
      <View style={{ flex: 1 }}>
        {showDescription && (
          <ViewDescription
            style={quizDescriptionStyle}
            data={descriptionData}
          />
        )}
        <RadioAdhoc {...props} />
      </View>
    )
  })
)

RadioQuiz.defaultProps = {
  showDescription: true
}
