import api from '../../data/api'

export const resetCalendarState = () => {
  return (dispatch) => {
    dispatch({
      type: 'calendar/reset-state'
    })
  }
}
export const saveCalendarEvent = (event) => {
  return () => {
    let route = '/calendar/events'

    if (event.id) {
      route += `/${event.id}`
    }

    const method = event.id ? 'put' : 'post'
    return api[method](route, event)
  }
}

export const deleteCalendarEvent = (eventId) => {
  return () => {
    let route = `/calendar/events/${eventId}`

    const method = 'delete'
    const p = api[method](route).then((response) => response.json())

    return p
  }
}

export const fetchCalendarEventsMonth = (monthYear) => {
  return (dispatch) => {
    const route = `/calendar/events-month/${monthYear.month}.${monthYear.year}`

    const p = api.get(route).then((response) => {
      dispatch({
        type: 'calendar/list-month-success',
        response,
        monthYear
      })
      return response
    })

    return p
  }
}

export const listCalendarEventDefinitions = () => {
  return (dispatch) => {
    const route = `/calendar/events/definitions`

    const p = api.get(route).then((response) => {
      dispatch({
        type: 'calendar/definition-list-success',
        response
      })
      return response
    })

    return p
  }
}

export const listInstancesOfEventsUpcoming = (recurringEventIds) => {
  return (dispatch) => {
    const route = `/calendar/events/upcoming-for/` + recurringEventIds.join()

    const p = api.get(route).then((response) => {
      dispatch({
        type: 'calendar/events-upcoming-list-success',
        response
      })
      return response
    })

    return p
  }
}

export const fetchCalendarEvents = (date) => {
  return (dispatch) => {
    const route = `/calendar/events/${date}`

    const p = api.get(route).then((response) => {
      dispatch({
        type: 'calendar/list-success',
        response,
        date
      })
      return response
    })

    return p
  }
}
