import _ from "lodash"
import React, { useMemo } from "react"
import { View, Text } from 'react-native'
import { RadioAdhoc } from "./RadioAdhoc"
import { RadioQuiz } from "./RadioQuiz"
import { ViewDescription } from "./ViewDescription"


export const TrueFalseQuiz = React.forwardRef((props, ref) => {



  const newProps = useMemo(() => {
    let newProps = {...props};
    

  const radioOptions = [
    {
      key: false,
      label: "False",
      offline_id: "4bfd0bc6-6bcb-4c3c-900c-1ea0d148e77b"
    },
    {
      key: true,
      label: "True",
      offline_id: "a39f9443-398c-4564-abeb-c3e626da6da4"
    },

  ]

  newProps = _.set(newProps, "data.field.field_data.params.options", radioOptions)
    return newProps;
    
  }, [props])
  
  return (
    <View style={{ flex: 1 }}>
      <RadioQuiz
        {...newProps}
      />
    </View>

  )
})