// libraries
import React, { useEffect, useState } from 'react'
import { RefreshControl } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
// styles

//actions
import entityActions from '../store/entity/actions'
import entityTypeActions, {
  listLiteEntityTypes
} from '../store/entity-type/actions'

// components
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import WithHeader from '../components/WithHeader'
import DraggableFlatList from 'react-native-draggable-flatlist'
import { getResortedData } from './SettingsManageEntityTypesScreen'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
export default function SettingsAdminModules({ route, navigation }) {
  const dispatch = useDispatch()

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const updateSorts = (data, from, to) => {
    data = getResortedData(data, from, to)
    setEntityTypes(data)
    dispatch(entityTypeActions.updateEntityTypeBatch(data))
  }

  const adminEntityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.dataLite.data', [])
  )

  useEffect(() => {
    const filtered = adminEntityTypes.filter((entityType) => {
      return (
        _.get(
          current_user,
          `effective_permissions['view|id:${entityType.id}|name:${entityType.name}']`,
          false
        ) && _.get(entityType, 'object_data.is_global_admin_only', false)
      )
    })
    setEntityTypes(filtered)
  }, [adminEntityTypes])

  const [entityTypes, setEntityTypes] = useState([])

  const [refreshing, setRefreshing] = React.useState(false)
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    dispatch(listLiteEntityTypes()).then(() => setRefreshing(false))
    // TODO: Reload stubs here.
    // dispatch(entityTypeActions.listEntityTypes()).then(() => {
    //   setRefreshing(false)
    // })
  }, [])

  // if we're on a subroute, we need to work that out.

  const { entityType: tool } = useDiscoverEntityEntityType(route, null)
  const pageTitle = 'Admin Modules'

  const sortedEntityTypes = _.sortBy(entityTypes, (e) => e.label)

  useEffect(() => {
    if (tool) {
      dispatch(entityActions.listEntity(tool))
    }
  }, [])

  return (
    <WithHeader
      title={pageTitle}
      navigation={navigation}
      leftButtonType={'back'}
    >
      {!tool && (
        <DraggableFlatList
          style={{ marginBottom: 70 }}
          keyExtractor={(item) => item.id.toString()}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          onDragEnd={({ data, from, to }) => updateSorts(data, from, to)}
          data={sortedEntityTypes}
          renderItem={({ item, drag }) => (
            <ListItemLinkButton
              title={item.label}
              onLongPress={drag}
              to={
                '/admin/entity/?type=' +
                item.name +
                '?from=' +
                encodeURIComponent(
                  '/settings/admin-tools/manage-entity-types/index'
                )
              }
              avatarIcon={{
                name: _.get(item, 'object_data.icon', null),
                type: 'font-awesome'
              }}
            />
          )}
        />
      )}
    </WithHeader>
  )
}
