import React, { useEffect, useState } from 'react'
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder'

import _ from 'lodash'

// For AntDesign widgets only:
import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig // or MaterialConfig or BasicConfig

import 'antd/dist/antd.css'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css' //optional, for more compact styles

// You need to provide your own config. See below 'Config format'

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: 'group' }

const resolveQueryBuilderTypeFromOurType = (field) => {
  const type = _.get(field.field_data, 'type', 'single-text')
  switch (type) {
    case 'phone':
    case 'hidden':
    case 'single-text':
    case 'single-text-mask':
    case 'email':
      return { type: 'text' }
    case 'boolean':
      return { type: 'boolean' }
    case 'select-list':
      return { type: 'text' }
    default:
      return { type }
  }
}
const getConfig = (entityType) => {
  const fields = {}
  entityType.fields.forEach((f) => {
    fields[f.field_data.property] = {
      label: f.field_data.title,
      ...resolveQueryBuilderTypeFromOurType(f)
    }
  })
  const builtConfig = {
    ...InitialConfig,
    fields
  }

  // console.log(builtConfig, config);
  return builtConfig
}
const DemoQueryBuilder = (props) => {
  const [, setEntityType] = useState(null)

  const [state, setState] = useState(null)

  useEffect(() => {
    if (props.entityType) {
      setEntityType(props.entityType)

      const config = getConfig(props.entityType)

      setState((oldState) => {
        return {
          ...oldState,
          tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
          config
        }
      })
    }
  }, [props])

  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  )

  const renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div>
        Query string:{' '}
        <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre>
      </div>
      <div>
        MongoDb query:{' '}
        <pre>
          {JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}
        </pre>
      </div>
      <div>
        SQL where:{' '}
        <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
      </div>
      <div>
        JsonLogic:{' '}
        <pre>
          {JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}
        </pre>
      </div>
    </div>
  )

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ tree: immutableTree, config: config })

    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }

  return state?.config ? (
    <div>
      <Query
        {...state.config}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      {renderResult(state)}
    </div>
  ) : null
}

export default DemoQueryBuilder
