import _ from 'lodash'
const defaultState = {
  byEntity: {},
  errors: {
    errors: null,
    hasError: false
  }
}

export default function entities(state = defaultState, action) {
  switch (action.type) {
    
    case 'version/list-success':
      {
        const { response, entity, entityDefinition, filterString } = action

        const byEntity = {...state.byEntity}

        byEntity[entity.id] = response.data
        state = {
          ...state,
          byEntity
        }
      }

      break
  }

  return state
}
