import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import colors from '../styles/colors';

import _ from 'lodash'
import spacing from '../styles/spacing';
import { Divider } from 'react-native-elements/dist/divider/Divider';
import shared from '../styles/shared';

export default function TextLabel(props) {

  const { fontSize = 16, children, style = null, value, textStyle = null } = props;

  return <View style={[{...style, justifyContent:"center"}, shared.debugOff]}>
    <Text style={[{color: colors.headingText, fontWeight:"bold", fontSize}, textStyle]}>{value}</Text>
    </View>

    
}