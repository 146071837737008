import React from 'react'
import { View } from 'react-native'
import { useGetWidgetRenderBuckets } from '../../hooks/useGetWidgetRenderBuckets'
import { WidgetRenderBucket } from '../../components/WidgetRenderBucket'
export const WidgetBar = ({ entityType }) => {
  const { renderBuckets } = useGetWidgetRenderBuckets(
    'view_entity_list',
    entityType?.id
  )

  return (
    !!renderBuckets?.length && (
      <View>
        {renderBuckets.map((renderBucket, bucketIndex) => {
          return <WidgetRenderBucket bucket={renderBucket} key={bucketIndex} />
        })}
      </View>
    )
  )
}
