// libraries
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { FlatList } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

//actions
import conversationActions from '../store/conversation/actions'
import entityActions, { getFilterString } from '../store/entity/actions'

// internal libraries
import { getDisplayLabelForEntity } from '../libraries/entityTools'

// components
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import { ScrollView } from 'react-native'
import { useLinkTo } from '@react-navigation/native'
import WithHeader from '../components/WithHeader'
import { showMessage } from 'react-native-flash-message'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'

const ConversationStarterRow = ({ personnel }) => {
  const dispatch = useDispatch()
  const linkTo = useLinkTo()

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  return (
    <ListItemLinkButton
      title={getDisplayLabelForEntity(personnel)}
      key={personnel.id}
      onPress={() => {
        dispatch(
          conversationActions.createConversation({
            participants: [{ id: personnel.user }, { id: current_user.id }]
          })
        )
          .then((conversation) => {
            linkTo('/conversations/' + conversation.id)
          })
          .catch((e) => {
            showMessage({ message: e.message, type: 'danger' })
          })
        // start a conversation with personnel.id
      }}
    />
  )
}

export default function ConversationCreateScreen({ navigation }) {
  const scrollviewRef = useRef(null)

  const dispatch = useDispatch()

  const pageTitle = 'Start Conversation with'

  useEffect(() => {
    dispatch(entityActions.listEntity({ name: 'personnel' }, { filter: null }))
  }, [])

  const sourceListPersonnelData = useSelector((state) =>
    _.get(
      state.entities,
      `[${'personnel' + sanitiseFilterString(getFilterString(null))}].data`,
      []
    )
  )

  const renderConversationStarterRowPersonnel = ({ item }) => {
    return <ConversationStarterRow personnel={item} />
  }

  return (
    <WithHeader
      title={pageTitle}
      navigation={navigation}
      leftButtonType="back"
      rightButtonAction={null}
    >
      <ScrollView ref={scrollviewRef}>
        <FlatList
          data={sourceListPersonnelData}
          renderItem={renderConversationStarterRowPersonnel}
          keyExtractor={(item) => item.id}
        />
      </ScrollView>
    </WithHeader>
  )
}
