// external libraries
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'

// internal libraries

// styles

//actions
import userActions from '../store/user/actions'
import appActions from '../store/app/actions'
import transitionActions from '../store/transition/actions'
import {
  listLiteEntityTypes,
  scrubExcessEntityTypes
} from '../store/entity-type/actions'

// components
import DrawerNavigator from '../navigators/DrawerNavigator'
import DesktopDrawerNavigator from '../navigators/DesktopDrawerNavigator'
import SignedOutNavigator from './SignedOutNavigator'
import SplashScreen from 'react-native-splash-screen'
import { Platform, useWindowDimensions } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import colors from '../styles/colors'
import shared from '../styles/shared'
import { shouldShowDesktop } from '../libraries/shouldShowDesktop'
import { useNetInfo } from '@react-native-community/netinfo'
import { StatusBar } from 'expo-status-bar'
import PublicFormContext from '../contexts/PublicFormContext'
import useDeepLink from '../hooks/useDeepLink'
import { listCalendarEventDefinitions } from '../store/calendar/actions'
import { getUrl } from '../data/api'
import GlobalContext from '../contexts/GlobalContext'
import { MFAEnrolmentModal } from '../components/mfa/MFAEnrolmentModal'
import { useTimeFormat } from '../contexts/TimeFormatContext'
import entityActions from '../store/entity/actions'

export default function AuthGateScreen({ navigation }) {
  const {
    environment,
    user,
    access_token,
    mfa_enrol_by,
    mfa_enrolled_at,
    entityTypes
  } = useSelector((appState) => {
    const environment = _.get(appState, 'app.environment', null)
    const { access_token, user } = appState.users
    const { mfa_enrol_by, mfa_enrolled_at } = user || {}
    const entityTypes = appState?.entityTypes?.data
    return {
      environment,
      user,
      access_token,
      mfa_enrol_by,
      mfa_enrolled_at,
      entityTypes
    }
  })

  const dispatch = useDispatch()

  useEffect(() => {
    getUrl() //fires for zoho to catch
  }, [''])

  useEffect(() => {
    if (access_token) {
      dispatch(userActions.fetchUser())
        .then(() => {
          dispatch(transitionActions.listTransitions())
          dispatch(transitionActions.listPlaces())
          // TODO: Load stub entity types only
          dispatch(listLiteEntityTypes())
        })
        .catch((e) => {
          if (e.statusCode === 401) {
            // user is unauthorised. Dump access token to sign in again.
            dispatch(userActions.purgeToken())
          }
        })
    }
  }, ['', access_token])

  const [hasLoadedCalendarDefs, setHasLoadedCalendarDefs] = useState(false)
  useEffect(() => {
    if (entityTypes && !hasLoadedCalendarDefs) {
      dispatch(listCalendarEventDefinitions())
      setHasLoadedCalendarDefs(true)
    }
  }, [entityTypes])

  useEffect(() => {
    if (Platform.OS !== 'web') {
      SplashScreen.hide()
    }

    /* HRFIR-724 - scrub old entity types */
    dispatch(scrubExcessEntityTypes())
    /* end HRFIR-724 */
  }, [])

  const windowDimensions = useWindowDimensions()
  const showDesktop = shouldShowDesktop(windowDimensions)

  const [MFACompleted, setMFACompleted] = useState(false)
  const showMfaWarning =
    user && mfa_enrol_by && !mfa_enrolled_at && !MFACompleted

  const isNative = Platform.OS !== 'web'

  const component = (
    <>
      {showDesktop ? (
        <DesktopDrawerNavigator navigation={navigation} />
      ) : (
        <DrawerNavigator />
      )}
      {showMfaWarning && (
        <MFAEnrolmentModal
          dismissMFA={() => setMFACompleted(true)}
          mfa_enrol_by={mfa_enrol_by}
        />
      )}
    </>
  )

  const navColor =
    environment == 'prod' ? colors.blueDarkest : colors.stagingRed

  const netInfo = useNetInfo()

  useEffect(() => {
    dispatch(appActions.setNetInfo(netInfo))
  }, [netInfo])

  useEffect(() => {
    useDeepLink(async (url) => {
      if (url) {
        if (url.pathname?.indexOf('/public_forms/') !== 0) {
          // this may be a deep linking item. Check path, navigate accordingly.
          if (navigation) {
            const { query, pathname } = url

            switch (pathname) {
              case '/reset-password':
                navigation?.navigate('ResetPasswordScreen', query)
                break
            }
          }
        }
      }
    })
  }, ['', navigation])

  /* When should we use setTimeFormat? ONLY when the tenant record changes. */
  const { setTimeFormat } = useTimeFormat()

  useEffect(() => {
    if (user?.tenant_record) {
      dispatch(
        entityActions.showEntity({
          type: { name: 'tenant' },
          id: user?.tenant_record?.id
        })
      )
    }
  }, [user])
  const currentTenantRecord = useSelector((state) => {
    return _.get(state, 'entities.byId.' + state.users?.user?.tenant_record?.id)
  })

  const time_format = currentTenantRecord
    ? currentTenantRecord.time_format
    : 'TIME_WITH_SECONDS'
  const date_format = currentTenantRecord
    ? currentTenantRecord.date_format
    : 'DATE_SHORT'

  useEffect(() => {
    if (time_format && date_format != null) {
      setTimeFormat({
        time_format: time_format,
        date_format: date_format
      })
    }
  }, [currentTenantRecord])

  return access_token ? (
    <>
      <StatusBar backgroundColor="white" barStyle="light-content" />
      {isNative && (
        <>
          <SafeAreaView
            style={{
              flex: 0,
              backgroundColor: showDesktop ? navColor : colors.background
            }}
          />
          <SafeAreaView
            style={[
              {
                flex: 1,
                backgroundColor: showDesktop ? colors.blueDarkest : navColor
              },
              shared.debugOff
            ]}
          >
            <GlobalContext.Provider value={{ mfa_enrol_by, mfa_enrolled_at }}>
              <PublicFormContext.Provider value={{ public: false }}>
                {component}
              </PublicFormContext.Provider>
            </GlobalContext.Provider>
          </SafeAreaView>
        </>
      )}
      {!isNative && component}
    </>
  ) : (
    <SignedOutNavigator />
  )
}
