import React from 'react'
import { useSelector } from 'react-redux'
import SelectWithData from './SelectWithData'
const SelectEntityType = React.forwardRef((props, ref) => {
  const entityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  const entityTypesWGlobal = entityTypes
    .map((e) => {
      e.labelGlobal = e.label + (e.is_global ? ' 🌐' : '')
      return e
    })
    .filter((e) => {
      if (props.entityTypeFilter?.includes('is_module_parent')) {
        return e.object_data?.is_module_parent
      }
      return true
    })

  return (
    <SelectWithData
      {...props}
      dataRows={entityTypesWGlobal}
      valueField="name"
      labelField="labelGlobal"
      keyField="id"
      value={props.defaultValue}
    />
  )
})

export default SelectEntityType
