import api from '../../data/api'
export const listSensitiveActions = ({ page = 1 }) => {
  return () => {
    const routeUsed = '/sensitive-actions?page=' + page

    return api
      .get(routeUsed)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
