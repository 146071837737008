import React, { useState } from 'react'
import { showMessage } from 'react-native-flash-message'
import { getFreshImportState } from '../components/EntityTypeActions'

import { useDispatch } from 'react-redux'
import MediaHelper from '../libraries/mediaHelper'
import entityActions from '../store/entity/actions'
import entityTypeActions from '../store/entity-type/actions'
import FileImportOverlay from '../components/FileImportOverlay'
import { openExternalLink } from '../helpers/link'
import wrappedAlert from '../helpers/alert'

export const useUploadDataFile = ({ entityType, filters, queryParams }) => {
  const dispatch = useDispatch()
  const [importState, setImportState] = useState(getFreshImportState())
  const [exportLoading, setExportLoading] = useState(false)
  const [nfirsLoading, setNfirsLoading] = useState(false)
  const uploadDataFile = () => {
    MediaHelper.getMedia(MediaHelper.DocumentType, false).then(
      async ({ payload }) => {
        // try{
        const blob = await MediaHelper.getBlobFromDataURL(payload.uri)
        const body = await blob.text()

        const testRun = true
        const bodyAppended = { body, testRun }

        setImportState((importState) => ({
          ...importState,
          performingImport: true,
          results: null,
          dialogOpen: true,
          testRun: true,
          body
        }))

        dispatch(entityActions.uploadDataFile(bodyAppended, entityType))
          .then((response) => {
            const message = 'Data file uploaded successfully.'
            showMessage({ message, type: 'success' })
            setImportState((importState) => ({
              ...importState,
              importId: response.importId
            }))

            const interval = setInterval(() => {
              dispatch(
                entityActions.requestLongRunningJobUpdate(response.importId)
              )
                .then((response) => {
                  setImportState((importState) => ({
                    ...importState,
                    performingImport: false,
                    dialogOpen: true,
                    results: response,
                    importId: response.importId,
                    testRun: true
                  }))
                  clearInterval(interval)
                })
                .catch(() => {
                  // no response yet.
                })
            }, 3000)
          })
          .catch((e) => {
            let friendlyErrors = []

            showMessage({
              message:
                'An error occurred: ' +
                e.message +
                '\n' +
                friendlyErrors.join('\n'),
              type: 'danger',
              duration: 10000
            })
          })
        // }
        // catch(e){
        //   showMessage({ message: "An error occurred: " + e.message, type: "danger"})
        // } finally {
        //   onBackdropPress()

        // }
      }
    )
  }

  const hideImportModal = () => {
    setImportState((importState) => ({ ...importState, dialogOpen: false }))
  }

  const runFinalImport = () => {
    const bodyAppended = { body: importState.body, testRun: false }

    setImportState((importState) => ({
      ...importState,
      performingImport: true,
      results: null,
      testRun: false
    }))

    dispatch(entityActions.uploadDataFile(bodyAppended, entityType))
      .then((response) => {
        const interval = setInterval(() => {
          dispatch(entityActions.requestLongRunningJobUpdate(response.importId))
            .then((response) => {
              setImportState((importState) => ({
                ...importState,
                performingImport: false,
                dialogOpen: true,
                results: response,
                importId: response.importId
              }))
              clearInterval(interval)
            })
            .catch(() => {
              // no response yet.
            })
        }, 3000)
      })
      .catch((e) => {
        wrappedAlert('Error occurred', e)
      })
  }

  const fileImportOverlay = importState.dialogOpen && (
    <FileImportOverlay
      onClosePressed={hideImportModal}
      runFinalImport={runFinalImport}
      {...importState}
    />
  )

  const createImportTemplate = () => {
    dispatch(entityTypeActions.getImportTemplate(entityType)).then(
      (response) => {
        openExternalLink(response.url)
      }
    )
  }

  const notifyExportStartedAndCheckUntilComplete = (importId, friendlyName) => {
    let firstTimeBack = true
    showMessage({
      message: `${friendlyName} is queued successfully. You will receive an email on success.`,
      type: 'success',
      hideOnPress: true,
      floating: true
    })
    const interval = setInterval(() => {
      dispatch(entityActions.requestLongRunningJobUpdate(importId))
        .then((response) => {
          if (firstTimeBack) {
            firstTimeBack = false
            showMessage({
              message: `${friendlyName} has started.`,
              type: 'success'
            })
          }
          if (response.finished) {
            showMessage({
              message: `${friendlyName} is complete.`,
              type: 'success'
            })
            clearInterval(interval)
          }
        })
        .catch(() => {
          // no response yet.
        })
    }, 3000)
  }

  const exportDataAsCSVLongRunning = () => {
    setExportLoading(true)
    dispatch(entityTypeActions.getDataCSVLongRunning(entityType)).then(
      (response) => {
        setExportLoading(false)

        notifyExportStartedAndCheckUntilComplete(response.importId, 'Export')
      }
    )
  }

  const exportDataAsCSV = () => {
    dispatch(entityTypeActions.getDataCSV(entityType)).then((response) => {
      openExternalLink(response.url)
    })
  }

  const onGenerateNFIRSReportRequestedLongRunning = () => {
    setNfirsLoading(true)

    const { page, searchText, due_at } = queryParams
    dispatch(
      entityActions.listEntity(
        entityType,
        {
          filter: filters,
          page,
          searchText,
          due_at,
          detailed: true,
          forQueue: true
        },
        'nfirs'
      )
    ).then((response) => {
      setNfirsLoading(false)
      notifyExportStartedAndCheckUntilComplete(response.importId, 'Export')
    })
  }
  // const onGenerateNFIRSReportRequested = () => {
  //   setNfirsLoading(true)
  //   const { saveAs } = require('file-saver')

  //   const { page, searchText, due_at } = queryParams
  //   dispatch(
  //     entityActions.listEntity(
  //       entityType,
  //       { filter: filters, page, searchText, due_at, detailed: true },
  //       'nfirs'
  //     )
  //   )
  //     .then((response) => {
  //       setNfirsLoading(false)
  //       if (Platform.OS === 'web') {
  //         const url = response.url

  //         // Create a URL object
  //         const parsedUrl = new URL(url)
  //         const pathname = parsedUrl.pathname
  //         const filename = pathname.substring(pathname.lastIndexOf('/') + 1)

  //         fetch(url)
  //           .then((res) => res.blob())
  //           .then((blob) => {
  //             saveAs(blob, filename)
  //           })
  //           .catch((error) => {
  //             console.error('Error downloading file:', error)
  //           })
  //       } else {
  //         openExternalLink(response.url)
  //       }
  //     })
  //     .finally(() => {
  //       setNfirsLoading(false)
  //     })
  // }

  return {
    uploadDataFile,
    exportDataAsCSV,
    exportDataAsCSVLongRunning,
    // onGenerateNFIRSReportRequested,
    onGenerateNFIRSReportRequestedLongRunning,
    createImportTemplate,
    exportLoading,
    nfirsLoading,
    importState,
    fileImportOverlay
  }
}
