import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'

import { DateTime } from 'luxon'
import { colord } from 'colord'
import colors from '../../styles/colors'
import { boxPadding, fontSize } from './CalendarDay'
import { useIsDesktop } from '../../libraries/shouldShowDesktop'

export const CalendarDayTile = ({ onPress, agendaItem, isLast }) => {
  const hasBottomBorder = !isLast
  const backgroundColor = agendaItem.color ?? colors.lighterGray
  const foregroundColor = colord(backgroundColor).isLight()
    ? colord(backgroundColor).darken(0.5).toHex()
    : colord(backgroundColor).lighten(0.5).toHex()
  const borderColor = colord(backgroundColor).darken(0.05).toHex()

  const isMobile = !useIsDesktop()
  const [timeLabel, setTimeLabel] = useState(null)
  const invalidIndicators = ['Invalid DateTime', '00:00']
  useEffect(() => {
    if (agendaItem.start) {
      const startTimeString = DateTime.fromISO(
        agendaItem.start.split(' ').join('T')
      ).toLocaleString(DateTime.TIME_SIMPLE)

      const endTimeString = DateTime.fromISO(
        agendaItem.end.split(' ').join('T')
      ).toLocaleString(DateTime.TIME_SIMPLE)

      if (!invalidIndicators.includes(startTimeString)) {
        if (
          !invalidIndicators.includes(endTimeString) &&
          endTimeString !== startTimeString
        ) {
          setTimeLabel(`${startTimeString} -\n${endTimeString}`)
        } else {
          setTimeLabel(startTimeString)
        }
      }
    }
  }, [agendaItem])

  const {
    timeLabelStyle,
    calendarDayTileStyle,
    agendaTitleStyle
  } = useMemo(() => {
    return {
      timeLabelStyle: {
        color: foregroundColor,
        fontSize: fontSize,
        fontWeight: 'bold',
        marginRight: 2,
        paddingRight: 2,
        borderRightWidth: 0,
        borderRightColor: foregroundColor
      },
      calendarDayTileStyle: {
        flexDirection: isMobile ? 'column' : 'row',
        backgroundColor,
        borderRadius: 2,
        borderWidth: StyleSheet.hairlineWidth,
        borderBottomWidth: !hasBottomBorder ? 0 : undefined,
        borderColor,
        padding: boxPadding,
        paddingLeft: 0,
        marginLeft: StyleSheet.hairlineWidth
      },
      agendaTitleStyle: {
        color: foregroundColor,
        fontSize: fontSize,
        flex: 1,
        paddingLeft: 2
      }
    }
  }, [foregroundColor, hasBottomBorder, backgroundColor, borderColor])

  return (
    <TouchableOpacity onPress={onPress} style={calendarDayTileStyle}>
      {!!timeLabel && <Text style={timeLabelStyle}>{timeLabel}</Text>}
      <Text style={agendaTitleStyle}>{agendaItem.title}</Text>
    </TouchableOpacity>
  )
}
