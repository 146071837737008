import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from './Dropdown'
import { View } from 'react-native'
import { ListItem } from 'react-native-elements'
import { MSFESCheckbox } from '../MSFESCheckbox'
import { listItemContainerStyle } from '../EntityTypeActions'
import entityActions, { getFilterString } from '../../store/entity/actions'
import { noData } from '../HomeContainer'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'
import _ from 'lodash'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'

export const CalendarFilterDropdown = ({ onFiltersChanged, value }) => {
  const dispatch = useDispatch()

  const { entityType: entityTypeFilter } = useDiscoverEntityEntityType(
    null,
    null,
    'calendar_filter'
  )
  const args = { detailed: true },
    filter = {}

  const { filters } = useSelector((appState) => {
    const filterString = sanitiseFilterString(getFilterString(filter, args))
    return {
      filters: _.get(
        appState.entities,
        `[calendar_filter${filterString}]`,
        noData
      )
    }
  })

  const [isFirstLoad, setIsFirstLoad] = useState(false)
  useEffect(() => {
    if (entityTypeFilter) {
      if (!filters?.meta || isFirstLoad) {
        dispatch(
          entityActions.listEntity(entityTypeFilter, { ...args, filter })
        )

        if (isFirstLoad) {
          setIsFirstLoad(false)
        }
      }
    }
  }, [isFirstLoad, filters?.meta, entityTypeFilter])

  const visibleCalendarsLabel = useMemo(() => {
    if (value?.length == 0) {
      return 'Filters: None'
    }
    if (value?.length == 1) {
      return value[0].display_name
    } else {
      const remaining = value.length - 1
      return `${value[0].display_name} + ${remaining} more`
    }
  }, [value])

  const toggleSelected = (filter) => {
    if (value?.find((v) => v.id === filter.id)) {
      onFiltersChanged(value.filter((v) => v.id !== filter.id))
    } else {
      onFiltersChanged([...value, filter])
    }
  }
  return (
    <Dropdown title={`${visibleCalendarsLabel}`} variant={'outline'}>
      <View>
        {filters?.data?.map((filter) => (
          <ListItem
            key={filter.id}
            onPress={() => toggleSelected(filter)}
            disabled={false}
            containerStyle={listItemContainerStyle}
          >
            <ListItem.Content>
              <ListItem.Title>{filter.display_name}</ListItem.Title>
            </ListItem.Content>
            <MSFESCheckbox
              listitem
              radio={false}
              checked={value.find((v) => v.id === filter.id)}
              onPress={() => toggleSelected(filter)}
            />
          </ListItem>
        ))}
      </View>
    </Dropdown>
  )
}
