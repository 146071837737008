// libraries
import React, { useState } from 'react'

import { Portal, Dialog, Paragraph, Button } from 'react-native-paper'
import { Overlay } from 'react-native-elements'
import { Platform } from 'react-native'
import colors from '../styles/colors'
// styles

//actions

// components

const YesNoCancelDialog = (props) => {
  const {
    title,
    body,
    yesAction,
    noAction,
    cancelAction,
    yesLabel = 'Yes',
    noLabel = 'No',
    cancelLabel = 'Cancel'
  } = props

  const [yesLoading, setYesLoading] = useState(false)
  const executeYesAction = () => {
    const result = yesAction()

    // if yes action gives us a promise, set loading and wait for it to finish.
    if (result && result.then) {
      // it's a promise.
      setYesLoading(true)
      result.then(() => {
        setYesLoading(false)
      })
    }
  }

  const buttonColor = colors.blueDarkest
  const dialog = (
    <Overlay isVisible={true} onBackdropPress={cancelAction}>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Content>
        <Paragraph>{body}</Paragraph>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={cancelAction} color={buttonColor}>
          {cancelLabel}
        </Button>
        <Button onPress={noAction} color={buttonColor}>
          {noLabel}
        </Button>
        <Button
          onPress={executeYesAction}
          loading={yesLoading}
          color={buttonColor}
        >
          {yesLabel}
        </Button>
      </Dialog.Actions>
    </Overlay>
  )

  const IsWeb = Platform.OS === 'web'

  return IsWeb ? <Portal>{dialog}</Portal> : dialog
}

export default YesNoCancelDialog
