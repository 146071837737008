// libraries
import React from 'react'

import { SelectListMultipleAdhoc } from './SelectListMultipleAdhoc'

export const allKey = { key: 'ALL', label: 'ALL', value: 'ALL' }

export const globalAdminRole = {
  key: 'MSFES User',
  label: 'MSFES ADMIN',
  value: 'MSFES User'
}
export const roles = [
  allKey,
  globalAdminRole,
  { key: 'Tenant Admin', label: 'Fire Chief', value: 'Tenant Admin', sort: 1 },
  {
    key: 'Deputy Fire Chief',
    label: 'Deputy Fire Chief',
    value: 'Deputy Fire Chief',
    sort: 3
  },
  {
    key: 'Assistant Fire Chief',
    label: 'Assistant Fire Chief',
    value: 'Assistant Fire Chief',
    sort: 4
  },
  {
    key: 'Battalion Fire Chief',
    label: 'Battalion Fire Chief',
    value: 'Battalion Fire Chief',
    sort: 5
  },
  { key: 'Lieutenant', label: 'Lieutenant', value: 'Lieutenant', sort: 6 },
  { key: 'Engineer', label: 'Engineer', value: 'Engineer', sort: 7 },
  { key: 'Tenant User', label: 'Firefighter', value: 'Tenant User', sort: 8 },

  {
    key: 'Human Resources',
    label: 'Human Resources',
    value: 'Human Resources',
    sort: 9
  },
  {
    key: 'Office Manager',
    label: 'Office Manager',
    value: 'Office Manager',
    sort: 10
  },
  {
    key: 'Administrative Assistant',
    label: 'Administrative Assistant',
    value: 'Administrative Assistant',
    sort: 11
  },
  {
    key: 'Town/City/County Manager',
    label: 'Town/City/County Manager',
    value: 'Town/City/County Manager',
    sort: 12
  },
  {
    key: 'Town/City/County Attorney',
    label: 'Town/City/County Attorney',
    value: 'Town/City/County Attorney',
    sort: 13
  },
  {
    key: 'Trainer Admin',
    label: 'Trainer Admin',
    value: 'Trainer Admin',
    sort: 14
  },
  {
    key: 'MSFES Office Staff',
    label: 'MSFES Office Staff',
    value: 'MSFES Office Staff',
    sort: 150
  },
  { key: 'MSFES Sales', label: 'MSFES Sales', value: 'MSFES Sales', sort: 160 },
  {
    key: 'Department Officer',
    label: 'Captain',
    value: 'Department Officer',
    sort: 170
  },
  {
    key: 'Board Chairman',
    label: 'Board Chairman',
    value: 'Board Chairman',
    sort: 190
  },
  {
    key: 'Board Member',
    label: 'Board Member',
    value: 'Board Member',
    sort: 200
  },
  {
    key: 'ISO Inspector',
    label: 'ISO Inspector',
    value: 'ISO Inspector',
    sort: 210
  },
  {
    key: 'Board Treasurer',
    label: 'Board Treasurer',
    value: 'Board Treasurer',
    sort: 210
  },
  {
    key: 'Relief Fund Board Chair',
    label: 'Relief Fund Board Chair',
    value: 'Relief Fund Board Chair',
    sort: 220
  },
  {
    key: 'Relief Fund Board Member',
    label: 'Relief Fund Board Member',
    value: 'Relief Fund Board Member',
    sort: 230
  },
  {
    key: 'Auxiliary',
    label: 'Auxiliary',
    value: 'Auxiliary',
    sort: 240
  },
  {
    key: 'Junior',
    label: 'Junior',
    value: 'Junior',
    sort: 250
  }
]

export const getPublicRoleName = (internalRoleName) => {
  return roles.find((r) => r.key === internalRoleName)?.label
}
export const MultipleRoleSelector = (props) => {
  const {
    value,
    defaultValue,
    onValueChange,
    excludedRoles = [],
    ...rest
  } = props

  const rolesFiltered = roles.filter(
    (r) => !excludedRoles.find((er) => er.key === r.key)
  )

  let useValue = value
  if (defaultValue && !value) {
    useValue = defaultValue
  }
  if (typeof useValue === 'string') {
    useValue = roles.find((r) => r.value === useValue)
  }

  return (
    <SelectListMultipleAdhoc
      onChangeText={onValueChange}
      value={useValue}
      defaultValue={useValue}
      {...rest}
      data={{ field: { field_data: { params: { options: rolesFiltered } } } }}
    />
  )
}
