// libraries
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useLinkTo } from '@react-navigation/native'
// styles

//actions
import notificationActions, {
  markBatchAsRead
} from '../store/notification/actions'
import { ScrollView, RefreshControl } from 'react-native'
import MSFESIcon from './MSFESIcon'
import { ListItemButton } from './ListItemButton'
import MSFESButton from './MSFESButton'

// components

export const NotificationsContainer = () => {
  const dispatch = useDispatch()

  const [refreshing, setRefreshing] = useState(false)

  const fetchNotifications = () => {
    dispatch(notificationActions.listUnreadNotifications()).then(() => {
      setRefreshing(false)
    })
  }
  useEffect(() => {
    fetchNotifications()
  }, [])

  const linkTo = useLinkTo()
  const gotoVisitUrl = (notification) => {
    // notification.data.path
    dispatch(notificationActions.markAsRead(notification)).then(() => {
      notification.data.path && linkTo(notification.data.path)
      dispatch(notificationActions.listUnreadNotifications())
    })
  }
  const notificationsUnread = useSelector((state) =>
    _.get(state.notifications, 'unread_notifications.data', [])
  )

  const markVisibleAsRead = () => {
    const ids = notificationsUnread.map((n) => n.id)

    setRefreshing(true)
    dispatch(markBatchAsRead({ ids })).then(() => {
      fetchNotifications()
    })
  }

  const onRefresh = () => {
    setRefreshing(true)
    fetchNotifications()
  }

  return (
    <>
      <MSFESButton title="Mark visible as read" onPress={markVisibleAsRead} />
      <ScrollView
        style={{ flexDirection: 'column' }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {notificationsUnread.map((notification) => {
          return (
            <View key={notification.id}>
              <ListItemButton
                image={null}
                title={notification.data.subject}
                active={false}
                rightImage={
                  !notification.read_at ? (
                    <MSFESIcon
                      name="circle"
                      type="font-awesome"
                      size={12}
                      color="red"
                    />
                  ) : null
                }
                onPress={() => gotoVisitUrl(notification)}
                rightButtonAction={() => {
                  dispatch(notificationActions.markAsRead(notification))
                }}
              />
            </View>
          )
        })}
      </ScrollView>
    </>
  )
}

export default NotificationsContainer
