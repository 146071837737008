import React from 'react'
import { Linking, StyleSheet } from 'react-native'
import analytics from '../libraries/analytics'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'
import { ButtonLink } from './ButtonLink'
import { iconShadow } from './ModuleButton'
import useBetterLinkTo from '../useBetterLinkTo'
import _ from 'lodash'
import { useActionNavigateTo } from '../hooks/useActionNavigateTo'

const defaultVariantDark = {
  backgroundColor: 'transparent',
  titleColor: colors.buttonPrimaryTitleDark,
  linkTitleColor: colors.buttonPrimaryTitle
}
const defaultVariantLight = {
  backgroundColor: 'transparent',
  titleColor: colors.buttonPrimaryTitle,
  linkTitleColor: colors.buttonPrimaryTitleDark
}

export const buttonTheme = {
  dark: {
    variant: {
      primary: {
        ...defaultVariantDark,
        backgroundColor: colors.buttonPrimaryDark
      },
      secondary: {
        ...defaultVariantDark,
        backgroundColor: colors.buttonSecondaryDark
      },
      link: {
        ...defaultVariantDark,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: 'transparent'
      },
      outline: {
        ...defaultVariantDark,
        backgroundColor: colors.buttonPrimaryTitleDark,
        borderColor: colors.buttonPrimaryDark
      },
      delete: {
        ...defaultVariantDark,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: colors.buttonDanger
      },
      add: {
        ...defaultVariantDark,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: colors.buttonSuccess
      },
      small: {
        ...defaultVariantLight,
        backgroundColor: 'transparent'
      }
    }
  },
  light: {
    variant: {
      primary: {
        ...defaultVariantLight,
        backgroundColor: colors.brightOrange
      },
      secondary: {
        ...defaultVariantLight,
        backgroundColor: colors.buttonSecondary
      },
      danger: {
        ...defaultVariantLight,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: colors.buttonDanger
      },
      link: {
        ...defaultVariantLight,
        backgroundColor: 'transparent'
      },
      outline: {
        ...defaultVariantLight,
        backgroundColor: colors.white,
        borderColor: colors.brightOrange
      },
      delete: {
        ...defaultVariantLight,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: colors.buttonDanger
      },
      add: {
        ...defaultVariantLight,
        titleColor: 'white',
        linkTitleColor: 'white',
        backgroundColor: colors.buttonSuccess
      },
      small: {
        ...defaultVariantLight,
        backgroundColor: 'transparent'
      }
    }
  }
}

export default function MSFESButton(props) {
  const linkTo = useBetterLinkTo()
  const { actionNavigateTo } = useActionNavigateTo()

  const buttonStyle = [props.buttonStyle]

  const theme = props.dark ? buttonTheme.dark : buttonTheme.light

  let variant = props.variant
    ? props.variant
    : props.secondary
    ? 'secondary'
    : props.isLink
    ? 'link'
    : props.modifier === 'outline'
    ? 'outline'
    : props.type === 'delete'
    ? 'delete'
    : props.type === 'add'
    ? 'add'
    : 'primary'

  let titleColor =
    props.titleColor ??
    (['link', 'outline'].includes(variant)
      ? theme.variant[variant].linkTitleColor
      : theme.variant[variant].titleColor)

  let backgroundColor = theme.variant[variant].backgroundColor ?? 'transparent'
  let borderColor = _.get(
    theme.variant[variant],
    'borderColor',
    backgroundColor
  )

  if (!['link', 'outline', 'primary', 'small'].includes(variant)) {
    buttonStyle.push(iconShadow)
  }

  buttonStyle.push({ backgroundColor, minWidth: 50 })

  // set up container style
  const containerStyle = []

  if (!props.fullSize) {
    buttonStyle.push({
      paddingVertical: 12,
      borderColor,
      minHeight: 48,
      marginBottom: 8,
      marginTop: 4,
      borderWidth: StyleSheet.hairlineWidth,
      borderRadius: spacing.borderRadius
    })
  } else {
    buttonStyle.push({
      paddingVertical: 12,
      borderColor,
      borderWidth: StyleSheet.hairlineWidth,
      minHeight: 68,
      borderRadius: 0,
      height: '100%'
    })

    containerStyle.push({ borderRadius: 0, minWidth: 60 })
  }

  containerStyle.push(props.containerStyle)

  if (
    variant == 'small' ||
    variant == 'delete' ||
    variant == 'add' ||
    props.small
  ) {
    buttonStyle.push({
      minHeight: 36,
      minWidth: 40,
      paddingVertical: 4,
      marginHorizontal: spacing.m1
    })
  }

  // set up title style
  const titleStyle = []
  titleStyle.push(fonts.buttonTitleStyle)

  titleStyle.push({
    color: titleColor
  })

  if (props.isLink) {
    titleStyle.push({
      textDecorationLine: 'underline'
    })
  }

  titleStyle.push(props.titleStyle)

  // set up title text
  const { title = '' } = props

  const titleTransformed = title //title.toUpperCase();

  const loadingProps = {
    color: titleColor
  }

  let icon
  if (props.icon) {
    icon = props.icon
    if (!icon.color) {
      icon.color = titleColor
    }
  }

  const onPress = () => {
    if (props.disabled) {
      return null
    }
    console.info('Analytics track press ', {
      label: titleTransformed,
      iconName: icon?.name
    })

    analytics.TrackEvent('Button Pressed', {
      label: titleTransformed,
      iconName: icon?.name
    })
    if (props.navigateTo) {
      actionNavigateTo(props.navigateTo)
    } else {
      props.onPress && props.onPress()
    }
  }

  const overloadedOnPress = () => {
    if (props.to) {
      if (props.to.substr(0, 1) === '/') {
        linkTo(props.to)
      } else {
        Linking.openURL(props.to)
      }
    } else {
      onPress()
    }
  }

  if (props.dark) {
    //sign in screen
    buttonStyle.push({ shadowOpacity: 0, elevation: 0 })
  }

  if (props.disabled) {
    buttonStyle.push({ opacity: 0.5 })
  }
  buttonStyle.push({ marginTop: 0 })

  buttonStyle.push(props.buttonStyle)

  return (
    <ButtonLink
      {...props}
      onPress={overloadedOnPress}
      buttonStyle={buttonStyle}
      containerStyle={containerStyle}
      loadingProps={loadingProps}
      titleStyle={titleStyle}
      title={titleTransformed}
    />
  )
}
