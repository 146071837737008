import React, { useMemo } from 'react'
import { Icon, Image } from 'react-native-elements'
import { ListItemButton } from './ListItemButton'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
export const imageSize = 24;

export const ListItemAdd = ({
  title,
  linkTo = null,
  onPress=null
}) => {


  const image = useMemo(() => {
      return <Icon type={"font-awesome"} size={imageSize} color={fonts.listItemTextStyle.color} name={"plus"} />
  }, [])

  return <ListItemButton innerContainerStyle={{backgroundColor:colors.buttonSecondaryDark}} onPress={onPress} image={image} linkTo={linkTo} title={title} rightImage={null} /> 
  
}


