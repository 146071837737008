import React from 'react'
import { Text, View } from 'react-native'
import spacing from '../../styles/spacing'
import { useSelector } from 'react-redux'
import _ from 'lodash'
export const FilterParametersList = ({
  friendlyFilter,
  meta,
  tenant_id = null
}) => {
  const tenants = useSelector((state) => {
    return _.get(state, 'tenants.tenants.data', [])
  })

  const tenant = tenants.find((t) => t.id === tenant_id)
  return (
    <View style={{ paddingLeft: spacing.m2 }}>
      {!!tenant && <Text>{tenant?.name}</Text>}
      {friendlyFilter?.map((filterString, index) => (
        <Text key={index}>{filterString}</Text>
      ))}
      {meta && (
        <Text>
          {'\n'}Showing {meta.total ? 1 : 0} - {meta.to ?? 0} of {meta.total}{' '}
          matched records
        </Text>
      )}
    </View>
  )
}
