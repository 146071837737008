// libraries
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext
} from 'react'
import { useDispatch } from 'react-redux'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import spacing from '../../styles/spacing'
import colors from '../../styles/colors'
import { Button, Overlay } from 'react-native-elements'
import { v4 as uuidv4 } from 'uuid'
import md5 from 'md5'
import mime from 'mime'

import SignatureCanvas from 'react-signature-canvas'
// internal libraries

// styles

//actions
import fileActions from '../../store/file/actions'
import OverlayHeader from '../OverlayHeader'
import { Portal } from 'react-native-paper'
import PublicFormContext from '../../contexts/PublicFormContext'

export const saveSignatureButtonLabel = 'Save Signature'

export const SignaturePad = React.forwardRef((props, ref) => {
  const { onChangeText, onCancel, onFinished, field } = props

  const [mediaLoading, setMediaLoading] = useState(false)
  const [availableWidth, setAvailableWidth] = useState(0)
  const dispatch = useDispatch()
  const publicFormContext = useContext(PublicFormContext)

  useEffect(() => {
    if (props.defaultValue && props.entity.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }

    if (props.value) {
      // there is an image ID.
      dispatch(fileActions.showFile(props.value))
    }
  }, [props.defaultValue, props.value])

  const saveSignaturePressed = useCallback(async () => {
    const canvas = sigRef.current.getCanvas()

    const dataUrl = canvas.toDataURL()
    setMediaLoading(true)

    const startOfBase64 = dataUrl.substring(
      dataUrl.indexOf(':') + 1,
      dataUrl.indexOf(';')
    )
    const extension = mime.getExtension(startOfBase64)
    const filename = 'Image.' + extension

    let result = {
      type: startOfBase64,
      name: filename,
      md5: md5(dataUrl)
    }

    localStorage.setItem(result.md5, dataUrl)
    // const payload = await MediaHelper.getBlobFromDataURL(dataUrl)

    const offline_id = uuidv4()

    const entity = props.entity
    const saveAgainstId = entity.id ?? entity.offline_id

    dispatch(
      fileActions.uploadFile(
        result,
        'signature.' + extension,
        field.id,
        true,
        saveAgainstId,
        offline_id,
        publicFormContext.tenantId
          ? {
              tenantId: publicFormContext.tenantId,
              tenantHmac: publicFormContext.tenantHmac
            }
          : undefined
      )
    )
    onFinished({ offline_id })
    setMediaLoading(false)
  })

  const sigRef = useRef(null)

  const [signHereVisible, setSignHereVisible] = useState(true)
  const hideSignHereMessage = () => {
    setSignHereVisible(false)
  }

  const onViewLayout = (event) => {
    const { width } = event.nativeEvent.layout

    setAvailableWidth(width)
  }

  const signatureStyle = {
    height: 100,
    width: availableWidth,
    borderRadius: spacing.borderRadius
  }
  const handleClear = () => {
    sigRef.current.clear()
  }
  return (
    <Portal>
      <Overlay onBackdropPress={onCancel}>
        <OverlayHeader>
          <Text style={{ marginLeft: '35%' }}>Sign Below</Text>
          <TouchableOpacity
            onPress={handleClear}
            style={{
              textDecoration: 'underline',
              textAlign: 'right',
              color: 'red',
              fontSize: 14,
              marginLeft: '25%'
            }}
          >
            Clear
          </TouchableOpacity>
        </OverlayHeader>

        <View
          onLayout={onViewLayout}
          style={{
            backgroundColor: 'white',
            position: 'relative',
            borderWidth: StyleSheet.hairlineWidth,
            minWidth: 420,
            width: signatureStyle.width,
            borderColor: colors.inactiveBackground,
            borderStyle: 'solid'
          }}
        >
          {signHereVisible && (
            <View
              style={{ position: 'absolute', bottom: 30, left: 30 }}
              pointerEvents={'none'}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 24, opacity: 0.3 }}>
                SIGN HERE
              </Text>
            </View>
          )}

          <SignatureCanvas
            ref={sigRef}
            penColor="black"
            canvasProps={{
              width: signatureStyle.width,
              height: signatureStyle.height,
              // style: { maxWidth: signatureStyle.width },
              className: 'sigCanvas'
            }}
            onBegin={hideSignHereMessage}
          />
        </View>
        <Button
          title={saveSignatureButtonLabel}
          loading={mediaLoading}
          style={{
            paddingTop: spacing.m1
          }}
          onPress={saveSignaturePressed}
        />
      </Overlay>
    </Portal>
  )
})

export default SignaturePad
