// libraries
import React, { useEffect, useState, useRef } from 'react'
import { View, Text } from 'react-native'
import { withTheme } from 'react-native-elements'
import { DateTime } from 'luxon'
import { TouchableOpacity } from 'react-native'
import spacing from '../styles/spacing'
import fonts from '../styles/fonts'
import colors from '../styles/colors'
import { LayoutAnimation } from 'react-native'
import { FlatList } from 'react-native'
import MSFESIcon from './MSFESIcon'
import shared from '../styles/shared'
import { useRoute } from '@react-navigation/native'
import { StyleSheet } from 'react-native'
import { useColorContext } from '../contexts/ColorContext'

// styles

//actions

// components

export const DateChanger = ({ value, onDateChanged }) => {
  const flatlistRef = useRef(null)
  const route = useRoute()
  const isDashboard = route.name.includes('Dashboard') ? true : false
  const { colorContext } = useColorContext()

  const [internalValue, setInternalValue] = useState(() =>
    value ? DateTime.fromISO(value) : DateTime.local()
  )
  useEffect(() => {
    onDateChanged(internalValue.toISODate())
  }, [])
  const [isDeselected, setIsDeselected] = useState(false)

  const animateSetInternalValue = (
    newValue,
    index = -1,
    updateParent = true
  ) => {
    if (newValue.toISODate() === internalValue.toISODate()) {
      // clicking selected will unselect.
      const deselection = !isDeselected
      setIsDeselected(deselection)
      onDateChanged(null)
    } else {
      setIsDeselected(false)
      LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
      if (index !== -1 && newValue > internalValue) {
        flatlistRef.current.scrollToIndex({ index })
      }
      setInternalValue(newValue)
      updateParent && onDateChanged(newValue)
    }
  }

  const [display, setDisplay] = useState({ days: [], monthYear: null })

  useEffect(() => {
    if (value) {
      const parsedDate = DateTime.fromISO(value)
      if (parsedDate.isValid) {
        setInternalValue(parsedDate)
      } else {
        console.error('Received invalid date string:', value)

        setInternalValue(DateTime.local())
      }
    } else {
      setInternalValue(DateTime.local())
    }
  }, [value])

  // const daysTotalToShow = 7

  // when internal value changes, update UI stuff.
  useEffect(() => {
    let currentValue = DateTime.isDateTime(internalValue)
      ? internalValue
      : DateTime.fromISO(internalValue)

    if (currentValue.isValid) {
      let days = []
      let startDay

      if (isDashboard) {
        startDay = currentValue.minus({ days: 3 })
        for (let i = 0; i < 7; i++) {
          days.push(startDay)
          startDay = startDay.plus({ days: 1 })
        }
      } else {
        startDay = currentValue.startOf('month')
        const endDay = currentValue.endOf('month')

        while (startDay <= endDay) {
          days.push(startDay)
          startDay = startDay.plus({ days: 1 })
        }
      }

      setDisplay({
        monthYear: `${currentValue.monthLong} ${currentValue.year}`,
        days
      })
    } else {
      console.error('Invalid DateTime object:', currentValue)
    }
  }, [internalValue, isDashboard])

  const goToNextMonth = () => {
    animateSetInternalValue(internalValue.plus({ months: 1 }), 0, false)
  }
  const goToPrevMonth = () => {
    animateSetInternalValue(internalValue.plus({ months: -1 }), 0, false)
  }

  const dayTextStyle = {
    color: colors.moduleIconTitle,
    fontFamily: fonts.standardFace,
    fontWeight: fonts.font500,
    fontSize: fonts.smallLabelSize
  }

  const weekdayTextStyle = {
    color: colors.inputLabel,
    fontFamily: fonts.standardFace,
    fontWeight: fonts.font500,
    fontSize: fonts.smallLabelSize
  }

  const buttonShadow = {
    shadowColor: '#333',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.16,
    shadowRadius: 3.75,
    elevation: 5
  }

  const dayButtonStyle = {
    backgroundColor: isDashboard ? colors.skyLighter : colors.background,
    borderRadius:
      isDashboard && !isDeselected
        ? spacing.m1
        : isDashboard
        ? spacing.m0
        : spacing.m1,
    width: 42,
    marginHorizontal: isDashboard ? spacing.m0 + 1 : spacing.m1,
    paddingVertical: isDashboard ? spacing.m0 + 1 : spacing.m1,
    alignItems: 'center'
  }
  const activeDaybuttonStyle = {
    borderColor:
      isDashboard && !isDeselected
        ? colorContext.color
        : colors.moduleIconTitle,
    borderWidth: 2,
    backgroundColor:
      isDashboard && !isDeselected ? colors.white : colors.skyLighter
  }

  const styles = StyleSheet.create({
    colorCircle: {
      width: 5,
      height: 5,
      borderRadius: spacing.m0_5,
      marginRight: spacing.m1,
      marginLeft: spacing.m1,
      marginTop: spacing.m0_5
    }
  })

  return (
    <>
      <View style={{ flexDirection: 'column' }}>
        <View
          style={[
            {
              flexDirection: 'row',
              backgroundColor: isDashboard
                ? colors.white
                : colors.headerBackground
            },
            shared.debugOff
          ]}
        >
          <View
            style={[
              {
                paddingLeft: spacing.m2,
                flex: 1,
                minWidth: 160,
                justifyContent: 'center'
              },
              shared.debugOff
            ]}
          >
            <Text
              style={[
                fonts.headerStyle,
                shared.debugOff,
                isDashboard && {
                  marginLeft: -spacing.m1_5,
                  marginTop: -spacing.m2_5,
                  fontSize: fonts.buttonLabelSize
                }
              ]}
            >
              {display.monthYear}
            </Text>
          </View>
          <View
            style={[
              { flexDirection: 'row', marginLeft: spacing.m2, flex: 6 },
              shared.debugOff
            ]}
          >
            <MSFESIcon
              name={'chevron-left'}
              style={isDashboard && { marginLeft: spacing.m3 }}
              tappable
              size={18}
              type="font-awesome"
              onPress={goToPrevMonth}
            />
            <MSFESIcon
              name={'chevron-right'}
              tappable
              size={18}
              type="font-awesome"
              onPress={goToNextMonth}
            />
          </View>
        </View>

        <View
          style={{
            backgroundColor: isDashboard ? colors.white : colors.background
          }}
        >
          <View
            style={{
              position: 'absolute',
              height: '50%',
              backgroundColor: isDashboard
                ? colors.white
                : colors.headerBackground,
              width: '100%'
            }}
          >
            {/* The half shade of blue behind the days. */}
          </View>

          <View
            style={{
              flexDirection: 'row',
              position: 'relative',
              marginHorizontal: isDashboard ? spacing.m0 : spacing.m1,
              paddingBottom: isDashboard ? spacing.m0 : spacing.m1
            }}
          >
            <FlatList
              ref={flatlistRef}
              contentContainerStyle={{ paddingBottom: spacing.m2_5 }}
              horizontal={true}
              data={display.days}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    key={item.day}
                    onPress={() => animateSetInternalValue(item, index)}
                    style={[
                      dayButtonStyle,
                      isDashboard ? '' : buttonShadow,
                      internalValue &&
                        item.day === internalValue.day &&
                        activeDaybuttonStyle
                    ]}
                  >
                    <Text style={weekdayTextStyle}>{item.weekdayShort}</Text>
                    <Text style={dayTextStyle}>{item.day}</Text>
                    {isDashboard && (
                      <View
                        style={[
                          styles.colorCircle,
                          {
                            backgroundColor: colorContext.color
                              ? colorContext.color
                              : colors.lightGray
                          }
                        ]}
                      />
                    )}
                  </TouchableOpacity>
                )
              }}
            />
          </View>
        </View>
      </View>
    </>
  )
}

export default withTheme(DateChanger)
