import { simplifyObject } from './SelectWithData'

import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'

import _ from 'lodash'
import { SelectList } from './SelectList'
export const SelectAdminPersonnel = React.forwardRef((props, ref) => {
  /* Always defaults to this guy, if not already filled. */
  let personnelId = undefined

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  useEffect(() => {
    if (props.defaultValue) {
      personnelId = props.defaultValue
    } else if (_.get(props.data, 'entity.mode', 'add') == 'add') {
      personnelId = simplifyObject(current_user.personnel_record)
    }

    props.onChangeText(personnelId, false)
  }, [])

  return (
    <SelectList
      {...props}
      value={props.defaultValue}
      data={{
        entity: props.data.entity,
        field: {
          field_data: {
            params: {
              sourceName: 'personnel',
              optionsFilter: {
                json: {
                  errors: [],
                  logic: {
                    and: [
                      {
                        '==': [
                          {
                            var: 'role'
                          },
                          'MSFES User'
                        ]
                      }
                    ]
                  },
                  data: {
                    role: null
                  }
                }
              }
            }
          }
        }
      }}
    />
  )
})
