// libraries
import React from 'react'
import { View } from 'react-native'
import MSFESLabel from '../MSFESLabel'
import { MSFESCheckbox } from '../MSFESCheckbox'

export const YesNo = React.forwardRef((props, ref) => {
  const {
    value,
    changeHandler,
    editable = true,
    renderStaticHeader = false
  } = props

  const setChecked = (checkedState) => {
    changeHandler(checkedState)
  }

  const labelWidth = 64
  const labelPadding = 28

  return (
    <View
      style={{
        position: 'relative',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-end'
      }}
    >
      {renderStaticHeader && (
        <View
          style={{
            position: 'absolute',
            top: -24,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <MSFESLabel
            label="Yes"
            style={{ paddingLeft: labelPadding, width: labelWidth }}
            labelStyle={{ textAlign: 'center' }}
          />
          <MSFESLabel
            label="No"
            style={{ paddingLeft: labelPadding, width: labelWidth }}
          />
        </View>
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: -6,
          marginRight: -12
        }}
      >
        <MSFESCheckbox
          disabled={!editable}
          checked={value === true}
          onPress={() => setChecked(true)}
        />
        <MSFESCheckbox
          disabled={!editable}
          checked={value === false}
          onPress={() => setChecked(false)}
        />
      </View>
    </View>
  )
})
