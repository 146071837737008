import React, { useEffect, useMemo, useState } from 'react'
import { View, Text } from 'react-native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import MSFESIcon from '../components/MSFESIcon'

import userActions from '../store/user/actions'
import tenantActions from '../store/tenant/actions'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import shared from '../styles/shared'
import { DrawerActions } from '@react-navigation/routers'
import colors from '../styles/colors'
import MSFESAvatar from '../components/MSFESAvatar'
import BottomTabNavigator from '../navigators/TabNavigator'
import spacing from '../styles/spacing'
import DrawerTenantSwitcher from '../components/DrawerTenantSwitcher'

import DrawerItemList from '../components/DrawerItemList'
import { ScrollView } from 'react-native-gesture-handler'
import MyProfile from '../components/MyProfile'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { getFilterString } from '../store/entity/actions'
import { noData } from '../components/HomeContainer'

import MSFESNestedAddEntity from '../components/MSFESNestedAddEntity'
import { updateMode } from '../screens/EntityAddComponent'
import { useShowMyDepartmentUI } from '../hooks/useShowMyDepartmentUI'

import { useOnButtonObjectPressed } from '../components/TopNavButtonHousing/QuickOverlay'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

const CustomDrawerContent = (props) => {
  const dispatch = useDispatch()
  const { navigation } = props

  const { user, screenButtonsWrapper } = useSelector((appState) => {
    const user = _.get(appState, 'users.user', {})

    const args = { detailed: true },
      filter = {}

    const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
      null,
      null,
      'screen_button'
    )
    const filterString = sanitiseFilterString(getFilterString(filter, args))

    const screenButtonsWrapper =
      entityTypeScreenButtons &&
      _.get(
        appState.entities,
        `[${entityTypeScreenButtons.name + filterString}]`,
        noData
      )

    return { user, screenButtonsWrapper }
  })

  const navbarButtons = useMemo(
    () =>
      screenButtonsWrapper
        ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
            (r) => _.get(r, 'position.label', null) === 'Navigation Bar'
          )
        : [],
    [screenButtonsWrapper]
  )
  const navbarButtonsDocs = useMemo(
    () =>
      screenButtonsWrapper
        ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
            (r) => _.get(r, 'position.label', null) === 'Navigation Bar - Docs'
          )
        : [],
    [screenButtonsWrapper]
  )

  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false)
  const [isTenantAdmin, setIsTenantAdmin] = useState(false)

  const [displayPicture, setDisplayPicture] = useState(null)

  useEffect(() => {
    let hasGlobalAdmin = false
    let hasTenantAdmin = false
    user.roles.map((r) => {
      if (r.name === 'MSFES User') {
        hasGlobalAdmin = true
      }
      if (r.name === 'Tenant Admin' || r.name === 'Trainer Admin') {
        hasTenantAdmin = true
      }
    })

    setDisplayPicture(
      _.get(user, 'personnel_record.SYSTEM_display_picture', null)
    )

    setIsGlobalAdmin(hasGlobalAdmin)
    setIsTenantAdmin(hasTenantAdmin)

    dispatch(tenantActions.listTenants())
  }, [user])

  const [showMyProfile, setShowMyProfile] = useState(false)

  const [
    showMyDepartment,
    setShowMyDepartment,
    tenantEntityType,
    currentTenantEntity
  ] = useShowMyDepartmentUI(user)

  const dismissMyProfile = () => {
    setShowMyProfile(false)
  }

  const state = navigation.dangerouslyGetState()
  // routes[0] = "FireHR" / Tab navigator
  // next routes[0] = "Home" / place where the ad-hoc routes live
  const secondaryRoutes = _.get(
    state,
    'routes[0].state.routes[0].state.routes',
    []
  )
  const lastRoute = secondaryRoutes.length
    ? secondaryRoutes[secondaryRoutes.length - 1].name
    : null

  const onButtonObjectPressed = useOnButtonObjectPressed()

  const [showQuickLinks, setShowQuickLinks] = useState(false)
  const [showQuickDocs, setShowQuickDocs] = useState(false)
  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={[
        shared.debugOff,
        { flexDirection: 'column', flex: 1 }
      ]}
    >
      <View style={{ flexDirection: 'row' }}>
        <DrawerItem
          style={[shared.debugOff]}
          label=""
          icon={() => <MSFESIcon name="times" type="font-awesome" />}
          onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
        />

        <View style={{ flex: 1, alignItems: 'flex-end', marginRight: -60 }}>
          <DrawerItem style={[shared.debugOff]} label="" onPress={() => {}} />
        </View>
      </View>

      <MSFESAvatar
        source={displayPicture}
        label={user.display_name}
        textProps={{ h4: true }}
        size="xlarge"
        outerContainerStyle={{
          alignItems: 'center',
          marginTop: 40,
          marginBottom: 40
        }}
        titleStyle={{ color: colors.avatarText, fontSize: 60 }}
      />
      {isGlobalAdmin && (
        <View
          style={{
            paddingLeft: 70,
            paddingVertical: spacing.m1,
            marginBottom: spacing.m1,
            backgroundColor: colors.headingBackground
          }}
        >
          <Text style={{ color: colors.headingText, fontWeight: 'bold' }}>
            Global Admin
          </Text>
        </View>
      )}

      <View style={[shared.debugOff, { flex: 1 }]}>
        <ScrollView>
          <DrawerTenantSwitcher />
          <DrawerItemList {...props} />
          <DrawerItem
            style={[shared.debugOff]}
            label="Messages"
            focused={['ConversationList', 'ConversationView'].includes(
              lastRoute
            )}
            icon={() => (
              <View style={{ width: 20 }}>
                <MSFESIcon name="edit" size={18} type="font-awesome" />
              </View>
            )}
            onPress={() =>
              navigation.navigate('Home', { screen: 'ConversationList' })
            }
          />

          <DrawerItem
            style={[shared.debugOff]}
            label="Documents"
            focused={['DocumentList', 'DocumentView'].includes(lastRoute)}
            icon={() => (
              <View style={{ width: 20 }}>
                <MSFESIcon name="folder" size={18} type="font-awesome" />
              </View>
            )}
            onPress={() =>
              navigation.navigate('Home', { screen: 'DocumentList' })
            }
          />
          <DrawerItem
            style={[shared.debugOff]}
            label="My Profile"
            onPress={() =>
              setTimeout(() => {
                setShowMyProfile(true)
              }, 1)
            }
            icon={() => (
              <View style={{ width: 20 }}>
                <MSFESIcon name="id-card" size={18} type="font-awesome" />
              </View>
            )}
          />
          {isTenantAdmin && (
            <DrawerItem
              style={[shared.debugOff]}
              label="My Department"
              onPress={() =>
                setTimeout(() => {
                  setShowMyDepartment(true)
                }, 1)
              }
              icon={() => (
                <View style={{ width: 20 }}>
                  <MSFESIcon name="building" size={18} type="font-awesome" />
                </View>
              )}
            />
          )}
          <DrawerItem
            key={'quicklinks'}
            label={'Quick Links'}
            focused={false}
            style={{ marginLeft: 30 }}
            onPress={() => {
              setShowQuickLinks((_showQuickLinks) => !_showQuickLinks)
            }}
            icon={() => (
              <View style={{ width: 20, position: 'absolute', right: 16 }}>
                <MSFESIcon
                  name={showQuickLinks ? 'chevron-up' : 'chevron-down'}
                  size={12}
                  type="font-awesome"
                />
              </View>
            )}
          />
          {showQuickLinks &&
            navbarButtons.map((buttonObject, index) => {
              return (
                <View
                  key={'hsb' + index}
                  style={{
                    backgroundColor: '#f5f5f5'
                  }}
                >
                  <DrawerItem
                    label={buttonObject.name}
                    focused={false}
                    icon={() => (
                      <View style={{ width: 20 }}>
                        <MSFESIcon
                          name={buttonObject.icon}
                          size={20}
                          type="font-awesome"
                        />
                      </View>
                    )}
                    onPress={async () => {
                      onButtonObjectPressed({
                        buttonObject
                      })
                    }}
                  />
                </View>
              )
            })}

          <DrawerItem
            key={'quickdocs'}
            label={'Quick Docs'}
            focused={false}
            style={{ marginLeft: 30 }}
            onPress={() => {
              setShowQuickDocs((_showQuickDocs) => !_showQuickDocs)
            }}
            icon={() => (
              <View style={{ width: 20, position: 'absolute', right: 16 }}>
                <MSFESIcon
                  name={showQuickDocs ? 'chevron-up' : 'chevron-down'}
                  size={12}
                  type="font-awesome"
                />
              </View>
            )}
          />
          {showQuickDocs &&
            navbarButtonsDocs.map((buttonObject, index) => {
              return (
                <View
                  key={'hsb' + index}
                  style={{
                    backgroundColor: '#f5f5f5'
                  }}
                >
                  <DrawerItem
                    label={buttonObject.name}
                    focused={false}
                    icon={() => (
                      <View style={{ width: 20 }}>
                        <MSFESIcon
                          name={buttonObject.icon}
                          size={18}
                          type="font-awesome"
                        />
                      </View>
                    )}
                    onPress={async () => {
                      onButtonObjectPressed({
                        buttonObject
                      })
                    }}
                  />
                </View>
              )
            })}
          <DrawerItem
            style={[shared.debugOff]}
            label="Sign Out"
            icon={() => (
              <View style={{ width: 20 }}>
                <MSFESIcon name="sign-out" type="font-awesome" />
              </View>
            )}
            onPress={() => dispatch(userActions.signout())}
          />
        </ScrollView>
      </View>

      {!!showMyProfile && (
        <MyProfile navigation={navigation} onDismiss={dismissMyProfile} />
      )}

      {showMyDepartment && !!tenantEntityType && (
        <MSFESNestedAddEntity
          navigation={navigation}
          entityType={tenantEntityType}
          tenant={user.tenant_id}
          mode={updateMode}
          id={currentTenantEntity.id}
          onDismiss={() => setShowMyDepartment(false)}
          shouldDismissOnSaved={false}
          onChangedCallback={() => {
            setShowMyDepartment(false)
          }}
        />
      )}
    </DrawerContentScrollView>
  )
}

const Drawer = createDrawerNavigator()

export default function DrawerNavigator() {
  return (
    <Drawer.Navigator
      sceneContainerStyle={{
        backgroundColor: 'transparent'
      }}
      overlayColor={'rgba(255,255,255,0.7)'}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen
        name="FireHR"
        component={BottomTabNavigator}
        focused={false}
        options={{
          drawerIcon: () => <MSFESIcon name="home" type="font-awesome" />,
          hidden: true
        }}
      />
    </Drawer.Navigator>
  )
}
