import React, { useState, useEffect } from 'react'
import { ActivityIndicator, View, Text, Platform, FlatList } from 'react-native'
import { DataTable } from 'react-native-paper'
import CurrentlyNoItems from '../CurrentlyNoItems'
import OverlayHeader from '../OverlayHeader'
import { Overlay } from 'react-native-elements'
import { useDispatch } from 'react-redux'
import {
  listTenantLogins,
  updateTenantLogin
} from '../../store/admin/logins/actions'
import { cellStyle } from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import MSFESInput from '../MSFESInput'
import spacing from '../../styles/spacing'
import { WrappedSwitch } from '../EntityField'
import MSFESButton from '../MSFESButton'
import { showMessage } from 'react-native-flash-message'
import _ from 'lodash'
export const LoginsTenant = ({ activeSort, searchText }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [data, setData] = useState([])
  useEffect(() => {
    fetchNextPage()
  }, [page, searchText])

  useEffect(() => {
    setPage(1)
  }, [searchText])

  const fetchNextPage = () => {
    dispatch(listTenantLogins({ page, searchText })).then((response) => {
      setLoading(false)
      if (page > 1) {
        setData((data) => [...data, ...response.data])
      } else {
        setData(response.data)
      }
      setPages(response.meta.last_page)
    })
  }

  const [editingTenant, setEditingTenant] = useState(null)

  const [dataSorted, setDataSorted] = useState([])
  useEffect(() => {
    setDataSorted(
      _.orderBy(data, ['name'], [activeSort.name == 'a_to_z' ? 'asc' : 'desc'])
    )
  }, [data, activeSort])

  const saveChanges = () => {
    setSaving(true)
    dispatch(updateTenantLogin({ tenant: editingTenant }))
      .then((response) => {
        setEditingTenant(false)
        showMessage({
          message: 'Updated.',
          type: 'success'
        })
        setData((data) => {
          const newData = [...data]
          const index = newData.findIndex((el) => el.id === response.data.id)
          newData[index] = response.data
          return newData
        })
      })
      .finally(() => {
        setSaving(false)
      })
  }

  return (
    <View style={{ flex: 1 }}>
      {loading && <ActivityIndicator />}
      {!loading && !data.length && <CurrentlyNoItems label="No tenants" />}

      {!!editingTenant && (
        <Overlay
          isVisible={true}
          onBackdropPress={() => setEditingTenant(false)}
          overlayStyle={{ padding: 0, margin: 0 }}
        >
          <View
            style={{
              flex: 1,
              padding: spacing.m1,
              minHeight: Platform.OS === 'ios' ? 330 : 140
            }}
          >
            <OverlayHeader>{editingTenant.name}</OverlayHeader>

            <MSFESInput
              label={'Tenant'}
              editable={false}
              horizontal
              defaultValue={editingTenant.name}
            />
            <MSFESInput
              label={'Users can Sign In'}
              horizontal
              InputComponent={WrappedSwitch}
              onValueChange={() =>
                setEditingTenant((editingTenant) => ({
                  ...editingTenant,
                  hasChanges: true,
                  signInEnabled: !editingTenant.signInEnabled
                }))
              }
              value={editingTenant.signInEnabled}
            />
            <MSFESInput
              label={'Error Message'}
              horizontal
              onChangeText={(signInDisabledError) => {
                setEditingTenant((editingTenant) => ({
                  ...editingTenant,
                  hasChanges: true,
                  signInDisabledError
                }))
              }}
              editable={!editingTenant.signInEnabled}
              placeholder={`Enter sign in failed error message`}
              defaultValue={editingTenant.signInDisabledError}
            />
            <MSFESButton
              loading={saving}
              onPress={saveChanges}
              title="Save Sign-In Changes"
            />
          </View>
        </Overlay>
      )}
      {!loading && !!data.length && (
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title key={'tenant'}>Tenant</DataTable.Title>
            <DataTable.Title key={'datetime'}>Status</DataTable.Title>
            <DataTable.Title key={'notes'}>Message</DataTable.Title>
          </DataTable.Header>

          <View style={{ flex: 1 }}>
            <FlatList
              data={dataSorted}
              onEndReached={() => {
                if (page < pages) {
                  setPage(page + 1)
                }
              }}
              renderItem={({ item: tenant, index }) => {
                return (
                  <DataTable.Row
                    key={index}
                    style={{
                      backgroundColor: index % 2 ? 'transparent' : '#f1f1f1'
                    }}
                    onPress={() => setEditingTenant(tenant)}
                  >
                    <DataTable.Cell key={'name'} style={cellStyle}>
                      <Text>{tenant.name}</Text>
                    </DataTable.Cell>
                    <DataTable.Cell key={'created_at'} style={cellStyle}>
                      <Text
                        style={{
                          fontWeight: !tenant.signInEnabled ? 'bold' : 'normal'
                        }}
                      >
                        {tenant.signInEnabled == true ? 'Enabled' : 'Disabled'}
                      </Text>
                    </DataTable.Cell>
                    <DataTable.Cell key={'content'} style={cellStyle}>
                      <Text>{tenant.signInDisabledError}</Text>
                    </DataTable.Cell>
                  </DataTable.Row>
                )
              }}
            />
          </View>
        </DataTable>
      )}
    </View>
  )
}
