// libraries
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import versionActions from '../store/version/actions'
import { ListItemLinkButton } from './ListItemLinkButton'
import { Overlay } from 'react-native-elements'
import OverlayHeader from './OverlayHeader'
import { FlatList } from 'react-native'
import CurrentlyNoItems from './CurrentlyNoItems'
import { getDisplayLabelForEntity } from '../libraries/entityTools'
import { ActivityIndicator } from 'react-native'
import { PortalWrapper } from './fields/DatePicker'

//actions

// components

export const VersionFiles = ({ entity, definition, onHideVersions }) => {
  const dispatch = useDispatch()

  const versions = useSelector((state) => {
    return _.get(state, `versions.byEntity[${entity.id}]`, [])
  })

  const [haveRequestedVersions, setHaveRequestedVersions] = useState(false)
  const [loading] = useState(false)
  useEffect(() => {
    // if user pops open overlay, fetch the version.
    if (!haveRequestedVersions) {
      dispatch(versionActions.listFiles(entity, definition))
      setHaveRequestedVersions(true)
    }
  }, [])

  const currentlyNoLabel = 'No versions found.'

  const simplifyDate = (complicatedDate) => {
    const date = new Date(complicatedDate)
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
    return date.toLocaleDateString(undefined, options)
  }

  return (
    <PortalWrapper>
      <Overlay visible={true} onBackdropPress={onHideVersions}>
        <View style={{ maxHeight: 400, minWidth: 220, position: 'relative' }}>
          <OverlayHeader>Version History</OverlayHeader>

          {loading && <ActivityIndicator size="large" />}
          {!loading && (
            <FlatList
              contentContainerStyle={{ flex: 1 }}
              ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
              keyExtractor={(item) => item.version_id.toString()}
              data={versions}
              renderItem={({ item, index }) => {
                const display_name =
                  item?.responsible_user?.display_name ?? 'Unknown'
                return (
                  <ListItemLinkButton
                    entity={item.model_data}
                    title={
                      getDisplayLabelForEntity(entity) + ' v' + item.version
                    }
                    subtitle={
                      !item.pdf
                        ? '(Report not available yet)'
                        : 'Updated at: ' +
                          simplifyDate(item.updated_at) +
                          '\n' +
                          'Author: ' +
                          display_name
                    }
                    key={index}
                    showChevron={!!item.pdf}
                    fileUrl={item.pdf}
                  />
                )
              }}
            />
          )}
        </View>
      </Overlay>
    </PortalWrapper>
  )
}
