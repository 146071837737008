import React from 'react'
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  ActivityIndicator
} from 'react-native'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
import { iconShadow } from './ModuleButton'
import useBetterLinkTo from '../useBetterLinkTo'
import {
  IsDeletedEntityIdentifier,
  IsGenericEntityIdentifier,
  IsVirtualEntityIdentifier
} from './GridItemButton'
import { useRoute } from '@react-navigation/native'

export const ListItemButton = (props) => {
  const {
    color,
    alarmColor = null,
    loading = false,
    linkTo = null,
    title,
    subtitle = null,
    isGeneric = false,
    isVirtual = false,
    isDeleted = false,
    rightImage,
    rightButtonAction = null,
    onPress = null,
    active = false,
    touchableStyle = null,
    formattedDate
  } = props
  const route = useRoute()
  const isDashboard = route.name.includes('Dashboard') ? true : false
  const isCalendar = route.name.includes('Calendar') ? true : false
  const openLinkTo = useBetterLinkTo()

  const overloadedOnPress = onPress
    ? onPress
    : () => {
        linkTo && openLinkTo(linkTo)
      }

  return (
    <View style={[styles.outerContainer]}>
      <View
        style={[
          isDashboard ? styles.dashboardInnerContainer : styles.innerContainer,
          {
            backgroundColor: isDashboard
              ? colors.skyLighter
              : active
              ? colors.yellowLightest
              : colors.white
          },
          alarmColor && {
            shadowColor: alarmColor,
            shadowRadius: spacing.borderRadius,
            shadowOpacity: 0.5
          },
          props.innerContainerStyle
        ]}
      >
        <TouchableOpacity
          style={[{ flexDirection: 'row' }, touchableStyle]}
          onPress={overloadedOnPress}
        >
          <View style={styles.identifiers}>
            {isGeneric && <IsGenericEntityIdentifier />}
            {isVirtual && <IsVirtualEntityIdentifier />}
            {isDeleted && <IsDeletedEntityIdentifier />}
          </View>

          <View style={styles.labelContainer}>
            <Text
              style={[
                isDashboard
                  ? fonts.dashboardListItemTextStyle
                  : fonts.listItemTextStyle,
                { marginLeft: spacing.m2_5 }
              ]}
            >
              {!formattedDate && !subtitle && (
                <View
                  style={[
                    isDashboard || isCalendar ? styles.colorCircle : {},
                    {
                      backgroundColor: color ? color : colors.labelGray
                    }
                  ]}
                />
              )}
              {title}
            </Text>
            {isDashboard && (
              <Text
                style={[
                  isDashboard
                    ? fonts.dashboardListItemFormattedDate
                    : fonts.listItemTextStyle,
                  {
                    marginLeft: spacing.m2_5,
                    marginTop: spacing.m0 + 1,
                    marginBottom: spacing.m0 + 1
                  }
                ]}
              >
                {formattedDate && (
                  <View
                    style={[
                      isDashboard || isCalendar ? styles.colorCircle : {},
                      {
                        backgroundColor: color ? color : colors.labelGray
                      }
                    ]}
                  />
                )}
                {formattedDate}
              </Text>
            )}
            <Text
              style={[
                fonts.moduleLabelSize,
                { paddingVertical: spacing.m0, paddingBottom: spacing.m2 }
              ]}
            >
              {subtitle && (
                <View
                  style={[
                    {
                      backgroundColor: color ? color : colors.labelGray,
                      marginLeft: spacing.m2_5
                    }
                  ]}
                />
              )}
              {subtitle}
            </Text>
          </View>
        </TouchableOpacity>
        {(!!rightButtonAction || !!rightImage) && (
          <View style={styles.settingsContainer}>
            <TouchableOpacity
              style={styles.settingsButtonStyle}
              onPress={rightButtonAction}
            >
              <View>{rightImage}</View>
            </TouchableOpacity>
          </View>
        )}
        {loading && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              height: '100%',
              left: 0,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: active ? colors.yellowLightest : 'white',
              opacity: 0.7
            }}
          >
            <ActivityIndicator />
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  colorCircle: {
    width: 10,
    height: 10,
    borderRadius: spacing.borderRadius - 5,
    marginRight: spacing.m1
  },
  outerContainer: {
    margin: spacing.m1,
    minHeight: 64
  },
  innerContainer: {
    flexDirection: 'row',
    paddingRight: spacing.m2,
    flex: 1,
    ...iconShadow
  },
  dashboardInnerContainer: {
    flexDirection: 'row',
    paddingRight: spacing.m2,
    flex: 1,
    // ...iconShadow,
    borderRadius: spacing.borderRadius,
    marginLeft: spacing.m1_5,
    marginRight: spacing.m1_5,
    marginBottom: spacing.m1_5,
    height: 40
  },
  identifiers: {
    position: 'absolute',
    right: 8,
    top: 8,
    height: 16
  },
  imageContainer: {
    minHeight: 40,
    padding: spacing.m2,
    alignContent: 'center',
    justifyContent: 'center',
    minWidth: 64
  },
  labelContainer: { paddingTop: spacing.m0_5 },
  settingsContainer: { minWidth: 40, flexDirection: 'column' },
  settingsButtonStyle: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center'
  }
})
