import gridStyles from './grid'
import spacing from './spacing'
const shared = {
  darkView: [],
  fullHeight: [{ flex: 1 }],
  screenView: [gridStyles.padding.viewPort],
  debugOff: [],
  debug: [{ borderWidth: 2, borderColor: 'red', borderStyle: 'solid' }],
  debug2: [{ borderWidth: 2, borderColor: 'green', borderStyle: 'solid' }],
  mobileWidthView: [gridStyles.grid.mobileWidth, gridStyles.padding.viewPort],
  tabletWidthView: [gridStyles.grid.tabletWidth, gridStyles.padding.viewPort],
  gridWidthView: [
    { maxWidth: '100%', alignSelf: 'center' },
    {
      paddingHorizontal: spacing.m1,
      paddingVertical: spacing.m1
    }
  ],
  withHeaderPadding: [{ marginTop: 40 }]
}

export default shared
