import React from 'react'
import { View } from 'react-native'
// import MSFESLabel from './MSFESLabel'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import colors from '../styles/colors'
import MSFESHeading from './MSFESHeading'
import { iconShadow } from './ModuleButton'
// import { getLabelDirectionStyle } from './MSFESInput'
import { getRenderStaticHeader } from './EntityEdit'
import { Icon } from 'react-native-elements'

export const groupContainerStyle = [
  {
    marginTop: spacing.m1,
    backgroundColor: colors.white,
    paddingHorizontal: spacing.m1,
    borderRadius: spacing.borderRadius,
    paddingBottom: spacing.m1
  },
  iconShadow,
  shared.debugOff
]

export const checkVisibilityFunction = (fieldVisibilityRules) => (f) => {
  let visibilityRulesState = true

  // the field can have a default visibility parameter of false.
  if (_.get(f, 'field_data.params.visibility', true) === false) {
    visibilityRulesState = false
  } else {
    if (_.get(f, 'field_data.visibility', true) === false) {
      visibilityRulesState = false
    }
  }
  if (fieldVisibilityRules[f.field_data.property] !== undefined) {
    visibilityRulesState = fieldVisibilityRules[f.field_data.property]
  }
  return visibilityRulesState
}
export const GroupContainer = React.forwardRef((props, ref) => {
  const {
    mode,
    entity,
    prepopulatedFields,
    fileAppended,
    fieldChanged,
    definition,
    label,
    fieldVisibilityRules = []
  } = props

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )
  // console.log(props.data.field.field_data.type)
  // props.data.field.field_data.params.sourceName
  const containerType = props.data.field.field_data.type
  const myEntityType = definition
  const visibility = props.hasVisibilityRules

  const fieldIds = _.get(
    props,
    'data.field.field_data.params.fieldList',
    []
  ).map((f) => f.offline_id ?? f.id)
  const fields = _.get(myEntityType, 'fields', [])
    .filter((f) => fieldIds.includes(f.id) || fieldIds.includes(f.offline_id))
    .filter(checkVisibilityFunction(fieldVisibilityRules))

  const fieldsSorted = _.sortBy(fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  const useRowHeader = _.get(
    props,
    'data.field.field_data.params.useRowHeader',
    false
  )

  const fieldTypes = fields.map((field) => ({
    id: field.id,
    type: field.field_data.type
  }))

  return (
    <View style={{ flexDirection: 'column' }}>
      <View style={groupContainerStyle}>
        {fieldTypes.type != 'container-row' && (
          <MSFESHeading
            style={{
              backgroundColor: 'transparent',
              paddingTop: spacing.m2,
              paddingHorizontal: spacing.m1
            }}
          >
            {(label ?? '').toUpperCase()}
            {visibility && (
              <Icon
                name="visibility"
                type="material"
                color="#aeaeae"
                iconStyle={{
                  fontFamily: 'FontAwesome5Free-Regular',
                  fontWeight: 'normal',
                  marginLeft: 3,
                  paddingTop: 4
                }}
                size={14}
              />
            )}
          </MSFESHeading>
        )}
        <View
          style={[
            containerType === 'container-row' && {
              flexDirection: 'row',
              flex: 1
            },
            shared.debugOff
          ]}
        >
          {fieldsSorted.map((field, index) => {
            const errorMessage = _.get(
              errors,
              field.field_data.property,
              []
            ).join(',')

            let renderStaticHeader = getRenderStaticHeader(
              field,
              index,
              fieldsSorted
            )

            return (
              <EntityField
                horizontal
                style={{ marginRight: spacing.m0_5 }}
                showLabel={!useRowHeader}
                displayIcon={false} // hide in row mode.
                mode={mode}
                entity={entity}
                renderStaticHeader={renderStaticHeader}
                name={field.field_data.property}
                prepopulatedFieldValue={
                  prepopulatedFields
                    ? prepopulatedFields[field.field_data.property]
                    : undefined
                }
                definition={definition}
                errorMessage={errorMessage}
                field={field}
                fileAppended={fileAppended}
                fieldChanged={fieldChanged}
                key={index}
              />
            )
          })}
        </View>
      </View>
    </View>
  )
})
