import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import { DataTable } from 'react-native-paper'
import {
  cellStyle,
  headerCellStyle
} from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import CurrentlyNoItems from '../CurrentlyNoItems'
import OverlayHeader from '../OverlayHeader'
import { EvaluateActionTruth } from './EvaluateActionTruth'
import { ActionTruthRunnerButton } from './ActionTruthRunnerButton'
import spacing from '../../styles/spacing'

export const ActionTruthPanel = ({ entity, entityType }) => {
  return (
    <ScrollView>
      <View
        style={{
          maxHeight: 800,
          width: 800,
          position: 'relative'
        }}
      >
        <OverlayHeader>Entity Actions</OverlayHeader>
        {!entityType?.actions?.length && (
          <CurrentlyNoItems label="No actions for this entity type" />
        )}
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title
              key={'name'}
              style={[
                headerCellStyle,
                { justifyContent: 'left', alignItems: 'left' }
              ]}
            >
              Action Name
            </DataTable.Title>
            <DataTable.Title key={'enabled'} style={headerCellStyle}>
              Enabled
            </DataTable.Title>
            <DataTable.Title key={'evaluates'} style={headerCellStyle}>
              Evaluates to
            </DataTable.Title>
            <DataTable.Title key={'actions'} style={headerCellStyle}>
              Actions
            </DataTable.Title>
          </DataTable.Header>
          {entityType?.actions?.map((action, rowIndex) => {
            return (
              <DataTable.Row
                key={rowIndex}
                style={{
                  backgroundColor: rowIndex % 2 ? 'transparent' : '#f1f1f1'
                }}
              >
                <DataTable.Cell
                  key={'name'}
                  style={[
                    cellStyle,
                    {
                      justifyContent: 'left',
                      alignItems: 'left',
                      paddingTop: spacing.m3,
                      paddingLeft: spacing.m0
                    }
                  ]}
                >
                  <Text>{action?.name}</Text>
                </DataTable.Cell>
                <DataTable.Cell key={'enabled'} style={cellStyle}>
                  <Text>{action?.enabled ? 'true' : 'false'}</Text>
                </DataTable.Cell>
                <DataTable.Cell key={'evaluates_to'} style={cellStyle}>
                  <EvaluateActionTruth
                    entity={entity}
                    entityType={entityType}
                    action={action}
                  />
                </DataTable.Cell>
                <DataTable.Cell key={'force_run'} style={cellStyle}>
                  <ActionTruthRunnerButton
                    entity={entity}
                    action={action}
                    entityType={entityType}
                  />
                </DataTable.Cell>
              </DataTable.Row>
            )
          })}
        </DataTable>
      </View>
    </ScrollView>
  )
}
