import React, { useEffect, useMemo, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'

import WithHeader from './WithHeader'
import shared from '../styles/shared'
import { sortListWithEntityType } from '../libraries/entityTools'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import entityActions, { getFilterString } from '../store/entity/actions'
import MSFESButton from './MSFESButton'
import spacing from '../styles/spacing'
import { TreeCategoriesMatrix } from './categories/TreeCategoriesMatrix'
import { useGetWidgetRenderBuckets } from '../hooks/useGetWidgetRenderBuckets'
import { WidgetRenderBucket } from './WidgetRenderBucket'
import { TENANT_LOGIC_BLOCK_ENTITY_TYPE } from '../libraries/supportedEntityTypeProperties'

import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

export const noData = { data: [], meta: null, hasSeenData: false }

export const getSortedEntitiesFromStore = (entityType, state) => {
  const args = { detailed: true },
    filter = {}
  const filterString = sanitiseFilterString(getFilterString(filter, args))
  const entitiesFromStore =
    entityType &&
    _.get(state.entities, `[${entityType.name + filterString}]`, noData)

  if (entitiesFromStore) {
    entitiesFromStore.data = sortListWithEntityType(
      entitiesFromStore.data,
      entityType
    )

    return entitiesFromStore
  }

  return null
}

export default function HomeContainer({ navigation }) {
  const dispatch = useDispatch()

  const args = { detailed: true, per_page: 240, filter: {} }

  const { entityType: entityTypeCategories } = useDiscoverEntityEntityType(
    null,
    null,
    'home_screen_category'
  )
  const { entityType: entityTypeTreeCategories } = useDiscoverEntityEntityType(
    null,
    null,
    'tree_category'
  )
  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  /* Do not remove, forces a  preload */

  /* eslint-disable-next-line no-unused-vars */
  const { entityType: personnelEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    'personnel'
  )

  useEffect(() => {
    dispatch(
      entityActions.listEntity(
        { name: 'home_screen_category' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    )
    dispatch(
      entityActions.listEntity(
        { name: 'tenant' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    )
    dispatch(
      entityActions.listEntity(
        { name: 'system_dashboard' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    )

    dispatch(
      entityActions.listEntity(
        { name: 'tree_category' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    ).then(async (response) => {
      let responseNext = response

      while (responseNext.meta.current_page < responseNext.meta.last_page) {
        responseNext = await dispatch(
          entityActions.listEntity(
            { name: 'tree_category' },
            {
              ...args,
              page: responseNext.meta.current_page + 1
            }
          )
        )
      }
    })

    dispatch(
      entityActions.listEntity(TENANT_LOGIC_BLOCK_ENTITY_TYPE, {
        ...args,
        isFullRefresh: true
      })
    ).then(async (response) => {
      let responseNext = response

      while (responseNext.meta.current_page < responseNext.meta.last_page) {
        responseNext = await dispatch(
          entityActions.listEntity(TENANT_LOGIC_BLOCK_ENTITY_TYPE, {
            ...args,
            page: responseNext.meta.current_page + 1
          })
        )
      }
    })
    dispatch(
      entityActions.listEntity(
        { name: 'screen_button' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    ).then(async (response) => {
      let responseNext = response

      while (responseNext.meta.current_page < responseNext.meta.last_page) {
        responseNext = await dispatch(
          entityActions.listEntity(
            { name: 'screen_button' },
            {
              ...args,
              page: responseNext.meta.current_page + 1
            }
          )
        )
      }
    })
    dispatch(
      entityActions.listEntity(
        { name: 'widget' },
        {
          ...args,
          isFullRefresh: true
        }
      )
    ).then(async (response) => {
      let responseNext = response

      while (responseNext.meta.current_page < responseNext.meta.last_page) {
        responseNext = await dispatch(
          entityActions.listEntity(
            { name: 'widget' },
            {
              ...args,
              page: responseNext.meta.current_page + 1
            }
          )
        )
      }
    })
  }, [entityTypeCategories, entityTypeScreenButtons])

  const { treeCategoriesListWrapper, screenButtonsWrapper } = useSelector(
    (state) => {
      const treeCategoriesListWrapper = getSortedEntitiesFromStore(
        entityTypeTreeCategories,
        state
      )
      const categoriesListWrapper = getSortedEntitiesFromStore(
        entityTypeCategories,
        state
      )

      const screenButtonsWrapper = getSortedEntitiesFromStore(
        entityTypeScreenButtons,
        state
      )

      return {
        categoriesListWrapper,
        treeCategoriesListWrapper,
        screenButtonsWrapper
      }
    }
  )

  const homeScreenButtons = useMemo(() => {
    const dataArray = screenButtonsWrapper
      ? (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
          (r) => _.get(r, 'position.label', null) === 'Home Screen'
        )
      : []

    return dataArray
  }, [screenButtonsWrapper])

  const [scrollHeight, setScrollHeight] = useState(0)

  const { renderBuckets } = useGetWidgetRenderBuckets('home_screen')

  return (
    <>
      <WithHeader title="" navigation={navigation}>
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            paddingBottom: 10
          }}
        >
          <View style={[{ flexDirection: 'column', flex: 1 }]}>
            <View style={[shared.screenView, { flex: 1 }, shared.debugOff]}>
              <ScrollView
                style={{ flex: 1 }}
                onLayout={(event) => {
                  setScrollHeight(event.nativeEvent.layout.height)
                }}
                contentContainerStyle={{
                  height: scrollHeight ? scrollHeight : '100%'
                }}
              >
                <View style={{ flex: 1 }}>
                  {!!treeCategoriesListWrapper && (
                    <View style={{ flex: 1 }}>
                      {/* <Divider /> */}
                      <TreeCategoriesMatrix
                        parentId={
                          treeCategoriesListWrapper.data?.find(
                            (t) => t.category_name === 'Home'
                          )?.id
                        }
                        isHome={true}
                      />
                    </View>
                  )}
                </View>
                <View
                  style={{
                    marginTop: spacing.m1,
                    marginHorizontal: spacing.m2
                  }}
                >
                  {homeScreenButtons.map((homeScreenButton, index) => (
                    <View style={{ maxWidth: 360 }} key={index}>
                      <MSFESButton
                        to={homeScreenButton.navigate_to}
                        key={index}
                        icon={{
                          name: homeScreenButton.icon,
                          type: 'font-awesome'
                        }}
                        title={homeScreenButton.name}
                      />
                    </View>
                  ))}
                </View>
              </ScrollView>
            </View>
            <View>
              {renderBuckets.map((renderBucket, bucketIndex) => {
                return (
                  <WidgetRenderBucket bucket={renderBucket} key={bucketIndex} />
                )
              })}
            </View>
          </View>
        </View>
      </WithHeader>
    </>
  )
}
