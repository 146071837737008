import { useSelector } from 'react-redux'
import useBetterLinkTo from '../useBetterLinkTo'
import { useCallback } from 'react'
import { getAddUrl } from '../store/entity/actions'
import { openExternalLink } from '../helpers/link'
export const useActionNavigateTo = () => {
  const { entityTypes } = useSelector((appState) => {
    const entityTypes = appState?.entityTypes?.dataLite?.data ?? []

    return { entityTypes }
  })

  const linkTo = useBetterLinkTo()

  const actionNavigateTo = useCallback(
    (navigateTo) => {
      if (typeof navigateTo === 'string') {
        linkTo(navigateTo)
      } else {
        switch (navigateTo?.linkToType) {
          case 'create-entity':
            {
              const entityType = entityTypes.find(
                (e) => e.name == navigateTo?.sourceName
              )
              linkTo(
                getAddUrl(
                  entityType,
                  false,
                  '/entity-list/?type=' + entityType?.name
                )
              )
            }

            break
          case 'view-entity-list':
            {
              const entityType = entityTypes.find(
                (e) => e.name == navigateTo?.sourceName
              )
              linkTo('/entity-list/?type=' + entityType?.name)
            }

            break
          case 'view-report':
            linkTo('/settings/report/' + navigateTo.reportSource?.id, false)
            break
          case 'url':
            openExternalLink(navigateTo?.url)
            break
        }
      }
    },
    [linkTo, getAddUrl, openExternalLink, entityTypes]
  )

  return { actionNavigateTo }
}
