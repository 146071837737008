import React, { useState } from 'react'
import { View, ScrollView } from 'react-native'

import WithHeader from './WithHeader'
import shared from '../styles/shared'
import { TopePanelDashboard } from './TopPanelDashboard'
import { RightPanelDashboard } from './RightPanelDashboard'
import { useSelector } from 'react-redux'
import { getSortedEntitiesFromStore } from './HomeContainer'
import { DashBoardContext } from '../contexts/DashboardContext'
import DashboardCanvas from './DashboardCanvas'
import spacing from '../styles/spacing'

export const noData = { data: [], meta: null, hasSeenData: false }

export default function HomeContainer({ navigation }) {
  const dashboardEntityType = {
    name: 'system_dashboard'
  }
  const state = useSelector((state) => state)
  const dashboards = getSortedEntitiesFromStore(dashboardEntityType, state)

  const [currentDashboard, setCurrentDashboard] = useState(0)

  return (
    <DashBoardContext.Provider
      value={{ currentDashboard, setCurrentDashboard }}
    >
      <WithHeader title="" navigation={navigation}>
        <TopePanelDashboard dashboards={dashboards}></TopePanelDashboard>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingBottom: spacing.m1_5
          }}
        >
          <View style={[{ flexDirection: 'column', flex: 1 }]}>
            <View style={[shared.screenView, { flex: 1 }, shared.debugOff]}>
              <ScrollView style={{ flex: 1 }}>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'column'
                  }}
                >
                  <DashboardCanvas></DashboardCanvas>
                </View>
              </ScrollView>
            </View>
          </View>
          <RightPanelDashboard></RightPanelDashboard>
        </View>
      </WithHeader>
    </DashBoardContext.Provider>
  )
}
