import React, { useMemo, useEffect, useState } from 'react'
import { View } from 'react-native'
import { ListItemButton } from './ListItemButton'
import { useSelector } from 'react-redux'
import { getIconOrImage, getImage } from './GridItemEntity'
import Mustache from 'mustache'
import { flattenEntity } from '../helpers/entity'
import {
  getColor,
  getTimeFromTimestamp
} from '../screens/CalendarViewScreenHelpers/CalendarEntityList'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { useColorContext } from '../contexts/ColorContext'
import colors from '../styles/colors'

export const getSubtitle = (entity, entityType) => {
  const start_time = getTimeFromTimestamp(
    _.get(
      entity,
      entityType?.object_data?.start_datetime_field?.field_data?.property,
      ''
    ) ||
      _.get(
        entity?.object_data,
        entityType?.object_data?.start_datetime_field?.field_data?.property,
        ''
      )
  )
  const end_time = getTimeFromTimestamp(
    _.get(
      entity,
      entityType?.object_data?.end_datetime_field?.field_data?.property,
      ''
    ) ||
      _.get(
        entity?.object_data,
        entityType?.object_data?.end_datetime_field?.field_data?.property,
        ''
      )
  )

  if (end_time) {
    return `${start_time} - ${end_time}`
  }
  if (start_time) {
    return `${start_time}`
  }

  const subtitleValue = Mustache.render(
    `${entityType?.object_data?.subtitle ?? ''}`,
    {
      ...flattenEntity(entity, false)
    }
  )

  if (subtitleValue) {
    return subtitleValue
  }
  return ''
}
export const ListItemEntity = ({
  isDashboard,
  entity,
  entityType = null,
  alarmColor,
  entityTypeId = null,
  onPress,
  active = false
}) => {
  const { entityTypes } = useSelector((state) => ({
    entityTypes: state.entityTypes?.data ?? null
  }))
  const { setColorContext } = useColorContext()
  const startDateTimeField =
    entity?.object_data?.Start_datetime_field?.dateObject

  let formattedDate = ''

  const dateObject = { startDateTimeField }

  if (startDateTimeField) {
    const parsedDate = DateTime.fromISO(dateObject.startDateTimeField)
    formattedDate = parsedDate.toFormat('dd LLL yyyy  /  hh:mma').toUpperCase()
  }

  const resolvedEntityType = useMemo(() => {
    if (entityType) {
      return entityType
    }
    if (entityTypeId) {
      return entityTypes.find((e) => e.id == entityTypeId)
    }
  }, [entityTypes, entityType, entityTypeId])

  const iconOrImage = useMemo(() => {
    return getIconOrImage(entity, resolvedEntityType)
  }, [entity, resolvedEntityType])

  const image = useMemo(() => {
    return getImage(iconOrImage)
  }, [iconOrImage])
  const [dashboardEntities, setDashboardEntities] = useState([])
  const [hasSetValidEntity, setHasSetValidEntity] = useState(false)
  const color = getColor(entity, resolvedEntityType)

  const subtitle = getSubtitle(entity, resolvedEntityType)

  useEffect(() => {
    if (onPress) {
      setDashboardEntities([])
      setColorContext({ entity: '', entityType: '', color: colors.lightGray })
      setHasSetValidEntity(false)
    }
    if (isDashboard && entity) {
      setDashboardEntities((prev) => [...prev, entity])
    }
  }, [isDashboard, entity])

  useMemo(() => {
    if (hasSetValidEntity) {
      return
    }
    //this returns the last value entity instead. Needs refactor/fix, Should store entities in dashboardEntities in a list/array and filters the first valid entity with a valid color based off getColor(). If it comes back undefined its not valid.
    const firstValidEntity = dashboardEntities.find((e) => {
      const entityType = resolvedEntityType
      if (!entityType) return false
      const color = getColor(e, entityType)
      return color !== undefined
    })

    if (firstValidEntity) {
      const entityType = entityTypes.find(
        (type) => type.id === firstValidEntity.entity_type_id
      )
      const newColor = getColor(firstValidEntity, entityType)
      setColorContext((prev) => ({
        ...prev,
        entity: firstValidEntity,
        entityType,
        color: newColor
      }))
      setHasSetValidEntity(true)
    }
  }, [
    dashboardEntities,
    entityTypes,
    setColorContext,
    hasSetValidEntity,
    dashboardEntities.length
  ])

  return (
    <View>
      <ListItemButton
        color={color}
        formattedDate={formattedDate}
        alarmColor={alarmColor}
        image={image}
        accentColor={color}
        isGeneric={!entity.tenant_id}
        isDeleted={!!entity.deleted_at}
        isVirtual={entity.virtual}
        title={entity.display_name}
        subtitle={subtitle}
        active={active}
        linkTo={entity?.uri}
        onPress={onPress}
      />
    </View>
  )
}
