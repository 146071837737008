import { DateTime } from 'luxon'
const defaultState = {
    notifications: {},
    unread_notifications: {},
    errors: {
        errors: null,
        hasError: false
    }
}

export default function tenants(state = defaultState, action) {
    switch (action.type) {
        case 'notification/unread-list-started':
            {
                state = {
                    ...state,
                    notifications: {
                        ...state.notifications,
                        lastRetrieveStarted: DateTime.local()
                    }
                }
            }

            break
        case 'notification/unread-list-success':
            {
                const { response } = action

                state = {
                    ...state,
                    ...{
                        unread_notifications: {
                            ...state.unread_notifications,
                            data: response.data,
                            meta: response.meta,
                            links: response.links,
                            lastRetrieveFinished: DateTime.local()
                        }
                    }
                }
            }

            break
        case 'notification/upsert-started':
        case 'notification/upsert-success':
            state.errors = {
                ...state.errors,
                ...{ hasError: false, message: null, statusCode: null, errors: null }
            }
            break

        case 'notification/upsert-error':
            state.errors = {
                errors: action.error.errors,
                message: action.error.message,
                statusCode: action.error.statusCode,
                hasError: true
            }
            break
        case 'notification/clear-general-error':
            state.errors = {
                ...state.errors,
                ...{ message: null, statusCode: null }
            }
            break
    }

    return state
}
