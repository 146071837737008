import api from '../../data/api'

const promiseRepository = {}
const markAsRead = ({ id }) => {
  return () => {
    const route = `/notifications/${id}/read`

    return api.post(route)
  }
}

export const markBatchAsRead = ({ ids }) => {
  return () => {
    const route = `/notifications/batch-read`

    return api.post(route, { notification_ids: ids })
  }
}
const listUnreadNotifications = () => {
  return (dispatch) => {
    const route = '/notifications/unread'

    if (promiseRepository[route]) {
      return promiseRepository[route]
    }

    dispatch({
      type: 'notification/unread-list-started'
    })
    const p = api
      .get(route)
      .then(async (response) => {
        delete promiseRepository[route]
        await dispatch({
          type: 'notification/unread-list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'notification/unread-list-error',
          error
        })

        console.error(error)
        // throw error
      })

    promiseRepository[route] = p

    return p
  }
}
const listNotifications = () => {
  return (dispatch) => {
    const route = '/notifications'

    dispatch({
      type: 'notification/list-started'
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'notification/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'notification/list-error',
          error
        })

        // throw error
      })
  }
}
export default { listNotifications, listUnreadNotifications, markAsRead }
