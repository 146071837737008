// libraries

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'
import _ from 'lodash'
// internal libraries
import {
  getDisplayLabelForEntity
} from '../libraries/entityTools'

//actions
import entityActions from '../store/entity/actions'
import fileActions from '../store/file/actions'

// styles
import shared from '../styles/shared'

// components

import { websafeGoBack } from '../libraries/navigation'
import { showMessage } from 'react-native-flash-message'
import WithHeader from '../components/WithHeader'
import { KeyboardAvoidingView } from 'react-native'
import { ListItemLinkButton } from '../components/ListItemLinkButton'
import MSFESLabel from '../components/MSFESLabel'
import grid from '../styles/grid'
import GlobalLoading from '../components/GlobalLoading'
import useQueryString from '../libraries/querystring'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import EntityField from '../components/EntityField'
import ListItemAsFieldProps from '../components/ListItemAsFieldProps'

export default function DocumentViewScreen(props) {
  const { route, navigation } = props;
  const dispatch = useDispatch()



  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )


  const addMode = 'add'
  const updateMode = 'edit'
  const viewMode = 'view'

  const [mode, setMode] = useQueryString('mode', route.params.id ? viewMode : addMode)

  const [hasChanges, setHasChanges] = useState(false)

  const [entityType, setEntityType] = useState(null);
  const [pageHeading, setPageHeading] = useState('');

  const [file, updateLocalFile] = useState(null)


  useEffect(() => {
    const title = file?.original_name

    setPageHeading(title)
    navigation.setOptions({ title })

  }, [file, route.params.id])

  const [fileLoading, setFileLoading] = useState(false);

  const fetchAndUpdateLocalFile = (id) => {
    return dispatch(fileActions.showFile(id)).then(
      (response) => {
        updateLocalFile(response.data)

        setFileLoading(false)
      }
    )
  }
  useEffect(() => {
    if (route.params.id) {
      const { id } = route.params
      setFileLoading(true)
      fetchAndUpdateLocalFile(id);
    }

  }, [route.params.id, current_user.current_tenant])

  const fileChanged = (newFile, isUserInitiated) => {

    if (isUserInitiated) {
      setHasChanges(true)

    }
    updateLocalFile((file) => {
      return {
        ...file,
        ...newFile
      }
    })
  }

  const saveFile = () => {
    setFileLoading(true)
    return dispatch(entityActions.upsertEntity(file, entityType, mode))
      .then((response) => {
        const file = response.data
        const { id } = file

        showMessage({ message: 'Updated Successfully', type: 'success' })

        setHasChanges(false)

        return response
        // navigation.replace(`EntityList`, { type: entityType.name })
      })
      .catch((errors) => {
        console.warn("Save File Errors", errors)
        throw errors
      })
      .finally(() => {
        setFileLoading(false)
      })
  }

  const [overlayState, setOverlayState] = useState(false)
  const toggleExtraSettingsOverlay = () => {
    setOverlayState(!overlayState);
  }
  

  const noActionEditing = () => {
    setFileLoading(true)
    setSaveActionPending(false)
    fetchAndUpdateLocalFile(file.id).then(() => {
      setMode(viewMode)
      setFileLoading(false)

    })
  }

  const noActionBackButton = () => {
    setSaveActionPending(false)
    websafeGoBack(navigation)
  }

  const yesActionEditing = () => {
    setSaveActionPending(false)
    saveFile().then(() => {
      setMode(viewMode)
    }).catch(e => {

    })
  }

  const yesActionBackButton = () => {
    setSaveActionPending(false)
    saveFile().then(() => {
      websafeGoBack(navigation)
    }).catch(e => {

    })
  }

  const [saveActionPending, setSaveActionPending] = useState(false)
  return (

    file &&
    <WithHeader hasTabNavigatorPadding={false} title={pageHeading} navigation={navigation} leftButtonType="back" >

      <View style={[grid.padding.viewPort]}>
        {file.entity && <View>

          <MSFESLabel label={"Belongs To"} />
          <ListItemLinkButton
          {...ListItemAsFieldProps}
            entity={file.entity}
            title={getDisplayLabelForEntity(file.entity)}
            key={file.entity.id}
            to={file.entity.uri}

          /></View>}

        <EntityField
          mode={mode}
          entity={file}
          name={""}
          errorMessage={''}
          field={{ title: "File", field_data: { property: "id", type: 'single-file' } }}
        />

        <EntityField
          mode={mode}
          entity={file}
          name={"created_at"}
          errorMessage={''}
          field={{ title: "Upload Date", field_data: { property: "created_at", type: 'date-picker' } }}
        />

        <EntityField
          mode={mode}
          entity={file}
          name={"size_bytes"}
          errorMessage={''}
          field={{ title: "File Size (Bytes)", field_data: { property: "size_bytes", type: 'number-entry' } }}
        />

      </View>

      {/* {(saveActionPending) && <YesNoCancelDialog
        title="Unsaved Changes"
        body="Changes have been made. Would you like to save first?"
        yesAction={saveActionPending === "backPressed" ? yesActionBackButton : yesActionEditing}
        noAction={saveActionPending === "backPressed" ? noActionBackButton : noActionEditing}
        cancelAction={() => {
          setSaveActionPending(false)
        }} />} */}
      <KeyboardAvoidingView behavior="padding" style={[shared.debugOff, { flex: 1, flexDirection: 'column', justifyContent: 'center' }]} keyboardVerticalOffset={120}>

      </KeyboardAvoidingView>
      {fileLoading && <GlobalLoading />}
    </WithHeader>
  )
}
