import React, { useState } from 'react'
import { View } from 'react-native'
import MSFESLabel from './MSFESLabel'
import MSFESButton from './MSFESButton'
import colors from '../styles/colors'
import spacing from '../styles/spacing'
import YesNoCancelDialog from './YesNoCancelDialog'

export const CommitPendingApprovalsBar = ({
  pendingApprovals,
  approveChanges,
  rejectChanges
}) => {
  const [rejectActionPending, setRejectActionPending] = useState(false)

  const onRejectChangesPressed = () => {
    setRejectActionPending(true)
  }

  const rejectCancelPressed = () => {
    setRejectActionPending(false)
  }

  const onRejectYesPressed = () => {
    setRejectActionPending(false)
    rejectChanges()
  }

  return pendingApprovals?.length ? (
    <View
      style={{
        flexDirection: 'row',
        padding: spacing.m1,
        backgroundColor: colors.approvalBackground
      }}
    >
      <View style={{ flex: 1 }}>
        <MSFESLabel
          label={`${pendingApprovals.length} Approval${
            pendingApprovals.length !== 1 ? 's' : ''
          } `}
        />
      </View>
      <MSFESButton
        title={'Reject'}
        modifier="outline"
        onPress={onRejectChangesPressed}
        style={{ marginRight: spacing.m2 }}
      />
      <MSFESButton title={'Approve'} onPress={approveChanges} />

      {rejectActionPending && (
        <YesNoCancelDialog
          title="Reject Changes"
          body="Are you sure you want to reject the selected changes? This is a permanent action."
          yesAction={onRejectYesPressed}
          noAction={rejectCancelPressed}
          cancelAction={rejectCancelPressed}
        />
      )}
    </View>
  ) : null
}
