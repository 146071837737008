import { StyleSheet } from 'react-native'
import colors from './colors';

const sheet = StyleSheet.create({
  accordionViewStyle: {
    backgroundColor: colors.lightestGray,
    borderBottomColor: colors.white,
    borderBottomWidth: StyleSheet.hairlineWidth
  }
})

export default sheet;