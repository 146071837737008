// libraries
import React from 'react'
import { Overlay } from 'react-native-elements'
import { View, Platform } from 'react-native'
// styles
import spacing from '../styles/spacing'

//actions
import OverlayHeader from './OverlayHeader'

// components

export const MSFESModal = ({ children, title, onClosePressed, style }) => {
  return (
    <Overlay isVisible={true} onBackdropPress={onClosePressed}>
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            padding: spacing.m1,
            minHeight: Platform.OS === 'ios' ? 330 : 140,
            ...style
          }}
        >
          <OverlayHeader>{title}</OverlayHeader>

          {children}
        </View>
      </View>
    </Overlay>
  )
}

export default MSFESModal
