import React, { useState, useCallback, useEffect } from "react";
import _ from 'lodash'
import {
  ListItem,
} from 'react-native-elements'
import { View, Text, FlatList } from 'react-native'
import { Overlay } from "react-native-elements/dist/overlay/Overlay";
import OverlayHeader from "../OverlayHeader";
import ListItemAsFieldProps, { InputSelectStyle } from "../ListItemAsFieldProps";

export const StatusPicker = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    style,
    editable,
    dataRows,
  } = props

  const [visible, setVisible] = useState(false)
  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const _onChange = (args) => {
    onDismiss();
    onChange(args)
  }


  const onPressAction = editable ? () => setVisible(true) : null

  const labelField = "metadata.title"
  const getLabel = useCallback((item, labelField) => {
    return _.get(item, labelField, null);
  });
  return (<View style={style}>
    <ListItem onPress={onPressAction} disabled={!editable} containerStyle={ListItemAsFieldProps.containerStyle} {...ListItemAsFieldProps.touchableProps}>
      <ListItem.Content>
        <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>{value}</ListItem.Title>
      </ListItem.Content>
      {editable && <ListItem.Chevron type="font-awesome" name="chevron-down" size={14} color={"black"} />}
    </ListItem>
    <Overlay isVisible={visible} onBackdropPress={onDismiss}>
      <OverlayHeader>Change Status To:</OverlayHeader>

      <FlatList style={{ maxHeight: 400, minWidth: 200 }} keyExtractor={(item) => {
        const key = item.key
        return key
      }
      } data={dataRows} renderItem={({ item, index }) => {

        return <ListItem key={index.toString()} onPress={() => _onChange(item)} >
          {item.image && <MSFESAvatar showLabel={false} rounded={false} size={96} source={{ uri: item.image }} />}
          {item?.icon && <MSFESAvatar showLabel={false} icon={{ name: item[iconField], type: 'font-awesome' }} />}
          <ListItem.Content>

            <ListItem.Title>
              {getLabel(item, labelField)}</ListItem.Title></ListItem.Content>
        </ListItem>
      }}></FlatList>

      <View>
      </View>
    </Overlay>

  </View>)
});