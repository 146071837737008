import React, { useContext, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import colors from '../styles/colors'
import MSFESHeading from './MSFESHeading'
import seedrandom from 'seedrandom'
import EntityEditQuizIntroBlock from './EntityEditQuizIntroBlock'
import EntityEditContext from '../contexts/EntityEditContext'
import EntityEditQuizInProgressBlock from './EntityEditQuizInProgressBlock'
import useQuizTiming, {
  getFinishObjectFromStartedAtAndQuizTimeLimit,
  getMinutesRemaining,
  useBeginQuiz
} from '../hooks/useQuizTiming'
import EntityEditQuizFinishBlock from './EntityEditQuizFinishBlock'
import EntityEditQuizIsFinishedBlock from './EntityEditQuizIsFinishedBlock'

export const getOtherActiveQuizzes = (entity, definition, thisField) => {
  const otherQuizFields = definition.fields
    .filter((f) => f.field_data?.type == 'quiz-question-group')
    .filter((f) => f.offline_id !== thisField.offline_id)

  let quizFieldsOpen = []

  otherQuizFields.forEach((f) => {
    const value = entity[f.field_data?.property]

    if (value) {
      if (value.started_at?.value) {
        // it's started
        if (!value.finished_at?.value) {
          // it's not officially finished yet.
          // if it has not timed out, this quiz is open

          const finishObject = getFinishObjectFromStartedAtAndQuizTimeLimit(
            value.started_at,
            f.field_data?.params.quizTimeLimit
          )
          const minutesRemaining = getMinutesRemaining(finishObject)

          if (minutesRemaining > 0) {
            quizFieldsOpen.push(f)
          }
        }
      }
    }
  })

  return quizFieldsOpen
}
export const QuizQuestionGroupContainer = React.forwardRef((props, ref) => {
  const [randomSeed, setRandomSeed] = useState(null)

  useEffect(() => {
    setRandomSeed(props.data?.entity?.offline_id)
  }, [])
  const {
    mode,
    entity,
    disabled,
    prepopulatedFields,
    fileAppended,
    fieldChanged,
    definition,
    label
  } = props

  const { errors } = useSelector((appState) => {
    return {
      errors: appState.entities?.errors?.errors
    }
  })

  const myEntityType = definition

  const { randomize_questions, quiz_number_of_questions } = useMemo(() => {
    const randomize_questions =
      props.data?.field?.field_data?.params?.randomize_questions
    const quiz_number_of_questions =
      props.data?.field?.field_data?.params?.quiz_number_of_questions

    return { randomize_questions, quiz_number_of_questions }
  })
  const fieldsSorted = useMemo(() => {
    const myrng = new seedrandom(randomSeed)

    const fieldIds = _.get(
      props,
      'data.field.field_data.params.fieldList',
      []
    ).map((f) => f.offline_id ?? f.id)
    const fields = _.get(myEntityType, 'fields', []).filter(
      (f) => fieldIds.includes(f.id) || fieldIds.includes(f.offline_id)
    )
    const fieldsSorted = _.sortBy(fields, (f) =>
      randomize_questions ? myrng() : _.get(f, 'field_data.sort_order', 999)
    )

    if (quiz_number_of_questions > 0) {
      return fieldsSorted.slice(0, quiz_number_of_questions)
    }
    return fieldsSorted
  }, [myEntityType, randomSeed])

  const useRowHeader = useMemo(() => {
    return _.get(props, 'data.field.field_data.params.useRowHeader', false)
  }, [props.data?.field])

  // display start if there is no entity[property].started_at
  // display quiz if there is.

  const canRenderAnyFields = !!props.defaultValue

  const [, , , saveEntity] = useContext(EntityEditContext)

  const [onBeginQuiz, otherActiveQuizzes, onFinishQuiz] = useBeginQuiz(
    props.data?.entity,
    definition,
    props.data?.field,
    props.onChange,
    saveEntity
  )

  const [started_at, finished_at, finishes_at] = useQuizTiming(
    props.data?.entity,
    props.data?.field
  )

  const hasStartedQuiz = started_at?.value
  const hasNotStartedQuiz = !started_at?.value
  const hasFinishedQuiz =
    (hasStartedQuiz && finished_at?.time_remaining_minutes <= 0) ||
    finished_at?.value

  const quizInfo = useMemo(() => {
    const quizInfo = _.get(
      props.data?.entity,
      props.data?.field?.field_data?.property,
      null
    )

    return quizInfo
  }, [props.data?.entity, props.data?.field])

  return (
    <View style={{ flexDirection: 'column' }}>
      <View
        style={[
          {
            marginTop: spacing.m1,
            flex: 1,
            backgroundColor: colors.formInputBackground,
            paddingHorizontal: spacing.m1,
            borderRadius: spacing.borderRadius
          },
          shared.debugOff
        ]}
      >
        <MSFESHeading
          style={{
            backgroundColor: 'transparent',
            paddingVertical: spacing.m2,
            paddingHorizontal: spacing.m1
          }}
        >
          {(label ?? '').toUpperCase()}
        </MSFESHeading>
        {!canRenderAnyFields && hasNotStartedQuiz && (
          <>
            <EntityEditQuizIntroBlock
              field={props.data?.field}
              definition={definition}
              disabled={disabled || otherActiveQuizzes.length > 0}
              onBeginQuiz={onBeginQuiz}
            />
          </>
        )}
        {canRenderAnyFields && hasStartedQuiz && !hasFinishedQuiz && (
          <>
            <EntityEditQuizInProgressBlock
              definition={definition}
              disabled={disabled}
              entity={props.data.entity}
              field={props.data?.field}
              started_at={started_at}
              finish_at={finishes_at}
            />
            <View>
              {fieldsSorted.map((field, index) => {
                const errorMessage = _.get(
                  errors,
                  field.field_data.property,
                  []
                ).join(',')
                return (
                  <EntityField
                    horizontal
                    fieldIndex={index}
                    style={{
                      marginRight: spacing.m0_5,
                      paddingBottom: spacing.m4
                    }}
                    showLabel={!useRowHeader}
                    displayFieldNumberInsteadOfLabel={true}
                    displayIcon={false} // hide in row mode.
                    mode={mode}
                    entity={entity}
                    name={field.field_data.property}
                    prepopulatedFieldValue={
                      prepopulatedFields
                        ? prepopulatedFields[field.field_data.property]
                        : undefined
                    }
                    errorMessage={errorMessage}
                    field={field}
                    fileAppended={fileAppended}
                    fieldChanged={fieldChanged}
                    key={index}
                  />
                )
              })}
            </View>
            {hasStartedQuiz && !hasFinishedQuiz && (
              <EntityEditQuizFinishBlock
                field={props.data?.field}
                definition={definition}
                disabled={disabled}
                onFinishQuiz={onFinishQuiz}
              />
            )}
          </>
        )}

        {hasFinishedQuiz && (
          <EntityEditQuizIsFinishedBlock
            quizInfo={quizInfo}
            disabled={disabled}
            onRetakeQuizPressed={onBeginQuiz}
            field={props.data?.field}
          />
        )}
      </View>
    </View>
  )
})
