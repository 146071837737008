import React, { useMemo, useState } from 'react'
import { Icon } from 'react-native-elements'
import { ListItemButton } from './ListItemButton'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import entityActions from '../store/entity/actions'
import { imageSize } from './ListItemAdd'
import { updateMode } from '../screens/EntityAddComponent'
import { useBetterLinkTo } from '../useBetterLinkTo'

export const getWeekStart = () => {
  return DateTime.now().startOf('week').toISODate()
}

export const getUrlForEntityWithParams = (entityType, params, dispatch) => {
  return dispatch(
    entityActions.viewOrCreateEntity(entityType, { filter: params })
  ).then((response) => {
    return response.data
  })
}

export const ListItemButtonOpenCurrentTimesheet = ({
  title,
  user,
  entityType,
  splitviewNavigate,
  onNavigate: _onNavigate = null
}) => {
  const dispatch = useDispatch()
  const linkTo = useBetterLinkTo()

  const [loading, setLoading] = useState(false)
  const CreateOrOpenEntityWithParams = async (entityType, params) => {
    setLoading(true)

    const newOrCreatedEntity = await getUrlForEntityWithParams(
      entityType,
      params,
      dispatch
    )
    setLoading(false)

    linkTo(newOrCreatedEntity?.uri)
  }

  const onPress = () => {
    const week_start = getWeekStart()

    const params = {
      assignee: user.personnel_record.id,
      personnel: user.personnel_record.id,
      week_start
    }

    const onNavigate =
      _onNavigate ?? ((url) => splitviewNavigate(url, updateMode))
    CreateOrOpenEntityWithParams(entityType, params, onNavigate)
  }

  const image = useMemo(() => {
    return (
      <Icon
        type={'font-awesome'}
        size={imageSize}
        color={fonts.listItemTextStyle.color}
        name={'plus'}
      />
    )
  }, [])

  return (
    <ListItemButton
      loading={loading}
      innerContainerStyle={{ backgroundColor: colors.buttonSecondaryDark }}
      onPress={onPress}
      image={image}
      title={title}
      rightImage={null}
    />
  )
}
