import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { Divider } from 'react-native-paper'
import spacing from '../styles/spacing'
import MSFESButton from './MSFESButton'
import { headerTextStyle } from './WithHeader'

import pluralize from 'pluralize'

const EntityEditQuizIsFinishedBlock = ({
  quizInfo,
  disabled,
  field,
  onRetakeQuizPressed
}) => {
  const passMark = useMemo(() => {
    return field?.field_data?.params?.quiz_pass_mark_percent
  }, [field])

  const didPassQuiz = useMemo(() => {
    return quizInfo?.marking_array?.quizMarksPercent >= passMark
  }, [quizInfo, passMark])

  const attemptsRemainingLabel = useMemo(() => {
    return `${quizInfo?.quiz_attempts_remaining} ${pluralize(
      'attempt',
      quizInfo?.quiz_attempts_remaining
    )} remaining`
  }, [quizInfo])
  return (
    <View>
      <Text style={headerTextStyle}>Quiz complete</Text>
      {quizInfo?.marking_array && (
        <View>
          <Text>
            Score achieved: {quizInfo.marking_array.marksAchieved} /{' '}
            {quizInfo.marking_array.marksPossible} (
            {quizInfo?.marking_array?.quizMarksPercent}%)
          </Text>
          <Text>Pass Mark: {passMark}%</Text>
          <Text>Result: {didPassQuiz ? 'PASS' : 'FAIL'}</Text>
          <Text>Actions: Print Report</Text>

          <Divider style={{ marginVertical: spacing.m2 }} />
          <MSFESButton
            small
            title={`Retake Quiz (${attemptsRemainingLabel})`}
            containerStyle={{ padding: 0, flex: 1 }}
            disabled={disabled || quizInfo?.quiz_attempts_remaining <= 0}
            onPress={onRetakeQuizPressed}
          />
          <Divider style={{ marginVertical: spacing.m2 }} />
        </View>
      )}
    </View>
  )
}

export default EntityEditQuizIsFinishedBlock
