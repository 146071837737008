import React from 'react'
import { View } from "react-native"
import { Chip } from "react-native-paper"
import spacing from '../styles/spacing'


const MSFESChip = ({ children, ...rest}) => {
  return <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginRight: spacing.m1, paddingBottom: 4}}><Chip {...rest}>{children}</Chip></View>
}

export default MSFESChip