import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { DatePickerModal } from 'react-native-paper-dates/src'
import { ListItem, Overlay } from 'react-native-elements'
import { useWindowDimensions, View } from 'react-native'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import { Provider as PaperProvider } from 'react-native-paper'
import { DatePickerTheme } from './DatePicker'
import { TimePicker } from 'react-native-simple-time-picker'
import OverlayHeader from '../OverlayHeader'
import MSFESButton from '../MSFESButton'
import colors from '../../styles/colors'
import CrossFieldReferencesContext from '../../contexts/CrossFieldReferencesContext'
import CrossFieldRenderAsNullContext from '../../contexts/CrossFieldRenderAsNullContext'
import { getNowObject } from './CustomFunctionalityTimesheetPunchCard'
import { useTimeFormat } from '../../contexts/TimeFormatContext'

export const DateTimeToDisplayValue = (dateObject, isTime = false) => {
  const { timeFormat } = useTimeFormat()

  const dateFormat =
    DateTime[timeFormat?.date_format?.label] || DateTime.DATE_SHORT
  const timeFormatString = isTime
    ? DateTime[timeFormat?.time_format?.label] || DateTime.TIME_WITH_SECONDS
    : ''

  if (!dateObject || !dateObject.isValid) {
    console.error(
      'Invalid dateObject passed to DateTimeToDisplayValue:',
      dateObject
    )
    return ''
  }

  const newTime = dateObject.toLocaleString({
    ...dateFormat,
    ...timeFormatString
  })

  return newTime
}
export const TimepickerTimeToPendingDate = (TimepickerTime, pendingDate) => {
  const { timeFormat } = useTimeFormat()
  const { month, day, year } = pendingDate
  let { hours = 0, minutes = 0, seconds = 0, ampm } = TimepickerTime
  const is24HourFormat =
    timeFormat?.time_format?.label === 'TIME_24_WITH_SECONDS'

  if (is24HourFormat) {
    ampm = ''
  } else {
    if (!ampm) {
      ampm = hours < 12 ? 'am' : 'pm'
    }
  }

  const parseDateTime = (
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    ampm,
    is24HourFormat
  ) => {
    const dayDefault = day || '01'
    const monthDefault = month || '01'
    const yearDefault = year || '1970'
    const hoursDefault =
      hours !== undefined ? String(hours).padStart(2, '0') : '12'
    const minutesDefault =
      minutes !== undefined ? String(minutes).padStart(2, '0') : '00'
    const secondsDefault =
      seconds !== undefined ? String(seconds).padStart(2, '0') : '00'

    const dateFormat = is24HourFormat
      ? 'dd/MM/yyyy HH:mm:ss'
      : 'dd/MM/yyyy hh:mm:ss a'

    const dateString = is24HourFormat
      ? `${dayDefault}/${monthDefault}/${yearDefault} ${hoursDefault}:${minutesDefault}:${secondsDefault}`
      : `${dayDefault}/${monthDefault}/${yearDefault} ${hoursDefault}:${minutesDefault}:${secondsDefault} ${ampm}`

    const dateObject = DateTime.fromFormat(dateString, dateFormat, {
      locale: 'en-US'
    })

    if (!dateObject.isValid) {
      console.error('Invalid date:', dateObject.invalidReason)
      return null
    }
    return dateObject
  }

  const dateObject = parseDateTime(
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    ampm,
    is24HourFormat
  )

  if (!dateObject) {
    return null
  }

  const newDate = dateObject.toISO()
  const display_value = DateTimeToDisplayValue(dateObject, true)

  const newPendingDate = {
    dateObject,
    value: newDate,
    hours,
    minutes,
    seconds,
    month,
    day,
    year,
    display_value
  }

  return newPendingDate
}

export const pendingDateToTimepickerTime = (pendingDate, is24HourFormat) => {
  let hours = 12
  let minutes = 0
  let seconds = 0
  let ampm = 'am'

  if (pendingDate?.display_value) {
    const { dateObject } = pendingDate

    if (!is24HourFormat) {
      // 12-hour format
      try {
        hours = parseInt(dateObject.toFormat('h'))
        minutes = parseInt(dateObject.toFormat('mm'))
        seconds = parseInt(dateObject.toFormat('ss'))
        ampm = dateObject.toFormat('a').toLowerCase()
      } catch (e) {
        console.error(
          'Error in converting pendingDate via pendingDateToTimepickerTime() to 12-hour time picker format:',
          e
        )
      }
    } else {
      // 24-hour format
      try {
        hours = parseInt(pendingDate.hours || dateObject.toFormat('H'))
        minutes = parseInt(dateObject.toFormat('mm'))
        seconds = parseInt(dateObject.toFormat('ss'))
      } catch (e) {
        console.error(
          'Error in converting pendingDate via pendingDateToTimepickerTime() to 24-hour time picker format:',
          e
        )
      }
    }
  }

  const retval = { hours, minutes, seconds, ampm }
  return retval
}

export const TimePickerOverlay = (props) => {
  const dimensions = useWindowDimensions()
  const minWidth = dimensions.width < 400 ? '100%' : 330

  const { timeFormat } = useTimeFormat()
  const isLabelDefined = timeFormat?.time_format?.label != null

  const isAmpm =
    !isLabelDefined || timeFormat.time_format.label !== 'TIME_24_WITH_SECONDS'

  const ampmLocalization = isAmpm ? { am: 'AM', pm: 'PM' } : undefined

  const { value, onChange, onSave, onDismiss } = props

  return (
    <Overlay onBackdropPress={onDismiss}>
      <View style={{ minWidth, minHeight: 240 }}>
        <OverlayHeader>Select Time</OverlayHeader>
        <View>
          <TimePicker
            value={value}
            onChange={onChange}
            style={{ fontSize: 11 }}
            itemStyle={{ fontSize: 11, textAlign: 'center' }}
            secondsInterval={1}
            zeroPadding={true}
            pickerShows={['hours', 'minutes', 'seconds']}
            isAmpm={isAmpm}
            ampmLocalization={ampmLocalization}
          />
        </View>
        <MSFESButton title={'Save'} onPress={onSave} />
      </View>
    </Overlay>
  )
}

export const getDateTimeFormatForTimeFormat = (timeFormat) => {
  const is24HourFormat =
    timeFormat?.time_format?.label === 'TIME_24_WITH_SECONDS'

  return {
    ...(DateTime[timeFormat?.date_format?.label] || DateTime.DATE_SHORT),
    ...(DateTime[timeFormat?.time_format?.label] || DateTime.TIME_WITH_SECONDS),
    ...(is24HourFormat
      ? { hourCycle: 'h23' }
      : {
          hourCycle: 'h12'
        }) /* Force to 24h if 24h time, force to 12h if 12h time. */
  }
}
export const DateTimePicker = (props) => {
  const { timeFormat } = useTimeFormat()
  const is24HourFormat =
    timeFormat?.time_format?.label === 'TIME_24_WITH_SECONDS'

  const { value, changeHandler, style, editable } = props

  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [timePickerVisible, setTimePickerVisible] = useState(false)

  const [pendingDate, setPendingDate] = useState(null)
  const onDateDismiss = useCallback(() => {
    setDatePickerVisible(false)
  }, [setDatePickerVisible])
  const onTimeDismiss = useCallback(() => {
    setTimePickerVisible(false)
  }, [setTimePickerVisible])

  const onDateChange = (args) => {
    const { date } = args
    setDatePickerVisible(false)
    setTimePickerVisible(true)

    const now = DateTime.now()

    const dateObject = DateTime.fromJSDate(date).set({
      hour: now.toFormat('HH'),
      minute: now.toFormat('mm'),
      second: now.toFormat('ss')
    })

    const dateFormat =
      DateTime[timeFormat?.date_format?.label] || DateTime.DATE_SHORT
    const timeFormatString =
      DateTime[timeFormat?.time_format?.label] || DateTime.TIME_WITH_SECONDS

    const display_value = dateObject.toLocaleString({
      ...dateFormat,
      ...timeFormatString
    })

    const newDate = dateObject.toISO()

    const month = dateObject.toFormat('MM')
    const day = dateObject.toFormat('dd')
    const year = dateObject.toFormat('yyyy')

    setPendingDate({
      dateObject,
      value: newDate,
      month,
      day,
      year,
      display_value
    })

    // changeHandler({ value: newDate, month, year, day })
  }

  const onTimeChange = (args) => {
    const newPendingDate = TimepickerTimeToPendingDate(args, pendingDate)
    setPendingDate(newPendingDate)
  }

  const lockedToCreationDate = _.get(
    props.data?.field,
    'field_data.params.lockedToCreationDate',
    false
  )

  useEffect(() => {
    // do not set the date if not already (MSFES-132)
    if (!value && !props.defaultValue && lockedToCreationDate) {
      // onDateChange({ date: new Date() })
      changeHandler(getNowObject(), false)
    }
  }, [''])

  const jsDate = value ? DateTime.fromISO(value.value).toJSDate() : new Date()

  const format = getDateTimeFormatForTimeFormat(timeFormat)
  const displayDateTime = DateTime.fromJSDate(jsDate).toLocaleString(format)

  const displayValue = useMemo(() => {
    if (pendingDate) {
      const dateFormat =
        DateTime[timeFormat?.date_format?.label] || DateTime.DATE_SHORT
      const timeFormatString =
        DateTime[timeFormat?.time_format?.label] || DateTime.TIME_WITH_SECONDS

      return DateTime.fromISO(pendingDate.value).toLocaleString({
        ...dateFormat,
        ...timeFormatString
      })
    }

    return value ? displayDateTime : 'Select a Date & time'
  }, [pendingDate, value, timeFormat, displayDateTime])

  const onPressDateAction = editable
    ? () => {
        setDatePickerVisible(true)
      }
    : null

  const timePickerInputValue = useMemo(() => {
    return pendingDateToTimepickerTime(pendingDate, is24HourFormat)
  }, [pendingDate, is24HourFormat])

  const { setCrossFieldReferences } = useContext(CrossFieldReferencesContext)
  useEffect(() => {
    if (props.data?.field?.field_data?.property) {
      if (setCrossFieldReferences) {
        setCrossFieldReferences((crossFieldReferences) => ({
          ...crossFieldReferences,
          [props.data.field.field_data.property]: {
            primaryAction: onPressDateAction
          }
        }))
      }
    }
  }, [''])

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  return (
    <View style={[{ flex: 1 }]}>
      {!skipStaticRendering && (
        <View style={style}>
          <ListItem
            onPress={onPressDateAction}
            ViewComponent={View}
            disabled={lockedToCreationDate}
            containerStyle={ListItemAsFieldProps.containerStyle}
            {...ListItemAsFieldProps.touchableProps}
          >
            <ListItem.Content>
              <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
                {displayValue}
              </ListItem.Title>
            </ListItem.Content>
            {editable && !!value && (
              <ListItem.Chevron
                type="font-awesome"
                onPress={() => {
                  changeHandler(undefined)
                  return true
                }}
                name="times"
                size={14}
                color={colors.blueLighter}
                style={{ marginRight: 32 }}
              />
            )}
          </ListItem>
        </View>
      )}
      <PaperProvider theme={DatePickerTheme}>
        <DatePickerModal
          mode="single"
          visible={datePickerVisible}
          onDismiss={onDateDismiss}
          date={jsDate}
          onConfirm={onDateChange}
          saveLabel="Save" // optional
          label="Select date" // optional
          animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
        />

        {timePickerVisible && (
          <TimePickerOverlay
            value={timePickerInputValue}
            onChange={onTimeChange}
            onSave={() => {
              changeHandler(pendingDate)
              onTimeDismiss()
            }}
            onDismiss={() => setTimePickerVisible(false)}
          />
        )}
      </PaperProvider>
    </View>
  )
}
