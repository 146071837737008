import React from 'react'
import { useSelector } from 'react-redux'
import { withTheme } from 'react-native-elements'
import { Text, View } from 'react-native'
import MSFESHeading from './MSFESHeading'
import _ from 'lodash'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
const InPageValidationErrors = (props) => {
  const { offline_id, step, definition } = props

  const { errors } = useSelector((appState) => {
    return { errors: appState.entities?.errors }
  })

  const errorsThisEntity =
    errors?.offline_id === offline_id ? errors?.errorsByPage : null

  const errorsThisEntityThisPage = _.get(errorsThisEntity, step, undefined)

  return (
    !!errorsThisEntityThisPage && (
      <View
        style={{
          padding: spacing.m1,
          borderRadius: spacing.borderRadius,
          backgroundColor: colors.lighterPink
        }}
      >
        <MSFESHeading style={{ backgroundColor: undefined }}>
          Validation errors this page
        </MSFESHeading>
        {Object.keys(errorsThisEntityThisPage).map((key, index) => {
          const matchingField = definition.fields.find(
            (f) => f.field_data?.property === key
          )

          const friendlyName = matchingField
            ? matchingField.field_data?.title
            : key === 'assignees'
            ? 'Assignees'
            : key
          return (
            <View key={index} style={{ marginTop: spacing.m0_5 }}>
              <Text style={{ fontWeight: 'bold' }}>{friendlyName}</Text>
              <Text>{errorsThisEntityThisPage[key].join()}</Text>
            </View>
          )
        })}
      </View>
    )
  )
}

export default withTheme(InPageValidationErrors)
