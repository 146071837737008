// libraries
import React, { useEffect, useState } from 'react'
import { View, ActivityIndicator } from 'react-native'
import _ from 'lodash'

import { Overlay, SearchBar } from 'react-native-elements'

import spacing from '../styles/spacing'
import { FlatList } from 'react-native'
import { ListItemLinkButton } from './ListItemLinkButton'
import CurrentlyNoItems from './CurrentlyNoItems'
import { getDisplayLabelForEntity } from '../libraries/entityTools'
import { useDispatch, useSelector } from 'react-redux'

import fileActions from '../store/file/actions'
import colors from '../styles/colors'
import OverlayHeader from './OverlayHeader'

export const MediaLibraryOverlay = React.forwardRef((props, ref) => {
  const dispatch = useDispatch()

  const defaultQueryParams = { page: 1, searchText: null }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const filter = false
  const [loading, setLoading] = useState(false)
  const { page, searchText } = queryParams

  const { onMediaItemSelected } = props

  const loadMoreItems = () => {
    if (page < meta.last_page || !meta) {
      setQueryParams(({ page, searchText }) => {
        return { page: page + 1, searchText }
      })
    }
  }

  const fetchItems = () => {
    setLoading(true)
    dispatch(fileActions.listFiles({ filter, page, searchText }))
      .then(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchItems()
  }, [queryParams])

  const filterString = 'default' //sub with actual filter
  const filesWrapper = useSelector((state) => {
    return _.get(state.files, `[${filterString}]`, { data: null, meta: null })
  })

  const { meta, data: fileItems } = filesWrapper

  const { onClosePressed } = props

  const currentlyNoLabel = 'No files found.'

  const onSearchCancel = () => {
    searchTextChanged('')
  }

  const searchTextChanged = (searchText) => {
    setQueryParams((oldQueryParams) => {
      return { ...oldQueryParams, page: 1, searchText }
    })
  }

  return (
    <Overlay isVisible={true} onBackdropPress={onClosePressed}>
      <View style={{ maxHeight: 400 }}>
        <OverlayHeader>Media Library</OverlayHeader>
        <SearchBar
          placeholder="Search"
          onClear={onSearchCancel}
          lightTheme={true}
          inputContainerStyle={[
            { backgroundColor: colors.inputBackground, marginHorizontal: 8 }
          ]}
          containerStyle={[{ backgroundColor: colors.headerBackground }]}
          value={queryParams.searchText}
          onChangeText={searchTextChanged}
        />
        {loading && (
          <ActivityIndicator size="large" style={{ paddingTop: spacing.m2 }} />
        )}
        <FlatList
          contentContainerStyle={{ flex: 1 }}
          onEndReached={loadMoreItems}
          onEndReachedThreshold={1}
          ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
          keyExtractor={(item) => item.id.toString()}
          data={fileItems}
          renderItem={({ item, index }) => {
            return (
              <ListItemLinkButton
                entity={item}
                title={getDisplayLabelForEntity(item)}
                key={index}
                onPress={() => {
                  onMediaItemSelected(item)
                }}
              />
            )
          }}
        />
      </View>
    </Overlay>
  )
})
