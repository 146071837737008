import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Icon } from 'react-native-elements'
import colors from '../styles/colors'
import fonts from '../styles/fonts'
export default function CurrentlyNoItems({ label }) {
  return <View style={styles.viewStyle}>
    <Icon name="folder-open" type="font-awesome" size={64} color={colors.iconDark} />
    <Text style={styles.textStyle}>{label}</Text></View>
}

export function CurrentlyNoLabelOnly({ label}) {
  return <View style={styles.viewStyleNoLabelOnly}>
    <Text style={styles.textStyleNoLabelOnly}>{label}</Text>
  </View>
}

const styles = StyleSheet.create({
  textStyle: { textAlign: "center", fontFamily: fonts.standardFace, fontSize: 24, fontWeight: "700", paddingHorizontal: 60 },
  viewStyle: { flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 0, minHeight: 160 },
  textStyleNoLabelOnly: { textAlign: "center", fontFamily: fonts.standardFace, fontSize: 14, fontWeight: "700", paddingHorizontal: 60 },
  viewStyleNoLabelOnly:{ flex: 1, alignItems: 'center', justifyContent: 'center'}
})