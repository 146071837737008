import React, { useEffect, useState } from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import { DataTable } from 'react-native-paper'
import { useDispatch } from 'react-redux'
import { cellStyle } from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import { showActionHistory } from '../../store/entity/actions'
import CurrentlyNoItems from '../CurrentlyNoItems'
import OverlayHeader from '../OverlayHeader'
export const ActionHistoryPanel = ({ entity, entityType }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  useEffect(() => {
    dispatch(showActionHistory({ id: entity?.id, entityType })).then(
      (response) => {
        setLoading(false)
        setData(response.data)
      }
    )
  }, [])
  return (
    <View>
      <View style={{ maxHeight: 400, position: 'relative' }}>
        <OverlayHeader>Action History</OverlayHeader>
        {loading && <ActivityIndicator />}
        {!loading && !data.length && (
          <CurrentlyNoItems label="No actions recorded" />
        )}
        {!loading && !!data.length && (
          <DataTable style={{ flex: 1 }}>
            <DataTable.Header>
              <DataTable.Title key={'name'}>Action</DataTable.Title>
              <DataTable.Title key={'name'}>ID</DataTable.Title>
              <DataTable.Title key={'name'}>Created At</DataTable.Title>
              <DataTable.Title key={'files'}>Completed At</DataTable.Title>
            </DataTable.Header>
            {data.map((actionHistory, rowIndex) => {
              return (
                <DataTable.Row
                  key={rowIndex}
                  style={{
                    backgroundColor: rowIndex % 2 ? 'transparent' : '#f1f1f1'
                  }}
                >
                  <DataTable.Cell key={'action_id'} style={cellStyle}>
                    <Text>{actionHistory.action_id}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell key={'id'} style={cellStyle}>
                    <Text>{actionHistory.id}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell key={'created_at'} style={cellStyle}>
                    <Text>{actionHistory.created_at}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell key={'completed_at'} style={cellStyle}>
                    <Text>{actionHistory.completed_at}</Text>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            })}
          </DataTable>
        )}
      </View>
    </View>
  )
}
