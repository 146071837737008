import React from 'react';



const CrossFieldRenderAsNullContext = React.createContext({crossFieldRenderAsNull: null, setCrossFieldRenderAsNull: null});

/*

Usage:

Some field types need to affect those around them.

E.g. CustomFunctionalityTimesheetPunchCard will dictate that
punch in, punch out, meal breaks, callbacks should not render, because the timesheet punch card looks after them

We render as null because we might want some of the actions created in the component, without anything rendered to the UI

e.g. Hiding the 'Monday' punch in and punch out data fields, but being able to respond to a 'Monday add break time' activity.


*/


export default CrossFieldRenderAsNullContext