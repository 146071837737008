import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import MSFESButton from '../MSFESButton'
import { doPull } from '../../store/entity/actions'
import spacing from '../../styles/spacing'
import EntityEditContext from '../../contexts/EntityEditContext'
import EntityField from '../EntityField'
import { getLabel } from './SelectWithData'
import { ListItem } from 'react-native-elements'
import { showMessage } from 'react-native-flash-message'
import { useTenantOrGlobalAdmin } from '../../hooks/useTenantOrGlobalAdmin'
import { useLinkTo } from '@react-navigation/native'

export const getNowObject = () => {
  const now = DateTime.now()
  const nowValue = now.toISO()
  const nowDisplay = now.toFormat('yyyy-MM-dd h:mm:ss a')
  return { value: nowValue, display_value: nowDisplay }
}

export const CustomFunctionalityRandomEntityPull = (props) => {
  const dispatch = useDispatch()

  const linkTo = useLinkTo()
  const { isGlobalAdmin } = useTenantOrGlobalAdmin()
  const calculatedStyle = [props.style, { padding: spacing.m1, height: null }]

  const [, , , saveEntity] = useContext(EntityEditContext)

  const [pullEntity, setPullEntity] = useState({})

  const onDoPull = async () => {
    setPullEntity((_pullEntity) => ({ ..._pullEntity, pullBegun: true }))
    try {
      let id = props.data.entity?.id
      let uri
      let didCreate = false
      if (!id) {
        const response = await saveEntity({
          showLoading: false,
          showToasts: false
        })
        didCreate = true
        id = response.data.id
        uri = response.data.uri
      }

      // get field ID for pull
      const fieldName = props.data.field.field_data.property

      dispatch(
        doPull({
          entityType: props.definition,
          id,
          fieldName,
          count: pullEntity.pullCount
        })
      )
        .then(() => {
          setPullEntity((_pullEntity) => ({ ..._pullEntity, pullBegun: false }))
          if (didCreate) {
            linkTo(uri)
          }
        })
        .catch(() => {
          showMessage({
            message:
              'An error occurred when performing random pull. Check permissions.',
            type: 'danger'
          })
          setPullEntity((_pullEntity) => ({ ..._pullEntity, pullBegun: false }))
        })
    } catch (e) {
      setPullEntity((_pullEntity) => ({ ..._pullEntity, pullBegun: false }))
    }
  }

  const pullHasCompleted = !!props.data.entity[
    props.data.field.field_data.property
  ]

  return (
    <View style={calculatedStyle}>
      {!pullHasCompleted && (
        <View>
          <EntityField
            name={'pullCount'}
            mode={
              pullEntity.pullBegun || !isGlobalAdmin ? 'locked' : props.mode
            }
            errorMessage={''}
            entity={pullEntity}
            fieldChanged={(field, newValue) => {
              setPullEntity((_pullEntity) => ({
                ..._pullEntity,
                [field.field_data.property]: newValue
              }))
            }}
            field={{
              title: 'Count to pull',
              field_data: { property: 'pullCount', type: 'number-entry' }
            }}
          />
          <MSFESButton
            small
            loading={pullEntity.pullBegun}
            disabled={
              !pullEntity?.pullCount ||
              isNaN(pullEntity.pullCount) ||
              pullEntity.pullBegun
            }
            containerStyle={{ padding: 0, flex: 1 }}
            onPress={onDoPull}
            title="Pull Random Entities"
          />
        </View>
      )}
      {pullHasCompleted && (
        <>
          {props.data.entity[props.data.field.field_data.property].map(
            (pullItem, index) => (
              <ListItem key={index.toString()}>
                <ListItem.Content>
                  <ListItem.Title>
                    {getLabel(pullItem, 'display_name')}
                  </ListItem.Title>
                </ListItem.Content>
              </ListItem>
            )
          )}
        </>
      )}
    </View>
  )
}
