import React from 'react'
import { Platform, Text, View, Image } from 'react-native'
import colors from '../styles/colors'
import shared from '../styles/shared'
import MSFESIcon from './MSFESIcon'
import _ from 'lodash'

import { useSelector } from 'react-redux'
import { Badge } from 'react-native-elements'
import { headerTextStyle } from './WithHeader'
import { websafeGoBack } from '../libraries/navigation'
import { useLinkTo } from '@react-navigation/native'
import spacing from '../styles/spacing'

const WithHeaderWithLogo = ({
  title,
  dropdownVisibilityState,
  backHint,
  navigation,
  leftButtonType,
  backButtonAction,
  rightButtonAction,
  rightButtonRef,
  rightButtonIcon
}) => {
  const linkTo = useLinkTo()
  const navState = navigation?.dangerouslyGetState()
  const unreadNotificationsCount = useSelector((state) =>
    _.get(state.notifications, 'unread_notifications.meta.total', null)
  )

  const shouldShowBurger = leftButtonType === 'burger' || Platform.OS === 'web'

  const headerTitleObj = !!title && <Text style={headerTextStyle}>{title}</Text>

  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: colors.headerBackground,
        minHeight: 44
      }}
    >
      {shouldShowBurger && !(leftButtonType === 'back') && (
        <View style={{ width: 48 }}>
          {/* <MSFESIcon name={'bars'} tappable
    size={24}
    type="font-awesome" onPress={() => { navigation && navigation.dispatch(DrawerActions.openDrawer()) }} /> */}
        </View>
      )}
      {leftButtonType === 'back' && (
        <View>
          <MSFESIcon
            name={'chevron-left'}
            tappable
            size={24}
            type="font-awesome"
            onPress={
              backButtonAction ??
              (() => {
                navigation &&
                  websafeGoBack(navigation, navState, linkTo, backHint)
              })
            }
          />
        </View>
      )}
      <View
        style={[
          {
            flex: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center'
          },
          shared.debugOff
        ]}
      >
        {!title && (
          <Image
            source={require('../images/msfes_header.png')}
            style={{ width: 80, height: 40 }}
            resizeMode={'contain'}
          />
        )}
        {headerTitleObj}
      </View>

      {rightButtonAction ? (
        <View
          ref={rightButtonRef}
          style={{
            position: 'relative',
            marginRight: spacing.m1,
            backgroundColor: dropdownVisibilityState
              ? colors.lightestGray
              : undefined,
            marginVertical: 4,
            borderTopLeftRadius: spacing.borderRadius,
            borderTopRightRadius: spacing.borderRadius,
            paddingBottom: 4
          }}
        >
          <View
            style={{
              position: 'relative',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 56,
              paddingLeft: 2,
              backgroundColor: dropdownVisibilityState
                ? colors.blueDarker
                : colors.white,
              borderRadius: spacing.borderRadius
            }}
          >
            <MSFESIcon
              tappable
              name={rightButtonIcon}
              size={24}
              color={dropdownVisibilityState ? colors.white : colors.blueDarker}
              onPress={rightButtonAction}
              type="font-awesome"
            />
            {!!unreadNotificationsCount && rightButtonIcon === 'bell' && (
              <Badge
                status="success"
                value={unreadNotificationsCount}
                containerStyle={{ position: 'absolute', top: 4, right: 4 }}
              />
            )}
          </View>
        </View>
      ) : (
        <View style={{ width: 48 }} />
      )}
    </View>
  )
}

export default WithHeaderWithLogo
