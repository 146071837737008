import React, { useMemo } from 'react'
import { View } from 'react-native'

import DashboardContainer from '../components/DashboardContainer'

export default function DashboardScreen({ route, navigation }) {
  const dashboardContainer = useMemo(() => {
    return (
      <View style={{ flex: 1 }}>
        <DashboardContainer route={route} navigation={navigation} />
      </View>
    )
  }, [route, navigation])

  return dashboardContainer
}
