import React, { useMemo } from 'react'
import { View, FlatList } from 'react-native'
import { useSelector } from 'react-redux'

import _ from 'lodash'

import { ModuleButton } from '.././ModuleButton'
import { useColumnHelper } from '../../hooks/useColumnHelper'
import { tileButtonWidth } from '../../screens/CategoryViewScreen'
import { getSortedEntitiesFromStore } from '../HomeContainer'
import spacing from '../../styles/spacing'
import MSFESButton from '../MSFESButton'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'

export const EntityTypesUnderCategory = ({ category, header = null }) => {
  const { entityTypes, entitiesFromStore } = useSelector((appState) => {
    const { entityType: entityTypeTreeCategory } = useDiscoverEntityEntityType(
      null,
      null,
      'tree_category'
    )
    return {
      entityTypes: appState?.entityTypes?.dataLite?.data ?? [],
      entitiesFromStore: getSortedEntitiesFromStore(
        entityTypeTreeCategory,
        appState
      )
    }
  })

  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const { screenButtonsWrapper } = useSelector((state) => {
    const screenButtonsWrapper = getSortedEntitiesFromStore(
      entityTypeScreenButtons,
      state
    )
    return { screenButtonsWrapper }
  })

  const entityThisCategory = entitiesFromStore?.data?.find(
    (e) => e.display_name === category
  )
  const numColumns = useColumnHelper()
  const matchingEntityTypes = useMemo(() => {
    return entityTypes.filter(
      (e) => e.object_data?.tree_category?.id === entityThisCategory?.id
    )
  }, [entityTypes])
  const matchingScreenButtons = screenButtonsWrapper?.data?.filter(
    (button) => button.tree_category_screen?.id === entityThisCategory?.id
  )

  return !!header && !!matchingEntityTypes?.length ? (
    <>
      {!!numColumns && (
        <FlatList
          contentContainerStyle={{
            paddingBottom: spacing.m2,
            alignItems: 'center'
          }}
          numColumns={numColumns}
          key={numColumns}
          data={matchingEntityTypes}
          renderItem={({ item, index }) => {
            const icon = _.get(item, 'object_data.icon', null)
            return (
              <View style={[{ width: tileButtonWidth }]}>
                <ModuleButton
                  key={index}
                  title={item.label}
                  icon={icon}
                  to={item.uri}
                />
              </View>
            )
          }}
        />
      )}

      <View style={{ marginTop: spacing.m1, marginHorizontal: spacing.m2 }}>
        {matchingScreenButtons?.map((homeScreenButton, index) => (
          <View style={{ maxWidth: 360 }} key={index}>
            <MSFESButton
              key={index}
              navigateTo={homeScreenButton.navigate_to}
              icon={{
                name: homeScreenButton.icon,
                type: 'font-awesome'
              }}
              title={homeScreenButton.name}
            />
          </View>
        ))}
      </View>
    </>
  ) : null
}
