import qs from 'querystring'

import { useState, useCallback } from 'react'

import _ from 'lodash'
const setQueryStringValue = (key, value, queryString, replace = false) => {
  if (!queryString) {
    queryString = _.get(window, 'location.search', '')
  }
  if (queryString[0] === '?') {
    queryString = queryString.substr(1)
  }
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: value })

  setQueryStringWithoutPageReload(`?${newQsValue}`, replace)
}
const setQueryStringWithoutPageReload = (qsValue, replace = false) => {
  const newurl =
    _.get(window, 'location.protocol', 'msfes') +
    '//' +
    _.get(window, 'location.host', 'msfes.app') +
    _.get(window, 'location.pathname', '') +
    qsValue

  window.history &&
    window.history[replace ? 'replaceState' : 'pushState'](
      { path: newurl },
      '',
      newurl
    )
}

export const getQueryStringValue = (key, queryString) => {
  if (!queryString) {
    queryString = _.get(window, 'location.search', '')
  }
  if (queryString[0] === '?') {
    queryString = queryString.substr(1)
  }

  const values = qs.parse(queryString)
  return values[key]
}

function useQueryString(key, initialValue, route) {
  const [value, setValue] = useState(
    route?.params?.[key] || getQueryStringValue(key) || initialValue
  )

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  return [value, onSetValue]
}

export function useQueryStringReplace(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue)

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue)
      setQueryStringValue(key, newValue, undefined, true)
    },
    [key]
  )

  return [value, onSetValue]
}

export default useQueryString
