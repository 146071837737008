import React, { useState } from 'react'
import { Linking, Platform, ScrollView, View } from 'react-native'
import { ListItem, Overlay } from 'react-native-elements'
import MSFESButton from '../MSFESButton'
import OverlayHeader from '../OverlayHeader'
import { MSFESCheckbox } from '../MSFESCheckbox'
import spacing from '../../styles/spacing'
import { useDispatch } from 'react-redux'
import actions from '../../store/entity/actions'
import api from '../../data/api'
import printJS from 'print-js'
import { PortalWrapper } from '../fields/DatePicker'

const getPrintablePages = (allPages) => {
  return [...allPages, { name: 'Appendix', index: allPages.length }]
}
export const PrintPdfOverlay = ({
  entity,
  entityType,
  onDismissed,
  allPages
}) => {
  const printablePages = getPrintablePages(allPages)
  const [printPages, setPrintPages] = useState(printablePages)
  const listItemContainerStyle = { padding: 0 }

  const [loadingPrintPDF, setLoadingPrintPDF] = useState(null)
  const [loadingViewPDF, setLoadingViewPDF] = useState(null)
  const dispatch = useDispatch()
  return (
    <PortalWrapper>
      <Overlay visible={true} onBackdropPress={onDismissed}>
        <View style={{ maxHeight: 400, minWidth: 220, position: 'relative' }}>
          <OverlayHeader>Print Pages</OverlayHeader>

          <ScrollView>
            <ListItem
              onPress={() => {}}
              containerStyle={listItemContainerStyle}
            >
              <ListItem.Content>
                <ListItem.Title>All Pages</ListItem.Title>
              </ListItem.Content>
              <MSFESCheckbox
                listitem
                radio={false}
                checked={printablePages.length == printPages.length}
                onPress={() => {
                  if (printablePages.length == printPages.length) {
                    setPrintPages(() => [])
                  } else {
                    setPrintPages(() => [...printablePages])
                  }
                }}
              />
            </ListItem>
            {printablePages.map((page, index) => {
              const onPagePressed = () => {
                if (printPages.find((p) => p.index == page.index)) {
                  setPrintPages((printPages) => [
                    ...printPages.filter((p) => p.index !== page.index)
                  ])
                } else {
                  setPrintPages((printPages) => [...printPages, page])
                }
              }
              return (
                <ListItem
                  key={index}
                  onPress={onPagePressed}
                  containerStyle={listItemContainerStyle}
                >
                  <ListItem.Content>
                    <ListItem.Title>{page.name}</ListItem.Title>
                  </ListItem.Content>
                  <MSFESCheckbox
                    listitem
                    radio={false}
                    checked={printPages.find((p) => p.index == page.index)}
                    onPress={onPagePressed}
                  />
                </ListItem>
              )
            })}
          </ScrollView>
          <View style={{ flexDirection: 'row' }}>
            {Platform.OS === 'web' && (
              <View style={{ flex: 1, marginRight: spacing.m1 }}>
                <MSFESButton
                  title="Print"
                  modifier="outline"
                  disabled={loadingPrintPDF}
                  loading={loadingPrintPDF}
                  onPress={() => {
                    setLoadingPrintPDF(true)
                    dispatch(
                      actions.onPrintEntity({
                        id: entity.id,
                        type: entityType,
                        pages: printPages
                      })
                    )
                      .then((response) => {
                        if (Platform.OS === 'web') {
                          printJS({ printable: response.url, type: 'pdf' })
                        } else {
                          const path =
                            '/pdfjs/web/viewer.html?file=' +
                            encodeURIComponent(response.url)
                          const pdfViewerUrl = api.getUrl(path, '')
                          Linking.openURL(pdfViewerUrl)
                        }
                        // if (Platform.OS === 'web') {
                        //   window.open(pdfViewerUrl, '_blank')
                        // } else {
                        //   Linking.openURL(pdfViewerUrl)
                        // }
                      })
                      .finally(() => {
                        setLoadingPrintPDF(null)
                      })
                  }}
                />
              </View>
            )}
            <View style={{ flex: 1 }}>
              <MSFESButton
                title="Save"
                loading={loadingViewPDF}
                disabled={loadingViewPDF}
                onPress={() => {
                  setLoadingViewPDF(true)
                  dispatch(
                    actions.onPrintEntity({
                      id: entity.id,
                      type: entityType,
                      pages: printPages
                    })
                  )
                    .then((response) => {
                      const path =
                        '/pdfjs/web/viewer.html?file=' +
                        encodeURIComponent(response.url)
                      const pdfViewerUrl = api.getUrl(path, '')

                      if (Platform.OS === 'web') {
                        window.open(pdfViewerUrl, '_blank')
                      } else {
                        Linking.openURL(pdfViewerUrl)
                      }
                    })
                    .finally(() => {
                      setLoadingViewPDF(null)
                    })
                }}
              />
            </View>
          </View>
        </View>
      </Overlay>
    </PortalWrapper>
  )
}
