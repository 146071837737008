import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ActivityIndicator, View, Text } from 'react-native'
import { Overlay } from 'react-native-elements'
import { useDispatch, useSelector } from 'react-redux'
import SelectWithData from '../../components/fields/SelectWithData'
import MSFESInput from '../../components/MSFESInput'
import OverlayHeader from '../../components/OverlayHeader'

import { DateTime } from 'luxon'
import SegmentedControl from '@react-native-community/segmented-control'
import spacing from '../../styles/spacing'
import EntityAddComponent, { addMode } from '../EntityAddComponent'
import { useNavigation } from '@react-navigation/native'
import _ from 'lodash'
import {
  saveCalendarEvent,
  listCalendarEventDefinitions
} from '../../store/calendar/actions'
import { Prefills } from './Prefills'
import MSFESButton from '../../components/MSFESButton'
import FlashMessage, { showMessage } from 'react-native-flash-message'
import {
  useGetModalDimensions,
  useIsDesktop
} from '../../libraries/shouldShowDesktop'
import { SelectTenant } from '../../components/fields/SelectTenant'
import { DatePicker } from '../../components/fields/DatePicker'
import { DayOfWeekSelector } from '../../components/DayOfWeekSelector'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'

function ordinal(n) {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

const getScheduleKeys = (addingDate, event) => {
  const today = (addingDate
    ? DateTime.fromISO(addingDate.split(' ').join('T')).toUTC()
    : DateTime.fromISO(event?.virtual_event_created_date?.value).toUTC() ??
      DateTime.now().toUTC()
  ).set({ hour: 0, minute: 0, second: 0 })

  const isWeekday = today.weekday <= 5

  const dayName = today.weekdayLong

  const dayOfMonth = today.toFormat('d')
  const monthLong = today.toFormat('MMMM')
  const list = [
    {
      name: 'every_day',
      label: 'Every Day'
    }
  ]

  const epoch = DateTime.utc(1970, 1, 1)

  const diffDays = today.diff(epoch, 'days')
  const daysSinceEpoch = Math.floor(diffDays.days)

  const daysMod3 = daysSinceEpoch % 3
  const daysMod6 = daysSinceEpoch % 6
  const daysMod9 = daysSinceEpoch % 9
  const daysMod21 = daysSinceEpoch % 21

  list.push({
    name: `weekly_${dayName.toLowerCase()}`,
    label: `Weekly on ${dayName}`
  })

  list.push({
    name: `24on_48off_${daysMod3}`,
    label: `24 On, 48 Off, starting ${dayName}`
  })
  list.push({
    name: `48on_96off_${daysMod6}`,
    label: `48 On, 96 Off, starting ${dayName}`
  })
  list.push({
    name: `california_berkeley_${daysMod9}`,
    label: `California Berkeley Shift, starting ${dayName}`
  })
  list.push({
    name: `modified_la_${daysMod21}`,
    label: `Modified LA Shift, starting ${dayName}`
  })

  list.push({
    name: `monthly_${dayOfMonth}`,
    label: `${ordinal(dayOfMonth)} of each Month`
  })

  list.push({
    name: `annually_${monthLong}_${dayOfMonth}`.toLowerCase(),
    label: `${ordinal(dayOfMonth)} of ${monthLong} annually`
  })
  if (isWeekday) {
    list.push({
      name: 'week_days',
      label: 'Every Weekday'
    })
  } else {
    list.push({
      name: 'weekend_days',
      label: 'Weekend Days'
    })
  }

  list.push({
    name: `custom_recurrence`,
    label: `Custom Recurrence`
  })

  return list
}

export const CalendarEventAdd = ({
  onDismiss,
  addingDate,
  addingType = null,
  modifyingEventWithEventId = null
}) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [scheduleKeys, setScheduleKeys] = useState(null)

  const { role, entityTypes } = useSelector((state) => {
    const personnel_record = _.get(state, 'users.user.personnel_record', null)

    return {
      role: _.get(personnel_record, 'role', 'Tenant User'),
      entityTypes: _.get(state, 'entityTypes.dataLite.data', null)
    }
  })

  const recurrableEntityTypes = useMemo(() => {
    const forceExclude = ['personnel', 'apparatus', 'tenant']
    return entityTypes
      .filter((entityType) => entityType.is_module)
      .filter((et) => !forceExclude.includes(et.name))
  }, [entityTypes])

  const isGlobalAdmin = role === 'MSFES User'

  const todayDt = useMemo(() => {
    return addingDate
      ? DateTime.fromISO(addingDate.split(' ').join('T'))
      : DateTime.now()
  }, [addingDate])
  const virtualEventCreatedDate = useMemo(() => {
    return todayDt.toFormat('yyyy-MM-dd HH:mm:ss')
  }, [todayDt])

  useEffect(() => {
    const todayDate = todayDt.toFormat('yyyy-MM-dd')

    setScheduleKeys(getScheduleKeys(todayDate, null))
  }, [todayDt])

  useEffect(() => {
    if (modifyingEventWithEventId) {
      setLoading(true)
      dispatch(listCalendarEventDefinitions()).then((response) => {
        setLoading(false)
        setActiveTab(1)
        const match = response.recurringEvents?.find(
          (e) => e.id === modifyingEventWithEventId
        )

        if (match) {
          match.entityType = entityTypes.find(
            (e) => e.id === match.entity_type_id
          )
          setEvent(match)

          setScheduleKeys(getScheduleKeys(match.origin_day, null))
        }
      })
    } else {
      if (addingDate) {
        setScheduleKeys(getScheduleKeys(addingDate, null))
      }
    }
  }, [''])

  const [event, setEvent] = useState({
    prefills: null,
    origin_day: addingDate,
    active_at: null,
    event_key: null
  })

  const {
    entityType: fullLoadedEntityType,
    isFetching
  } = useDiscoverEntityEntityType(null, null, event.entityType?.name)

  useEffect(() => {
    if (fullLoadedEntityType) {
      changeHandler(event, fullLoadedEntityType)
    }
  }, [fullLoadedEntityType])

  const changeHandler = (property, value) => {
    setEvent((_event) => ({ ..._event, [property]: value }))
  }

  const [activeTab, setActiveTab] = useState(() => {
    if (modifyingEventWithEventId) {
      return 1
    }
    if (addingType === 'entity' || addingType === 'task' || !addingType) {
      return 0
    }
    return 1
  })
  const modalFlashRef = useRef()
  const [currentPage, updateCurrentPage] = useState(1)
  const [saved, setSaved] = useState(false)
  const tryAddCalendarEvent = () => {
    setSaving(true)

    dispatch(saveCalendarEvent(event))
      .then(() => {
        modalFlashRef.current?.showMessage({
          message: 'Recurring event saved.',
          type: 'success'
        })
        setSaved(true)
        onDismiss()
      })
      .catch((e) => {
        modalFlashRef.current?.showMessage({
          message: e.message,
          type: 'danger'
        })
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const tabOptions = ['Create New Entity', 'Create Schedule Item']

  const isDesktop = useIsDesktop()
  const { calculatedHeight, calculatedWidth } = useGetModalDimensions()

  const typeWords = [addingType === 'entity' ? 'Entity' : 'Task', 'Schedule']

  return (
    <Overlay onBackdropPress={onDismiss}>
      <View
        style={{
          maxHeight: calculatedHeight,
          flex: 1,
          maxWidth: calculatedWidth,
          minWidth: isDesktop ? undefined : calculatedWidth
        }}
      >
        <OverlayHeader>
          {event?.id
            ? `Update ${typeWords[activeTab]} Details`
            : `Add ${typeWords[activeTab]} Details`}
        </OverlayHeader>
        {loading && <ActivityIndicator />}
        {!loading && (
          <>
            {!saved && (
              <>
                {!addingType && !modifyingEventWithEventId && (
                  <View style={{ marginBottom: spacing.m1 }}>
                    <SegmentedControl
                      enabled={!event?.id}
                      key={tabOptions}
                      values={tabOptions}
                      selectedIndex={activeTab}
                      onChange={(event) => {
                        setActiveTab(event.nativeEvent.selectedSegmentIndex)
                      }}
                    />
                  </View>
                )}
                <View
                  style={{
                    flexDirection: 'column',
                    flex: 1
                  }}
                >
                  <View
                    style={{
                      minHeight: 48,
                      marginTop: spacing.m2,
                      flexDirection: 'column'
                    }}
                  >
                    <MSFESInput
                      key="list-source"
                      horizontal
                      InputComponent={SelectWithData}
                      label={'List Source'}
                      dataRows={recurrableEntityTypes}
                      onChangeText={(newType) => {
                        changeHandler('entityType', newType)
                      }}
                      value={_.get(event, 'entityType', null)}
                      placeholder={`(Select type)`}
                      valueField="name"
                      labelField="label"
                      keyField="name"
                    />
                  </View>
                  {activeTab == 0 && event.entityType && (
                    <View style={{ flex: 1 }}>
                      {isFetching ? (
                        <ActivityIndicator />
                      ) : (
                        event?.entityType?.id && (
                          <EntityAddComponent
                            currentPage={currentPage}
                            onUpdatePage={updateCurrentPage}
                            navigation={navigation}
                            isInSplitView={true}
                            onCloseSplit={onDismiss}
                            onEditToggled={() => {}}
                            saveWithTaskLevelValidation={
                              addingType === 'task' ? true : false
                            }
                            route={{
                              params: {
                                type: event.entityType?.name,
                                'field.virtual_event_created_date': virtualEventCreatedDate,
                                mode: addMode
                              }
                            }}
                            savedCallback={() => {
                              showMessage({
                                message: event.entityType?.label + ' saved.',
                                type: 'success'
                              })
                              setSaved(true)
                              onDismiss()
                            }}
                          />
                        )
                      )}
                    </View>
                  )}
                  {activeTab == 1 && (
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          minHeight: 48,
                          marginTop: spacing.m2,
                          flexDirection: 'column'
                        }}
                      >
                        <MSFESInput
                          key="origin-day"
                          horizontal
                          InputComponent={DatePicker}
                          label={'Origin Day'}
                          changeHandler={(newDate) => {
                            changeHandler('virtual_event_created_date', newDate)
                            changeHandler(
                              'origin_day',
                              newDate['display_value']
                            )
                            setScheduleKeys(
                              getScheduleKeys(null, {
                                ...event,
                                virtual_event_created_date: newDate
                              })
                            )
                          }}
                          value={_.get(event, 'origin_day', todayDt)}
                        />
                      </View>
                      <View style={{ flexDirection: 'column' }}>
                        <MSFESInput
                          key="event_key"
                          horizontal
                          InputComponent={SelectWithData}
                          label={'Schedule'}
                          dataRows={scheduleKeys}
                          onChangeText={(newSchedule) => {
                            changeHandler('event_key', newSchedule)
                          }}
                          value={_.get(event, 'event_key.name', null)}
                          placeholder={`Select Schedule`}
                          valueField="name"
                          labelField="label"
                          keyField="name"
                        />
                      </View>

                      {event.event_key?.name === 'custom_recurrence' && (
                        <>
                          <View style={{ flexDirection: 'row' }}>
                            <View
                              style={{ flex: 1, paddingRight: spacing.m0_5 }}
                            >
                              <MSFESInput
                                label={'Repeat Every'}
                                keyboardType={'numbers-and-punctuation'}
                                type="number"
                                onChangeText={(custom_key_n) => {
                                  changeHandler('custom_key_n', custom_key_n)
                                }}
                              />
                            </View>
                            <View
                              style={{ flex: 1, paddingRight: spacing.m0_5 }}
                            >
                              <MSFESInput
                                key="custom_key"
                                InputComponent={SelectWithData}
                                label={'Interval'}
                                dataRows={[
                                  {
                                    name: 'repeat_every_n_weeks',
                                    label: 'Week/s'
                                  },
                                  {
                                    name: 'repeat_every_n_months',
                                    label: 'Month/s'
                                  },
                                  {
                                    name: 'repeat_every_n_days',
                                    label: 'Day/s'
                                  }
                                ]}
                                onChangeText={(newSchedule) => {
                                  changeHandler('custom_key', newSchedule.name)
                                }}
                                value={_.get(event, 'custom_key', null)}
                                placeholder={`Select Schedule`}
                                valueField="name"
                                labelField="label"
                                keyField="name"
                              />
                            </View>
                          </View>
                          {event?.custom_key &&
                            event.custom_key === 'repeat_every_n_weeks' && (
                              <View>
                                <View style={{ flex: 1 }}>
                                  <MSFESInput
                                    label={'On Day'}
                                    InputComponent={DayOfWeekSelector}
                                    value={_.get(
                                      event,
                                      'on_day_of_period',
                                      null
                                    )}
                                    onChangeText={(on_day_of_period) => {
                                      changeHandler(
                                        'on_day_of_period',
                                        on_day_of_period
                                      )
                                    }}
                                  />
                                </View>
                              </View>
                            )}
                          {event?.custom_key &&
                            event.custom_key === 'repeat_every_n_months' && (
                              <View>
                                <View style={{ flex: 1 }}>
                                  <MSFESInput
                                    label={'On Day of month'}
                                    keyboardType={'numbers-and-punctuation'}
                                    value={_.get(
                                      event,
                                      'on_day_of_period',
                                      null
                                    )}
                                    type="number"
                                    onChangeText={(on_day_of_period) => {
                                      changeHandler(
                                        'on_day_of_period',
                                        on_day_of_period
                                      )
                                    }}
                                  />
                                </View>
                              </View>
                            )}
                        </>
                      )}
                      {isGlobalAdmin && (
                        <View style={{ flexDirection: 'column' }}>
                          <MSFESInput
                            name={'tenant'}
                            label={'Tenant'}
                            horizontal
                            InputComponent={SelectTenant}
                            defaultValue={_.get(event, 'tenant_id', null)}
                            onChangeText={(tenant) => {
                              changeHandler('tenant_id', tenant)
                            }}
                          />
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: 'column',
                          flex: 1
                        }}
                      >
                        <Prefills
                          entityType={event.entityType}
                          prefillWrapper={event?.prefills}
                          onChange={(newPrefills) => {
                            changeHandler('prefills', newPrefills)
                          }}
                        />
                      </View>
                      <View>
                        <MSFESButton
                          loading={saving}
                          disabled={saving}
                          title={'Save Event'}
                          onPress={tryAddCalendarEvent}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </>
            )}
          </>
        )}

        {saved && (
          <>
            <View>
              <Text>Saved successfully.</Text>
            </View>
          </>
        )}
      </View>

      <FlashMessage
        ref={modalFlashRef}
        canRegisterAsDefault={false}
        position="top"
        floating={true}
      />
    </Overlay>
  )
}
