const spacingConstants = {
  m0: 0,
  m0_5: 4,
  m1: 8,
  m1_5: 12,
  m2: 16,
  m2_5: 20,
  m3: 24,
  m4: 32,
  m5: 40,
  m6: 48,
  m7: 56,
  m7_5: 60,
  m11: 88,
  m12_5: 100,
  m13: 104
}

const spacing = {
  rightPanelLayout: {
    padding: spacingConstants.m1_5,
    marginTop: spacingConstants.m1,
    marginRight: spacingConstants.m2,
    borderRadius: spacingConstants.m1_5
  },
  calendarLayout: {
    marginTop: spacingConstants.m0_5,
    paddingRight: spacingConstants.m0,
    marginLeft: spacingConstants.m_2_5 * 0.5
  },
  incidentTypesHeaderLayout: {
    marginRight: spacingConstants.m13,
    marginTop: spacingConstants.m_2_5,
    marginLeft: spacingConstants.m1_5,
    borderRadius: spacingConstants.m0_5
  },
  iconRight: { marginLeft: spacingConstants.m2 },
  mr1: { marginRight: spacingConstants.m1 },
  sectionDivider: {
    marginTop: spacingConstants.m1,
    marginBottom: spacingConstants.m1
  },
  borderRadius: spacingConstants.m1,
  viewboxNavbarPadding: 85,
  ...spacingConstants
}

export default spacing
