// allows user to define a link to an external address, or to an internal entity, or to a create entity.
import React, { useEffect, useState } from 'react'
import { View, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import SelectWithData, { SelectField } from './SelectWithData'
import _ from 'lodash'
import SelectEntityType from './SelectEntityType'
import { Input } from 'react-native-elements'
import { useShowMyDepartmentUI } from '../../hooks/useShowMyDepartmentUI'
import spacing from '../../styles/spacing'
import { SelectList } from './SelectList'
// outcome is a crafted link (a real hyperlink, an entity link, or a create link with filled key/values)

const FieldLinkTo = (props) => {
  const { onChangeText, style, editable = true } = props
  const [localValue, setLocalValue] = useState({ linkToType: null, url: '' })

  const { user } = useSelector((appState) => ({ user: appState?.users?.user }))
  const wrappedSetLocalValue = (key, value) => {
    setLocalValue((_localValue) => ({ ..._localValue, [key]: value }))
  }

  useEffect(() => {
    if (localValue && localValue.linkToType) {
      onChangeText(localValue)
    }
  }, [localValue])

  useEffect(() => {
    if (props.defaultValue) {
      setLocalValue(props.defaultValue)
    }
  }, [props.defaultValue])

  const [, , tenantEntityType] = useShowMyDepartmentUI(user)
  return (
    <View style={style}>
      <SelectWithData
        dataRows={[
          {
            type: 'url',
            name: 'URL'
          },
          // {
          //   type: 'existing-entity',
          //   name: 'Existing Entity'
          // },
          {
            type: 'create-entity',
            name: 'Create Entity Form'
          },
          {
            type: 'view-entity-list',
            name: 'View Entity List'
          },
          {
            type: 'open-current-timesheet',
            name: 'Open Current Timesheet'
          },
          {
            type: 'open-current-handbook',
            name: 'Open Current Handbook'
          },
          {
            type: 'open-tenant-file',
            name: 'Open Tenant File'
          },
          {
            type: 'view-report',
            name: 'View Report'
          },
          {
            type: 'field-value',
            name: 'URL in field value'
          }
        ]}
        onChangeText={(newType) =>
          wrappedSetLocalValue('linkToType', newType.type)
        }
        placeholder={`(Select link type)`}
        value={_.get(localValue, 'linkToType')}
        valueField="type"
        labelField="name"
        editable={editable}
        keyField="type"
      />

      {['create-entity', 'view-entity-list'].includes(
        _.get(localValue, 'linkToType')
      ) && (
        <SelectEntityType
          key="create-entity-source"
          label={'Create Entity Of Type'}
          onChangeText={(newType) => {
            wrappedSetLocalValue('sourceName', newType.name)
          }}
          editable={editable}
          defaultValue={_.get(localValue, 'sourceName')}
          placeholder={`(Select entity type)`}
        />
      )}

      {['open-tenant-file'].includes(_.get(localValue, 'linkToType')) && (
        <SelectField
          style={{ marginTop: spacing.m1 }}
          key="tenant-file-source"
          label={'Field in Client with File'}
          fieldTypeFilter={['view-list-inline']}
          onChangeText={(newType) => {
            wrappedSetLocalValue('clientFieldFile', newType)
          }}
          data={{
            entity: tenantEntityType
          }}
          helpText={
            "Select the field that will be updated by this action. Fields are filtered to those which are 'View List Inline' type."
          }
          editable={editable}
          value={_.get(localValue, 'clientFieldFile')}
          placeholder={`(Select file field)`}
        />
      )}

      {['view-report'].includes(_.get(localValue, 'linkToType')) && (
        <SelectList
          style={{ marginTop: spacing.m1 }}
          key="view-report-source"
          label={'Report Source'}
          onChangeText={(reportSelected) => {
            wrappedSetLocalValue('reportSource', reportSelected)
          }}
          data={{
            entity: props.data?.entity,
            field: {
              field_data: {
                params: {
                  ...props.data?.field.field_data.params,
                  ...{ sourceName: 'system_report' }
                }
              }
            }
          }}
          helpText={'Select the report to link to.'}
          editable={editable}
          value={_.get(localValue, 'reportSource')}
          placeholder={`(Select report source)`}
        />
      )}

      {_.get(localValue, 'linkToType') == 'url' && (
        <Input
          onBlur={(e) => {
            const value =
              Platform.OS === 'web' ? e.target.value : e.nativeEvent.text
            wrappedSetLocalValue('url', value)
          }}
          editable={editable}
          placeholder="URL to navigate to"
          defaultValue={_.get(localValue, 'url')}
          style={{ flex: 1 }}
        />
      )}
    </View>
  )
}

export default FieldLinkTo
