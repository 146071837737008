import { sentryIdentify } from "../../helpers/sentry";

const defaultState = {
  user: null,
  errors: {
    errors: null,
    hasError: false
  },
  access_token: false,
  email: false
}
export default function users(state = defaultState, action) {
  switch (action.type) {
    case 'users/signin-started':
    case 'users/clear-error':

      console.log("Clear errors")
      state.errors = { ...state.errors, ...{ hasError: false, errors: null } }

      break
    case 'users/signin-error':
    case 'users/reset-password-error':
      let friendlyErrors = [];

      if (action.error.errors) {
        Object.keys(action.error.errors).map(key => {
          friendlyErrors.push(action.error.errors[key])
        })
      }

      state.errors = { errors: friendlyErrors.length ? friendlyErrors.join("\n") : action.error.message, hasError: true }
      break
    case 'users/signin-success':
    case 'users/select-tenant-success':
      const { access_token, user } = action

      const { email } = user

      try {
        sentryIdentify(email)
      }
      catch(e) {
        
      }

      state = { ...state, access_token, user, email }
      break
    case 'users/signout-success':
      state = { ...state, ...{ access_token: null } }
      break
    case 'users/fetch-success':
      state = { ...state, ...{ user: action.data } }
      break
    case 'users/purge-token':
      state = { ...state, access_token: false }

      break
  }
  return state
}
