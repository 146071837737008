import React, { useState } from 'react'
import { View, Text, Dimensions, Platform } from 'react-native'
import colors from '../styles/colors';
import fonts from '../styles/fonts';

import _ from 'lodash'
import { getLabelInputColor, getLabelTitleColor } from './MSFESLabel';


export default function MSFESSubLabel(props) {


  let titleColor =colors.mediumGray;
  let inputColor = getLabelInputColor(props);


  // set up title style
  const labelStyle = [props.labelStyle, {
    color: titleColor,
    fontFamily: fonts.inputLabelFace,
    fontSize: fonts.inputLabelSize
  }]




  // set up title text
  const { label, required, capitalize = false, style = {} } = props;

  const labelTransformed = label && (capitalize ? label.toUpperCase() : label);


  if (props.rightIcon) {
    props.rightIcon.color = colors.iconDark
  }

  const [helpVisible, setHelpVisible] = useState(false)


  const requiredStyle = { color: colors.required }

  const width = Dimensions.get('window').width;

  const marginTop = Platform.OS === "web" ? -12 : -12
  const marginBottom = Platform.OS === "web" ? -16 : -12
  return <View style={[{ flexDirection: "row", paddingBottom: 0, marginTop, marginBottom }, style]}>
    <Text style={[labelStyle, {fontSize: fonts.smallLabelSize}]}>{labelTransformed} {required && <Text style={requiredStyle}>*</Text>}</Text>
  </View >

}