import React, { useMemo } from 'react';
import colors from '../styles/colors'
import { View } from 'react-native'
import { RadioButton, Checkbox } from 'react-native-paper'
import spacing from '../styles/spacing';

export const MSFESCheckbox = (props) => {

  const { radio = true } = props

  const radioProps = useMemo(() => {
    return {
      checkedColor: colors.controlSelected,
      color: colors.controlSelected
    }
  }, [radio])

  const status = props.checked ? "checked" : "unchecked"

  const style = props.listitem ? {} : { marginRight: spacing.m2 }

  return <View style={[style, props.style]}>

    {radio ? <RadioButton.Android   {...props}
      {...radioProps} status={status} /> :
      <Checkbox.Android   {...props}
        {...radioProps} status={status} />}</View>

}