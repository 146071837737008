import React from 'react'
import SelectWithData from './fields/SelectWithData'
import MSFESInput from './MSFESInput'
import { Divider } from 'react-native-elements'
export const PageSelector = ({ values, selectedIndex, onChange }) => {
  return (
    <>
      <MSFESInput
        horizontal
        InputComponent={SelectWithData}
        label={'Editing Page:'}
        dataRows={values.map((value) => ({
          value
        }))}
        onChangeText={(newValue) =>
          onChange(values.findIndex((v) => v === newValue.value))
        }
        value={{ value: values[selectedIndex] }}
        valueField="value"
        keyField="value"
        labelField="value"
      />
      <Divider />
    </>
  )
}
