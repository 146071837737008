import React from 'react'
import { View } from 'react-native'

import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import MSFESButton from './MSFESButton'
import { Overlay } from 'react-native-elements'
import colors from '../styles/colors'
import { useDropdownHelper } from '../useDropdownHelper'
import { pinkButtonStyle } from './TopNavButtonHousing'

export const ButtonContainer = React.forwardRef((props, ref) => {
  const {
    mode,
    entity,
    prepopulatedFields,
    fileAppended,
    fieldChanged,
    definition,
    label
  } = props

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )

  // props.data.field.field_data.params.sourceName
  const myEntityType = definition

  const fieldIds = _.get(
    props,
    'data.field.field_data.params.fieldList',
    []
  ).map((f) => f.offline_id ?? f.id)
  const fields = _.get(myEntityType, 'fields', []).filter(
    (f) => fieldIds.includes(f.id) || fieldIds.includes(f.offline_id)
  )

  let icon = null
  if (props.data.field?.field_data?.icon) {
    icon = {
      name: props.data.field?.field_data?.icon,
      type: 'font-awesome'
    }
  }

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(200, -5)

  const fieldsSorted = _.sortBy(fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  return (
    <View style={{ flexDirection: 'column', maxHeight: 64, flex: 1 }}>
      <View
        style={[
          {
            marginTop: spacing.m0_5,
            flex: 1,
            paddingHorizontal: spacing.m1,
            borderRadius: spacing.borderRadius
          },
          shared.debugOff
        ]}
      >
        <View
          style={{
            justifyContent: 'center',
            flex: 1,
            flexDirection: 'row',
            position: 'relative'
          }}
        >
          <View ref={buttonRef}>
            <View
              style={{
                backgroundColor: dropdownVisibilityState
                  ? colors.lightPink
                  : undefined,
                borderTopLeftRadius: spacing.borderRadius,
                borderTopRightRadius: spacing.borderRadius,
                paddingBottom: 4
              }}
            >
              <MSFESButton
                buttonStyle={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingVertical: spacing.m0_5,
                  height: 48,
                  ...pinkButtonStyle
                }}
                variant="danger"
                title={label}
                titleStyle={{
                  fontSize: 14,
                  ...(icon ? { paddingLeft: 32 } : null)
                }}
                titleColor={colors.redDark}
                icon={icon}
                onPress={toggleDropdownState}
              />
            </View>
            {dropdownVisibilityState && (
              <Overlay
                visible={true}
                backdropStyle={{ opacity: 0 }}
                onBackdropPress={toggleDropdownState}
                overlayStyle={dropdownOverlayStyle}
              >
                <View style={{ backgroundColor: colors.lightPink }}>
                  {fieldsSorted.map((field, index) => {
                    const errorMessage = _.get(
                      errors,
                      field.field_data.property,
                      []
                    ).join(',')

                    return (
                      <EntityField
                        showLabel={false}
                        displayIcon={false} // hide in row mode.
                        mode={mode}
                        entity={entity}
                        name={field.field_data.property}
                        prepopulatedFieldValue={
                          prepopulatedFields
                            ? prepopulatedFields[field.field_data.property]
                            : undefined
                        }
                        errorMessage={errorMessage}
                        field={field}
                        fileAppended={fileAppended}
                        fieldChanged={fieldChanged}
                        key={index}
                      />
                    )
                  })}
                </View>
              </Overlay>
            )}
          </View>
        </View>
      </View>
    </View>
  )
})
