import React, { useState, useEffect } from 'react'
import {
  ActivityIndicator,
  View,
  Text,
  Platform,
  Dimensions,
  FlatList
} from 'react-native'
import { DataTable } from 'react-native-paper'
import CurrentlyNoItems from './CurrentlyNoItems'
import OverlayHeader from './OverlayHeader'
import { useDispatch } from 'react-redux'
import { listSensitiveActions } from '../store/sensitive-actions/actions'
import { cellStyle } from '../screens/EntityListScreenHelpers/DataTableWrapper'

export const SensitiveActionsPanel = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  /* eslint-disable-next-line no-unused-vars */
  const [page, setPage] = useState(1)
  /* eslint-disable-next-line no-unused-vars */
  const [pages, setPages] = useState(1)
  const [data, setData] = useState([])
  useEffect(() => {
    fetchNextPage()
  }, [page])

  const fetchNextPage = () => {
    dispatch(listSensitiveActions({ page })).then((response) => {
      setLoading(false)
      setData((data) => [...data, ...response.data])
      setPages(response.meta.last_page)
    })
  }

  const maxOverlayHeight =
    Platform.OS === 'web' ? Dimensions.get('window').height * 0.7 : 600

  return (
    <View style={{ height: maxOverlayHeight }}>
      <OverlayHeader>Sensitive Actions</OverlayHeader>
      {loading && <ActivityIndicator />}
      {!loading && !data.length && (
        <CurrentlyNoItems label="No sensitive action history" />
      )}
      {!loading && !!data.length && (
        <DataTable style={{ flex: 1 }}>
          <DataTable.Header>
            <DataTable.Title key={'user'}>User</DataTable.Title>
            <DataTable.Title key={'datetime'}>Date & Time</DataTable.Title>
            <DataTable.Title key={'notes'}>Notes</DataTable.Title>
          </DataTable.Header>

          <FlatList
            data={data}
            onEndReached={() => {
              if (page < pages) {
                setPage(page + 1)
              }
            }}
            renderItem={({ item, index }) => {
              return (
                <DataTable.Row
                  key={index}
                  style={{
                    backgroundColor: index % 2 ? 'transparent' : '#f1f1f1'
                  }}
                >
                  <DataTable.Cell key={'name'} style={cellStyle}>
                    <Text>{item.name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell key={'created_at'} style={cellStyle}>
                    <Text>{item.created_at}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell key={'content'} style={cellStyle}>
                    <Text>{item.content}</Text>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            }}
          />
        </DataTable>
      )}
    </View>
  )
}
