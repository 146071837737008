import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  Platform
} from 'react-native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { ModulesStackNavigator } from './StackNavigator'
import SettingsNavigator from '../screens/SettingsNavigator'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import colors from '../styles/colors'
import TasksNavigator from '../screens/TasksNavigator'
import { DrawerActions } from '@react-navigation/routers'
import spacing from '../styles/spacing'
import fonts from '../styles/fonts'
import MSFESSvgIcon from '../icons/MSFESSvgIcon'
import ConversationListScreen from '../screens/ConversationListScreen'
import CalendarViewScreen from '../screens/CalendarViewScreen'
import { LinkingContext } from '@react-navigation/native'
import { shouldShowDesktop } from '../libraries/shouldShowDesktop'
const Tab = createBottomTabNavigator()

export const getTabIcon = (iconName) => {
  return (
    <MSFESSvgIcon
      name={iconName}
      fill="transparent"
      stroke="#223E4C"
      strokeWidth="2"
      height="32"
      width="32"
      viewBox="0 0 40 40"
      preserveAspectRatio="xMinYMin slice"
    />
  )
}
export const getIconName = (routeName) => {
  let iconName
  switch (routeName) {
    case ROUTE_HOME:
      iconName = 'Home'
      break
    case ROUTE_TASKS:
      iconName = 'CircledTick'
      break
    case ROUTE_CALENDAR:
      iconName = 'FieldsDate'
      break
    case ROUTE_SETTINGS:
      iconName = 'Handbook'
      break
    case ROUTE_MENU:
      iconName = 'HorizontalMenu'
      break
  }

  return iconName
}

export const ROUTE_HOME = 'Home'
export const ROUTE_TASKS = 'Tasks'
export const ROUTE_CALENDAR = 'Calendar'
export const ROUTE_MESSAGES = 'Messages'
export const ROUTE_SETTINGS = 'Settings'
export const ROUTE_MENU = 'Menu'

const HOME_INDEX = 0
const TASKS_INDEX = 1
const CALENDAR_INDEX = 2
const MESSAGES_INDEX = 3
const SETTINGS_INDEX = 4
const MENU_INDEX = 5

const iconBoxSize = 46

const resetHomeStackOnTabPress = ({ navigation }) => ({
  tabPress: (e) => {
    const state = navigation.dangerouslyGetState()

    if (state) {
      // Grab all the tabs that are NOT the one we just pressed
      const index = state.routes.findIndex((r) => r.key === e.target)

      if (state.index === index) {
        // user has tapped again.
        switch (index) {
          case HOME_INDEX:
          case TASKS_INDEX:
          case CALENDAR_INDEX:
          case SETTINGS_INDEX:
            {
              const navInstruction = bottomTabStructure[index]
              navInstruction.onPress(e, navigation)
              // e.preventDefault();
              // navigation.navigate(ROUTE_HOME, { screen: 'Home' });
            }
            break

          case MENU_INDEX:
            e.preventDefault()
            navigation.dispatch(DrawerActions.openDrawer())
            break
          default:
            console.warn(state.routes, e.target, bottomTabStructure)
            break
        }
      }
    }
  }
})

export const bottomTabStructure = [
  {
    id: 'home',
    name: ROUTE_HOME,
    component: ModulesStackNavigator,
    desktopFocusedWhen: (navState) => {
      const index = navState?.routes[0]?.state?.routes[0]?.state?.index
      const page = _.get(
        navState,
        `routes[0].state.routes[0].state.routes[${index}].params.id`,
        null
      )

      const active = _.get(navState, 'routes[0].state.index', 0) == HOME_INDEX

      return active && !page
    },
    onPress: (e, _navigation) => {
      e.preventDefault && e.preventDefault()
      _navigation.navigate(ROUTE_HOME, { screen: 'Home', params: { id: 0 } })
    }
  },
  {
    id: 'tasks',
    name: ROUTE_TASKS,
    component: TasksNavigator,
    desktopFocusedWhen: (navState) => {
      const active = _.get(navState, 'routes[0].state.index', 0) == TASKS_INDEX
      return active
    },
    onPress: (e, _navigation) => {
      e.preventDefault && e.preventDefault()
      _navigation.navigate(ROUTE_TASKS, { screen: 'TasksIndex' })
    }
  },
  {
    id: 'calendar',
    name: ROUTE_CALENDAR,
    component: CalendarViewScreen,
    desktopFocusedWhen: (navState) => {
      const active =
        _.get(navState, 'routes[0].state.index', 0) == CALENDAR_INDEX
      return active
    },
    onPress: (e, _navigation) => {
      e.preventDefault && e.preventDefault()
      _navigation.navigate('Calendar')
    }
  },
  {
    id: 'messages',
    name: ROUTE_MESSAGES,
    component: ConversationListScreen,
    desktopFocusedWhen: (navState) => {
      const active =
        _.get(navState, 'routes[0].state.index', 0) == MESSAGES_INDEX
      return active
    },
    onPress: (e, _navigation) => {
      e.preventDefault && e.preventDefault()
      _navigation.navigate(ROUTE_MESSAGES, { screen: 'ConversationListScreen' })
    }
  },
  {
    id: 'settings',
    name: ROUTE_SETTINGS,
    component: SettingsNavigator,
    desktopFocusedWhen: (navState) => {
      const active =
        _.get(navState, 'routes[0].state.index', 0) == SETTINGS_INDEX
      return active
    },
    onPress: (e, _navigation) => {
      e.preventDefault && e.preventDefault()
      _navigation.navigate(ROUTE_SETTINGS, { screen: 'SettingsIndex' })
    }
  }
]

const BottomTabNavigator = (props) => {
  // stash these for components that do not have access to the context.
  global._navigation = props.navigation
  const linking = React.useContext(LinkingContext)
  global._linking = linking

  return (
    <Tab.Navigator
      tabBar={(props) => <MyTabBar {...props} />}
      tabBarOptions={{
        activeTintColor: colors.tabNavActiveTint,
        inactiveTintColor: colors.tabNavInactiveTint,
        activeBackgroundColor: colors.tabNavBackground,
        inactiveBackgroundColor: colors.tabNavBackground,
        keyboardHidesTabBar: true,
        tabBarHideOnKeyboard: true
      }}
    >
      {bottomTabStructure.map((item) => (
        <Tab.Screen
          id={item.id}
          key={item.id}
          name={item.name}
          component={item.component}
          listeners={resetHomeStackOnTabPress}
        />
      ))}

      <Tab.Screen
        name={ROUTE_MENU}
        component={SettingsNavigator}
        listeners={resetHomeStackOnTabPress}
      />
    </Tab.Navigator>
  )
}

function MyTabBar({ state, descriptors, navigation }) {
  const focusedOptions = descriptors[state.routes[state.index].key].options

  if (focusedOptions.tabBarVisible === false) {
    return null
  }

  const windowDimensions = useWindowDimensions()
  const showDesktop = shouldShowDesktop(windowDimensions)

  const { environment } = useSelector((appState) => {
    const environment = _.get(appState, 'app.environment', null)
    return { environment }
  })

  const tabbarheight =
    Platform.OS === 'ios'
      ? 60
      : 80 /* ios - we have a safe area view working for us. */

  const navColor =
    environment == 'prod' ? colors.blueDarkest : colors.stagingRed

  return (
    <View
      pointerEvents={'box-none'}
      style={[
        { flexDirection: 'column', height: tabbarheight, marginTop: -26 },
        showDesktop && { marginTop: 0, height: 0, overflow: 'hidden' }
      ]}
    >
      <View
        pointerEvents={'box-none'}
        style={{ flexDirection: 'row', flex: 1 }}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key]
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name

          const isFocused = state.index === index

          const onPress = () => {
            if (route.name === ROUTE_MENU) {
              navigation.dispatch(DrawerActions.openDrawer())
              return
            }
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true
            })

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name)
            }
          }

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key
            })
          }

          let iconName = getIconName(route.name)

          if (route.name === 'Messages') {
            return null
          }
          return (
            <View
              pointerEvents={'box-none'}
              key={index}
              style={{ flex: 1, alignItems: 'center', position: 'relative' }}
            >
              <View
                style={{
                  position: 'absolute',
                  top: 26,
                  height: 55,
                  width: '100%',
                  backgroundColor: navColor
                }}
              ></View>
              <TouchableOpacity
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                style={{ flex: 1, height: iconBoxSize, alignItems: 'center' }}
                onPress={onPress}
                activeOpacity={1}
                onLongPress={onLongPress}
              >
                <View
                  style={{
                    backgroundColor: colors.brightOrange,
                    borderRadius: spacing.borderRadius,
                    width: iconBoxSize,
                    height: iconBoxSize,
                    justifyContent: 'center'
                  }}
                >
                  <View style={{ alignItems: 'center' }}>
                    {getTabIcon(iconName)}
                  </View>
                </View>
                <Text
                  style={[
                    {
                      color: isFocused ? colors.white : colors.blueLighter,
                      textAlign: 'center'
                    },
                    fonts.navButtonTitleStyle
                  ]}
                >
                  {label}
                </Text>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default BottomTabNavigator
