import React, { useContext, useMemo, useState } from 'react'
import _ from 'lodash'

import { View } from 'react-native'
import { ListItem } from 'react-native-elements'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import MSFESButton from '../MSFESButton'
import { showMessage } from 'react-native-flash-message'
import GlobalLoading from '../GlobalLoading'
import actions from '../../store/user/actions'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { CustomFunctionalityTimesheetPunchCard } from './CustomFunctionalityTimesheetPunchCard'
import { CustomFunctionalityBIBViewReport } from './CustomFunctionalityBIBViewReport'
import EntityEditContext from '../../contexts/EntityEditContext'
import { updateMode } from '../../screens/EntityAddComponent'
import { useSelector } from 'react-redux'
import { CustomFunctionalityRandomEntityPull } from './CustomFunctionalityRandomEntityPull'
import spacing from '../../styles/spacing'
export const ApprovalRequired = (props) => {
  const {
    isDisabledFromPermissions,
    editable,
    data: { field }
  } = props

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [, setIsSaving] = useState(false)

  const [, , , saveEntity] = useContext(EntityEditContext)

  const { role } = useSelector((state) => {
    const personnel_record = _.get(state, 'users.user.personnel_record', null)
    return {
      role: _.get(personnel_record, 'role', 'Tenant User')
    }
  })

  const canApprove = useMemo(() => {
    const permittedRoles = (
      props.data.field?.field_data?.params?.approverRoles ?? []
    ).map((r) => r.key ?? r)

    if (
      permittedRoles.includes('ALL') ||
      permittedRoles.includes(role) ||
      !permittedRoles.length
    ) {
      return true
    }
    return false
  })

  const onPressAction = () => {
    setIsSaving(true)
    setDisabled(true)
    setLoading(true)

    saveEntity({
      showLoading: false,
      showToasts: true,
      withPartial: {
        [field?.field_data?.property]: {
          approved_at: DateTime.fromJSDate(new Date()).toFormat(
            'yyyy-MM-dd h:mm a'
          ),
          value: true
        }
      },
      mode: updateMode
    })
      .then(() => {
        showMessage({
          message: 'Item approved.',
          type: 'success'
        })
      })
      .finally(() => {
        setDisabled(false)
        setLoading(false)
        setIsSaving(false)
      })
  }

  const isApproved = !!props.defaultValue?.approved_at

  const usedStyle = {
    ...props.style,
    ...(!editable && !isDisabledFromPermissions ? { opacity: 1 } : null)
  }
  const calculatedStyle = [
    usedStyle,
    !isApproved ? { flex: 1, minHeight: 66 } : undefined
  ]
  return (
    <View style={calculatedStyle}>
      <ListItem
        onPress={onPressAction}
        containerStyle={[
          ListItemAsFieldProps.containerStyle,
          !isApproved ? { minHeight: 66 } : null
        ]}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
            {isApproved ? 'Item is approved.' : 'Item is not approved.'}{' '}
          </ListItem.Title>
        </ListItem.Content>
        {!isApproved &&
          (editable || (!isDisabledFromPermissions && !canApprove)) && (
            <MSFESButton
              disabled={disabled || !props.data.entity?.id}
              style={{ marginTop: 8, justifyContent: 'flex-end' }}
              onPress={onPressAction}
              title={'Approve'}
            ></MSFESButton>
          )}
      </ListItem>
      {loading && <GlobalLoading />}
    </View>
  )
}
export const CustomFunctionalityActivationStatus = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const onPressAction = () => {
    setLoading(true)
    setDisabled(true)
    dispatch(actions.resendActivationEmail(props.data.entity.email_address))
      .then(() => {
        showMessage({
          message: 'Resent welcome email successfully.',
          type: 'success'
        })
      })
      .catch(() => {
        setDisabled(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const isVerified = props.data?.entity?.verified_status === 'verified'
  const calculatedStyle = [
    props.style,
    !isVerified
      ? {
          flex: 1,
          minHeight: 120,
          height: undefined
        }
      : undefined
  ]
  return (
    <View style={calculatedStyle}>
      <ListItem
        onPress={onPressAction}
        containerStyle={[
          ListItemAsFieldProps.containerStyle,
          !isVerified ? { minHeight: 120, height: undefined } : null
        ]}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title
            style={[
              props.inputStyle,
              InputSelectStyle,
              { marginTop: spacing.m1 }
            ]}
          >
            {isVerified
              ? 'User has previously signed in.'
              : 'User has not previously signed in.'}{' '}
          </ListItem.Title>
          {!isVerified && (
            <MSFESButton
              disabled={disabled}
              style={{ justifyContent: 'flex-end', marginTop: spacing.m1 }}
              onPress={onPressAction}
              title={'Resend Welcome Email'}
            ></MSFESButton>
          )}
        </ListItem.Content>
      </ListItem>
      {loading && <GlobalLoading />}
    </View>
  )
}

const _CustomFunctionalityFieldType = (props, ref) => {
  const customFunctionalityType = useMemo(() => {
    return _.get(
      props.data?.field,
      'field_data.params.customFunctionalityType',
      false
    )
  }, [])

  switch (customFunctionalityType) {
    case 'activation-status':
      /* Does not require forwarded ref */
      return <CustomFunctionalityActivationStatus {...props} />
    case 'random-entity-pull':
      return <CustomFunctionalityRandomEntityPull {...props} />
    case 'timesheet-punch-card':
      return <CustomFunctionalityTimesheetPunchCard {...props} ref={ref} />

    case 'bib-view-report':
      return <CustomFunctionalityBIBViewReport {...props} ref={ref} />
  }
}
export const CustomFunctionalityFieldType = React.forwardRef(
  _CustomFunctionalityFieldType
)
