import React, { useState } from 'react'
import { View } from 'react-native'
import { ListItem } from 'react-native-elements'
import ListItemAsFieldProps from './ListItemAsFieldProps'
import spacing from '../styles/spacing'
import { Rating } from 'react-native-ratings'

export const nestedInputExtraProps = {
  inputInternalStyle: { backgroundColor: 'transparent' }
}
export const StarRating = React.forwardRef((props, ref) => {
  const { defaultValue, editable, style } = props // Child props

  let minimumValue = props.data?.field?.field_data?.validation?.min ?? 0
  if (!minimumValue) {
    minimumValue = 0
  }
  minimumValue = parseInt(minimumValue)
  let maximumValue = props.data?.field?.field_data?.validation?.max ?? 5
  if (!maximumValue) {
    maximumValue = 5
  }
  maximumValue = parseInt(maximumValue)

  const [rating, setRating] = useState(parseInt(defaultValue ?? minimumValue))

  const handleRating = (newRating) => {
    setRating(newRating)
    if (props.onChangeText) {
      props.onChangeText(newRating)
    }
  }

  return (
    <View style={[style, { height: 'auto' }]}>
      <ListItem
        containerStyle={[
          ListItemAsFieldProps.containerStyle,
          { height: 'auto' }
        ]}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: spacing.m1
            }}
          >
            <Rating
              type="custom"
              ratingCount={maximumValue}
              minimumValue={minimumValue}
              maximumValue={maximumValue}
              tintColor={'#F2F4F5'}
              ratingColor={'#FF9933'}
              ratingBackgroundColor={'#223E4C'}
              showRating={false}
              disabled={!editable}
              imageSize={30}
              startingValue={rating}
              onFinishRating={handleRating}
              readonly={!editable}
            />
          </View>
        </ListItem.Content>
      </ListItem>
    </View>
  )
})
