import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tenantActions from '../../store/tenant/actions'
import SelectMultipleWithData from './SelectMultipleWithData'
import { castArray } from 'lodash'
//actions

export const SelectTenantMultiple = (props) => {
  const dispatch = useDispatch()

  const tenantsListRoot = useSelector((state) =>
    _.get(state, `tenants.tenants`, { data: [] })
  )

  // we will just always reload tenants - not exp check
  useEffect(() => {
    dispatch(tenantActions.listTenants())
  }, [])

  const data = tenantsListRoot.data?.map((t) => {
    t.label = t.display_name + ' (' + t.id + ')'
    return t
  })

  return (
    <SelectMultipleWithData
      {...props}
      dataRows={data}
      value={castArray(props.defaultValue)}
      labelField={'label'}
      onChangeText={(value, userInitiated) => {
        props.onChangeText(value, userInitiated)
      }}
    />
  )
}
