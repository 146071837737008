import React, { useMemo, useState } from 'react'
import { View } from 'react-native'
import { Overlay } from 'react-native-elements'
import { showMessage } from 'react-native-flash-message'
import { useSelector } from 'react-redux'
import {
  useGetModalDimensions,
  useIsDesktop
} from '../../libraries/shouldShowDesktop'
import EntityAddComponent, { addMode } from '../../screens/EntityAddComponent'
import { simplifyObject } from '../fields/SelectWithData'
import MSFESButton from '../MSFESButton'

const DocumentUploader = ({ folder }) => {
  const [uploadingBoxVisible, setUploadingBoxVisible] = useState(false)

  const { entityTypes, user } = useSelector((appState) => ({
    entityTypes: appState.entityTypes?.data ?? [],
    user: appState.users?.user ?? {}
  }))

  const documentEntityType = useMemo(() => {
    return entityTypes.find((e) => e.name === 'upload_document')
  }, [entityTypes])
  const prefillFieldsInEntity = {}
  const onDismiss = () => {
    setUploadingBoxVisible(false)
  }
  const onChangedCallback = () => {}

  const { calculatedHeight, calculatedWidth } = useGetModalDimensions()
  const isDesktop = useIsDesktop()
  return (
    <>
      <MSFESButton
        title={'Upload'}
        onPress={() => setUploadingBoxVisible(true)}
      />
      {uploadingBoxVisible && (
        <Overlay onBackdropPress={onDismiss} isVisible={true}>
          <View
            style={{
              maxHeight: calculatedHeight,
              flex: 1,
              maxWidth: calculatedWidth,
              minWidth: isDesktop ? undefined : calculatedWidth
            }}
          >
            <EntityAddComponent
              currentPage={1}
              onUpdatePage={() => {}}
              navigation={null}
              isInSplitView={true}
              isInsideModal={true}
              onCloseSplit={onDismiss}
              tenant={user.tenant_id}
              route={{
                params: {
                  ...prefillFieldsInEntity,
                  type: documentEntityType.name,
                  mode: addMode,
                  'field.folder': simplifyObject(folder),
                  page: 1
                }
              }}
              savedCallback={(response) => {
                showMessage({
                  message: documentEntityType.name + ' saved.',
                  type: 'success'
                })
                onChangedCallback && onChangedCallback(response)
                onDismiss()
              }}
            />
          </View>
        </Overlay>
      )}
    </>
  )
}

export default DocumentUploader
