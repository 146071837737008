// libraries
import React from 'react'

import { ActivityIndicator, View } from 'react-native'
// styles

//actions

// components

const LoadingOverlay = (props) => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'white',
        opacity: 0.7,
        justifyContent: 'center'
      }}
    >
      <ActivityIndicator />
    </View>
  )
}

export default LoadingOverlay
