import React from 'react'
import { Button } from 'react-native-elements'

import { View } from 'react-native'
import spacing from '../../styles/spacing'
import colors from '../../styles/colors'
export const PageButton = ({ option, active, onPress }) => {
  const foreColor = option.errors ? colors.redDark : colors.blueDarkest
  return (
    <View key={option.index} style={{ paddingTop: spacing.m0_5 }}>
      <Button
        title={`${option.name}${option.errors ? '*' : ''}`}
        buttonStyle={{
          borderRadius: 12,
          borderWidth: 1,
          borderColor: foreColor,
          backgroundColor: active ? foreColor : colors.white,
          maxWidth: 300
        }}
        titleStyle={{ color: active ? colors.white : foreColor }}
        type={active ? undefined : 'outline'}
        onPress={onPress}
      ></Button>
      {/* <ListItem
        key={option.index}
        onPress={() => onPress(option, index)}
        disabled={false}
        containerStyle={
          option.errors ? listItemContainerStyleError : listItemContainerStyle
        }
      >
        <ListItem.Content>
          <ListItem.Title
            style={
              option.errors && {
                color: colors.buttonDanger,
                fontWeight: 'bold'
              }
            }
          >
            {option.name}
            {option.errors ? '*' : ''}
          </ListItem.Title>
        </ListItem.Content>
        <MSFESCheckbox
          listitem
          checked={active}
          onPress={() => onPress(option, index)}
        />
      </ListItem> */}
    </View>
  )
}
