import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { evaluateActionTruth } from '../../store/entity/actions'
export const EvaluateActionTruth = ({ entity, entityType, action }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    dispatch(
      evaluateActionTruth({
        id: entity.id,
        actionId: action.offline_id,
        entityType
      })
    )
      .then((response) => {
        setData(response.result)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  return (
    <View>
      {loading && <ActivityIndicator />}
      {!loading && <Text>{data === true ? 'true' : 'false'}</Text>}
    </View>
  )
}
