import { Platform } from 'react-native'
import api from '../../data/api'

const updatePassword = ({ email, password, password_confirmation, token }) => {
  return (dispatch) => {
    const route = '/user/password-reset/update'
    const body = { email, password, password_confirmation, token }

    return api
      .post(route, body)
      .then(async (response) => {
        await dispatch({
          type: 'users/reset-password-initiated',
          email
        })

        await completeSignin(response, dispatch)

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/reset-password-error',
          error
        })

        throw error
      })
  }
}

const clearErrors = () => {
  return (dispatch) => {
    return dispatch({ type: 'users/clear-error' })
  }
}

const resendActivationEmail = (email) => {
  return () => {
    const route = '/user/resend-welcome'
    const body = { email }

    return api
      .post(route, body)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
const resetPassword = ({ email }) => {
  return (dispatch) => {
    const route = '/user/password-reset'
    const body = { email, source: Platform.OS === 'web' ? 'web' : 'mobile' }

    return api
      .post(route, body)
      .then(async (response) => {
        await dispatch({
          type: 'users/reset-password-initiated',
          email
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/reset-password-error',
          error
        })

        throw error
      })
  }
}

const completeSignin = async (response, dispatch) => {
  const { access_token, user, core_entity_types, core_entities } = response
  const { email } = user
  await dispatch({
    type: 'users/signin-success',
    email,
    access_token,
    core_entity_types,
    core_entities,
    user
  })
}
const attemptSignin = ({ email, password, mfa_code }) => {
  return (dispatch) => {
    const route = '/user/login'
    const body = { email, password, mfa_code }

    dispatch({
      type: 'users/signin-started'
    })
    return api
      .post(route, body)
      .then(async (response) => {
        await completeSignin(response, dispatch)

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/signin-error',
          error
        })

        throw error
      })
  }
}

export const requestGlobalToken = () => {
  return (dispatch) => {
    const route = '/user/select-global'

    dispatch({
      type: 'users/select-tenant-started'
    })
    dispatch({
      type: 'entity/reset'
    })
    return api
      .post(route, { tenant: null })
      .then(async (response) => {
        const { access_token, user } = response

        await completeSignin(response, dispatch)
        dispatch({
          type: 'users/select-tenant-success',
          access_token,
          user
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/select-tenant-error',
          error
        })

        throw error
      })
  }
}
export const selectTenant = ({ tenant, role = null }) => {
  return (dispatch) => {
    const route = '/user/select-tenant'

    dispatch({
      type: 'users/select-tenant-started'
    })
    dispatch({
      type: 'entity/reset'
    })
    return api
      .post(route, { tenant, role })
      .then(async (response) => {
        const { access_token, user } = response

        await completeSignin(response, dispatch)
        dispatch({
          type: 'users/select-tenant-success',
          access_token,
          user
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/select-tenant-error',
          error
        })

        throw error
      })
  }
}

const fetchUser = () => {
  return (dispatch) => {
    const route = '/user'

    dispatch({
      type: 'users/fetch-started'
    })
    return api
      .get(route)
      .then((response) => {
        const { data } = response
        dispatch({
          type: 'users/fetch-success',
          data
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/fetch-error',
          error
        })

        throw error
      })
  }
}

const purgeToken = () => {
  return {
    type: 'users/purge-token'
  }
}

const signout = () => {
  return (dispatch) => {
    const route = '/user/logout'

    dispatch({
      type: 'users/signout-started'
    })

    dispatch({
      type: 'entity/reset'
    })
    return api
      .post(route)
      .then((response) => {
        dispatch({
          type: 'users/signout-success'
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'users/signout-error',
          error
        })

        throw error
      })
  }
}

export default {
  clearErrors,
  resendActivationEmail,
  requestGlobalToken,
  attemptSignin,
  updatePassword,
  resetPassword,
  signout,
  fetchUser,
  selectTenant,
  purgeToken
}
