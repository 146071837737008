import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { ActivityFeedKioskScreen } from './ActivityFeedKioskScreen'
const KioskStack = createStackNavigator()
const KioskNavigator = () => {
  return (
    <KioskStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <KioskStack.Screen
        name="ActivityFeedKioskScreen"
        component={ActivityFeedKioskScreen}
      />
    </KioskStack.Navigator>
  )
}

export default KioskNavigator
