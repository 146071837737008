// libraries
import React, { memo, useState } from 'react'
import { View } from 'react-native'

import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html'
import _ from 'lodash'
import AutoHeightWebView from 'react-native-autoheight-webview'
import { ListItemLinkButton, openEmbeddedHtml } from '../ListItemLinkButton'
import ListItemAsFieldProps from '../ListItemAsFieldProps'

// styles

//actions

export const customHTMLElementModels = {
  font: HTMLElementModel.fromCustomModel({
    tagName: 'font',
    contentModel: HTMLContentModel.mixed,
    getUADerivedStyleFromAttributes({ face, color, size }) {
      /* Font tag is generated by the rich text generator
        but not handled by the renderer.
        We have added a definition here for it. */
      let style = {}
      if (face) {
        style.fontFamily = face
      }
      if (color) {
        style.color = color
      }
      if (size) {
        // handle size such as specified in the HTML4 standard. This value
        // IS NOT in pixels. It can be absolute (1 to 7) or relative (-7, +7):
        // https://www.w3.org/TR/html4/present/graphics.html#edef-FONT
        // implement your solution here
      }

      return style
    }
  }),
  b: HTMLElementModel.fromCustomModel({
    tagName: 'b',
    /* B is generated by the generator but not handled. So we have added it here. */
    contentModel: HTMLContentModel.mixed,
    getUADerivedStyleFromAttributes() {
      let style = {}
      style.fontWeight = 'bold'
      return style
    }
  })
}

export const ViewDescription = memo(
  React.forwardRef((props, ref) => {
    const { style = {} } = props

    const { textField = 'data.field.field_data.params.descriptionText' } = props
    const descriptionText = _.get(props, textField, '')

    const linkToTarget = !_.get(
      props,
      'data.field.field_data.params.displayInline',
      false
    )

    const [uuid] = useState(Math.random())

    /*** Warning --
     *
     * Patch on 'react-native-autoheight-webview
     * (https://github.com/iou90/react-native-autoheight-webview/issues/226)
     * uses the page title as an ID (🤢)
     * Instead of rewriting the patch we can just use it, with a per isntance ID.
     */
    const stylesheet = `
  <title>${uuid}</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <style>
  html, body {
    font-family: Arial;
    overflow:hidden;
  }
  @media(width > 500px) {

  }
  iframe {
    flex: 1;
    max-width: 100%;
  }
  </style>
  `

    /* end warning! */

    const [iframeHeight, setIframeHeight] = useState(null)
    return (
      <View style={style}>
        <View style={{ height: iframeHeight }}>
          {linkToTarget && (
            <>
              {descriptionText && (
                <ListItemLinkButton
                  onPress={() => {
                    openEmbeddedHtml(stylesheet + descriptionText)
                  }}
                  title={'View Content'}
                  containerStyle={ListItemAsFieldProps.containerStyle}
                  showChevron={false}
                />
              )}
              {!descriptionText && (
                <ListItemLinkButton
                  title={'(No content added)'}
                  containerStyle={ListItemAsFieldProps.containerStyle}
                  showChevron={false}
                />
              )}
            </>
          )}

          {/* OG webview style. Replaced with popout. */}
          {!linkToTarget && (
            <AutoHeightWebView
              scrollEnabled={false}
              nestedScrollEnabled={false}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              onSizeUpdated={(args) => {
                setIframeHeight(args.height < 200 ? 200 : args.height)
              }}
              androidLayerType="hardware"
              androidHardwareAccelerationDisabled={true}
              originWhitelist={['*']}
              style={{
                flex: 1,
                width: '100%',
                height: 1,
                minHeight: 200,
                opacity: 0.99
              }}
              source={{ html: stylesheet + descriptionText }}
            />
          )}
        </View>
      </View>
    )
  })
)
