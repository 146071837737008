import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import entityActions from '../store/entity/actions'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator } from 'react-native-paper'
import spacing from '../styles/spacing'
import CurrentlyNoItems from './CurrentlyNoItems'
import { ScrollView } from 'react-native-gesture-handler'
import { DocumentListWrapper } from './DocumentReportList/DocumentListWrapper'
import { DocumentTableWrapper } from './DocumentReportList/DocumentTableWrapper'
import _ from 'lodash'
const DocumentReportList = ({ relevantEntityTypes = [], folder }) => {
  const dispatch = useDispatch()
  const [queryParams] = useState({
    page: 1,
    searchText: null,
    filters: null
  })

  /* eslint-disable-next-line no-unused-vars */
  const [page, setPage] = useState(1)
  /* eslint-disable-next-line no-unused-vars */
  const [pages, setPages] = useState(1)

  const [data, setData] = useState([])

  const { gridOrList, entityTypes } = useSelector((appState) => {
    return {
      gridOrList: _.get(appState.app, 'gridOrList', 'grid'),
      entityTypes: appState.entityTypes?.data
    }
  })

  const uploadDocumentEntityType = useMemo(() => {
    return entityTypes.find((e) => e.name == 'upload_document')
  }, [entityTypes])

  const [loading, setLoading] = useState(false)
  const { searchText } = queryParams

  const fetchItemsForParams = () => {
    if (relevantEntityTypes.length) {
      setLoading(true)

      return dispatch(
        entityActions.listEntity(
          [...relevantEntityTypes, uploadDocumentEntityType],
          {
            page,
            searchText,
            filter: { in_folder: folder.id },
            detailed: true,
            files: true
          }
        )
      )
        .then((response) => {
          if (response.meta.current_page === 1) {
            setData(response.data)
          } else {
            setData((data) => [...data, ...response.data])
          }
          setPages(response.meta.last_page)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (relevantEntityTypes.length) {
      setData([])
      setPage(1)
    }
  }, [relevantEntityTypes])

  useEffect(() => {
    fetchItemsForParams()
  }, [page])

  const loadMoreItems = () => {
    if (page < pages) {
      setPage(page + 1)
    }
  }

  return (
    <View style={{ paddingHorizontal: spacing.m2, flex: 1 }}>
      {loading && <ActivityIndicator />}
      <View style={{ flex: 1 }}>
        {!data.length && !loading && (
          <CurrentlyNoItems label={'No documents found.'} />
        )}
        {!!data.length && (
          <View style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }}>
              {(gridOrList === 'table' || gridOrList === 'grid') && (
                <DocumentTableWrapper
                  items={data}
                  loadMoreItems={loadMoreItems}
                />
              )}
              {gridOrList === 'list' && (
                <DocumentListWrapper
                  items={data}
                  loadMoreItems={loadMoreItems}
                />
              )}
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  )
}

export default DocumentReportList
