
import Analytics from 'appcenter-analytics';
import { Platform } from 'react-native';

const analytics = {
  TrackEvent: (event, params = null) => {

    if(Platform.OS === "web"){

    } else {
      if (params) {
        let paramsSanitised = {}
        Object.keys(params).map(k => {
          if (params[k] !== null) {
            paramsSanitised[k] = params[k]
          }
        })
  
        try{
          Analytics.trackEvent(event, paramsSanitised)

        }
        catch(e){
          console.log("analytics error", e, event, params)
        }
      } else if(event){
        Analytics.trackEvent(event);
      }
    }


    
  }
}

export default analytics