import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import colors from '../styles/colors'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import spacing from '../styles/spacing'
import { Overlay, ListItem } from 'react-native-elements'
import OverlayHeader from './OverlayHeader'
import entityActions, { getFilterString } from '../store/entity/actions'
import MSFESIcon from './MSFESIcon'
import useBetterLinkTo from '../useBetterLinkTo'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { ActivityIndicator } from 'react-native-paper'
import { DateTime } from 'luxon'
export default function MSFESAlert() {
  const filter = { assignee: 'me' }
  const args = { detailed: true, place: 'created' }
  const filterString = sanitiseFilterString(getFilterString(filter, args))

  const dispatch = useDispatch()
  const { queuedEntitiesForSave } = useSelector((state) => {
    return {
      queuedEntitiesForSave: state.entities.queuedEntitiesForSave ?? []
    }
  })

  const linkTo = useBetterLinkTo()

  const [processingQueue, setProcessingQueue] = useState(false)

  const noData = { data: null, meta: null, hasSeenData: false }

  const { alertListWrapper } = useSelector((state) => {
    return {
      alertListWrapper:
        state.entities[`${'system_alert' + filterString}`] ?? noData
    }
  })

  const alertsFiltered = useMemo(() => {
    const nowUtc = DateTime.now().toUTC().toISO()
    return alertListWrapper?.data?.filter((f) => {
      // get only alerts where ends_at is less than now
      return (f.ends_at?.valueUtc ?? f.ends_at?.value) > nowUtc
    })
  }, [alertListWrapper])

  useEffect(() => {
    dispatch(
      entityActions.listEntity(
        { name: 'system_alert' },
        {
          ...args,
          filter,
          isFullRefresh: true
        }
      )
    )
  }, [])

  const [viewingAlerts, setViewingAlerts] = useState(false)

  const onBackdropPress = useCallback(() => {
    setViewingAlerts(false)
  })
  return (
    <>
      {!!alertsFiltered?.length && (
        <TouchableOpacity
          style={style.notificationBar}
          onPress={() => setViewingAlerts(true)}
        >
          <Text style={style.notificationBarHeading}>
            {alertsFiltered.length} active alerts.
          </Text>
          <MSFESIcon
            name={'chevron-right'}
            size={12}
            style={{ paddingVertical: 2 }}
            color={colors.validationErrorText}
            type="font-awesome"
            onPress={() => {}}
          />
        </TouchableOpacity>
      )}
      {!!queuedEntitiesForSave.length && (
        <View style={style.notificationBarQueuedUploads}>
          <Text style={style.notificationBarHeading}>
            {queuedEntitiesForSave.length} updates are queued.
          </Text>
          <View style={{ paddingRight: spacing.m2 }}>
            {processingQueue && (
              <ActivityIndicator size="small" color="white" />
            )}
            {!processingQueue && (
              <TouchableOpacity
                onPress={() => {
                  setProcessingQueue(true)
                  dispatch(entityActions.processEntireEntityQueue())
                    .then(() => {
                      setProcessingQueue(false)
                    })
                    .finally(() => {
                      setProcessingQueue(false)
                    })
                }}
              >
                <MSFESIcon
                  name={'upload'}
                  size={16}
                  style={{ paddingVertical: 2 }}
                  color={colors.validationErrorText}
                  type="font-awesome"
                />
              </TouchableOpacity>
            )}
          </View>
          <MSFESIcon
            name={'trash'}
            size={16}
            style={{ paddingVertical: 2 }}
            color={colors.validationErrorText}
            type="font-awesome"
            onPress={() => {
              dispatch(entityActions.dumpQueue())
            }}
          />
        </View>
      )}
      {viewingAlerts && (
        <Overlay visible={true} onBackdropPress={onBackdropPress}>
          <OverlayHeader>Active Alerts</OverlayHeader>
          {alertListWrapper.data.map((d, index) => {
            return (
              <ListItem
                key={index.toString()}
                bottomDivider={true}
                onPress={() => {
                  onBackdropPress()
                  linkTo(d.uri)
                }}
              >
                <ListItem.Content>
                  <ListItem.Title>{d.title}</ListItem.Title>
                  <ListItem.Subtitle>{d.alert}</ListItem.Subtitle>
                  {d.ends_at ? (
                    <ListItem.Subtitle>
                      Ends: {d.ends_at?.display_value}
                    </ListItem.Subtitle>
                  ) : null}
                </ListItem.Content>
              </ListItem>
            )

            // <View key={d.id}>

            //   <Text style={style.heading}>
            //     {d.title}
            //   </Text>
            //   <Text style={style.text}>
            //     {d.alert} </Text>
            //   <Text style={style.textDate}>
            //     Ends: {d.ends_at?.display_value}
            //   </Text>
            //   {index < alertListWrapper.data.length - 1 && <Divider style={{ marginVertical: spacing.m1 }} />}
            // </View>
          })}
        </Overlay>
      )}
    </>
  )
}

const style = StyleSheet.create({
  notificationBar: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.validationErrorBackground,
    flexDirection: 'row'
  },
  notificationBarQueuedUploads: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.lightOrange,
    flexDirection: 'row'
  },
  notificationBarHeading: {
    color: colors.validationErrorText,
    fontSize: 16,
    flex: 1
  },
  view: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.validationErrorBackground
  },
  text: {},
  textDate: {
    fontSize: 10
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold'
  }
})
