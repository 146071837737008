import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getSortedEntitiesFromStore,
  noData
} from '../../components/HomeContainer'
import MSFESButton from '../../components/MSFESButton'
import { View } from 'react-native'
import shared from '../../styles/shared'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
export const ScreenButtonsBar = ({ entityType }) => {
  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const { screenButtonsWrapper } = entityTypeScreenButtons
    ? useSelector((appState) => {
        const screenButtonsWrapper = getSortedEntitiesFromStore(
          entityTypeScreenButtons,
          appState
        )

        return { screenButtonsWrapper }
      })
    : { screenButtonsWrapper: noData }

  const thisScreenButtons = useMemo(() => {
    return (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
      (r) =>
        r.entity_list_screen?.id == entityType?.id &&
        r.position?.key === 'view_entity_list'
    )
  })

  return (
    !!thisScreenButtons?.length && (
      <View
        style={[
          shared.screenView,
          { alignItems: 'flex-start', paddingHorizontal: 8 }
        ]}
      >
        <View style={{ maxWidth: 400, minWidth: 300, flex: 1 }}>
          {thisScreenButtons.map((thisScreenButton, index) => (
            <MSFESButton
              key={index}
              navigateTo={thisScreenButton.navigate_to}
              icon={{ name: thisScreenButton.icon, type: 'font-awesome' }}
              title={thisScreenButton.name}
            />
          ))}
        </View>
      </View>
    )
  )
}
