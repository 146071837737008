import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { useDiscoverEntityEntityType } from './useDiscoverEntityEntityType'

export const useShowMyDepartmentUI = (user) => {
  const { tenants } = useSelector((appState) => {
    return {
      tenants: _.get(appState, 'tenants.tenants.data', [])
    }
  })

  const currentTenantEntity = useMemo(() => {
    if (tenants && user) {
      const tenantId = user.tenant_record?.tenant_id

      const tenant = tenants.find((t) => t.id == tenantId)

      return tenant?.tenant_record
    }

    return null
  }, [tenants, user])

  const { entityType: tenantEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    'tenant'
  )

  const [showMyDepartment, setShowMyDepartment] = useState(false)

  return [
    showMyDepartment,
    setShowMyDepartment,
    tenantEntityType,
    currentTenantEntity
  ]
}
