// libraries
import React, { useEffect, useMemo, useState } from 'react'
import { View, Platform } from 'react-native'
import _ from 'lodash'

import colors from '../../styles/colors'
import { ListItem, Overlay } from 'react-native-elements'
import { FlatList } from 'react-native'
import OverlayHeader from '../OverlayHeader'
import MSFESAvatar from '../MSFESAvatar'
import { Portal } from 'react-native-paper'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import { SearchBar } from 'react-native-elements'
import MSFESHeading from '../MSFESHeading'
import spacing from '../../styles/spacing'
import {
  formatDataRows,
  getKey,
  getLabel,
  searchStyleProps,
  simplifyObject
} from './SelectWithData'

export const onValueToggleWrapped = (
  value,
  dataRows,
  defaultValue,
  keyField,
  keyFieldResolver,
  onChangeText,
  objectIsValue = true,
  callback = null,
  valueField = null
) => {
  const userInitiated = value !== defaultValue && value !== ''

  const localValues = defaultValue ? _.castArray(defaultValue) : []

  if (objectIsValue) {
    // fetch object and pass back up.
    const matchingObject = dataRows.find((d) => {
      const rowKey = getKey(d, keyFieldResolver, keyField)
      const selectedKey = getKey(value, keyFieldResolver, keyField)
      const isMatch = rowKey == value || rowKey === selectedKey

      return isMatch
    })

    const existingIndex = localValues.findIndex(
      (v) =>
        getKey(v, keyFieldResolver, keyField) ===
        getKey(value, keyFieldResolver, keyField)
    )

    const simplifiedObject = simplifyObject(
      matchingObject,
      keyField,
      valueField
    )

    if (existingIndex !== -1) {
      // exists
      localValues.splice(existingIndex, 1)
      onChangeText(localValues, userInitiated)
    } else {
      localValues.push(simplifiedObject)
      onChangeText(localValues, userInitiated)
    }
  } else {
    const existingIndex = localValues.findIndex(
      (v) => getKey(v, keyFieldResolver, keyField) == value
    )

    if (existingIndex !== -1) {
      // exists
      localValues.splice(existingIndex, 1)
      onChangeText(localValues, userInitiated)
    } else {
      localValues.push(value)
      onChangeText(localValues, userInitiated)
    }
  }

  callback && callback()
}

export const SelectMultipleWithData = React.forwardRef((props, ref) => {
  const {
    onEndReached = () => {},
    placeholder,
    value,
    onChangeText,
    style,
    dataRows = [],
    editable = true,
    valueField = 'id',
    keyField = 'id',
    labelField = 'display_name',
    iconField = null,
    objectIsValue = true,
    keyFieldResolver = false,
    onDropdownStatusChanged = null,
    groupByFunction = null,
    sortByFunction = (el) => el
  } = props

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (props.defaultValue && props.data?.entity?.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      if (typeof props.defaultValue === 'object') {
        // if the new key supplied does not match the current one (in default value), propagate it up.
        // if (getKey(selectedItem, keyFieldResolver, keyField) !== getKey(props.defaultValue, keyFieldResolver, keyField)) {
        //   onChangeText({ [keyField]: getKey(props.defaultValue, keyFieldResolver, keyField) }, false)
        // }
      } else {
        onChangeText({ [keyField]: props.defaultValue }, false)
      }
    }
  }, [props.defaultValue?.key])

  const dataFormatted = useMemo(() => {
    return _.sortBy(
      formatDataRows(
        dataRows,
        keyFieldResolver,
        keyField,
        valueField,
        labelField,
        iconField,
        sortByFunction
      ),
      groupByFunction
    )
  }, [
    dataRows,
    keyFieldResolver,
    keyField,
    valueField,
    labelField,
    iconField,
    sortByFunction,
    groupByFunction
  ])

  const dataFiltered = useMemo(() => {
    const searchValueLower = searchValue.toLowerCase()

    return dataFormatted.filter((f) =>
      searchValueLower
        ? getLabel(f, labelField).toLowerCase().includes(searchValueLower)
        : true
    )
  }, [dataFormatted, searchValue])

  const [showDropDown, setShowDropDown] = useState(false)

  useEffect(() => {
    onDropdownStatusChanged && onDropdownStatusChanged(showDropDown)
  }, [showDropDown])

  const selectedItems = useMemo(() => {
    const matchingObjects = dataRows.filter((d) => {
      const rowKey = getKey(d, keyFieldResolver, keyField)
      const propsValueArray = props.value ? _.castArray(props.value) : []
      const isMatch = !!propsValueArray?.find(
        (v) => getKey(v, keyFieldResolver, keyField) === rowKey
      )

      return isMatch
    })

    if (!matchingObjects.length && props.value?.length) {
      return props.value
    } else {
      return matchingObjects
    }
  }, [dataFormatted, value])

  const placeholderText = editable
    ? placeholder
      ? placeholder
      : 'Select an item'
    : '(None)'

  const searchTextChanged = (searchValue) => {
    setSearchValue(searchValue)
  }
  const onSearchCancel = () => {
    searchTextChanged('')
  }

  const dropdownOverlay = useMemo(() => {
    return (
      showDropDown && (
        <Overlay
          isVisible={true}
          onBackdropPress={() => setShowDropDown(false)}
          overlayStyle={{ padding: 0, margin: 0 }}
        >
          <OverlayHeader>{placeholderText}</OverlayHeader>
          {dataFormatted.length > 5 && (
            <View>
              <SearchBar
                autoFocus={true}
                placeholder="Search"
                onClear={onSearchCancel}
                lightTheme={true}
                {...searchStyleProps}
                value={searchValue}
                onChangeText={searchTextChanged}
              />
            </View>
          )}
          <FlatList
            style={{ maxHeight: 400, minWidth: 200 }}
            keyExtractor={(item) => {
              const key = '' + getKey(item, keyFieldResolver, keyField)
              return key
            }}
            onEndReached={onEndReached}
            onEndReachedThreshold={2}
            data={dataFiltered}
            renderItem={({ item, index }) => {
              const needsHeader =
                (groupByFunction && index == 0) ||
                (index > 0 &&
                  groupByFunction &&
                  groupByFunction(dataFiltered[index - 1]) !==
                    groupByFunction(dataFiltered[index]))

              const key = getKey(item, keyFieldResolver, keyField)

              const active =
                selectedItems.findIndex(
                  (s) => getKey(s, keyFieldResolver, keyField) === key
                ) !== -1

              const listItem = (
                <ListItem
                  key={index.toString()}
                  onPress={() =>
                    onValueToggleWrapped(
                      item,
                      dataRows,
                      props.defaultValue,
                      keyField,
                      keyFieldResolver,
                      onChangeText,
                      objectIsValue,
                      null /*() => setShowDropDown(false)*/,
                      valueField
                    )
                  }
                >
                  {item?.image ? (
                    <MSFESAvatar
                      showLabel={false}
                      rounded={false}
                      size={item.imageSize}
                      source={item.image}
                    />
                  ) : null}
                  {item?.icon ? (
                    <MSFESAvatar
                      showLabel={false}
                      icon={{ name: item[iconField], type: 'font-awesome' }}
                    />
                  ) : null}
                  <ListItem.Content>
                    <ListItem.Title>
                      {getLabel(item, labelField)}
                    </ListItem.Title>
                  </ListItem.Content>
                  {active && (
                    <ListItem.Chevron
                      name="check"
                      type="font-awesome"
                      size={14}
                      style={{ marginBottom: -2 }}
                      color={colors.moduleIcon}
                    />
                  )}
                </ListItem>
              )

              if (needsHeader) {
                const Header = (
                  <MSFESHeading
                    key={'Header_' + index}
                    textStyle={{ marginHorizontal: spacing.m2 }}
                  >
                    {groupByFunction(dataFiltered[index])}
                  </MSFESHeading>
                ) // <View><Text></Text></View>
                return [Header, listItem]
              } else {
                return listItem
              }
            }}
          ></FlatList>
        </Overlay>
      )
    )
  }, [dataFormatted, labelField, keyField, showDropDown, searchValue])
  return (
    <View style={[style]}>
      <View>
        <ListItem
          bottomDivider
          containerStyle={ListItemAsFieldProps.containerStyle}
          onPress={
            editable ? () => editable && setShowDropDown(true) : undefined
          }
          {...ListItemAsFieldProps.touchableProps}
        >
          {/* {selectedItem?.image ? <MSFESAvatar showLabel={false} source={{ uri: selectedItem.image }} /> : null}
          {selectedItem?.icon && <MSFESAvatar showLabel={false} icon={{name: selectedItem.icon, type: 'font-awesome'}} />} */}
          <ListItem.Content>
            <ListItem.Title style={InputSelectStyle}>
              {selectedItems.length > 0
                ? selectedItems
                    .map((selectedItem) => getLabel(selectedItem, labelField))
                    .join(', ')
                : placeholderText}
            </ListItem.Title>
          </ListItem.Content>
          {editable && (
            <ListItem.Chevron
              name="chevron-down"
              type="font-awesome"
              size={14}
              style={{ marginBottom: -2 }}
              color={colors.moduleIcon}
            />
          )}
        </ListItem>

        {Platform.OS === 'web' && (
          <>{showDropDown && <Portal>{dropdownOverlay}</Portal>}</>
        )}
        {Platform.OS !== 'web' && <>{showDropDown && dropdownOverlay}</>}
      </View>
    </View>
  )
})

export default SelectMultipleWithData
