import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View, Text } from 'react-native'
import { SelectWithData } from './SelectWithData'
import _ from 'lodash'

export const EntityTypeFieldPicker = React.forwardRef((props, ref) => {
  const { entityTypeFields, additionalOptions, filterFunction = f =>f } = props

  const { onEntityTypeFieldSelected } = props

  const fieldsWithAdditional = _.filter([...entityTypeFields, ...additionalOptions], filterFunction);
  return (
    <View>
      <SelectWithData
        {...props}
        dataRows={fieldsWithAdditional}
        labelField={'field_data.title'}
        keyFieldResolver={(obj) => {
          return obj && (obj.id ? obj.id : obj.offline_id)
        }}
        objectIsValue={true}
        onChangeText={onEntityTypeFieldSelected}
      />
    </View>
  )
})

EntityTypeFieldPicker.defaultProps = {
  additionalOptions: []
}
