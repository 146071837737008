import React from 'react'
import { View, Image, useWindowDimensions, Text } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import colors from '../styles/colors'

const SignInFireman = () => {
  const { height, width } = useWindowDimensions()
  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black'
      }}
      pointerEvents={'none'}
    >
      <Image
        height={height}
        width={width}
        style={{ height, width, opacity: 0.4 }}
        source={require('../images/firefighter.jpeg')}
        resizeMode={'cover'}
      />
      <View
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(172,24,45,0.7)'
        }}
        pointerEvents={'none'}
      >
        <View
          style={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}
          pointerEvents={'none'}
        >
          <SafeAreaView>
            <Text
              style={{ color: colors.white, textAlign: 'center', fontSize: 12 }}
            >
              Powered by Management Solutions For Emergency Services
            </Text>
          </SafeAreaView>
        </View>
      </View>
    </View>
  )
}

export default SignInFireman
