import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useDashboardContext } from '../contexts/DashboardContext'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import fonts from '../styles/fonts'
import spacing from '../styles/spacing'
import colors from '../styles/colors'

export const TopePanelDashboard = ({ dashboards }) => {
  const { currentDashboard, setCurrentDashboard } = useDashboardContext()

  const dashboardsArray = dashboards?.data

  const handleForward = () => {
    setCurrentDashboard(
      (prevIndex) => (prevIndex + 1) % dashboardsArray?.length
    )
  }

  const handleBack = () => {
    setCurrentDashboard((prevIndex) => {
      return prevIndex === 0 ? dashboardsArray?.length - 1 : prevIndex - 1
    })
  }

  return (
    <View
      style={[
        {
          paddingHorizontal: spacing.m1_5,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: colors.white,
          width: '100%',
          height: 84,
          borderBottomWidth: 1,
          borderBottomColor: colors.lightestGray,
          flexShrink: 0
        }
      ]}
    >
      <TouchableOpacity
        style={{
          width: 40,
          height: 40,
          flexShrink: 0,
          borderWidth: 1,
          borderRadius: spacing.m1_5 - 2,
          borderColor: colors.lightestGray,
          backgroundColor: colors.white,
          marginLeft: spacing.m1_5 - 2
        }}
        onPress={handleBack}
      >
        <MaterialIcons
          style={[
            fonts.materialIconArrowStyle,
            {
              color: colors.mediumGray,
              paddingTop: spacing.m1_5,
              paddingLeft: spacing.m1_5
            }
          ]}
          name="arrow-back-ios"
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          width: 40,
          height: 40,
          flexShrink: 0,
          borderWidth: 1,
          borderRadius: spacing.m1_5 - 2,
          borderColor: colors.lightestGray,
          backgroundColor: colors.white,
          marginLeft: spacing.m1_5 - 2
        }}
        onPress={handleForward}
      >
        <MaterialIcons
          style={[
            fonts.materialIconArrowStyle,
            {
              color: colors.mediumGray,
              paddingTop: spacing.m1_5,
              paddingLeft: spacing.m1_5
            }
          ]}
          name="arrow-forward-ios"
        />
      </TouchableOpacity>
      <Text
        style={[
          {
            paddingLeft: spacing.m3
          },
          fonts.headerStyle
        ]}
      >
        {dashboardsArray?.[currentDashboard]?.dashboard_name ||
          (!dashboardsArray && 'no dashboard available')}
      </Text>
    </View>
  )
}
