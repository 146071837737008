import React, { useState } from 'react'
import { View, Text, Dimensions } from 'react-native'
import colors from '../styles/colors'
import fonts from '../styles/fonts'

import MSFESIcon from './MSFESIcon'
import { TouchableOpacity } from 'react-native'
import { Overlay } from 'react-native-elements'
import spacing from '../styles/spacing'
import OverlayHeader from './OverlayHeader'

export const getLabelTitleColor = (props) => {
  let titleColor = 'white'

  if (props.labelColor) {
    titleColor = props.labelColor
  } else {
    if (props.dark) {
      titleColor = colors.inputLabelDark

      if (props.isError) {
        titleColor = colors.lightRed
      }
    } else {
      titleColor = colors.inputLabel

      if (props.isError) {
        titleColor = colors.redDark
      }
    }
  }

  return titleColor
}

export const getLabelInputColor = (props) => {
  let inputColor

  if (props.dark) {
    inputColor = colors.inputTextDark
  } else {
    inputColor = colors.inputText
  }

  return inputColor
}

export default function MSFESLabel(props) {
  let titleColor = getLabelTitleColor(props)
  // set up title style
  const labelStyle = [
    props.labelStyle,
    {
      color: titleColor,
      fontFamily: fonts.inputLabelFace,
      fontSize: fonts.inputLabelSize
    }
  ]

  // set up title text
  const {
    label,
    required,
    helpText = null,
    isDisabledFromPermissions = false,
    hasVisibilityRules = false,
    dataAwaitingApproval = null
  } = props

  const labelTransformed = label // && (capitalize ? label.toUpperCase() : label);

  if (props.rightIcon) {
    props.rightIcon.color = colors.iconDark
  }

  const [helpVisible, setHelpVisible] = useState(false)

  const toggleHelpText = () => {
    setHelpVisible(!helpVisible)
  }

  const requiredStyle = { color: colors.validationErrorBackgroundDark }

  const width = Dimensions.get('window').width
  const overlayWidth = width < 400 ? 300 : 400
  return (
    <View style={[{ flexDirection: 'row' }, props.style]}>
      <Text style={labelStyle}>
        {labelTransformed} {required && <Text style={requiredStyle}>*</Text>}
      </Text>
      {helpText && (
        <TouchableOpacity
          onPress={toggleHelpText}
          style={{ paddingLeft: spacing.m1 }}
        >
          <MSFESIcon name="info-circle" size={16} color={colors.labelGray} />
        </TouchableOpacity>
      )}

      {isDisabledFromPermissions && (
        <MSFESIcon
          name="lock"
          size={12}
          color={colors.labelGray}
          style={{ marginLeft: 2, paddingTop: 2 }}
        />
      )}
      {hasVisibilityRules && (
        <MSFESIcon
          name="eye"
          size={12}
          color={colors.labelGray}
          style={{ marginLeft: 2, paddingTop: 2 }}
        />
      )}
      {!!dataAwaitingApproval && (
        <MSFESIcon
          name="edit"
          size={12}
          color={colors.labelGray}
          style={{ marginLeft: 2, paddingTop: 2 }}
        />
      )}
      {helpVisible && (
        <Overlay
          overlayStyle={{ minWidth: overlayWidth }}
          isVisible={true}
          onBackdropPress={toggleHelpText}
        >
          <OverlayHeader>{label}</OverlayHeader>

          <View style={{ padding: spacing.m2 }}>
            <Text>{helpText}</Text>
          </View>
        </Overlay>
      )}
    </View>
  )
}
