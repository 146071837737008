import { evaluateRule } from '../components/query/evaluateRule'
import { useSelector } from 'react-redux'
import { useDiscoverEntityEntityType } from './useDiscoverEntityEntityType'
import { getSortedEntitiesFromStore } from '../components/HomeContainer'
import { TENANT_LOGIC_BLOCK_ENTITY_TYPE } from '../libraries/supportedEntityTypeProperties'
export const getSubcategories = ({ categoryId, categories }) => {
  return {
    categoriesWithChildCategories: (categories ?? []).filter(
      (category) =>
        category.parent_category && category.parent_category?.id === categoryId
    )
  }
}

export const useChildCategoriesWithPermissionResolver = () => {
  const {
    treeCategoriesListWrapper,
    systemTenantLogicBlockEntities
  } = useSelector((appState) => {
    const { entityType: entityTypeTreeCategory } = useDiscoverEntityEntityType(
      null,
      null,
      'tree_category'
    )

    const treeCategoriesListWrapper = getSortedEntitiesFromStore(
      entityTypeTreeCategory,
      appState
    )

    const systemTenantLogicBlockEntities = getSortedEntitiesFromStore(
      TENANT_LOGIC_BLOCK_ENTITY_TYPE,
      appState
    )

    return { treeCategoriesListWrapper, systemTenantLogicBlockEntities }
  })

  const { user } = useSelector((appState) => ({
    user: appState?.users?.user
  }))

  return ({ parentId, isHome }) => {
    let { categoriesWithChildCategories } = getSubcategories({
      categoryId: parentId,
      categories: treeCategoriesListWrapper?.data,
      isHome
    })

    const matchSubject = {
      ...user,
      personnel: user?.personnel_record,
      client: user?.tenant_record
    }

    //filter down using display rules
    categoriesWithChildCategories = (
      categoriesWithChildCategories ?? []
    ).filter((cat) => {
      let shouldShow = true

      const displayRules = systemTenantLogicBlockEntities.data.filter(
        (e) => e.tree_category?.id == cat?.id
      )

      // there's display rules, so now we're false until true.
      if (displayRules && displayRules.length) {
        shouldShow = false
        const result = evaluateRule(
          displayRules[0].logic?.json?.logic,
          matchSubject,
          displayRules[0].show_when_true?.key == 'show_when_true'
        )
        if (result) {
          shouldShow = true
        }
      }

      return shouldShow
    })

    return { categoriesWithChildCategories }
  }
}
