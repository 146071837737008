import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { Divider } from 'react-native-paper'
import spacing from '../styles/spacing'
import { ViewDescription } from './fields/ViewDescription'
import MSFESButton from './MSFESButton'
import MSFESHeading from './MSFESHeading'

const EntityEditQuizFinishBlock = ({
  definition,
  onFinishQuiz,
  disabled,
  field
}) => {
  const onFinishQuizPressed = () => {
    onFinishQuiz()
  }

  return (
    <View>
      <Divider style={{ marginVertical: spacing.m2 }} />

      <MSFESButton
        small
        title="Complete Quiz"
        containerStyle={{ padding: 0, flex: 1 }}
        disabled={disabled}
        onPress={onFinishQuizPressed}
      />
    </View>
  )
}

export default EntityEditQuizFinishBlock
