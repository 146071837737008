import React, { useMemo } from 'react'
import _ from 'lodash'
import {
  cellStyle,
  sortColumnHeaders
} from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import { Text } from 'react-native'
const { DateTime } = require('luxon')
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
import { DataTableCell } from '../../screens/EntityListScreenHelpers/DataTableCell'
import { DataTable } from 'react-native-paper'
import { IsGenericEntityIdentifier } from '../GridItemButton'
import { useBetterLinkTo } from '../../useBetterLinkTo'
import { flattenEntity } from '../../helpers/entity'
import { useTimeFormat } from '../../contexts/TimeFormatContext'
import { getDateTimeFormatForTimeFormat } from '../fields/DateTimePicker'
import { useTenantOrGlobalAdmin } from '../../hooks/useTenantOrGlobalAdmin'

export const ActivityFeedRow = ({ entity, index }) => {
  const linkTo = useBetterLinkTo()
  const { isGlobalAdmin } = useTenantOrGlobalAdmin()

  const { entityType } = useDiscoverEntityEntityType(null, entity)

  const showLabelInTable =
    _.get(entityType, 'object_data.show_label_table_view', false) || true
  const columnHeaders = useMemo(() => {
    const headersToUse = (entityType?.fields ?? []).filter((f) => {
      return _.get(f, 'field_data.flags.tableView', false)
    })
    return headersToUse
  }, [entityType])

  const columns = sortColumnHeaders(columnHeaders, false)

  const nonFieldColumnsAlarmColorStyle = entity._alarmStateColors?.length
    ? { color: entity._alarmStateColors[0] }
    : null
  const flattened = flattenEntity(entity, true)

  const { timeFormat } = useTimeFormat()

  const format = getDateTimeFormatForTimeFormat(timeFormat)

  return (
    <DataTable.Row
      key={index}
      style={{
        backgroundColor: index % 2 ? 'transparent' : '#f1f1f1'
      }}
      onPress={() => linkTo(entity.uri)}
    >
      {isGlobalAdmin && (
        <DataTable.Cell
          key={'tenant'}
          style={[cellStyle, { maxWidth: 100, minWidth: undefined }]}
        >
          <Text style={nonFieldColumnsAlarmColorStyle}>
            {flattened.tenant_id ? flattened._tenant_name : 'Global'}
          </Text>
        </DataTable.Cell>
      )}
      <DataTable.Cell
        key={'modified at'}
        style={[cellStyle, { maxWidth: 200, width: 200, minWidth: undefined }]}
      >
        <Text style={nonFieldColumnsAlarmColorStyle}>
          {DateTime.fromISO(flattened.updated_at).toLocaleString(format)}
        </Text>
      </DataTable.Cell>
      <DataTable.Cell
        key={'modified by'}
        style={[cellStyle, { maxWidth: 140, width: 140, minWidth: undefined }]}
      >
        <Text style={nonFieldColumnsAlarmColorStyle}>
          {flattened._modified_by}
        </Text>
      </DataTable.Cell>
      {showLabelInTable && (
        <DataTable.Cell key={'display name'} style={cellStyle}>
          <Text style={nonFieldColumnsAlarmColorStyle}>
            {flattened.display_name}
          </Text>
          {!flattened.tenant_id && <IsGenericEntityIdentifier />}
        </DataTable.Cell>
      )}

      {columns?.slice(0, 3).map((columnHeaderField, columnIndex) => (
        <DataTableCell
          key={columnIndex}
          columnHeaderField={columnHeaderField}
          entity={flattened}
          cellStyle={cellStyle}
        />
      ))}
      {columns?.length < 3 &&
        Array.from({ length: 3 - columns.length }).map((_, index) => (
          <DataTable.Cell key={`empty-${index}`} style={cellStyle}>
            <Text></Text>
          </DataTable.Cell>
        ))}
    </DataTable.Row>
  )
}
