// libraries
import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity
} from 'react-native'
import { DateTime } from 'luxon'

import { useDispatch, useSelector } from 'react-redux'

import { deleteCalendarEvent } from '../store/calendar/actions'

import WithHeader from '../components/WithHeader'

import { Calendar, WeekCalendar } from 'react-native-calendars'

import appActions from '../store/app/actions'
import { CalendarEntityList } from './CalendarViewScreenHelpers/CalendarEntityList'
import { useMonthLoadingHelper } from './CalendarViewScreenHelpers/useMonthLoadingHelper'
import { CalendarEventAdd } from './CalendarViewScreenHelpers/CalendarEventAdd'
import { ViewEntityOverlay } from './CalendarViewScreenHelpers/ViewEntityOverlay'
import MSFESButton from '../components/MSFESButton'
import { CalendarAgenda } from './CalendarViewScreenHelpers/CalendarAgenda'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
import { useIsDesktop } from '../libraries/shouldShowDesktop'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import OverlayHeader from '../components/OverlayHeader'
import { useRoute } from '@react-navigation/native'
import {
  CalendarDay,
  getAgendaItems
} from './CalendarViewScreenHelpers/CalendarDay'
import { Overlay } from 'react-native-elements'
import { simplifyObject } from '../components/fields/SelectWithData'
import { CalendarDayTile } from './CalendarViewScreenHelpers/CalendarDayTile'
import { CurrentlyNoLabelOnly } from '../components/CurrentlyNoItems'
import { CalendarFilterDropdown } from '../components/dropdowns/CalendarFilterDropdown'
import { CalendarViewDropdown } from '../components/dropdowns/CalendarViewDropdown'
import { CreateDropdown } from '../components/dropdowns/CreateDropdown'
import { getSortedEntitiesFromStore } from '../components/HomeContainer'

export const themeColor = '#00AAAF'
export const lightThemeColor = '#f2f7f7'

export default function CalendarViewScreen({ navigation }) {
  const route = useRoute()
  const isDashboard = route.name.includes('Dashboard') ? true : false

  const [activeDate, setActiveDate] = useState(null)

  const [
    markedMonthYear,
    monthLoading,
    setActiveMonthYear,
    refetchMonth
  ] = useMonthLoadingHelper(activeDate)

  /* end to go in month/year helper */

  const onMonthChanged = (args) => {
    const { month, year } = args
    setActiveMonthYear({ month, year })
  }

  const onDateChanged = (args) => {
    setActiveDate(args?.dateString)
  }

  const onWeekChangeChangedMonth = (newActiveDateDt) => {
    const month = newActiveDateDt.toFormat('MM')
    const year = newActiveDateDt.toFormat('yyyy')
    setActiveMonthYear({ month, year })
  }

  const decrementOneWeek = () => {
    const prevWeek = DateTime.fromISO(activeDate).plus({ weeks: -1 })
    onWeekChangeChangedMonth(prevWeek)
    setActiveDate(prevWeek.toFormat('yyyy-MM-dd'))
  }
  const incrementOneWeek = () => {
    const nextWeek = DateTime.fromISO(activeDate).plus({ weeks: 1 })
    onWeekChangeChangedMonth(nextWeek)
    setActiveDate(nextWeek.toFormat('yyyy-MM-dd'))
  }
  const dashboardEntityType = {
    name: 'system_dashboard'
  }

  const state = useSelector((state) => state)
  const dashboards = getSortedEntitiesFromStore(dashboardEntityType, state)
  const dashboardsArray = dashboards?.data
  const calendarsArray =
    dashboardsArray?.flatMap((dashboard) => dashboard.calendar_layouts) ?? []
  const dashboardCalendarString = calendarsArray?.[0]
  const calendarLayoutString = dashboardCalendarString?.key

  const isDesktop = useIsDesktop()
  const { calendarStyle, calendarsVisible } = useSelector((appState) => {
    return {
      calendarStyle: isDashboard
        ? calendarLayoutString
        : isDesktop
        ? appState.app?.dayOrMonth
        : 'month',
      calendarsVisible: appState.app?.calendarsVisible ?? []
    }
  })

  const dispatch = useDispatch()

  const toggleCurrentView = (newState = null) => {
    setFrameSize(null)
    forceResetActiveDate()
    dispatch(appActions.setCalendarStyle(newState))
  }

  useEffect(() => {
    if (!isDesktop && calendarStyle !== 'month') {
      dispatch(appActions.setCalendarStyle('month'))
    }
  })

  const forceResetActiveDate = () => {
    const _activeDate = activeDate
    setActiveDate(null)
    setTimeout(() => {
      setActiveDate(_activeDate)
    }, 100)
  }

  const setCalendarsVisible = (calendarsVisible) => {
    dispatch(
      appActions.setCalendarsVisible(
        calendarsVisible.map((c) => simplifyObject(c))
      )
    )
  }

  const [renderKey, setRenderKey] = useState(0)
  useEffect(() => {
    const today = DateTime.now().toFormat('yyyy-MM-dd')
    const month = DateTime.now().toFormat('MM')
    const year = DateTime.now().toFormat('yyyy')
    onDateChanged({ dateString: today })
    onMonthChanged({ month, year })

    setTimeout(() => {
      setRenderKey((renderKey) => renderKey + 1)
    }, 300)
  }, [])

  const [isAddingEventDate, setIsAddingEventDate] = useState(false)

  const [viewingEntity, setViewingEntity] = useState(null)
  const [viewPopout, setViewPopout] = useState(null)
  const [deletingEvent, setDeletingEvent] = useState(null)

  const [modifyingEventWithEventId, setModifyingEventWithEventId] = useState(
    null
  )

  const { agendaItems: viewPopoutAgendaItems } = getAgendaItems(
    viewPopout?.date,
    calendarsVisible
  )

  const deleteScheduleItemConfirmPressed = () => {
    dispatch(deleteCalendarEvent(deletingEvent))
      .then(() => {
        setViewingEntity(null)
        setDeletingEvent(false)
      })
      .catch(() => {})
      .finally(() => {
        setViewingEntity(null)
        setDeletingEvent(false)
      })
  }

  const [frameSize, setFrameSize] = useState(null)

  const isWeekCalendar =
    calendarStyle === 'week' ||
    calendarStyle === 'work week' ||
    calendarStyle === 'weekend'
  const { CalendarComponent, ContainerComponent } = useMemo(() => {
    if (isWeekCalendar) {
      return { CalendarComponent: WeekCalendar, ContainerComponent: View }
    } else if (isDashboard) {
      return { CalendarComponent: Calendar, ContainerComponent: View }
    }
    return { CalendarComponent: Calendar, ContainerComponent: ScrollView }
  }, [calendarStyle])

  const weekStartingLabel = useMemo(() => {
    const activeDateDt = DateTime.fromISO(activeDate)
      .startOf('week')
      .plus({ days: calendarStyle === 'weekend' ? 0 : -1 })
      .toFormat('MMMM d')

    return `Week Starting ${activeDateDt}`
  }, [activeDate])

  const createSelected = (option) => {
    setIsAddingEventDate({ activeDate, addingType: option })
  }

  return (
    <WithHeader
      title="Calendar"
      hasTabNavigatorPadding={false}
      navigation={navigation}
      rightButtonAction={null}
      isInSplitView={isDashboard ? true : false}
    >
      <View
        style={{
          flexDirection: 'row',
          padding: spacing.m0_5,
          justifyContent: 'flex-end'
        }}
      >
        {!isDashboard && (
          <View style={{ paddingRight: spacing.m0_5 }}>
            <CreateDropdown onOptionChanged={createSelected} />
          </View>
        )}

        {isDesktop && !isDashboard && (
          <View style={{ paddingRight: spacing.m0_5 }}>
            <CalendarViewDropdown
              onViewChanged={toggleCurrentView}
              value={calendarStyle}
            />
          </View>
        )}
        {!isDashboard && (
          <View style={{ paddingRight: spacing.m0_5 }}>
            <CalendarFilterDropdown
              onFiltersChanged={(newVal) => {
                setCalendarsVisible([...newVal])
              }}
              value={calendarsVisible}
            />
          </View>
        )}
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          height: '100%'
        }}
      >
        <View
          style={{
            flex: isDashboard
              ? 1
              : isDesktop
              ? calendarStyle === 'day'
                ? 1 / 3
                : 1
              : 1,
            height: isDashboard ? 550 : '100%',
            width: '100%',
            flexDirection: 'column',
            borderRightWidth: StyleSheet.hairlineWidth,
            borderColor: colors.inactiveBackground,
            backgroundColor: 'white'
          }}
        >
          <ContainerComponent
            style={{
              flexDirection: 'column',
              paddingBottom: spacing.m4,
              flex: 1,
              backgroundColor: isDashboard && 'white',
              height: isDashboard ? 650 : undefined
            }}
          >
            {isWeekCalendar && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: spacing.m2,
                  paddingTop: spacing.m2
                }}
              >
                <TouchableOpacity onPress={decrementOneWeek}>
                  <Text>Prev Week</Text>
                </TouchableOpacity>
                <Text
                  style={{
                    fontWeight: '300',
                    fontSize: 16,
                    color: 'rgba(45,65,80,1)'
                  }}
                >
                  {weekStartingLabel}
                </Text>
                <TouchableOpacity onPress={incrementOneWeek}>
                  <Text>Next Week</Text>
                </TouchableOpacity>
              </View>
            )}
            {activeDate && (
              <View
                style={{
                  flex: 1
                }}
                onLayout={(event) => {
                  if (isWeekCalendar) {
                    const { width, height } = event.nativeEvent.layout
                    setFrameSize({ width, height })
                  }
                }}
              >
                {((frameSize && isWeekCalendar) ||
                  calendarStyle === 'month' ||
                  calendarStyle === 'day') && (
                  <View
                    style={{
                      flex: 1,
                      width:
                        isDashboard && calendarStyle === 'day'
                          ? '100%'
                          : isDashboard && calendarStyle === 'month'
                          ? 340
                          : frameSize?.width
                    }}
                  >
                    <CalendarComponent
                      hideWeekend={calendarStyle === 'work week'}
                      hideWeek={calendarStyle === 'weekend'}
                      pageHeight={frameSize?.height}
                      calendarWidth={frameSize?.width}
                      firstDay={calendarStyle === 'weekend' ? 1 : 0}
                      key={renderKey}
                      calendarStyle={{
                        flex: 1
                      }}
                      style={
                        calendarStyle === 'month' && {
                          borderColor: '#ddd',
                          borderWidth: StyleSheet.hairlineWidth,
                          padding: 0
                        }
                      }
                      // Collection of dates that have to be marked. Default = {}
                      disableMonthChange={false}
                      dayComponent={
                        calendarStyle == 'day' || !isDesktop
                          ? undefined
                          : (props) => {
                              return (
                                <CalendarDay
                                  itemsToShow={
                                    isWeekCalendar ? 1000 : undefined
                                  }
                                  setViewingEntity={setViewingEntity}
                                  setViewPopout={setViewPopout}
                                  state={props.state}
                                  date={props.date?.dateString}
                                  calendarsVisible={calendarsVisible}
                                />
                              )
                            }
                      }
                      theme={{
                        selectedDayTextColor: 'yellow',
                        todayTextColor: 'black',
                        selectedDayBackgroundColor: colors.blue,
                        arrowColor: 'white',

                        // textDisabledColor: 'red',
                        stylesheet: {
                          calendar: {
                            header: {
                              week: {
                                marginTop: 30,
                                marginHorizontal: 12,
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                              }
                            }
                          }
                        }
                      }}
                      hideExtraDays={false}
                      displayLoadingIndicator={monthLoading}
                      onDayPress={(args) => {
                        if (!isDesktop) {
                          setViewPopout({ date: args.dateString })
                        }
                        onDateChanged(args)
                      }}
                      current={activeDate}
                      animateScroll
                      hideArrows={false}
                      markingType={'multi-dot'}
                      markedDates={markedMonthYear}
                      onMonthChange={onMonthChanged}
                      renderArrow={(direction) => {
                        if (direction == 'left') {
                          return <Text>Prev</Text>
                        } else {
                          return <Text>Next</Text>
                        }
                      }}
                    />
                  </View>
                )}
              </View>
            )}
          </ContainerComponent>
          {calendarStyle == 'day' && isDesktop && (
            <View
              style={{
                paddingVertical: spacing.m4,
                flex: 1,
                borderTopWidth: StyleSheet.hairlineWidth,
                borderColor: colors.inactiveBackground
              }}
            >
              <CalendarEntityList
                isDashboard={isDashboard}
                date={activeDate}
                calendarsVisible={calendarsVisible}
                onAddPressed={(date) =>
                  setIsAddingEventDate({ activeDate: date })
                }
                onEntityClicked={(entity) => setViewingEntity(entity)}
              />
            </View>
          )}
        </View>

        {calendarStyle == 'day' && isDesktop && (
          <View
            style={{
              flexDirection: 'row',
              flex: isDashboard ? 1 / 2 : 2 / 3
            }}
          >
            {!isDashboard && (
              <CalendarAgenda
                date={activeDate}
                calendarsVisible={calendarsVisible}
                onAddPressed={(datetime) =>
                  setIsAddingEventDate({ activeDate: datetime })
                }
                onEntityClicked={(entity) => setViewingEntity(entity)}
              />
            )}
          </View>
        )}
      </View>

      {/* {!isDesktop && (
        <View style={{ flex: 1 }}>
          <CalendarAgenda
            date={activeDate}
            calendarsVisible={calendarsVisible}
            onAddPressed={(datetime) => setIsAddingEventDate(datetime)}
            onEntityClicked={(entity) => setViewingEntity(entity)}
          />
        </View>
      )} */}
      {viewingEntity && (
        <ViewEntityOverlay
          entity={viewingEntity}
          virtualEventCreateDate={activeDate}
          onDismiss={() => setViewingEntity(null)}
          onRefetch={() => refetchMonth()}
          onModifySchedulePressed={() => {
            const virtual_event_id = viewingEntity.virtual_event_id
            setViewingEntity(null)
            setTimeout(() => {
              setModifyingEventWithEventId(virtual_event_id)
            })
          }}
          onDeleteSchedulePressed={() => {
            setDeletingEvent(viewingEntity.virtual_event_id)
          }}
        />
      )}
      {viewPopout?.date && !!viewPopoutAgendaItems && (
        <Overlay
          onBackdropPress={() => {
            setViewPopout(null)
          }}
          isVisible={!viewingEntity}
          overlayStyle={{
            padding: 0,
            margin: 0,
            maxHeight: '50%'
          }}
        >
          <OverlayHeader>{viewPopout.date}</OverlayHeader>

          <View style={{ flex: 1, padding: 8, width: 400, maxWidth: '100%' }}>
            <ScrollView>
              {viewPopoutAgendaItems?.map((agendaItem, index) => {
                return (
                  <CalendarDayTile
                    isLast={index === viewPopoutAgendaItems?.length - 1}
                    key={index}
                    onPress={() => {
                      setViewPopout(null)
                      setViewingEntity(agendaItem.entity)
                    }}
                    agendaItem={agendaItem}
                  />
                )
              })}
              {!viewPopoutAgendaItems.length && (
                <CurrentlyNoLabelOnly label="No events." />
              )}
            </ScrollView>
            <View style={{ paddingTop: spacing.m0_5 }}>
              <MSFESButton
                title={'Add'}
                onPress={() => {
                  setIsAddingEventDate({ activeDate })
                }}
              />
            </View>
          </View>
        </Overlay>
      )}
      {deletingEvent && (
        <YesNoCancelDialog
          title="Delete"
          body="Are you sure you want to delete this schedule item? This is a permanent action. No future entities will be created for this schedule."
          yesAction={deleteScheduleItemConfirmPressed}
          noAction={() => setDeletingEvent(null)}
          cancelAction={() => setDeletingEvent(null)}
        />
      )}

      {(isAddingEventDate || modifyingEventWithEventId) && (
        <View style={{ borderWidth: 2 }}>
          <CalendarEventAdd
            addingDate={isAddingEventDate.activeDate}
            addingType={isAddingEventDate.addingType}
            modifyingEventWithEventId={modifyingEventWithEventId}
            onDismiss={() => {
              setIsAddingEventDate(false)
              refetchMonth()
              setModifyingEventWithEventId(null)
            }}
          />
        </View>
      )}
    </WithHeader>
  )
}
