import React from 'react'
import { View, Text } from 'react-native'
import { WidgetDataLoading } from './WidgetDataLoading'
import { VictoryBar } from 'victory-native'
import { Chart } from './Chart'
const { DateTime } = require('luxon')
import spacing from '../../styles/spacing'
import fonts from '../../styles/fonts'

export const transformViewData = (values, group_function) => {
  if (values) {
    switch (group_function) {
      case 'single-entity':
        return Object.keys(values).map((key) => {
          // Specify the format of the date string
          const format = 'yyyy-MM-dd HH:mm:ss'

          // Convert the date string to a Luxon DateTime object using the specified format
          const luxonDate = DateTime.fromFormat(key, format).toJSDate()
          return {
            x: key !== '' ? luxonDate : '(None entered)',
            y: values[key] ? parseFloat(values[key]) : 0
          }
        })
      default:
        if (values) {
          return Object.keys(values).map((key) => ({
            x: key !== '' ? key : '(None entered)',
            y: values[key]
          }))
        }
        return null
    }
  }
  return null
}
export const WidgetButtonChart = ({ widget, viewData }) => {
  const handledTypes = ['piechart', 'gauge', 'single-entity', 'multi_line']
  let isChartPlanner =
    widget?.render_type?.key === 'chart' && widget?.position?.key === 'planner'
  let isPlanner = widget?.position?.key === 'planner'

  return !viewData.loaded ? (
    <View>
      <WidgetDataLoading isPlanner={isPlanner} />
      {isChartPlanner && (
        <Text
          style={
            isPlanner
              ? {
                  textAlign: 'center',
                  marginRight: spacing.m11,
                  fontWeight: fonts.font500
                }
              : {
                  textAlign: 'center',
                  fontWeight: fonts.font500
                }
          }
        >
          {widget.name}
        </Text>
      )}
    </View>
  ) : (
    <View
      style={{
        minHeight: 72,
        justifyContent: 'center',
        alignContent: 'center',
        marginLeft: isPlanner && 0,
        marginRight: isPlanner && spacing.m7
      }}
    >
      <Chart viewData={viewData} widget={widget} />
      <View style={{ height: 25, marginTop: -spacing.m7 + 6 }}>
        {isPlanner && (
          <Text
            style={{
              textAlign: 'center',
              marginTop: spacing.m6,
              fontWeight: fonts.font500
            }}
          >
            {widget.name}
          </Text>
        )}
      </View>
      {!handledTypes.includes(widget.group_function?.key) && <VictoryBar />}
    </View>
  )
}
