
import React from 'react';
import { View, Text } from 'react-native'
import shared from '../styles/shared';
import spacing from '../styles/spacing';
import { MSFESFooter } from './MSFESFooter';
import MSFESHeading from './MSFESHeading';

export const MSFESSuccessfulAnonymousSubmission = () => {
  return <View style={[shared.tabletWidthView, { marginBottom: spacing.viewboxNavbarPadding, borderRadius: spacing.borderRadius }, {backgroundColor:"white"}]}>

  <MSFESHeading key="header">Saved Successfully</MSFESHeading>
  <View style={{paddingVertical: spacing.m2}}>

    <Text>Thankyou for your submission.</Text>
    </View>
    <MSFESFooter />
    </View>
}