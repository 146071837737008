// libraries
import React, { useState, useEffect } from 'react'
import { SearchBar } from 'react-native-elements'
import {
  View,
  SafeAreaView,
  useWindowDimensions,
  ScrollView
} from 'react-native'
import { useDispatch } from 'react-redux'
// styles
import colors from '../styles/colors'
import shared from '../styles/shared'
import MSFESIcon from './MSFESIcon'
import fonts from '../styles/fonts'
import notificationActions from '../store/notification/actions'

//actions

// components

import { DateChanger } from './DateChanger'
import { FiltersConfigurator } from './FiltersConfigurator'
import MSFESAlert from './MSFESAlert'
import MSFESButton from './MSFESButton'
import spacing from '../styles/spacing'
import { shouldShowDesktop } from '../libraries/shouldShowDesktop'
import WithHeaderWithLogo from './WithHeaderWithLogo'
import WithHeaderSplitView from './WithHeaderSplitView'
import { FilterBar } from './FilterBar'
import MSFESChip from './MSFESChip'
import { getFriendlyFilters } from '../screens/ShowReportsScreen/useGetFriendlyFilter'
import MSFESLabel from './MSFESLabel'
import { UnsavedEntitiesBanner } from './UnsavedEntitiesBanner'

export const headerTextStyle = [
  { textAlign: 'center' },
  fonts.headerStyle,
  { color: colors.blueDarkest },
  shared.debugOff
]

export const searchBarInputContainerStyle = [
  {
    backgroundColor: colors.inputBackground,
    marginHorizontal: 0,
    padding: 0
  }
]

export const searchBarContainerStyle = [
  {
    backgroundColor: colors.headerBackground,
    margin: 0,
    padding: 0,
    height: 44,
    flex: 1
  }
]

export const WithHeader = (props) => {
  const windowDimensions = useWindowDimensions()
  const isDesktopLayout = shouldShowDesktop(windowDimensions)

  const dispatch = useDispatch()

  // splitViewShowHeader - split view left side doesn't need a header. Right side usually does.
  let {
    dropdownVisibilityState = false,
    paginationText = '',
    onSortChanged,
    sortOptions,
    activeSort,
    isInSplitView = false,
    splitViewShowHeader = false,
    gridOrList = null,
    onCloseSplit = null,
    onToggleGridOrList = null,
    children = null,
    navigation,
    title = '',
    filtersAvailable = [],
    filtersApplied = {},
    onFiltersChanged = null,
    backHint = null,
    leftButtonType = 'burger',
    rightButtonAction = null,
    rightButtonIcon = null,
    rightButtonText = '',
    rightButtonRef = null,
    onSearchTextChanged = null,
    onDateChanged = null,
    backButtonAction = null,
    filterableEntityType = null,
    setPageTitle = true,
    collapsibleSearch = false //when true, search box starts collapsed (for eg pagination text)
  } = props

  if (!rightButtonIcon && !rightButtonText) {
    // if none specified, then should be notifications.
    rightButtonIcon = null
    rightButtonAction = null
  }

  const [searchValue, setSearchValue] = useState('')
  const [dateValue, setDateValue] = useState(null)

  useEffect(() => {
    setPageTitle &&
      navigation &&
      navigation.setOptions &&
      navigation.setOptions({ title })
  }, [title])

  const searchTextChanged = (searchValue) => {
    onSearchTextChanged(searchValue)

    setSearchValue(searchValue)
  }
  const onSearchCancel = () => {
    onSearchTextChanged('')
  }

  const dateChanged = (newDateValue) => {
    onDateChanged(newDateValue)

    setDateValue(newDateValue)
  }

  const [configureFiltersVisible, setConfigureFiltersVisible] = useState(false)

  const toggleConfigureFilters = (newValue = null) => {
    const nextValue = newValue !== null ? newValue : !configureFiltersVisible

    setConfigureFiltersVisible(nextValue)
  }

  const hasFiltersApplied = filtersAvailable?.some(
    (f) => filtersApplied[f.field_data.property]
  ) //{/* {filtersAvailable.map((f, index) => {

  const showFilterBar =
    onToggleGridOrList || !!filtersAvailable?.length || onSortChanged

  const useMobileLayout = !isDesktopLayout
  useEffect(() => {
    if (useMobileLayout) {
      dispatch(notificationActions.listUnreadNotifications())
    }
  }, [])

  const onSortClicked = () => {
    let nextIndex = activeSort + 1
    if (nextIndex > sortOptions.length - 1) {
      nextIndex = 0
    }

    onSortChanged(nextIndex)
  }

  const activeSortIcon = sortOptions
    ? activeSort
      ? sortOptions[activeSort].icon
      : sortOptions[0].icon
    : null

  const renderFilterBar =
    onSortChanged || onSearchTextChanged || onToggleGridOrList || showFilterBar

  const friendlyFilters = getFriendlyFilters(filtersApplied)

  const [showSearchBar, setShowSearchBar] = useState(!collapsibleSearch)
  const toggleShowSearchBar = () => {
    setShowSearchBar(!showSearchBar)
  }

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView
        style={{ flex: 0, backgroundColor: colors.headerBackground }}
      />

      {/* THIS IS HEADER BLOCK STUFF */}
      <View style={[{ backgroundColor: colors.headerBackground }]}>
        {!isInSplitView && <MSFESAlert />}
        {!isInSplitView && <UnsavedEntitiesBanner />}

        {splitViewShowHeader && !useMobileLayout && (
          <WithHeaderSplitView
            title={title}
            rightButtonIcon={rightButtonIcon}
            dropdownVisibilityState={dropdownVisibilityState}
            rightButtonRef={rightButtonRef}
            rightButtonAction={rightButtonAction}
            onCloseSplit={onCloseSplit}
          ></WithHeaderSplitView>
        )}

        {useMobileLayout && (
          <WithHeaderWithLogo
            backHint={backHint}
            title={title}
            dropdownVisibilityState={dropdownVisibilityState}
            navigation={navigation}
            rightButtonRef={rightButtonRef}
            backButtonAction={backButtonAction}
            leftButtonType={leftButtonType}
            rightButtonAction={rightButtonAction}
            rightButtonIcon={rightButtonIcon}
          ></WithHeaderWithLogo>
        )}

        {!!props.buttons && (
          <View
            style={{
              backgroundColor: colors.skyLighter,
              maxHeight: 60,
              height: 60,
              paddingVertical: spacing.m0_5,
              borderTopColor: colors.lightGray,
              borderBottomColor: colors.lightGray
            }}
          >
            <View style={{ flex: 1 }}>{props.buttons}</View>
          </View>
        )}
        {renderFilterBar && (
          <View style={{ flexDirection: 'row' }}>
            {onSearchTextChanged && (
              <>
                {!friendlyFilters.length && (
                  <>
                    {!showSearchBar && (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          minHeight: 64
                        }}
                      >
                        <MSFESButton
                          variant="small"
                          light
                          icon={{
                            name: 'search',
                            type: 'font-awesome',
                            size: 16
                          }}
                          onPress={toggleShowSearchBar}
                        />
                      </View>
                    )}
                    {showSearchBar && (
                      <SearchBar
                        style={{ width: 48, height: 48 }}
                        placeholder="Search"
                        lightTheme={true}
                        onClear={() => {
                          onSearchCancel?.()
                          if (collapsibleSearch) {
                            setShowSearchBar(false)
                          }
                        }}
                        inputContainerStyle={searchBarInputContainerStyle}
                        containerStyle={searchBarContainerStyle}
                        value={searchValue}
                        onChangeText={searchTextChanged}
                      />
                    )}
                  </>
                )}
                {!!friendlyFilters.length && (
                  <ScrollView horizontal={true}>
                    {friendlyFilters.map((key) => {
                      return <MSFESChip key={key}>{key}</MSFESChip>
                    })}
                  </ScrollView>
                )}
              </>
            )}

            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  minHeight: 64
                }}
              >
                {onSortChanged && (
                  <View style={{ flexDirection: 'row' }}>
                    <MSFESButton
                      variant="small"
                      light
                      icon={{ name: activeSortIcon, type: 'font-awesome-5' }}
                      onPress={onSortClicked}
                    />
                  </View>
                )}

                {!useMobileLayout && (
                  <View style={{ flexDirection: 'row' }}>
                    <FilterBar
                      filterEntityType={filterableEntityType}
                      onBackdropPress={() => toggleConfigureFilters(false)}
                      filtersApplied={filtersApplied}
                      filtersAvailable={filtersAvailable}
                      onFiltersChanged={onFiltersChanged}
                    />
                  </View>
                )}

                <View>
                  {((showFilterBar && !showSearchBar) || !useMobileLayout) && (
                    <View
                      style={{
                        flexDirection: 'row',
                        height: 48
                      }}
                    >
                      <View
                        style={{
                          justifyContent: 'center',
                          alignContent: 'center',
                          flex: 1,
                          paddingBottom: 8,
                          paddingHorizontal: spacing.m2
                        }}
                      >
                        <MSFESLabel label={paginationText} />
                      </View>
                    </View>
                  )}
                </View>
                {onToggleGridOrList && (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <MSFESButton
                      variant="small"
                      light
                      icon={{
                        name: gridOrList === 'grid' ? 'th-large' : 'list',
                        type: 'font-awesome',
                        size: 16
                      }}
                      onPress={() => onToggleGridOrList()}
                    />
                  </View>
                )}
                {showFilterBar && (
                  <MSFESButton
                    disabled={!filtersAvailable?.length}
                    variant="small"
                    light
                    icon={{
                      name: 'sliders-h',
                      type: 'font-awesome-5',
                      size: 16,
                      color: hasFiltersApplied ? 'red' : undefined
                    }}
                    onPress={() => toggleConfigureFilters(true)}
                  />
                )}
              </View>
              {/* {!showFilterBar && <View style={{backgroundColor:colors.white, height: 4, width: "100%"}} />} */}
            </View>
            {/* Appears in the search box, as e.g. the filter button. */}
            {!useMobileLayout && rightButtonAction && (
              <View
                ref={rightButtonRef}
                style={{
                  position: 'relative',
                  marginRight: spacing.m1,
                  backgroundColor: dropdownVisibilityState
                    ? colors.lightestGray
                    : undefined,
                  marginVertical: 4,
                  borderTopLeftRadius: spacing.borderRadius,
                  borderTopRightRadius: spacing.borderRadius,
                  paddingBottom: 4
                }}
              >
                <View
                  style={{
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 56,
                    paddingLeft: 2,
                    backgroundColor: dropdownVisibilityState
                      ? colors.blueDarkest
                      : colors.blueDarkest,
                    borderRadius: spacing.borderRadius
                  }}
                >
                  <MSFESIcon
                    tappable
                    name={rightButtonIcon}
                    color={colors.white}
                    size={24}
                    onPress={rightButtonAction}
                    type="font-awesome"
                  />
                </View>
              </View>
            )}
          </View>
        )}

        {useMobileLayout && (
          <ScrollView horizontal>
            <View style={{ flexDirection: 'row', marginRight: spacing.m2 }}>
              <FilterBar
                filterEntityType={filterableEntityType}
                onBackdropPress={() => toggleConfigureFilters(false)}
                filtersApplied={filtersApplied}
                filtersAvailable={filtersAvailable}
                onFiltersChanged={onFiltersChanged}
              />
            </View>
          </ScrollView>
        )}
        {configureFiltersVisible && (
          <FiltersConfigurator
            filterEntityType={filterableEntityType}
            onBackdropPress={() => toggleConfigureFilters(false)}
            filtersApplied={filtersApplied}
            filtersAvailable={filtersAvailable}
            onFiltersChanged={onFiltersChanged}
          />
        )}
      </View>
      {onDateChanged && (
        <View>
          <DateChanger value={dateValue} onDateChanged={dateChanged} />
        </View>
      )}

      {children}
    </View>
  )
}

export default WithHeader
