import React, { useState, useCallback, useEffect } from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { DatePickerModal } from 'react-native-paper-dates'
import { ListItem } from 'react-native-elements'
import { View, Platform } from 'react-native'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import {
  DefaultTheme,
  Portal,
  Provider as PaperProvider
} from 'react-native-paper'
import colors from '../../styles/colors'

export const DATE_FORMAT = 'yyyy-MM-dd'
export const DatePickerTheme = {
  ...DefaultTheme,
  colors: {
    primary: colors.redDark,
    surface: colors.white,
    text: colors.mediumGray
  }
}

export const PortalWrapper = Platform.OS === 'web' ? Portal : View

export const DatePicker = React.forwardRef((props, ref) => {
  const {
    value,
    defaultValue,
    changeHandler,
    style,
    editable,
    datePlaceholder = 'Select a Date'
  } = props

  const [visible, setVisible] = useState(false)

  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const onChange = (args) => {
    const { date } = args
    setVisible(false)

    const dateObject = DateTime.fromJSDate(date)
    const newDate = dateObject.toISO()

    const month = dateObject.toFormat('MM')
    const day = dateObject.toFormat('dd')
    const year = dateObject.toFormat('yyyy')
    const display_value = dateObject.toFormat(DATE_FORMAT)

    changeHandler({ value: newDate, month, year, day, display_value })
  }

  const lockedToCreationDate = _.get(
    props.data?.field,
    'field_data.params.lockedToCreationDate',
    false
  )

  useEffect(() => {
    // do not set the date if not already (MSFES-132)

    if (!value && !defaultValue && lockedToCreationDate) {
      onChange({ date: new Date() })
    }
  }, [])

  const jsDate = value
    ? DateTime.fromISO(value.value ?? value).toJSDate()
    : new Date()
  const displayDate = DateTime.fromJSDate(jsDate).toLocaleString(
    DateTime.DATE_SHORT
  )

  const onPressAction = editable
    ? () => {
        setVisible(true)
      }
    : null

  return (
    <View style={style}>
      <ListItem
        onPress={onPressAction}
        disabled={lockedToCreationDate}
        containerStyle={ListItemAsFieldProps.containerStyle}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
            {value ? displayDate : datePlaceholder}
          </ListItem.Title>
        </ListItem.Content>
        {/* {editable && <ListItem.Chevron type="font-awesome" name="chevron-down" size={14} color={"black"} />} */}
      </ListItem>
      <PortalWrapper>
        <PaperProvider theme={DatePickerTheme}>
          <DatePickerModal
            mode="single"
            visible={visible}
            onDismiss={onDismiss}
            date={jsDate}
            onConfirm={onChange}
            saveLabel="Save" // optional
            label="Select date" // optional
            locale={'en'} // optional, default is automically detected by your system
          />
        </PaperProvider>
      </PortalWrapper>
    </View>
  )
})
