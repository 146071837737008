const grid = {
  mobileWidth: { width: 420, maxWidth: '100%', alignSelf: 'center' },
  tabletWidth: { width: 760, maxWidth: '100%', alignSelf: 'center' }
}

const padding = {
  viewPort: {
    paddingHorizontal: 16,
    paddingVertical: 16
  }
}

export default { grid, padding }
