import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import users from './user/reducers.js'
import calendar from './calendar/reducers.js'
import files from './file/reducers.js'
import versions from './version/reducers.js'
import entities from './entity/reducers.js'
import tenants from './tenant/reducers.js'
import notifications from './notification/reducers.js'
import conversations from './conversation/reducers.js'
import permissions from './permission/reducers.js'
import transitions from './transition/reducers.js'
import entityTypes from './entity-type/reducers.js'
import logins from './admin/logins/reducers.js'
import app from './app/reducers.js'
// https://redux.js.org/recipes/configuring-your-store/
import { getStorage } from './storage'
const rootReducer = combineReducers({
  users,
  calendar,
  entities,
  notifications,
  conversations,
  permissions,
  entityTypes,
  tenants,
  transitions,
  files,
  versions,
  app,
  logins
})

const storage = getStorage()
const persistConfig = {
  key: 'root',
  timeout: 0,
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export let store
export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const composedEnhancers = composeEnhancers(...enhancers)

  store = createStore(persistedReducer, preloadedState, composedEnhancers)

  let persistor = persistStore(store)

  return { store, persistor }
}
