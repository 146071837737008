// libraries
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { CheckboxMultiselectAdhoc } from './CheckboxMultiselectAdhoc'

import { roles } from './MultipleRolePicker'
import SelectWithData from './SelectWithData'

export const SelectRole = React.forwardRef((props, ref) => {

  const { value, onValueChange } = props

  const rolesSingular = roles.filter(r => r.key !== "ALL");

  return (
    <SelectWithData
    {...props} labelField={'label'} keyField={'key'}
    
    onChangeText={(value, userInitiated) => {
      props.onChangeText(value.key, userInitiated)
    }}
     valueField={'value'} dataRows={rolesSingular} value={props.defaultValue} />
  )
})
