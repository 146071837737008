import { DateTime } from 'luxon'
import _ from 'lodash'
const defaultState = {
  errors: {
    errors: null,
    hasError: false
  }
}

export default function permissions(state = defaultState, action) {
  switch (action.type) {
  
    case 'permission/list-started':
      {
        state = {
          ...state,
          lastRetrieveStarted: DateTime.local()
        }
      }

      break
    case 'permission/list-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            data: response.data,
            lastRetrieveFinished: DateTime.local()
          }
        }
      }

      break

    case 'permission/get-success':
    case 'permission/upsert-success':
      {
        const { response } = action

        const data = state.data

        const existingRecord = data.findIndex(
          (record) => record && record.id === response.data.id
        )

        if (existingRecord !== -1) {
          data[existingRecord] = response.data
        } else {
          data.push(mutated)
        }

        state = {
          ...state,
          ...{
            data
          }
        }
      }

      break
    case 'permission/upsert-started':
      state.errors = {
        ...state.errors,
        ...{ hasError: false, message: null, statusCode: null, errors: null }
      }
      break

    case 'permission/upsert-error':
      state.errors = {
        errors: action.error.errors,
        message: action.error.message,
        statusCode: action.error.statusCode,
        hasError: true
      }
      break
      case 'permission/clear-general-error':
        state.errors = {
          ...state.errors,
          ...{ message: null, statusCode: null }
        }
        break
        case 'permission/clear-field-errors':
          state.errors = {... defaultState }
          break
  }

  return state
}
