import { useMemo, useRef, useState } from 'react'
import { LayoutAnimation } from 'react-native'
import spacing from './styles/spacing'

export const useDropdownHelper = (
  minWidth = 200,
  menuYOffset = 0,
  positionAtButton = true
) => {
  const buttonRef = useRef()

  const [dropdownVisibilityState, setDropdownVisibilityState] = useState(false)
  const [containerPosition, setContainerPosition] = useState(null)

  const toggleDropdownState = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.spring)
    if (!buttonRef.current) {
      console.warn('Has buttonref been set?')
    }
    buttonRef.current?.measure((x, y, width, height, pageX, pageY) => {
      setContainerPosition({
        top: pageY,
        left: pageX,
        width: width,
        height: height
      })

      setDropdownVisibilityState(!dropdownVisibilityState)
    })
  }

  const dropdownOverlayStyle = useMemo(() => {
    if (containerPosition) {
      const eventualWidth = Math.max(containerPosition.width ?? 0, minWidth)

      const eventualLeft =
        (containerPosition.left ?? 0) +
        (containerPosition.width ?? 0) -
        eventualWidth

      const style = {
        elevation: 0,
        shadowOffset: { width: -2, height: 2 },
        margin: 0,
        padding: 0,
        position: 'absolute',
        top: positionAtButton
          ? (containerPosition.top ?? 0) +
            (containerPosition.height ?? 0) +
            menuYOffset
          : '10%',
        width: positionAtButton ? eventualWidth : '80%',
        left: positionAtButton ? (eventualLeft < 0 ? 0 : eventualLeft) : '10%',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: spacing.borderRadius,
        borderBottomRightRadius: spacing.borderRadius,
        overflow: 'hidden'
      }

      return style
    }
    return null
  }, [containerPosition, minWidth])

  return [
    dropdownVisibilityState,
    containerPosition,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ]
}
