import localforage from 'localforage'
import storage from 'redux-persist/lib/storage'

export const getStorage = () => {
  const storageUpdated = localStorage.getItem('storageUpdated')
  if (storageUpdated) {
    return localforage
  } else {
    updateStorage()
    return storage
  }
}

const updateStorage = async () => {
  const oldStorage = storage.getItem('persist:root')

  if (oldStorage) {
    await localforage.setItem('persist-root', oldStorage)
    localStorage.setItem('storageUpdated', 'y')
  }
}
