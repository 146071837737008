// libraries
import React, { useState } from 'react'
import _ from 'lodash'
import spacing from '../styles/spacing'
import { useDispatch, useSelector } from 'react-redux'


import MSFESInput from './MSFESInput'
import { StatusPicker } from './fields/StatusPicker'
//actions

// components

export const TransitionManager = ({
  entity,
  definition,
  loading,
  transitionRequested,
  mode
}) => {



  const transitionObjects = useSelector((state) =>
    _.get(state, 'transitions.transitions', [])
  )
  const placeObjects = useSelector((state) =>
    _.get(state, 'transitions.places.data', [])
  )

  const availableTransitionObjects = _.get(
    entity,
    'available_transitions',
    []
  ).map((t) => {
    return { ...transitionObjects.data[t], key: t }
  })

  const hideButtonModes = ['add', 'view']
  const canModifyPlace = (!!entity.id) && !hideButtonModes.includes(mode)

  const matchedPlace = placeObjects.find(p => p.name === entity.place);

  const placeName = _.get(matchedPlace, 'metadata.title', null)
  return (

    <MSFESInput
      loading={loading}
      disabled={!canModifyPlace}
      label="Current Status"
      helpText={"Select the current status for this " + definition.label}
      value={placeName}
      onChange={(pendingTransition) => {
        transitionRequested(
          entity,
          definition,
          pendingTransition
        )
      }}
      dataRows={availableTransitionObjects}
      InputComponent={StatusPicker} />

  )
}
