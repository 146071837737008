import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const internalRoleNames = [
  'MSFES User',
  'MSFES Office Staff',
  'MSFES Sales'
]
export const useTenantOrGlobalAdmin = () => {
  const { user } = useSelector((appState) => ({
    user: appState?.users?.user ?? {}
  }))

  const {
    isGlobalAdmin,
    isTenantAdmin,
    isTrainerAdmin,
    isCurrentlyGlobalAdmin,
    isInternalRole,
    actingRole,
    tenant
  } = useMemo(() => {
    let isGlobalAdmin = false
    let isTenantAdmin = false
    let isCurrentlyGlobalAdmin = false
    let isInternalRole = false
    let isTrainerAdmin = false
    const actingRole = user?.acting_role
    const tenant = user?.current_tenant
    user?.roles?.map((r) => {
      if (r.name === 'MSFES User') {
        isGlobalAdmin = true
      }

      if (internalRoleNames.includes(r.name)) {
        isInternalRole = true
      }
      if (r.name === 'MSFES User' && !user.tenant_record) {
        isCurrentlyGlobalAdmin = true
      }
      if (r.name === 'Tenant Admin') {
        isTenantAdmin = true
      }
      if (r.name === 'Trainer Admin') {
        isTrainerAdmin = true
      }
    })

    return {
      isGlobalAdmin,
      isInternalRole,
      isTenantAdmin,
      isTrainerAdmin,
      isCurrentlyGlobalAdmin,
      actingRole,
      tenant
    }
  }, [user])

  return {
    isGlobalAdmin,
    isTenantAdmin,
    isTrainerAdmin,
    isCurrentlyGlobalAdmin,
    isInternalRole,
    actingRole,
    tenant
  }
}
