import React from 'react'
import { View } from 'react-native'
import { Overlay } from 'react-native-elements'
import spacing from '../../styles/spacing'
import MSFESHeading from '../MSFESHeading'

import { FolderTile } from './FolderTile'



export const FolderDropdown = ({
  dropdownVisibilityState,
  toggleDropdownState,
  dropdownOverlayStyle,
  folders,
  setCurrentRootFolder,
  selectableSubfolders
}) => {

  return (
    dropdownVisibilityState && (
      <>
        <Overlay
          visible={true}
          backdropStyle={{ opacity: 0 }}
          onBackdropPress={toggleDropdownState}
          overlayStyle={dropdownOverlayStyle}
        >
          {!!selectableSubfolders?.folders?.length && (
            <>
              <MSFESHeading textStyle={{ marginHorizontal: spacing.m2 }}>
                Folders
              </MSFESHeading>
              <View
                style={{
                  paddingHorizontal: spacing.m2,
                  paddingLeft: spacing.m2
                }}
              >
                <View style={{ paddingHorizontal: spacing.m2 }}>
                  {selectableSubfolders.folders?.map((f, index) => (
                    <FolderTile
                      key={index}
                      folder={f}
                      onPress={() => {
                        toggleDropdownState()
                        setCurrentRootFolder(f)
                      }}
                    />
                  ))}
                </View>
              </View>
            </>
          )}
          {!selectableSubfolders?.folders?.length && (
            <>
              <MSFESHeading textStyle={{ marginHorizontal: spacing.m2 }}>
                Folders
              </MSFESHeading>
              <View
                style={{
                  paddingHorizontal: spacing.m2,
                  paddingLeft: spacing.m2
                }}
              >
                {folders.data
                  .filter((f) => !f.parent_folder)
                  .map((f, index) => (
                    <FolderTile
                      key={index}
                      folder={f}
                      onPress={() => {
                        toggleDropdownState()
                        setCurrentRootFolder(f)
                      }}
                    />
                  ))}
              </View>
            </>
          )}
        </Overlay>
      </>
    )
  )
}