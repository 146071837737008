import { DataTable } from 'react-native-paper'
import { formatTotalCost } from './DataTableWrapper'
import { Text } from 'react-native'
import React from 'react'
export const DataTableCell = ({ columnHeaderField, entity, cellStyle }) => {
  const alarmStateIndex = (entity._fieldsInAlarmState ?? []).findIndex(
    (el) => el === columnHeaderField.field_data.property || el == 'id'
  )

  const alarmStateColor = entity._alarmStateColors?.[alarmStateIndex] ?? 'red'
  const value = entity[columnHeaderField.field_data.property]

  const isTotalCostField =
    columnHeaderField.field_data.params.formatResultAs === 'dollar'

  return (
    <DataTable.Cell style={cellStyle}>
      <Text
        style={{
          color:
            alarmStateIndex !== -1 ||
            (entity._fieldsInAlarmState ?? []).includes('id')
              ? alarmStateColor
              : undefined
        }}
      >
        {value?.offline_id
          ? null
          : isTotalCostField
          ? formatTotalCost(value)
          : value}
      </Text>
    </DataTable.Cell>
  )
}
