import { ENVIRONMENT } from '@env'
const defaultState = {
  environment: ENVIRONMENT ? ENVIRONMENT : 'prod',
  gridOrList: 'grid',
  dayOrMonth: 'month',
  calendarsVisible: [],
  expandedChildren: null
}

export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'app/set-environment':
      state = { ...state, environment: action.environment }

      break
    case 'app/set-feature-flag':
      {
        const { feature, flagState } = action.args
        state = {
          ...state,
          features: { ...state.features, [feature]: flagState }
        }
      }

      break
    case 'app/set-netinfo':
      state = { ...state, netInfo: action.netInfo }

      break
    case 'app/set-grid-or-list':
      state = { ...state, gridOrList: action.gridOrList }

      break
    case 'app/set-calendar-style':
      state = { ...state, dayOrMonth: action.dayOrMonth }

      break
    case 'app/set-drawer-expanded-children':
      state = { ...state, expandedChildren: action.expandedChildren }

      break
    case 'app/set-calendars-visible':
      {
        const { calendarsVisible } = action
        state = { ...state, calendarsVisible }
      }

      break
  }

  return state
}
