import _ from "lodash"
import React from "react"
import { useDispatch} from "react-redux"

//actions

import RadioWithData from "./RadioWithData"


export const RadioAdhoc = React.forwardRef((props, ref) => {

  const options = _.get(props, "data.field.field_data.params.options", [])


  return (
    <RadioWithData
      {...props}
      dataRows={options}
      labelField="label"
      keyField="key"
      valueField="key"
      value={props.defaultValue || props.value}
    />
  )
})