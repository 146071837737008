import React from 'react'
import { FlatList, RefreshControl, Text, View } from 'react-native'
import { DataTable } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { cellStyle } from '../../screens/EntityListScreenHelpers/DataTableWrapper'
import _ from 'lodash'
import { ListItemLinkButton, openPdf, openFile } from '../ListItemLinkButton'

const getFilenameNoSig = (url) => {
  const fileName = url?.substr(url.lastIndexOf('/') + 1)
  const fileNameNoSig = decodeURIComponent(
    fileName.substr(0, fileName.indexOf('?'))
  )

  return fileNameNoSig
}
export const DocumentTableWrapper = ({ items, loadMoreItems }) => {
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', [])
  )

  return (
    <>
      <DataTable style={{ flex: 1 }}>
        <DataTable.Header>
          <DataTable.Title key={'name'}>Name</DataTable.Title>
          <DataTable.Title key={'files'}>Files</DataTable.Title>
        </DataTable.Header>
        <View style={{ flex: 1 }} key={'list-container'}>
          <FlatList
            numColumns={1}
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={() => {}} />
            }
            contentContainerStyle={{ paddingBottom: 30, flex: 1 }}
            onEndReached={loadMoreItems}
            onEndReachedThreshold={1}
            keyExtractor={(item) => item.id.toString()}
            data={items}
            renderItem={({ item, index: rowIndex }) => {
              const url = item.SYSTEM_report_url
              const filenameNoSig = getFilenameNoSig(url)

              const entityType = entityTypes.find(
                (e) => e.id === item.entity_type_id
              )
              return (
                <DataTable.Row
                  key={rowIndex}
                  style={{
                    backgroundColor: rowIndex % 2 ? 'transparent' : '#f1f1f1'
                  }}
                  onPress={() => {
                    item?.SYSTEM_report_url && openPdf(item.SYSTEM_report_url)
                  }}
                >
                  <DataTable.Cell key={'display_name'} style={cellStyle}>
                    <Text>{item.display_name}</Text>
                  </DataTable.Cell>
                  <DataTable.Cell
                    key={'files'}
                    style={[cellStyle, { paddingLeft: 0 }]}
                  >
                    <View style={{ flexDirection: 'column' }}>
                      {!!filenameNoSig && (
                        <ListItemLinkButton
                          entity={item}
                          title={'Report: ' + filenameNoSig}
                          key={item.id}
                          fileUrl={item.SYSTEM_report_url}
                          roleIdsVisible={entityType?.visible_to_roles}
                          onPress={() => {
                            url?.endsWith('pdf') ? openPdf(url) : openFile(url)
                          }}
                        />
                      )}
                      {item.files.map((f) => {
                        return (
                          <ListItemLinkButton
                            entity={item}
                            roleIdsVisible={
                              f.field?.field_data?.permissions?.visible_to
                            }
                            title={`${
                              f.field?.field_data?.title ?? 'File'
                            }: ${getFilenameNoSig(f.url)}`}
                            fileUrl={f.url}
                            key={f.id}
                            onPress={() => {
                              f.original_name.endsWith('pdf')
                                ? openPdf(f.url)
                                : openFile(f.url)
                            }}
                          />
                        )
                      })}
                    </View>
                  </DataTable.Cell>
                </DataTable.Row>
              )
            }}
          />
        </View>
      </DataTable>
    </>
  )
}
