import React, { useState } from 'react'
import { Text, View } from 'react-native'
import _ from 'lodash'
import spacing from '../../styles/spacing';
import MSFESButton from '../MSFESButton';
import { useDispatch } from 'react-redux'
import actions from '../../store/entity/actions';
import { openExternalLink } from '../../helpers/link';



export const CustomFunctionalityBIBViewReport = (props) => {

  const dispatch = useDispatch();

  const calculatedStyle = [props.style, { paddingLeft: spacing.m1, height: null }]

  const { data } = props

  const [detailsVisible, setDetailsVisible] = useState(false);

  const onViewReport = () => {
    dispatch(actions.fetchAuthenticatedBackgroundCheckUrl(props.defaultValue)).then(response => {
      openExternalLink(response.report_link);
    });
  }

  return <View style={calculatedStyle}>

{props.defaultValue ? <MSFESButton small title="View Report" containerStyle={{ padding: 0, flex: 1 }} onPress={onViewReport} /> : <Text>No report available</Text>}
  </View>
}
