import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import EntityAddComponent, {
  addMode,
  updateMode,
  viewMode
} from '../EntityAddComponent'
import { Overlay } from 'react-native-elements'
import { DateTime } from 'luxon'
import {
  useGetModalDimensions,
  useIsDesktop
} from '../../libraries/shouldShowDesktop'
import { showMessage } from 'react-native-flash-message'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
export const ViewEntityOverlay = ({
  entity,
  onDismiss,
  onRefetch = () => {},
  virtualEventCreateDate = null,
  onModifySchedulePressed = null,
  onDeleteSchedulePressed = null
}) => {
  const [currentPage, updateCurrentPage] = useState(1)

  const { entityType: resolvedEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    null,
    entity.entity_type_id,
    false
  )

  const navigation = useNavigation()

  const { calculatedHeight, calculatedWidth } = useGetModalDimensions()
  const isDesktop = useIsDesktop()
  const [isVisible, setIsVisible] = useState(true)
  const [mode, setMode] = useState(
    entity.id
      ? entity?.place === 'finalised'
        ? viewMode
        : updateMode
      : addMode
  )

  return (
    <Overlay
      onBackdropPress={() => {
        setIsVisible(false)
        onDismiss()
      }}
      isVisible={isVisible}
    >
      <View
        style={{
          maxHeight: calculatedHeight,
          flex: 1,
          maxWidth: calculatedWidth,
          minWidth: isDesktop ? undefined : calculatedWidth
        }}
      >
        {resolvedEntityType && (
          <EntityAddComponent
            currentPage={currentPage}
            onUpdatePage={updateCurrentPage}
            navigation={navigation}
            backButtonAction={() => {
              setIsVisible(false)
              onDismiss()
            }}
            onModifySchedulePressed={onModifySchedulePressed}
            onDeleteSchedulePressed={onDeleteSchedulePressed}
            isInSplitView={true}
            onCloseSplit={onDismiss}
            onEditToggled={() => {
              if (mode === viewMode) {
                setMode(updateMode)
              } else if (mode === updateMode) {
                setMode(viewMode)
              } else {
                setMode(addMode)
              }
            }}
            route={{
              params: {
                id: entity.id,
                type: resolvedEntityType?.name,
                mode,
                'field.virtual_event_created_date': DateTime.fromFormat(
                  virtualEventCreateDate,
                  'yyyy-MM-dd'
                ).toFormat('yyyy-MM-dd HH:mm:ss')
              }
            }}
            virtualEntity={entity.virtual ? entity : undefined}
            savedCallback={() => {
              setIsVisible(false)
              showMessage({
                message: resolvedEntityType?.name + ' saved.',
                type: 'success'
              })
              onRefetch()
              onDismiss()
            }}
          />
        )}
      </View>
    </Overlay>
  )
}
