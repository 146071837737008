import api from '../../data/api'
import _ from 'lodash'
import { getFilterString, getRouteForEntityDefinition } from '../entity/actions'

const listFiles = (entity, entityDefinition, args={}) => {
  const { filter, page, searchText, due_at } = args
  return (dispatch) => {

    let route = getRouteForEntityDefinition({ entityDefinitions: entityDefinition })

    route = `${route}/${entity.id}/versions`

    const filterString = getFilterString(filter);

    dispatch({
      type: 'version/list-started',
      data: entityDefinition,
      filterString
    })
    return api
      .get(route)
      .then(async (response) => {
        
        await dispatch({
          type: 'version/list-success',
          response,
          filterString,
          entity,
          entityDefinition
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'version/list-error',
          error
        })

        throw error
      })
  }
}

export default { listFiles }
