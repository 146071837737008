import React, { useState, useEffect, useMemo } from 'react'
import { View, ActivityIndicator, Dimensions } from 'react-native'
import { ActivityFeed } from '../components/WidgetButton/ActivityFeed'

import { useDispatch, useSelector } from 'react-redux'
import { showEntity } from '../store/entity/actions'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
import TenantBrandHeader from '../components/TenantBrandHeader'
import { MSFESFooter } from '../components/MSFESFooter'
import spacing from '../styles/spacing'
export const ActivityFeedKioskScreen = ({ route }) => {
  const { entityType: widgetEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    'widget'
  )
  const [widget, setWidget] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (widgetEntityType && !widget) {
      dispatch(
        showEntity({ id: route.params?.widget, type: widgetEntityType })
      ).then((resp) => {
        setWidget(resp.data)
      })
    }
  }, [widget, widgetEntityType])

  const { current_user } = useSelector((state) => {
    return {
      current_user: state?.users?.user
    }
  })

  const tenantRecord = useMemo(() => {
    return current_user.tenant_record
  }, [current_user])

  const { width, height } = Dimensions.get('window')

  return (
    <View style={{ width, height, flexDirection: 'column' }}>
      <View style={{ width }}>
        <TenantBrandHeader tenant={tenantRecord} style={{ marginBottom: 0 }} />
      </View>
      <View style={{ flex: 1 }}>
        {widget ? (
          <ActivityFeed widget={widget} isPoppedOut />
        ) : (
          <ActivityIndicator />
        )}
      </View>
      <View style={{ padding: spacing.m2 }}>
        <MSFESFooter />
      </View>
    </View>
  )
}
