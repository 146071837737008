import React, { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { ListItem } from 'react-native-elements'
import ListItemAsFieldProps, { InputSelectStyle } from './ListItemAsFieldProps'
import { buttonTheme } from './MSFESButton'
import spacing from '../styles/spacing'

const DaySelector = ({ on, onPress, label }) => {
  return (
    <View>
      <TouchableOpacity
        onPress={onPress}
        style={[
          on
            ? buttonTheme.light.variant.primary
            : buttonTheme.light.variant.outline,
          { borderRadius: 20, marginRight: spacing.m1, padding: spacing.m1 }
        ]}
      >
        <Text>{label}</Text>
      </TouchableOpacity>
    </View>
  )
}
export const DayOfWeekSelector = React.forwardRef((props, ref) => {
  const { style, onChangeText, value } = props

  const [localValue, setLocalValue] = useState(
    _.castArray(value?.split('|')).filter((f) => f)
  )
  useEffect(() => {
    if (props.defaultValue && props.entity.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }
  })

  const days = [
    { label: 'Su' },
    { label: 'Mo' },
    { label: 'Tu' },
    { label: 'We' },
    { label: 'Th' },
    { label: 'Fr' },
    { label: 'Sa' }
  ]

  const toggleOn = (day, index) => {
    let newValue
    if (localValue.includes(index)) {
      newValue = localValue.filter((l) => l !== index)
    } else {
      newValue = [...localValue, index]
    }
    setLocalValue(newValue)
    onChangeText(newValue.join('|'))
  }

  return (
    <View style={[style]}>
      <ListItem
        bottomDivider
        containerStyle={ListItemAsFieldProps.containerStyle}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title style={[InputSelectStyle]}>
            {days.map((day, index) => (
              <DaySelector
                key={index}
                label={day.label}
                on={localValue.includes(index)}
                onPress={() => toggleOn(day, index)}
              />
            ))}
          </ListItem.Title>
        </ListItem.Content>
      </ListItem>
    </View>
  )
})

export default DayOfWeekSelector
