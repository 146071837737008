// Colors
const pieGreen = '#289C1E'
const pieRed = '#AC182D'
const pieYellow = '#F9CA46'
const red100 = '#ac1f2e'
const orange200 = '#ff9933'
const yellow300 = '#FFDE59'
const darkblue700 = '#192e5b'
const blue800 = '#1E65A7'
const lightblue900 = '#7ABAF6'
const darkgreen400 = '#32cd33'
const green500 = '#00743F'
const lightgreen600 = '#1e65a7'

const colors = [
  pieGreen,
  pieRed,
  pieYellow,
  red100,
  orange200,
  yellow300,
  darkblue700,
  blue800,
  lightblue900,
  darkgreen400,
  green500,
  lightgreen600
]

// const progressGreen = '#289C1E'
// const progressOrange = '#EB6033'
// const progressRed = '#AC182D'
// const progressYellow = '#F9CA46'
// const progressBlue = '#0078FF'

// const progressBarColors = [
//   progressGreen,
//   progressOrange,
//   progressRed,
//   progressYellow,
//   progressBlue
// ]
const blueGrey50 = '#233d4d'
// const blueGrey300 = '#2596be'
// const orange700 = '#ff9933'
const grey900 = '#2596be'

// Typography
const sansSerif = "'Helvetica Neue', 'Helvetica', sans-serif"
const letterSpacing = 'normal'
const fontSize = 9

// Layout
const padding = 8
const baseProps = {
  width: 350,
  height: 350,
  padding: 50
}

// * Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding,
  fill: blueGrey50,
  stroke: 3,
  strokeWidth: 0
}

const assign = (obj1, obj2) => {
  return { ...obj1, ...obj2 }
}
const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

// Strokes
const strokeDasharray = '10, 5'
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

// Put it all together...
export const chartTheme = {
  area: assign(
    {
      style: {
        data: {
          fill: grey900
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  axis: assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: blueGrey50,
          strokeWidth: 2,
          strokeLinecap,
          strokeLinejoin
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding,
          stroke: 'transparent'
        }),
        grid: {
          fill: 'none',
          stroke: blueGrey50,
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted'
        },
        ticks: {
          fill: 'transparent',
          size: 5,
          stroke: blueGrey50,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin
        },
        tickLabels: assign({}, baseLabelStyles, {
          fill: blueGrey50
        })
      }
    },
    baseProps
  ),
  polarDependentAxis: assign({
    style: {
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent'
      }
    }
  }),
  bar: assign(
    {
      style: {
        data: {
          fill: blueGrey50,
          padding,
          strokeWidth: 0
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  boxplot: assign(
    {
      style: {
        max: { padding, stroke: blueGrey50, strokeWidth: 1 },
        maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
        median: { padding, stroke: blueGrey50, strokeWidth: 1 },
        medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
        min: { padding, stroke: blueGrey50, strokeWidth: 1 },
        minLabels: assign({}, baseLabelStyles, { padding: 3 }),
        q1: { padding, fill: blueGrey50 },
        q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
        q3: { padding, fill: blueGrey50 },
        q3Labels: assign({}, baseLabelStyles, { padding: 3 })
      },
      boxWidth: 20
    },
    baseProps
  ),
  candlestick: assign(
    {
      style: {
        data: {
          stroke: blueGrey50
        },
        labels: assign({}, baseLabelStyles, { padding: 5 })
      },
      candleColors: {
        positive: '#ffffff',
        negative: blueGrey50
      }
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey50,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  group: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  histogram: assign(
    {
      style: {
        data: {
          fill: blueGrey50,
          stroke: grey900,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
        fill: 'white',
        stroke: 'grey',
        strokeWidth: 2
      },
      labels: baseLabelStyles,
      title: assign({}, baseLabelStyles, { padding: 5 })
    }
  },
  line: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blue800,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  pie: assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: blueGrey50,
          strokeWidth: 0
        },
        labels: assign({}, baseLabelStyles, { padding: 10 })
      }
    },
    baseProps
  ),
  scatter: assign(
    {
      style: {
        data: {
          fill: blue800,
          opacity: 1,
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  stack: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  tooltip: {
    style: assign({}, baseLabelStyles, { padding: 0, pointerEvents: 'none' }),
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none'
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10
  },
  voronoi: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: assign({}, baseLabelStyles, {
          padding: 5,
          pointerEvents: 'none'
        }),
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none'
        }
      }
    },
    baseProps
  )
}
