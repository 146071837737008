import React from 'react'
import { List } from 'react-native-paper';
import sheet from '../styles/sheet';

export default function MSFESAccordion(props) {

  const { children, style, ...rest } = props

  const appliedStyle = [sheet.accordionViewStyle, style]
  return <List.Accordion style={appliedStyle} {...rest}>{children}</List.Accordion>
}