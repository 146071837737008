// libraries
import React, { useCallback, useState } from 'react'
import { View, Text } from 'react-native'
import { ListItem, Slider } from 'react-native-elements'
import ListItemAsFieldProps from './ListItemAsFieldProps'
import spacing from '../styles/spacing'
import colors from '../styles/colors'

export const nestedInputExtraProps = {
  inputInternalStyle: { backgroundColor: 'transparent' }
}

export const NumberSlider = React.forwardRef((props, ref) => {
  const { defaultValue, editable, style } = props //child props

  let minimumValue = props.data?.field?.field_data?.validation?.min ?? 0
  if (!minimumValue) {
    minimumValue = 0
  }
  minimumValue = parseInt(minimumValue)
  let maximumValue = props.data?.field?.field_data?.validation?.max ?? 100
  if (!maximumValue) {
    maximumValue = 100
  }
  maximumValue = parseInt(maximumValue)

  const [localValue, setLocalValue] = useState(
    parseInt(defaultValue ?? minimumValue)
  )
  const wrappedSetLocalValue = (newValue) => {
    setLocalValue(newValue)
    // onChangeText(newValue, true)
  }

  const finished = useCallback(
    (args) => {
      props.onChangeText(args)
    },
    [localValue]
  )
  return (
    <View style={[style, { height: null }]}>
      <ListItem
        containerStyle={[ListItemAsFieldProps.containerStyle, { height: null }]}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          {/* <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
            {localValue} {minimumValue} : {maximumValue}
          </ListItem.Title> */}
          <View
            style={{
              flex: 1,
              width: '100%',
              padding: spacing.m1,
              flexDirection: 'column'
            }}
          >
            <Slider
              minimumValue={minimumValue}
              maximumValue={maximumValue}
              minimumTrackTintColor={colors.brightOrange}
              thumbStyle={{
                borderWidth: 2,
                borderColor: !editable
                  ? colors.blueLighter
                  : colors.brightOrange,
                backgroundColor: colors.white
              }}
              disabled={editable ? false : true}
              trackStyle={{ height: 16, borderRadius: 8 }}
              thumbProps={{
                children: (
                  <View
                    style={{
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Text>{localValue}</Text>
                  </View>
                )
              }}
              step={1}
              onSlidingComplete={finished}
              value={localValue ?? minimumValue}
              onValueChange={wrappedSetLocalValue}
            />
            <View style={{ flexDirection: 'row' }}>
              <Text>{minimumValue}</Text>
              <View style={{ flex: 1 }}></View>
              <Text>{maximumValue}</Text>
            </View>
          </View>
        </ListItem.Content>
        {/* {editable && <ListItem.Chevron type="font-awesome" name="chevron-down" size={14} color={"black"} />} */}
      </ListItem>
    </View>
  )
})
