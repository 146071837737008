import React, { useState } from 'react'
import { View } from 'react-native'
import {
  getAvailableFilters,
  simplifyFilters
} from '../../screens/EntityListScreen'
import { FilterBar } from '../FilterBar'
import MSFESButton from '../MSFESButton'
import { FiltersConfigurator } from '../FiltersConfigurator'

export const SelectFilters = (props) => {
  const entityType = props.data?.entity

  const availableFilters = getAvailableFilters(entityType)

  const [filters, setFilters] = useState({})

  const [configureFiltersVisible, setConfigureFiltersVisible] = useState(false)

  const onFiltersChanged = (newFilters) => {
    setFilters(newFilters)

    const newFiltersSimplified = simplifyFilters(
      filters,
      newFilters,
      availableFilters
    )

    const newFiltersLength = Object.keys(newFiltersSimplified).length

    const newFilterObject = newFiltersLength
      ? {
          ...filters,
          ...newFiltersSimplified,
          detailed: true
        }
      : { detailed: true }

    props.changeHandler(newFilterObject)
  }

  const toggleConfigureFilters = (newValue = null) => {
    const nextValue = newValue !== null ? newValue : !configureFiltersVisible

    setConfigureFiltersVisible(nextValue)
  }

  const hasFiltersApplied = availableFilters?.some(
    (f) => props.defaultValue?.[f.field_data.property]
  )

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', flex: 1, minHeight: 48 }}>
        <View style={{ flex: 1 }}>
          <FilterBar
            filterEntityType={entityType}
            onBackdropPress={() => {}}
            filtersApplied={props.defaultValue}
            filtersAvailable={availableFilters}
            forceShowOptions={true}
            // onFiltersChanged={onFiltersChanged}
          />
        </View>
        <MSFESButton
          variant="small"
          light
          icon={{
            name: 'sliders-h',
            type: 'font-awesome-5',
            size: 16,
            color: hasFiltersApplied ? 'red' : undefined
          }}
          onPress={() => toggleConfigureFilters(true)}
        />
      </View>

      {configureFiltersVisible && (
        <FiltersConfigurator
          filterEntityType={entityType}
          onBackdropPress={() => toggleConfigureFilters(false)}
          filtersApplied={props.defaultValue}
          filtersAvailable={availableFilters}
          onFiltersChanged={onFiltersChanged}
        />
      )}
    </View>
  )
}
