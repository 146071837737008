// libraries
import React, { useMemo } from 'react'

import { Text, View } from 'react-native'
import colors from '../styles/colors'
import spacing from '../styles/spacing'
import fonts from '../styles/fonts'
// styles

//actions

// components
import MSFESIcon from './MSFESIcon'

const DisplayErrors = ({ errors, dark = false, onClearError = null }) => {

  const backgroundColor = dark ? colors.validationErrorBackgroundDark : colors.validationErrorBackground

  const style = useMemo(() => {
    return {
      backgroundColor,
      justifyContent: 'center',
      padding: spacing.m2, marginTop: spacing.m1, marginBottom: spacing.m2,
      borderRadius: spacing.borderRadius,
      flexDirection:'row'
    }
  }, [])
  return (
    errors && <View
      style={style}
    >
      <MSFESIcon name="exclamation-circle" size={20} color={colors.validationErrorText} style={{marginRight: spacing.m2}} />
      <Text style={{color: colors.validationErrorText, fontWeight: "bold", fontSize: fonts.inputLabelSize, flex: 1}}>{errors}</Text>
    </View>
  )
}

export default DisplayErrors
