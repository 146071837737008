import React from 'react'
import { Text, View, Image } from 'react-native'
import colors from '../styles/colors';
import shared from '../styles/shared';
import MSFESIcon from './MSFESIcon';

import { useSelector } from 'react-redux'
import { Badge } from 'react-native-elements';
import { headerTextStyle } from './WithHeader';

import _ from 'lodash'
import spacing from '../styles/spacing';

const WithHeaderSplitView = ({ title, rightButtonIcon, rightButtonRef, rightButtonAction, dropdownVisibilityState, onCloseSplit }) => {

  const unreadNotificationsCount = useSelector((state) =>
    _.get(state.notifications, 'unread_notifications.meta.total', null)
  )


  const headerTitleObj = (!!title && <Text style={headerTextStyle}>
    {title}
  </Text>)

  return <View style={{ flexDirection: 'row', backgroundColor: colors.headerBackground, height: 54 }}>

    <View style={[{ flex: 1, justifyContent: "center", alignContent: "flex-start" }, shared.debugOff]}>
      {headerTitleObj}
    </View>


    {rightButtonAction && (
      <View ref={rightButtonRef}
        style={{ position: "relative", marginRight: spacing.m1, backgroundColor: dropdownVisibilityState ? colors.lightestGray : undefined, marginVertical: 4, borderTopLeftRadius: spacing.borderRadius, borderTopRightRadius: spacing.borderRadius, paddingBottom: 4 }}>
        <View style={{ position: "relative", alignItems: "center", justifyContent: "center", minWidth: 56, paddingLeft: 2, backgroundColor: dropdownVisibilityState ? colors.blueDarker : colors.blueDarker, borderRadius: spacing.borderRadius }}>

          <MSFESIcon
            tappable
            name={rightButtonIcon}
            color={colors.white}
            size={24}
            onPress={rightButtonAction}
            type="font-awesome"
          />
          {!!unreadNotificationsCount && (rightButtonIcon === 'bell') && <Badge
            status="success"
            value={unreadNotificationsCount}
            containerStyle={{ position: 'absolute', top: 4, right: 4 }}
          />}
        </View>
      </View>
    )}

    {onCloseSplit && (
      <View style={{ position: "relative" }}>
        <MSFESIcon
          tappable
          name={"times"}
          size={24}
          onPress={onCloseSplit}
          type="font-awesome"
        />
      </View>
    )}
  </View>
}

export default WithHeaderSplitView;