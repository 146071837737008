// external libraries
import React, { useEffect, useRef, useState } from 'react'
import { StatusBar } from 'expo-status-bar'
import { View, AppState, NativeModules, ActivityIndicator } from 'react-native'
import { NavigationContainer, getStateFromPath } from '@react-navigation/native'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-native-gesture-handler'
import { TimeFormatContext } from './contexts/TimeFormatContext'
import { ColorContext } from './contexts/ColorContext'
// configure Intl polyfill
// per https://sentry.io/organizations/msfes/issues/3314577838/?project=6179271&query=is%3Aunresolved+intl&statsPeriod=14d
import 'intl'
import 'intl/locale-data/jsonp/en' // other local data is available, import each locale that you need

// internal libraries
import configureStore from './store'

// screens
import FlashMessage from 'react-native-flash-message'
import AuthGateScreen from './screens/AuthGateScreen'
import { Platform } from 'react-native'
import shared from './styles/shared'
import { Portal } from 'react-native-paper'
import analytics from './libraries/analytics'
import { en, registerTranslation } from 'react-native-paper-dates'

const { store, persistor } = configureStore()
registerTranslation('en', en)

import { LogBox } from 'react-native'
LogBox.ignoreLogs(['Warning: ...']) // Ignore log notification by message
LogBox.ignoreAllLogs() //Ignore all log notifications

export let landedPublicUrlParams = null
export const linking = {
  prefixes: Platform.OS !== 'web' ? ['https://app.emberfd.com'] : undefined,
  getStateFromPath: (path, config) => {
    const state = getStateFromPath(path, config)
    if (state?.routes?.length && Platform.OS !== 'web') {
      landedPublicUrlParams = state.routes[0].params
    }
    return state
  },
  config: {
    screens: {
      PublicFormAddScreen: {
        path: '/public_forms/:entityType',
        parse: {
          entityType: (entityType) => {
            return entityType
          }
        }
      },
      ActivityFeedKioskScreen: {
        path: '/activity_feed_kiosk/:widget',
        parse: {
          widget: (widget) => {
            return widget
          }
        }
      },
      StoryBook: '/storybook',
      SigninScreen: '/signin',
      ForgotPasswordScreen: '/forgot-password',
      ResetPasswordScreen: '/reset-password',
      Messages: {
        path: '/messages',
        screens: {
          Messages: '/'
        }
      },

      FireHR: {
        path: '/',
        screens: {
          Notifications: '/notifications',
          Tasks: {
            path: '/tasks',
            screens: {
              TasksIndex: '/',
              TaskAddSelectType: '/add',

              TaskView: '/:type/:id',
              TaskEdit: '/edit'
            }
          },
          Settings: {
            path: '/settings',
            screens: {
              SettingsIndex: '/',
              SettingsAdminTools: '/admin-tools/index',
              SettingsCollections: '/collections',
              SettingsReports: '/reports/index',
              SettingsReportsShowReport: '/report/:reportId',
              SettingsAdminToolsPermissions: '/admin-tools/permissions',
              SettingsAdminModules: '/admin-tools/modules',
              SettingsManageLogins: '/manage-logins',
              SettingsManageEntityTypes:
                '/admin-tools/manage-entity-types/index',
              SettingsAdminEntityTypeAdd:
                '/admin-tools/manage-entity-types/add',
              SettingsAdminEntityTypeEdit:
                '/admin-tools/manage-entity-types/:id'
            }
          },

          Calendar: {
            path: '/calendar',
            screens: {
              CalendarView: '/'
            }
          },
          Home: {
            path: '/',
            screens: {
              Dashboard: '/dashboard',
              CategoryTree: '/categorytree',
              CategoryView: '/category/:id',
              Home: '/',
              EntityEdit: '/entity/edit/:id',
              EntityView: '/entity/:type/:id',
              EntityList: '/entity-list',
              EntityListDetail: '/entity-list-detail',
              EntityAdd: '/entity/add',
              AdminTenantList: '/admin/tenant',
              AdminTenantView: '/admin/tenant/view',

              AdminEntityList: '/admin/entity/',

              ConversationCreate: '/conversations/create/',
              ConversationView: '/conversations/:id',
              ConversationList: '/conversations',
              DocumentList: '/documents/',
              DocumentView: '/documents/:id'
            }
          }
        }
      }
    }
  }
}

import sentryInit from './helpers/sentry'
import useMount from './hooks/useMount'
import useDeepLink from './hooks/useDeepLink'
import PublicFormsNavigator from './screens/PublicFormsNavigator'
import KioskNavigator from './screens/KioskNavigator'

sentryInit()

var appKey
var accessKey

if (Platform.OS !== 'web') {
  if (Platform.OS === 'ios') {
    appKey =
      'd7lJrRMHKxzPjQpbyO4rQMN2ia%2B06j9hgPxDV4U5AQ8YQn8%2BDbKbPVNsj%2FZVLaD1E8IA0L0udBo%3D'
    accessKey =
      'KrGBiGJwC%2FQlKiT5lYG6QfJne5UQ3FKPrJjfE9QLKZnrImSNFhIYyUOb8htGQh5KfUj6LTb%2Fx1ndo0f3ty7p0ua%2FaPUg0usUiEr%2FA9sijdXyGb4zlpVCIA%3D%3D'
  } else {
    appKey =
      'd7lJrRMHKxzPjQpbyO4rQMN2ia%2B06j9hgPxDV4U5AQ8YQn8%2BDbKbPVNsj%2FZVLaD1E8IA0L0udBo%3D'
    accessKey =
      'KrGBiGJwC%2FQlKiT5lYG6QVShN16mmoYX8wnlLkzg%2FA4w4XGypz2o3tXGpYvBYIS9n3vusOr7Kc21ydFDwhQX77Io4ZpkoseQpeuxeGqqsYycmUd2L%2FB54Q%3D%3D'
  }
}

let App = function () {
  /* navigationRef can be null 🙄 so once the navigator is onReady, we save the ref
  Pass the ref on down (via refValidated)
  So the top nav bar can perform navigation actions when it's a bit out of scope
  */
  const navigationRef = useRef(null)
  const [refValidated, setRefValidated] = useState(null)

  const _handleAppStateChange = (currentAppState) => {
    analytics.TrackEvent('App state changed', {
      nextState: currentAppState
    })

    if (currentAppState === 'active') {
      // check for updates.
      if (Platform.OS !== 'web') {
        const codePush = require('react-native-code-push')
        codePush.sync({
          installMode: codePush.InstallMode.ON_NEXT_RESUME
        })
      }
    }
  }

  useEffect(() => {
    const ENABLE_ZOHO = true
    if (Platform.OS !== 'web') {
      if (NativeModules.RNZohoSalesIQ) {
        const { ZohoSalesIQ } = require('react-native-zohosalesiq-mobilisten')

        if (ENABLE_ZOHO) {
          ZohoSalesIQ.initWithCallback(appKey, accessKey, (success) => {
            if (success) {
              ZohoSalesIQ.setLauncherVisibility(true)
              // To show the default live chat launcher, you can use the setLauncherVisibility API.
              // Alternatively, you may use the 'Avail floating chat button for your app' option under Settings → Brands → Installation → Android/iOS.
            } else {
              // your code to handle initialization failure
            }
          })
        }
      }
    }
  }, [])

  AppState.addEventListener('change', _handleAppStateChange)

  const globalFlashRef = useRef()

  const [isPublicForms, setIsPublicForms] = useState({
    showPublicForm: null,
    publicFormEntityTypeName: null
  })

  const [isKiosk, setIsKiosk] = useState({ isKiosk: false })
  useMount(() => {
    useDeepLink(async (url) => {
      if (url) {
        if (url.pathname?.indexOf('/public_forms/') === 0) {
          const pieces = url.pathname.split('/')
          setIsPublicForms({
            showPublicForm: true,
            publicFormEntityTypeName: pieces[2]
          })
        } else {
          setIsPublicForms({ showPublicForm: false })
        }

        if (url.pathname?.indexOf('/activity_feed_kiosk/') === 0) {
          setIsKiosk({ isKiosk: true })
        } else {
          setIsKiosk({ isKiosk: false })
        }
      } else {
        setIsPublicForms({ showPublicForm: false })
      }
    })
  })
  const [timeFormat, setTimeFormat] = useState({})
  const [colorContext, setColorContext] = useState({
    entity: null,
    entityType: null,
    color: null
  })

  return (
    <Provider store={store}>
      {Platform.OS === 'web' && (
        <>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;700&display=swap"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600&display=swap"
            rel="stylesheet"
          />
        </>
      )}
      <PersistGate loading={null} persistor={persistor}>
        <StatusBar style="auto" />
        <ColorContext.Provider value={{ colorContext, setColorContext }}>
          <TimeFormatContext.Provider value={{ timeFormat, setTimeFormat }}>
            <Portal.Host>
              <View style={[{ flex: 1 }, shared.debugOff]}>
                <NavigationContainer
                  linking={linking}
                  ref={navigationRef}
                  onReady={() => {
                    setRefValidated(navigationRef)
                  }}
                >
                  {isKiosk.isKiosk ? (
                    <KioskNavigator />
                  ) : (
                    <>
                      {isPublicForms.showPublicForm == false &&
                        !!landedPublicUrlParams && (
                          <View>
                            <ActivityIndicator />
                          </View>
                        )}
                      {isPublicForms.showPublicForm == false &&
                        !landedPublicUrlParams && (
                          <AuthGateScreen navigation={refValidated?.current} />
                        )}
                      {isPublicForms.showPublicForm == true && (
                        <PublicFormsNavigator />
                      )}
                    </>
                  )}
                </NavigationContainer>
              </View>
            </Portal.Host>
          </TimeFormatContext.Provider>
        </ColorContext.Provider>
        <FlashMessage
          canRegisterAsDefault={true}
          ref={globalFlashRef}
          position="top"
          floating={true}
        />
      </PersistGate>
    </Provider>
  )
}

const getApp = () => {
  if (Platform.OS === 'web') {
    return App
  } else {
    const codePush = require('react-native-code-push')
    let options =
      Platform.OS === 'web'
        ? null
        : {
            installMode: codePush.InstallMode.ON_NEXT_RESUME,
            checkFrequency: codePush.CheckFrequency.ON_APP_RESUME
          }

    return codePush(options)(App)
  }
}

export default getApp()
