import React from 'react'
import {
  TouchableOpacity,
  View,
  Image,
  Platform,
  useWindowDimensions
} from 'react-native'
import shared from '../styles/shared'
const SignInLogo = (props) => {
  const { onPress = null } = props
  const imageMinHeight = Platform.OS === 'web' ? 300 : 100

  const imageMaxWidth = useWindowDimensions().height > 750 ? 180 : 120

  const Container = onPress ? TouchableOpacity : View
  return (
    <View
      style={[
        shared.debugOff,
        {
          alignItems: 'center',
          flex: 1 / 3,
          minHeight: imageMinHeight,
          flexDirection: 'row'
        }
      ]}
    >
      <Container
        onPress={onPress}
        activeOpacity={1}
        style={[
          { flex: 1, minHeight: imageMinHeight, alignItems: 'center' },
          shared.debugOff
        ]}
        pointerEvents={onPress ? 'auto' : 'none'}
      >
        <Image
          source={require('../images/ember_phoenix.png')}
          style={[
            {
              width: '100%',
              maxWidth: imageMaxWidth,
              minHeight: imageMinHeight
            },
            shared.debugOff
          ]}
          resizeMode="contain"
        />
      </Container>
    </View>
  )
}

export default SignInLogo
