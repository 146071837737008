import { Platform } from "react-native";
import colors from "../styles/colors";
import fonts from "../styles/fonts";
import spacing from "../styles/spacing";

export const fieldHeight = 48;


export const InputTextStyle = {
  color: colors.inputText,
  fontFamily: fonts.inputTextFace,
  fontSize: fonts.inputTextSize,
  fontWeight: "500",
  minHeight: 50
};

export const InputSelectStyle = {
  ...InputTextStyle,
  marginTop: 30,
  paddingTop: Platform.OS === "ios" || Platform.OS === "android" ? 12 : 0
}

const ListItemAsFieldProps = {
  containerStyle: { paddingLeft: 0, paddingTop: 2, paddingBottom: 6,height: fieldHeight,marginTop: 0, backgroundColor:"transparent", borderColor: "transparent", borderWidth: 0 },
  textStyle: {...InputTextStyle},
  touchableProps: {
    underlayColor: "transparent"
  }
}
export default ListItemAsFieldProps