import React, { useMemo } from 'react'
import {
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryPie,
  VictoryAxis,
  VictoryLabel
} from 'victory-native'
import { chartTheme } from './ChartTheme'
import {
  View
  //Text
} from 'react-native'
import { transformViewData } from './WidgetButtonChart'
import colors from '../../styles/colors'

export const Chart = ({ viewData, widget, isPoppedOut = false }) => {
  const { transformed, hasAnyData } = useMemo(() => {
    const transformed = transformViewData(
      viewData?.values,
      widget.group_function?.key
    )

    const hasAnyData = transformed?.length && transformed?.some((t) => t.y)

    return { transformed, hasAnyData }
  }, [viewData, widget.group_function?.key])

  //parseDate into date object so we can compare the dates and not see if a string > string but if these numbers > numbers
  //this helps order things chronologically
  const parseDate = (date) => new Date(date)

  //destructure dateString array to allow re formating via split and returning the properly formatted date.
  const reformatDate = (dateString) => {
    const [year, month, day] = dateString.split('-')

    return `${month}-${day}-${year}`
  }

  //I needed a way to compare two strings (they were dates) so using parseDate and JS date object we parse them to comparable values
  //Cannot simply compare string to string. Made the date string comparable and sorted through the shallow copied transformed array (To order them chronologically).
  //If the result is negative, a comes before b; if positive, a comes after b; if zero, they are equal.

  //then added safeguards because when you clicked a widget transformed was either null or undefined causing a crash. So if transformed true then perform sort else its an empty array
  //just to handle cases where it could cause errors
  const sortedData = transformed
    ? [...transformed].sort((a, b) => parseDate(a.x) - parseDate(b.x))
    : []

  //Extract the last 30 elements from the sortedData array. (These just happen to be individual days so -30 = last 30 days)
  const last30DaysData = sortedData.slice(-30)

  //Call our reformatDate function to reformat the date so it looks better to the user.
  const formattedData = last30DaysData.map((data) => ({
    ...data,
    x: reformatDate(data.x)
  }))

  let isPlanner = widget.position?.key === 'planner'

  return (
    <>
      {/* {widget.group_function?.key === 'single-entity' && (
        <VictoryChart theme={chartTheme}>
          <VictoryLine data={transformed} theme={chartTheme} x="x" y="y" />
        </VictoryChart>
      )} */}

      {widget.group_function?.key === 'multi_line' && hasAnyData && (
        <View
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <VictoryChart theme={chartTheme} height={500} width={1000}>
            <VictoryAxis dependentAxis />
            <VictoryAxis
              tickLabelComponent={
                <VictoryLabel angle={45} textAnchor="start" dy={-4} />
              }
            />
            {/* {transformed.map((data, index) => ( */}
            <VictoryLine
              // key={index}
              data={formattedData}
              x="x"
              y="y"
              style={{
                data: {
                  stroke: colors.strokeColors[0]
                  //[index % colors.strokeColors.length]
                }
              }}
            />
            {/* ))} */}
          </VictoryChart>
        </View>
      )}

      {widget.group_function?.key === 'piechart' && (
        <View
          style={{
            marginTop: 0,
            flex: 1
          }}
        >
          <View
            style={{
              position: 'relative',
              flex: 1
            }}
          >
            {isPoppedOut && (
              <View
                style={{
                  position: 'absolute',
                  width: '100%',
                  flex: 1,
                  zIndex: 1
                }}
              >
                <VictoryLegend
                  title="Legend"
                  centerTitle
                  orientation="horizontal"
                  theme={chartTheme}
                  data={transformed.map((t, index) => ({
                    name: t.x,
                    symbol: {
                      fill: 'white',
                      stroke:
                        colors.strokeColors[index % colors.strokeColors.length],
                      strokeWidth: 2
                    }
                  }))}
                />
              </View>
            )}
            <VictoryPie
              data={transformed}
              innerRadius={({ datum }) => {
                const maxValue = Math.max(...transformed.map((d) => d.y))
                return datum.y === maxValue ? 0 : 30
              }}
              labelRadius={({ datum }) => {
                const maxValue = Math.max(...transformed.map((d) => d.y))
                if (datum.y === maxValue) {
                  return isPlanner ? datum.y + 50 : datum.y + 50
                } else {
                  return isPlanner ? datum.y + 100 : datum.y + 100
                }
              }}
              labels={({ datum }) => `${datum.y}%`}
              startAngle={isPlanner ? -30 : -30}
              endAngle={450}
              padAngle={3}
              style={{
                labels: {
                  fontSize: isPlanner ? 14 : 14,
                  fill: 'black'
                }
              }}
              radius={({ datum }) => {
                const maxValue = Math.max(...transformed.map((d) => d.y))
                if (isPlanner) {
                  return datum.y === maxValue ? 100 : 90
                }
                return datum.y === maxValue ? 100 : 90
              }}
              theme={chartTheme}
              colorScale={chartTheme.pie.colorScale}
            />
            {!isPoppedOut && (
              <View
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  marginTop: !isPlanner ? '75%' : '75%'
                }}
              >
                <VictoryLegend
                  x={!isPlanner ? 75 : 75}
                  orientation="horizontal"
                  gutter={20}
                  style={chartTheme}
                  data={transformed.map((t, index) => ({
                    name: t.x,
                    symbol: {
                      fill: 'white',
                      stroke:
                        colors.strokeColors[index % colors.strokeColors.length],
                      strokeWidth: 2
                    }
                  }))}
                />
              </View>
            )}
          </View>
        </View>
      )}
      {widget.group_function?.key === 'gauge' && (
        <View style={{ marginTop: 0 }}>
          <View style={{ position: 'relative' }}>
            {isPoppedOut && (
              <View
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  marginTop: '-15%'
                }}
              >
                <VictoryLegend
                  title="Legend"
                  centerTitle
                  orientation="horizontal"
                  theme={chartTheme}
                  data={transformed.map((t) => ({ name: t.x }))}
                />
              </View>
            )}
            <View style={{ marginTop: '15%', marginBottom: '-15%' }}>
              <VictoryPie
                labels={isPoppedOut ? () => '' : undefined}
                startAngle={90}
                theme={chartTheme}
                endAngle={-90}
                data={transformed}
              />
            </View>
          </View>
        </View>
      )}
    </>
  )
}
