import React, { useEffect, useMemo, useState } from 'react'
import { headerStyles } from '../../components/OverlayHeader'

import { useSelector } from 'react-redux'
import { Text, View } from 'react-native'
import { CalendarProvider, TimelineList } from 'react-native-calendars'
import spacing from '../../styles/spacing'
import { organizeAgendaItems } from './CalendarEntityList'
import { DateTime } from 'luxon'

export const CalendarAgenda = ({
  date,
  onEntityClicked,
  onAddPressed,
  calendarsVisible
}) => {
  const { byDate, entityTypes } = useSelector((appState) => {
    return {
      byDate: appState.calendar.byDate,
      entityTypes: appState.entityTypes?.data
    }
  })

  const { agendaItems } = useMemo(() => {
    const dateObject = byDate[date]

    if (date) {
      return {
        agendaItems: {
          [date]: organizeAgendaItems(
            dateObject?.entities,
            dateObject?.virtualEvents,
            date,
            entityTypes,
            calendarsVisible,
            true
          )
        }
      }
    }
    return {
      relevantEntities: [],
      virtualEvents: [],
      agendaItems: {}
    }
  }, [date, byDate, entityTypes, calendarsVisible])

  const [friendlyDate, setFriendlyDate] = useState(null)
  useEffect(() => {
    if (date) {
      setFriendlyDate(
        DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
      )
    }
  }, [date])

  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <View style={{ flexDirection: 'column', flex: 1 }}>
        <View style={{ flex: 1, marginTop: spacing.m2 }}>
          <Text style={headerStyles.textStyle}>Agenda {friendlyDate}</Text>

          {date && agendaItems && (
            <CalendarProvider
              date={date}
              onDateChanged={() => {}}
              onMonthChange={() => {}}
              showTodayButton
              disabledOpacity={0.6}
              // numberOfDays={3}
            >
              <TimelineList
                timelineProps={{
                  onEventPress: (event) => {
                    if (event.entity) {
                      onEntityClicked(event.entity)
                    }
                  },
                  onBackgroundLongPress: (dateRequested) => {
                    onAddPressed(dateRequested)
                  }
                }}
                events={agendaItems}
                showNowIndicator
                // scrollToNow
                scrollToFirst
              />
            </CalendarProvider>
          )}
        </View>
      </View>
    </View>
  )
}
