import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import userActions from '../store/user/actions'
import transitionActions from '../store/transition/actions'

import HomeContainer from '../components/HomeContainer'

export default function HomeScreen({ route, navigation }) {
  const access_token = useSelector((state) => state.users.access_token)

  const dispatch = useDispatch()

  useEffect(() => {
    if (access_token) {
      dispatch(userActions.fetchUser())
        .then(() => {
          dispatch(transitionActions.listTransitions())
          dispatch(transitionActions.listPlaces())
        })
        .catch((e) => {
          if (e.statusCode === 401) {
            // user is unauthorised. Dump access token to sign in again.
            dispatch(userActions.purgeToken())
          }
        })
    }
  }, [])

  return (
    <View style={{ flex: 1 }}>
      <HomeContainer route={route} navigation={navigation} />
    </View>
  )
}
