import React, { useMemo } from 'react'
import { Text, View, FlatList, RefreshControl } from 'react-native'
import { ListItemLinkButton, openPdf, openFile } from '../ListItemLinkButton'
import { useSelector } from 'react-redux'
export const DocumentListWrapper = ({ items, loadMoreItems }) => {
  const entityTypes = useSelector((appState) => appState.entityTypes?.data)

  const skipReportEntityTypes = ['upload_document']
  const skipReportEntityTypeIds = useMemo(() => {
    return entityTypes
      ? entityTypes
          .filter((e) => skipReportEntityTypes.includes(e.name))
          .map((e) => e.id)
      : []
  }, [entityTypes])

  return (
    <FlatList
      numColumns={1}
      refreshControl={
        <RefreshControl refreshing={false} onRefresh={() => {}} />
      }
      contentContainerStyle={{ paddingBottom: 30, flex: 1 }}
      onEndReached={loadMoreItems}
      onEndReachedThreshold={1}
      keyExtractor={(item) => item.id.toString()}
      data={items}
      renderItem={({ item }) => {
        return (
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: '#ccc'
            }}
          >
            <Text>{item.display_name}</Text>
            {!skipReportEntityTypeIds.includes(item.entity_type_id) && (
              <ListItemLinkButton
                entity={item}
                title={item.report_name}
                key={item.id}
                fileUrl={item.SYSTEM_report_url}
                onPress={() => {
                  openPdf(item.SYSTEM_report_url)
                }}
              />
            )}
            {item.files.map((f) => (
              <ListItemLinkButton
                entity={item}
                title={f.original_name}
                fileUrl={f.url}
                key={f.id}
                onPress={() => {
                  f.original_name.endsWith('pdf')
                    ? openPdf(f.url)
                    : openFile(f.url)
                }}
              />
            ))}
          </View>
        )
      }}
    />
  )
}
