import React, { Component } from 'react'
import { Platform, TextInput, View } from 'react-native'

import ColorPicker from 'react-native-wheel-color-picker'
import { Overlay } from 'react-native-elements'
import MSFESButton from './MSFESButton'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
class InsertHTMLOverlay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      html: props.html
    }

  }
  setHTML = (event) => {

    const html = (Platform.OS === "web" ? event.target.value : event.nativeEvent.text)

    this.setState({ html: html })
  }
  acceptPressed = () => {
    this.props.onChange(this.state.html);
    this.props.onClosePressed();
  }
  cancelPressed = () => {
    this.props.onClosePressed();
  }
  render() {

    const swatchesOnly = false
    return (
      <Overlay isVisible={true} onBackdropPress={this.props.onClosePressed}>

        <View style={{ width: "100%", minWidth: 300 }}>
          <TextInput onBlur={this.setHTML} multiline={true} numberOfLines={3} containerStyle={{ flex: 1, height: "100%" }} style={{ height: "100%", backgroundColor: colors.formInputBackground, borderRadius: spacing.borderRadius, padding: spacing.m1 }} placeholder="Paste HTML embed snippet." defaultValue={this.state.html} />
          <View style={{ flexDirection: "row", marginTop: spacing.m1, width: "100%" }}>
            <MSFESButton
              modifier="outline" containerStyle={{ flex: 1 }}
              title={'Cancel'}
              onPress={this.cancelPressed}
            />
            <MSFESButton
              title={'Accept'} containerStyle={{ flex: 1 }}
              onPress={this.acceptPressed}
            />
          </View>

        </View>
      </Overlay>

    )
  }
}

export default InsertHTMLOverlay