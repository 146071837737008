import status from 'http-status'
import uppercamelcase from 'uppercamelcase'

// https://gist.github.com/TooTallNate/4fd641f820e1325695487dfd883e5285
class HTTPError extends Error {
  constructor(code, message, extras) {
    super(message || status[code])
    if (arguments.length >= 3 && extras) {
      Object.assign(this, extras)
    }
    this.name = toName(code)
    this.statusCode = code
  }
}

export function toName(code) {
  const suffix =
    ((code / 100) | 0) === 4 || ((code / 100) | 0) === 5 ? 'error' : ''
  return uppercamelcase(String(status[code]).replace(/error$/i, ''), suffix)
}

export default HTTPError
