import React, { useMemo } from 'react'
import { View, FlatList, Dimensions, Text, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import { ModuleButton } from '../ModuleButton'
import { useColumnHelper } from '../../hooks/useColumnHelper'
import { getSortedEntitiesFromStore, noData } from '../HomeContainer'
import MSFESButton from '../MSFESButton'
import shared from '../../styles/shared'
import _ from 'lodash'
import { useIsDesktop } from '../../libraries/shouldShowDesktop'
import { useActionNavigateTo } from '../../hooks/useActionNavigateTo'
import { tileButtonWidth } from '../../screens/CategoryViewScreen'
import spacing from '../../styles/spacing'
import fonts from '../../styles/fonts'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
import { useChildCategoriesWithPermissionResolver } from '../../hooks/useChildCategoriesWithPermissions'
export const treeCategoryLink = (category, actionNavigateTo) => {
  if (category?.navigate_to) {
    return () => actionNavigateTo(category?.navigate_to)
  }
  return `/categorytree?parent=${encodeURIComponent(
    category.display_name
  )}&id=${category.id}`
}

export const TreeCategoriesMatrix = ({ parentId, isHome = false }) => {
  const { entityType: entityTypeScreenButtons } = useDiscoverEntityEntityType(
    null,
    null,
    'screen_button'
  )

  const resolver = useChildCategoriesWithPermissionResolver()
  const { screenButtonsWrapper } = useSelector((appState) => {
    const screenButtonsWrapper =
      getSortedEntitiesFromStore(entityTypeScreenButtons, appState) ?? noData

    return { screenButtonsWrapper }
  })

  const thisScreenButtons = useMemo(() => {
    return (screenButtonsWrapper.data ? screenButtonsWrapper.data : []).filter(
      (r) => r.tree_category_screen?.id == parentId && parentId
    )
  }, [screenButtonsWrapper, parentId])

  const { categoriesWithChildCategories } = resolver({
    parentId,
    isHome
  })

  const numColumns = useColumnHelper(isHome)

  const isDesktop = useIsDesktop()
  const { width } = Dimensions.get('window')

  const tabletModifierContainer = undefined
  const viewStyleTablet =
    width < 1024 && isDesktop
      ? {
          width: '100%',
          alignItems: 'center',
          paddingVertical: spacing.m2
        }
      : undefined
  const mobileOverride = isDesktop ? null : { width: '100%' }

  const { actionNavigateTo } = useActionNavigateTo()

  const fullWidthWhenMobile = {
    flex: 1
  }
  const floatWhenDesktop = {
    flexDirection: 'row'
  }

  const sections = _.chain(categoriesWithChildCategories)
    .groupBy((cat) => {
      return _.get(cat, 'subheading', null)
    })
    .map((elements, subheading) => {
      return { elements, subheading }
    })
    .value()

  return (
    <View
      style={{
        justifyContent: 'center',
        flex: 1
      }}
    >
      {!!thisScreenButtons?.length && (
        <View
          style={[
            shared.screenView,
            {
              alignItems: 'center',
              justifyContent: 'center' /* centre horizontally */,
              flexDirection: 'row'
            }
          ]}
        >
          <View
            style={{
              minWidth: 300,
              ...(isDesktop ? floatWhenDesktop : fullWidthWhenMobile)
            }}
          >
            {thisScreenButtons.map((thisScreenButton, index) => (
              <MSFESButton
                style={{
                  minWidth: 300,
                  ...(isDesktop ? { marginRight: spacing.m1 } : null)
                }}
                key={index}
                to={thisScreenButton.navigate_to}
                icon={{ name: thisScreenButton.icon, type: 'font-awesome' }}
                title={thisScreenButton.name}
              />
            ))}
          </View>
        </View>
      )}
      <View
        style={{
          alignItems: 'center',
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'row'
        }}
      >
        {sections.map((section, index) => {
          return (
            <View key={index} style={{ ...mobileOverride }}>
              {section.subheading !== 'null' && (
                <View style={styles.subcategoryHeader}>
                  <Text
                    style={[
                      { textAlign: 'center' },
                      fonts.headerStyle,
                      shared.debugOff
                    ]}
                  >
                    {section.subheading}
                  </Text>
                </View>
              )}
              <FlatList
                style={{
                  ...mobileOverride
                }}
                contentContainerStyle={
                  isDesktop
                    ? {
                        alignItems: 'center',
                        marginVertical: 'auto',
                        ...tabletModifierContainer
                      }
                    : isHome
                    ? undefined
                    : { alignItems: 'center' }
                }
                columnWrapperStyle={
                  numColumns > 1 && {
                    flexWrap: 'wrap'
                  }
                }
                horizontal={false}
                key={numColumns}
                numColumns={numColumns}
                data={section.elements}
                renderItem={({ item, index }) => {
                  if (isHome) {
                    return (
                      <View style={viewStyleTablet}>
                        <ModuleButton
                          key={index}
                          title={item.category_name || item.display_name}
                          image={item.SYSTEM_display_picture}
                          to={treeCategoryLink(item, actionNavigateTo)}
                          isHome={isHome}
                        />
                      </View>
                    )
                  } else {
                    return (
                      <View style={[{ width: tileButtonWidth }]}>
                        <ModuleButton
                          key={index}
                          title={item.category_name || item.display_name}
                          image={item.SYSTEM_display_picture}
                          to={treeCategoryLink(item, actionNavigateTo)}
                          isHome={isHome}
                        />
                      </View>
                    )
                  }
                }}
                keyExtractor={(item) => item.id.toString()}
              />
            </View>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  subcategoryHeader: {
    paddingVertical: spacing.m1
  }
})
