// libraries
import React, { useEffect, useState, useMemo } from 'react'
import { View, Text } from 'react-native'
import _ from 'lodash'

// internal libraries
// styles

//actions

import spacing from '../../styles/spacing'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import { TouchableOpacity } from 'react-native'
import { formatDataRows, getKey, getLabel } from './SelectWithData'
import { onValueToggleWrapped } from './SelectMultipleWithData'
import { MSFESCheckbox } from '../MSFESCheckbox'

export const CheckboxMultiselectWithData = React.forwardRef((props, ref) => {
  const {
    placeholder,
    value,
    onChangeText,
    style,
    dataRows = [],
    editable = true,
    valueField = 'id',
    keyField = 'id',
    labelField = 'name',
    objectIsValue = false,
    keyFieldResolver = false
  } = props

  useEffect(() => {
    if (props.defaultValue && props.data?.entity?.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }
  }, [props.defaultValue])

  const dataFormatted = useMemo(() => {
    return formatDataRows(
      dataRows,
      keyFieldResolver,
      keyField,
      valueField,
      labelField
    )
  }, [dataRows, keyFieldResolver, keyField, valueField, labelField])

  let inputColor

  if (props.dark) {
    inputColor = colors.inputTextDark
  } else {
    inputColor = colors.inputText
  }

  const inputStyle = [
    props.inputStyle,
    {
      color: inputColor,
      fontFamily: fonts.inputTextFace
    }
  ]

  const selectedItems = useMemo(() => {
    const matchingObjects = dataRows.filter((d) => {
      const rowKey = getKey(d, keyFieldResolver, keyField)

      let isMatch = false

      try {
        const valueArray = props.value ? _.castArray(props.value) : []
        isMatch = !!(
          valueArray &&
          valueArray.find(
            (v) => getKey(v, keyFieldResolver, keyField) === rowKey
          )
        )
      } catch (e) {
        console.warn('.value.find is null', e)
      }

      return isMatch
    })

    return matchingObjects
  }, [dataFormatted, props.value])

  return (
    <View style={style}>
      <View>
        {dataFormatted.map((item, index) => {
          const key = getKey(item, keyFieldResolver, keyField)
          const onclick = () =>
            onValueToggleWrapped(
              item,
              dataRows,
              props.defaultValue,
              keyField,
              keyFieldResolver,
              onChangeText
            )
          const active =
            selectedItems.findIndex(
              (s) => getKey(s, keyFieldResolver, keyField) === key
            ) !== -1

          const isOdd = index % 2

          const label = getLabel(item, labelField)

          return (
            <TouchableOpacity
              disabled={!editable}
              onPress={onclick}
              style={{
                flexDirection: 'row',
                backgroundColor: isOdd ? 'white' : 'transparent',
                paddingTop: spacing.m0_5,
                marginLeft: -spacing.m2
              }}
              key={key}
            >
              <Text
                style={[
                  {
                    flex: 1,
                    opacity: active ? 1 : 0.7,
                    marginLeft: spacing.m2,
                    marginTop: spacing.m1
                  },
                  inputStyle
                ]}
              >
                {label}
              </Text>
              <MSFESCheckbox
                radio={false}
                disabled={!editable}
                onPress={onclick}
                checked={active}
                style={{ marginRight: 4 }}
              />
              {/* <Checkbox disabled={!editable} value={value} status={active ? 'checked' : 'unchecked'} color={colors.controlSelected} onPress={onclick} /> */}
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
})

export default CheckboxMultiselectWithData
