import React, { useMemo } from 'react'
import _ from 'lodash'
import { Card } from 'react-native-elements'
import { Text, View } from 'react-native'
import spacing from '../styles/spacing'
import WidgetButton from './WidgetButton'

const WidgetEntityField = React.forwardRef((props, ref) => {
  const widget = useMemo(() => {
    return {
      ...props.data?.field?.field_data?.params?.widgetSource,
      render_type: { key: 'chart' },
      group_function: { key: 'single-entity' }
    }
  }, [props.data?.field?.field_data?.params?.widgetSource])
  // we can never view list if it's an add.
  if (props.data.entity.mode === 'add') {
    const label = _.get(props, 'definition.label', '')
    const title = _.get(props, 'data.field.field_data.title', '')
    return (
      <View style={[{ flex: 1 }, { marginBottom: spacing.m2 }]}>
        <Card>
          <Text>
            Please save the {label} before viewing associated {title}
          </Text>
        </Card>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <WidgetButton
        widget={widget}
        withEntity={{ id: props.data?.entity?.id }}
      />
    </View>
  )
})

export default WidgetEntityField
