import React from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import useBetterLinkTo from '../../useBetterLinkTo'
import { getAddUrl } from '../../store/entity/actions'

import { openExternalLink } from '../../helpers/link'
import { updateMode } from '../../screens/EntityAddComponent'
import {
  getUrlForEntityWithParams,
  getWeekStart
} from '../ListItemButtonOpenCurrentTimesheet'
import { Overlay } from 'react-native-elements'
import ListItemLinkButton from '../ListItemLinkButton'
import { listItemContainerStyle } from '../EntityTypeActions'
import { useNavigateToTenantHandbook } from '../TopNavButtonHousing'

export const useOnButtonObjectPressed = () => {
  const dispatch = useDispatch()
  const {
    navigateToTenantHandbook,
    navigateToTenantFile
  } = useNavigateToTenantHandbook()

  const linkTo = useBetterLinkTo()
  const { current_user } = useSelector((appState) => {
    return {
      current_user: _.get(appState, 'users.user')
    }
  })

  const onButtonObjectPressed = async ({ buttonObject }) => {
    if (typeof buttonObject.navigate_to === 'string') {
      linkTo(buttonObject.navigate_to)
    } else {
      switch (buttonObject.navigate_to?.linkToType) {
        case 'create-entity':
          linkTo(
            getAddUrl(
              { name: buttonObject.navigate_to?.sourceName },
              false,
              '/entity-list/?type=' + buttonObject.navigate_to?.sourceName
            )
          )
          break
        case 'view-entity-list':
          linkTo('/entity-list/?type=' + buttonObject.navigate_to?.sourceName)
          break
        case 'open-current-handbook':
          navigateToTenantHandbook()
          break
        case 'open-tenant-file':
          navigateToTenantFile(buttonObject)
          break
        case 'open-current-timesheet':
          {
            const week_start = getWeekStart()
            const params = {
              assignee: current_user.personnel_record.id,
              personnel: current_user.personnel_record.id,
              week_start
            }

            const response = await getUrlForEntityWithParams(
              { name: buttonObject.navigate_to?.sourceName},
              params,
              dispatch
            )

            const url = response.uri + '?mode=' + updateMode
            linkTo(url)
          }

          break
        case 'url':
          openExternalLink(buttonObject.navigate_to?.url)
          break
        case 'view-report':
          openExternalLink(buttonObject.navigate_to?.url)
          break
      }
    }
  }
  return onButtonObjectPressed
}
export const QuickOverlay = ({ onBackdropPress, overlayStyle, buttons }) => {
  const onButtonObjectPressed = useOnButtonObjectPressed()

  return (
    <Overlay
      visible={true}
      backdropStyle={{ opacity: 0 }}
      onBackdropPress={onBackdropPress}
      overlayStyle={overlayStyle}
    >
      <View style={{ marginTop: -1 }}>
        {buttons?.map((buttonObject, index) => {
          const icon = buttonObject.icon
            ? {
                name: buttonObject.icon,
                type: 'font-awesome',
                size: 16
              }
            : undefined

          return (
            <ListItemLinkButton
              key={index}
              avatarIcon={icon}
              containerStyle={listItemContainerStyle}
              title={buttonObject.name}
              onPress={async () => {
                onButtonObjectPressed({
                  buttonObject
                })
              }}
            />
          )
        })}
      </View>
    </Overlay>
  )
}
