import React, { useEffect, useState } from 'react';
import { TouchableOpacity, Text, View, StyleSheet, useWindowDimensions } from 'react-native'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { Overlay } from 'react-native-elements';
import MSFESIcon from './MSFESIcon';
import MSFESButton from './MSFESButton';

const BarcodeScannerWrapper = (props) => {

  const { onBarcodeScanned } = props
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [ shouldScan, setShouldScan ] = useState(false);
  const [ dataScanned, setDataScanned ] = useState(null);

  // useEffect(() => {
  //   (async () => {
  //     const { status } = await BarCodeScanner.requestPermissionsAsync();
  //     setHasPermission(status === 'granted');
  //   })();
  // }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    setDataScanned(data);
};

  const requestPermissionAndBeginScan = async () => {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === 'granted');

    if(status === 'granted'){
      setShouldScan(true)
    }
  }

  const scanBarcode = () => {

  }

  const onCancelScan = () => {
    setScanned(false)
    setDataScanned(null);
    setShouldScan(false)
  }

  const dimensions = useWindowDimensions();
  return<View>
          {shouldScan &&  <Overlay isVisible={shouldScan} overlayStyle={{padding: 0}} onBackdropPress={onCancelScan}><View style={{width: dimensions.width * 0.85, height: dimensions.height * 0.85}}><BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
      <View style={{flexDirection:"row", justifyContent:"flex-end"}}>
      {!!dataScanned && <MSFESButton small variant="outline" title={`Use '${dataScanned}'`} containerStyle={{padding: 0, flex: 1}} onPress={() => {
        onBarcodeScanned(dataScanned);
        onCancelScan();
      }} />}
      {<MSFESButton small variant="outline" title={`✕`} containerStyle={{padding: 0}} onPress={() => {
        onCancelScan();
      }} />}
      </View>

      </View></Overlay>}
          <TouchableOpacity onPress={requestPermissionAndBeginScan}
          style={{flex: 1, marginBottom: 20, justifyContent:"center", alignItems:"center"}}><MSFESIcon name={'barcode'}
          tappable
            size={18}
            type="font-awesome"/>
            </TouchableOpacity>
    </View>
}

export default BarcodeScannerWrapper