import React from 'react'
import { View, TouchableOpacity, Text, Platform } from 'react-native'
import MSFESIcon from '../components/MSFESIcon'
import colors from '../styles/colors'
import shared from '../styles/shared'
import {
  activeColor,
  activeLabelColor,
  baseDrawerLabelStyle,
  drawerLabelStyle,
  inactiveLabelColor,
  selectedDrawerLabelStyle
} from './DesktopDrawerNavigator'

const baseButtonStyle = {
  margin: 0,
  padding: 0,
  position: 'relative',
  borderRadius: 0,
  alignItems: 'center',
  flexDirection: 'row'
}
const baseAccentStyle = {
  height: 48,
  width: 2,
  marginLeft: 20,
  marginRight: 48
}

export const CustomParentDrawerItem = ({
  item,
  isExpanded,
  onExpand,
  depth,
  focused
}) => {
  return (
    <View>
      <View
        style={[
          shared.debugOff,
          baseButtonStyle,
          {
            minWidth: 80,
            backgroundColor: focused ? activeColor : 'transparent',
            marginLeft: 6 * (depth + 1)
          }
        ]}
      >
        <TouchableOpacity
          onPress={item.onPress}
          style={[
            {
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center'
            },
            shared.debugOff
          ]}
        >
          <View
            style={{
              ...baseAccentStyle,
              width: 42,
              marginLeft: Platform.OS === 'web' ? 0 : 12,
              marginRight: 20,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {!!item.icon && item.icon({ focused })}
          </View>
          <Text
            style={[
              baseDrawerLabelStyle,
              focused
                ? selectedDrawerLabelStyle
                : { color: inactiveLabelColor },
              shared.debugOff
            ]}
          >
            {item.label}
          </Text>
        </TouchableOpacity>
        {!!onExpand && (
          <MSFESIcon
            name={isExpanded ? 'chevron-up' : 'chevron-down'}
            size={12}
            style={{ paddingVertical: 2, marginRight: 12, marginLeft: 12 }}
            type="font-awesome"
            color={focused ? activeLabelColor : inactiveLabelColor}
            onPress={onExpand}
          />
        )}
      </View>
    </View>
  )
}
export const CustomDrawerItem = ({ item, secondaryRoutes }) => {
  const childFocused = item.focusedWhen && item.focusedWhen(secondaryRoutes)
  return (
    <View>
      <TouchableOpacity
        onPress={item.onPress}
        style={[
          shared.debugOff,
          baseButtonStyle,
          {
            backgroundColor: childFocused ? activeColor : 'transparent'
          }
        ]}
      >
        <View
          style={{
            ...baseAccentStyle,
            backgroundColor: childFocused ? colors.redDark : '#e0e0e0'
          }}
        ></View>
        <Text
          style={[
            baseDrawerLabelStyle,
            childFocused ? selectedDrawerLabelStyle : drawerLabelStyle
          ]}
        >
          {item.label}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
