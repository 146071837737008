import api from '../../data/api'

const promiseRepository = {}
let listPromise = null
let listLitePromise = null
const clearFieldErrors = () => {
  return {
    type: 'entity-type/clear-field-errors'
  }
}

const getImportTemplate = (entityType) => {
  return () => {
    let route = '/entity'
    route = `${route}/${entityType.name}/template/csv`
    const method = 'get'

    return api[method](route).then(async (response) => {
      return response
    })
  }
}

const getDataCSV = (entityType) => {
  return () => {
    let route = '/entity'
    route = `${route}/${entityType.name}/csv`
    const method = 'get'

    return api[method](route).then(async (response) => {
      return response
    })
  }
}

const getDataCSVLongRunning = (entityType) => {
  return () => {
    let route = '/entity'
    route = `${route}/${entityType.name}/queue/csv`
    const method = 'get'

    return api[method](route).then(async (response) => {
      return response
    })
  }
}

const getPublicUrl = (entityType, tenantId) => {
  return () => {
    let route = '/entity-type'
    route = `${route}/${entityType.name}/public-url/${tenantId ? tenantId : ''}`
    const method = 'get'

    return api[method](route, entityType).then(async (response) => {
      return response
    })
  }
}

const exportEntityType = (entityType) => {
  return (dispatch) => {
    let route = '/entity-type'
    route = `${route}/${entityType.id}`
    const method = 'get'

    return api[method](route).then(async (response) => {
      await dispatch({
        type: 'entity-type/export-success',
        response
      })

      return response
    })
  }
}

export const dumpStoredEntityTypes = () => {
  return (dispatch) => {
    dispatch({
      type: 'entity-type/dump-stored'
    })
  }
}

export const scrubExcessEntityTypes = () => {
  /* 
  June 2024 we changed so all users do not load all entity types, they load on demand.
  Old users may have excessive amounts of entity types causing crashes.
  If the user has an unrealistic amount of entity types (>300) we will clear them out to a reasonable amount (250) and log a sentry event. 
  */
  return (dispatch) => {
    dispatch({
      type: 'entity-type/scrub-excess'
    })
  }
}

export const listLiteEntityTypes = () => {
  return (dispatch) => {
    if (listLitePromise) {
      return listLitePromise
    }
    const route = '/entity-type/lite'

    dispatch({
      type: 'entity-type/list-lite-started'
    })

    let p = api
      .get(route)
      .then(async (response) => {
        dispatch({
          type: 'entity-type/list-lite-success',
          response
        })
        listLitePromise = null
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/list-lite-error',
          error
        })

        throw error
      })
    listLitePromise = p
    return p
  }
}
export const listEntityTypes = () => {
  console.warn('listEntityTypes is deprecated, use listLiteEntityTypes.')
  return (dispatch) => {
    if (listPromise) {
      return listPromise
    }
    const route = '/entity-type'

    dispatch({
      type: 'entity-type/list-started'
    })

    let p = api
      .get(route)
      .then(async (response) => {
        dispatch({
          type: 'entity-type/list-success',
          response
        })
        listPromise = null
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/list-error',
          error
        })

        throw error
      })
    listPromise = p
    return p
  }
}

const updateEntityTypeBatch = (entityTypes) => {
  return (dispatch) => {
    dispatch({
      type: 'entity-type/batch-update-started'
    })

    const method = 'put'

    let route = '/entity-type/sort-order'

    const payload = entityTypes.map((e) => ({
      id: e.id,
      sort_order: e.sort_order
    }))
    return api[method](route, payload)
      .then(async (response) => {
        await dispatch({
          type: 'entity-type/batch-update-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/batch-update-error',
          error
        })

        throw error
      })
  }
}
const upsertEntityType = (entityTypeWrapper, skipTypeChecks = false) => {
  return (dispatch) => {
    dispatch({
      type: 'entity-type/upsert-started',
      data: { entityTypeWrapper }
    })

    const method = entityTypeWrapper.mode === 'add' ? 'post' : 'put'

    let route = '/entity-type'

    const entityType = entityTypeWrapper.data
    if (entityTypeWrapper.mode === 'edit') {
      route = `${route}/${entityType.id}`
    }

    const entityTypeWithArgs = {
      ...entityType,
      importArgs: { skipTypeChecks }
    }
    return api[method](route, entityTypeWithArgs) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'entity-type/upsert-success',
          response
        })

        dispatch(listEntityTypes())
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/upsert-error',
          error
        })

        throw error
      })
  }
}

const deleteEntityType = (id) => {
  return (dispatch) => {
    const route = `/entity-type/${id}`

    dispatch({
      type: 'entity-type/delete-started'
    })

    return api
      .delete(route)
      .then(async (response) => {
        dispatch({
          type: 'entity-type/delete-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/delete-error',
          error
        })

        throw error
      })
  }
}

export const getEntityType = (id, public_form = false, additionalArgs = {}) => {
  return (dispatch) => {
    let route
    if (additionalArgs.read) {
      route = `/entity-type/read/${id}?`
    } else {
      route = (public_form ? '/public-forms' : '') + `/entity-type/${id}?`
    }

    let extraParams = []
    Object.keys(additionalArgs).forEach((key) => {
      extraParams.push(key + '=' + encodeURIComponent(additionalArgs[key]))
    })

    route += extraParams.join('&')

    if (promiseRepository[route]) {
      return promiseRepository[route]
    }

    dispatch({
      type: 'entity-type/get-started'
    })

    let p = api
      .get(route)
      .then(async (response) => {
        delete promiseRepository[route]
        dispatch({
          type: 'entity-type/get-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity-type/get-error',
          error
        })

        throw error
      })

    promiseRepository[route] = p
    return p
  }
}

export default {
  getPublicUrl,
  getDataCSV,
  getDataCSVLongRunning,
  listEntityTypes,
  getEntityType,
  updateEntityTypeBatch,
  deleteEntityType,
  upsertEntityType,
  exportEntityType,
  getImportTemplate,
  clearFieldErrors
}
