import { StyleSheet } from 'react-native'
import spacing from '../styles/spacing'
import colors from '../styles/colors'

export const styles = StyleSheet.create({
  touchableChevron: {
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.lighterGray,
    borderRadius: spacing.borderRadius,
    marginHorizontal: spacing.m0_5
  }
})
