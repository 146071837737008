// ./navigation/StackNavigator.js

import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
// screens
import HomeScreen from '../screens/HomeScreen'

import EntityListScreen from '../screens/EntityListScreen'
import EntityAddScreen from '../screens/EntityAddScreen'

import ConversationViewScreen from '../screens/ConversationViewScreen'
import ConversationListScreen from '../screens/ConversationListScreen'
import ConversationCreateScreen from '../screens/ConversationCreateScreen'
import DocumentListScreen from '../screens/DocumentListScreen'
import DocumentViewScreen from '../screens/DocumentViewScreen'
import CategoryViewScreen from '../screens/CategoryViewScreen'
import NotificationsScreen from '../screens/NotificationsScreen'
import CalendarViewScreen from '../screens/CalendarViewScreen'
import DashboardScreen from '../screens/DashboardScreen'
import CategoryTreeScreen from '../screens/CategoryTreeScreen'

const Stack = createStackNavigator()

const ModulesStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={{ title: 'Dashboard' }}
      />
      <Stack.Screen
        name="CategoryTree"
        component={CategoryTreeScreen}
        options={{ title: 'Categories' }}
      />
      <Stack.Screen
        name="AdminEntityList"
        component={EntityListScreen}
        options={{ title: 'List' }}
      />
      <Stack.Screen
        name="AdminTenantView"
        component={EntityAddScreen}
        initialParams={{ mode: 'view' }}
      />
      <Stack.Screen name="AdminTenantList" component={EntityListScreen} />
      <Stack.Screen
        name="EntityList"
        component={EntityListScreen}
        options={{ title: 'List' }}
      />
      <Stack.Screen
        name="EntityListDetail"
        component={EntityListScreen}
        options={{ title: 'List Detail' }}
      />
      <Stack.Screen
        name="EntityAdd"
        component={EntityAddScreen}
        options={{ title: 'Add New' }}
      />
      <Stack.Screen
        name="EntityEdit"
        component={EntityAddScreen}
        options={{ title: 'Edit' }}
      />
      <Stack.Screen
        name="EntityView"
        component={EntityAddScreen}
        options={{ title: 'View' }}
      />
      <Stack.Screen
        name="CategoryView"
        component={CategoryViewScreen}
        options={{ title: 'View' }}
      />
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{ title: 'View' }}
      />
      <Stack.Screen
        name="ConversationCreate"
        component={ConversationCreateScreen}
        options={{ title: 'Create Conversation' }}
      />
      <Stack.Screen
        name="ConversationView"
        component={ConversationViewScreen}
        options={{ title: 'View Conversation' }}
      />
      <Stack.Screen
        name="ConversationList"
        component={ConversationListScreen}
        options={{ title: 'List Conversations' }}
      />
      <Stack.Screen
        name="CalendarView"
        component={CalendarViewScreen}
        options={{ title: 'Calendar' }}
      />
      <Stack.Screen
        name="DocumentList"
        component={DocumentListScreen}
        options={{ title: 'List Documents' }}
      />
      <Stack.Screen
        name="DocumentView"
        component={DocumentViewScreen}
        options={{ title: 'View Document' }}
      />
    </Stack.Navigator>
  )
}

export { ModulesStackNavigator }
