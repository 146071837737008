import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { Overlay } from 'react-native-elements'
import { showMessage } from 'react-native-flash-message'
import EntityAddComponent from '../screens/EntityAddComponent'
import { addMode } from '../screens/EntityAddScreen'
import spacing from '../styles/spacing'

const MSFESNestedAddEntity = ({
  navigation,
  entityType,
  prefillFieldsInEntity = null,
  beginsWithEntity = null,
  id = null,
  mode = addMode,
  fieldName,
  tenant,
  parentId,
  onDismiss = () => {},
  onChangedCallback = () => {},
  shouldDismissOnSaved = true
}) => {
  let navigationHook
  try {
    navigationHook = useNavigation()
  } catch (e) {}

  navigation = navigation ?? navigationHook
  const [localMode, setLocalMode] = useState(mode)

  const onEditToggled = (newMode) => {
    setLocalMode(newMode)
  }
  const linkToParentProperty = 'field.' + fieldName

  const [currentPage, updateCurrentPage] = useState(1)

  const savedCallback = useCallback(
    (response) => {
      showMessage({
        message: entityType.name + ' saved.',
        type: 'success'
      })
      onChangedCallback && onChangedCallback(response)

      if (shouldDismissOnSaved) {
        onDismiss()
      }
    },
    [showMessage, onChangedCallback, onDismiss]
  )

  const routeParams = useMemo(() => {
    return {
      params: {
        ...prefillFieldsInEntity,
        id,
        type: entityType.name,
        mode: localMode,
        'field.tenant_id': tenant,
        [linkToParentProperty]: parentId,
        page: 1
      }
    }
  }, [prefillFieldsInEntity, entityType, linkToParentProperty, localMode])
  const { width } = Dimensions.get('window')

  return (
    <Overlay
      isVisible={true}
      onBackdropPress={onDismiss}
      overlayStyle={{
        margin: 0,
        padding: 0,
        minWidth: 320,
        width: width < 500 ? '94%' : '80%',
        maxHeight: '80%',
        flex: 1,
        marginTop: 40
      }}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flex: 1,
            padding: spacing.m1,
            minHeight: Platform.OS === 'ios' ? 330 : 140
          }}
        >
          <View style={[{ flex: 1 }]}>
            {entityType && (
              <EntityAddComponent
                currentPage={currentPage}
                onUpdatePage={updateCurrentPage}
                navigation={navigation}
                isInSplitView={true}
                onCloseSplit={onDismiss}
                onEditToggled={onEditToggled}
                beginsWithEntity={beginsWithEntity}
                route={routeParams}
                savedCallback={savedCallback}
              />
            )}
          </View>
        </View>
      </View>
    </Overlay>
  )
}

export default MSFESNestedAddEntity
