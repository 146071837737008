// libraries
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Platform } from 'react-native'
import spacing from '../../styles/spacing'
import { Button, Overlay } from 'react-native-elements'
import _, { isArray } from 'lodash'

// internal libraries

import MediaHelper from '../../libraries/mediaHelper'
// styles

//actions
import fileActions from '../../store/file/actions'
import { MediaLibraryOverlay } from '../MediaLibraryOverlay'
import LoadingOverlay from '../LoadingOverlay'
import OverlayHeader from '../OverlayHeader'
import MSFESButton from '../MSFESButton'
import ListItemAsFieldProps from '../ListItemAsFieldProps'
import YesNoCancelDialog from '../YesNoCancelDialog'
import { Portal } from 'react-native-paper'
import AsyncStorage from '@react-native-community/async-storage'
import { getLoadableValueFromPropValue } from '../../helpers/images'

import PublicFormContext from '../../contexts/PublicFormContext'
import { ListItemFile } from '../ListItemFile'

export const SHOULD_TRY_UPLOAD_INSTANTLY = true

const _SingleFile = (props, ref) => {
  const {
    value,
    onChangeText,
    style,
    savesAgainstEntityId = undefined,
    editable = true,
    isRestricted = true,
    onBusyStateChanged = () => {}
  } = props

  const publicFormContext = useContext(PublicFormContext)

  const [mediaLoading, setMediaLoading] = useState(false)
  const [isSelectingMediaSource, setIsSelectingMediaSource] = useState(false)
  const [
    isSelectingFromMediaLibrary,
    setIsSelectingFromMediaLibrary
  ] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (props.defaultValue && props.data.entity.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }
  }, [props.defaultValue, props.value])

  const { appStateFiles } = useSelector((appState) => {
    let idsInUse = []

    try {
      idsInUse = props.value
        ? props.value.map((el) => el.id ?? el.offline_id)
        : []
    } catch (e) {
      idsInUse = []
    }
    let appStateFiles = _.get(appState.files, 'queuedUploads', []).filter(
      (f) => f.data.fileOfflineId && idsInUse.includes(f.data.fileOfflineId)
    )

    return { appStateFiles }
  })

  const [localFiles, setLocalFiles] = useState([])

  useEffect(() => {
    const getFileContents = async () => {
      if (appStateFiles && appStateFiles.length !== localFiles.length) {
        const _localFiles = await appStateFiles.map(async (l) => {
          return {
            ...l.data.payload,
            uri:
              Platform.OS === 'web'
                ? localStorage.getItem(l.data.payload.md5)
                : await AsyncStorage.getItem(l.data.payload.md5)
          }
        })
        setLocalFiles(_localFiles)
      }
    }

    getFileContents()
  }, [appStateFiles])

  const [removePending, setRemovePending] = useState(null)

  const allowMultipleUploads = _.get(
    props.data,
    'field.field_data.params.allowMultipleUploads',
    false
  )

  const canSelectFromLibrary = _.get(
    props.data,
    'field.field_data.params.canSelectFromLibrary',
    true
  )

  const canSelectFromCamera = Platform.OS !== 'web'
  const fieldId = _.get(props.data, 'field.id', null)

  const fileType = _.get(props.data, 'field.field_data.params.fileType', null)
  const fileTypeUsed = fileType ? fileType : MediaHelper.ImageType
  const processMediaAdded = async ({ payload, filename, offline_id }) => {
    setMediaLoading(true)
    onBusyStateChanged(true)

    // do we have any context (e.g. offlineId of entity)
    const entity = props.data.entity
    const saveAgainstId =
      savesAgainstEntityId !== undefined
        ? savesAgainstEntityId
        : entity.id ?? entity.offline_id

    // store file locally, with a reference to the offline ID of this entity

    // strip out the b64. On a mobile device this is a killer.
    // strip the b64 if we are storing in local state.

    // eslint-disable-next-line no-unused-vars
    const { base64, ...payloadExB64 } = payload

    if (SHOULD_TRY_UPLOAD_INSTANTLY) {
      const result = await dispatch(
        fileActions.uploadFile(
          payloadExB64,
          filename,
          fieldId,
          isRestricted,
          saveAgainstId,
          offline_id,
          publicFormContext.tenantId
            ? {
                tenantId: publicFormContext.tenantId,
                tenantHmac: publicFormContext.tenantHmac
              }
            : undefined
        )
      )
      payload.uri = result.data.url
    } else {
      dispatch(
        fileActions.storeFile(
          payloadExB64,
          filename,
          fieldId,
          isRestricted,
          saveAgainstId,
          offline_id
        )
      )

      // attempt to upload file (file upload can stay in a queue basically forever)
      // will cascade down and remove when possible.
      dispatch(fileActions.tryUploadQueued())
    }

    // when connection status changes, attempt to upload file

    // (file is stored with an offline ID against it)

    // when a file is stored with offline ID, server checks if offline ID matches a real entity. If so, it will update it with real ID.

    // when an entity is saved, server will check if there's any offline ID files and match them up. If so it will update them.
    const currentValue = props.value ?? []
    const currentPaths = props.pathValue ?? []
    onChangeText(
      [...currentValue, { offline_id: offline_id }],
      [...currentPaths, payload.uri]
    )
    setMediaLoading(false)
    onBusyStateChanged(false)
    setIsSelectingMediaSource(false)

    // dispatch(fileActions.uploadFile(payload, filename, fieldId, isRestricted)).then((file) => {
    //   const currentValue = props.value ?? []
    //   const currentPaths = props.pathValue ?? []
    //   onChangeText([...currentValue, file.data.id], [...currentPaths, file.data.url])
    //   setMediaLoading(false)
    //   onBusyStateChanged(false);
    //   setIsSelectingMediaSource(false)
    // })
  }
  const addMediaCameraPressed = () => {
    MediaHelper.getMedia(fileTypeUsed, false, true).then(processMediaAdded)
  }
  const addMediaPressed = () => {
    switch (fileTypeUsed) {
      case MediaHelper.MediaType:
      case MediaHelper.ImageType:
      case MediaHelper.DocumentType:
      default:
        // m1 issue: https://github.com/react-native-image-picker/react-native-image-picker/issues/1541
        MediaHelper.getMedia(fileTypeUsed, false).then(processMediaAdded)
        break
    }
  }

  const valueAsArray = isArray(value)
    ? value
    : typeof value === 'undefined'
    ? []
    : [value]

  const appendFileId = (id, url) => {
    const currentValue = props.value ?? []
    const currentPaths = props.pathValue ?? []
    onChangeText([...currentValue, id], [...currentPaths, url])
  }
  const mediaItemSelected = (item) => {
    appendFileId(item.id, item.url)

    // onChangeText(item.id)
    setIsSelectingFromMediaLibrary(false)
    setIsSelectingMediaSource(false)
  }

  const onFileRemoved = (file) => {
    setRemovePending(file)
  }

  const yesRemoveFile = () => {
    let currentValue = props.value ?? []
    let currentPaths = props.pathValue ?? []

    // currently attached
    const index = isArray(currentValue)
      ? currentValue.findIndex((v) => v === removePending)
      : 0

    currentValue.splice(index, 1)
    currentPaths.splice(index, 1)

    // currently queued to attach

    const indexQueued = isArray(localFiles)
      ? localFiles.findIndex(
          (l) => l.data.filename == removePending.data.filename
        )
      : null

    if (indexQueued !== null) {
      dispatch(fileActions.removeQueuedFile(removePending))
    }

    setRemovePending(null)
    onChangeText(currentValue, currentPaths)
  }

  const noRemoveFile = () => {
    setRemovePending(false)
  }

  const updateAddButtonLabel = value
    ? `Add ${fileTypeUsed}`
    : `Add ${fileTypeUsed}`

  const canAddFiles = allowMultipleUploads || valueAsArray.length < 1

  return (
    <View style={style}>
      {!localFiles.length &&
        valueAsArray.map((value, index) => {
          const loadValue = getLoadableValueFromPropValue(value)

          return (
            <ListItemFile
              containerStyle={ListItemAsFieldProps.containerStyle}
              key={index}
              file={loadValue}
              showChevron={!editable}
              removeAction={editable ? () => onFileRemoved(value) : undefined}
            />
          )
        })}

      <View>
        {localFiles.map((l, index) => {
          return (
            // <Image source={{uri: l.data.payload.uri}} height={200} width={200} style={{width: 200, height: 200}} />,
            <ListItemFile
              title={l.name}
              avatarImageUri={l.uri}
              key={index}
              containerStyle={ListItemAsFieldProps.containerStyle}
              showChevron={false}
              removeAction={editable ? () => onFileRemoved(l) : undefined}
            />
          )
        })}
      </View>
      <View style={{ flexDirection: 'row' }}>
        {editable && canAddFiles && (
          <View
            style={{
              flexDirection: 'row'
            }}
          >
            <Button
              title={updateAddButtonLabel}
              type="clear"
              loading={mediaLoading}
              buttonStyle={{ justifyContent: 'flex-start' }}
              containerStyle={{
                paddingTop: 0,
                paddingBottom: 0,
                marginLeft: -6,
                marginBottom: 6
              }}
              onPress={() =>
                canSelectFromLibrary || canSelectFromCamera
                  ? setIsSelectingMediaSource(true)
                  : addMediaPressed()
              }
            />
          </View>
        )}
      </View>

      {isSelectingMediaSource && (
        <Overlay
          isVisible={true}
          onBackdropPress={() => setIsSelectingMediaSource(false)}
          overlayStyle={{ position: 'relative', padding: 0 }}
        >
          <OverlayHeader>Select Source</OverlayHeader>
          <View style={{ padding: spacing.m1 }}>
            {fileTypeUsed === MediaHelper.ImageType && Platform.OS !== 'web' && (
              <MSFESButton
                title={'Camera'}
                style={{
                  paddingTop: spacing.m1
                }}
                onPress={addMediaCameraPressed}
              />
            )}
            <MSFESButton
              title={'My Device'}
              style={{
                paddingTop: spacing.m1
              }}
              onPress={addMediaPressed}
            />
            <MSFESButton
              title={'From Media Library'}
              style={{
                paddingTop: spacing.m1
              }}
              onPress={() => setIsSelectingFromMediaLibrary(true)}
            />
          </View>
          {mediaLoading && <LoadingOverlay />}
        </Overlay>
      )}

      {removePending && (
        <Portal>
          <YesNoCancelDialog
            title="Detach file?"
            body="This action will detach the file from this entry. Continue?"
            yesAction={yesRemoveFile}
            noAction={noRemoveFile}
            cancelAction={noRemoveFile}
          />
        </Portal>
      )}

      {isSelectingFromMediaLibrary && (
        <MediaLibraryOverlay
          onMediaItemSelected={mediaItemSelected}
          onClosePressed={() => setIsSelectingFromMediaLibrary(false)}
        />
      )}
    </View>
  )
}
export const SingleFile = React.forwardRef(_SingleFile)

export default SingleFile
