// libraries
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  View,
  Text,
  StyleSheet,
  Keyboard,
  TextInput,
  ScrollView,
  Platform
} from 'react-native'
import _ from 'lodash'

import { Icon } from 'react-native-elements'

import {
  actions,
  RichEditor,
  RichToolbar,
  defaultActions
} from 'react-native-pell-rich-editor'
import ColorPickerOverlay from './ColorPicker'
import InsertHTMLOverlay from './InsertHTMLOverlay'
import WebView from 'react-native-webview'
import { ListItemLinkButton, openEmbeddedHtml } from './ListItemLinkButton'
import ListItemAsFieldProps from './ListItemAsFieldProps'

// internal libraries

// styles

//actions

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#efefef'
  },
  nav: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 5
  },
  rich: {
    flex: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#e3e3e3'
  },
  topVi: {
    backgroundColor: '#fafafa'
  },
  richBar: {
    borderColor: '#efefef',
    borderTopWidth: StyleSheet.hairlineWidth
  },
  richBarDark: {
    backgroundColor: '#191d20',
    borderColor: '#696969'
  },
  scroll: {
    backgroundColor: '#ffffff'
  },
  scrollDark: {
    backgroundColor: '#2e3847'
  },
  darkBack: {
    backgroundColor: '#191d20'
  },
  item: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#e8e8e8',
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 15
  },

  input: {
    flex: 1
  },

  tib: {
    textAlign: 'center',
    color: '#515156'
  },

  flatStyle: {
    paddingHorizontal: 12
  }
})

const _RichTextContainer = (props, ref) => {
  const richText = useRef()

  const {
    defaultValue: initHTML,
    onChangeText,
    style = {},
    disabled = false
  } = props

  const [defaultValue, setDefaultValue] = useState('')
  useEffect(() => {
    setDefaultValue(props.defaultValue ?? '')
  }, [''])

  const linkToTarget = !_.get(
    props,
    'data.field.field_data.params.displayInline',
    false
  )

  const [state, setState] = useState({
    richHTML: initHTML ?? '',
    forecolorPickerActive: false,
    foreColor: '#000000',
    highlightColor: 'yellow',
    disabled: false,
    displayInsertHTML: false
  })

  const noop = useCallback(() => {})
  const handlePaste = useCallback(() => {})
  const handleKeyUp = useCallback(() => {})
  const handleKeyDown = useCallback(() => {})
  const onKeyHide = () => {}
  const onKeyShow = () => {
    TextInput.State.currentlyFocusedInput() &&
      setState((state) => ({ ...state, emojiVisible: false }))
  }
  const handleCursorPosition = () => {
    // Positioning scroll bar
  }

  const insertHTML = () => {
    setState((state) => ({ ...state, displayInsertHTML: true }))
  }
  const addHTML = (html) => {
    richText.current?.insertHTML(html)
  }

  const handleFocus = () => {
    setState((state) => ({ ...state, editorFocus: true }))
  }

  const handleChange = useCallback((html) => {
    setState((state) => ({ ...state, a: Math.random, richHTML: html }))
    onChangeText(html)
  })

  const foreColor = () => {
    setState((state) => ({ ...state, forecolorPickerActive: true }))
  }

  const foreColorChanged = (color) => {
    richText.current?.setForeColor(color)
    setState((state) => ({ ...state, foreColor: color }))
  }

  const highlightColorChanged = (color) => {
    richText.current?.setHiliteColor(color)
    setState((state) => ({ ...state, highlightColor: color }))
  }

  const hiliteColor = () => {
    setState((state) => ({ ...state, highlightPickerActive: true }))
  }

  const handleBlur = () => {
    setState((state) => ({ ...state, editorFocus: false }))
    onChangeText(state.richHTML)
  }

  const handleMessage = () => {}

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', onKeyShow)
    Keyboard.addListener('keyboardDidHide', onKeyHide)
    return () => {
      Keyboard.removeListener('keyboardDidShow', onKeyShow)
      Keyboard.removeListener('keyboardDidHide', onKeyHide)
    }
  }, [''])

  const stylesheet = `
  <style>
  html, body {
    font-family: Arial;
  }
  </style>
  `

  const [knownHeight, setKnownHeight] = useState(200)

  const [userDraggedHeight, setUserDraggedHeight] = useState(null)
  const definedMaxHeight = parseInt(
    props.data?.field?.field_data?.params?.maxHeight ?? 400
  )
  const decidedHeight =
    userDraggedHeight ??
    (knownHeight < definedMaxHeight ? knownHeight : definedMaxHeight)

  const addScrollHandle = useMemo(() => {
    return Platform.OS === 'web' ? { resize: 'vertical' } : undefined
  })
  return (
    <View style={style}>
      {!props.editable && (
        <View>
          {linkToTarget && (
            <>
              {props.defaultValue && (
                <ListItemLinkButton
                  onPress={() => {
                    openEmbeddedHtml(stylesheet + props.defaultValue)
                  }}
                  title={'View Content'}
                  containerStyle={ListItemAsFieldProps.containerStyle}
                  showChevron={false}
                />
              )}
              {!props.defaultValue && (
                <ListItemLinkButton
                  title={'(No content added)'}
                  containerStyle={ListItemAsFieldProps.containerStyle}
                  showChevron={false}
                />
              )}
            </>
          )}

          {/* OG webview style. Replaced with popout. */}
          {!linkToTarget && (
            <WebView
              originWhitelist={['*']}
              style={{
                opacity: 0.99 /* part of crash fix https://github.com/facebook/react-native/issues/33083 */,
                flex: 1,
                minHeight: 400
              }}
              source={{ html: stylesheet + (props.defaultValue ?? '') }}
            />
          )}
        </View>
      )}
      {props.editable && (
        <View>
          {state.displayInsertHTML && (
            <InsertHTMLOverlay
              onClosePressed={() =>
                setState((state) => ({ ...state, displayInsertHTML: false }))
              }
              onChange={addHTML}
            />
          )}
          {state.forecolorPickerActive && (
            <ColorPickerOverlay
              onClosePressed={() =>
                setState((state) => ({
                  ...state,
                  forecolorPickerActive: false
                }))
              }
              color={state.foreColor}
              onColorChanged={foreColorChanged}
            />
          )}
          {state.highlightPickerActive && (
            <ColorPickerOverlay
              onClosePressed={() =>
                setState((state) => ({
                  ...state,
                  highlightPickerActive: false
                }))
              }
              color={state.highlightColor}
              onColorChanged={highlightColorChanged}
            />
          )}
          {richText.current && (
            <RichToolbar
              style={[styles.richBar]}
              flatContainerStyle={styles.flatStyle}
              editor={richText}
              disabled={disabled}
              insertHTML={insertHTML}
              actions={[
                ...defaultActions,
                'insertHTML',
                actions.foreColor,
                actions.hiliteColor,
                actions.heading1,
                actions.heading4
              ]}
              iconMap={{
                ['insertHTML']: ({ tintColor }) => {
                  return (
                    <Icon
                      type="font-awesome"
                      color={tintColor}
                      name="code"
                      size={18}
                      containerStyle={{}}
                    />
                  )
                },
                [actions.foreColor]: ({ tintColor }) => {
                  return (
                    <Icon
                      type="font-awesome"
                      color={tintColor}
                      name="paint-brush"
                      size={18}
                      containerStyle={{}}
                    />
                  )
                },
                [actions.hiliteColor]: ({ tintColor }) => {
                  return (
                    <Icon
                      type="font-awesome"
                      color={tintColor}
                      name="tint"
                      size={18}
                      containerStyle={{}}
                    />
                  )
                },
                [actions.heading1]: ({ tintColor }) => (
                  <Text style={[styles.tib, { color: tintColor }]}>H1</Text>
                ),
                [actions.heading4]: ({ tintColor }) => (
                  <Text style={[styles.tib, { color: tintColor }]}>H3</Text>
                )
              }}
              selectedIconTint={'#2095F2'}
              disabledIconTint={'#bfbfbf'}
              foreColor={foreColor}
              hiliteColor={hiliteColor}
              onPressAddImage={noop}
              onInsertLink={noop}
            />
          )}
          <ScrollView
            scrollEnabled={
              decidedHeight >= definedMaxHeight && !userDraggedHeight
            }
            testID="scrollview-wrapper"
            style={{
              height: decidedHeight,
              ...addScrollHandle
            }}
            contentContainerStyle={
              decidedHeight < definedMaxHeight || userDraggedHeight
                ? { flex: 1 }
                : { height: knownHeight }
            }
            onLayout={(event) => {
              if (event.nativeEvent.layout.height !== decidedHeight) {
                setUserDraggedHeight(event.nativeEvent.layout.height)
              }
            }}
          >
            <RichEditor
              // initialFocus={true}
              key={props.data?.field?.field_data?.property}
              disabled={state.disabled}
              editorStyle={{
                backgroundColor: '#white',
                color: '#2e3847',
                caretColor: 'red', // initial valid// initial valid
                placeholderColor: 'gray',
                // cssText: '#editor {background-color: #f3f3f3}', // initial valid
                contentCSSText: 'font-size: 16px; min-height: 200px;' // initial valid
              }} // default light style
              ref={richText}
              style={styles.rich}
              useContainer={true}
              // containerStyle={{ borderRadius: 24 }}
              placeholder={'please input content'}
              initialContentHTML={defaultValue}
              onHeightChange={(height) => {
                setKnownHeight(height)
              }}
              editorInitializedCallback={() => {
                setState((state) => ({ ...state, disabled: true }))
                setTimeout(() => {
                  setState((state) => ({ ...state, disabled: false }))
                }, 50)
              }}
              onChange={handleChange}
              onPaste={handlePaste}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onMessage={handleMessage}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onCursorPosition={handleCursorPosition}
              pasteAsPlainText={true}
            />
          </ScrollView>
        </View>
      )}
    </View>
  )
}
const RichTextContainer = React.forwardRef(_RichTextContainer)

export default RichTextContainer
