import React, { useEffect, useState } from 'react'
import { Overlay } from 'react-native-elements'
import { Text, View } from 'react-native'
import { Card, Divider } from 'react-native-paper'
import MSFESInput from './MSFESInput'
import _ from 'lodash'
import MSFESButton from './MSFESButton'
import spacing from '../styles/spacing'
import { ScrollView } from 'react-native'
import { Dimensions } from 'react-native'
import OverlayHeader from './OverlayHeader'
import YesNoCancelDialog from './YesNoCancelDialog'

import { viewMode } from '../screens/EntityAddComponent'
import QueryEditorField from './fields/QueryEditorField'
import ColorPickerField from './fields/ColorPickerField'

const AlarmEditor = (props) => {
  const { alarm, onCancelEdit, onAlarmChanged, definition, mode } = props

  const [localAlarm, setLocalAlarm] = useState(null)
  const [deleteAlarmPending, setDeleteAlarmPending] = useState(null)
  const changeHandler = (property, value) => {
    const newAlarm = { ...localAlarm }
    if (typeof property === 'object') {
      for (var key in property) {
        _.set(newAlarm, key, property[key])
      }
    } else {
      _.set(newAlarm, property, value)
    }

    setLocalAlarm(newAlarm)
  }

  useEffect(() => {
    setLocalAlarm(alarm)
  }, [alarm])

  const yesDeleteAction = () => {
    props.deleteAlarm()
    setDeleteAlarmPending(null)
  }

  const noDeleteAction = () => {
    setDeleteAlarmPending(null)
  }

  const deleteAlarm = () => {
    setDeleteAlarmPending(true)
  }

  const willRender = !!(localAlarm && definition)

  const height = Dimensions.get('window').height

  const includedProps = { editable: mode !== viewMode }

  return (
    <View>
      {willRender ? (
        <Overlay isVisible={!!alarm} onBackdropPress={onCancelEdit}>
          <OverlayHeader>Editing Alarm {localAlarm?.name}</OverlayHeader>

          {deleteAlarmPending && (
            <YesNoCancelDialog
              title="delete alarm?"
              body="This will delete the alarm permanently. Continue?"
              yesAction={yesDeleteAction}
              noAction={noDeleteAction}
              cancelAction={noDeleteAction}
            />
          )}

          <ScrollView style={{ maxHeight: height * 0.8 }}>
            <Card.Content>
              <MSFESInput
                horizontal
                label="Alarm Name"
                defaultValue={localAlarm.name}
                name="name"
                onChangeText={(text) => changeHandler('name', text)}
                {...includedProps}
              />
              <MSFESInput
                InputComponent={QueryEditorField}
                key="alarm-condition"
                label={'Alarm Condition'}
                title={'Alarm Condition'}
                helpText={
                  'When this formula evaluates to true, the data will be presented in an alarm state.'
                }
                changeHandler={(value) => {
                  changeHandler('alarmCondition', value)
                }}
                data={{
                  entity: { entity_type_target: { id: definition.id } }
                }}
                defaultValue={localAlarm?.alarmCondition}
                {...includedProps}
              />
              <MSFESInput
                InputComponent={ColorPickerField}
                key="alarm-condition-color"
                label={'Alarm Condition Color'}
                title={'Alarm Condition Color'}
                changeHandler={(color) => {
                  changeHandler('alarmConditionColor', color)
                }}
                data={{
                  entity: { entity_type_target: { id: definition.id } }
                }}
                defaultValue={localAlarm?.alarmConditionColor}
                {...includedProps}
              />
            </Card.Content>

            <Card.Content>
              <View style={{ paddingVertical: spacing.m2 }}>
                <Text>
                  When the rule set above is true for the first time, these
                  effects will run in parallel.
                </Text>
              </View>
            </Card.Content>
          </ScrollView>

          <View style={{ flexDirection: 'row', paddingHorizontal: spacing.m2 }}>
            <View style={{ marginRight: spacing.m2 }}>
              <Divider />
              <View>
                <MSFESButton
                  icon={{ type: 'font-awesome', name: 'trash' }}
                  type="delete"
                  onPress={deleteAlarm}
                ></MSFESButton>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <MSFESButton
                onPress={() => {
                  onAlarmChanged(localAlarm)
                  onCancelEdit()
                }}
                title="Save Alarm"
              />
            </View>
          </View>
        </Overlay>
      ) : (
        <></>
      )}
    </View>
  )
}

export default AlarmEditor
