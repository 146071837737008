import { DateTime } from 'luxon'
import jsonLogic from 'json-logic-js'

jsonLogic.add_operation('now', function (mockNow) {
  const date = DateTime.fromISO(mockNow || DateTime.local().toISO())
  return date.toJSDate()
})

jsonLogic.add_operation('date_add', function (firstDate, distance, unit) {
  distance = parseInt(distance)

  let resultDate = DateTime.fromJSDate(new Date(firstDate))

  resultDate = resultDate.plus({ [unit]: distance })

  return resultDate.toJSDate()
})
jsonLogic.add_operation('start_of_month', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const startOfMonth = parsedDate.startOf('month')

  return startOfMonth
})
jsonLogic.add_operation('end_of_month', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const endOfMonth = parsedDate.endOf('month')
  return endOfMonth
})

jsonLogic.add_operation('start_of_week', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const startOfWeek = parsedDate.startOf('week')

  return startOfWeek
})
jsonLogic.add_operation('end_of_week', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const endOfWeek = parsedDate.endOf('week')
  return endOfWeek
})
jsonLogic.add_operation('start_of_year', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const startOfYear = parsedDate.startOf('year')

  return startOfYear
})
jsonLogic.add_operation('end_of_year', function (firstDate) {
  const parsedDate = DateTime.fromJSDate(firstDate)
  const endOfYear = parsedDate.endOf('year')
  return endOfYear
})
// Define the evaluate function
export const evaluate = (ruleset, data, mockNow) => {
  const result = jsonLogic.apply(ruleset, data, mockNow)
  return result
}
