import React, { useState } from 'react'
import { View } from 'react-native'
import { Overlay } from 'react-native-elements'
import SegmentedControl from '@react-native-community/segmented-control'
import { ActionHistoryPanel } from './ActionHistoryPanel'
import { ActionTruthPanel } from './ActionTruthPanel'

export const ViewActionsOverlay = ({ entity, entityType, onDismissed }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <Overlay visible={true} onBackdropPress={onDismissed}>
      <View style={{ maxHeight: 800, position: 'relative' }}>
        <SegmentedControl
          key={[0, 1]}
          values={['Actions', 'History']}
          selectedIndex={activeTabIndex}
          onChange={(event) => {
            setActiveTabIndex(event.nativeEvent.selectedSegmentIndex)
          }}
        />
        {activeTabIndex === 0 && (
          <ActionTruthPanel entity={entity} entityType={entityType} />
        )}
        {activeTabIndex === 1 && (
          <ActionHistoryPanel entity={entity} entityType={entityType} />
        )}
      </View>
    </Overlay>
  )
}
