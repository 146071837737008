import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { Card } from 'react-native-elements'
import { Text, View } from 'react-native'
import ListItemLinkButton from './ListItemLinkButton'
import spacing from '../styles/spacing'

const ViewList = React.forwardRef((props, ref) => {
  const entityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )
  // props.data.field.field_data.params.sourceName
  const targetEntityType = entityTypes.find(
    (e) => e.name === props.data.field.field_data.params.sourceName
  )

  const targetEntityFieldName = _.get(
    props,
    'data.field.field_data.params.targetEntityField.field_data.property',
    ''
  )

  const sourceFieldName = _.get(
    props,
    'data.field.field_data.params.sourceEntityField.id',
    'id'
  )
  const sourceId = _.get(props.data, `entity.${sourceFieldName}`)

  const sourceName = _.get(props.data.entity, 'display_name', '')

  // we can never view list if it's an add.
  if (props.data.entity.mode === 'add') {
    const label = _.get(props, 'definition.label', '')
    const title = _.get(props, 'data.field.field_data.title', '')
    return (
      <View style={[{ flex: 1 }, { marginBottom: spacing.m2 }]}>
        <Card>
          <Text>
            Please save the {label} before viewing associated {title}
          </Text>
        </Card>
      </View>
    )
  }

  // go to the target entity, and filter by the source ID (on the linked field).
  // e.g. source is truck, ID is 5. Target entity linked field is truck. Target entity is truck report.
  //  Go to /truck_reports?truck=5

  const link = targetEntityType
    ? `/entity-list/?type=${
        targetEntityType?.name
      }&sourceName=${encodeURIComponent(
        sourceName
      )}&filter={"${targetEntityFieldName}":${sourceId}}`
    : null

  return (
    <View style={{ flex: 1 }}>
      <ListItemLinkButton title={`View ${targetEntityType?.label}`} to={link} />
    </View>
  )
})

export default ViewList
