import React, { useMemo } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { isArray } from 'lodash'
import spacing from '../styles/spacing'
import { Checkbox } from 'react-native-paper'
import colors from '../styles/colors'
export const PendingChange = ({ onApproveChecked, value, checked }) => {
  const displayableValue = useMemo(() => {
    if (isArray(value)) {
      return value?.map((v) => v.label)
    }

    return null
  }, [value])

  const radioProps = useMemo(() => {
    return {
      checkedColor: colors.blueDarker,
      color: colors.blueDarkest
    }
  }, [])

  const status = checked ? 'checked' : 'unchecked'

  return value ? (
    <TouchableOpacity
      onPress={onApproveChecked}
      style={{
        backgroundColor: colors.approvalBackground,
        padding: spacing.m1,
        marginVertical: spacing.m1,
        borderRadius: spacing.borderRadius,
        flexDirection: 'row'
      }}
    >
      <View style={{ flex: 1 }}>
        <Text style={{ fontWeight: 'bold' }}>Awaiting approval:</Text>
        <Text style={{ color: 'black' }}>
          {displayableValue ?? JSON.stringify(value)}
        </Text>
      </View>
      <View>
        <Checkbox.Android {...radioProps} status={status} />
      </View>
    </TouchableOpacity>
  ) : null
}
