import { DateTime } from 'luxon'
import _ from 'lodash'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'
import analytics from '../../libraries/analytics'
import { getFilterString } from './actions'
const defaultState = {
  byId: {},
  ids: [],
  widgetIds: [],
  widgetById: {},
  editing: null,
  queuedEntitiesForSave: [],
  errors: {
    errors: null,
    hasError: false
  }
}

export default function entities(state = defaultState, action) {
  switch (action.type) {
    case 'persist/REHYDRATE':
      break

    case 'entity/reset':
      state = { ...defaultState }
      break
    case 'entity/update-editing':
      {
        const { entity, entityType } = action

        state = { ...state, editing: { entity, entityType } }
      }

      break
    case 'entity/dump-unsaved':
      {
        const unsavedPrev = [...(state.unsaved ?? [])]
        const filteredUnsaved = unsavedPrev.filter(
          (item) => item.offline_id !== action.entity.offline_id
        )
        state = { ...state, unsaved: filteredUnsaved }
      }

      break
    case 'entity/store-unsaved':
      {
        const unsavedPrev = [...(state.unsaved ?? [])]
        let newUnsaved = action.entity

        const existingUnsaved = (state.unsaved ?? []).find(
          (e) => e.offline_id === newUnsaved.offline_id
        )

        if (existingUnsaved?.touched_at === newUnsaved.touched_at) {
          // it's the same as an existing.
          return state
        }

        if (
          newUnsaved.type &&
          typeof newUnsaved.type === 'object' &&
          newUnsaved.type.id
        ) {
          newUnsaved = {
            ...newUnsaved,
            type: { id: newUnsaved.type.id, label: newUnsaved.type.label }
          }
        }

        const filteredUnsaved = unsavedPrev.filter(
          (item) => item.offline_id !== newUnsaved.offline_id
        )

        filteredUnsaved.unshift(newUnsaved)

        // If array > 5, drop last
        if (filteredUnsaved.length > 5) {
          filteredUnsaved.pop()
        }

        state = { ...state, unsaved: filteredUnsaved }
      }

      break

    case 'entity/abandon-editing':
      state = { ...state, editing: null }
      break
    case 'entity/delete-started':
      {
        const { id } = action.data
        const propertyPathFilter = action.data.type?.name + '&'

        state = { ...state }
        const affectedPropertyKeys = Object.keys(state).filter(
          (f) => f.indexOf(propertyPathFilter) === 0
        )
        affectedPropertyKeys.map((key) => {
          const stateKeyLength = state[key].length
          if (state[key].data) {
            state[key].data = state[key].data.filter((k) => k.id !== id)

            if (state[key].length !== stateKeyLength) {
              console.warn('Key lengths do not match')
            }
          }
        })
      }

      break
    case 'entity/list-started':
      {
        const entityDefinition = action.data
        const { filterString } = action

        const propertyPath =
          entityDefinition?.name + sanitiseFilterString(filterString)

        state = {
          ...state,
          [propertyPath]: {
            ...state[propertyPath],
            lastRetrieveStarted: DateTime.local()
          }
        }
      }

      break
    case 'entity/task-list-error':
      state = {
        ...state,
        ...{
          tasks: {
            ...state.tasks,
            isError: true
          }
        }
      }
      break
    case 'entity/task-list-success':
      {
        const { response } = action

        const { data, meta } = response

        const shouldAppend = meta.current_page > 1

        const prevData = _.get(state, 'tasks.data', [])

        const newTo = _.get(meta, 'to', null)

        const oldTo = _.get(state.tasks, 'meta.to', null)

        state = {
          ...state,
          ...{
            tasks: {
              ...state.tasks,
              isError: false,
              data: shouldAppend ? [...prevData, ...data] : data,
              meta: { ...meta, to: newTo > oldTo ? newTo : oldTo },
              lastRetrieveFinished: DateTime.local()
            }
          }
        }
      }

      break
    case 'entity/list-error':
      {
        const { entityDefinitions, filterString, error } = action

        const propertyPath =
          entityDefinitions?.map((e) => e?.name).join(',') +
          sanitiseFilterString(filterString)
        // if it's an authorization error, remove this data from the local cache.

        if (error.statusCode == 403) {
          //authenticated, but not authorized.

          const oldState = state

          state = {
            ...oldState,
            [propertyPath]: undefined
          }
        }
      }
      break
    case 'users/signin-success':
      // core entities.

      {
        const { core_entities } = action
        core_entities?.forEach((core_entity) => {
          const propertyPath =
            core_entity.name +
            sanitiseFilterString(getFilterString({}, { detailed: true }))

          const newValueInPath = {
            ...state[propertyPath],
            path: propertyPath,
            isSearch: false,
            data: core_entity.entities,
            meta: null,
            lastRetrieveFinished: null
          }

          state = {
            ...state,
            [propertyPath]: newValueInPath
          }
        })
      }
      break
    case 'entity/list-success':
      {
        const {
          response,
          entityDefinitions,
          filterString,
          isSearch = false,
          isFullRefresh = false
        } = action

        const propertyPath =
          entityDefinitions.map((e) => e.name).join(',') +
          sanitiseFilterString(filterString)

        const { data, meta } = response

        const shouldAppend =
          (isSearch && meta?.current_page == 1) || isFullRefresh ? false : true //meta?.current_page > 1

        const prevData = _.get(state[propertyPath], 'data', [])

        const byId = { ...state.byId }
        const ids = [...ids]

        // This is only a useful strategy when the EntityController->List returns the rich entity type objects.
        // response.data.map(entity => {
        //   byId[entity.id] = entity;
        //   ids.push(entity.id)
        // })
        const oldState = state

        const newTo = _.get(meta, 'to', null)

        const oldTo = _.get(state[propertyPath], 'meta.to', null)

        const newData = _.uniqBy([...data, ...prevData], 'id')

        const newValueInPath = {
          ...state[propertyPath],
          path: propertyPath,
          isSearch,
          data: shouldAppend ? newData : data,
          meta: shouldAppend
            ? { ...meta, to: newTo > oldTo ? newTo : oldTo }
            : meta,
          lastRetrieveFinished: DateTime.local()
        }

        state = {
          ...oldState,
          byId,
          ids,
          [propertyPath]: newValueInPath
        }
      }

      break
    case 'entity/widget-show-success':
      {
        const { response, withEntityId = '' } = action

        const widgetById = { ..._.get(state, 'widgetById', {}) }
        const widgetIds = [..._.get(state, 'widgetIds', [])]
        widgetById[response.data.id + '.' + withEntityId] = response.data
        widgetIds.push(response.data.id)

        state = {
          ...state,
          widgetById,
          widgetIds
        }
      }

      break
    case 'entity/show-success':
      {
        const { response } = action

        const byId = { ...state.byId }
        const ids = [...state.ids]
        byId[response.data.id] = response.data
        ids.push(response.data.id)

        state = {
          ...state,
          byId,
          ids
        }
      }

      break
    case 'entity/queue-entity-for-save': {
      const {
        data: { entity, entityType, mode, useTaskLevelValidation }
      } = action

      const queuedEntitiesForSave = [
        ..._.get(state, 'queuedEntitiesForSave', [])
      ]

      // is something already on the queue with this offline_id? If so, it's probably being replaced.
      const existingIndex = queuedEntitiesForSave.findIndex(
        (q) => q.entity.offline_id === entity.offline_id
      )

      const payload = { entity, entityType, mode, useTaskLevelValidation }
      if (existingIndex !== -1) {
        queuedEntitiesForSave[existingIndex] = payload
      } else {
        queuedEntitiesForSave.push(payload)
      }

      return { ...state, queuedEntitiesForSave }
    }

    case 'entity/remove-queued-entity': {
      const {
        data: { entity }
      } = action

      const queuedEntitiesForSave = _.get(
        state,
        'queuedEntitiesForSave',
        []
      ).filter((q) => q.entity.offline_id !== entity.offline_id)
      return { ...state, queuedEntitiesForSave }
    }

    case 'entity/dump-queue':
      state = {
        ...state,
        queuedEntitiesForSave: []
      }
      break
    case 'entity/upsert-success':
      {
        const { response, entityDefinition, entity } = action

        const data = _.get(state, `[${entityDefinition.name}].data`, [])
        const existingRecord = data.findIndex(
          (record) => record.id === response.data.id
        )

        if (existingRecord !== -1) {
          data[existingRecord] = response.data
        } else {
          data.push(response.data)
        }

        analytics.TrackEvent('Entity Insert/Update Success', {
          name: entityDefinition.name
        })

        const queuedEntitiesForSave = _.get(
          state,
          'queuedEntitiesForSave',
          []
        ).filter((q) => q.entity.offline_id !== entity.offline_id)

        const byId = { ...state.byId }
        const ids = [...ids]
        byId[response.data.id] = response.data
        ids.push(response.data.id)

        state = {
          ...state,
          byId,
          ids,
          queuedEntitiesForSave,
          editing: null,
          ...{
            [entityDefinition.name]: {
              ...state[entityDefinition.name],
              data
            }
          }
        }
      }

      break
    case 'entity/upsert-started':
      state.errors = {
        ...state.errors,
        ...{ hasError: false, message: null, statusCode: null, errors: null }
      }
      break

    case 'entity/upsert-error':
      // mutate the message to include details about each field.
      {
        let friendlyErrors = []
        let errorsByPage = {}
        const { errors } = action.error
        const { entityDefinition } = action

        let lastPage = ''
        try {
          _.sortBy(
            Object.keys(errors),
            (key) => errors[key]?.field_data?.page ?? 1
          ).map((key) => {
            const friendly = entityDefinition.fields.find(
              (f) => f.field_data.property === key
            )
            let title = key

            const page = friendly?.field_data?.page ?? 1

            if (!errorsByPage[page]) {
              errorsByPage[page] = {}
            }
            errorsByPage[page][key] = errors[key]

            if (friendly) {
              title = _.get(friendly, 'field_data.title', key)
            }
            if (lastPage !== page) {
              lastPage = page

              const pageName =
                entityDefinition?.object_data?.page_labels?.[page - 1] ??
                `Page ${page}`
              friendlyErrors.push(pageName)
            }
            friendlyErrors.push(title + ': ' + errors[key])
          })
        } catch {}

        state.errors = {
          offline_id: action?.entity?.offline_id,
          errorsByPage: errorsByPage,
          errors: action.error.errors,
          message: friendlyErrors
            ? friendlyErrors.join('\n')
            : action.error.message,
          statusCode: action.error.statusCode,
          hasError: true
        }
        analytics.TrackEvent('Entity Insert/Update Error', {
          name: entityDefinition.name,
          message: state.errors.message,
          statusCode: state.errors.statusCode
        })
      }

      break
    case 'entity/clear-general-error':
      state.errors = {
        ...state.errors,
        ...{ message: null, statusCode: null, errorsByPage: {} }
      }
      break
    case 'entity/clear-field-errors':
      state.errors = { ...defaultState }
      break
  }

  return state
}
