// libraries
import React, { useState, useEffect, useRef } from 'react'
import { View, SafeAreaView, Platform, Text } from 'react-native'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

import { showMessage } from 'react-native-flash-message'
// styles
import spacing from '../styles/spacing'
import shared from '../styles/shared'

//actions
import userActions from '../store/user/actions'
import appActions from '../store/app/actions'
// components
import colors from '../styles/colors'
import MSFESButton from '../components/MSFESButton'
import MSFESInput from '../components/MSFESInput'
import { StatusBar } from 'react-native'
import { KeyboardAvoidingView } from 'react-native'
import DisplayErrors from '../components/DisplayErrors'
import analytics from '../libraries/analytics'
import { InputTextStyle } from '../components/ListItemAsFieldProps'
import SignInFireman from '../components/SignInFireman'
import SignInLogo from '../components/SignInLogo'
import { scrubExcessEntityTypes } from '../store/entity-type/actions'
export default function signinScreen({ route, navigation }) {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({ email: '', password: '' })
  const [requiresMFACode, setRequiresMFACode] = useState(false)
  const previousEmail = useSelector((state) => state.users.email)
  const errors = useSelector((state) => state.users.errors.errors)

  const qsEmail = _.get(route.params, 'email', false)
  const qsMessage = _.get(route.params, 'message', false)

  const timer = useRef(null)
  const changeHandler = (name, text) => {
    setValues({ ...values, [name]: text })
  }

  useEffect(() => {
    if (qsEmail) {
      changeHandler('email', qsEmail)
    }
    if (qsMessage) {
      showMessage({ message: qsMessage, type: 'success', duration: 10000 })
    }
  }, [qsEmail, qsMessage])

  useEffect(() => {
    analytics.TrackEvent('App Booted')
    userActions.clearErrors()

    // if not production, we want it to be production.
    navigation.setOptions({ title: 'Sign In' })
  }, [])
  useEffect(() => {
    // if there's an email in store, put it in the form.
    if (previousEmail && !values.email) {
      changeHandler('email', previousEmail)
    }
  }, [errors])

  //

  const forceAppUpdate = () => {
    if (Platform.OS !== 'web') {
      try {
        const codePush = require('react-native-code-push')
        codePush.sync({
          updateDialog: true,
          installMode: codePush.InstallMode.IMMEDIATE
        })
      } catch (e) {}
    }
  }
  const signinPressed = () => {
    setLoading(true)
    dispatch(userActions.clearErrors())

    /* HRFIR-724 - scrub old entity types */
    dispatch(scrubExcessEntityTypes())
    /* end HRFIR-724 */

    const { email, password, mfa_code } = values
    dispatch(userActions.attemptSignin({ email, password, mfa_code }))
      .catch((error) => {
        if (error.statusCode == 422) {
          userActions.clearErrors()
          setRequiresMFACode(true)
        }
      })
      .finally(() => {
        setLoading(false)
        forceAppUpdate()
      })
  }

  const forgotPasswordPressed = () => {
    dispatch(userActions.clearErrors())
    navigation.navigate('ForgotPasswordScreen', { email: values.email })
  }

  const [taps, setTaps] = useState(0)
  const environment = useSelector((state) => {
    return state.app.environment
  })

  const switchEnvironment = (toEnvironment = null) => {
    if (toEnvironment) {
      dispatch(appActions.setEnvironment(toEnvironment))
      return
    }

    let newEnvironment
    switch (environment) {
      case 'dev':
        newEnvironment = 'stage'
        break
      case 'prod':
        newEnvironment = 'dev'
        break
      case 'stage':
      case '':
      default:
        newEnvironment = 'prod'
        break
    }

    dispatch(appActions.setEnvironment(newEnvironment))
  }

  useEffect(() => {
    if (Platform.OS !== 'web') {
      switchEnvironment('prod')
    }
  }, [''])

  const logoTapped = () => {
    setTaps(taps + 1)
    if (!timer.current) {
      timer.current = setTimeout(() => {
        setTaps(0)
        clearInterval(timer.current)
        timer.current = null
      }, 5000)
    }

    if (taps >= 4) {
      setTaps(0)
      switchEnvironment()
    }
  }

  return (
    <SafeAreaView style={[shared.fullHeight, shared.darkView]}>
      <StatusBar barStyle="light-content" />
      <SignInFireman />
      {environment !== 'prod' && (
        <View
          style={[
            shared.debugOff,
            { alignItems: 'flex-end', padding: spacing.m2 }
          ]}
        >
          <Text style={{ color: colors.white }}>env:{environment}</Text>
        </View>
      )}
      <View
        style={[
          shared.mobileWidthView,
          shared.darkView,
          shared.debugOff,
          shared.fullHeight
        ]}
      >
        <SignInLogo onPress={logoTapped} />
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          contentContainerStyle={[
            shared.debugOff,
            { flex: 1, flexDirection: 'column', justifyContent: 'center' }
          ]}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <View
            style={[
              shared.debugOff,
              { flexDirection: 'column', justifyContent: 'center' }
            ]}
          >
            <DisplayErrors errors={errors} dark />
            {requiresMFACode && (
              <MSFESInput
                showLabel={false}
                signin
                placeholder="Multi-Factor Auth Code"
                defaultValue={values.mfa_code}
                keyboardType={'number-pad'}
                style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
                name="email"
                errorMessage={_.get(errors, 'mfa_code', []).join(',')}
                onChangeText={(text) => changeHandler('mfa_code', text)}
              />
            )}
            {!requiresMFACode && (
              <>
                <MSFESInput
                  showLabel={false}
                  signin
                  placeholder="Email Address"
                  defaultValue={values.email}
                  style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
                  keyboardType={'email-address'}
                  name="email"
                  errorMessage={_.get(errors, 'email', []).join(',')}
                  onChangeText={(text) => changeHandler('email', text)}
                />

                <MSFESInput
                  showLabel={false}
                  signin
                  placeholder="Password"
                  defaultValue={values.password}
                  name="password"
                  style={[InputTextStyle, { marginBottom: 12, height: 48 }]}
                  secureTextEntry={true}
                  errorMessage={_.get(errors, 'password', []).join(',')}
                  onChangeText={(text) => changeHandler('password', text)}
                />
              </>
            )}

            <MSFESButton
              dark
              primary
              loading={loading}
              title="Sign In"
              onPress={signinPressed}
            />
          </View>
          <MSFESButton
            dark
            isLink
            title="Forgot Password"
            onPress={forgotPasswordPressed}
          />
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  )
}
