import React from 'react'
import WithHeader from '../components/WithHeader'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { ListItemLinkButton } from '../components/ListItemLinkButton'
import { Dropdown } from '../components/dropdowns/Dropdown'
import { SensitiveActionsPanel } from '../components/SensitiveActionsPanel'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'

export default function SettingsIndexScreen({ navigation }) {
  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const canModifyEntityTypes = _.get(
    current_user,
    `effective_permissions['modify||name:entity types']`,
    false
  )


  const { isGlobalAdmin } = useTenantOrGlobalAdmin()

  const pageTitle = 'Admin Tools'
  return (
    <WithHeader
      title={pageTitle}
      navigation={navigation}
      leftButtonType={'back'}
    >
      <View key="view-module-wrapper">
        <View>
          {canModifyEntityTypes && (
            <ListItemLinkButton
              title={'Manage Entity Types'}
              to={`/settings/admin-tools/manage-entity-types/index`}
            />
          )}
          <ListItemLinkButton
            title={'Admin Modules'}
            to={`/settings/admin-tools/modules`}
          />
          {!!isGlobalAdmin && <ListItemLinkButton
            title={'Manage Logins'}
            to={`/settings/manage-logins`}
          />}
          <Dropdown
            positionAtButton={false}
            component={({ onPress }) => (
              <ListItemLinkButton
                title={'Sensitive Actions'}
                onPress={onPress}
              />
            )}
          >
            <SensitiveActionsPanel />
          </Dropdown>
        </View>
      </View>
    </WithHeader>
  )
}
