// libraries
import React, { useState } from 'react'
import { View } from 'react-native'
import _ from 'lodash'
import MSFESInput from './MSFESInput'
import { nestedInputExtraProps } from './LatLng'
import { SelectList } from './fields/SelectList'
import spacing from '../styles/spacing'
import { ListItem, Overlay } from 'react-native-elements'
import ListItemAsFieldProps, { InputSelectStyle } from './ListItemAsFieldProps'
import MSFESButton from './MSFESButton'

export const FullName = React.forwardRef((props, ref) => {
  const { defaultValue, changeHandler, editable, /*definition,*/ style } = props //child props

  // const field_data = _.get(props, 'data.field.field_data', false)
  // console.log(field_data, definition)

  const [localState, setLocalState] = useState({
    nameprefix: _.get(defaultValue, 'nameprefix'),
    first: _.get(defaultValue, 'first', ''),
    middle: _.get(defaultValue, 'middle', ''),
    last: _.get(defaultValue, 'last', ''),
    namesuffix: _.get(defaultValue, 'namesuffix'),
    display_name: _.get(defaultValue, 'display_name', '')
  })

  const [visible, setVisible] = useState(false)

  const localChangeHandler = (prop, value) => {
    setLocalState((current) => {
      const newLocalState = {
        ...current,
        [prop]: value
      }

      const prefix = newLocalState.nameprefix?.display_name
      const suffix = newLocalState.namesuffix?.display_name
      newLocalState.display_name = (
        '' +
        (prefix ? prefix + ' ' : '') +
        (newLocalState.first ? newLocalState.first + ' ' : '') +
        (newLocalState.middle ? newLocalState.middle + ' ' : '') +
        (newLocalState.last ? newLocalState.last + ' ' : '') +
        (suffix ? suffix + ' ' : '')
      ).trim()
      return newLocalState
    })
  }

  const blurChanges = () => {}

  const onConfirmChanges = () => {
    changeHandler(localState) //bubble up.
  }

  const onPressAction = () => {
    setVisible(true)
  }
  return (
    <View style={style}>
      <ListItem
        onPress={onPressAction}
        disabled={!editable}
        containerStyle={ListItemAsFieldProps.containerStyle}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
            {localState.display_name
              ? localState.display_name
              : '(None entered)'}
          </ListItem.Title>
        </ListItem.Content>
        {visible && (
          <Overlay onBackdropPress={() => setVisible(false)} isVisible={true}>
            <View
              style={{ flexDirection: 'column', maxHeight: 360, minWidth: 240 }}
            >
              <SelectList
                style={[{ marginBottom: spacing.m1 }, props.style]}
                placeholder="Prefix"
                defaultValue={localState.nameprefix}
                labelField={'display_name'}
                onChangeText={(value) =>
                  localChangeHandler('nameprefix', value)
                }
                data={{
                  entity: props.data?.entity,
                  field: {
                    field_data: {
                      params: {
                        ...props.data?.field.field_data.params,
                        ...{ sourceName: 'name_prefix' }
                      }
                    }
                  }
                }}
              />
              <MSFESInput
                supportsInputShading={false}
                showLabel={false}
                {...nestedInputExtraProps}
                value={localState.first}
                placeholder="First Name"
                style={{
                  ...props.style,
                  paddingLeft: 8,
                  flex: 1,
                  marginBottom: 10
                }}
                disabled={!editable}
                onChangeText={(value) => localChangeHandler('first', value)}
                onBlur={blurChanges}
              />
              <MSFESInput
                showLabel={false}
                {...nestedInputExtraProps}
                value={localState.middle}
                placeholder="Middle Name"
                style={{
                  ...props.style,
                  paddingLeft: 8,
                  flex: 1,
                  marginBottom: 10
                }}
                disabled={!editable}
                onChangeText={(value) => localChangeHandler('middle', value)}
                onBlur={blurChanges}
              />
              <MSFESInput
                showLabel={false}
                {...nestedInputExtraProps}
                value={localState.last}
                placeholder="Last Name"
                style={{
                  ...props.style,
                  paddingLeft: 8,
                  flex: 1,
                  marginBottom: 10
                }}
                disabled={!editable}
                onChangeText={(value) => localChangeHandler('last', value)}
                onBlur={blurChanges}
              />

              <SelectList
                placeholder="Suffix"
                defaultValue={localState.namesuffix}
                labelField={'display_name'}
                style={[props.style]}
                onChangeText={(value) =>
                  localChangeHandler('namesuffix', value)
                }
                data={{
                  entity: props.data?.entity,
                  field: {
                    field_data: {
                      params: {
                        ...props.data?.field.field_data.params,
                        ...{ sourceName: 'name_suffix' }
                      }
                    }
                  }
                }}
              />

              <MSFESButton
                style={{ marginTop: 8, justifyContent: 'flex-end' }}
                onPress={onConfirmChanges}
                title={'Confirm'}
              ></MSFESButton>
            </View>
          </Overlay>
        )}
        {editable && (
          <ListItem.Chevron
            type="font-awesome"
            name="chevron-down"
            size={14}
            color={'black'}
          />
        )}
      </ListItem>
    </View>
  )
})
