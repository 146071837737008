import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import spacing from '../../styles/spacing'

export const WidgetDataLoading = ({ isPlanner }) => {
  return (
    <View style={isPlanner && { marginRight: spacing.m11 }}>
      <ActivityIndicator size="small"></ActivityIndicator>
    </View>
  )
}
