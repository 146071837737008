// libraries
import React, { useEffect, useState, useCallback } from 'react'
import { View, useWindowDimensions, LayoutAnimation, Text } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { showMessage } from 'react-native-flash-message'
import { ActivityIndicator } from 'react-native-paper'

import EntityAddScreen, { addMode, viewMode } from '../screens/EntityAddScreen'

import _ from 'lodash'
// styles

//actions
import appActions from '../store/app/actions'
import entityActions, {
  getFilterString,
  getAddUrl
} from '../store/entity/actions'

// components

import {
  discoverPageTitle,
  sortListWithEntityType
} from '../libraries/entityTools'
import WithHeader from '../components/WithHeader'
import GlobalLoading from '../components/GlobalLoading'
import MSFESHeading from '../components/MSFESHeading'
import { useMemo } from 'react'
import { useIsFocused } from '@react-navigation/native'
import spacing from '../styles/spacing'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import { ListItemAdd } from '../components/ListItemAdd'
import SplitView from '../components/SplitView'
import EntityListActions from '../components/EntityListActions'
import MediaHelper from '../libraries/mediaHelper'
import { shouldShowDesktop, useIsMobile } from '../libraries/shouldShowDesktop'
import { useDropdownHelper } from '../useDropdownHelper'
import { getTypeFromUri, openExternalLink } from '../helpers/link'
import DataTableWrapper from './EntityListScreenHelpers/DataTableWrapper'
import DataListWrapper from './EntityListScreenHelpers/DataListWrapper'
import { ListItemButtonOpenCurrentTimesheet } from '../components/ListItemButtonOpenCurrentTimesheet'
import shared from '../styles/shared'
import { ScreenButtonsBar } from './EntityListScreenHelpers/ScreenButtonsBar'
import { WidgetBar } from './EntityListScreenHelpers/WidgetBar'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
export const simplifyFilters = (filter, newFilters, availableFilters) => {
  const newFiltersSimplified = {}

  const existingFilter = { ...filter }

  Object.keys(newFilters).map((k) => {
    if (!newFilters[k]) {
      //if filter has been removed, ditch it.
      delete existingFilter[k]
    } else {
      const matchedFilterType = availableFilters.find(
        (a) => a.field_data.property == k
      )
      if (matchedFilterType?.filterType == 'date') {
        // do dates
        newFiltersSimplified[k] = {
          from: newFilters[k].from,
          to: newFilters[k].to
        }
      } else {
        if (Array.isArray(newFilters[k])) {
          newFiltersSimplified[k] = newFilters[k].map((el) => ({
            id: el.id ? el.id : el.key,
            display_name: el.display_name ? el.display_name : el.label
          }))
        } else {
          const objectValue = newFilters[k].id
            ? newFilters[k].id
            : newFilters[k].key
          newFiltersSimplified[k] = _.isObject(newFilters[k])
            ? objectValue
            : newFilters[k]
        }
      }
    }
  })

  return newFiltersSimplified
}

export const getAvailableFilters = (entityType) => {
  if (!entityType) {
    return []
  }
  const { fields } = entityType

  if (!fields) {
    return []
  }

  const filterableTypes = ['select-list']
  const filterableAdhocTypes = [
    'select-adhoc',
    'radio-adhoc',
    'checkbox-multiselect-adhoc'
  ]

  const filterableBooleanTypes = ['yes-no']
  const dateFilterableTypes = ['date-picker', 'date-time-picker']

  const filterableDropdown = fields
    .filter((f) => filterableTypes.includes(f.field_data.type))
    .filter((f) => _.get(f, 'field_data.params.canUseAsFilter', true))
    .map((f) => {
      f.filterType = 'dropdown'
      return f
    })
  const filterableAdhocDropdowns = fields
    .filter((f) => filterableAdhocTypes.includes(f.field_data.type))
    .filter((f) => _.get(f, 'field_data.params.canUseAsFilter', true))
    .map((f) => {
      f.filterType = 'dropdown-adhoc'
      return f
    })

  const filterableBooleans = fields
    .filter((f) => filterableBooleanTypes.includes(f.field_data.type))
    .filter((f) => _.get(f, 'field_data.params.canUseAsFilter', true))
    .map((f) => {
      f.filterType = 'dropdown-boolean'
      return f
    })

  const filterableDates = fields
    .filter((f) => dateFilterableTypes.includes(f.field_data.type))
    .filter((f) => _.get(f, 'field_data.params.canUseAsFilter', true))
    .map((f) => {
      f.filterType = 'date'
      return f
    })

  const filterableSpecial = [
    {
      field_data: {
        property: 'assignee',
        title: 'Assignee',
        params: { sourceName: 'personnel' }
      },
      filterType: 'dropdown'
    },
    {
      field_data: {
        property: 'place',
        title: 'Place',
        params: { sourceName: 'personnel' }
      },
      filterType: 'dropdown'
    },
    {
      field_data: {
        property: 'tenant_id',
        title: 'Tenant',
        params: { sourceName: 'tenant' }
      },
      filterType: 'tenant'
    }
  ]

  const availableFilters = [
    ...filterableDropdown,
    ...filterableDates,
    ...filterableAdhocDropdowns,
    ...filterableSpecial,
    ...filterableBooleans
  ]

  return availableFilters
}

export const defaultQueryParams = { page: 1, searchText: null, due_at: null }

export default function EntityListScreen({ route, navigation }) {
  const [forceReRenderTicker, setForceReRenderTicker] = useState(0)
  const [filter, updateFilter] = useState(false)
  const [reportPreparing, setReportPreparing] = useState(false)
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const [columnHeaders, updateColumnHeaders] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const isFocused = useIsFocused()
  const dispatch = useDispatch()

  const windowDimensions = useWindowDimensions()
  const shouldSplit = shouldShowDesktop(windowDimensions)

  const [splitViewState, setSplitViewState] = useState({
    splitDetailScreen: null,
    splitDetailScreenParams: null
  })

  const { unsavedEntitiesLength } = useSelector(
    (state) => {
      return {
        unsavedEntitiesLength: (state.entities.unsaved ?? [])?.length
      }
    },
    (a, b) => a.length === b.length
  )

  useEffect(() => {
    if (isFocused) {
      setForceReRenderTicker(forceReRenderTicker + 1)

      const paramsChanged =
        splitViewState.splitDetailScreenParams?.id !==
          route.params?.['split.id'] ||
        route.params?.mode !== splitViewState.splitDetailScreenParams?.mode ||
        route.params?.mode == addMode
      if (
        route.params &&
        (route.params?.['split.id'] || route.params?.mode == addMode) &&
        paramsChanged
      ) {
        splitviewNavigate(
          { id: route.params['split.id'] },
          route.params?.mode ?? viewMode
        )
      } else if (
        !route.params?.['split.id'] &&
        route.params?.['mode'] !== addMode
      ) {
        onCloseSplit()
      }
    }
  }, [isFocused, route.params])

  const {
    gridOrList,
    current_user,
    effective_permissions,
    showDeletedFlagState
  } = useSelector((appState) => {
    return {
      gridOrList: _.get(appState.app, 'gridOrList', 'grid'),
      current_user: _.get(appState, 'users.user'),
      effective_permissions: _.get(
        appState,
        'users.user.effective_permissions',
        {}
      ),
      showDeletedFlagState: appState.app?.features?.showDeleted
    }
  })

  // when the deleted state was changed by the user, reset the page and re-query
  // (the local entity list is deleted inside the feature flag toggle)
  // isFirstPass stops it firing on render.
  const [isFirstPass, setIsFirstPass] = useState(true)
  useEffect(() => {
    if (isFirstPass) {
      setIsFirstPass(false)
      return
    }
    setQueryParams((queryParams) => {
      return { ...queryParams, page: 1 }
    })
  }, [showDeletedFlagState, isFirstPass, setIsFirstPass])

  // **

  const toggleGridOrList = (gridOrList) => {
    let nextGridOrList
    switch (gridOrList) {
      case 'grid':
        nextGridOrList = 'list'
        break
      case 'list':
        nextGridOrList = shouldSplit ? 'table' : 'grid'
        break
      case 'table':
        nextGridOrList = 'grid'
        break
    }
    dispatch(appActions.setGridOrList(nextGridOrList))
    setForceReRenderTicker(forceReRenderTicker + 1)
  }

  const setQueryParamsDebounced = useCallback(
    _.debounce(setQueryParams, 200),
    []
  )
  const { page = null, searchText, due_at } = queryParams

  useEffect(() => {
    if (route.params?.filter) {
      updateFilter(JSON.parse(route.params.filter))
    }
  }, [route])

  const { entityType, isFetching } = useDiscoverEntityEntityType(route)
  const pageTitle = discoverPageTitle(entityType, 'list')

  const filterString = sanitiseFilterString(
    getFilterString(filter, { detailed: true })
  )

  const path = `${entityType?.name + filterString}`

  const entityListWrapper = useSelector((state) => {
    const entityListWrapper = entityType
      ? _.get(state.entities, path, {
          data: null,
          meta: null,
          hasSeenData: false
        })
      : { data: null, meta: null, hasSeenData: false }

    entityListWrapper.data = sortListWithEntityType(
      entityListWrapper.data,
      entityType
    )

    return entityListWrapper
  })
  const { meta } = entityListWrapper

  const onSearchTextChanged = (_searchText) => {
    setQueryParamsDebounced((queryParams) => {
      return { ...queryParams, page: 1, searchText: _searchText }
    })
  }

  const onDateChanged = (_dueAt) => {
    setQueryParams((queryParams) => {
      return { ...queryParams, page: 1, due_at: _dueAt }
    })
  }

  const loadMoreItems = () => {
    if (!meta || page < meta.last_page) {
      setQueryParams((queryParams) => {
        return { ...queryParams, page: page + 1, searchText }
      })
    }
  }

  const onCloseSplit = () => {
    if (splitViewState.splitDetailScreen) {
      setForceReRenderTicker(forceReRenderTicker + 1)
      navigation.setParams({ 'split.id': undefined })
      const state = navigation.dangerouslyGetState()

      let lastScreenBeforeAddList = -1
      for (let i = state.routes.length; i > 0; i--) {
        const screen = state.routes[i - 1]
        if (
          screen.name === 'EntityAdd' ||
          (screen.name === 'EntityListDetail' &&
            ['view', 'add'].includes(screen.params?.mode))
        ) {
          // do not go back to these.
          continue
        }
        lastScreenBeforeAddList = i
        break
      }
      if (state.routes.length > 1 && lastScreenBeforeAddList !== -1) {
        // we have a back route in the history
        navigation.pop(state.routes.length - lastScreenBeforeAddList)
      } else {
        // we do not, list this current entity.
        navigation.push('EntityList', {
          'split.id': undefined,
          mode: undefined,
          type: route.params.type
        })
      }
      setSplitViewState({
        ...splitViewState,
        splitDetailScreen: null,
        splitDetailScreenComponent: null
      })
    }
  }

  const splitviewNavigate = (item, mode) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)

    if (!mode || mode === 'undefined') {
      mode = viewMode
    }

    const type =
      route.params && route.params.type
        ? route.params.type
        : getTypeFromUri(item.uri)

    if (item) {
      if (!route.params['split.id'] && item?.id) {
        navigation.navigate('EntityListDetail', {
          'split.id': item?.id,
          mode,
          type
        })
        return
      }

      // navigation.setParams({ 'split.id': item?.id, mode })
    } else if (mode === addMode) {
      navigation.navigate('EntityListDetail', {
        'split.id': undefined,
        mode,
        type
      })
      return
    }

    const newSplitViewState = {
      ...splitViewState,
      splitDetailScreen: 'EntityAddScreen',
      splitDetailScreenComponent: EntityAddScreen,
      splitDetailScreenParams: {
        id: item?.id ?? undefined,
        type: type,
        tenant_id: item?.tenant_id,
        mode: mode,
        page: 1
      }
    }

    setSplitViewState(newSplitViewState)
  }

  const fetchItemsForParams = () => {
    const from_widget = route.params?.from_widget
    // console.log(from_widget, 'from_widget')
    return dispatch(
      entityActions.listEntity(entityType, {
        filter,
        page,
        searchText,
        due_at,
        from_widget,
        detailed: true
      })
    )
  }

  useEffect(() => {
    const fetchAllPersonnel = () => {
      const currentPage = 1 // This can be moved out if pagination is handled differently

      return dispatch(
        entityActions.listEntity(entityType, {
          filter,
          page: currentPage,
          searchText,
          due_at,
          detailed: true
        })
      )
    }

    if (entityType?.name === 'personnel') {
      setIsLoading(true)
      fetchAllPersonnel()
        .then(() => {
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Failed to fetch all personnel:', error)
          setIsLoading(false)
        })
    }
  }, [entityType, filter, searchText])

  useEffect(() => {
    setQueryParams(defaultQueryParams)
  }, [route, current_user.current_tenant])
  // console.log(entityType, 'entityType')
  useEffect(() => {
    if (entityType) {
      setRefreshing(true)
      setIsLoading(true)
      fetchItemsForParams()
        .then(() => {})
        .finally(() => {
          setIsLoading(false)
          setRefreshing(false)
        })

      const headersToUse = entityType?.fields?.filter((f) =>
        _.get(f, 'field_data.flags.tableView', false)
      )
      updateColumnHeaders(headersToUse)
    }
  }, [filter, queryParams, entityType])

  const [refreshing, setRefreshing] = React.useState(false)
  const onRefreshEntityListActions = () => {
    toggleDropdownState()
    onRefresh()
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    fetchItemsForParams().then(() => {
      setRefreshing(false)
    })
  }, [])

  const hasAddPermission = useMemo(() => {
    const match = _.get(
      effective_permissions,
      `create|id:${entityType?.id}|name:${entityType?.name}`,
      undefined
    )

    if (match == undefined) {
      return true //permission may not exist yet
    } else {
      return match
    }
  }, [entityType, effective_permissions])

  const hasSource = !!route.params?.sourceName

  const addButtonLabel = entityType
    ? hasSource
      ? `Add new ${entityType.label} to ${route.params.sourceName}`
      : `Add new ${entityType.label}`
    : ''

  const { availableFilters } = useMemo(() => {
    if (entityType) {
      const availableFilters = getAvailableFilters(entityType)

      return {
        availableFilters
      }
    }

    return { availableFilters: null }
  }, [entityType])

  const [filters, setFilters] = useState({})

  const onFiltersChanged = (newFilters) => {
    setFilters(newFilters)

    const newFiltersSimplified = simplifyFilters(
      filters,
      newFilters,
      availableFilters
    )

    setQueryParams({ ...queryParams, page: 1 })

    const newFiltersLength = Object.keys(newFiltersSimplified).length
    updateFilter(() => {
      const newFilterObject = newFiltersLength
        ? {
            ...filters,
            ...newFiltersSimplified,
            detailed: true
          }
        : { detailed: true }
      return newFilterObject
    })
  }

  useEffect(() => {
    if (entityType) {
      if (entityType.object_data?.filters_selected) {
        onFiltersChanged(entityType.object_data?.filters_selected)
      } else {
        onFiltersChanged({})
      }
    }
  }, [entityType, setFilters])

  const [deleteActionPending, setDeleteActionPending] = useState(null)
  const deleteCancelPressed = useCallback(() => {
    setDeleteActionPending(false)
  })

  const deleteConfirmPressed = useCallback((entity, entityType) => {
    setForceReRenderTicker(forceReRenderTicker + 1)
    return dispatch(entityActions.deleteEntity(entity, entityType))
      .then(async () => {
        await fetchItemsForParams()
        showMessage({ message: 'Deleted Successfully', type: 'success' })
        setDeleteActionPending(false)
      })
      .catch((errors) => {
        console.warn('Delete Entity Errors', errors)
        showMessage({ message: errors.message, type: 'danger' })
        throw errors
      })
      .finally(() => {})
  })

  const onGenerateTimesheetReportRequested = useCallback(() => {
    setReportPreparing(true)
    toggleDropdownState()
    dispatch(
      entityActions.listEntity(
        entityType,
        { filter, page, searchText, due_at, detailed: true },
        'timesheet'
      )
    )
      .then((response) => {
        openExternalLink(response.url)
      })
      .finally(() => {
        setReportPreparing(false)
      })
  })

  const onGenerateReportRequestedExcel = useCallback(() => {
    setReportPreparing(true)
    toggleDropdownState()
    dispatch(
      entityActions.listEntity(
        entityType,
        { filter, page, searchText, due_at, detailed: true },
        'xlsx'
      )
    )
      .then((response) => {
        openExternalLink(response.url)
      })
      .finally(() => {
        setReportPreparing(false)
      })
  })

  const onGenerateReportRequestedPDF = useCallback(() => {
    setReportPreparing(true)
    toggleDropdownState()
    dispatch(
      entityActions.listEntity(
        entityType,
        { filter, page, searchText, due_at, detailed: true },
        'pdf'
      )
    )
      .then((response) => {
        openExternalLink(response.url)
      })
      .finally(() => {
        setReportPreparing(false)
      })
  })

  const splitscreenComponent = useMemo(() => {
    const Component = splitViewState.splitDetailScreenComponent

    if (!isFocused) {
      return null
    }
    if (Component) {
      return (
        <Component
          navigation={navigation}
          key={splitViewState.splitDetailScreenParams.id}
          isInSplitView={true}
          onCloseSplit={onCloseSplit}
          route={{ params: splitViewState.splitDetailScreenParams }}
        />
      )
    }
  }, [splitViewState, isFocused])

  const onImportEntity = () => {
    MediaHelper.getMedia(MediaHelper.DocumentType).then(async ({ payload }) => {
      try {
        const body = JSON.parse(await payload.text())
        delete body.id
        delete body.tenant_id
        const bodyAppended = { ...body }

        let mode = 'add'

        dispatch(
          entityActions.upsertEntity(bodyAppended, bodyAppended.type, mode)
        )
          .then(() => {
            const message =
              mode == 'edit'
                ? 'Entity updated successfully.'
                : 'Entity added successfully'
            showMessage({ message, type: 'success' })
          })
          .catch((e) => {
            showMessage({
              message: 'An error occurred: ' + e.message,
              type: 'danger'
            })
          })
      } catch (e) {
        showMessage({
          message: 'An error occurred: ' + e.message,
          type: 'danger'
        })
      } finally {
        toggleDropdownState()
      }
    })
  }

  const ENABLE_TABLE_VIEW =
    gridOrList === 'table' && shouldSplit && !isCurrentlySplit
  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(250)

  const isMobileOrTablet = useIsMobile(true)
  const isMobile = useIsMobile(false)
  const WithHeaderContainer = useCallback(
    ({ children, paginationText, gridOrList }) => {
      // wrapped in callback to stop it re-rendering with entitylistwrapper.

      if (!isFocused) {
        return null
      }

      return (
        <WithHeader
          title={pageTitle}
          dropdownVisibilityState={dropdownVisibilityState}
          paginationText={paginationText}
          collapsibleSearch={isMobile}
          rightButtonRef={buttonRef}
          rightButtonAction={toggleDropdownState}
          rightButtonIcon="bars"
          isInSplitView={isCurrentlySplit}
          navigation={navigation}
          leftButtonType={'back'}
          filtersAvailable={availableFilters}
          gridOrList={gridOrList}
          onToggleGridOrList={() => toggleGridOrList(gridOrList)}
          filtersApplied={filters}
          onFiltersChanged={onFiltersChanged}
          onSearchTextChanged={onSearchTextChanged}
          filterableEntityType={entityType}
          onDateChanged={entityType?.date_significant ? onDateChanged : null}
        >
          {children}
        </WithHeader>
      )
    },
    [pageTitle, isMobile, isFocused, filters, unsavedEntitiesLength]
  )

  const isCurrentlySplit = !!splitViewState.splitDetailScreen

  const addEntityButtonBar = useMemo(() => {
    const displayOpenMyCurrentList = ['time_sheet']

    if (!entityType) {
      return null
    }
    if (!isFocused) {
      return null
    }

    /*
          isMobile ? { flex: 1 } : null

          */
    return (
      <View style={[shared.debugOff, { flexDirection: 'row' }]}>
        <View style={isMobileOrTablet ? { flex: 1 } : null}>
          {displayOpenMyCurrentList.includes(entityType?.name) && (
            <ListItemButtonOpenCurrentTimesheet
              entityType={entityType}
              splitviewNavigate={splitviewNavigate}
              title={`Open my current ${entityType.label}`}
              user={current_user}
            />
          )}

          {hasAddPermission &&
            !displayOpenMyCurrentList.includes(entityType?.name) && (
              <ListItemAdd
                title={addButtonLabel}
                key={'add'}
                linkTo={getAddUrl(
                  entityType,
                  filter,
                  '/entity-list/?type=' + entityType?.name
                )}
                onPress={
                  shouldSplit
                    ? () => splitviewNavigate(null, addMode)
                    : undefined
                }
              />
            )}
        </View>
      </View>
    )
  }, [hasAddPermission, entityType, shouldSplit, isFocused])
  const paginationText = entityListWrapper?.meta
    ? `Displaying: 1 - ${entityListWrapper?.meta?.to ?? 0} of ${
        entityListWrapper?.meta?.total
      }`
    : ''

  // console.log(entityListWrapper)
  const viewList = useMemo(() => {
    if (!isFocused) {
      return null
    }
    return (
      <>
        {entityType && (
          <WithHeaderContainer
            gridOrList={gridOrList}
            paginationText={paginationText}
          >
            <View style={{ flex: 1 }}>
              {/* <Text>Displaying: 1 - {entityListWrapper.meta.to} of {entityListWrapper.meta.total}</Text> */}
              {!!hasSource && (
                <MSFESHeading textStyle={{ paddingLeft: spacing.m2 }}>
                  Filtered to {route.params.sourceName}
                </MSFESHeading>
              )}
              <EntityListActions
                isVisible={dropdownVisibilityState}
                entityType={entityType}
                dropdownOverlayStyle={dropdownOverlayStyle}
                onGenerateReportRequestedExcel={onGenerateReportRequestedExcel}
                onGenerateTimesheetReportRequested={
                  onGenerateTimesheetReportRequested
                }
                filters={filters}
                queryParams={queryParams}
                refreshList={onRefreshEntityListActions}
                onImportEntity={onImportEntity}
                onGenerateReportRequestedPDF={onGenerateReportRequestedPDF}
                onBackdropPress={toggleDropdownState}
              />
              {!!ENABLE_TABLE_VIEW && (
                <View style={{ flex: 1 }}>
                  {addEntityButtonBar}

                  <DataTableWrapper
                    entityType={entityType}
                    shouldSplit={shouldSplit}
                    sourceKey={path}
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    loadMoreItems={loadMoreItems}
                    splitviewNavigate={splitviewNavigate}
                  >
                    <ScreenButtonsBar entityType={entityType} />
                    <WidgetBar entityType={entityType} />
                  </DataTableWrapper>
                </View>
              )}
              {!ENABLE_TABLE_VIEW && (
                <View style={{ flex: 1 }}>
                  {addEntityButtonBar}

                  <DataListWrapper
                    entityType={entityType}
                    shouldSplit={shouldSplit}
                    sourceKey={path}
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    loadMoreItems={loadMoreItems}
                    activeId={splitViewState.splitDetailScreenParams?.id}
                    splitviewNavigate={splitviewNavigate}
                  >
                    <ScreenButtonsBar entityType={entityType} />
                    <WidgetBar entityType={entityType} />
                  </DataListWrapper>
                </View>
              )}
              {reportPreparing && <GlobalLoading />}
            </View>
          </WithHeaderContainer>
        )}
        {!entityType && isFetching && <GlobalLoading />}
      </>
    )
  }, [
    route.params,
    queryParams.searchText,
    dropdownVisibilityState,
    ENABLE_TABLE_VIEW,
    gridOrList,
    entityType,
    isFetching,
    filter,
    refreshing,
    forceReRenderTicker,
    splitViewState.splitDetailScreenParams?.id,
    columnHeaders,
    reportPreparing,
    paginationText,
    isFocused,
    unsavedEntitiesLength
  ])

  return isFocused ? (
    <>
      {/* Main UI Content */}
      <SplitView
        isCurrentlySplit={isCurrentlySplit}
        viewList={viewList}
        viewDetail={splitscreenComponent}
        onDismiss={onCloseSplit}
      />
      {deleteActionPending && (
        <YesNoCancelDialog
          title="Delete"
          body="Are you sure you want to delete? This is a permanent action."
          yesAction={() => {
            deleteConfirmPressed(
              deleteActionPending.entity,
              deleteActionPending.entityType
            )
          }}
          noAction={deleteCancelPressed}
          cancelAction={deleteCancelPressed}
        />
      )}
      {isLoading && (
        <View
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // right: 0,
            // bottom: 0,
            height: 100,
            width: 100,
            backgroundColor: 'transparent', // Lightly tinted
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 2,
            transform: [{ translateX: -50 }, { translateY: -50 }]
          }}
        >
          <ActivityIndicator size="large" color="#FF9933" />
          <Text>Loading...</Text>
        </View>
      )}
    </>
  ) : null
}
