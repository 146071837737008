import * as Sentry from "@sentry/browser";

import { ENVIRONMENT } from "@env";

import { Integrations } from "@sentry/tracing";

const sentryInit = () => {

  console.log(ENVIRONMENT, "Environment");
  if (ENVIRONMENT === "dev") {
    // disabled because it hijacks the debugger.
    return;
  }
  Sentry.init({
    dsn: "https://d2628a0ab96845d6a322091df35f50d2@o1133062.ingest.sentry.io/6179271",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.1,
  });
}

export const sentryIdentify = (email) => {

  Sentry.setUser({ email });

}

export default sentryInit;