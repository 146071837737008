import React from 'react';

const CrossFieldReferencesContext = React.createContext({ crossFieldReferences: undefined, setCrossFieldReferences: undefined});

/*

Usage:

In the callee, set (field name): { primaryAction: () => {}}

Per viewListInline

In the caller, call out to (field name): { primaryAction: () => {}} to run the primary action across field boundaries.

Per CustomFunctionalityTimesheetPunchCard

Able to add further properties (eg. not just primary action) if needed.

*/


export default CrossFieldReferencesContext