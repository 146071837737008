import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import spacing from '../styles/spacing'
import MSFESInput from './MSFESInput'
import _ from 'lodash'
import { SelectListMultiple } from './fields/SelectListMultiple'
import SelectWithData from './fields/SelectWithData'
import { SelectTenant } from './fields/SelectTenant'

export const FilterBar = ({
  filtersApplied = {},
  filtersAvailable = [],
  onFiltersChanged,
  filterEntityType,
  forceShowOptions = false
}) => {
  const [localFiltersApplied, setLocalFiltersApplied] = useState({})

  const transitions = useSelector((state) => state?.transitions)

  useEffect(() => {
    if (!_.isEqual(localFiltersApplied, filtersApplied)) {
      setLocalFiltersApplied(filtersApplied)
    }
  }, [])

  const [isFirstPass, setIsFirstPass] = useState(true)
  useEffect(() => {
    if (!isFirstPass) {
      onFiltersChanged && onFiltersChanged(localFiltersApplied)
    } else {
      setIsFirstPass(false)
    }
  }, [localFiltersApplied])

  return (
    <View style={{ flexDirection: 'row' }}>
      {(['modal+bar', 'bar'].includes(
        filterEntityType?.object_data?.filter_on_assignee?.key
      ) ||
        forceShowOptions) &&
        filtersAvailable
          .filter(
            (f) =>
              f.filterType === 'dropdown' || f.filterType === 'dropdown-boolean'
          )
          .filter(
            (f) =>
              _.get(f, 'field_data.params.displayFilterInHeader', false) ||
              f.field_data.property === 'assignee' ||
              (forceShowOptions && localFiltersApplied[f.field_data.property])
          )
          .map((f) => {
            return (
              <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                <MSFESInput
                  showLabel={false}
                  placeholder={f.field_data.title}
                  InputComponent={SelectListMultiple}
                  data={{ field: f }}
                  defaultValue={localFiltersApplied[f.field_data.property]}
                  onChangeText={(newValue) => {
                    setLocalFiltersApplied((filtersApplied) => {
                      if (_.get(newValue, 'id', false) === null) {
                        delete filtersApplied[f.field_data.property]
                      } else {
                        filtersApplied[f.field_data.property] = newValue
                      }
                      return { ...filtersApplied }
                    })
                  }}
                />
              </View>
            )
          })}

      {['modal+bar', 'bar'].includes(
        filterEntityType?.object_data?.filter_on_place?.key
      ) &&
        filtersAvailable
          .filter((f) => f.filterType === 'dropdown')
          .filter(
            (f) =>
              _.get(f, 'field_data.params.displayFilterInHeader', false) ||
              f.field_data.property === 'place'
          )
          .map((f) => {
            return (
              <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                <MSFESInput
                  showLabel={false}
                  placeholder={f.field_data.title}
                  InputComponent={SelectWithData}
                  dataRows={transitions?.places?.data.map((item) => ({
                    ...item,
                    id: item.name
                  }))}
                  keyField={'name'}
                  labelField={'name'}
                  value={localFiltersApplied[f.field_data.property]}
                  defaultValue={localFiltersApplied[f.field_data.property]}
                  onChangeText={(newValue) => {
                    setLocalFiltersApplied((filtersApplied) => {
                      filtersApplied[f.field_data.property] = newValue
                      return { ...filtersApplied }
                    })
                  }}
                />
              </View>
            )
          })}

      {['modal+bar', 'bar'].includes(
        filterEntityType?.object_data?.filter_on_tenant?.key
      ) &&
        filtersAvailable
          .filter((f) => f.filterType === 'tenant')
          .filter((f) => f.field_data.property === 'tenant_id')
          .map((f) => {
            return (
              <View key={f.id} style={{ marginLeft: spacing.m2 }}>
                <MSFESInput
                  showLabel={false}
                  name={'tenant'}
                  label={'Tenant'}
                  placeholder={'Select Tenant'}
                  InputComponent={SelectTenant}
                  defaultValue={localFiltersApplied[f.field_data.property]}
                  onChangeText={(tenant) => {
                    setLocalFiltersApplied((filtersApplied) => {
                      filtersApplied[f.field_data.property] = tenant
                      return { ...filtersApplied }
                    })
                  }}
                />
              </View>
            )
          })}

      {(filtersAvailable ?? [])
        .filter((f) => f.filterType === 'dropdown-adhoc')
        .filter((f) =>
          _.get(f, 'field_data.params.displayFilterInHeader', false)
        )
        .map((f) => {
          return (
            <View key={f.id} style={{ marginLeft: spacing.m2, flex: 1 }}>
              <MSFESInput
                showLabel={false}
                placeholder={f.field_data.title}
                InputComponent={SelectWithData}
                dataRows={f.field_data?.params?.options}
                keyField={'key'}
                labelField={'label'}
                value={localFiltersApplied[f.field_data.property]}
                defaultValue={localFiltersApplied[f.field_data.property]}
                onChangeText={(newValue) => {
                  setLocalFiltersApplied((filtersApplied) => {
                    filtersApplied[f.field_data.property] = newValue
                    return { ...filtersApplied }
                  })
                }}
              />
            </View>
          )
        })}
    </View>
  )
}
