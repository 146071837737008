import React from 'react'

import { StyleSheet, View } from 'react-native'
import { Overlay } from 'react-native-elements'
import DrawerTenantSwitcher from './DrawerTenantSwitcher'

export const topRightPopoutMenuStyle = StyleSheet.create({
  overlayStyle: {
    position: 'absolute',
    padding: 0,
    right: 0,
    top: 70,
    minWidth: 200
  }
})

const UserTopNavMenuDropdown = ({ onBackdropPress }) => {
  return (
    <Overlay
      visible={true}
      onBackdropPress={onBackdropPress}
      overlayStyle={topRightPopoutMenuStyle.overlayStyle}
    >
      <View>
        <DrawerTenantSwitcher />
      </View>
    </Overlay>
  )
}

export default UserTopNavMenuDropdown
