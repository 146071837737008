import React, { useEffect, useState } from 'react'
import {
  Query,
  Builder,
  Utils as QbUtils,
  BasicFuncs
} from 'react-awesome-query-builder'

// For AntDesign widgets only:
import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig // or MaterialConfig or BasicConfig

import 'antd/dist/antd.css'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css' //optional, for more compact styles
import { supportedFieldTypes } from '../../libraries/supportedFieldTypes'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { sortListWithEntityType } from '../../libraries/entityTools'
import { roles } from '../fields/MultipleRolePicker'
import { listEntity } from '../../store/entity/actions'
import { CURRENT_USER_ENTITY_TYPE } from '../../libraries/supportedEntityTypeProperties'

// You need to provide your own config. See below 'Config format'

// You can load query value from your backend storage (for saving see `Query.onChange()`)

const resolveQueryBuilderTypeFromOurType = (field, store, dispatch, args) => {
  const type = _.get(field.field_data, 'type', 'single-text')

  const { additionalFieldsForQueryEditor = [] } = args
  const inlineOptions = _.get(
    field.field_data,
    'params.options',
    []
  ).map((el) => ({ title: el.label, value: el.label }))
  const sourceName = _.get(field.field_data, 'params.sourceName', '')

  const sourceNameFinal =
    type === 'select-personnel'
      ? 'personnel'
      : type === 'select-tenant'
      ? 'tenant'
      : sourceName

  const asyncFetchSettings = {
    useLoadMore: true,
    useAsyncSearch: true,
    asyncFetch: (search, offset) => {
      return dispatch(
        listEntity(
          { name: sourceNameFinal },
          { page: offset / 24 + 1, searchText: search ?? undefined }
        )
      ).then((response) => {
        return {
          values: [
            ...response.data.map((el) => ({
              title: el.display_name,
              value: '' + el.id
            })),
            ...additionalFieldsForQueryEditor
          ],
          hasMore: response.meta.current_page < response.meta.last_page
        }
      })
    }
  }
  switch (type) {
    case 'phone':
    case 'hidden':
    case 'single-text-mask':
    case 'single-text':
    case 'email':
    case 'full-name-entry':
      return { type: 'text' }
    case 'year-entry':
    case 'number-entry':
    case 'number-slider':
      return { type: 'number' }
    case 'date-picker':
    case 'date-time-picker':
      return {
        type: 'datetime',
        valueSources: ['func', 'value', 'field']
      }
    case 'boolean':
    case 'signature-pad':
    case 'yes-no':
      return { type: 'boolean' }
    case 'checkbox-multiselect-adhoc':
      return {
        type: 'multiselect',
        fieldSettings: {
          listValues: inlineOptions
        }
      }
    case 'select-role':
      return {
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: roles.map((el) => ({ title: el.label, value: el.value }))
        }
      }
    case 'select-tenant':
      return {
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: sortListWithEntityType(
            _.get(store.getState().entities, `${sourceNameFinal}.data`, []),
            null
          ).map((el) => ({ title: el.display_name, value: '' + el.id })),
          ...asyncFetchSettings
        }
      }

    case 'select-list':
    case 'radio-list':
    case 'select-personnel':
      return {
        type: 'select',
        valueSources: ['value'],
        fieldSettings: {
          listValues: sortListWithEntityType(
            _.get(store.getState().entities, `${sourceNameFinal}.data`, []),
            null
          ).map((el) => ({ title: el.display_name, value: '' + el.id })),
          ...asyncFetchSettings
        }
      }
    case 'select-adhoc':
    case 'radio-adhoc':
      return {
        type: 'select',
        fieldSettings: {
          listValues: inlineOptions
        }
      }
    default: {
      const fieldType = supportedFieldTypes.find((f) => f.type === type)

      if (fieldType.shouldHaveValue) {
        return { type: fieldType.queryFieldValueType ?? 'text' } //this is a rich object, and we expect it to have a value field.
      }
      return { type }
    }
  }
}
const getConfig = (entityType, store, dispatch, args) => {
  const state = store.getState()

  const statuses = state.transitions?.places?.data?.map((item) => ({
    title: item.metadata.title,
    value: item.name
  }))
  const fields = {}

  // not guaranteed sort based on platform, but may as well try it.
  _.sortBy(entityType.fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  ).forEach((f) => {
    fields[f.field_data.property] = {
      label: f.field_data.title,
      ...resolveQueryBuilderTypeFromOurType(f, store, dispatch, args)
    }
  })

  if (entityType.name === CURRENT_USER_ENTITY_TYPE.name) {
    // this is current user, so include personnel fields.
    const personnelEntityType = state.entityTypes?.data.find(
      (e) => e.name === 'personnel'
    )
    _.sortBy(personnelEntityType?.fields, (f) =>
      _.get(f, 'field_data.sort_order', 999)
    ).forEach((f) => {
      fields['personnel.' + f.field_data.property] = {
        label: 'Personnel.' + f.field_data.title,
        ...resolveQueryBuilderTypeFromOurType(f, store, dispatch, args)
      }
    })
    const tenantEntityType = state.entityTypes?.data.find(
      (e) => e.name === 'tenant'
    )
    _.sortBy(tenantEntityType.fields, (f) =>
      _.get(f, 'field_data.sort_order', 999)
    ).forEach((f) => {
      fields['client.' + f.field_data.property] = {
        label: 'Client.' + f.field_data.title,
        ...resolveQueryBuilderTypeFromOurType(f, store, dispatch, args)
      }
    })

    fields['client.id'] = {
      label: 'Client',
      ...resolveQueryBuilderTypeFromOurType(
        { field_data: { type: 'select-tenant' } },
        store,
        dispatch,
        args
      )
    }
  }

  fields['id'] = {
    label: 'ID',
    type: 'number'
  }

  fields['status'] = {
    label: 'Status',
    type: 'select',
    valueSources: ['value'],
    fieldSettings: {
      listValues: statuses
    }
  }

  const funcs = {
    ...BasicFuncs,
    start_of_month: {
      label: 'Start of Month',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          start_of_month: [{ now: [] }]
        }
      }
    },
    end_of_month: {
      label: 'End of Month',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          end_of_month: [{ now: [] }]
        }
      }
    },
    start_of_week: {
      label: 'Start of Week',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          start_of_week: [{ now: [] }]
        }
      }
    },
    end_of_week: {
      label: 'End of Week',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          end_of_week: [{ now: [] }]
        }
      }
    },
    start_of_year: {
      label: 'Start of Year',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          start_of_year: [{ now: [] }]
        }
      }
    },
    end_of_year: {
      label: 'End of Year',
      returnType: 'datetime',
      jsonLogic: (ref) => {
        return {
          end_of_year: [{ now: [] }]
        }
      }
    }
  }

  const builtConfig = {
    ...InitialConfig,
    funcs,
    fields
  }

  return builtConfig
}
const QueryBuilder = (props) => {
  const { onQueryChanged, store } = props

  const { additionalFieldsForQueryEditor } = props
  const dispatch = useDispatch()

  let initialValue = { id: QbUtils.uuid(), type: 'group' }

  const [entityType, setEntityType] = useState(null)

  const [state, setState] = useState(null)

  useEffect(() => {
    if (props.entityType && !entityType) {
      setEntityType(props.entityType)

      const config = getConfig(props.entityType, store, dispatch, {
        additionalFieldsForQueryEditor
      })

      let initialRuleset = _.get(props.action, 'ruleset', initialValue)

      // we are casting the stored values (the ID) to strings for the lookup to work
      // correctly in this library. HRFIR-605.
      if (initialRuleset.children1) {
        try {
          initialRuleset.children1?.forEach((toplevelChildren) => {
            let children = toplevelChildren.children1
            if (children) {
              children.forEach((child) => {
                child.properties.value = [
                  ...child.properties.value.map((p) => '' + p)
                ]
                child.properties.asyncListValues = [
                  ...child.properties.asyncListValues.map((v) => ({
                    ...v,
                    value: '' + v.value
                  }))
                ]
              })
            }
          })
        } catch (e) {
          console.warn(e)
        }
      }

      const tree = QbUtils.checkTree(QbUtils.loadTree(initialRuleset), config)

      setState((oldState) => {
        const newConfig = {
          ...oldState,
          tree,
          config
        }

        return newConfig
      })
    }
  }, [props.entityType])

  const renderBuilder = (props) => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  )

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState({ tree: immutableTree, config: config })

    const jsonTree = QbUtils.getTree(immutableTree)

    const payload = {
      rules: jsonTree,
      json: QbUtils.jsonLogicFormat(immutableTree, config)
    }

    onQueryChanged(payload)
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }

  const shouldRender = !!state?.config

  return shouldRender ? (
    <div>
      {state.config && (
        <Query
          {...state.config}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
      )}
    </div>
  ) : null
}

export default QueryBuilder
