import { useState } from "react";
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

const useEditingReport = (entityType, entityTypeChanged) => {

  const [editingReport, setEditingReport] = useState(null)
  const editReport = (report) => {
    setEditingReport(report);
  }

  const cancelEditingReport = () => {
    setEditingReport(null);
  }

  const reportChanged = (report) => {
    // find this report by offline ID and replace it.
    const newEntityType = { ...entityType }
    const reports = _.get(newEntityType, 'data.reports', []);

    const index = reports.findIndex(a => a.offline_id === report.offline_id);

    reports[index] = report;
    entityTypeChanged(newEntityType)


  }
  const addNewReport = () => {

    const newEntityType = { ...entityType }
    const reports = newEntityType.data.reports ?? [];
    const newReport = {
      offline_id: uuidv4(),
      enabled: true
    };

    reports.push(newReport)

    _.set(newEntityType, 'data.reports', reports)
    entityTypeChanged(newEntityType)

    editReport(newReport);
  }

  const deleteReport = () => {

    const newEntityType = { ...entityType }
    const reports = _.get(newEntityType, 'data.reports', []);

    const index = reports.findIndex(a => a.offline_id === editingReport.offline_id);
    reports.splice(index, 1);

    _.set(newEntityType, 'data.reports', reports)
    entityTypeChanged(newEntityType)

    cancelEditingReport();
  }

  return [ editingReport, editReport, deleteReport, cancelEditingReport, reportChanged, addNewReport ]
}

export default useEditingReport