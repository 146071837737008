import React, { useState } from 'react'
import { View } from 'react-native'
import { ListItem, Overlay } from 'react-native-elements'
import { listItemContainerStyle } from '../EntityTypeActions'
import { Dropdown } from './Dropdown'
import EntityAddComponent from '../../screens/EntityAddComponent'

export const CreateDropdown = ({ onOptionChanged }) => {
  const [dropdownVisibleState, setDropdownVisibleState] = useState(true)
  const [showGeneralTask, setShowGeneralTask] = useState(false)

  const onSelected = (option) => {
    onOptionChanged(option)
    setDropdownVisibleState(false)
  }

  const handleTaskPressed = () => {
    setShowGeneralTask(true)
  }

  return (
    <Dropdown variant="primary" title={`Create`} visible={dropdownVisibleState}>
      <View>
        <ListItem
          onPress={() => onSelected('entity')}
          disabled={false}
          containerStyle={listItemContainerStyle}
        >
          <ListItem.Content>
            <ListItem.Title>New Action</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={handleTaskPressed}
          disabled={false}
          containerStyle={listItemContainerStyle}
        >
          <ListItem.Content>
            <ListItem.Title>New Task</ListItem.Title>
          </ListItem.Content>
        </ListItem>
        <ListItem
          onPress={() => onSelected('schedule_item')}
          disabled={false}
          containerStyle={listItemContainerStyle}
        >
          <ListItem.Content>
            <ListItem.Title>New Recurring Event</ListItem.Title>
          </ListItem.Content>
        </ListItem>
      </View>
      {showGeneralTask && (
        <Overlay
          isVisible={showGeneralTask}
          onBackdropPress={() => setShowGeneralTask(false)}
        >
          <EntityAddComponent
            onCloseSplit={() => setShowGeneralTask(false)}
            route={{
              params: {
                type: 'general_task'
              }
            }}
            saveWithTaskLevelValidation={true}
            savedCallback={() => {
              setShowGeneralTask(false)
            }}
          />
        </Overlay>
      )}
    </Dropdown>
  )
}
