// libraries
import React, { useState, useEffect } from 'react'
import { ListItem, SearchBar } from 'react-native-elements'
import { ScrollView, View } from 'react-native'
import _ from 'lodash'
import tenantActions from '../store/tenant/actions'
import userActions from '../store/user/actions'
// styles

//actions

import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'react-native-flash-message'
import { ActivityIndicator } from 'react-native'
import { Overlay } from 'react-native-elements'
import OverlayHeader from './OverlayHeader'
import { DrawerItem } from '@react-navigation/drawer'
import shared from '../styles/shared'
import MSFESIcon from './MSFESIcon'
import { resetCalendarState } from '../store/calendar/actions'
import {
  searchBarContainerStyle,
  searchBarInputContainerStyle
} from './WithHeader'
import { useTenantOrGlobalAdmin } from '../hooks/useTenantOrGlobalAdmin'
import { setFeatureFlag } from '../store/app/actions'
import { getPublicRoleName } from './fields/MultipleRolePicker'
import {
  dumpStoredEntityTypes,
  listLiteEntityTypes
} from '../store/entity-type/actions'

// components

const modalOverlayStyle = { padding: 0, margin: 0, maxHeight: '50%' }
const DrawerTenantSwitcher = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(tenantActions.listTenants())
  }, [])
  const tenants = useSelector((state) => {
    return _.get(state, 'tenants.tenants.data', [])
  })

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const { features } = useSelector((appState) => {
    return { features: appState?.app?.features }
  })

  const [loading, setLoading] = useState(false)

  const [showTenantPicker, setShowTenantPicker] = useState(false)
  const [showRolePicker, setShowRolePicker] = useState(false)

  /* eslint-disable-next-line no-unused-vars */
  const requestRoleTokenFor = (tenant, role) => {
    setLoading(role.id)
    return dispatch(userActions.selectTenant({ tenant, role })).then(() => {
      setLoading(false)
      setShowTenantPicker(false)
      setShowRolePicker(false)
      dispatch(listLiteEntityTypes())
      dispatch(dumpStoredEntityTypes())
      dispatch(resetCalendarState())
      showMessage({
        message: 'Selected tenant: ' + tenant.name + ', role: ' + role.name,
        type: 'success'
      })
    })
  }
  const requestTokenFor = (tenant) => {
    setLoading(tenant.id)
    return dispatch(userActions.selectTenant({ tenant })).then(() => {
      setLoading(false)
      setShowTenantPicker(false)
      dispatch(resetCalendarState())
      dispatch(listLiteEntityTypes())
      dispatch(dumpStoredEntityTypes())
      showMessage({
        message: 'Selected tenant: ' + tenant.name,
        type: 'success'
      })
    })
  }
  const requestGlobalToken = () => {
    setLoading('global')
    return dispatch(userActions.requestGlobalToken()).then(() => {
      setLoading(false)
      setShowTenantPicker(false)
      dispatch(resetCalendarState())
      dispatch(listLiteEntityTypes())
      dispatch(dumpStoredEntityTypes())
      showMessage({ message: 'Admin level selected.', type: 'success' })
    })
  }

  const hasGlobalAdminRole = !!current_user.roles.find(
    (r) => r.name === 'MSFES User'
  )

  const current_tenant = current_user.current_tenant

  const tenantAccordionTitle = current_tenant ? current_tenant.name : 'Global'
  const roleAccordionTitle = current_user.acting_role
    ? current_user.acting_role
    : 'Role'

  const hasAnyOptions =
    tenants.length > 1 || hasGlobalAdminRole || current_user.roles.length > 1

  const [tenantSearchValue, setSearchValue] = useState('')

  const setShowDeleted = (status) => {
    dispatch(setFeatureFlag('showDeleted', status))
  }
  const onSearchClear = () => {
    setSearchValue('')
  }
  const tenantSearchTextChanged = (searchValue) => {
    setSearchValue(searchValue)
  }

  const { isGlobalAdmin } = useTenantOrGlobalAdmin()

  return (
    hasAnyOptions && (
      <View pointerEvents={'box-none'}>
        <View>
          {tenants.length > 1 && (
            <DrawerItem
              style={[shared.debugOff, { margin: 10 }]}
              label={'' + tenantAccordionTitle}
              focused={false}
              icon={() => (
                <View style={{ width: 20 }}>
                  <MSFESIcon name="users" size={18} type="font-awesome" />
                </View>
              )}
              onPress={() => setShowTenantPicker(true)}
            />
          )}
          <DrawerItem
            style={[shared.debugOff, { margin: 10 }]}
            label={'' + getPublicRoleName(roleAccordionTitle)}
            focused={false}
            icon={() => (
              <View style={{ width: 20 }}>
                <MSFESIcon name="users" size={18} type="font-awesome" />
              </View>
            )}
            onPress={
              current_user.roles?.length > 1
                ? () => setShowRolePicker(true)
                : undefined
            }
          />
          {isGlobalAdmin && (
            <DrawerItem
              style={[shared.debugOff, { margin: 10 }]}
              label={'Show Deleted'}
              focused={false}
              icon={() => (
                <View style={{ width: 20 }}>
                  <MSFESIcon
                    name={features?.showDeleted ? 'check-square' : 'square'}
                    size={18}
                    type="font-awesome"
                  />
                </View>
              )}
              onPress={() => setShowDeleted(!features?.showDeleted)}
            />
          )}
          {showRolePicker && (
            <Overlay
              isVisible={true}
              onBackdropPress={() => setShowRolePicker(false)}
              overlayStyle={modalOverlayStyle}
            >
              <OverlayHeader>Select Role</OverlayHeader>

              <View style={{ flex: 1 }}>
                <ScrollView>
                  {current_user.roles
                    .filter(
                      (r) =>
                        current_tenant?.id === r.pivot.tenant_id ||
                        !r.pivot.tenant_id
                    )
                    .map((r) => (
                      <ListItem
                        onPress={() => requestRoleTokenFor(current_tenant, r)}
                        key={r.id}
                      >
                        {loading && loading === r.id ? (
                          <ActivityIndicator />
                        ) : (
                          <ListItem.Title>
                            {getPublicRoleName(r.name)}
                          </ListItem.Title>
                        )}
                      </ListItem>
                    ))}
                </ScrollView>
              </View>
            </Overlay>
          )}
          {showTenantPicker && (
            <Overlay
              isVisible={true}
              onBackdropPress={() => setShowTenantPicker(false)}
              overlayStyle={modalOverlayStyle}
            >
              <OverlayHeader>Select Tenant</OverlayHeader>

              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row' }}>
                  <SearchBar
                    placeholder="Search"
                    lightTheme={true}
                    inputContainerStyle={searchBarInputContainerStyle}
                    containerStyle={searchBarContainerStyle}
                    onClear={onSearchClear}
                    value={tenantSearchValue}
                    onChangeText={tenantSearchTextChanged}
                  />
                </View>

                <ScrollView>
                  {tenants
                    .filter((t) => current_tenant?.id !== t.id)
                    .filter((t) => {
                      return tenantSearchValue
                        ? t.name
                            .toLowerCase()
                            .indexOf(tenantSearchValue.toLowerCase()) !== -1
                        : true
                    })
                    .map((t) => (
                      <ListItem onPress={() => requestTokenFor(t)} key={t.id}>
                        {loading && loading === t.id ? (
                          <ActivityIndicator />
                        ) : (
                          <ListItem.Title>{t.name}</ListItem.Title>
                        )}
                      </ListItem>
                    ))}

                  {hasGlobalAdminRole && current_tenant && (
                    <ListItem
                      onPress={() => requestGlobalToken()}
                      key={'global'}
                    >
                      {loading && loading === 'global' ? (
                        <ActivityIndicator />
                      ) : (
                        <ListItem.Title>Change to Global</ListItem.Title>
                      )}
                    </ListItem>
                  )}
                </ScrollView>
              </View>
            </Overlay>
          )}
        </View>
      </View>
    )
  )
}

export default DrawerTenantSwitcher
