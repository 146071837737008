import React from 'react'
import { View } from 'react-native'

import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import { getRenderStaticHeader } from './EntityEdit'
import MSFESHeading from './MSFESHeading'

const _MatrixContainer = (props, ref) => {
  const {
    mode,
    entity,
    prepopulatedFields,
    fileAppended,
    label,
    fieldChanged,
    definition
  } = props

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )

  // props.data.field.field_data.params.sourceName
  const myEntityType = definition

  const fields = _.get(myEntityType, 'fields', []).filter(
    (f) => f.field_data?.belongs_to_matrix === props.data?.field?.offline_id
  )

  const fieldsSorted = _.sortBy(fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  const useRowHeader = _.get(
    props,
    'data.field.field_data.params.useRowHeader',
    false
  )

  return (
    <View style={{ flexDirection: 'column' }}>
      <MSFESHeading
        style={{
          backgroundColor: 'transparent',
          paddingVertical: spacing.m2,
          paddingHorizontal: spacing.m1
        }}
      >
        {(label ?? '').toUpperCase()}
      </MSFESHeading>
      <View>
        {fieldsSorted.map((field, index) => {
          const errorMessage = _.get(
            errors,
            field.field_data.property,
            []
          ).join(',')

          let renderStaticHeader = getRenderStaticHeader(
            field,
            index,
            fieldsSorted
          )

          return (
            <EntityField
              horizontal
              style={{ marginRight: spacing.m0_5 }}
              showLabel={!useRowHeader}
              mode={mode}
              entity={entity}
              renderStaticHeader={renderStaticHeader}
              name={field.field_data.property}
              prepopulatedFieldValue={
                prepopulatedFields
                  ? prepopulatedFields[field.field_data.property]
                  : undefined
              }
              errorMessage={errorMessage}
              field={field}
              fileAppended={fileAppended}
              fieldChanged={fieldChanged}
              key={index}
            />
          )
        })}
      </View>
    </View>
  )
}
export const MatrixContainer = React.forwardRef(_MatrixContainer)
