


import React, { useEffect, useState } from "react";

import { View, Switch, StyleSheet } from 'react-native'

import colors from "../styles/colors";
import _ from 'lodash'


// display roles across the screen
// display permissions down the screen (sorted into categories somehow)

export default function PermissionTile(props) {
 
  const { permission, role, valueChanged, user, friendlyName } = props;

  const isGlobalUser = !user.current_tenant





  const rolePermission = permission && role.permissions.find(p => p.id === permission.id)
  const hasPermission = !!rolePermission

  useEffect(() => {
    if(hasPermission !== localValue){
      setLocalValue(hasPermission) //was changed from outside.
    }
  }, [hasPermission])


  const globally_set = _.get(rolePermission, 'globally_set', false)

  const [ hasChanged, setHasChanged ] = useState(false)

  const [ localValue, setLocalValue ] = useState(hasPermission)

  const onValueChange = () => {
    setHasChanged(true)
    valueChanged(!localValue)
    setLocalValue(!localValue)
  }
  return (


<View style={{borderColor: hasChanged ? colors.lightGreen : colors.white, borderWidth: StyleSheet.hairlineWidth }}><Switch disabled={globally_set && !isGlobalUser} onValueChange={onValueChange} value={localValue} />

</View> )
}