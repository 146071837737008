// libraries
import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { ListItem } from 'react-native-elements'
import ListItemAsFieldProps from './ListItemAsFieldProps'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
import Counter from 'react-native-counters'

export const nestedInputExtraProps = {
  inputInternalStyle: { backgroundColor: 'transparent' }
}

export const NumberCounter = React.forwardRef((props, ref) => {
  const { defaultValue, editable, style } = props //child props

  let minimumValue = props.data?.field?.field_data?.validation?.min ?? 0
  if (!minimumValue) {
    minimumValue = 0
  }
  minimumValue = parseInt(minimumValue)
  let maximumValue = props.data?.field?.field_data?.validation?.max ?? 100
  if (!maximumValue) {
    maximumValue = 100
  }
  maximumValue = parseInt(maximumValue)

  const [localValue, setLocalValue] = useState(
    parseInt(defaultValue ?? minimumValue)
  )
  const wrappedSetLocalValue = (newValue) => {
    setLocalValue(newValue)
    // onChangeText(newValue, true)
  }

  const finished = useCallback(
    (args) => {
      props.onChangeText(args)
    },
    [localValue]
  )
  return (
    <View style={[style, { height: null }]}>
      <ListItem
        containerStyle={[ListItemAsFieldProps.containerStyle, { height: null }]}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <View
            style={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              padding: spacing.m1
            }}
          >
            <Counter
              start={localValue}
              max={maximumValue}
              onChange={(value) => {
                wrappedSetLocalValue(value)
                finished()
              }}
              buttonStyle={{
                borderColor: editable
                  ? colors.brightOrange
                  : colors.blueLighter,
                borderWidth: 2,
                borderRadius: 5
              }}
              buttonTextStyle={{
                color: editable ? colors.brightOrange : colors.blueLighter
              }}
              countTextStyle={{
                color: colors.dark
              }}
              disabled={!editable}
            />
          </View>
        </ListItem.Content>
      </ListItem>
    </View>
  )
})
