import React, { useState } from 'react'

import { View, Text } from 'react-native'
import { Overlay, ListItem } from 'react-native-elements'


export default function EntityListFilters({ isVisible, onBackdropPress, filtersApplied, onFiltersChanged, filtersAvailable }) {



  const [expandedItem, setIsExpandedItem] = useState(0)

  const toggleExpandedItem = (item) => {
    if (expandedItem === item) {
      setIsExpandedItem(-1)
    } else {
      setIsExpandedItem(item)
    }
  }


  const selectFilterOption = (filter, option) => {
    const newFilters = {...filtersApplied, [filter.id]: option.id}

    onFiltersChanged(newFilters)
  }
  return (
    isVisible ? <Overlay isVisible={true} onBackdropPress={onBackdropPress}>
      <View>

        {filtersAvailable.map((filter, filterIndex) => {
          const isExpanded = expandedItem === filterIndex;
          return <ListItem.Accordion
              key={filter.id}
              isExpanded={isExpanded}
              onPress={() => {
                toggleExpandedItem(filterIndex)
              }}
              content={
                  <ListItem.Content>
                    <ListItem.Title>{filter.name}</ListItem.Title>
                  </ListItem.Content>
              }
            >
              {filter.options.map((option, optionIndex) => {
                const isChecked = filtersApplied ? filtersApplied[filter.id] === option.id : option.default
                return <ListItem key={option.id} bottomDivider onPress={() => selectFilterOption(filter, option)}>
                  <ListItem.Content>
                    <ListItem.Title>{option.label}</ListItem.Title>
                  </ListItem.Content>
                  <ListItem.CheckBox checked={isChecked}  onPress={() => selectFilterOption(filter, option)} />
                </ListItem>
        })}
            </ListItem.Accordion>
        })}

      </View>
    </Overlay> : null

  )
}