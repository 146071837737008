import React, { useMemo } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { EntityTypesUnderCategory } from '../components/categories/EntityTypesUnderCategory'
import { TreeCategoriesMatrix } from '../components/categories/TreeCategoriesMatrix'
import spacing from '../styles/spacing'
import { useSelector } from 'react-redux'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import { getFilterString } from '../store/entity/actions'
import { noData } from '../components/HomeContainer'
import fonts from '../styles/fonts'
import _ from 'lodash'
import WithHeader from '../components/WithHeader'
export default function CategoryTreeScreen({ route, navigation }) {
  const { parent } = route.params

  const { entitiesFromStore } = useSelector((state) => {
    const args = { detailed: true },
      filter = {}
    const filterString = sanitiseFilterString(getFilterString(filter, args))
    return {
      entitiesFromStore: _.get(
        state.entities,
        `[${'tree_category' + filterString}]`,
        noData
      )
    }
  })

  const entityThisCategory = entitiesFromStore?.data?.find(
    (e) => e.display_name === parent
  )

  const categoryContainer = useMemo(() => {
    return (
      <WithHeader title="" leftButtonType="back" navigation={navigation}>
        <View
          style={[{ flex: 1, flexDirection: 'column', paddingTop: spacing.m2 }]}
        >
          <ScrollView style={{ flex: 1 }}>
            <View style={{ paddingBottom: spacing.m2 }}>
              <Text style={[{ textAlign: 'center' }, fonts.headerStyle]}>
                {entityThisCategory?.category_name}
              </Text>
            </View>

            <TreeCategoriesMatrix parentId={entityThisCategory?.id} />
            {!!entityThisCategory && (
              <View key={entityThisCategory.id}>
                <EntityTypesUnderCategory
                  header={entityThisCategory.category_name}
                  category={entityThisCategory.display_name}
                />
              </View>
            )}
          </ScrollView>
        </View>
      </WithHeader>
    )
  }, [route, navigation, entityThisCategory])

  return categoryContainer
}
