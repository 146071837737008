import React, { useMemo, useRef, useEffect } from 'react'
import { Icon } from 'react-native-elements'
import { View, Image } from 'react-native'
import colors from '../styles/colors'
import { GridItemButton } from './GridItemButton'
import shared from '../styles/shared'
import _ from 'lodash'
import { getSubtitle } from './ListItemEntity'

export const getIconOrImage = (entity, entityType) => {
  if (entity?.SYSTEM_display_picture) {
    return { type: 'image', source: entity.SYSTEM_display_picture }
  }
  if (entity?.icon) {
    return { type: 'icon', library: 'font-awesome', source: entity.icon }
  }

  if (entityType) {
    const entityTypeIcon = _.get(entityType, 'object_data.icon')
    return { type: 'icon', library: 'font-awesome', source: entityTypeIcon }
  }
}

export const getImage = (iconOrImage, imageSize = 36) => {
  if (iconOrImage?.type === 'image') {
    return (
      <Image
        resizeMode="contain"
        style={{
          width: imageSize,
          height: imageSize,
          justifyContent: 'center',
          alignSelf: 'center',
          borderRadius: shared.borderRadius
        }}
        source={{ uri: iconOrImage.source }}
        width={imageSize}
        height={imageSize}
      />
    )
  }
  if (iconOrImage?.type === 'icon') {
    return (
      <Icon
        type={iconOrImage.library}
        size={imageSize}
        color={colors.moduleIcon}
        name={iconOrImage.source}
      />
    )
  }
}
export const GridItemEntity = ({
  entity,
  entityType,
  alarmColor,
  style,
  onPress = null
}) => {
  const modalizeRef = useRef(null)

  const iconOrImage = useMemo(() => {
    const iconOrImage = getIconOrImage(entity, entityType)
    return iconOrImage
  }, [entity, entityType])

  const image = useMemo(() => {
    return getImage(iconOrImage, 32)
  }, [iconOrImage])

  useEffect(() => {
    modalizeRef.current?.open()
  }, [])

  const subtitle = getSubtitle(entity, entityType)

  return (
    <View style={style}>
      <GridItemButton
        alarmColor={alarmColor}
        image={image}
        title={entity.display_name}
        subtitle={subtitle}
        isGeneric={!entity.tenant_id}
        isDeleted={!!entity.deleted_at}
        linkTo={entity?.uri}
        onPress={onPress}
      />
    </View>
  )
}
