import api from '../../data/api'

const listTransitions = () => {
  return (dispatch) => {
    const route = '/workflow/transitions'

    dispatch({
      type: 'transition/list-started'
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'transition/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'transition/list-error',
          error
        })
        console.error(error);
        // throw error
      })
  }
}

const listPlaces = () => {
  return (dispatch) => {
    const route = '/workflow/places'

    dispatch({
      type: 'places/list-started'
    })
    return api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'places/list-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'places/list-error',
          error
        })

        console.error(error);
        // throw error
      })
  }
}
export default { listTransitions, listPlaces }
