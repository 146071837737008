import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { DateTimeToDisplayValue } from '../components/fields/DateTimePicker'
import wrappedAlert from '../helpers/alert'
import _ from 'lodash'
import { getOtherActiveQuizzes } from '../components/QuizQuestionGroupContainer'
import { v4 as uuidv4 } from 'uuid'
import { useLinkTo } from '@react-navigation/native'
import { updateMode } from '../screens/EntityAddComponent'

export const getFinishObjectFromStartedAtAndQuizTimeLimit = (
  startedAt,
  quizTimeLimit
) => {
  const startedAtDateTime = DateTime.fromISO(startedAt.value)

  const finishObject = startedAtDateTime.plus({
    minutes: quizTimeLimit
  })

  return finishObject
}

export const getMinutesRemaining = (finishObject) => {
  const diff = finishObject.diffNow('minutes')
  return Math.round(diff.values.minutes, 0)
}

export const useBeginQuiz = (
  entity,
  definition,
  field,
  onChange,
  saveEntity
) => {
  const [isBeginningQuiz, setIsBeginningQuiz] = useState(false)
  const [isFinishingQuiz, setIsFinishingQuiz] = useState(false)
  const [originalQuizObject, setOriginalQuizObject] = useState(null)

  const otherActiveQuizzes = useMemo(
    () => getOtherActiveQuizzes(entity, definition, field),
    [entity, definition, field]
  )

  const onFinishQuiz = () => {
    setIsFinishingQuiz(true)
    const nowObject = DateTime.now()

    debugger
    onChange({
      ...entity[field?.field_data?.property],
      finished_at: {
        display_value: DateTimeToDisplayValue(nowObject),
        value: nowObject.toISO()
      },
      is_active: false
    })

    setTimeout(() => {
      setIsFinishingQuiz(false)
    }, 5000)
  }

  const onBeginQuiz = () => {
    // if (!entity.id) {
    //   wrappedAlert(
    //     'Problem starting quiz',
    //     'Please save before beginning quiz.'
    //   )
    //   return
    // }

    // is there any other quizzes on the page? If so, are they active? If they are, do not start this quiz.

    //how do we know they're active?
    //1) they have a started_at and no finished_at
    //2) they have a started_at and the time available has not expired.

    if (otherActiveQuizzes.length) {
      wrappedAlert(
        'Problem starting quiz',
        'Please finish any other quizzes before beginning quiz.'
      )
      return
    }

    setIsBeginningQuiz(true)
    const nowObject = DateTime.now()

    const started_at = {
      display_value: DateTimeToDisplayValue(nowObject),
      value: nowObject.toISO(),
      offline_id: uuidv4()
    }
    const finishObject = getFinishObjectFromStartedAtAndQuizTimeLimit(
      started_at,
      field.field_data?.params.quizTimeLimit
    )
    const finishes_at = {
      display_value: DateTimeToDisplayValue(finishObject),
      value: finishObject.toISO()
    }

    const existingFieldValue = entity[field.field_data?.property]
    setOriginalQuizObject(existingFieldValue)

    onChange({
      ...existingFieldValue,
      started_at,
      finishes_at,
      is_active: true
    })

    setTimeout(() => {
      setIsBeginningQuiz(false)
    }, 5000)
  }

  const linkTo = useLinkTo()
  useEffect(() => {
    if (isBeginningQuiz && entity[field?.field_data?.property]) {
      // user just clicked begin, and we've got the updated prop for this container.
      if (!entity.id) {
        // doesn't exist - show the spinner.
        saveEntity({ showLoading: true, showToasts: true })
          .catch((e) => {
            // roll back to original.
            onChange(originalQuizObject)
            throw e
          })
          .then((response) => {
            linkTo(response.data.uri + '?mode=' + updateMode)
          })
      } else {
        saveEntity({ showLoading: false, showToasts: true })
      }
      setIsBeginningQuiz(false)
    }
    if (isFinishingQuiz && entity[field?.field_data?.property].finished_at) {
      // user just clicked begin, and we've got the updated prop for this container.
      debugger
      saveEntity({ showLoading: false, showToasts: true })
      setIsFinishingQuiz(false)
    }
  }, [entity])

  return [onBeginQuiz, otherActiveQuizzes, onFinishQuiz]
}
const useQuizTiming = (entity, field) => {
  const started_at = useMemo(() => {
    const startTimeObject = _.get(entity, field?.field_data?.property, null)

    return startTimeObject?.started_at
  }, [entity, field])

  const finishes_at = useMemo(() => {
    /* user has not started quiz. */
    if (!started_at) {
      return null
    }
    /* quiz is ongoing, or timed out */
    const finishObject = getFinishObjectFromStartedAtAndQuizTimeLimit(
      started_at,
      field.field_data?.params.quizTimeLimit
    )

    const time_remaining_minutes = getMinutesRemaining(finishObject)

    return {
      display_value: DateTimeToDisplayValue(finishObject),
      value: finishObject.toISO(),
      time_remaining_minutes
    }
  }, [started_at])

  const finished_at = useMemo(() => {
    /* user has finished the quiz themselves */
    const finishTimeObject = _.get(entity, field?.field_data?.property, null)

    if (finishTimeObject?.finished_at) {
      return { ...finishTimeObject?.finished_at, time_remaining_minutes: 0 }
    }

    if (finishes_at?.time_remaining_minutes <= 0) {
      return finishes_at
    }
  }, [started_at, finishes_at])

  return [started_at, finished_at, finishes_at]
}

export default useQuizTiming
