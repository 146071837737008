// libraries
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'react-native-elements'
import { View } from 'react-native'
import _ from 'lodash'

import { SelectWithData } from './SelectWithData'
import MSFESButton from '../MSFESButton'

export const DynamicTextFields = (props) => {
  const { changeHandler, fieldsSupplied, onBlur, style, editable } = props

  const fieldListRoot = useSelector((state) => {
    if (_.get(props, 'data.entity', null)) {
      return state.entityTypes.data.find(
        (entityType) =>
          entityType.id === props.data.entity.id ||
          entityType.name === props.data.entity.name
      )
    }
    return null
  })

  const fields = fieldsSupplied
    ? fieldsSupplied
    : _.get(fieldListRoot, 'fields', [])

  const [statefulValue, setStatefulValue] = useState(props.defaultValue)

  const updateValue = (newValue) => {
    setStatefulValue(newValue)
    !onBlur && changeHandler(newValue)
  }

  const keyField = 'field_data.property'
  const labelField = 'field_data.title'
  const valueField = 'field_data.title'
  const refText = useRef()
  return (
    <View style={[{ flex: 1, flexDirection: 'row' }, style]}>
      <View style={{ flex: 1 }}>
        <Input
          containerStyle={{ marginLeft: 0, paddingLeft: 0 }}
          style={{ fontSize: 12 }}
          value={statefulValue}
          onChangeText={updateValue}
          onBlur={onBlur}
          multiline={true}
          numberOfLines={5}
          ref={refText}
          disabled={!editable}
        />
      </View>
      <View>
        <SelectWithData
          dataRows={fields}
          value={''}
          placeholder={'+'}
          disabled={!editable}
          selectComponent={({ onPress }) => (
            <MSFESButton
              icon={{ type: 'font-awesome', name: 'plus' }}
              type="add"
              disabled={!editable}
              onPress={onPress}
            ></MSFESButton>
          )}
          labelField={labelField}
          keyField={keyField}
          valueField={valueField}
          onChangeText={(value) => {
            const val = _.get(value, keyField, '')
            val &&
              updateValue(
                (statefulValue == null ? '' : statefulValue) +
                  '' +
                  '{{' +
                  val +
                  '}}'
              )
            setTimeout(() => {
              refText.current.focus()
              refText.current.blur()
            }, 50)

            // we blur when a field is selected.
          }}
        />
      </View>
    </View>
  )
}
