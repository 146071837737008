import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  LayoutAnimation
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Icon, SearchBar } from 'react-native-elements'
import colors from '../styles/colors'
import shared from '../styles/shared'
import spacing from '../styles/spacing'
import notificationActions from '../store/notification/actions'
import MSFESSvgIcon from '../icons/MSFESSvgIcon'
import MSFESAvatar from './MSFESAvatar'
import entityActions from '../store/entity/actions'
import SearchResultsView from './SearchResultsView'
import MSFESIcon from './MSFESIcon'
import UserTopNavMenuDropdown from './UserTopNavMenuDropdown'
import _ from 'lodash'
import { APP_NAME } from '../helpers/appName'
import TopNavButtonHousing from './TopNavButtonHousing'
import { ROUTE_CALENDAR } from '../navigators/TabNavigator'

export const navWidth = 280

export const getNavColor = (environment) => {
  switch (environment) {
    case 'prod':
      return colors.blueDarkest
    case 'dev':
      return colors.iconShadow
    default:
      return colors.stagingRed
  }
}

const DesktopTopNav = ({ navigation }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(notificationActions.listUnreadNotifications())
  }, [])

  const { user, unreadNotificationsCount, environment } = useSelector(
    (state) => {
      const user = _.get(state, 'users.user', {})
      const unreadNotificationsCount = _.get(
        state.notifications,
        'unread_notifications.meta.total',
        null
      )
      const environment = _.get(state, 'app.environment', null)
      return { user, unreadNotificationsCount, environment }
    }
  )

  const [displayPicture, setDisplayPicture] = useState(null)
  const [searchResults, setSearchResults] = useState(null)
  const [searchVisible, setSearchVisible] = useState(false)
  const [userTopNavVisible, setUserTopNavVisible] = useState(false)

  const setSearchVisibleAnimated = (newState) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setSearchVisible(newState)
  }

  useEffect(() => {
    setDisplayPicture(
      _.get(user, 'personnel_record.SYSTEM_display_picture', null)
    )

    // runSearchQuery("rhy")
  }, [user])

  const [localSearchText, setLocalSearchText] = useState('')

  const runSearchQuery = (searchTerm) => {
    if (searchTerm?.length > 2) {
      dispatch(entityActions.performSearch(searchTerm)).then((results) => {
        setSearchResults({ searchTerm, results })
      })
    } else {
      setSearchResults(null)
    }
  }
  const runSearchQueryDebounced = useCallback(
    _.debounce(runSearchQuery, 200),
    []
  )

  const onChangeSearchText = (newSearch) => {
    setLocalSearchText(newSearch)

    runSearchQueryDebounced(newSearch)
  }

  const iconWidthArea = 32
  const topNavHeight = 70
  const svgHeight = iconWidthArea - 4

  const navColor = getNavColor(environment)

  return (
    <View
      style={{
        backgroundColor: navColor,
        height: topNavHeight,
        flexDirection: 'row'
      }}
    >
      <View
        style={[
          shared.debugOff,
          { width: navWidth, alignContent: 'flex-start', flexDirection: 'row' }
        ]}
      >
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => navigation.navigate('Home')}
        >
          <Image
            source={require('../images/phoenix_on_dark_256.png')}
            style={{ height: 50, width: 50, margin: 6 }}
            resizeMode="contain"
          />

          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text style={{ color: colors.white, fontSize: 18 }}>
              {APP_NAME}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={[shared.debugOff, { flex: 1, justifyContent: 'center' }]}>
        {searchVisible && (
          <View>
            <SearchBar
              placeholder="Search"
              onClear={() => {}}
              lightTheme={false}
              inputContainerStyle={[
                { backgroundColor: colors.inputBackground, marginHorizontal: 8 }
              ]}
              containerStyle={[
                {
                  backgroundColor: navColor,
                  borderWidth: 0,
                  margin: 0,
                  borderBottomColor: 'transparent',
                  borderTopColor: 'transparent'
                }
              ]}
              showCancel={false}
              value={localSearchText}
              onChangeText={onChangeSearchText}
            />
            <TouchableOpacity
              onPress={() => setSearchVisibleAnimated(false)}
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                height: '100%',
                width: 64,
                justifyContent: 'center'
              }}
            >
              <Icon
                type="font-awesome"
                name="times"
                size={24}
                color={colors.blueDarkest}
              />
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View
        style={[
          shared.debugOff,
          { flexDirection: 'row', width: navWidth, justifyContent: 'flex-end' }
        ]}
      >
        {!searchVisible && (
          <View
            style={[
              { justifyContent: 'center', marginHorizontal: spacing.m2 },
              shared.debugOff
            ]}
          >
            <View
              style={[
                {
                  width: iconWidthArea,
                  height: iconWidthArea,
                  marginTop: 9,
                  alignItems: 'center',
                  position: 'relative'
                },
                shared.debugOff
              ]}
            >
              <MSFESIcon
                name="search"
                type="font-awesome"
                color={colors.brightOrange}
                onPress={() => setSearchVisibleAnimated(!searchVisible)}
              />
            </View>
          </View>
        )}

        {!searchVisible && (
          <View
            style={[
              {
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: spacing.m1
              },
              shared.debugOff
            ]}
          >
            <TopNavButtonHousing navigation={navigation} />
          </View>
        )}
        <View
          style={[
            { justifyContent: 'center', marginRight: spacing.m2 },
            shared.debugOff
          ]}
        >
          <View
            style={[
              {
                width: iconWidthArea,
                height: iconWidthArea,
                marginTop: 9,
                alignItems: 'center',
                position: 'relative'
              },
              shared.debugOff
            ]}
          >
            <MSFESIcon
              name="calendar"
              type="font-awesome"
              color={colors.brightOrange}
              onPress={() => {
                const state = navigation.getRootState()
                if (
                  state.routes[0]?.state?.index &&
                  state.routes[0]?.state?.index !== 0
                ) {
                  navigation.navigate(ROUTE_CALENDAR)
                } else {
                  navigation.navigate(ROUTE_CALENDAR)
                }
              }}
            />
          </View>
        </View>
        <View
          style={[
            { justifyContent: 'center', marginRight: spacing.m2 },
            shared.debugOff
          ]}
        >
          <TouchableOpacity
            style={[
              {
                width: iconWidthArea,
                height: iconWidthArea,
                marginTop: 16,
                alignItems: 'center',
                position: 'relative'
              },
              shared.debugOff
            ]}
            onPress={() => {
              navigation && navigation.navigate('Notifications')
            }}
          >
            <MSFESSvgIcon
              name={'Bell'}
              fill="transparent"
              stroke={colors.brightOrange}
              strokeWidth="2"
              height={svgHeight}
              width={svgHeight}
              viewBox="0 0 40 40"
              preserveAspectRatio="xMinYMin slice"
            />
            {!!unreadNotificationsCount && (
              <Badge
                status="success"
                value={unreadNotificationsCount}
                containerStyle={{ position: 'absolute', top: -8, right: 0 }}
              />
            )}
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          style={[
            shared.debugOff,
            {
              justifyContent: 'center',
              flexDirection: 'row',
              marginRight: spacing.m2
            }
          ]}
          onPress={() => setUserTopNavVisible(true)}
        >
          <MSFESAvatar
            source={displayPicture}
            showLabel={false}
            label={user.display_name}
            textProps={{ h4: true }}
            size="small"
            outerContainerStyle={{
              justifyContent: 'center',
              marginRight: spacing.m2
            }}
            titleStyle={{ color: colors.avatarText, fontSize: 16 }}
          />
          <View style={{ justifyContent: 'center', flexDirection: 'column' }}>
            <Text style={{ color: colors.white, fontWeight: 'bold' }}>
              {user.display_name}
            </Text>
            <Text
              style={{
                color: colors.brightOrange,
                fontWeight: 'bold',
                fontSize: 10,
                paddingTop: 4
              }}
            >
              {user.current_tenant?.name}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      {/* <Text>I'm top nav!</Text>
      <TouchableOpacity onPress={() => navigation.navigate("Home")}>
        <Text>Nav Searcht: {searchText}</Text>
      </TouchableOpacity> */}

      {userTopNavVisible && (
        <UserTopNavMenuDropdown
          onBackdropPress={() => setUserTopNavVisible(false)}
        />
      )}

      <SearchResultsView
        searchResults={searchResults}
        topNavHeight={topNavHeight}
        navWidth={navWidth}
      />
    </View>
  )
}

export default DesktopTopNav
