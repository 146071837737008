import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { View, FlatList, Text } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import appActions from '../store/app/actions'
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import WithHeader, { headerTextStyle } from '../components/WithHeader'
import CurrentlyNoItems from '../components/CurrentlyNoItems'

import fileActions from '../store/file/actions'
import { getDisplayLabelForEntity } from '../libraries/entityTools'
import { sanitiseFilterString } from '../libraries/sanitiseFilterString'
import entityActions, { getFilterString } from '../store/entity/actions'
import { noData } from '../components/HomeContainer'
import MSFESChip from '../components/MSFESChip'
import ListItemAsFieldProps from '../components/ListItemAsFieldProps'
import MSFESHeading from '../components/MSFESHeading'
import spacing from '../styles/spacing'
import DocumentReportList from '../components/DocumentReportList'
import { StackActions } from '@react-navigation/native'
import { useDropdownHelper } from '../useDropdownHelper'
import DocumentUploader from '../components/DocumentReportList/DocumentUploader'
import { Breadcrumb } from '../components/DocumentReportList/Breadcrumb'
import { FolderDropdown } from '../components/DocumentReportList/FolderDropdown'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'

const topLevelPath = {
  none: 'none',
  myDocuments: 'my',
  station: 'station',
  stations: 'stations'
}

const getDefaultViewState = () => {
  return {
    topLevelPath: topLevelPath.none,
    folderPath: [],
    currentStation: null,
    currentRootFolder: null
  }
}

export default function DocumentListScreen({ route, navigation }) {
  const dispatch = useDispatch()

  const args = { detailed: true },
    filter = {}

  const defaultQueryParams = { page: 1, searchText: null }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const { page, searchText } = queryParams

  const entityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  const { entityType: entityTypeFolder } = useDiscoverEntityEntityType(
    null,
    null,
    'folder'
  )

  const fetchItems = () => {
    dispatch(fileActions.listFiles({ filter, page, searchText })).then(() => {})
  }

  useEffect(() => {
    fetchItems()
    entityTypeFolder &&
      dispatch(entityActions.listEntity(entityTypeFolder, { ...args, filter }))
  }, [queryParams])

  const { filesWrapper, tenants, folders } = useSelector((appState) => {
    const filterString = sanitiseFilterString(getFilterString(filter, args))

    return {
      filesWrapper: _.get(appState.files, `[${filterString}]`, {
        data: null,
        meta: null
      }),
      tenants: _.get(appState, 'tenants.tenants.data', []),
      folders: _.get(appState.entities, `[folder${filterString}]`, noData)
    }
  })

  const localHeaderStyle = [...headerTextStyle, { paddingVertical: spacing.m2 }]

  const { data: fileItems } = filesWrapper

  const currentlyNoLabel = 'No documents found.'

  const searchTextChanged = (searchText) => {
    setQueryParams((oldQueryParams) => {
      return { ...oldQueryParams, page: 1, searchText }
    })
  }

  const [viewState, setViewState] = useState(getDefaultViewState())

  useEffect(() => {
    if (route.params?.topLevelPath === topLevelPath.none) {
      setViewState(getDefaultViewState())
    } else if (route.params?.topLevelPath) {
      const { topLevelPath } = route.params
      setViewState((viewState) => ({ ...viewState, topLevelPath }))
    }
    if (route.params?.currentStation && tenants) {
      const { currentStation } = route.params
      setViewState((viewState) => ({
        ...viewState,
        currentStation: tenants.find((t) => t.id == currentStation)
      }))
    }

    if (route.params?.folderPath && folders) {
      const { folderPath } = route.params
      const folderObjects = folderPath.split('|').map((folderId) => {
        return folders.data.find((f) => f.id === parseInt(folderId))
      })

      setViewState((viewState) => ({
        ...viewState,
        folderPath: folderObjects,
        currentRootFolder: folders.data.find(
          (f) => f.id == parseInt(route.params?.currentRootFolder)
        )
      }))
    }
  }, [route.params, tenants])

  const setCurrentRootFolder = (newRootFolder) => {
    const folderPath = viewState.folderPath

    const thisFolderIndex = folderPath.findIndex(
      (f) => f.id == newRootFolder.id
    )

    let newFolderPath

    // check if there's a node with the same parent ID as this. If there is, scrub it to there and push it.
    if (thisFolderIndex === -1) {
      const replaceAtIndex = folderPath.findIndex(
        (f) => f.parent_folder?.id === newRootFolder.parent_folder?.id
      )
      if (replaceAtIndex !== -1) {
        newFolderPath = [...folderPath.slice(0, replaceAtIndex), newRootFolder]
      } else {
        newFolderPath = [...folderPath, newRootFolder]
      }
    } else {
      newFolderPath = folderPath.slice(0, thisFolderIndex + 1)
    }

    navigation.dispatch(
      StackActions.push('DocumentList', {
        ...route.params,
        folderPath: newFolderPath.map((f) => f.id).join('|'),
        currentRootFolder: newFolderPath[newFolderPath.length - 1].id
      })
    )
  }

  // selectable: The folders under the one that has been pressed
  // available: The folders under the current lowest tree node
  // e.g. if you press a folder that is one back, you will see selectable.
  const [selectableSubfolders, setSelectableSubfolders] = useState(null)
  const { availableSubfolders, matchingEntityTypes } = useMemo(() => {
    const availableSubfolders = viewState.currentRootFolder
      ? folders.data.filter(
          (f) => f.parent_folder?.id === viewState.currentRootFolder.id
        )
      : []
    const matchingEntityTypes = viewState.currentRootFolder
      ? entityTypes?.filter(
          (et) =>
            et.object_data?.document_folder?.id ==
            viewState.currentRootFolder.id
        )
      : []

    return { availableSubfolders, matchingEntityTypes }
  }, [viewState.currentRootFolder])

  const { gridOrList } = useSelector((appState) => {
    return {
      gridOrList: _.get(appState.app, 'gridOrList', 'grid')
    }
  })

  const toggleGridOrList = () => {
    let nextGridOrList
    switch (gridOrList) {
      case 'grid':
        nextGridOrList = 'list'
        break
      case 'list':
        nextGridOrList = 'table'
        break
      case 'table':
        nextGridOrList = 'grid'
        break
    }

    dispatch(appActions.setGridOrList(nextGridOrList))
  }

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(240, 0)

  return (
    <WithHeader
      title="Document Library"
      hasTabNavigatorPadding={false}
      navigation={navigation}
      rightButtonAction={null}
      gridOrList={gridOrList}
      onToggleGridOrList={toggleGridOrList}
      onSearchTextChanged={searchTextChanged}
      // searchComponent={<SearchBar
      //   placeholder="Search"
      //   onClear={onSearchCancel}
      //   lightTheme={true}
      //   inputContainerStyle={[
      //     { backgroundColor: colors.inputBackground, marginHorizontal: 8 }
      //   ]}
      //   containerStyle={[{ backgroundColor: colors.headerBackground }]}
      //   value={queryParams.searchText}
      //   onChangeText={searchTextChanged}
      // />}
    >
      <View style={{ flex: 1 }}>
        {viewState.topLevelPath === topLevelPath.none && (
          <View style={{ marginHorizontal: spacing.m2 }}>
            <ListItemLinkButton
              onPress={() => {
                navigation.dispatch(
                  StackActions.push('DocumentList', {
                    topLevelPath: topLevelPath.myDocuments
                  })
                )
              }}
              title={'My Documents'}
              containerStyle={ListItemAsFieldProps.containerStyle}
              showChevron={true}
            />
            <ListItemLinkButton
              onPress={() => {
                navigation.dispatch(
                  StackActions.push('DocumentList', {
                    topLevelPath: topLevelPath.stations
                  })
                )
              }}
              title={'Station Documents'}
              containerStyle={ListItemAsFieldProps.containerStyle}
              showChevron={false}
            />
          </View>
        )}

        {viewState.topLevelPath === topLevelPath.myDocuments && (
          <View style={{ flex: 1 }}>
            <Text style={localHeaderStyle}>My Documents</Text>
            <FlatList
              contentContainerStyle={{ alignContent: 'flex-start' }}
              ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
              keyExtractor={(item) => item.id.toString()}
              data={fileItems}
              renderItem={({ item, index }) => {
                return (
                  <ListItemLinkButton
                    entity={item}
                    title={getDisplayLabelForEntity(item)}
                    key={index}
                    to={item.uri}
                  />
                )
              }}
            />
          </View>
        )}

        {viewState.topLevelPath === topLevelPath.stations && (
          <View style={{ flex: 1 }}>
            <Text style={localHeaderStyle}>Select Station</Text>
            <StationSelector
              stations={tenants}
              onChange={(item) => {
                navigation.dispatch(
                  StackActions.push('DocumentList', {
                    topLevelPath: topLevelPath.station,
                    currentStation: item.id
                  })
                )
              }}
            />
          </View>
        )}

        {viewState.topLevelPath === topLevelPath.station && (
          <View style={{ flex: 1 }}>
            <View>
              <Text style={localHeaderStyle}>
                {viewState.currentStation?.name}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingHorizontal: spacing.m2,
                  paddingVertical: spacing.m2
                }}
              >
                <MSFESChip
                  onPress={() =>
                    navigation.dispatch(
                      StackActions.push('DocumentList', {
                        topLevelPath: topLevelPath.stations,
                        currentRootFolder: undefined,
                        folderPath: undefined
                      })
                    )
                  }
                >
                  {viewState.currentStation?.name}
                </MSFESChip>
                {!!viewState.currentRootFolder && (
                  <Text style={{ paddingTop: 8, paddingRight: 8 }}> / </Text>
                )}
                {viewState.folderPath.map((folder, index) => (
                  <Breadcrumb
                    key={index}
                    setCurrentRootFolder={setCurrentRootFolder}
                    folders={folders}
                    folder={folder}
                    isLast={index == viewState.folderPath.length - 1}
                    index={index}
                    onPress={() => {
                      const thisFolderSubfolders = folders.data.filter(
                        (f) => f.parent_folder?.id === folder.parent_folder?.id
                      )
                      setSelectableSubfolders({
                        folders: thisFolderSubfolders,
                        nestDepth: index
                      })
                    }}
                  />
                ))}

                {(!!availableSubfolders.length ||
                  !viewState.currentRootFolder) && (
                  <>
                    <Text style={{ paddingTop: 8, paddingRight: 8 }}> / </Text>
                    <View ref={buttonRef}>
                      <MSFESChip
                        onPress={() => {
                          setSelectableSubfolders({
                            folders: availableSubfolders,
                            nestDepth: viewState.folderPath.length
                          })
                          toggleDropdownState()
                        }}
                      >
                        Select...
                      </MSFESChip>
                    </View>
                    <FolderDropdown
                      folders={folders}
                      setCurrentRootFolder={setCurrentRootFolder}
                      selectableSubfolders={selectableSubfolders}
                      dropdownVisibilityState={dropdownVisibilityState}
                      dropdownOverlayStyle={dropdownOverlayStyle}
                      toggleDropdownState={toggleDropdownState}
                    />
                  </>
                )}
              </View>

              <View
                style={{
                  paddingHorizontal: spacing.m2
                }}
              >
                <DocumentUploader folder={viewState.currentRootFolder} />
              </View>
            </View>

            {viewState.currentRootFolder && (
              <>
                <MSFESHeading textStyle={{ marginHorizontal: spacing.m2 }}>
                  Documents
                </MSFESHeading>
                <DocumentReportList
                  folder={viewState.currentRootFolder}
                  relevantEntityTypes={matchingEntityTypes}
                />
              </>
            )}
            {/* <Text>{JSON.stringify(folders.data.filter(f => !f.parent_folder))}</Text> */}
          </View>
        )}
      </View>
    </WithHeader>
  )
}

const StationSelector = ({ stations, onChange }) => {
  const currentlyNoLabel = 'No documents found.'

  return (
    <FlatList
      contentContainerStyle={{ flex: 1 }}
      ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
      keyExtractor={(item) => item.id.toString()}
      data={stations}
      renderItem={({ item, index }) => {
        return (
          <ListItemLinkButton
            entity={item}
            title={getDisplayLabelForEntity(item)}
            key={index}
            onPress={() => {
              onChange(item)
            }}
          />
        )
      }}
    />
  )
}
