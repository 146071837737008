import React, { useEffect, useState } from 'react'
import { titleCase } from 'title-case'
import { Overlay } from 'react-native-elements'
import { Text, View } from 'react-native'
import { Card, List, Divider } from 'react-native-paper'
import MSFESInput from './MSFESInput'
import MSFESAccordion from './MSFESAccordion'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import MSFESButton from './MSFESButton'
import QueryBuilder from './query/QueryBuilder'
import { ListItemLinkButton } from './ListItemLinkButton'
import SelectWithData, { SelectField } from './fields/SelectWithData'
import { WrappedSwitch } from './EntityField'
import spacing from '../styles/spacing'
import { SelectPersonnel } from './fields/SelectPersonnel'
import { ScrollView } from 'react-native'
import { Dimensions } from 'react-native'
import OverlayHeader from './OverlayHeader'
import { SelectMultipleFields } from './fields/SelectMultipleFields'
import { SelectMultipleOptions } from './fields/SelectMultipleOptions'
import YesNoCancelDialog from './YesNoCancelDialog'
import { DynamicTextFields } from './fields/DynamicTextFields'
import TextLabel from './TextLabel'
import MSFESHeading from './MSFESHeading'

import { useStore } from 'react-redux'
import RichTextContainer from './RichTextContainer'
import { SelectListMultiple } from './fields/SelectListMultiple'
import { TextArrayField } from './fields/TextArrayField'
import { viewMode } from '../screens/EntityAddComponent'
import QueryEditorField from './fields/QueryEditorField'
import { MultipleRoleSelector } from './fields/MultipleRolePicker'
import RadioWithData from './fields/RadioWithData'

const triggerTypes = [
  {
    name: 'Check On Save',
    type: 'check-on-save'
  },
  { name: 'Check Daily', type: 'check-daily' }
  // { name: 'Check Weekly', type: 'check-weekly' }
]
const effectTypes = [
  {
    name: 'Update Related Property',
    type: 'update-related-property',
    effectType: 'executable'
  },
  { name: 'Email', type: 'email', effectType: 'executable' },
  { name: 'Fax', type: 'fax', effectType: 'executable' },
  { name: 'Hide Fields', type: 'hide-fields', effectType: 'observable' },
  { name: 'Show Fields', type: 'show-fields', effectType: 'observable' },
  { name: 'Hide Pages', type: 'hide-pages', effectType: 'observable' },
  { name: 'Show Pages', type: 'show-pages', effectType: 'observable' },
  {
    name: 'Set Property (Server)',
    type: 'setProperty',
    effectType: 'multiple-executable'
  },
  {
    name: 'Notify Service (Server)',
    type: 'notifyService',
    effectType: 'executable'
  }
]

const serviceTypes = [
  { name: 'BIB - Initiate Background Check', type: 'initiate-background-check' }
]

const ActionEditor = (props) => {
  const { action, onCancelEdit, onActionChanged, definition, mode } = props

  const [localAction, setLocalAction] = useState(null)
  const [deleteActionPending, setDeleteActionPending] = useState(null)
  const [deleteEffectPending, setDeleteEffectPending] = useState(null)
  const changeHandler = (property, value) => {
    const newAction = { ...localAction }
    if (typeof property === 'object') {
      for (var key in property) {
        _.set(newAction, key, property[key])
      }
    } else {
      _.set(newAction, property, value)
    }

    setLocalAction(newAction)
  }

  useEffect(() => {
    setLocalAction(action)
  }, [action])

  const onRulesChanged = ({ rules, json }) => {
    changeHandler({ ruleset: rules, ruleset_json: json })
  }

  const yesDeleteAction = () => {
    props.deleteAction()
    setDeleteActionPending(null)
  }

  const noDeleteAction = () => {
    setDeleteActionPending(null)
  }

  const yesDeleteEffect = () => {
    const eff = deleteEffectPending
    const effects = _.get(localAction, 'effects', [])

    effects.splice(
      effects.findIndex((e) => e.offline_id === eff.offline_id),
      1
    )

    setLocalAction({ ...localAction, effects })
    changeHandler('effects', effects)
    setDeleteEffectPending(null)
  }

  const noDeleteEffect = () => {
    setDeleteEffectPending(null)
  }

  const deleteAction = () => {
    setDeleteActionPending(true)
  }

  const getNewEffect = () => {
    return {
      offline_id: uuidv4()
    }
  }
  const addNewEffect = () => {
    const effects = _.get(localAction, 'effects', [])

    effects.push(getNewEffect())

    setLocalAction({ ...localAction, effects })
    changeHandler('effects', effects)
  }

  const deleteEffect = (eff) => {
    setDeleteEffectPending(eff)
  }

  const effectChangeHandler = (effectToUpdate, key, value) => {
    const effects = _.get(localAction, 'effects', [])
    const effect = effects.find(
      (e) => e.offline_id === effectToUpdate.offline_id
    )
    _.set(effect, key, value)
    setLocalAction({ ...localAction, effects })
    changeHandler('effects', effects)
  }

  const triggerChangeHandler = (newTriggers) => {
    changeHandler('triggers', newTriggers)
  }

  const willRender = !!(localAction && definition)

  const height = Dimensions.get('window').height

  const pageRows = []
  const pages = parseInt(_.get(definition, 'pages', 1))

  for (let i = 0; i < pages; i++) {
    pageRows.push({ id: i + 1, label: 'Page ' + (i + 1), value: i + 1 })
  }

  const store = useStore()

  const includedProps = { editable: mode !== viewMode }

  return (
    <View>
      {willRender ? (
        <Overlay isVisible={!!action} onBackdropPress={onCancelEdit}>
          <OverlayHeader>Editing Action {localAction?.name}</OverlayHeader>

          {deleteActionPending && (
            <YesNoCancelDialog
              title="delete action?"
              body="This will delete the action permanently. Continue?"
              yesAction={yesDeleteAction}
              noAction={noDeleteAction}
              cancelAction={noDeleteAction}
            />
          )}
          {deleteEffectPending && (
            <YesNoCancelDialog
              title="delete effect?"
              body="This will delete the effect permanently. Continue?"
              yesAction={yesDeleteEffect}
              noAction={noDeleteEffect}
              cancelAction={noDeleteEffect}
            />
          )}

          <ScrollView style={{ maxHeight: height * 0.8 }}>
            <List.AccordionGroup>
              <MSFESAccordion title="Properties" id="properties">
                <Card.Content>
                  <MSFESInput
                    horizontal
                    label="Action Name"
                    defaultValue={localAction.name}
                    name="name"
                    onChangeText={(text) => changeHandler('name', text)}
                    {...includedProps}
                  />
                  <MSFESInput
                    horizontal
                    label="Enabled?"
                    InputComponent={WrappedSwitch}
                    supportsInputShading={false}
                    name="enabled"
                    onValueChange={() => {
                      const newEnabled = !localAction.enabled
                      changeHandler('enabled', newEnabled)
                    }}
                    value={localAction.enabled}
                    {...includedProps}
                  />
                </Card.Content>
              </MSFESAccordion>
              <MSFESAccordion title="Rules" id="rules">
                <QueryBuilder
                  entityType={definition}
                  action={localAction}
                  store={store}
                  onQueryChanged={onRulesChanged}
                  {...includedProps}
                />
              </MSFESAccordion>
              <MSFESAccordion title="Effects" id="effects">
                <Card.Content>
                  <View style={[]}>
                    {_.get(localAction, 'effects', []).map((eff, index) => {
                      const effectDefinition = effectTypes.find(
                        (e) => e.type === eff.type
                      )

                      const field = definition.fields.find(
                        (f) => f.id == eff.relatedEntity?.field.id
                      )
                      const relatedEntityType = {
                        name: field?.field_data?.params?.sourceName
                      }

                      const relatableFields = definition.fields.filter(
                        (f) => !!f.field_data.params?.sourceName
                      )

                      return (
                        <Card.Content key={index}>
                          {!eff.type && [
                            <MSFESHeading key="header">
                              New Effect Type
                            </MSFESHeading>,
                            <MSFESInput
                              horizontal
                              key="type-select"
                              InputComponent={SelectWithData}
                              label={'Type'}
                              dataRows={effectTypes}
                              onChangeText={(newType) =>
                                effectChangeHandler(eff, 'type', newType.type)
                              }
                              placeholder={`(Select effect type)`}
                              value={eff.type}
                              valueField="type"
                              labelField="name"
                              keyField="type"
                              {...includedProps}
                            />
                          ]}
                          {eff.type && (
                            <List.AccordionGroup>
                              <MSFESAccordion
                                title={`${titleCase(eff.type)} Effect Details`}
                                id="details"
                              >
                                <MSFESInput
                                  horizontal
                                  InputComponent={TextLabel}
                                  label={'Effect Type'}
                                  enabled={false}
                                  value={effectDefinition.effectType}
                                  helpText={
                                    effectDefinition.effectType === 'observable'
                                      ? 'Observable effects can change fields, pages visible and change the validation behaviour on the server. They are checked each time a field is changed.'
                                      : 'Executable effects can only run once - the first time an entity is saved to the server and the rules evaluate to true. If the entity is saved again with true rules, the effect will not run.'
                                  }
                                  {...includedProps}
                                />
                                {eff.type === 'setProperty' && (
                                  <View>
                                    <MSFESInput
                                      label={'Set Property'}
                                      InputComponent={SelectField}
                                      fieldsSupplied={definition.fields}
                                      changeHandler={(fieldsList) =>
                                        effectChangeHandler(
                                          eff,
                                          'setProperty.property',
                                          fieldsList
                                        )
                                      }
                                      onChangeText={(fieldsList) => {
                                        effectChangeHandler(
                                          eff,
                                          'setProperty.property',
                                          fieldsList
                                        )
                                      }}
                                      placeholder={`Property to update`}
                                      value={eff['setProperty']?.property}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'notifyService' && (
                                  <View>
                                    <MSFESInput
                                      label={'Notify Service'}
                                      InputComponent={SelectWithData}
                                      dataRows={serviceTypes}
                                      onChangeText={(fieldsList) =>
                                        effectChangeHandler(
                                          eff,
                                          'notifyService.service',
                                          fieldsList.type
                                        )
                                      }
                                      placeholder={`Service to notify`}
                                      value={eff['notifyService']?.service}
                                      valueField="type"
                                      labelField="name"
                                      keyField="type"
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'update-related-property' && (
                                  <View>
                                    {/* <Text>F: {JSON.stringify(eff['relatedEntity']?.field)}</Text> */}
                                    <MSFESInput
                                      label={'Related Entity Field'}
                                      helpText="Field list is filtered to fields with an entity type as a source."
                                      InputComponent={SelectField}
                                      fieldsSupplied={relatableFields}
                                      onChangeText={(relatedEntity) =>
                                        effectChangeHandler(
                                          eff,
                                          'relatedEntity.field',
                                          relatedEntity
                                        )
                                      }
                                      placeholder={`Related Entity Field`}
                                      value={eff['relatedEntity']?.field}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'update-related-property' && (
                                  <View>
                                    <MSFESInput
                                      InputComponent={SelectField}
                                      label={
                                        'Field to Update in Related Entity'
                                      }
                                      key="select-related-field"
                                      fieldTypeFilter={[
                                        'formula-result',
                                        'select-personnel'
                                      ]}
                                      data={{
                                        entity: relatedEntityType
                                      }}
                                      onChangeText={(newType) => {
                                        effectChangeHandler(
                                          eff,
                                          'relatedEntity.fieldToUpdate',
                                          newType
                                        )
                                      }}
                                      value={
                                        eff['relatedEntity']?.fieldToUpdate
                                      }
                                      helpText={
                                        "Select the field that will be updated by this action. Fields are filtered to those which are 'Formula Result' type."
                                      }
                                      placeholder={`Select field to update`}
                                      valueField="field_data.property"
                                      labelField="field_data.title"
                                      keyField="id"
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'update-related-property' && (
                                  <View>
                                    <MSFESInput
                                      InputComponent={QueryEditorField}
                                      label={
                                        'When condition is true (blank for always)'
                                      }
                                      key="when-condition"
                                      data={{
                                        entity: {
                                          entity_type_target: relatedEntityType
                                        }
                                      }}
                                      changeHandler={(newType) => {
                                        effectChangeHandler(
                                          eff,
                                          'relatedEntity.whenConditionTrue',
                                          newType
                                        )
                                      }}
                                      defaultValue={
                                        eff['relatedEntity']?.whenConditionTrue
                                      }
                                      additionalFieldsForQueryEditor={definition?.fields.map(
                                        (f) => ({
                                          title:
                                            'Parent Field: ' +
                                            f.field_data?.title,
                                          value: `field.parent.${f.field_data?.property}` /* 'field.xyz' is resolved as the value in this field by evaluateRule. */
                                        })
                                      )}
                                      helpText={
                                        'Update the field when this child condition is true. Will not affect running of the action, only updating of the field.'
                                      }
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'update-related-property' && (
                                  <View>
                                    <MSFESInput
                                      InputComponent={DynamicTextFields}
                                      label={'Formula for Updated Value'}
                                      data={{
                                        entity: {
                                          id: definition.id,
                                          name: definition.name
                                        }
                                      }}
                                      key="formula-input"
                                      title={'Formula Input'}
                                      helpText={
                                        'Enter a formula to update this field contents.'
                                      }
                                      changeHandler={(newFormula) => {
                                        effectChangeHandler(
                                          eff,
                                          'relatedEntity.formula',
                                          newFormula
                                        )
                                      }}
                                      defaultValue={
                                        eff['relatedEntity']?.formula
                                      }
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'setProperty' && (
                                  <View>
                                    <MSFESInput
                                      label={'Value'}
                                      InputComponent={DynamicTextFields}
                                      fieldsSupplied={definition.fields}
                                      changeHandler={(expression) =>
                                        effectChangeHandler(
                                          eff,
                                          'setProperty.expression',
                                          expression
                                        )
                                      }
                                      placeholder={`Value to set`}
                                      defaultValue={
                                        eff['setProperty']?.expression
                                      }
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'email' && (
                                  <View>
                                    <MSFESInput
                                      horizontal
                                      label={'Email Subject'}
                                      onChangeText={(emailSubject) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.subject',
                                          emailSubject
                                        )
                                      }
                                      placeholder={`Email Subject`}
                                      value={eff.email?.subject}
                                      {...includedProps}
                                    />
                                    <MSFESInput
                                      InputComponent={RichTextContainer}
                                      label={'Email Body'}
                                      onChangeText={(emailBody) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.body',
                                          emailBody
                                        )
                                      }
                                      placeholder={`Email Body`}
                                      defaultValue={eff.email?.body}
                                      {...includedProps}
                                    />

                                    <MSFESInput
                                      label={'Internal Recipient/s'}
                                      defaultValue={_.castArray(eff.email?.to)}
                                      onChangeText={(value) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.to',
                                          value
                                        )
                                      }
                                      InputComponent={SelectListMultiple}
                                      data={{
                                        field: {
                                          field_data: {
                                            params: {
                                              ...{ sourceName: 'personnel' }
                                            }
                                          }
                                        }
                                      }}
                                      {...includedProps}
                                    />

                                    <MSFESInput
                                      horizontal
                                      InputComponent={MultipleRoleSelector}
                                      label="Role Recipient/s"
                                      defaultValue={_.castArray(
                                        eff.email?.toRoles
                                      )}
                                      onChangeText={(value) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.toRoles',
                                          value
                                        )
                                      }
                                      {...includedProps}
                                    />
                                    <MSFESInput
                                      InputComponent={TextArrayField}
                                      canExportImport={false}
                                      usesLabelsAndValues={false}
                                      placeholder={'Enter Email'}
                                      buttonLabel={'Add Email'}
                                      label={'External Recipient/s'}
                                      onChangeText={(externalTo) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.externalTo',
                                          externalTo
                                        )
                                      }
                                      value={eff?.email?.externalTo}
                                      {...includedProps}
                                    />

                                    <MSFESInput
                                      label={'Field List Recipient/s'}
                                      InputComponent={SelectField}
                                      helpText={
                                        "For each entity selected in this field, we will send an email to the 'email' property."
                                      }
                                      fieldTypeFilter={[
                                        'select-personnel',
                                        'select-list',
                                        'select-list-multiple',
                                        'radio-list',
                                        'checkbox-multiselect',
                                        'email'
                                      ]}
                                      fieldsSupplied={definition.fields}
                                      onChangeText={(fieldsList) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.fieldListTo',
                                          fieldsList
                                        )
                                      }
                                      placeholder={`Field List Recipient/s`}
                                      value={eff?.email?.fieldListTo}
                                      {...includedProps}
                                    />
                                    <MSFESInput
                                      horizontal={true}
                                      label={
                                        'Attach PDF of ' +
                                        definition.label +
                                        '?'
                                      }
                                      InputComponent={WrappedSwitch}
                                      supportsInputShading={false}
                                      onChangeText={(attachReport) =>
                                        effectChangeHandler(
                                          eff,
                                          'email.attachReport',
                                          attachReport
                                        )
                                      }
                                      onValueChange={(newValue) => {
                                        effectChangeHandler(
                                          eff,
                                          'email.attachReport',
                                          newValue
                                        )
                                      }}
                                      defaultValue={eff.email?.attachReport}
                                      value={eff.email?.attachReport}
                                      {...includedProps}
                                    />
                                  </View>
                                )}

                                {eff.type === 'fax' && (
                                  <View>
                                    <MSFESInput
                                      label={'Fax Subject'}
                                      onChangeText={(faxSubject) =>
                                        effectChangeHandler(
                                          eff,
                                          'fax.subject',
                                          faxSubject
                                        )
                                      }
                                      placeholder={`Fax Subject`}
                                      value={eff.fax?.subject}
                                      {...includedProps}
                                    />
                                    <MSFESInput
                                      label={'Fax Recipient'}
                                      InputComponent={SelectPersonnel}
                                      onChangeText={(faxTo) =>
                                        effectChangeHandler(
                                          eff,
                                          'fax.to',
                                          faxTo
                                        )
                                      }
                                      placeholder={`Fax Recipient`}
                                      defaultValue={eff.fax?.to}
                                      value={eff.fax?.to}
                                      {...includedProps}
                                    />
                                    <MSFESInput
                                      label={
                                        'Attach PDF of ' +
                                        definition.label +
                                        '?'
                                      }
                                      InputComponent={WrappedSwitch}
                                      supportsInputShading={false}
                                      value={true}
                                      {...includedProps}
                                    />
                                  </View>
                                )}

                                {eff.type === 'hide-fields' && (
                                  <View>
                                    <MSFESInput
                                      label={'Fields to hide'}
                                      horizontal
                                      InputComponent={SelectMultipleFields}
                                      fieldsSupplied={definition.fields}
                                      changeHandler={(fieldsList) =>
                                        effectChangeHandler(
                                          eff,
                                          'fields.list',
                                          fieldsList
                                        )
                                      }
                                      placeholder={`Fields to hide`}
                                      value={eff.fields?.list}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'show-fields' && (
                                  <View>
                                    <MSFESInput
                                      label={'Fields to show'}
                                      horizontal
                                      InputComponent={SelectMultipleFields}
                                      fieldsSupplied={definition.fields}
                                      changeHandler={(fieldsList) =>
                                        effectChangeHandler(
                                          eff,
                                          'fields.list',
                                          fieldsList
                                        )
                                      }
                                      placeholder={`Fields to show`}
                                      value={eff.fields?.list}
                                      {...includedProps}
                                    />
                                  </View>
                                )}

                                {eff.type === 'hide-pages' && (
                                  <View>
                                    <MSFESInput
                                      label={'Pages to hide'}
                                      horizontal
                                      InputComponent={SelectMultipleOptions}
                                      dataRows={pageRows}
                                      labelField="label"
                                      changeHandler={(pagesList) =>
                                        effectChangeHandler(
                                          eff,
                                          'pages.list',
                                          pagesList
                                        )
                                      }
                                      placeholder={`Pages to hide`}
                                      addPlaceholder="Add Page:"
                                      value={eff.pages?.list}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {eff.type === 'show-pages' && (
                                  <View>
                                    <MSFESInput
                                      label={'Pages to show'}
                                      horizontal
                                      InputComponent={SelectMultipleOptions}
                                      dataRows={pageRows}
                                      labelField="label"
                                      changeHandler={(pagesList) =>
                                        effectChangeHandler(
                                          eff,
                                          'pages.list',
                                          pagesList
                                        )
                                      }
                                      placeholder={`Pages to show`}
                                      addPlaceholder="Add Page:"
                                      value={eff.pages?.list}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                <View style={{ flex: 1 }}>
                                  <Divider />
                                  <View style={{ alignItems: 'flex-end' }}>
                                    <MSFESButton
                                      icon={{
                                        type: 'font-awesome',
                                        name: 'trash'
                                      }}
                                      type="delete"
                                      disabled={mode === viewMode}
                                      onPress={() => deleteEffect(eff)}
                                    ></MSFESButton>
                                  </View>
                                </View>
                              </MSFESAccordion>
                            </List.AccordionGroup>
                          )}
                        </Card.Content>
                      )
                    })}
                  </View>
                </Card.Content>

                <ListItemLinkButton
                  title={`Add new effect`}
                  type="add"
                  onPress={addNewEffect}
                />
              </MSFESAccordion>
              <MSFESAccordion title="Triggers" id="triggers">
                <Card.Content>
                  <View style={{ paddingVertical: spacing.m2 }}>
                    <Text>
                      An action is checked for truthiness when it is saved. This
                      is the default trigger. Change it below. Triggers are only
                      effective on Executable effects, not Observable effects.
                    </Text>

                    <MSFESInput
                      horizontal
                      key="type-select"
                      InputComponent={RadioWithData}
                      label={'Type'}
                      dataRows={triggerTypes}
                      onChangeText={(newTriggers) =>
                        triggerChangeHandler(newTriggers)
                      }
                      placeholder={`(Select effect type)`}
                      value={localAction?.triggers ?? { type: 'check-on-save' }}
                      valueField="type"
                      labelField="name"
                      keyField="type"
                      {...includedProps}
                    />
                  </View>
                </Card.Content>
              </MSFESAccordion>
            </List.AccordionGroup>

            <Card.Content>
              <View style={{ paddingVertical: spacing.m2 }}>
                <Text>
                  When the rule set above is true for the first time, these
                  effects will run in parallel.
                </Text>
              </View>
            </Card.Content>
          </ScrollView>

          <View style={{ flexDirection: 'row', paddingHorizontal: spacing.m2 }}>
            <View style={{ marginRight: spacing.m2 }}>
              <Divider />
              <View>
                <MSFESButton
                  icon={{ type: 'font-awesome', name: 'trash' }}
                  type="delete"
                  onPress={deleteAction}
                ></MSFESButton>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <MSFESButton
                onPress={() => {
                  onActionChanged(localAction)
                  onCancelEdit()
                }}
                title="Save Action"
              />
            </View>
          </View>
        </Overlay>
      ) : (
        <></>
      )}
    </View>
  )
}

export default ActionEditor
