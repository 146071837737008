import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import EntityField from '../../components/EntityField'
import MSFESHeading from '../../components/MSFESHeading'
import _ from 'lodash'
import MSFESInput from '../../components/MSFESInput'
import { groupContainerStyle } from '../../components/GroupContainer'
import spacing from '../../styles/spacing'
import SelectWithData from '../../components/fields/SelectWithData'
import { addMode } from '../EntityAddComponent'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'
export const Prefills = ({
  entityType: suppliedEntityType,
  prefillWrapper,
  onChange
}) => {
  // entityType={event.entityType} prefills={event?.prefills} onChange={newPrefills => changeHandler("prefills", newPrefills)}

  const [virtualEntity, setVirtualEntity] = useState(null)

  useEffect(() => {
    if (prefillWrapper) {
      setVirtualEntity(prefillWrapper.prefillEntity)
    }
  }, [prefillWrapper])
  useEffect(() => {
    onChange({
      prefillFields: prefillWrapper?.prefillFields,
      prefillEntity: virtualEntity
    })
  }, [virtualEntity])

  const { entityType } = useDiscoverEntityEntityType(
    null,
    null,
    null,
    suppliedEntityType?.id
  )

  const fillableFields = useMemo(() => {
    if (entityType && entityType?.fields) {
      const fields = [...entityType.fields]

      if (entityType?.object_data?.show_assignee || entityType?.is_task) {
        fields.push({
          title: 'Assigned To',
          field_data: {
            title: 'Assigned To',
            property: 'assignees',
            type: 'select-list-multiple',
            params: { sourceName: ['personnel'] }
          }
        })
      }

      return fields
    }
    return []
  }, [entityType])

  return (
    <View
      style={[
        ...groupContainerStyle,
        { width: '100%', marginBottom: spacing.m1, flex: 1 }
      ]}
    >
      <MSFESHeading
        style={{
          backgroundColor: 'transparent',
          paddingVertical: spacing.m2,
          paddingHorizontal: spacing.m1
        }}
      >
        Prefill fields
      </MSFESHeading>

      <View style={{ flex: 1 }}>
        <ScrollView>
          {prefillWrapper?.prefillFields?.map((p, index) => {
            return (
              <View key={index}>
                <EntityField
                  fieldIndex={index}
                  mode={addMode}
                  entity={virtualEntity}
                  definition={entityType}
                  name={p.field_data.property}
                  renderStaticHeader={false}
                  field={p}
                  fileAppended={() => {}}
                  fieldChanged={(field, fieldValue) => {
                    const { property } = field.field_data
                    // allows dot notation
                    setVirtualEntity((_virtualEntity) => {
                      let _virtualEntityNotNull = _virtualEntity || {}
                      _.set(_virtualEntityNotNull, property, fieldValue)

                      return { ..._virtualEntityNotNull }
                    })
                  }}
                  key={index + '-' + p.id}
                />
              </View>
            )
          })}
        </ScrollView>
        <MSFESInput
          key="fields-list"
          horizontal
          InputComponent={SelectWithData}
          disabled={!entityType}
          label={'Add Prefill Field'}
          dataRows={fillableFields}
          onChangeText={(newType) => {
            if (newType) {
              const existingPrefillFields = prefillWrapper.prefillFields ?? []

              existingPrefillFields.push(newType)
              onChange({
                prefillFields: existingPrefillFields,
                prefillEntity: virtualEntity
              })
            }
          }}
          simplifyObject={false}
          value={null}
          placeholder={`(Select field)`}
          valueField="field_data.property"
          labelField="field_data.title"
          keyField="field_data.property"
        />
      </View>
    </View>
  )
}
