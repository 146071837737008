import { useNetInfo } from '@react-native-community/netinfo'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { View, ActivityIndicator, Platform } from 'react-native'
import appActions from '../store/app/actions'
import _ from 'lodash'

import { useDispatch } from 'react-redux'
import PublicFormContext from '../contexts/PublicFormContext'
import entityTypeActions from '../store/entity-type/actions'
import EntityAddComponent, { addMode } from './EntityAddComponent'
import spacing from '../styles/spacing'
import { MSFESSuccessfulAnonymousSubmission } from '../components/MSFESSuccessfulAnonymousSubmission'
import { landedPublicUrlParams } from '../App'
import SplashScreen from 'react-native-splash-screen'

const PublicFormsAddScreen = ({ navigation, route }) => {
  const dispatch = useDispatch()
  const netInfo = useNetInfo()

  const [receivedRouteParams, setReceivedRouteParams] = useState(null)

  const intervalRef = useRef()

  useEffect(() => {
    if (Platform.OS !== 'web') {
      SplashScreen.hide()
    }
  }, [])

  useEffect(() => {
    dispatch(appActions.setNetInfo(netInfo))
  }, [netInfo])

  useEffect(() => {
    if (route.params) {
      setReceivedRouteParams(route.params)
    }
  }, [route])

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // native does not receive the URL params properly from the deep URL
      // we set this 'landedPublicUrlParams' in app.js
      // and catch it here.
      intervalRef.current = setInterval(() => {
        if (landedPublicUrlParams) {
          clearInterval(intervalRef.current)
          setReceivedRouteParams(landedPublicUrlParams)
        }
      }, 200)
    }
  }, [''])

  const entityTypeName = receivedRouteParams?.entityType

  const [localEntityType, setLocalEntityType] = useState(null)

  const [currentPage, updateCurrentPage] = useState(1)

  const [savedSuccessfully, setSavedSuccessfully] = useState(false)
  const onDismiss = () => {}

  const onEditToggled = () => {}

  useEffect(() => {
    if (receivedRouteParams) {
      dispatch(
        entityTypeActions.getEntityType(entityTypeName, true, {
          hmac: receivedRouteParams.hmac,
          tenantId: receivedRouteParams.tenantId
        })
      ).then((response) => {
        setLocalEntityType(response.data)
      })
    }
  }, [receivedRouteParams])

  const backgroundColor = useMemo(() => {
    return _.get(localEntityType, 'tenant_record.color', '#9a0223')
  }, [localEntityType])

  return localEntityType && !!receivedRouteParams ? (
    <PublicFormContext.Provider
      value={{
        public: true,
        entityType: localEntityType,
        tenantId: receivedRouteParams.tenantId,
        tenantHmac: receivedRouteParams.hmac
      }}
    >
      <View
        style={{
          backgroundColor,
          paddingTop: spacing.viewboxNavbarPadding,
          minHeight: Platform.OS == 'web' ? '100vh' : '100%'
        }}
      >
        {!savedSuccessfully && (
          <EntityAddComponent
            currentPage={currentPage}
            onUpdatePage={updateCurrentPage}
            navigation={navigation}
            isPublicForm={true}
            isInSplitView={true}
            onCloseSplit={onDismiss}
            onEditToggled={onEditToggled}
            route={{
              params: {
                type: localEntityType.name,
                mode: addMode,
                page: 1
              }
            }}
            style={{ backgroundColor: 'white' }}
            savedCallback={() => {
              setSavedSuccessfully(true)
            }}
          />
        )}
        {savedSuccessfully && <MSFESSuccessfulAnonymousSubmission />}
      </View>
    </PublicFormContext.Provider>
  ) : (
    <View style={{ flex: 1, marginTop: spacing.m4 }}>
      <ActivityIndicator />
    </View>
  )
}

export default PublicFormsAddScreen
