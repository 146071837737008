import React from 'react'
import { View, SafeAreaView, StatusBar, Image, KeyboardAvoidingView, Platform } from 'react-native'
import { Text, Divider } from 'react-native-elements'
import spacing from '../styles/spacing'
import colors from '../styles/colors'
import shared from '../styles/shared'
import { ScrollView } from 'react-native'
import MSFESInput from '../components/MSFESInput'
import MSFESButton from '../components/MSFESButton'
import WithHeader from '../components/WithHeader'
import { ListItemLinkButton } from '../components/ListItemLinkButton'
import { FlatList } from 'react-native'
import { ModuleButton } from '../components/ModuleButton'
import { EntityEdit } from '../components/EntityEdit'
import { Card } from 'react-native-paper'
import DemoQueryBuilder from '../components/query/DemoQueryBuilder'
import RichTextContainer from '../components/RichTextContainer'

export default function StoryBook({ route, navigation }) {

  const buttonPanelButtonStyle = Platform.OS === "web" ? { flex: 1, marginRight: spacing.m1, marginTop: spacing.m1 } : { flex: 1, marginTop: spacing.m1 }


  const entity = {
    "type": null,
    "mode": "edit",
    "files": [],
    "id": 5,
    "tenant_id": null,
    "entity_type_id": 3,
    "created_at": "2021-05-06T23:50:54.000000Z",
    "updated_at": "2021-05-06T23:50:54.000000Z",
    "place": null,
    "assignee_id": 0,
    "parent_entity_id": null,
    "search_string": "Rhys Admin",
    "due_at": null,
    "due_status": "n/a",
    "creator": null,
    "user": 1,
    "last_name": "Admin",
    "first_name": "Rhys",
    "email_address": "rhysw@hey.com",
    "is_global_admin": true,
    "uri": "/entity/personnel/5",
    "available_transitions": [],
    "display_name": "Rhys Admin",
    "parent": null,
    "children": [],
    "watching": false
}
  const definition = {
    "id": 3,
    "label_string": "{{first_name}} {{last_name}}",
    "name": "personnel",
    "search_string": "{{first_name}} {{last_name}}",
    "grouping_key": null,
    "label": "Personnel",
    "tenant_id": null,
    "is_module": true,
    "deleted_at": null,
    "created_at": "2021-05-06T23:50:53.000000Z",
    "updated_at": "2021-05-11T10:34:48.000000Z",
    "object_data": {
        "is_special": true
    },
    "pages": 1,
    "is_task": false,
    "date_significant": 0,
    "fields": [
        {
            "id": 12,
            "field_data": {
                "page": 1,
                "type": "hidden",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "User",
                "property": "user"
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 13,
            "field_data": {
                "page": 1,
                "type": "single-text",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "First Name",
                "property": "first_name",
                "validation": {
                    "min:2": true,
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 14,
            "field_data": {
                "page": 1,
                "type": "single-text",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Middle Initial",
                "property": "middle_initial",
                "validation": {
                    "required": false
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 15,
            "field_data": {
                "page": 1,
                "type": "single-text",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Last Name",
                "property": "last_name",
                "validation": {
                    "min:2": true,
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 16,
            "field_data": {
                "page": 1,
                "type": "email",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Email Address",
                "property": "email_address",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 17,
            "field_data": {
                "page": 1,
                "type": "phone",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Phone 1",
                "property": "phone1",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 18,
            "field_data": {
                "page": 1,
                "type": "radio-list",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Phone A Type",
                "params": {
                    "sourceName": "phone_type"
                },
                "property": "phoneAType",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 18,
            "field_data": {
                "page": 1,
                "type": "select-list",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Phone 1 Type",
                "params": {
                    "sourceName": "phone_type"
                },
                "property": "phone1Type",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 19,
            "field_data": {
                "page": 1,
                "type": "phone",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Phone 2",
                "property": "phone2",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 20,
            "field_data": {
                "page": 1,
                "type": "select-list",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Phone 2 Type",
                "params": {
                    "sourceName": "phone_type"
                },
                "property": "phone2Type",
                "validation": {
                    "required": true
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 21,
            "field_data": {
                "page": 1,
                "type": "select-tenant",
                "flags": {
                    "tableView": true,
                    "tableSortable": true
                },
                "title": "Tenant Identifier",
                "property": "tenant_id",
                "validation": {
                    "required": false
                }
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 22,
            "field_data": {
                "page": "1",
                "type": "boolean",
                "flags": {
                    "tableView": true,
                    "tableSortable": true,
                    "adminOrGlobalAdminOnly": true
                },
                "title": "Is Admin",
                "property": "is_admin"
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 23,
            "field_data": {
                "page": "1",
                "type": "boolean",
                "flags": {
                    "tableView": true,
                    "tableSortable": true,
                    "globalAdminOnly": true
                },
                "title": "Is Global Admin",
                "property": "is_global_admin"
            },
            "created_at": "2021-05-06T23:50:53.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": null
        },
        {
            "id": 47,
            "field_data": {
                "page": 1,
                "type": "container-row",
                "title": "Row Container",
                "params": {
                    "fieldList": [
                        {
                            "id": 13,
                            "offline_id": null
                        },
                        {
                            "id": 14,
                            "offline_id": null
                        },
                        {
                            "id": 15,
                            "offline_id": null
                        }
                    ]
                },
                "property": "row_container"
            },
            "created_at": "2021-05-11T11:52:34.000000Z",
            "updated_at": "2021-05-19T03:51:18.000000Z",
            "offline_id": "c7807314-81f7-4d49-b591-4c11b529247d"
        }
    ],
    "allowed_transitions": [
        {
            "name": "mark_as_created",
            "generates_tasks": []
        }
    ]
}

const prepopulatedFields = {}

const children=<View><Text>I'm child.</Text></View>
  return (
    <SafeAreaView style={[shared.fullHeight]}>
      <StatusBar barStyle="light-content" />
      <ScrollView>
        <View style={[shared.mobileWidthView, shared.darkView, shared.fullHeight]}>
          <View style={[shared.debugOff, { alignItems: "center", minHeight: 240 }]}><Image source={require("../images/msfes_logo.png")} style={{ width: "50%", maxWidth: 200, height: 200 }} resizeMode="contain" /></View>
          <KeyboardAvoidingView style={[{ flex: 1, justifyContent: "center" }]} behavior="padding">
            <View style={[]}>
              <MSFESInput
                label="Email Address"
                dark
                defaultValue={''}
                keyboardType={'email-address'}
                name="email"
                rightIcon={{ type: 'font-awesome', name: 'envelope', color: colors.iconLight }}
              />

              <MSFESInput
                label="Password"
                dark
                defaultValue={''}
                name="password"
                secureTextEntry={true}
                errorMessage={"An error."}
                onChangeText={(text) => {}}
                rightIcon={{ type: 'font-awesome', name: 'key', color: colors.iconLight }}
              />

              <Divider style={spacing.sectionDivider} />
              <MSFESButton
                /* icon={{
                   name: "arrow-right",
                   size: 15,
                   color: "white",
                   type: 'font-awesome',
                   style: spacing.iconRight
                 }
                 }
                 iconRight={true} */
                dark
                primary
                title="Sign In"
                onPress={() => { }}
              />

              <MSFESButton
                dark
                secondary
                title="Forgot Password"
                loading={true}
                onPress={() => { }}
              />

              <MSFESButton
                dark
                title="DEBUG: Storybook"
                onPress={() => {
                }}
              />
            </View>
          </KeyboardAvoidingView>

        </View>
        <View style={[shared.mobileWidthView]}>
          <WithHeader title={"Burger"}>
            <View><Text>(Simple)</Text></View>
            </WithHeader>
          <WithHeader title={"A Test!"} leftButtonType={"back"} onSearchTextChanged={() => { }} onDateChanged={() => { }}>
            <ListItemLinkButton
              title={"A title."}
              subtitle={"Subtitle."}
              timestamp={"2021-04-04"}
              entity={{ display_name: "Rhys W" }}
              key={'add'}
              to={'/'}
            />
            <ListItemLinkButton
              title={"A title."}
              avatarIcon={{ name:"500px", type: 'font-awesome' }}
              key={'add'}
              to={'/'}
            />
            <View style={[shared.debug]}>
            <ListItemLinkButton
              title={"A title."}
              avatarIcon={{ name:"500px", type: 'font-awesome' }}
              key={'add'}
              to={'/'}
            /></View>
            <View style={[shared.debug2]}>
            <ListItemLinkButton
              title={"A title."}
              avatarIcon={{ name:"500px", type: 'font-awesome' }}
              key={'add'}
              to={'/'}
            /></View>
            <View style={[shared.debugOff]}>
            <ListItemLinkButton
              title={"A title."}
              avatarIcon={{ name:"500px", type: 'font-awesome' }}
              key={'add'}
              to={'/'}
            /></View>


            <FlatList numColumns={3} data={[{ icon: false }, { icon: '500px' }]}
              renderItem={({ item, index, separators }) => {
                return <View style={[{ flex: 1 / 3 }]}><ModuleButton
                  key={0}
                  title={"A Title"}
                  showIcon={!!item.icon}
                  icon={{ name: item.icon, type: 'font-awesome' }}
                  to={'/'}
                /></View>
              }} />


            <MSFESButton
              containerStyle={[buttonPanelButtonStyle]}
              title={'Back'}
              modifier="outline"
              disabled={true}
              onPress={() => { }}
            />
            <MSFESButton
              containerStyle={[buttonPanelButtonStyle]}
              title={'Back'}
              modifier="outline"
              disabled={false}
              onPress={() => { }}
            />
            <MSFESButton
              containerStyle={[buttonPanelButtonStyle]}
              loading={false}
              title={'Next'}
              onPress={() => { }}
            />



          </WithHeader>
        </View>
        <Card>


        <MSFESInput
                              horizontal={true}
                              label={'Horz Field'}
                              onChangeText={() => {}}
                              placeholder={`Horz Field`}
                              value={"horz field value"}
                            />
                

        <MSFESInput
                              horizontal={true}
                              label={'Horz Field 2 3 4 5'}
                              onChangeText={() => {}}
                              placeholder={`Horz Field 2 3 4 5`}
                              value={"horz field value"}
                            />            
        <EntityEdit
            mode="add" 
            entity={entity}
            entityChanged={()=>{}}
            onCancelEntityPressed={()=>{}}
            onSaveEntityPressed={()=>{}}
            definition={definition}
            prepopulatedFields={prepopulatedFields}
            loading={false}
            isWizard={false}
            hideButtons={false}
            hasPlaces={true}
            children={children}
        ></EntityEdit>
            </Card>

            <Card>
              {/* <DemoQueryBuilder entityType={definition} /> */}
            </Card>

<Card>
<RichTextContainer />

</Card>
            </ScrollView>
    </SafeAreaView>)
}