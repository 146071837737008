import React, { useEffect, useState } from "react";

import _ from 'lodash'

import { createStackNavigator } from '@react-navigation/stack'
import ForgotPasswordScreen from "./ForgotPasswordScreen";
import signinScreen from "./SigninScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";
import StoryBook from "./StoryBook";
const SignedOutStack = createStackNavigator()


const SignedOutNavigator = () => {


  return (
    /* this stack navigator only handles login stuff. */

    <SignedOutStack.Navigator
      screenOptions={{
        headerShown: false
      }}
    >
      <SignedOutStack.Screen
        name="SigninScreen"
        component={signinScreen}
      />
      <SignedOutStack.Screen
        name="ForgotPasswordScreen"
        component={ForgotPasswordScreen}
      />

      <SignedOutStack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
      />
      <SignedOutStack.Screen
        name="StoryBook"
        component={StoryBook}
      />


    </SignedOutStack.Navigator>
  )
}

export default SignedOutNavigator