import { Linking, Platform } from 'react-native'
import parse from 'url-parse'
const useDeepLink = (handleLinkUrl) => {
  const handleLinkUrlWrappedFinal = (_url) => {
    if (_url) {
      _url = _url.split('#!/').join('')
      const parsed = parse(_url, true)

      handleLinkUrl(parsed)
    }
  }
  const _handleLinkUrlInitial = (_url) => {
    handleLinkUrlWrappedFinal(_url)
  }

  const _handleLinkUrlEventListener = (_url) => {
    handleLinkUrlWrappedFinal(_url.url)
  }

  Linking.getInitialURL().then(_handleLinkUrlInitial)
  Linking.addEventListener('url', _handleLinkUrlEventListener)

  if (Platform.OS !== 'web') {
    handleLinkUrl(null)
  }
}

export default useDeepLink
