import { DateTime } from 'luxon'
const defaultState = {
  errors: {
    errors: null,
    hasError: false
  }
}

export default function transitions(state = defaultState, action) {
  switch (action.type) {
    case 'transition/list-started':
      {
        state = {
          ...state,
          transitions: {
            ...state.transitions,
            lastRetrieveStarted: DateTime.local()
          }
        }
      }

      break
    case 'places/list-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            places: {
              ...state.places,
              data: response.data,
              lastRetrieveFinished: DateTime.local()
            }
          }
        }
      }

        break
    case 'transition/list-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            transitions: {
              ...state.transitions,
              data: response.data,
              lastRetrieveFinished: DateTime.local()
            }
          }
        }
      }

      break
    case 'transition/clear-general-error':
      state.errors = {
        ...state.errors,
        ...{ message: null, statusCode: null }
      }
      break
  }

  return state
}
