// libraries
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import _ from 'lodash'

import { getKey, getLabel, SelectWithData } from './SelectWithData'
import spacing from '../../styles/spacing'
import MSFESChip from '../MSFESChip'

export const SelectMultipleOptions = React.forwardRef((props, ref) => {

  const { value, changeHandler, dataRows,
  
    valueField = 'id',
    keyField = 'id',
    labelField = 'display_name',
    addPlaceholder = "Add:"
   } = props

  const [statefulValue, setStatefulValue] = useState(props.value ?? [])

  const updateValue = (newValue) => {
    setStatefulValue(newValue)
    changeHandler(newValue)
  }

  const removeField = (value) => {
    const localValue = statefulValue.filter(v => getKey(v,null, keyField) !== getKey(value,null, keyField))
    updateValue(localValue)
  }

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={{ flex: 1, marginHorizontal: spacing.m2 }}>
        {statefulValue && statefulValue.map(valueOption => {

        return <MSFESChip
            onClose={() => removeField(valueOption)}
            style={{ marginTop: spacing.m1 }}
          >{getLabel(valueOption, labelField)}</MSFESChip>

        })}
      </View>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <SelectWithData
          dataRows={dataRows}
          value={''}
          placeholder={addPlaceholder}
          labelField={labelField}
          keyField={keyField}
          valueField={valueField}
          onChangeText={(value) => {

            const localValue = [...statefulValue]
            if(localValue.find(v => getKey(v,null, keyField) === getKey(value,null, keyField))){
              // remove
            } else{
              localValue.push(value);
            }
            updateValue(localValue)
          }}
        />
      </View>
    </View>
  )
})
