import React from 'react'
import { View, Text } from 'react-native'
import EntityField from '../EntityField'
import { DatePicker } from '../fields/DatePicker'
import { DateTimePicker } from '../fields/DateTimePicker'
import RadioWithData from '../fields/RadioWithData'
import SelectWithData from '../fields/SelectWithData'
import MSFESHeading from '../MSFESHeading'
import MSFESInput from '../MSFESInput'
import RichTextContainer from '../RichTextContainer'

const EntityTypeQuizConfiguration = (props) => {
  const { entityType, onEntityTypeChanged, mode } = props

  const propertyChanged = (propertyName, newValue) => {
    const newEntityType = { ...entityType }
    _.set(newEntityType.data, propertyName, newValue)

    onEntityTypeChanged(newEntityType)
  }

  return (
    <View>
      <MSFESHeading>Certificate Report</MSFESHeading>
      <MSFESInput
        horizontal
        InputComponent={SelectWithData}
        label={'Certificate Report'}
        dataRows={entityType.data.reports}
        onChangeText={(newOption) =>
          propertyChanged('object_data.certificate_report', newOption)
        }
        placeholder={`(Select Report)`}
        value={entityType.data?.object_data?.certificate_report}
        valueField="type"
        labelField="name"
        keyField="offline_id"
      />
      <MSFESHeading>Availability (Not wired up)</MSFESHeading>
      <MSFESInput
        horizontal
        InputComponent={RadioWithData}
        label={'Availability'}
        dataRows={[
          {
            type: 'always',
            name: 'Always'
          },
          {
            type: 'close-by-date',
            name: 'Close By Date'
          },
          {
            type: 'close-by-date-and-time',
            name: 'Close By Date and Time'
          }
        ]}
        onChangeText={(newOption) =>
          propertyChanged('object_data.availability_option', newOption.type)
        }
        placeholder={`(Select Availability)`}
        value={entityType.data?.object_data?.availability_option}
        valueField="type"
        labelField="name"
        keyField="type"
      />
      {entityType.data?.object_data?.availability_option ===
        'close-by-date' && (
        <View>
          <MSFESInput
            horizontal
            mode={mode}
            InputComponent={DatePicker}
            entity={entityType.data}
            label="Close By Date"
            name={'close_by_date'}
            errorMessage={null}
            value={entityType.data?.object_data?.close_by_date}
            changeHandler={(newValue) => {
              propertyChanged('object_data.close_by_date', newValue)
            }}
          />
        </View>
      )}
      {entityType.data?.object_data?.availabilityOption ===
        'close-by-date-and-time' && (
        <View>
          <MSFESInput
            horizontal
            mode={mode}
            InputComponent={DateTimePicker}
            entity={entityType.data}
            label="Close By Date Time"
            name={'close_by_date_time'}
            errorMessage={null}
            value={entityType.data?.object_data?.close_by_date_time}
            changeHandler={(newValue) => {
              propertyChanged('object_data.close_by_date_time', newValue)
            }}
          />
        </View>
      )}

      {/* <MSFESHeading>Introduction</MSFESHeading>
      <Text>
        The user will read and agree to this introduction before starting the
        quiz.
      </Text>
      <MSFESInput
        horizontal
        InputComponent={RichTextContainer}
        mode={mode}
        entity={entityType.data}
        label="Introduction"
        name={'introduction'}
        errorMessage={null}
        defaultValue={entityType.data?.object_data?.introduction}
        onChangeText={(newValue) => {
          propertyChanged('object_data.introduction', newValue)
        }}
      /> */}
    </View>
  )
}

export default EntityTypeQuizConfiguration
