// libraries
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, Image, Text, Platform } from 'react-native'
import spacing from '../../styles/spacing'
import { Button } from 'react-native-elements'
import _ from 'lodash'

// internal libraries

// styles

//actions
import fileActions from '../../store/file/actions'
import { fieldHeight } from '../ListItemAsFieldProps'
import SignaturePad from './SignaturePad'
import AsyncStorage from '@react-native-community/async-storage'
import { getLoadableValueFromPropValue } from '../../helpers/images'
import ClearButton from '../ClearButton'

export const SignaturePadContainer = React.forwardRef((props, ref) => {
  const { value, onChangeText, style, editable } = props

  const [mediaLoading, setMediaLoading] = useState(false)
  const [signing, setSigning] = useState(false)
  const [containerHeight, setContainerHeight] = useState(50)
  const [hasHeightChanged, setHasHeightChanged] = useState(false)
  const prevContainerHeight = useRef(containerHeight)
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.defaultValue && props.data.entity.mode === 'add') {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      onChangeText(props.defaultValue)
    }

    if (props.value) {
      // there is an image ID.
      const value = getLoadableValueFromPropValue(props.value)

      dispatch(fileActions.showFile(value)).catch(() => {
        // doesn't matter.
        console.warn('Could not laod file', value)
      })
    }
  }, [props.defaultValue, props.value])

  const handleClear = () => {
    AsyncStorage.removeItem('signatureKey')
      .then(() => {
        // console.log('Signature successfully removed!')

        setLocalFile(null)

        onChangeText(null)
      })
      .catch(() => {
        // console.log('Failed to remove the signature.')
      })
  }

  const { fileObject, localFiles } = useSelector((appState) => {
    const idInUse = getLoadableValueFromPropValue(props.value)

    let localFiles = _.get(appState.files, 'queuedUploads', []).filter(
      (f) => f.data.fileOfflineId && f.data.fileOfflineId == idInUse
    )

    const returnObject = {
      fileObject: idInUse && _.get(appState.files, `byId[${idInUse}]`, null),
      localFiles
    }

    return returnObject
  })

  const [localFile, setLocalFile] = useState(null)
  useEffect(() => {
    async function returnLocalFile() {
      let _localFile = null
      if (localFiles) {
        if (localFiles.length) {
          _localFile =
            Platform.OS === 'web'
              ? localStorage.getItem(localFiles[0].data.payload.md5)
              : await AsyncStorage.getItem(localFiles[0].data.payload.md5)
        }

        setLocalFile(_localFile)
      }
    }
    returnLocalFile()
  }, [localFiles])

  const signPressed = useCallback(() => {
    setSigning(true)
  })

  const buttonLabel = 'Add Signature'

  const [availableWidth, setAvailableWidth] = useState(0)

  const onSignatureCancel = () => {
    setSigning(false)
  }

  const onViewLayout = (event) => {
    const { width } = event.nativeEvent.layout
    const { height } = event.nativeEvent.layout
    setAvailableWidth(width)
    if (height > prevContainerHeight.current) {
      setContainerHeight(height)
      setHasHeightChanged(true)
    }
    prevContainerHeight.current = height
  }

  const signatureStyle = {
    height: 100,
    width: availableWidth * 0.5,
    borderRadius: spacing.borderRadius
  }

  return (
    <View style={[style, { minHeight: fieldHeight }]} onLayout={onViewLayout}>
      {value && fileObject && (
        <Image
          style={signatureStyle}
          height={48}
          resizeMode="contain"
          source={{ uri: fileObject.url }}
        ></Image>
      )}
      {value && localFile && (
        <Image
          style={signatureStyle}
          height={48}
          resizeMode="contain"
          source={{ uri: localFile }}
        ></Image>
      )}
      {!fileObject && !signing && !editable && (
        <View style={{ marginTop: spacing.m1 }}>
          <Text>No signature added yet.</Text>
        </View>
      )}
      {!value && editable && !signing && (
        <Button
          title={buttonLabel}
          type="clear"
          loading={mediaLoading}
          buttonStyle={{ justifyContent: 'flex-start' }}
          onPress={signPressed}
        />
      )}
      {hasHeightChanged && (
        <ClearButton
          title="Clear Signature"
          onPress={handleClear}
          style={{
            marginLeft: '85%',
            marginTop: hasHeightChanged ? containerHeight * 0.25 : 0
          }}
        />
      )}
      {/* here^ */}
      {!value && editable && signing && (
        <SignaturePad
          entity={props.data.entity}
          field={props.data.field}
          onCancel={onSignatureCancel}
          onFinished={(resultLocalFile) => {
            onChangeText(resultLocalFile)
            setMediaLoading(false)
          }}
        />
      )}
    </View>
  )
})

export default SignaturePadContainer
