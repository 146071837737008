import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { View, Text, Platform, StyleSheet } from 'react-native'
import WithHeader from '../components/WithHeader'
import MSFESButton from '../components/MSFESButton'
import PermissionTile from '../components/PermissionTile'
import permissionsActions from '../store/permission/actions'
import { DataTable } from 'react-native-paper'
import shared from '../styles/shared'
import { FlatList } from 'react-native'
import spacing from '../styles/spacing'
import { showMessage } from 'react-native-flash-message'

import _ from 'lodash'
import { roles } from '../components/fields/MultipleRolePicker'

// display roles across the screen
// display permissions down the screen (sorted into categories somehow)

export default function SettingsAdminToolsPermissionsScreen({
  route,
  navigation
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(permissionsActions.listPermissions()).then((permissions) => {
      setLocalPermissions(permissions.data)
    })
  }, [route])

  const user = useSelector((state) => _.get(state, 'users.user', {}))

  const [localPermissions, setLocalPermissions] = useState({
    roles: [],
    permissions: [],
    isInitial: true
  })

  const [loading, setLoading] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  const onValueChanged = (_role, _permission, _friendlyName, _newValue) => {
    const { roles } = localPermissions

    setChangesMade(true)
    const role = roles.find((r) => r.id === _role.id)

    if (role) {
      if (_permission) {
        const currentIndex = role.permissions.findIndex(
          (p) => p.id === _permission.id
        )

        if (_newValue) {
          // add the permission.
          if (currentIndex === -1) {
            role.permissions.push(_permission)
          }
        } else {
          if (currentIndex !== -1) {
            role.permissions.splice(currentIndex, 1)
          }
        }

        setLocalPermissions((currentPermissions) => {
          return { ...currentPermissions, roles }
        })
      } else {
        const matches = localPermissions.permissions.filter(
          (p) => p.friendly_name === _friendlyName
        )
        matches.forEach((_permission) => {
          const currentIndex = role.permissions.findIndex(
            (p) => p.id === _permission.id
          )

          if (_newValue) {
            // add the permission.
            if (currentIndex === -1) {
              role.permissions.push(_permission)
            }
          } else {
            if (currentIndex !== -1) {
              role.permissions.splice(currentIndex, 1)
            }
          }
        })

        setLocalPermissions((currentPermissions) => {
          return { ...currentPermissions, roles }
        })
      }
    }
  }

  const onSaveChangesPressed = () => {
    setLoading(true)
    dispatch(permissionsActions.update(localPermissions))
      .then((permissions) => {
        setChangesMade(false)
        showMessage({
          message: 'Permissions Updated Successfully',
          type: 'success'
        })
        setLocalPermissions(permissions.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onCancelChangesPressed = () => {
    setChangesMade(false)
    dispatch(permissionsActions.listPermissions()).then((permissions) => {
      showMessage({ message: 'Changes reverted successfully', type: 'success' })
      setLocalPermissions(permissions.data)
    })
  }

  const buttonPanelStyle =
    Platform.OS === 'web'
      ? { flexDirection: 'row', flex: 1, flexWrap: 'wrap' }
      : { flexDirection: 'column' }
  const buttonPanelButtonStyle =
    Platform.OS === 'web'
      ? { flex: 1, marginRight: spacing.m1, marginTop: spacing.m1 }
      : { flex: 1, marginTop: spacing.m1 }

  const [searchQuery, setSearchQuery] = useState(null)

  const setSearchQueryDebounced = useCallback(
    _.debounce(setSearchQuery, 200),
    []
  )

  const onSearchTextChanged = (_searchText) => {
    // flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
    setSearchQueryDebounced(_searchText)
  }

  const permissionsFiltered = useMemo(() => {
    const searchLower = searchQuery?.toLowerCase()
    return (
      localPermissions.permissions.filter((p) =>
        searchLower
          ? p.friendly_name.toLowerCase().indexOf(searchLower) !== -1
          : true
      ) ?? []
    )
  }, [localPermissions.permissions, searchQuery])

  const columnHeight = 160
  const columnWidth = 40
  return (
    <WithHeader
      title={'Manage Permissions'}
      onSearchTextChanged={onSearchTextChanged}
      navigation={navigation}
      leftButtonType={'back'}
    >
      <View style={{ flexDirection: 'column', flex: 1 }}>
        <View>
          <View style={shared.mobileWidthView}>
            <View style={buttonPanelStyle}>
              <MSFESButton
                containerStyle={[buttonPanelButtonStyle]}
                title={'Revert Changes'}
                modifier="outline"
                disabled={!changesMade}
                onPress={onCancelChangesPressed}
              />
              <MSFESButton
                containerStyle={[buttonPanelButtonStyle]}
                loading={loading}
                title={'Save Changes'}
                disabled={!changesMade}
                onPress={onSaveChangesPressed}
              />
            </View>
          </View>
        </View>

        <View style={{ flex: 1, paddingBottom: 100 }}>
          <DataTable style={{ flex: 1 }}>
            <DataTable.Header
              fixedHeader={true}
              style={{ height: columnHeight }}
            >
              <DataTable.Title>Permissions</DataTable.Title>
              {localPermissions.roles
                .sort(
                  (r, r2) =>
                    roles.find((role) => role.key === r.name).sort -
                    roles.find((role) => role.key === r2.name).sort
                )
                .map((r) => {
                  //roles
                  const match = roles.find((role) => role.key === r.name)
                  return (
                    <DataTable.Title
                      style={{
                        height: columnHeight,
                        width: columnWidth,
                        position: 'relative',
                        borderRightWidth: StyleSheet.hairlineWidth
                      }}
                      key={r.id}
                    >
                      <View
                        style={{
                          transform: 'rotate(90deg)',
                          marginTop: columnHeight / 2 - 30,
                          marginLeft: -columnWidth - columnWidth / 2,
                          width: columnHeight,
                          height: columnWidth,
                          position: 'absolute'
                        }}
                      >
                        <Text style={{ flexWrap: 'wrap', maxWidth: 120 }}>
                          {match.label}
                        </Text>
                      </View>
                    </DataTable.Title>
                  )
                })}
            </DataTable.Header>

            <FlatList
              keyExtractor={(item) => item.id.toString()}
              style={{ maxHeight: '80%' }}
              data={permissionsFiltered}
              contentContainerStyle={{ flex: 1 }}
              renderItem={({ item: p, index }) => {
                const displayHeader =
                  index === 0 ||
                  (p.friendly_name &&
                    p.friendly_name !==
                      permissionsFiltered[index - 1].friendly_name)
                return (
                  <>
                    {displayHeader && (
                      <DataTable.Header>
                        <DataTable.Title>{p.friendly_name}</DataTable.Title>
                      </DataTable.Header>
                    )}

                    <DataTable.Row key={p.id}>
                      <DataTable.Title>
                        {p.function ? p.function : p.name}
                      </DataTable.Title>
                      {localPermissions.roles
                        .sort(
                          (r, r2) =>
                            roles.find((role) => role.key === r.name).sort -
                            roles.find((role) => role.key === r2.name).sort
                        )
                        .map((r) => (
                          <DataTable.Cell
                            key={r.id + '_' + p.id}
                            style={{
                              borderRightWidth: StyleSheet.hairlineWidth,
                              alignItems: 'center'
                            }}
                          >
                            <PermissionTile
                              role={r}
                              permission={p}
                              user={user}
                              valueChanged={(newValue) =>
                                onValueChanged(r, p, null, newValue)
                              }
                            />
                          </DataTable.Cell>
                        ))}
                    </DataTable.Row>
                  </>
                )
              }}
            />
          </DataTable>
        </View>
      </View>
    </WithHeader>
  )
}
