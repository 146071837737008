import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-elements'

import spacing from '../styles/spacing'
import { useStore } from 'react-redux'
import { EntityTypePicker } from './fields/EntityTypePicker'
import { EntityTypeFieldPicker } from './fields/EntityTypeFieldPicker'
import MSFESLabel from './MSFESLabel.jsx'
import { EntityTypeFieldMapper } from './fields/EntityTypeFieldMapper'
import QueryBuilder from './query/QueryBuilder'
import { updateMode, viewMode } from '../screens/EntityAddComponent'

export const GeneratedEntityTypeCreator = ({
  task,
  entityType,
  transitionEntityTypeSelected,
  entityFieldMapAssigneeChosen,
  transitionKey,
  fieldmapChanged,
  mode = updateMode,
  onConditionsChanged = () => {},
  conditions = null
}) => {
  const includedProps = { editable: mode !== viewMode }

  const store = useStore()
  const [localConditions, setLocalConditions] = useState(null)

  useEffect(() => {
    setLocalConditions(conditions)
  }, [conditions])

  return (
    <>
      <View key={task.id ? task.id : task.offline_id}>
        <View>
          <MSFESLabel label={'Generated Entity Type'} required={true} />
          <EntityTypePicker
            placeholder="Select the new Entity Type"
            currentEntityType={task.entity_type_id}
            onEntityTypeSelected={(entityType) =>
              transitionEntityTypeSelected(entityType.id, task, transitionKey)
            }
          />
        </View>
        <View style={{ paddingTop: spacing.m4 }}>
          <MSFESLabel label={'Assign Entity To'} required={false} />
          <EntityTypeFieldPicker
            placeholder="Select the field with Responsible Person"
            entityTypeFields={entityType.data.fields}
            value={task?.assignee_id_property}
            additionalOptions={[
              { field_data: { title: 'Creator' }, id: 'creator_id' }
            ]}
            onEntityTypeFieldSelected={(value) =>
              entityFieldMapAssigneeChosen(value, task, transitionKey)
            }
          />
        </View>

        <View style={{ paddingTop: spacing.m4 }}>
          <MSFESLabel label={'Map fields to new Entity'} required={false} />
          {entityType && task.entity_type_id ? (
            <EntityTypeFieldMapper
              sourceEntityType={entityType}
              destinationEntityTypeId={task.entity_type_id}
              mappedFields={task.mapped_fields}
              onFieldmapChanged={(fieldmap) =>
                fieldmapChanged(fieldmap, task, transitionKey)
              }
            />
          ) : (
            <View>
              <Text>
                Select the destination entity type before mapping fields.
              </Text>
            </View>
          )}
        </View>
        <View style={{ paddingTop: spacing.m4 }}>
          <MSFESLabel label={'When condition is true'} required={false} />
          <Text>
            When a condition is added, the entity will only generate if the
            condition is true.
          </Text>
          {entityType && task.entity_type_id && localConditions ? (
            <QueryBuilder
              entityType={entityType}
              action={localConditions}
              store={store}
              onQueryChanged={onConditionsChanged}
              {...includedProps}
            />
          ) : (
            <View>
              <Text>
                Select the destination entity type before defining conditions.
              </Text>
            </View>
          )}
        </View>
      </View>
    </>
  )
}

export default GeneratedEntityTypeCreator
