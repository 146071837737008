// libraries
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { withTheme, Text, CheckBox, Divider } from 'react-native-elements'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { showMessage } from 'react-native-flash-message'
import { List, Card } from 'react-native-paper'
// internal libraries
import { supportedEntityTypeProperties } from '../libraries/supportedEntityTypeProperties.js'

// styles
import spacing from '../styles/spacing'
import { EntityTypeField } from './EntityTypeField'
import { EntityField } from './EntityField'
import transitionActions from '../store/transition/actions'
import entityTypeActions from '../store/entity-type/actions'

import { useSelector, useDispatch } from 'react-redux'

//actions

// components
import MSFESButton from './MSFESButton.jsx'
import grid from '../styles/grid.js'
import { ListItemLinkButton } from './ListItemLinkButton.jsx'
import { GeneratedEntityTypeCreator } from './GeneratedEntityTypeCreator.jsx'
import { FlatList } from 'react-native'
import CurrentlyNoItems from './CurrentlyNoItems.jsx'
import ActionEditor from './ActionEditor.jsx'
import MSFESAccordion from './MSFESAccordion.jsx'

import DraggableFlatList from 'react-native-draggable-flatlist'
import PermissionsEditor from './PermissionsEditor.jsx'
import useEditingReport from '../hooks/useEditingReport.js'
import ReportEditor from './ReportEditor.jsx'
import EntityTypeQuizConfiguration from './entityType/EntityTypeQuizConfiguration.jsx'
import colors from '../styles/colors.js'
import MSFESInput from './MSFESInput.jsx'
import { PageSelector } from './PageSelector.jsx'
import AlarmEditor from './AlarmEditor.jsx'
import { MultipleRoleSelector } from './fields/MultipleRolePicker'

const getErrorsThisField = (errors, index) => {
  const field_data_label = `fields.${index}.field_data.`
  let errorMessages = _.map(
    // grabs only errors related to this field
    _.pickBy(errors, (val, key) => {
      return key.indexOf(field_data_label) === 0
    }),
    (value, key) => {
      // removes references to full error name in both key and value.
      return {
        [key.substr(field_data_label.length)]: value
          .join(', ')
          .split(field_data_label)
          .join('')
      }
    }
  )

  // empty object if no errors.
  errorMessages = errorMessages.length ? _.merge(...errorMessages) : {}

  return errorMessages
}
export const EntityTypeEdit = ({
  mode,
  entityTypeChanged,
  entityType,
  userIsGlobal,
  userTenantId = null,
  userIsLocalAndEditingGlobal
}) => {
  const fieldChanged = (field) => {
    const existingFieldIndex = entityType.data.fields.findIndex((f) =>
      f.id ? field.id === f.id : field.offline_id === f.offline_id
    )
    // don't mutate from parent
    const newEntityType = { ...entityType }

    newEntityType.data.fields[existingFieldIndex] = field

    // propagates new entity down.
    entityTypeChanged(newEntityType)
  }

  const propertyChanged = (propertyContainer, newValue) => {
    let newEntityType = Object.assign({}, entityType)
    _.set(newEntityType.data, propertyContainer.field_data.property, newValue)

    if (_.get(propertyContainer, 'field_data.params.onChanged', null)) {
      newEntityType = propertyContainer.field_data.params.onChanged(
        newValue,
        newEntityType
      )
    }
    entityTypeChanged(newEntityType)
  }

  const customPropertyChanged = (propertyName, newValue) => {
    const newEntityType = { ...entityType }
    _.set(newEntityType.data, propertyName, newValue)

    entityTypeChanged(newEntityType)
  }

  const transitionCheckboxPressed = (key) => {
    const newEntityType = { ...entityType }

    const transitions = _.get(entityType, 'data.allowed_transitions', [])

    const existingIndex = transitions.findIndex((t) => t.name === key)

    if (existingIndex !== -1) {
      newEntityType.data.allowed_transitions = transitions.filter(
        (t) => t.name !== key
      )
    } else {
      newEntityType.data.allowed_transitions = [
        ...transitions,
        { name: key, generates_tasks: [] }
      ]
    }
    entityTypeChanged(newEntityType)
  }

  const getAllowedTransitionForKey = (key, newEntityType) => {
    const transitionIndex = newEntityType.data.allowed_transitions.findIndex(
      (t) => t.name === key
    )
    const transitions = _.get(newEntityType, 'data.allowed_transitions', [])

    const transition = transitions[transitionIndex]

    return transition
  }
  const getTaskIndex = (transition, task) => {
    const taskIndex = transition.generates_tasks.findIndex((t) =>
      t.id ? task.id === t.id : task.offline_id === t.offline_id
    )
    return taskIndex
  }
  const transitionEntityTypeSelected = (entityTypeSelected, task, key) => {
    const newEntityType = { ...entityType }
    const transition = getAllowedTransitionForKey(key, newEntityType)

    const taskIndex = getTaskIndex(transition, task)

    transition.generates_tasks[taskIndex].entity_type_id = entityTypeSelected

    entityTypeChanged(newEntityType)
  }

  const fieldmapChanged = (fieldmap, task, transitionKey) => {
    const newEntityType = { ...entityType }
    const transition = getAllowedTransitionForKey(transitionKey, newEntityType)

    const taskIndex = getTaskIndex(transition, task)

    transition.generates_tasks[taskIndex].mapped_fields = fieldmap

    entityTypeChanged(newEntityType)
  }

  const conditionsChanged = (task, conditions, transitionKey) => {
    const newEntityType = { ...entityType }
    const transition = getAllowedTransitionForKey(transitionKey, newEntityType)

    const taskIndex = getTaskIndex(transition, task)
    transition.generates_tasks[taskIndex].conditions = conditions

    entityTypeChanged(newEntityType)
  }

  const entityFieldMapAssigneeChosen = (field, task, transitionKey) => {
    const newEntityType = { ...entityType }
    const transition = getAllowedTransitionForKey(transitionKey, newEntityType)

    const taskIndex = getTaskIndex(transition, task)

    transition.generates_tasks[taskIndex].assignee_id_property = field

    entityTypeChanged(newEntityType)
  }

  const addNewGeneratedEntity = (transitionKey) => {
    const newEntityType = { ...entityType }
    const transitionIndex = entityType.data.allowed_transitions.findIndex(
      (t) => t.name === transitionKey
    )

    const transitions = _.get(entityType, 'data.allowed_transitions', [])

    transitions[transitionIndex].generates_tasks.push({
      offline_id: uuidv4(),
      mapped_fields: []
    })
    entityTypeChanged(newEntityType)
  }

  const [
    editingReport,
    editReport,
    deleteReport,
    cancelEditingReport,
    reportChanged,
    addNewReport
  ] = useEditingReport(entityType, entityTypeChanged)

  const [editingAction, setEditingAction] = useState(null)
  const [editingAlarm, setEditingAlarm] = useState(null)

  const editAction = (action) => {
    setEditingAction(action)
  }

  const editAlarm = (alarm) => {
    setEditingAlarm(alarm)
  }

  const cancelEditingAction = () => {
    setEditingAction(null)
  }
  const cancelEditingAlarm = () => {
    setEditingAlarm(null)
  }

  const actionChanged = (action) => {
    // find this action by offline ID and replace it.
    const newEntityType = { ...entityType }
    const actions = _.get(newEntityType, 'data.actions', [])

    const index = actions.findIndex((a) => a.offline_id === action.offline_id)

    actions[index] = action
    entityTypeChanged(newEntityType)
  }
  const alarmChanged = (action) => {
    // find this action by offline ID and replace it.
    const newEntityType = { ...entityType }
    const alarms = _.get(newEntityType, 'data.alarms', [])

    const index = alarms.findIndex((a) => a.offline_id === action.offline_id)

    alarms[index] = action
    entityTypeChanged(newEntityType)
  }

  const addNewAction = () => {
    const newEntityType = { ...entityType }
    const actions = newEntityType.data.actions ?? []
    const newAction = {
      offline_id: uuidv4(),
      enabled: true
    }

    actions.push(newAction)

    _.set(newEntityType, 'data.actions', actions)
    entityTypeChanged(newEntityType)

    editAction(newAction)
  }
  const addNewAlarm = () => {
    const newEntityType = { ...entityType }
    const alarms = newEntityType.data.alarms ?? []
    const newAlarm = {
      offline_id: uuidv4(),
      alarmCondition: true,
      alarmConditionColor: '#ff0000'
    }

    alarms.push(newAlarm)

    _.set(newEntityType, 'data.alarms', alarms)
    entityTypeChanged(newEntityType)

    editAlarm(newAlarm)
  }

  const deleteAlarm = () => {
    const newEntityType = { ...entityType }
    const alarms = _.get(newEntityType, 'data.alarms', [])

    const index = alarms.findIndex(
      (a) => a.offline_id === editingAlarm.offline_id
    )
    alarms.splice(index, 1)

    _.set(newEntityType, 'data.alarms', alarms)
    entityTypeChanged(newEntityType)

    cancelEditingAlarm()
  }

  const deleteAction = () => {
    const newEntityType = { ...entityType }
    const actions = _.get(newEntityType, 'data.actions', [])

    const index = actions.findIndex(
      (a) => a.offline_id === editingAction.offline_id
    )
    actions.splice(index, 1)

    _.set(newEntityType, 'data.actions', actions)
    entityTypeChanged(newEntityType)

    cancelEditingAction()
  }

  const onAddFieldPressed = (pageReference) => {
    const newEntityType = { ...entityType }

    const newField = {
      offline_id: uuidv4(),

      field_data: { page: pageReference }
    }
    if (userIsLocalAndEditingGlobal) {
      newField.tenant_id = userTenantId
      if (!newEntityType.data.tenantFields) {
        newEntityType.data.tenantFields = []
      }
      newEntityType.data.tenantFields.push(newField)
    } else {
      newEntityType.data.fields.push(newField)
    }
    entityTypeChanged(newEntityType)

    toggleExpanded(newField)
  }

  const deleteField = (field) => {
    const newEntityType = { ...entityType }
    const index = newEntityType.data.fields.findIndex(
      (f) =>
        (f.id && f.id === field.id) ||
        (f.offline_id && f.offline_id === field.offline_id)
    )
    newEntityType.data.fields.splice(index, 1)
    entityTypeChanged(newEntityType)
  }

  const errors = useSelector((state) =>
    _.get(state.entityTypes, 'errors.errors', null)
  )

  const generalError = useSelector((state) => {
    const generalError = _.get(state.entityTypes, 'errors.message', null)

    if (generalError) {
      return generalError
    }
  })

  const [expandedField, setExpandedField] = useState(null)

  const [activePage, setActivePage] = useState(0)

  const toggleExpanded = (field) => {
    if (fieldIsExpanded(field)) {
      setExpandedField(null)
    } else {
      setExpandedField(field)
    }
  }

  const fieldIsExpanded = (field) => {
    return (
      expandedField &&
      (field.id
        ? expandedField.id === field.id
        : expandedField.offline_id === field.offline_id)
    )
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (generalError) {
      showMessage({
        message: generalError,
        type: 'danger',
        hideOnPress: true,
        autoHide: false,
        floating: true,
        style: { marginTop: 40 }
      })

      // error has displayed. Can clear from store now.
      setTimeout(() => {
        dispatch(entityTypeActions.clearFieldErrors())
      }, 500)
    }
  }, [generalError])

  useEffect(() => {
    dispatch(transitionActions.listTransitions())
    dispatch(transitionActions.listPlaces())

    // editAction(entityType.data.actions[0])
  }, [])

  const transitions = useSelector((state) =>
    _.get(state, 'transitions.transitions.data', {})
  )

  useEffect(() => {
    // check the default one.

    if (Object.keys(transitions).length && mode === 'add') {
      _.map(transitions, (value, key) => {
        if (_.get(value, 'metadata.required_always', false)) {
          const transitions = _.get(entityType, 'data.allowed_transitions', [])
          const existingIndex = transitions.findIndex((t) => t.name === key)
          if (existingIndex === -1) {
            transitionCheckboxPressed(key)
          }
        }
      })
    }
  }, [transitions])

  const updateSorts = (data) => {
    const newEntityType = { ...entityType }

    data.map((field, index) => {
      _.set(field, 'field_data.sort_order', index)

      const existingFieldIndex = entityType.data.fields.findIndex((f) =>
        f.id ? field.id === f.id : field.offline_id === f.offline_id
      )

      newEntityType.data.fields[existingFieldIndex] = field
    })

    entityTypeChanged(newEntityType)
  }

  const onPermissionsChanged = (newPermissions) => {
    const newEntityType = { ...entityType }
    _.set(newEntityType, 'data.permissions', newPermissions)
    entityTypeChanged(newEntityType)
  }

  const fieldsFiltered = [
    ...(entityType.data?.fields ?? []),
    ...(entityType.data?.tenantFields ?? [])
  ].filter((f) => !_.get(f, 'field_data.belongs_to_matrix', false))
  const fieldsSorted = _.sortBy(fieldsFiltered, (f) => {
    return _.get(f, 'field_data.sort_order', 999)
  })

  let pageValues = []
  const pageCount = _.get(entityType, 'data.pages', 1)
  for (let i = 0; i < pageCount; i++) {
    const storedLabel = entityType.data?.object_data?.page_labels?.[i]
    pageValues.push(storedLabel ?? 'Page ' + (i + 1))
  }

  const fieldsThisPage = fieldsSorted.filter(
    (f) =>
      f.field_data.page == activePage + 1 ||
      (!f.field_data.page && activePage == 0)
  )

  return (
    <>
      <ActionEditor
        mode={mode}
        action={editingAction}
        onCancelEdit={cancelEditingAction}
        onActionChanged={actionChanged}
        definition={entityType.data}
        deleteAction={deleteAction}
      />
      <AlarmEditor
        mode={mode}
        alarm={editingAlarm}
        onCancelEdit={cancelEditingAlarm}
        onAlarmChanged={alarmChanged}
        definition={entityType.data}
        deleteAlarm={deleteAlarm}
      />
      <ReportEditor
        mode={mode}
        report={editingReport}
        onCancelEdit={cancelEditingReport}
        onReportChanged={reportChanged}
        definition={entityType.data}
        deleteReport={deleteReport}
      />

      <View style={[grid.padding.viewPort]}>
        <List.AccordionGroup>
          <MSFESAccordion title="Setup" id="setup">
            {supportedEntityTypeProperties
              .filter((t) => t.tab === 'setup')
              .filter(
                (t) =>
                  _.get(t, 'showOnlyWhenGlobal', false) === false ||
                  userIsGlobal
              )
              .map((property, index) => {
                const entityTypeFilter = property?.entityTypeFilter

                const fieldTypeFilter = _.get(
                  property,
                  'params.fieldTypeFilter',
                  null
                )
                // if there's a filter, filter fields to only types listed. Otherwise return all fields
                const fieldsSupplied = _.get(
                  property,
                  'params.usesFieldsAsProp',
                  false
                )
                  ? fieldsSorted
                  : null

                const isVisible = property.visible_when
                  ? property.visible_when(entityType.data)
                  : true

                return (
                  isVisible && (
                    <View
                      key={index}
                      style={
                        property.has_lower_divider
                          ? {
                              borderBottomWidth: StyleSheet.hairlineWidth,
                              borderBottomColor: '#dadada'
                            }
                          : null
                      }
                    >
                      <EntityField
                        horizontal
                        mode={mode}
                        entity={entityType.data}
                        fieldsSupplied={fieldsSupplied}
                        fieldTypeFilter={fieldTypeFilter}
                        entityTypeFilter={entityTypeFilter}
                        name={property.name}
                        errorMessage={null}
                        defaultValue={
                          mode === 'add' ? property.default : undefined
                        }
                        isGeneric={!property.tenant_id}
                        field={{
                          title: property.name,
                          field_data: {
                            property: property.property,
                            type: property.type,
                            params: property.params
                          },
                          validation: {
                            required: property.required
                          },
                          flags: {}
                        }}
                        fieldChanged={(propertyContainer, newValue) => {
                          propertyChanged(propertyContainer, newValue)
                        }}
                        key={index}
                      />
                    </View>
                  )
                )
              })}
          </MSFESAccordion>
          <MSFESAccordion title="Fields" id="fields">
            <Card>
              <Card.Content>
                {/* <SegmentedControl
                  key={pageValues}
                  values={pageValues}
                  selectedIndex={activePage}
                  onChange={(event) => {
                    setActivePage(event.nativeEvent.selectedSegmentIndex)
                  }}
                /> */}
                <PageSelector
                  values={pageValues}
                  selectedIndex={activePage}
                  onChange={(newIndex) => {
                    setActivePage(newIndex)
                  }}
                />
                {pageValues.map((label, index) => {
                  const labelInUse = label
                  return (
                    activePage === index && (
                      <View key={index}>
                        <MSFESInput
                          horizontal
                          InputComponent={MultipleRoleSelector}
                          mode={mode}
                          label={'Visibility Rules'}
                          onChangeText={(pageLabel) =>
                            customPropertyChanged(
                              `object_data.page_visibility_rules.${index}`,
                              pageLabel
                            )
                          }
                          value={
                            entityType.data?.object_data
                              ?.page_visibility_rules?.[index]
                          }
                        />
                        <MSFESInput
                          horizontal
                          mode={mode}
                          label={'Page Label'}
                          onChangeText={(pageLabel) =>
                            customPropertyChanged(
                              `object_data.page_labels.${index}`,
                              pageLabel
                            )
                          }
                          value={
                            entityType.data?.object_data?.page_labels?.[index]
                          }
                        />
                        <DraggableFlatList
                          data={fieldsThisPage}
                          keyExtractor={(item) =>
                            item.id ? item.id.toString() : item.offline_id
                          }
                          onDragEnd={({ data }) => updateSorts(data)}
                          renderItem={({ item, index, drag }) => {
                            const errorsThisField = getErrorsThisField(
                              errors,
                              index
                            )

                            const key = item.id ? item.id : item.offline_id

                            return (
                              <EntityTypeField
                                mode={mode}
                                pages={pageCount}
                                setActivePage={(page) => {
                                  setActivePage(page - 1) //(0 based here, 1 based in field def)
                                  showMessage({
                                    message: 'Page changed to ' + page,
                                    type: 'success'
                                  })
                                }}
                                entity={entityType}
                                userIsLocalAndEditingGlobal={
                                  userIsLocalAndEditingGlobal
                                }
                                name={item.property}
                                errors={errorsThisField}
                                toggleExpanded={() => toggleExpanded(item)}
                                isExpanded={fieldIsExpanded(item)}
                                field={item}
                                fieldChanged={(field) => fieldChanged(field)}
                                deleteField={() => deleteField(item)}
                                key={'f' + key}
                                onLongPress={drag}
                              />
                            )
                          }}
                        />

                        <MSFESButton
                          containerStyle={[spacing.mr1]}
                          title={`Add Field to ${labelInUse} `}
                          modifier="outline"
                          onPress={() => onAddFieldPressed(activePage + 1)}
                        />
                      </View>
                    )
                  )
                })}
              </Card.Content>
            </Card>
          </MSFESAccordion>

          <MSFESAccordion title="Additional Configuration" id="config">
            {supportedEntityTypeProperties
              .filter((t) => t.tab !== 'setup')
              .filter(
                (t) =>
                  _.get(t, 'showOnlyWhenGlobal', false) === false ||
                  userIsGlobal
              )
              .map((property, index) => {
                const fieldTypeFilter = _.get(
                  property,
                  'params.fieldTypeFilter',
                  null
                )
                // if there's a filter, filter fields to only types listed. Otherwise return all fields
                const fieldsSupplied = _.get(
                  property,
                  'params.usesFieldsAsProp',
                  false
                )
                  ? fieldsSorted
                  : null

                const isVisible = property.visible_when
                  ? property.visible_when(entityType.data)
                  : true

                return (
                  isVisible && (
                    <View
                      key={index}
                      style={
                        property.has_lower_divider
                          ? {
                              borderBottomWidth: StyleSheet.hairlineWidth,
                              borderBottomColor: colors.formInputBackground
                            }
                          : null
                      }
                    >
                      <EntityField
                        horizontal
                        mode={mode}
                        entity={entityType.data}
                        fieldsSupplied={fieldsSupplied}
                        fieldTypeFilter={fieldTypeFilter}
                        name={property.name}
                        errorMessage={null}
                        defaultValue={
                          mode === 'add' ? property.default : undefined
                        }
                        field={{
                          title: property.name,
                          field_data: {
                            property: property.property,
                            type: property.type,
                            params: property.params
                          },
                          validation: {
                            required: property.required
                          },
                          flags: {}
                        }}
                        fieldChanged={(propertyContainer, newValue) => {
                          propertyChanged(propertyContainer, newValue)
                        }}
                      />
                    </View>
                  )
                )
              })}
          </MSFESAccordion>
          {entityType.data?.object_data?.is_quiz && (
            <MSFESAccordion title="Quiz Configuration" id="quiz_config">
              <EntityTypeQuizConfiguration
                entityType={entityType}
                onEntityTypeChanged={entityTypeChanged}
                mode={mode}
              />
            </MSFESAccordion>
          )}
          <MSFESAccordion title="Reports" id="reports">
            <Card>
              <Card.Content>
                <Text>
                  Reports are documents created with data saved inside this
                  entity.{' '}
                </Text>
                <FlatList
                  contentContainerStyle={{ flex: 1 }}
                  ListEmptyComponent={
                    <CurrentlyNoItems label={'No Reports Defined'} />
                  }
                  keyExtractor={(item) => item.offline_id.toString()}
                  data={entityType.data.reports}
                  renderItem={({ item }) => {
                    return (
                      <ListItemLinkButton
                        title={item.name}
                        onPress={() => editReport(item)}
                      />
                    )
                  }}
                />

                <Divider />

                <ListItemLinkButton
                  title={`Add new report`}
                  type="add"
                  onPress={addNewReport}
                />
              </Card.Content>
            </Card>
          </MSFESAccordion>
          <MSFESAccordion title="Actions" id="actions">
            <Card>
              <Card.Content>
                <Text>
                  Actions hold a ruleset and a collection of effects.{' '}
                </Text>
                <Text>
                  When the ruleset evaluates to true for an entity, the effects
                  are run on the entity. An action will run once only for an
                  entity (e.g. if the ruleset continues to be true, it will not
                  run again).
                </Text>
                <FlatList
                  contentContainerStyle={{ flex: 1 }}
                  ListEmptyComponent={
                    <CurrentlyNoItems label={'No Actions Defined'} />
                  }
                  keyExtractor={(item) => item.offline_id.toString()}
                  data={entityType.data.actions}
                  renderItem={({ item }) => {
                    return (
                      <ListItemLinkButton
                        title={item.name}
                        onPress={() => editAction(item)}
                      />
                    )
                  }}
                />

                <Divider />

                <ListItemLinkButton
                  title={`Add new action`}
                  type="add"
                  onPress={addNewAction}
                />
              </Card.Content>
            </Card>
          </MSFESAccordion>
          <MSFESAccordion title="Alarms" id="alarms">
            <Card>
              <Card.Content>
                <Text>Alarms change the visual state of an entity. </Text>
                <Text>
                  When the ruleset evaluates to true for an entity, the alarm is
                  enabled and the visual state of the entity is changed.
                </Text>
                <FlatList
                  contentContainerStyle={{ flex: 1 }}
                  ListEmptyComponent={
                    <CurrentlyNoItems label={'No Alarms Defined'} />
                  }
                  keyExtractor={(item) => item.offline_id.toString()}
                  data={entityType.data.alarms ?? []}
                  renderItem={({ item }) => {
                    return (
                      <ListItemLinkButton
                        title={item.name}
                        onPress={() => editAlarm(item)}
                      />
                    )
                  }}
                />

                <Divider />

                <ListItemLinkButton
                  title={`Add new alarm`}
                  type="add"
                  onPress={addNewAlarm}
                />
              </Card.Content>
            </Card>
          </MSFESAccordion>
          <MSFESAccordion title="Transitions" id="transitions">
            <Text>
              An entity or task can transition to different states, causing side
              effects to occur. Define them here.
            </Text>
            {Object.keys(transitions).map((key) => {
              const transition = transitions[key]
              // if this is a task, we should skip ones that are not allowed for tasks.
              if (
                entityType.data.is_task &&
                !transition.metadata.enabled_for_tasks
              ) {
                return false
              }
              const label = _.get(transition, 'metadata.title', '')
              const matchedTransition = _.get(
                entityType,
                'data.allowed_transitions',
                []
              ).find((e) => e.name === key)

              return (
                <View key={key}>
                  <CheckBox
                    title={label}
                    checked={!!matchedTransition}
                    onPress={() => transitionCheckboxPressed(key)}
                  />
                  {matchedTransition && (
                    <List.AccordionGroup>
                      <MSFESAccordion
                        title={"Generate entities for '" + label + "'"}
                        id="ge"
                      >
                        <View>
                          <Card>
                            <Card.Content>
                              {!matchedTransition.generates_tasks.length && (
                                <View style={{ marginBottom: spacing.m2 }}>
                                  <Text>
                                    There are no generated entities for this
                                    transition.
                                  </Text>
                                </View>
                              )}
                              {matchedTransition.generates_tasks.map(
                                (task, index) => {
                                  return (
                                    <GeneratedEntityTypeCreator
                                      task={task}
                                      key={index}
                                      entityType={entityType}
                                      transitionKey={key}
                                      transitionEntityTypeSelected={
                                        transitionEntityTypeSelected
                                      }
                                      entityFieldMapAssigneeChosen={
                                        entityFieldMapAssigneeChosen
                                      }
                                      fieldmapChanged={fieldmapChanged}
                                      conditions={task.conditions}
                                      onConditionsChanged={({ rules, json }) =>
                                        conditionsChanged(
                                          task,
                                          {
                                            ruleset: rules,
                                            ruleset_json: json
                                          },
                                          key
                                        )
                                      }
                                    />
                                  )
                                }
                              )}

                              <Divider />

                              <ListItemLinkButton
                                title={`Add new generated entity`}
                                type="add"
                                onPress={() => addNewGeneratedEntity(key)}
                              />
                            </Card.Content>
                          </Card>

                          <Divider />
                        </View>
                      </MSFESAccordion>
                    </List.AccordionGroup>
                  )}
                </View>
              )
            })}
          </MSFESAccordion>
          <MSFESAccordion title="Permissions" id="permissions">
            <PermissionsEditor
              entityType={entityType}
              onPermissionsChanged={onPermissionsChanged}
            />
          </MSFESAccordion>
        </List.AccordionGroup>
      </View>
    </>
  )
}

export default withTheme(EntityTypeEdit)
