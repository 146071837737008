import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { ListItem } from 'react-native-elements'
import { View } from 'react-native'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import { Portal, Provider as PaperProvider } from 'react-native-paper'
import { DatePickerTheme } from './DatePicker'
import {
  pendingDateToTimepickerTime,
  TimePickerOverlay,
  TimepickerTimeToPendingDate
} from './DateTimePicker'
import { useTimeFormat } from '../../contexts/TimeFormatContext'

export const TimePicker = React.forwardRef((props, ref) => {
  const { value, changeHandler, style, editable } = props

  const [visible, setVisible] = useState(false)

  const onDismiss = useCallback(() => {
    setVisible(false)
    console.info(onDismiss)
  }, [setVisible])

  const lockedToCreationDate = _.get(
    props.data.field,
    'field_data.params.lockedToCreationDate',
    false
  )

  useEffect(() => {
    if (value) {
      setPendingDate(value)
    }
  }, [value])
  const { timeFormat } = useTimeFormat()
  const jsDate = value ? DateTime.fromISO(value.value).toJSDate() : new Date()
  let dateObject = DateTime.fromJSDate(jsDate)
  if (
    timeFormat?.time_format?.label &&
    timeFormat.time_format.label !== 'TIME_WITH_SECONDS' &&
    timeFormat.time_format.label !== 'TIME_24_WITH_SECONDS'
  ) {
    dateObject = DateTime[timeFormat.time_format.label]
  } else {
    dateObject = DateTime.fromJSDate(jsDate).toFormat(
      timeFormat?.time_format?.label === 'TIME_WITH_SECONDS'
        ? 'hh:mm:ss a'
        : timeFormat?.time_format?.label === 'TIME_24_WITH_SECONDS'
        ? 'HH:mm:ss'
        : 'hh:mm:ss a'
    )
  }

  const displayTime = useMemo(() => {
    return timeFormat?.time_format
      ? dateObject.toLocaleString(DateTime[timeFormat.time_format.label])
      : dateObject.toLocaleString(DateTime.TIME_WITH_SECONDS)
  }, [dateObject, timeFormat])

  const [pendingDate, setPendingDate] = useState(
    value
      ? value
      : TimepickerTimeToPendingDate(
          { hours: '0', minutes: '00', seconds: '00', ampm: 'am' },
          { month: '01', day: '01', year: '0001' },
          true
        )
  )

  const onTimeChange = useCallback(
    (args) => {
      const newPendingDate = TimepickerTimeToPendingDate(
        args,
        pendingDate,
        true
      )
      setPendingDate(newPendingDate)
    },
    [pendingDate]
  )

  const timePickerInputValue = useMemo(() => {
    return pendingDateToTimepickerTime(pendingDate)
  }, [pendingDate])

  const onOpenDialog = editable
    ? () => {
        setVisible(true)
      }
    : null

  return (
    <View style={style}>
      <ListItem
        onPress={onOpenDialog}
        disabled={lockedToCreationDate}
        containerStyle={ListItemAsFieldProps.containerStyle}
        {...ListItemAsFieldProps.touchableProps}
      >
        <ListItem.Content>
          <ListItem.Title style={[props.inputStyle, InputSelectStyle]}>
            {value ? displayTime : 'Select a Time'}
          </ListItem.Title>
        </ListItem.Content>
        {/* {editable && <ListItem.Chevron type="font-awesome" name="chevron-down" size={14} color={"black"} />} */}
      </ListItem>

      <Portal>
        <PaperProvider theme={DatePickerTheme}>
          {visible && (
            <TimePickerOverlay
              onDismiss={() => setVisible(false)}
              value={timePickerInputValue}
              onChange={onTimeChange}
              onSave={() => {
                changeHandler(pendingDate)
                setVisible(false)
              }}
            />
          )}
        </PaperProvider>
      </Portal>
    </View>
  )
})
