import React from 'react'
import { View } from 'react-native'
import { useGetWidgetRenderBuckets } from '../hooks/useGetWidgetRenderBuckets'
import { WidgetRenderBucket } from './WidgetRenderBucket'
import CurrentlyNoItems from './CurrentlyNoItems'
import shared from '../styles/shared'
import spacing from '../styles/spacing'

export default function DashboardCanvas() {
  const {
    responseTimeBuckets,
    renderBuckets,
    dashboardProgressBuckets,
    progressBarBuckets
  } = useGetWidgetRenderBuckets('dashboard')

  return (
    <View
      style={[
        shared.screenView,
        {
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginLeft: spacing.m7_5
        }
      ]}
    >
      {responseTimeBuckets.map((responseTimeBucket, bucketIndex) => {
        return (
          <WidgetRenderBucket bucket={responseTimeBucket} key={bucketIndex} />
        )
      })}
      {renderBuckets.map((renderBucket, bucketIndex) => {
        return <WidgetRenderBucket bucket={renderBucket} key={bucketIndex} />
      })}
      {dashboardProgressBuckets.map((renderBucket, bucketIndex) => {
        return <WidgetRenderBucket bucket={renderBucket} key={bucketIndex} />
      })}
      {!renderBuckets?.length &&
        !progressBarBuckets?.length &&
        !dashboardProgressBuckets.length &&
        !responseTimeBuckets.length && (
          <View>
            <CurrentlyNoItems label="There are no configured widgets." />
          </View>
        )}
    </View>
  )
}
