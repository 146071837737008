import React from 'react'
import { Dimensions, View } from 'react-native'

import _ from 'lodash'
import { useSelector } from 'react-redux'
import { EntityField } from './EntityField'
import spacing from '../styles/spacing'
import shared from '../styles/shared'
import MSFESLabel from './MSFESLabel'
import { getLabelDirectionStyle } from './MSFESInput'
import { checkVisibilityFunction } from './GroupContainer'

export const RowContainer = React.forwardRef((props, ref) => {
  const {
    mode,
    entity,
    prepopulatedFields,
    fileAppended,
    fieldChanged,
    definition,
    label,
    helpText = null,
    editable,
    required,
    fieldVisibilityRules = []
  } = props

  const errors = useSelector((state) =>
    _.get(state.entities, 'errors.errors', null)
  )

  // props.data.field.field_data.params.sourceName
  const myEntityType = definition

  const fieldIds = _.get(
    props,
    'data.field.field_data.params.fieldList',
    []
  ).map((f) => f.offline_id ?? f.id)
  const fields = _.get(myEntityType, 'fields', [])
    .filter((f) => fieldIds.includes(f.id) || fieldIds.includes(f.offline_id))
    .filter(checkVisibilityFunction(fieldVisibilityRules))

  const fieldsSorted = _.sortBy(fields, (f) =>
    _.get(f, 'field_data.sort_order', 999)
  )

  const { width } = Dimensions.get('window')
  const useRowHeader = _.get(
    props,
    'data.field.field_data.params.useRowHeader',
    false
  )

  const hasTooManyFieldsForRow = fieldsSorted.length > 2 && width < 480

  const fieldsCanFitRowStyle = { flexDirection: 'row', flex: 1 }
  return (
    <View
      testID="row-c"
      style={{
        flexDirection: 'column'
      }}
    >
      {useRowHeader && (
        <MSFESLabel
          style={getLabelDirectionStyle(props.horizontal, width)}
          label={label}
          helpText={helpText}
          required={editable && required}
        />
      )}
      <View
        style={[
          hasTooManyFieldsForRow ? undefined : fieldsCanFitRowStyle,
          shared.debugOff
        ]}
      >
        {fieldsSorted.map((field, index) => {
          const errorMessage = _.get(
            errors,
            field.field_data.property,
            []
          ).join(',')

          return (
            <EntityField
              style={{
                marginRight: spacing.m2,
                flex: 1,
                ...(hasTooManyFieldsForRow ? { flex: 0 } : undefined)
              }}
              showLabel={!useRowHeader}
              displayIcon={false} // hide in row mode.
              mode={mode}
              entity={entity}
              name={field.field_data.property}
              prepopulatedFieldValue={
                prepopulatedFields
                  ? prepopulatedFields[field.field_data.property]
                  : undefined
              }
              definition={definition}
              errorMessage={errorMessage}
              field={field}
              fileAppended={fileAppended}
              fieldChanged={fieldChanged}
              key={index}
            />
          )
        })}
      </View>
    </View>
  )
})
