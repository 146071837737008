import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { showEntity } from '../../store/entity/actions'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'

export const useGetReportEntity = ({ route, navigation }) => {
  const [reportEntity, setReportEntity] = useState(null)

  const dispatch = useDispatch()
  useEffect(() => {
    if (route.params.reportEntity) {
      setReportEntity(route.params.reportEntity)
      navigation.setParams({ reportEntity: undefined })
    } else {
      // user has arrived cold, fetch.
      dispatch(
        showEntity({
          type: { name: 'system_report' },
          id: route.params.reportId
        })
      ).then((response) => {
        setReportEntity(response.data)
      })
    }
  }, [route.params])

  const { entityType: reportEntityType } = useDiscoverEntityEntityType(
    null,
    null,
    null,
    reportEntity?.report_entity_type?.id
  )

  return {
    reportEntity,
    reportEntityType,
    reportFields: reportEntity?.report_fields
  }
}
