import React from 'react'
import { TouchableOpacity } from 'react-native'
import MSFESIcon from './MSFESIcon'
import colors from '../styles/colors'

const ClearButton = ({ style, onPress }) => {
  return (
    <TouchableOpacity
      style={{
        position: 'absolute',
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        width: 48,
        ...style
      }}
      onPress={onPress}
    >
      <MSFESIcon name="backspace" color={colors.buttonBackspace} size={16} />
    </TouchableOpacity>
  )
}

export default ClearButton
