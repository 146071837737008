import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { storeUnsavedEntity } from '../store/entity/actions'
// this component:
// for a given offline ID, if it does not have a system ID yet:
// saves the entity in an array of 5, and pushes out an old one if necessary
// displays to the user that it is still ready to be saved

const _EntityLocalCache = ({ entity }) => {
  const dispatch = useDispatch()

  const [lastTouchedAt, setLastTouchedAt] = useState(null)
  useEffect(() => {
    if (
      entity.offline_id &&
      !entity.id &&
      !entity.is_pristine &&
      entity.touched_at &&
      entity.touched_at !== lastTouchedAt
    ) {
      // we should cache this one.
      setLastTouchedAt(entity.touched_at)
      dispatch(storeUnsavedEntity({ entity }))
    }
  }, [entity])
  return null
}

export const EntityLocalCache = React.memo(_EntityLocalCache)
