import HTTPError from './HTTPError.js'
import _ from 'lodash'
import { store } from '../store'

//actions
import { Platform } from 'react-native'

const loggingToConsole = false
const shouldDebug = true

export const getUrl = (path = '', pathSuffix = 'api') => {
  const state = store.getState()
  const environment = _.get(state, 'app.environment', 'prod')
  window.emberenv = environment

  if (path.indexOf('/') === 0) {
    path = path.substr(1)
  }
  let baseUrl
  switch (environment) {
    case 'dev':
      baseUrl =
        Platform.OS === 'ios' || Platform.OS === 'web'
          ? `http://localhost/${pathSuffix}`
          : `http://192.168.0.190/${pathSuffix}`
      break
    case 'stage':
      baseUrl = `https://app-stage.firerescuehr.org/${pathSuffix}`
      break
    case 'prod':
      baseUrl = `https://app.emberfd.com/${pathSuffix}`
      break
    default:
      console.warn('No environment recognised: ' + environment)

      break
  }

  if (!baseUrl.endsWith('/')) {
    baseUrl = baseUrl + '/'
  }
  let debugString = ''
  if (shouldDebug) {
    if (path.indexOf('?') !== -1) {
      debugString = (!path.endsWith('&') ? '&' : '') + 'XDEBUG_SESSION_START=1'
    } else {
      debugString = '?XDEBUG_SESSION_START=1'
    }
  }
  let finalUrl = `${baseUrl}${path}${debugString}`
  loggingToConsole && console.info(finalUrl)
  return finalUrl
}
const postForm = (path, params = null, headersSupplied = {}) => {
  const url = getUrl(path)

  const method = 'POST'
  const requestId = startRequest({
    route: path,
    body: params,
    dispatch: store.dispatch,
    method
  })
  const headers = getHeaders(headersSupplied, false)

  return fetch(url, {
    method,
    headers,
    body: params
  })
    .then(handleOk)
    .catch((e) => handleError(e, headersSupplied))
    .finally(() => {
      finishRequest(requestId)
    })
}
const post = (path, params = null, headersSupplied = {}) => {
  const url = getUrl(path)

  const requestId = startRequest({
    route: path,
    body: params,
    dispatch: store.dispatch
  })
  return fetch(url, {
    method: 'POST',
    headers: getHeaders(headersSupplied),
    body: JSON.stringify(params)
  })
    .then(handleOk)
    .catch((e) => handleError(e, headersSupplied))
    .finally(() => {
      finishRequest(requestId)
    })
}

const del = (path, params = null, headersSupplied = {}) => {
  const url = getUrl(path)

  const method = 'DELETE'
  const requestId = startRequest({
    route: path,
    body: params,
    method,
    dispatch: store.dispatch
  })
  return fetch(url, {
    method,
    headers: getHeaders(headersSupplied)
  })
    .then(handleOk)
    .catch((e) => handleError(e, headersSupplied))
    .finally(() => {
      finishRequest(requestId)
    })
}

const put = (path, params = null, headersSupplied = {}) => {
  const url = getUrl(path)

  const requestId = startRequest({
    route: path,
    body: params,
    dispatch: store.dispatch
  })
  return fetch(url, {
    method: 'PUT',
    headers: getHeaders(headersSupplied),
    body: JSON.stringify(params)
  })
    .then(handleOk)
    .catch((e) => handleError(e, headersSupplied))
    .finally(() => {
      finishRequest(requestId)
    })
}

const get = (path, headersSupplied = {}, AbortController) => {
  const url = getUrl(path)

  const method = 'GET'
  const headers = getHeaders(headersSupplied)
  const requestId = startRequest({
    route: path,
    method,
    dispatch: store.dispatch,
    headers
  })
  return fetch(url, {
    method,
    headers,
    signal: AbortController?.signal
  })
    .then(handleOk)
    .catch((e) => {
      return handleError(e, headersSupplied)
    })
    .finally(() => {
      finishRequest(requestId)
    })
}

const api = { post, postForm, get, put, delete: del, getUrl }

export const getHeaders = (headersSupplied = null, isJson = true) => {
  const access_token = _.get(store.getState(), 'users.access_token', null)

  const show_deleted_requested =
    store.getState().app?.features?.showDeleted ?? false
  const headers = {
    ...{ Accept: 'application/json' },
    ...(isJson ? { 'Content-Type': 'application/json' } : null),
    ...headersSupplied,
    ...(access_token ? { Authorization: `Bearer ${access_token}` } : undefined),
    ...(show_deleted_requested ? { 'Show-Deleted-Requested': true } : undefined)
  }

  return headers
}

const handleOk = async (response) => {
  // loggingToConsole && console.info('Raw response is', response)
  if (!response.ok) {
    const body = await response.json()

    // /* REMOVE ME AFTER DEBUGGING */
    // if(response.url.indexOf("/files") === -1){
    //   debugger //where did this come from?

    // }
    throw new HTTPError(response.status, response.message, body)
  }
  return response.json()
}

const handleError = (e, headersSupplied) => {
  loggingToConsole && console.info(e)
  if (e.statusCode === 401 && _.get(headersSupplied, 'Authorization', false)) {
    store.dispatch({
      type: 'users/purge-token'
    })
  } else if (e.statusCode === 403) {
    console.info(e)
    throw e
  } else {
    throw e
  }
}

const startRequest = ({ route, body, headers, method = 'unknown' }) => {
  // for monitoring.
  const id = Math.random()
  loggingToConsole &&
    console.info('Started request with id', id, method, route, body, headers)
  return id
}

const finishRequest = (id) => {
  // matches start request id. for monitoring.
  loggingToConsole && console.info('Finished request with id', id)
}
export default api
