import React, { useState, useEffect } from 'react'
import { Avatar, Text } from 'react-native-elements'
import colors from '../styles/colors'
import { View } from 'react-native'
import initials from 'initialism'
import parse from 'url-parse'

const getInitials = (name) => {
  return initials(name)
}
export default function MSFESAvatar(props) {
  let {
    label,
    rounded = true,
    source,
    showLabel = true,
    icon = undefined,
    size,
    outerContainerStyle = { alignItems: 'center' },
    style = {},
    avatarContainerStyle = { backgroundColor: colors.inactiveBackground },
    textProps,
    textStyle = { marginTop: 20 },
    titleStyle = { color: colors.avatarText, fontSize: 18 }
  } = props

  const [initials, setInitials] = useState('')
  const [fileExtensionIcon, setFileExtensionIcon] = useState(null)

  const fileExtensionContainerStyle = {}
  // if the file has a file extension that matches a document, such as png, docx, xlsx, show that icon instead.

  useEffect(() => {
    if (label) {
      setInitials(getInitials(label))
    }
    const parsed = parse(source, true)
    const pathname = parsed?.pathname
    const parts = pathname.split('.')
    const fileExtension = parts[parts.length - 1]

    switch (fileExtension) {
      case 'pdf':
        setFileExtensionIcon({
          name: 'file-pdf',
          color: 'red',
          solid: true,
          type: 'font-awesome-5',
          size: 34
        })
        break
      case 'doc':
      case 'docx':
        setFileExtensionIcon({
          name: 'file-word',
          color: '#1B5EBE',
          solid: true,
          type: 'font-awesome-5',
          size: 34
        })
        break
      case 'xls':
      case 'xlsx':
        setFileExtensionIcon({
          name: 'file-excel',
          color: '#10793F',
          solid: true,
          type: 'font-awesome-5',
          size: 34
        })
        break
      case 'csv':
        setFileExtensionIcon({
          name: 'file-csv',
          color: '#10793F',
          solid: true,
          type: 'font-awesome-5',
          size: 34
        })
        break
    }
  }, [label, source])

  const title = icon ? null : initials

  return (
    <View style={[outerContainerStyle, style]}>
      <Avatar
        titleStyle={titleStyle}
        icon={icon || fileExtensionIcon}
        source={source ? { uri: source } : undefined}
        containerStyle={[
          fileExtensionIcon ? fileExtensionContainerStyle : avatarContainerStyle
        ]}
        placeholderStyle={{ backgroundColor: 'transparent' }}
        size={size}
        rounded={fileExtensionIcon ? false : rounded}
        title={title}
      />
      {showLabel && (
        <Text {...textProps} style={[textStyle]}>
          {label}
        </Text>
      )}
    </View>
  )
}
