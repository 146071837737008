// libraries
import React, { useCallback, useEffect, useState } from 'react'
import { RefreshControl } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
// styles

//actions
import entityTypeActions, {
  listLiteEntityTypes
} from '../store/entity-type/actions'

// components
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import WithHeader from '../components/WithHeader'
import CurrentlyNoItems from '../components/CurrentlyNoItems'
import DraggableFlatList from 'react-native-draggable-flatlist'
import { getResortedData } from './SettingsManageEntityTypesScreen'
export default function SettingsCollectionsScreen({ navigation }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listLiteEntityTypes())
  }, [])

  const _entityTypes = useSelector(
    (state) => state.entityTypes?.dataLite?.data ?? []
  )

  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })

  const [entityTypes, setEntityTypes] = useState([])
  const [entityTypesFiltered, setEntityTypesFiltered] = useState([])

  useEffect(() => {
    setEntityTypes(
      _entityTypes
        .filter((entityType) => !entityType.is_module)
        .filter((et) => {
          return _.get(
            current_user,
            `effective_permissions['view|id:${et.id}|name:${et.name}']`,
            false
          )
        })
    )
  }, [_entityTypes])

  const [searchText, setSearchText] = useState(null)
  const setSearchTextDebounced = useCallback(_.debounce(setSearchText, 200), [])

  const onSearchTextChanged = (_searchText) => {
    setSearchTextDebounced(_searchText.toLowerCase())
  }

  const [refreshing, setRefreshing] = React.useState(false)
  const onRefresh = React.useCallback(() => {
    setRefreshing(true)

    dispatch(listLiteEntityTypes()).then(() => {
      setRefreshing(false)
    })
  }, [])

  useEffect(() => {
    setEntityTypesFiltered(
      searchText
        ? entityTypes.filter(
            (e) => e.label.toLowerCase().indexOf(searchText) !== -1
          )
        : entityTypes
    )
  }, [searchText, entityTypes])

  const sortedEntityTypes = _.sortBy(
    entityTypesFiltered,
    (e) => e.sort_order ?? 1
  )

  const pageTitle = 'Collections'

  const hasCollectionsPermission = _.get(
    current_user.effective_permissions,
    'update||name:collections',
    false
  )

  const updateSorts = (data, from, to) => {
    data = getResortedData(data, from, to)
    setEntityTypes(data)
    dispatch(entityTypeActions.updateEntityTypeBatch(data))
  }

  return (
    <WithHeader
      title={pageTitle}
      onSearchTextChanged={onSearchTextChanged}
      navigation={navigation}
      leftButtonType={'back'}
    >
      {hasCollectionsPermission && (
        <DraggableFlatList
          data={sortedEntityTypes}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          keyExtractor={(item) => item.id.toString()}
          onDragEnd={({ data, from, to }) => updateSorts(data, from, to)}
          renderItem={({ item: entityType, drag }) => {
            return (
              <ListItemLinkButton
                title={entityType.label}
                onLongPress={drag}
                avatarIcon={{
                  name: _.get(entityType, 'object_data.icon', null),
                  type: 'font-awesome'
                }}
                to={`/entity-list/?type=${entityType.name}`}
              />
            )
          }}
        />
      )}
      {!hasCollectionsPermission && (
        <CurrentlyNoItems
          label={'Permissions error - cannot access collections.'}
        />
      )}
    </WithHeader>
  )
}
