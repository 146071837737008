import React, { useContext } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import colors from '../styles/colors'

import _ from 'lodash'
import spacing from '../styles/spacing'
import CrossFieldRenderAsNullContext from '../contexts/CrossFieldRenderAsNullContext'
import MSFESIcon from './MSFESIcon'

export default function MSFESHeading(props) {
  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  const {
    fontSize = 16,
    children,
    style = null,
    textStyle = null,
    signin = false,
    onPress
  } = props

  const backgroundColor = signin ? 'transparent' : colors.headingBackground
  const color = signin ? colors.white : colors.headingText

  const Component = onPress ? TouchableOpacity : View

  return !skipStaticRendering ? (
    <Component
      onPress={onPress}
      style={[
        {
          position: 'relative',
          marginBottom: spacing.m2,
          backgroundColor,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: colors.headingUnderline,
          paddingVertical: spacing.m1,
          flexDirection: 'row'
        },
        style
      ]}
    >
      <Text style={[{ color, fontWeight: 'bold', fontSize }, textStyle]}>
        {children?.toUpperCase?.() ?? children}
      </Text>
      {onPress && (
        <MSFESIcon
          name={'chevron-down'}
          size={12}
          style={{ paddingVertical: 2, marginRight: 12, marginLeft: 12 }}
          type="font-awesome"
          onPress={onPress}
        />
      )}
    </Component>
  ) : null
}
