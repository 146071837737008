import { useCallback, useState } from 'react'

const useTableSort = () => {
  const [activeSort, setActiveSort] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  const sort = useCallback((label) => {
    return () => {
      setSortDirection((sortDirection) => {
        switch (sortDirection) {
          case 'ascending':
            return 'descending'
          case 'descending':
            return null
          default:
            return 'ascending'
        }
      })
      setActiveSort(label)
    }
  }, [])

  return [sort, activeSort, sortDirection]
}

export default useTableSort
