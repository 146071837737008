import { useDispatch } from 'react-redux'
import { useState } from 'react'

import { approveChanges, rejectChanges } from '../store/entity/actions'
export const useFieldLevelApprovals = ({
  entity,
  entityType,
  setEntityLoading,
  fetchAndUpdateLocalEntity
}) => {
  const dispatch = useDispatch()
  const [pendingApprovals, setPendingApprovals] = useState([])

  const onApproveToggled = (property) => {
    if (pendingApprovals.includes(property)) {
      setPendingApprovals((_pendingApprovals) =>
        _pendingApprovals.filter((p) => p !== property)
      )
    } else {
      setPendingApprovals((_pendingApprovals) => [
        ..._pendingApprovals,
        property
      ])
    }
  }

  const _approveChanges = () => {
    setEntityLoading(true)
    dispatch(approveChanges({ entity, entityType, pendingApprovals }))
      .then(() => {
        fetchAndUpdateLocalEntity(entityType, entity.id).then(() => {
          setPendingApprovals([])
          setEntityLoading(false)
        })
      })
      .catch(() => {
        setEntityLoading(false)
      })
  }

  const _rejectChanges = () => {
    setEntityLoading(true)
    dispatch(
      rejectChanges({ entity, entityType, pendingRejections: pendingApprovals })
    )
      .then(() => {
        fetchAndUpdateLocalEntity(entityType, entity.id).then(() => {
          setPendingApprovals([])
          setEntityLoading(false)
        })
      })
      .catch(() => {
        setEntityLoading(false)
      })
  }

  return {
    pendingApprovals,
    approveChanges: _approveChanges,
    rejectChanges: _rejectChanges,
    onApproveToggled
  }
}
