import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import { Divider } from 'react-native-paper'
import spacing from '../styles/spacing'
import MSFESHeading from './MSFESHeading'

import _ from 'lodash'

const EntityEditQuizInProgressBlock = ({
  definition,
  disabled,
  entity,
  field,
  started_at,
  finish_at
}) => {
  //  definition={definition} disabled={disabled} entity={props.data.entity}

  return (
    <View>
      <MSFESHeading>Quiz Information</MSFESHeading>
      <Text>Start time: {started_at.display_value}</Text>
      <Text>Finish time: {finish_at?.display_value} </Text>
      <Text>Time remaining: {finish_at?.time_remaining_minutes}m</Text>
      <Divider style={{ marginVertical: spacing.m2 }} />
    </View>
  )
}

export default EntityEditQuizInProgressBlock
