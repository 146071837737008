import React from 'react'
import { Text } from 'react-native-elements'
import textStyles, { DEBUG_TEXT } from '../styles/text'

export const DebugText = (props) => {
  return (
    DEBUG_TEXT && (
      <Text style={[textStyles.debug]}>
        <>{props.children}</>
      </Text>
    )
  )
}

export default DebugText
