import React, { useEffect, useState } from 'react'
import { Overlay } from 'react-native-elements'
import { View } from 'react-native'
import { Card, List, Divider } from 'react-native-paper'
import MSFESInput from './MSFESInput'
import MSFESAccordion from './MSFESAccordion'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import MSFESButton from './MSFESButton'
import { ListItemLinkButton } from './ListItemLinkButton'
import { WrappedSwitch } from './EntityField'
import spacing from '../styles/spacing'
import { ScrollView } from 'react-native'
import { Dimensions } from 'react-native'
import OverlayHeader from './OverlayHeader'
import YesNoCancelDialog from './YesNoCancelDialog'
import SingleFile from './fields/SingleFile'

import MediaHelper from '../libraries/mediaHelper'
import SelectWithData from './fields/SelectWithData'
import ColorPickerField from './fields/ColorPickerField'
import { viewMode } from '../screens/EntityAddComponent'

const ReportEditor = (props) => {
  const { report, onCancelEdit, onReportChanged, definition, mode } = props

  const [localReport, setLocalReport] = useState(null)
  const [deleteReportPending, setDeleteReportPending] = useState(null)
  const [deleteStampPending, setDeleteStampPending] = useState(null)
  const changeHandler = (property, value) => {
    const newReport = { ...localReport }
    if (typeof property === 'object') {
      for (var key in property) {
        _.set(newReport, key, property[key])
      }
    } else {
      _.set(newReport, property, value)
    }

    setLocalReport(newReport)
  }

  useEffect(() => {
    setLocalReport(report)
  }, [report])

  const yesDeleteReport = () => {
    props.deleteReport()
    setDeleteReportPending(null)
  }

  const noDeleteReport = () => {
    setDeleteReportPending(null)
  }

  const yesDeleteStamp = () => {
    const stamps = _.get(localReport, 'stamps', [])

    stamps.splice(
      stamps.findIndex((e) => e.offline_id === deleteStampPending.offline_id),
      1
    )

    setLocalReport({ ...localReport, stamps })
    changeHandler('stamps', stamps)
    setDeleteStampPending(null)
  }

  const noDeleteStamp = () => {
    setDeleteStampPending(null)
  }

  const deleteReport = () => {
    setDeleteReportPending(true)
  }

  const getNewStamp = () => {
    return {
      offline_id: uuidv4(),
      x: null,
      y: null,
      page: null,
      fontSize: 12,
      fontFamily: 'Arial'
    }
  }
  const addNewStamp = () => {
    const stamps = _.get(localReport, 'stamps', [])

    stamps.push(getNewStamp())

    setLocalReport({ ...localReport, stamps })
    changeHandler('stamps', stamps)
  }

  const deleteStamp = (eff) => {
    setDeleteStampPending(eff)
  }

  const stampChangeHandler = (stampToUpdate, key, value) => {
    const stamps = _.get(localReport, 'stamps', [])
    const stamp = stamps.find((e) => e.offline_id === stampToUpdate.offline_id)
    _.set(stamp, key, value)
    setLocalReport({ ...localReport, stamps })
    changeHandler('stamps', stamps)
  }

  const willRender = !!(localReport && definition)

  const height = Dimensions.get('window').height

  const pageRows = []
  const pages = parseInt(_.get(definition, 'pages', 1))

  for (let i = 0; i < pages; i++) {
    pageRows.push({ id: i + 1, label: 'Page ' + (i + 1), value: i + 1 })
  }

  const fields = _.get(definition, 'fields', [])

  const keyField = 'field_data.property'
  const labelField = 'field_data.title'
  const valueField = 'field_data.title'

  const includedProps = { editable: mode !== viewMode }

  return (
    <View>
      {willRender ? (
        <Overlay isVisible={!!report} onBackdropPress={onCancelEdit}>
          <OverlayHeader>Editing Report {localReport?.name}</OverlayHeader>

          {deleteReportPending && (
            <YesNoCancelDialog
              title="delete report?"
              body="This will delete the report permanently. Continue?"
              yesAction={yesDeleteReport}
              noAction={noDeleteReport}
              cancelAction={noDeleteReport}
            />
          )}
          {deleteStampPending && (
            <YesNoCancelDialog
              title="delete stamp?"
              body="This will delete the stamp permanently. Continue?"
              yesAction={yesDeleteStamp}
              noAction={noDeleteStamp}
              cancelAction={noDeleteStamp}
            />
          )}

          <ScrollView style={{ maxHeight: height * 0.8 }}>
            <List.AccordionGroup>
              <MSFESAccordion title="Properties" id="properties">
                <Card.Content>
                  <MSFESInput
                    horizontal
                    label="Report Name"
                    defaultValue={localReport.name}
                    name="name"
                    onChangeText={(text) => changeHandler('name', text)}
                    {...includedProps}
                  />
                  <MSFESInput
                    horizontal
                    label="Enabled?"
                    InputComponent={WrappedSwitch}
                    supportsInputShading={false}
                    name="enabled"
                    onValueChange={() => {
                      const newEnabled = !localReport.enabled
                      changeHandler('enabled', newEnabled)
                    }}
                    value={localReport.enabled}
                    {...includedProps}
                  />
                  <MSFESInput
                    horizontal
                    label="Available in UI?"
                    InputComponent={WrappedSwitch}
                    supportsInputShading={false}
                    name="available_in_ui"
                    onValueChange={() => {
                      const newEnabled = !localReport.available_in_ui
                      changeHandler('available_in_ui', newEnabled)
                    }}
                    value={localReport.available_in_ui}
                    {...includedProps}
                  />

                  <MSFESInput
                    horizontal
                    label={'Default Font'}
                    dataRows={[
                      { title: 'Californian FB', key: 'CalifornianFB-Reg' },
                      { title: 'Helvetica', key: 'Helvetica' },
                      { title: 'Times', key: 'Times' }
                    ]}
                    value={localReport.defaultFont}
                    InputComponent={SelectWithData}
                    labelField={'title'}
                    keyField={'key'}
                    valueField={'key'}
                    onChangeText={(value) => {
                      changeHandler('defaultFont', value)
                    }}
                    {...includedProps}
                  />
                  <MSFESInput
                    horizontal
                    label="Font Color"
                    defaultValue={localReport.fontColor}
                    InputComponent={ColorPickerField}
                    changeHandler={(color) => {
                      changeHandler('fontColor', color)
                    }}
                    {...includedProps}
                  />
                  <MSFESInput
                    horizontal
                    keyboardType="numeric"
                    label="Font Size"
                    value={localReport.fontSize}
                    onChangeText={(size) => {
                      changeHandler('fontSize', size)
                    }}
                    {...includedProps}
                  />
                </Card.Content>
              </MSFESAccordion>
              <MSFESAccordion
                title="Background Document"
                id="background_document"
              >
                <SingleFile
                  value={localReport.backgroundDocument?.value}
                  savesAgainstEntityId={false}
                  onBusyStateChanged={() => {}}
                  pathValue={localReport.backgroundDocument?.path}
                  onChangeText={(value, path) => {
                    changeHandler('backgroundDocument', { value, path })
                  }}
                  data={{
                    field: {
                      field_data: {
                        params: {
                          fileType: MediaHelper.PDFType,
                          allowMultipleUploads: false,
                          canSelectFromLibrary: false
                        }
                      }
                    }
                  }}
                  isRestricted={false}
                  {...includedProps}
                />
              </MSFESAccordion>
              <MSFESAccordion title="Stamps" id="stamps">
                <Card.Content>
                  <View style={[]}>
                    {_.get(localReport, 'stamps', []).map((st, index) => {
                      const field = definition.fields.find(
                        (f) => f.offline_id == st.field?.offline_id
                      )

                      const stampLabel = st.field
                        ? 'Stamp field: ' + st.field.field_data.title
                        : st.text
                        ? 'Stamp text: ' + st.text?.substr(0, 40)
                        : 'New Stamp'

                      const isImage = ['single-file', 'signature-pad'].includes(
                        _.get(field, 'field_data.type', null)
                      )
                      return (
                        <Card.Content key={index}>
                          {true && (
                            <List.AccordionGroup>
                              <MSFESAccordion title={stampLabel} id="details">
                                <View>
                                  <MSFESInput
                                    horizontal
                                    label={'X Position (in, from left)'}
                                    onChangeText={(xPosition) =>
                                      stampChangeHandler(st, 'x', xPosition)
                                    }
                                    placeholder={`e.g. 3.25`}
                                    value={st.x}
                                    {...includedProps}
                                  />
                                </View>
                                <View>
                                  <MSFESInput
                                    horizontal
                                    label={'Y Position (in, from top)'}
                                    onChangeText={(yPosition) =>
                                      stampChangeHandler(st, 'y', yPosition)
                                    }
                                    placeholder={`e.g. 3.25`}
                                    value={st.y}
                                    {...includedProps}
                                  />
                                </View>

                                <View>
                                  <MSFESInput
                                    horizontal
                                    label={'Width (in)'}
                                    onChangeText={(width) =>
                                      stampChangeHandler(st, 'width', width)
                                    }
                                    placeholder={`e.g. 3.25`}
                                    value={st.width}
                                    {...includedProps}
                                  />
                                </View>

                                {!isImage && (
                                  <View>
                                    <MSFESInput
                                      horizontal
                                      label={'Text Alignment'}
                                      dataRows={[
                                        {
                                          title: 'Left',
                                          key: 'L'
                                        },
                                        {
                                          title: 'Center',
                                          key: 'C'
                                        },
                                        { title: 'Right', key: 'R' },
                                        { title: 'Justified', key: 'J' }
                                      ]}
                                      value={st.alignment}
                                      InputComponent={SelectWithData}
                                      labelField={'title'}
                                      keyField={'key'}
                                      valueField={'key'}
                                      onChangeText={(value) => {
                                        stampChangeHandler(
                                          st,
                                          'alignment',
                                          value
                                        )
                                      }}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                {isImage && (
                                  <View>
                                    <MSFESInput
                                      horizontal
                                      disabled={!isImage}
                                      label={'Height (in)'}
                                      onChangeText={(width) =>
                                        stampChangeHandler(st, 'height', width)
                                      }
                                      placeholder={`e.g. 3.25`}
                                      value={st.height}
                                      {...includedProps}
                                    />
                                  </View>
                                )}
                                <View>
                                  <MSFESInput
                                    horizontal
                                    label={'Page'}
                                    onChangeText={(page) =>
                                      stampChangeHandler(st, 'page', page)
                                    }
                                    placeholder={`e.g. 1`}
                                    value={st.page}
                                    {...includedProps}
                                  />
                                </View>
                                <View>
                                  <MSFESInput
                                    horizontal
                                    disabled={!!st.field?.field_data?.property}
                                    label={'Text'}
                                    onChangeText={(text) =>
                                      stampChangeHandler(st, 'text', text)
                                    }
                                    placeholder={`e.g. {{field}}`}
                                    value={st.text}
                                    {...includedProps}
                                  />

                                  <MSFESInput
                                    horizontal
                                    label={'Field'}
                                    dataRows={fields}
                                    value={st.field}
                                    InputComponent={SelectWithData}
                                    labelField={labelField}
                                    keyField={keyField}
                                    valueField={valueField}
                                    onChangeText={(value) => {
                                      stampChangeHandler(st, 'field', value)

                                      // we blur when a field is selected.
                                    }}
                                    {...includedProps}
                                  />

                                  {!isImage && (
                                    <MSFESInput
                                      horizontal
                                      label={'Font'}
                                      dataRows={[
                                        {
                                          title: 'Californian FB',
                                          key: 'CalifornianFB-Reg'
                                        },
                                        {
                                          title: 'Helvetica',
                                          key: 'Helvetica'
                                        },
                                        { title: 'Times', key: 'Times' }
                                      ]}
                                      value={st.font}
                                      InputComponent={SelectWithData}
                                      labelField={'title'}
                                      keyField={'key'}
                                      valueField={'key'}
                                      onChangeText={(value) => {
                                        stampChangeHandler(st, 'font', value)
                                      }}
                                      {...includedProps}
                                    />
                                  )}
                                  {!isImage && (
                                    <MSFESInput
                                      horizontal
                                      label="Color"
                                      defaultValue={st.fontColor}
                                      InputComponent={ColorPickerField}
                                      changeHandler={(color) => {
                                        stampChangeHandler(
                                          st,
                                          'fontColor',
                                          color
                                        )
                                      }}
                                      {...includedProps}
                                    />
                                  )}
                                  {!isImage && (
                                    <MSFESInput
                                      horizontal
                                      keyboardType="numeric"
                                      label="Size"
                                      value={st.fontSize}
                                      onChangeText={(size) => {
                                        stampChangeHandler(st, 'fontSize', size)
                                      }}
                                      {...includedProps}
                                    />
                                  )}
                                </View>
                                <View style={{ flex: 1 }}>
                                  <Divider />
                                  <View style={{ alignItems: 'flex-end' }}>
                                    <MSFESButton
                                      icon={{
                                        type: 'font-awesome',
                                        name: 'trash'
                                      }}
                                      type="delete"
                                      onPress={() => deleteStamp(st)}
                                    ></MSFESButton>
                                  </View>
                                </View>
                              </MSFESAccordion>
                            </List.AccordionGroup>
                          )}
                        </Card.Content>
                      )
                    })}
                  </View>
                </Card.Content>

                <ListItemLinkButton
                  title={`Add new stamp`}
                  type="add"
                  onPress={addNewStamp}
                />
              </MSFESAccordion>
            </List.AccordionGroup>
          </ScrollView>

          <View style={{ flexDirection: 'row', paddingHorizontal: spacing.m2 }}>
            <View style={{ marginRight: spacing.m2 }}>
              <Divider />
              <View>
                <MSFESButton
                  icon={{ type: 'font-awesome', name: 'trash' }}
                  type="delete"
                  onPress={deleteReport}
                ></MSFESButton>
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <MSFESButton
                onPress={() => {
                  onReportChanged(localReport)
                  onCancelEdit()
                }}
                title="Save Report"
              />
            </View>
          </View>
        </Overlay>
      ) : (
        <></>
      )}
    </View>
  )
}

export default ReportEditor
