import _ from 'lodash'
import React, { useContext } from 'react'
import CrossFieldRenderAsNullContext from '../../contexts/CrossFieldRenderAsNullContext'
import { SelectList } from './SelectList'
import { getCurrentUser, simplifyObject } from './SelectWithData'

export const SelectPersonnel = React.forwardRef((props, ref) => {
  const current_user = getCurrentUser()
  const lockedToCreator = _.get(
    props.data,
    'field.field_data.params.lockedToCreator',
    false
  )

  let personnelId = undefined
  if (
    lockedToCreator &&
    _.get(props.data, 'entity.mode', 'add') == 'add' &&
    !props.defaultValue &&
    !props.data?.entity?.virtual
  ) {
    personnelId = simplifyObject(current_user.personnel_record)
    props.onChangeText(personnelId)
  } else {
    personnelId = props.defaultValue
  }

  const { crossFieldRenderAsNull } = useContext(CrossFieldRenderAsNullContext)
  const skipStaticRendering =
    _.get(
      crossFieldRenderAsNull,
      `suppressObject[${props.data?.field?.field_data?.property}]`,
      true
    ) === false

  return skipStaticRendering ? null : (
    <SelectList
      {...props}
      value={personnelId}
      defaultValue={lockedToCreator ? personnelId : undefined}
      // editable={!lockedToCreator}
      keyField="id"
      data={{
        entity: props.data?.entity,
        field: {
          field_data: {
            params: {
              ...props.data?.field.field_data.params,
              ...{ sourceName: 'personnel' }
            }
          }
        }
      }}
    />
  )
})
