// libraries
import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { View, Text, SafeAreaView, FlatList, KeyboardAvoidingView } from 'react-native'
import { Card, Input, Button } from 'react-native-elements'
import { format } from 'timeago.js'
import { useSelector, useDispatch } from 'react-redux'
import useInterval from '@use-it/interval';

import WithHeader from '../components/WithHeader'

//actions
import conversationActions from '../store/conversation/actions'

// components

import { ScrollView } from 'react-native'
import text from '../styles/text'
import spacing from '../styles/spacing'
import shared from '../styles/shared'

const MessageRow = ({ message }) => {

  const { is_sender } = message;

  return <View style={{ alignItems: is_sender ? "flex-end" : "flex-start" }}>
    <Card containerStyle={{ maxWidth: "80%", minWidth: "30%" }}>
      <View style={{ paddingBottom: spacing.m1 }}>
        <Text>{message.body}</Text>
      </View>
      <Text style={[text.small, { textAlign: "right" }]}>{format(message.created_at)}</Text>
    </Card></View>
}


const AddMessageBox = ({ conversation, onMessageSaved }) => {

  const textboxRef = useRef(null)
  const { id } = conversation
  const dispatch = useDispatch();

  const [pendingMessageBody, setPendingMessageBody] = useState("")
  const [loading, setLoading] = useState(false)

  const saveMessage = () => {
    setLoading(true);
    dispatch(conversationActions.addMessageToConversation(id, pendingMessageBody)).then(() => {
      setLoading(false);
      textboxRef.current.focus()
    }).then(onMessageSaved)
    setPendingMessageBody("");
  }
  useEffect(() => {

    textboxRef.current.focus()
  }, [])


  return (
    <View style={{ alignItems: "flex-end", paddingBottom: 36 }}>
      <Card containerStyle={{ width: "80%" }}>

        <Input ref={textboxRef} value={pendingMessageBody} disabled={loading} onChangeText={setPendingMessageBody} />
        <Button onPress={saveMessage} loading={loading} disabled={!pendingMessageBody} title="Send" />
      </Card></View>)
}



export default function ConversationViewScreen({ route, navigation }) {
  const scrollviewRef = useRef(null);
  const { page = 1, id } = route.params;

  const dispatch = useDispatch();

  const delay = 5 * 1000; //every 15s.

  const [ lastIdReceived, setLastIdReceived ] = useState(0);
  useInterval(() => {
    console.log("<interval running>")
    loadMessages()
  }, delay);

  const conversation = useSelector((state) => {
    return _.get(state.conversations, 'conversations.data', []).find(c => c.id === parseInt(id))
  })

  const messages = useSelector((state) =>
    _.get(state.conversations, `messages.ref_${id}_${page}`, []))

  const loadMessages = () => {
    
    dispatch(conversationActions.listMessages(id, { page, sorting: 'desc', created_since: lastIdReceived })).then((response) => {

      if(response.data.length){
        setLastIdReceived(response.data[response.data.length-1].id)
      }
      scrollviewRef.current && scrollviewRef.current.scrollToEnd({ animated: true })
    })
  }
  const onMessageSaved = () => {
    loadMessages();
  }

  useEffect(() => {
    setLastIdReceived(0)
    loadMessages()
    dispatch(conversationActions.listAllConversations())
  }, ["", route.params.id])

  const pageTitle = _.get(conversation, "name", '');

  const renderMessageRow = ({ item }) => (
    <MessageRow message={item} />
  )
  return (
    <WithHeader title={pageTitle} navigation={navigation} leftButtonType={"back"}>

    <KeyboardAvoidingView behavior="padding" style={[shared.debugOff, { flex: 1, flexDirection: 'column', justifyContent: 'center' }]} keyboardVerticalOffset={80}>

      <ScrollView style={{ flex: 1 }}
        ref={scrollviewRef}>
        <FlatList
          data={messages}
          renderItem={renderMessageRow} keyExtractor={item => item.id.toString()} />
      </ScrollView>
      {conversation && <AddMessageBox conversation={conversation} onMessageSaved={onMessageSaved} />}
      </KeyboardAvoidingView>



    </WithHeader>)
}