import { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { listEntity } from '../../store/entity/actions'
import { defaultQueryParams } from '../EntityListScreen'
import _ from 'lodash'
export const useFetchEntitiesList = ({
  reportEntityType,
  filter,
  filterPath
}) => {
  const dispatch = useDispatch()

  const [refreshing, setRefreshing] = useState(false)

  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const { page = null, searchText, due_at } = queryParams
  const entityListWrapper = useSelector((state) => {
    const entityListWrapper = reportEntityType
      ? _.get(state.entities, filterPath, {
          data: null,
          meta: null,
          hasSeenData: false
        })
      : { data: null, meta: null, hasSeenData: false }

    return entityListWrapper
  })
  const { meta } = entityListWrapper

  const loadMoreItems = () => {
    if (!meta || page < meta.last_page) {
      setQueryParams((queryParams) => {
        return { ...queryParams, page: page + 1, searchText }
      })
    }
  }

  const fetchItemsForParams = () => {
    return dispatch(
      listEntity(reportEntityType, {
        filter,
        page,
        searchText,
        due_at,
        from_widget: false,
        detailed: true
      })
    )
  }

  useEffect(() => {
    if (reportEntityType) {
      setRefreshing(true)
      fetchItemsForParams()
        .then(() => {})
        .finally(() => {
          setRefreshing(false)
        })
    }
  }, [filter, queryParams, reportEntityType])

  const onRefresh = () => {
    setRefreshing(true)
    fetchItemsForParams().then(() => {
      setRefreshing(false)
    })
  }

  return { loadMoreItems, onRefresh, refreshing, meta }
}
