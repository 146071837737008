import _ from 'lodash'
import { DateTime } from 'luxon'
const defaultState = {
  conversations: {},
  messages: {},
  errors: {
    errors: null,
    hasError: false
  }
}

export default function tenants(state = defaultState, action) {
  switch (action.type) {
    case 'conversation/list-started':
      {
        state = {
          ...state,
          conversations: {
            ...state.conversations,
            lastRetrieveStarted: DateTime.local()
          }
        }
      }

      break
    case 'conversation/list-success':
      {
        const { response } = action

        state = {
          ...state,
          ...{
            conversations: {
              ...state.conversations,
              data: response.data,
              meta: response.meta,
              links: response.links,
              lastRetrieveFinished: DateTime.local()
            }
          }
        }
      }

      break
    case 'conversation/message-insert-success':
      {
        const { response, id, body } = action

      }
      break;
    case 'conversation/message-list-success':
      {
        const { response, id, args } = action

        const { page } = args;
        const messages = _.get(state, "messages", {})

        const messageBucket = _.get(messages, `ref_${id}_${page}`, [])

        let values;
        if(args.created_since > 0){
          // if this is incremental, merge in
          let merged = _.merge(_.keyBy(messageBucket, 'id'), _.keyBy(response.data, 'id'));
          values = _.values(merged);
        } else {
          // if this is start, wipe history
          values = response.data;
        }

        _.set(messages, `ref_${id}_${page}`, _.sortBy(values, m => m.id));
        state = {
          ...state,
          ...{
            messages
          }
        }

      }
      break
    case 'conversation/clear-general-error':
      state.errors = {
        ...state.errors,
        ...{ message: null, statusCode: null }
      }
      break
  }

  return state
}
