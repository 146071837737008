// libraries
import _ from 'lodash'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Icon } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'
import { useLinkTo } from '@react-navigation/native'

//actions
import conversationActions from '../store/conversation/actions'

// styles

// components
import { ListItemLinkButton } from '../components/ListItemLinkButton'

import WithHeader from '../components/WithHeader'
import CurrentlyNoItems from '../components/CurrentlyNoItems'
import { FlatList } from 'react-native'
import { currentlyNoTemplateDefaults } from './CategoryViewScreen'




export default function ConversationListScreen({ route, navigation }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(conversationActions.listAllConversations())
  }, [])
  const linkTo = useLinkTo()
  const gotoConversation = (conversation) => {
    linkTo("/conversations/" + conversation.id + "?from=" + encodeURIComponent("/conversations"))
  }
  const conversations = useSelector((state) =>
    _.get(state.conversations, 'conversations.data', [])
  )


  const currentlyNoLabel = currentlyNoTemplateDefaults({ label: 'conversations'})
  const addButtonLabel = "Start Conversation"

  return (
    <WithHeader title="Messages" navigation={navigation} rightButtonAction={null}>
      <ListItemLinkButton
        title={addButtonLabel}
        type="add"
        key={'add'}
        onPress={() => navigation.navigate('ConversationCreate', { from: "/conversations" })}
      />
      <View style={{ flex: 1 }}>
        <FlatList
          contentContainerStyle={{ flex: 1 }}
          ListEmptyComponent={<CurrentlyNoItems label={currentlyNoLabel} />}
          keyExtractor={item => item.id.toString()}
          data={conversations} renderItem={({ item, index, separators }) => {
            return <ListItemLinkButton
              title={item.name}
              avatarName={_.get(item.second_party, 'name', null)}
              timestamp={item.updated_at}
              showChevron={true}
              icon={
                item.unread_count > 0 ? (
                  <Icon name="circle" size={12} type="font-awesome" color="green" />
                ) : null
              }
              onPress={() => gotoConversation(item)}
            />
          }} />
      </View>
    </WithHeader>

  )
}