import React, { useEffect } from 'react'

import { View } from 'react-native'
import { useDropdownHelper } from '../../useDropdownHelper'
import MSFESButton from '../MSFESButton'
import { Overlay } from 'react-native-elements'
import { Portal } from 'react-native-paper'
import shared from '../../styles/shared'

export const Dropdown = ({
  title,
  children,
  visible = true,
  variant = 'outline',
  component = undefined,
  positionAtButton = true
}) => {
  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(240, 0, positionAtButton)

  useEffect(() => {
    if (!visible) {
      toggleDropdownState()
    }
  }, [visible])
  return (
    <View>
      <View ref={buttonRef} collapsable={false}>
        {!component && (
          <MSFESButton
            variant={variant}
            title={title}
            onPress={toggleDropdownState}
          />
        )}
        {component && component({ onPress: toggleDropdownState })}
      </View>
      <View
        style={[
          shared.debug,
          { position: 'absolute', left: -10000, top: -10000 }
        ]}
      >
        <Portal>
          {dropdownVisibilityState && (
            <Overlay
              visible={true}
              backdropStyle={{ opacity: 1 }}
              onBackdropPress={toggleDropdownState}
              overlayStyle={dropdownOverlayStyle}
            >
              <View style={{ marginTop: -1 }}>{children}</View>
            </Overlay>
          )}
        </Portal>
      </View>
    </View>
  )
}
