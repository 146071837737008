import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PublicFormContext from '../../contexts/PublicFormContext'
import { flattenEntity } from '../../helpers/entity'
import {
  entityByName,
  sortListWithEntityType
} from '../../libraries/entityTools'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'

//actions
import entityActions, { getFilterString } from '../../store/entity/actions'
import { evaluateRule } from '../query/evaluateRule'
import RadioWithData from './RadioWithData'

export const shouldRefetch = (sourceListRoot) => {
  const refetchIfBefore = DateTime.local().minus({ minutes: 5 })

  const lastRetrieved = _.get(sourceListRoot, 'lastRetrieveFinished', null)
  const lastRetrieveStarted = lastRetrieved && DateTime.fromISO(lastRetrieved)
  if (!lastRetrieveStarted || lastRetrieveStarted < refetchIfBefore) {
    return true
  } else {
    return false
  }
}

const _RadioList = (props, ref) => {
  const dispatch = useDispatch()

  const [nextPages, setNextPages] = useState({})

  const allEntityTypes = useSelector((state) =>
    _.get(state, 'entityTypes.data', null)
  )

  const sourceNames = _.castArray(props.data.field.field_data.params.sourceName)

  const filterRule =
    props?.data?.field?.field_data?.params?.optionsFilter?.json?.logic

  const needsFieldsToSort = useMemo(() => {
    return _.some(sourceNames, (sourceName) => {
      const entityType = allEntityTypes.find((a) => a.name === sourceName)
      const sortField = _.get(
        entityType,
        'object_data.sort_by_field.field_data.property',
        null
      )

      return sortField
    })
  }, [allEntityTypes])

  const filter = filterRule || needsFieldsToSort ? { detailed: true } : null

  const entityTypes = sourceNames.map((s) => entityByName(s, allEntityTypes))
  const publicFormContext = useContext(PublicFormContext)

  const filterString = sanitiseFilterString(getFilterString(null, filter))

  const sourceListRoots = sourceNames.map((entityTypeName) => {
    return useSelector((state) =>
      entityTypeName
        ? _.get(state.entities, `[${entityTypeName + filterString}]`, {
            data: []
          })
        : []
    )
  })

  const sourceData = useMemo(() => {
    const data = _.flatten(
      sourceListRoots.map((sourceListRoot, index) => {
        const data = sortListWithEntityType(
          _.get(sourceListRoot, 'data', []),
          entityTypes[index]
        )

        return data
      })
    )

    const filteredData = filterRule
      ? data.filter((d) =>
          evaluateRule(filterRule, {
            ...flattenEntity(d, false),
            field: flattenEntity(props.data.entity, false)
          })
        )
      : data

    return filteredData
  }, [sourceListRoots])

  const fetchNextPage = (index) => {
    if (_.get(nextPages, index, false) !== -1) {
      let pageToFetch

      try {
        pageToFetch = sourceListRoots[index].meta.current_page + 1
      } catch (e) {
        // doesn't matter.
        pageToFetch = nextPages[index]
      }

      if (!pageToFetch || pageToFetch > sourceListRoots[index].meta.last_page) {
        pageToFetch = 1
      }

      dispatch(
        entityActions.listEntity(
          { name: sourceNames[index] },
          { filter, page: pageToFetch, detailed: filter?.detailed },
          undefined,
          publicFormContext.entityType,
          {
            tenantId: publicFormContext.tenantId,
            tenantHmac: publicFormContext.tenantHmac
          }
        )
      ).then((response) => {
        if (!nextPages[index] || nextPages[index] <= response.meta.last_page) {
          if (response.meta.current_page < response.meta.last_page) {
            setNextPages((nextPages) => ({
              ...nextPages,
              ...{ [index]: response.meta.current_page + 1 }
            }))
          } else {
            setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
          }
        } else {
          setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: -1 } }))
        }
      })
    }
  }

  useEffect(() => {
    sourceListRoots.map((sourceListRoot, index) => {
      if (shouldRefetch(sourceListRoot)) {
        fetchNextPage(index)
      } else {
        setNextPages((nextPages) => ({ ...nextPages, ...{ [index]: 1 } }))
      }
    })
  }, [])

  return (
    <RadioWithData
      {...props}
      dataRows={sourceData}
      labelField={'display_name'}
      value={props.defaultValue}
    />
  )
}
export const RadioList = React.forwardRef(_RadioList)
